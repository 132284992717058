import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { transformMessageReport } from './transform';

export const GET_REPORTED_MESSAGES = gql`
  query GetMessageReports($offset: Int, $limit: Int, $searchText: String) {
    messageReportsCount: usersPermissionsUsers(
      filters: {
        message_reported_user: { id: { ne: null } }
        or: [{ username: { contains: $searchText } }, { name: { contains: $searchText } }]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:DESC"
      filters: {
        message_reported_user: { id: { ne: null } }
        or: [{ username: { contains: $searchText } }, { name: { contains: $searchText } }]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          type
          profileImage {
            data {
              attributes {
                url
              }
            }
          }
          message_reported_user {
            data {
              id
              attributes {
                message {
                  data {
                    id
                    attributes {
                      content
                    }
                  }
                }
                claim
                detail
                date
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetReportedMessages = (offset: number, limit: number, searchText: string) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_REPORTED_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  //   const reports = flattenObj(data?.usersPermissionsUsers);
  //   const formatedReports = reports?.map(
  //     (report: { messageReports: any; message_reported_user: any }) => {
  //       report.messageReports = transformMessageReport(report.message_reported_user);
  //     }
  //   );
  return {
    messageReports: flattenObj(data?.usersPermissionsUsers),
    messageReportsCount: data?.messageReportsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
