import { gql, useMutation } from '@apollo/client';

interface BanUserInput {
  banUserId: number;
  name: string;
  email: string;
  claim: string;
  detail: string;
  bannerId: number;
  listingId?: number;
  storefrontId?: number;
}
const BAN_USER = gql`
  mutation BanDeleteUser(
    $banUserId: ID!
    $bannerId: ID!
    $name: String
    $email: String
    $claim: String
    $detail: String
    $listingId: ID
    $storefrontId: ID
  ) {
    banDeleteUser(
      banUserId: $banUserId
      bannerId: $bannerId
      name: $name
      email: $email
      claim: $claim
      detail: $detail
      listingId: $listingId
      storefrontId: $storefrontId
    ) {
      count
    }
  }
`;

export const useBanDeleteUser = (): ((banUser: BanUserInput) => any) => {
  const [banUser] = useMutation(BAN_USER);
  return (input: BanUserInput) => banUser({ variables: input });
};
