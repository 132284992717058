import { ACCOUNT_TYPE } from '../constants/data';

export const progressContentHandler = (type: string) => {
  return [
    {
      id: 1,
      title: 'Choose account type',
      supportingTitle: 'Are you a private seller or a business?'
    },
    { id: 2, title: 'Create user profile', supportingTitle: 'Enter your information' },
    {
      id: 3,
      title: type === ACCOUNT_TYPE.PERSONAL ? 'Add your location' : 'Create Storefront',
      supportingTitle:
        type === ACCOUNT_TYPE.PERSONAL ? 'Where are you located?' : 'Enter your store information'
    },
    { id: 4, title: 'Congratulations', supportingTitle: 'You’re all done!' }
  ];
};
