import React from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import QuarantineListingsGridLayout from './QuarantineListingsGridLayout';
import QuarantineListingsDetailedLayout from './QuarantineListingsDetailedLayout';

const QuarantineListings = () => {
  const gridLayout = useAppSelector((state) => state.report.reportLayout);

  return (
    <div>
      {gridLayout.type === 'grid' ? (
        <QuarantineListingsGridLayout />
      ) : (
        <QuarantineListingsDetailedLayout />
      )}
    </div>
  );
};

export default QuarantineListings;
