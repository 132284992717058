import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ListingNew } from '../../../../model/Listing';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Typo } from '../../../Typo';
import { LISTING_BREAKPOINT, LISTING_TYPES, LIST_PAGES } from '../../../../constants/data';
import Loader from '../../../shared/Loader';
import { useGetListings } from '../../../../hooks/listing/useGetListings';
import { ApiSearchOrderEnum } from '../../../../util/enum/enum';
import ListingCard from '../../../shared/ListingCard/ListingCard';
import { useHandleSave } from '../../../../hooks/useHandleSave';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
type Props = {
  className?: string;
  userId: number;
  profileId: number;
};

const ProfileListings: React.FC<Props> = (props) => {
  const { userId, profileId } = props;
  const NUMBER_OF_ITEMS_PER_PAGE = 4;
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(NUMBER_OF_ITEMS_PER_PAGE);
  const { listings, pagination, loading, fetchMore, refetch } = useGetListings(
    userId,
    profileId,
    0,
    perPage,
    ApiSearchOrderEnum.PUBLISHED_DESC,
    LISTING_TYPES.SELLING
  );
  const { save } = useHandleSave(refetch);
  const fetchMoreListing = async () => {
    const newOffset = offset + NUMBER_OF_ITEMS_PER_PAGE;
    const newPerPage = perPage + NUMBER_OF_ITEMS_PER_PAGE;
    fetchMore({
      variables: { offset: newOffset, limit: NUMBER_OF_ITEMS_PER_PAGE }
    });
    setOffset(newOffset);
    setPerPage(newPerPage);
  };

  return (
    <div id="listingContent" className=" h-screen overflow-y-scroll pb-24 space-y-4">
      <div>
        <Typo.textSm fontWeight="font-medium" className="text-gray-700">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.RECENT_LISTINGS.id}
            defaultMessage={TRANSLATED_CONSTANTS.RECENT_LISTINGS.defaultMessage}
          />
        </Typo.textSm>
        <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.VIEW_USERS_RECENT_LISTINGS.id}
            defaultMessage={TRANSLATED_CONSTANTS.VIEW_USERS_RECENT_LISTINGS.defaultMessage}
          />
        </Typo.textSm>
        <div className=" space-y-4 pt-6">
          {listings && (
            <InfiniteScroll
              dataLength={pagination?.total || 0}
              next={fetchMoreListing}
              hasMore={pagination?.total > offset} // You should implement a condition to stop when all data is fetched
              scrollableTarget="listingContent"
              className="space-y-4"
              loader="">
              {listings?.map((listing: ListingNew) => {
                return (
                  <ListingCard
                    onLike={save}
                    cardClickable={true}
                    size={LISTING_BREAKPOINT.SLIDE}
                    page={LIST_PAGES.MAP}
                    key={listing.id}
                    listing={listing}
                  />
                );
              })}
            </InfiniteScroll>
          )}
          {loading && <Loader className=" h-10" />}
        </div>
      </div>
    </div>
  );
};

export default ProfileListings;
