import { gql, useMutation } from '@apollo/client';

interface BanUserInput {
  banUserId: number;
  bannerId: number;
}
const BAN_USER = gql`
  mutation BanUser($banUserId: ID!, $bannerId: ID!) {
    banUser(banUserId: $banUserId, bannerId: $bannerId) {
      count
    }
  }
`;

export const useBanUser = (): ((banUser: BanUserInput) => any) => {
  const [banUser] = useMutation(BAN_USER);
  return (input: BanUserInput) => banUser({ variables: input });
};
