type Props = {
  className?: string;
};
const TagItemIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-3 w-3 text-indigo-500 ${className}`}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1390_106835)">
        <path
          d="M10.5 5.5L6.70294 1.70294C6.44352 1.44352 6.3138 1.3138 6.16243 1.22104C6.02822 1.1388 5.8819 1.07819 5.72885 1.04145C5.55622 1 5.37277 1 5.00589 1L3 1M1.5 4.35L1.5 5.33726C1.5 5.58185 1.5 5.70414 1.52763 5.81923C1.55213 5.92127 1.59253 6.01881 1.64736 6.10828C1.7092 6.2092 1.79568 6.29568 1.96863 6.46863L5.86863 10.3686C6.26465 10.7646 6.46265 10.9627 6.69098 11.0368C6.89183 11.1021 7.10817 11.1021 7.30902 11.0368C7.53735 10.9627 7.73535 10.7646 8.13137 10.3686L9.36863 9.13137C9.76465 8.73535 9.96265 8.53735 10.0368 8.30902C10.1021 8.10817 10.1021 7.89183 10.0368 7.69098C9.96265 7.46265 9.76465 7.26465 9.36863 6.86863L5.71863 3.21863C5.54568 3.04568 5.4592 2.9592 5.35829 2.89736C5.26881 2.84253 5.17127 2.80213 5.06923 2.77763C4.95414 2.75 4.83185 2.75 4.58726 2.75H3.1C2.53995 2.75 2.25992 2.75 2.04601 2.85899C1.85785 2.95487 1.70487 3.10785 1.60899 3.29601C1.5 3.50992 1.5 3.78995 1.5 4.35Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1390_106835">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TagItemIcon;
