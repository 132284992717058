import React, { useState, Fragment } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import ChevronIcon from '../icons/ChevronIcon';
import { CheckboxOption } from '../../model/CheckboxOption';
import Button from './Button';
import { Typo } from '../Typo';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { shortenText } from '../../util/AppUtil';
import CheckIcon from '../icons/CheckIcon';

type Props = {
  iconType?: 'none' | 'image' | 'icon' | 'onlineIndicatro';
  prefix?: any;
  label?: string;
  placeholder?: string;
  hasSearch?: boolean;
  options: Array<CheckboxOption>;
  selectedOption: CheckboxOption;
  onChange?: (options: CheckboxOption) => void;
  className?: string;
  usedOnFilter?: boolean;
  [x: string]: any;
};

const MoreFilterCategoryItem: React.FC<Props> = (props) => {
  const intl = useIntl();
  const {
    label = '',
    prefix,
    options = [],
    selectedOption,
    onChange,
    className,
    usedOnFilter = false
  } = props;
  const [showItems, setShowItems] = useState(5);
  return (
    <div className={`top-16 flex flex-col gap-y-1.5 w-full  ${className}`}>
      {label && <div className=" text-sm font-normal font-inter text-gray-600">{label}</div>}
      <Combobox value={selectedOption}>
        {({ open }) => (
          <div className="relative ">
            <div className={usedOnFilter ? 'px-4 md:px-0' : ''}>
              <div
                className={clsx(
                  'relative w-full cursor-default overflow-hidden rounded-lg text-left shadow-xs border border-gray-300 focus:ring-4 focus:ring-gray-100',
                  { ' ring-4 ring-gray-100': open }
                )}>
                <Combobox.Button className="flex w-full h-11 justify-between items-center py-2.5 px-3.5 group">
                  <div className=" flex justify-between">
                    <div className=" flex gap-x-2 text-md items-center justify-center pr-2">
                      {prefix}
                      <div className="max-w-[200px] lg:max-w-[160px] xl:max-w-xs">
                        <Typo.textMd
                          fontWeight="font-normal"
                          className={clsx(
                            ' flex w-full whitespace-nowrap overflow-hidden pr-4 text-ellipsis text-gray-900'
                          )}>
                          {/* {selectedOption.value == '0'
                          ? selectedOption?.label?.toString()
                          : shortenText(selectedOption?.label?.toString(), 30)} */}
                          {selectedOption?.label?.toString()}
                        </Typo.textMd>
                      </div>
                    </div>
                  </div>
                  <ChevronIcon
                    className={clsx('h-4 w-4 text-gray-500', {
                      ' rotate-180 ': !open
                    })}></ChevronIcon>
                </Combobox.Button>
              </div>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0">
              <Combobox.Options className=" relative md:absolute z-10 md:border md:shadow-lg md:border-gray-300 mt-2 w-full md:max-h-60 md:overflow-y-auto overflow-x-hidden py-1 md:rounded-md bg-white text-base  focus:outline-none">
                {options?.length === 0 ? (
                  <div className="relative cursor-default select-none py-2.5 px-4 text-gray-700">
                    <Typo.textSm fontWeight="font-normal" className="truncate whitespace-nowrap">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.NOTHING_FOUND.id,
                        defaultMessage: TRANSLATED_CONSTANTS.NOTHING_FOUND.defaultMessage
                      })}
                    </Typo.textSm>
                  </div>
                ) : (
                  <>
                    <Combobox.Option
                      key={0}
                      onClick={() =>
                        onChange &&
                        onChange({
                          value: '0',
                          label: intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
                          })
                        })
                      }
                      className="relative block  w-full cursor-pointer focus:outline-none h-11 disabled:cursor-not-allowed select-none text-gray-700"
                      value={{
                        value: '0',
                        label: intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
                        })
                      }}>
                      <div
                        className={clsx(
                          `flex justify-between items-center h-full py-2.5 px-6 md:px-3 hover:bg-gray-50 ${
                            selectedOption?.value == '0' ? ' bg-gray-50' : ' bg-white'
                          }  `
                        )}>
                        <div className=" flex gap-x-2 items-center justify-center ">
                          <Typo.textSm
                            fontWeight="font-normal"
                            className="truncate whitespace-nowrap">
                            {intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
                              defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
                            })}
                          </Typo.textSm>
                        </div>
                        {selectedOption?.value == '0' && <CheckIcon className="text-yellow-600" />}
                      </div>
                    </Combobox.Option>
                    {options?.slice(0, showItems)?.map((option: CheckboxOption) => (
                      <Combobox.Option
                        key={option.value}
                        onClick={() => onChange && onChange(option)}
                        className="relative block md:hidden w-full cursor-pointer focus:outline-none h-11 disabled:cursor-not-allowed select-none text-gray-700"
                        value={option}>
                        <div
                          className={clsx(
                            `flex justify-between h-full items-center py-2.5 px-6 hover:bg-gray-50 ${
                              selectedOption?.value === option.value ? ' bg-gray-50' : ' bg-white'
                            }  `
                          )}>
                          <div className=" flex gap-x-2 items-center justify-center ">
                            <Typo.textSm
                              fontWeight="font-normal"
                              className="truncate whitespace-nowrap">
                              {option?.label}
                            </Typo.textSm>
                          </div>
                          {selectedOption?.value === option.value && (
                            <CheckIcon className="text-yellow-600" />
                          )}
                        </div>
                      </Combobox.Option>
                    ))}
                    {options?.map((option: CheckboxOption) => (
                      <Combobox.Option
                        key={option.value}
                        onClick={() => onChange && onChange(option)}
                        className="relative cursor-pointer hidden md:block focus:outline-none  h-11 disabled:cursor-not-allowed select-none text-gray-700"
                        value={option}>
                        <div
                          className={clsx(
                            `flex justify-between h-full items-center pl-2.5 pr-2.5 md:pr-4 px-4 hover:bg-gray-50 ${
                              selectedOption?.value === option.value ? ' bg-gray-50' : ' bg-white'
                            }  `
                          )}>
                          <div className=" flex gap-x-2 items-center justify-center ">
                            <Typo.textSm
                              fontWeight="font-normal"
                              className="truncate whitespace-nowrap">
                              {option?.label}
                            </Typo.textSm>
                          </div>
                          {selectedOption?.value === option?.value && (
                            <CheckIcon className="text-yellow-600" />
                          )}
                        </div>
                      </Combobox.Option>
                    ))}
                  </>
                )}
                {options?.length > showItems && (
                  <div className="px-4 block md:hidden">
                    <Button
                      onClick={() => setShowItems(showItems + 10)}
                      size="md"
                      className="underline "
                      theme="yellow-link">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.SHOW_10_MORE.id,
                        defaultMessage: TRANSLATED_CONSTANTS.SHOW_10_MORE.defaultMessage
                      })}
                    </Button>
                  </div>
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
    </div>
  );
};

export default MoreFilterCategoryItem;
