import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { customizeUsersBanned } from './transfrom';

export const GET_BANNED_USERS = gql`
  query usersBanner($bannerId: ID, $searchText: String) {
    usersBannerCount: usersPermissionsUsers(
      filters: {
        and: [
          { banned_user: { banned_by: { id: { eq: $bannerId } } } }
          {
            or: [
              { name: null }
              { name: { containsi: $searchText } }
              { username: { containsi: $searchText } }
            ]
          }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      filters: {
        and: [
          { banned_user: { banned_by: { id: { eq: $bannerId } } } }
          {
            or: [
              { name: null }
              { name: { containsi: $searchText } }
              { username: { containsi: $searchText } }
            ]
          }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          email
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          banned_user {
            data {
              id
              attributes {
                date
                banned {
                  data {
                    id
                  }
                }
              }
            }
          }
          listings {
            data {
              attributes {
                listing_reports {
                  data {
                    id
                  }
                }
                issued_listing {
                  data {
                    id
                  }
                }
              }
            }
          }
          banned_user {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const useGetUsersBanned = (
  offset: number,
  limit: number,
  searchText: string,
  bannerId: number
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_BANNED_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText, bannerId }
  });

  return {
    bannedUsers: customizeUsersBanned(flattenObj(data?.usersPermissionsUsers)),
    bannedUsersCounts: data?.usersBannerCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
