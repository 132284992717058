import clsx from 'clsx';
import { FC } from 'react';
import { Typo } from '../Typo';

type Props = {
  defaultValue?: string;
  errors?: any;
  placeholder?: string;
  register: (name: any, registerOptions: any) => any;
  name: string;
  registerOptions?: any;
  label?: string;
  hintText?: string;
  maxLength?: string;
  disabled?: boolean;
  className?: string;
  verificationtext?: string;
  labelStyle?: string;
  rows?: number;
  required?: boolean;
  value?: string;
};

const TextAreaField: FC<Props> = ({
  defaultValue,
  errors,
  placeholder,
  register,
  name,
  maxLength = 'none',
  hintText = '',
  registerOptions = {},
  disabled = false,
  className = undefined,
  label = '',
  labelStyle = '',
  rows = 10,
  required = false,
  value = null
}) => {
  return (
    <div className={clsx('w-full relative space-y-1.5', className)}>
      {label && (
        <label htmlFor={name || ''} className="left-0 text-left">
          <Typo.textSm fontWeight="font-normal" className={` text-gray-700 ${labelStyle}`}>
            {label}
            {required ? '*' : ''}
          </Typo.textSm>
        </label>
      )}
      <div className="relative">
        <textarea
          id={name}
          {...register(name, registerOptions)}
          placeholder={placeholder}
          rows={rows}
          defaultValue={defaultValue || ''}
          disabled={disabled}
          value={value}
          maxLength={maxLength}
          className={clsx(
            ' px-[14px] py-[10px] w-full rounded-lg border bg-white placeholder:text-gray-500 shadow-xs font-inter text-base leading-6 text-gray-500 hover:text-gray-900 focus:text-gray-900 disabled:bg-gray-50 disabled:text-gray-500 focus:ring-4 ',
            errors?.message == null
              ? ' border-gray-300  focus:border-gray-300 focus:ring-gray-100'
              : ' border-error-300 focus:border-error-300 focus:ring-error-100'
          )}
        />
      </div>
      <Typo.textSm
        fontWeight="font-normal"
        className={`w-full text-left ${errors?.message ? 'text-error-500' : 'text-gray-500'}`}>
        {errors?.message ? errors?.message : hintText}
      </Typo.textSm>
    </div>
  );
};

export default TextAreaField;
