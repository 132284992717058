import React from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import DeletedStorefrontsGridLayout from './DeletedStorefrontsGridLayout';
import DeletedStorefrontsDetailedLayout from './DeletedStorefrontsDetailedLayout';

const DeletedStorefronts = () => {
  const gridLayout = useAppSelector((state) => state.report.reportLayout);

  return (
    <div>
      {gridLayout.type === 'grid' ? (
        <DeletedStorefrontsGridLayout />
      ) : (
        <DeletedStorefrontsDetailedLayout />
      )}
    </div>
  );
};

export default DeletedStorefronts;
