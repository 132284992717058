import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
import { Chat } from '../../model/Message';
import { User } from '../../model/UserModel';
import { timeAgo } from '../../util/AppUtil';
import { Typo } from '../Typo';
import TrashIcon from '../icons/TrashIcon';
import User1Icon from '../icons/User1Icon';
import Avatar from './Avatar';
import React from 'react';
import Button from './Button';

type Props = {
  user?: User;
  onClick: (chat: Chat) => void;
  onDelete: (id: number) => void;
  chat: Chat;
};

const MessageCard: React.FC<Props> = (props) => {
  const { onClick, chat, onDelete } = props;
  return (
    <div
      onClick={() => onClick(chat)}
      className="w-full rounded-xl border border-gray-200 shadow-sm bg-white p-4 flex justify-between cursor-pointer">
      <div className="flex flex-col gap-y-4">
        <div className="flex gap-x-3">
          <Avatar
            image={chat?.profileImage}
            showState={true}
            isOnline={useCheckOnlineUsers(Number(chat?.userId)) || false}
            size="md"
            icon={!chat?.profileImage && <User1Icon className="text-gray-600" />}
            disabled={true}></Avatar>
          <div>
            <Typo.textSm fontWeight="font-medium" className="text-gray-900">
              {chat?.name}
            </Typo.textSm>
            <Typo.textSm fontWeight="font-normal" className="text-gray-600">
              @{chat?.username}
            </Typo.textSm>
          </div>
        </div>
        <Typo.textSm fontWeight="font-normal" className="text-gray-500 pl-5">
          {chat?.content}
        </Typo.textSm>
      </div>
      <div className="flex flex-col gap-y-3 items-end">
        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
          {timeAgo(chat.dateTime)}
        </Typo.textSm>
        <div>
          <Button
            onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              e.stopPropagation(); // Prevent link navigation
              onDelete(chat?.id);
            }}
            icon={<TrashIcon className="h-6 w-6 text-gray-600" />}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
