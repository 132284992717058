import React, { FC } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { Typo } from '../Typo';
type Props = {
  title: MessageDescriptor;
  subTitle: MessageDescriptor;
};

const ModalBodyText: FC<Props> = ({ title, subTitle }) => {
  return (
    <div className="flex flex-col text-center gap-1">
      <Typo.textLg fontWeight="font-medium" className="text-gray-900 ">
        <FormattedMessage id={title?.id} defaultMessage={title?.defaultMessage} />
      </Typo.textLg>

      <Typo.textSm fontWeight="font-normal" className="text-gray-600">
        <FormattedMessage id={subTitle?.id} defaultMessage={subTitle?.defaultMessage} />
      </Typo.textSm>
    </div>
  );
};

export default ModalBodyText;
