import clsx from 'clsx';

type Props = {
  className?: string;
};
const EmailIcon = ({ className }: Props) => {
  return (
    <svg
      className={`w-[19px] h-4 ${className}`}
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8334 3.00016C17.8334 2.0835 17.0834 1.3335 16.1667 1.3335H2.83335C1.91669 1.3335 1.16669 2.0835 1.16669 3.00016M17.8334 3.00016V13.0002C17.8334 13.9168 17.0834 14.6668 16.1667 14.6668H2.83335C1.91669 14.6668 1.16669 13.9168 1.16669 13.0002V3.00016M17.8334 3.00016L9.50002 8.8335L1.16669 3.00016"
        strokeWidth="1.66667"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmailIcon;
