type Props = {
  className?: string;
};
const ShoppingBagIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-4 ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.333 6.66667C13.333 7.55072 12.9818 8.39857 12.3567 9.02369C11.7316 9.64881 10.8837 10 9.99965 10C9.1156 10 8.26775 9.64881 7.64263 9.02369C7.01751 8.39857 6.66632 7.55072 6.66632 6.66667M3.02733 6.16782L2.444 13.1678C2.31869 14.6716 2.25603 15.4235 2.51022 16.0035C2.73356 16.5131 3.12051 16.9336 3.60981 17.1985C4.16671 17.5 4.9212 17.5 6.43018 17.5H13.5691C15.0781 17.5 15.8326 17.5 16.3895 17.1985C16.8788 16.9336 17.2657 16.5131 17.4891 16.0035C17.7433 15.4235 17.6806 14.6716 17.5553 13.1678L16.972 6.16782C16.8641 4.87396 16.8102 4.22703 16.5237 3.73738C16.2714 3.3062 15.8957 2.9605 15.4451 2.74487C14.9333 2.5 14.2841 2.5 12.9858 2.5L7.01352 2.5C5.71517 2.5 5.066 2.5 4.55425 2.74487C4.1036 2.9605 3.7279 3.3062 3.47559 3.73738C3.18907 4.22703 3.13516 4.87396 3.02733 6.16782Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShoppingBagIcon;
