import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { customizeNotifications } from './transform';

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($receiverId: ID, $offset: Int!, $limit: Int!) {
    notifications(
      sort: "updatedAt:DESC"
      pagination: { start: $offset, limit: $limit }
      filters: { receiver: { id: { eq: $receiverId } } }
    ) {
      data {
        id
        attributes {
          content
          count
          isRead
          publishedAt
          updatedAt
          chat {
            data {
              id
            }
          }
          sender {
            data {
              id
              attributes {
                name
                username
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          receiver {
            data {
              id
              attributes {
                name
                username
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetNotifications = (receiverId: string, offset: number, limit?: number) => {
  const { loading, error, data, fetchMore, updateQuery } = useQuery(GET_NOTIFICATIONS, {
    variables: { receiverId, offset, limit },
    fetchPolicy: 'cache-and-network',
    // Update UI when network status changes
    notifyOnNetworkStatusChange: true
  });
  const flattedData = flattenObj(data?.notifications);
  const customizedNotifications = flattedData?.map((notification: any) => {
    return customizeNotifications(notification);
  });
  return {
    notificationsFromDb: customizedNotifications,
    fetchMore,
    updateQuery,
    loading,
    error
  };
};
