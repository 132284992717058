import { ChangeEvent, FC, useEffect, useState } from 'react';
import Modal from './Modal';
import googleIcon from '../../assets/googleIcon.svg';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import InputField from '../shared/InputField';
import Button from '../shared/Button';
import { Typo } from '../Typo';
import { useHandleSignup } from '../../hooks/useHandleSignup';
import CheckboxField from '../shared/CheckboxField';
import Tooltip from '../shared/Tooltip';
import HelpIcon from '../icons/HelpIcon';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../lib/redux/reducers/modal.slice';
import { useAppSelector } from '../../lib/redux/hooks';
import { isVerifiedAge } from '../../util/AppUtil';
import { ApolloError } from '@apollo/client/errors';
import InlineAlert from '../shared/InlineAlert';
import { setAlertData, setAlertType } from '../../lib/redux/reducers/alert.slice';
import { ROUTES } from '../../constants/data';
import dogeIcon from '../../assets/dogelist-Icon.svg';

type Props = {
  isOpen: boolean;
  onCloseSignupModal: (arg0: boolean) => void;
};

const SignupModal: FC<Props> = ({ isOpen, onCloseSignupModal }) => {
  const dispatch = useDispatch();
  const ageSate = useAppSelector((state) => state.signup.age);
  const [isVerified, setIsVerified] = useState(false);
  const { onSubmit, register, handleSubmit, errors } = useHandleSignup();
  const [referralCodeExists, setReferralCodeExists] = useState(false);
  const [submiting, setSubmiting] = useState(false);
  const [alertType, SetAlertType] = useState<'default' | 'success' | 'error'>('success');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const intl = useIntl();
  const onGoogleConfirm = () => {
    window.location.href = `${process.env.REACT_APP_STRAPI_API_URL}/api/connect/google`;
  };

  const onAgeChecked = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      dispatch(hideModal('signup'));
      dispatch(showModal('age'));
    } else {
      setIsVerified(false);
    }
  };

  const onChangeType = (type: 'login' | 'signup') => {
    dispatch(showModal(type));
    dispatch(hideModal('signup'));
  };

  useEffect(() => {
    if (ageSate) {
      setIsVerified(isVerifiedAge(new Date(ageSate)));
    }
  }, [ageSate]);

  const onSignup = async (data: any) => {
    try {
      setSubmiting(true);
      const response = await onSubmit(data);
      if (response) {
        // localStorage.setItem('birthDate', ageSate);
        dispatch(setAlertType('welcome'));
        dispatch(hideModal('signup'));
        dispatch(showModal('login'));
        dispatch(
          setAlertData({
            title: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.WELCOME_ALERT_TITLE.id,
              defaultMessage: TRANSLATED_CONSTANTS.WELCOME_ALERT_TITLE.defaultMessage
            }),
            description: intl.formatMessage(
              {
                id: TRANSLATED_CONSTANTS.WELCOME_ALERT_DESCRIPTION.id,
                defaultMessage: TRANSLATED_CONSTANTS.WELCOME_ALERT_DESCRIPTION.defaultMessage
              },
              { name: response?.data.register.user.username }
            ),
            link: ROUTES.CREATE_ACOUNT_ROUTE,
            confirmTitle: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_CONFIRM_BUTTON.id,
              defaultMessage:
                TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_CONFIRM_BUTTON.defaultMessage
            }),
            cancelTitle: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.DISMISS.id,
              defaultMessage: TRANSLATED_CONSTANTS.DISMISS.defaultMessage
            }),
            image: dogeIcon
          })
        );
      }
    } catch (error) {
      setShowAlert(true);
      SetAlertType('error');

      if (error instanceof ApolloError) {
        // Handle GraphQL errors here
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
    } finally {
      setSubmiting(false);
    }
  };

  return (
    <Modal
      onCancel={() => onCloseSignupModal(false)}
      onGoogleConfirm={onGoogleConfirm}
      isOpen={isOpen}
      onChangeType={onChangeType}
      isConfirm
      confirmText={TRANSLATED_CONSTANTS.SIGN_UP}
      cancelText={TRANSLATED_CONSTANTS.SIGNUP_MODAL_GOOGLE_CONFIRM_BUTTON}
      title={TRANSLATED_CONSTANTS.SIGN_UP}
      subTitle={TRANSLATED_CONSTANTS.SIGNUP_MODAL_SUBTITLE}
      cancelBtnDisabled={!isVerified}
      confirmBtnDisabled={!isVerified}
      actionButtonsFlow="vertical"
      theme="primary"
      headerType="left"
      buttonIcon={<img src={googleIcon}></img>}
      containsLogo={true}
      type="signup"
      formId="signup"
      isSubmiting={submiting}>
      <div>
        <InlineAlert
          state={showAlert}
          onChangeState={setShowAlert}
          type={alertType}
          className="mt-4"
          text={alertMessage}></InlineAlert>
        <form id="signup" action="#" method="POST" onSubmit={handleSubmit(onSignup)}>
          <div className="w-full mt-5">
            <InputField
              name="email"
              label={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.EMAIL.id,
                defaultMessage: TRANSLATED_CONSTANTS.EMAIL.defaultMessage
              })}
              placeholder={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.id,
                defaultMessage: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.defaultMessage
              })}
              type="email"
              register={register}
              errors={errors?.email}
              required={true}
            />
          </div>
          <div className="w-full  mt-4">
            <InputField
              name="password"
              label={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.PASSWORD.id,
                defaultMessage: TRANSLATED_CONSTANTS.PASSWORD.defaultMessage
              })}
              type="password"
              placeholder={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.PASSWORD.id,
                defaultMessage: TRANSLATED_CONSTANTS.PASSWORD.defaultMessage
              })}
              register={register}
              hintText={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.id,
                defaultMessage:
                  TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.defaultMessage
              })}
              errors={errors?.password}
              required={true}
            />
          </div>
          {referralCodeExists && (
            <div className="w-full mt-4">
              <InputField
                placeholder="Paste your code here"
                name="referralCode"
                label={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.PASTE_CODE.id,
                  defaultMessage: TRANSLATED_CONSTANTS.PASTE_CODE.defaultMessage
                })}
                type="text"
                register={register}
              />
            </div>
          )}
          <div className="flex justify-between w-full items-center  mt-4 ">
            <div className="flex justify-start items-center">
              <CheckboxField
                size="sm"
                name="check"
                value="i am 18 + years old"
                label={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.I_AM_18_YEARS_OLD.id,
                  defaultMessage: TRANSLATED_CONSTANTS.CREATE_PASSWORD_PLACEHOLDER.defaultMessage
                })}
                isSelected={isVerified}
                type="checkbox"
                disabled={false}
                register={register}
                onChange={(e) => onAgeChecked(e)}></CheckboxField>
              <div className="ml-2 -mb-1">
                <Tooltip
                  title={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.YOU_MUST_BE_18.id,
                    defaultMessage: TRANSLATED_CONSTANTS.YOU_MUST_BE_18.defaultMessage
                  })}
                  theme="dark"
                  arrow="bottom-center">
                  <button>
                    <HelpIcon className="text-gray-500"></HelpIcon>
                  </button>
                </Tooltip>
              </div>
            </div>
            <Button
              onClick={() => setReferralCodeExists(!referralCodeExists)}
              size="md"
              theme="gray-link">
              <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.REFERRAL_CODE.id}
                  defaultMessage={TRANSLATED_CONSTANTS.REFERRAL_CODE.defaultMessage}
                />
              </Typo.textSm>
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default SignupModal;
