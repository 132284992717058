import React, { FC, ReactNode } from 'react';
type Props = {
  children: ReactNode;
};
const TableContainer: FC<Props> = ({ children }) => {
  return (
    <div className="w-full bg-white rounded-lg md:shadow  md:border md:border-gray-200 flex-col">
      {children}
    </div>
  );
};

export default TableContainer;
