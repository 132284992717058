import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useEffect } from 'react';
import CloseIcon from '../icons/CloseIcon';
import Badge from '../shared/Badge';
import BellIcon from '../icons/BellIcon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ChatState } from '../../context/ChatNotificationProvider';
import Divider from '../shared/Divider';
import { MessageNotification } from '../../model/Message';
import { AuthUserState } from '../../context/AuthUserProvider';
import { useGetNotifications } from '../../hooks/notification/useGetNotifications';
import { useUpdateNotification } from '../../hooks/notification/useUpdateNotification';
import { useDispatch } from 'react-redux';
import { setChatData, showSlide } from '../../lib/redux/reducers/slide.slice';
import NotificationItem from './NotificationItem';

const NotificationsList: React.FC = () => {
  const dispatch = useDispatch();
  const { me } = AuthUserState();
  const updateNotification = useUpdateNotification();
  const { socket } = ChatState();
  const { notificationsFromDb, error, loading, fetchMore, updateQuery } = useGetNotifications(
    me?.id,
    0,
    -1
  );
  useEffect(() => {
    if (socket) {
      socket.on('message notification', (notification: MessageNotification) => {
        const newNotification = {
          id: notification.notificationId,
          attributes: {
            content: notification.message.content,
            isRead: notification.message.isRead,
            count: notification.message.count,
            publishedAt: notification.message.dateTime,
            chat: {
              data: {
                id: notification.message.chatId
              }
            },
            receiver: {
              data: {
                id: notification.receiver.id,
                attributes: {
                  name: notification.receiver.name,
                  username: notification.receiver.username,
                  profileImage: {
                    data: {
                      url: notification.receiver.profileImage?.url
                    }
                  }
                }
              }
            },
            sender: {
              data: {
                id: notification.sender.id,
                attributes: {
                  name: notification.sender.name,
                  username: notification.sender.username,
                  profileImage: {
                    data: {
                      url: notification.sender.profileImage?.url
                    }
                  }
                }
              }
            }
          }
        };
        updateQuery((prevResult) => {
          const existingNotification = prevResult.notifications.data.find(
            (n: { id: number }) => n.id === newNotification.id
          ); // If there is one, update it
          if (existingNotification) {
            return {
              ...prevResult,
              notifications: {
                ...prevResult.notifications,
                data: prevResult.notifications.data.map((n: { id: number }) =>
                  n.id === newNotification.id ? newNotification : n
                )
              }
            };
          } else if (prevResult.notifications.data && prevResult.notifications.data.length > 0) {
            return {
              ...prevResult,
              notifications: {
                ...prevResult.notifications,
                data: [...prevResult.notifications.data, newNotification]
              }
            };
          } else {
            return {
              ...prevResult,
              notifications: { ...prevResult.notifications, data: [newNotification] }
            };
          }
        });
      });
    }
  }, [socket]);

  const openMessage = async (notification: MessageNotification) => {
    if (notification.message.isRead === false) {
      await updateNotification({
        variables: {
          data: {
            isRead: true,
            count: 1
          },
          id: notification.notificationId
        }
      });
    }
    const messageInfo = {
      sender: notification.receiver,
      receiver: notification.sender
    };
    dispatch(setChatData(messageInfo));
    dispatch(showSlide('message'));
  };
  return (
    <Menu as="div" className="sm:relative inline-block text-left">
      <div className="flex items-center">
        <Menu.Button className="">
          <div className="p-2.5">
            <BellIcon className="text-gray-500"></BellIcon>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute right-0 z-20 mt-2  w-full  sm:w-fit sm:min-w-[400px]  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
          <div className="w-full h-[54px]  justify-center items-end gap-2 inline-flex mb-4 px-6">
            <div className="grow shrink basis-0 h-[54px] pt-6 justify-start items-center gap-2 flex">
              <div className="flex-col justify-start items-start gap-1 inline-flex">
                <div className="text-gray-900 text-[20px] font-semibold leading-loose">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.NOTIFICATIONS.id}
                    defaultMessage={TRANSLATED_CONSTANTS.NOTIFICATIONS.defaultMessage}
                  />
                </div>
              </div>
              <Badge label={notificationsFromDb?.length} theme="gray" shape="circle" size="sm" />
            </div>
            <Menu.Item>
              <div className="w-6 h-10  justify-center items-center flex">
                <CloseIcon className="stroke-gray-500 cursor-pointer" />
              </div>
            </Menu.Item>
          </div>

          <div className="max-h-[600px] overflow-y-auto px-6">
            {notificationsFromDb?.map((notification: any, index: number) => {
              return (
                <Menu.Item key={index}>
                  <div className="">
                    <Divider />
                    <div className="cursor-pointer" onClick={() => openMessage(notification)}>
                      <NotificationItem {...notification} />
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default NotificationsList;
