/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import Loader from '../../../../components/shared/Loader';
import StorefrontDetailedLayout from './StorefrontDetailedLayout';
import { REPORT_PAGES } from '../../../../constants/data';
import RedActionModal from '../../../../components/modal/RedActionModal';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import UserXIcon from '../../../../components/icons/UserXIcon';
import PrimaryActionModal from '../../../../components/modal/PrimaryActionModal';
import TagIcon from '../../../../components/icons/TagIcon';
import { useHandleIssuedListings } from '../../../../hooks/useHandleIssuedListings';
import EmptyReportListings from '../../EmptyReportListings';
import TrashIcon from '../../../../components/icons/TrashIcon';
import { useHandleStorefrontFlaggedReports } from '../../../../hooks/useHandleStorefrontFlaggedReports';
import { useGetDetailedQuarantineStorefronts } from '../../../../hooks/reports/useGetDetailedQuarantinStorefront';
import { ActivityItemPropsType } from '../../../../model/Activity';
import { handleReportActivityInfo } from '../../../../util/ReportUtil';
import { useIntl } from 'react-intl';

const QuarantineStorefrontsDetailedLayout = () => {
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const selectedGridRow = useAppSelector((state) => state.report.reportLayout);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const intl = useIntl();
  const [isQuarantineFetched, setIsQuarantineFetched] = useState(false);
  const [numberOfFlags, setNumberOfFlags] = useState(0);
  const [numberOfWarnings, setNumberOfWarnings] = useState(0);
  const [activities, setActivities] = useState<ActivityItemPropsType[]>([]);
  const { quarantineStoreFronts, quarantineStorefrontsCount, loading, error, refetch, fetchMore } =
    useGetDetailedQuarantineStorefronts(offset, 1, searchInput);
  const activityHeader = handleReportActivityInfo(REPORT_PAGES.LISTING_QUARANTINE, intl);
  const { onRestoreButtonClicked, showReslistItemModal, onCloseRelistModalModal } =
    useHandleIssuedListings(refetch);
  const {
    showBanModal,
    onBanUserButtonClicked,
    onCloseBanModal,
    showWarnAndDeleteModal,
    onWarnUserButtonClicked,
    onCloseWarnAndDeleteModal,
    showBanAndDeleteModal,
    onCloseDetailedBanAndDeleteModal,
    onDetailedBanDeleteUserButtonClicked
  } = useHandleStorefrontFlaggedReports(refetch);
  const fetchMoreData = (pageNumber: number) => {
    const startIndex = pageNumber - 1;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: 1 }
    });
    setIsQuarantineFetched(false);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (quarantineStoreFronts && quarantineStoreFronts?.length > 0 && !isQuarantineFetched) {
      setIsQuarantineFetched(true);
      const [flags, warnings] = quarantineStoreFronts[0]?.user?.listings?.reduce(
        ([totalFlags, totalWarnings]: any, listing: any) => {
          const flags = totalFlags + (listing?.listing_reports?.length ?? 0);
          const warnings = totalWarnings + (listing?.issued_listing != null ? 1 : 0);
          return [flags, warnings];
        },
        [0, 0] // Initial values
      );

      const [storefrontsFlags, storefrontsWarnings] =
        quarantineStoreFronts[0]?.user?.store_fronts?.reduce(
          ([totalFlags, totalWarnings]: any, storefront: any) => {
            const flags = totalFlags + (storefront?.storefront_reports?.length ?? 0);
            const warnings = totalWarnings + (storefront?.issued_listing != null ? 1 : 0);
            return [flags, warnings];
          },
          [0, 0] // Initial values
        );

      setNumberOfFlags(flags + storefrontsFlags);
      setNumberOfWarnings(warnings + storefrontsWarnings);

      setActivities(
        quarantineStoreFronts[0]?.storefront_reports?.map((report: any) => {
          return {
            name: report?.reporter?.name,
            username: report?.reporter?.username,
            time: report?.date,
            claim: report?.claim,
            detail: report?.detail,
            type: 'quarantine',
            opened: true,
            userId: report?.reporter?.id,
            userImageUrl: report?.reporter?.profileImage?.url
          };
        })
      );
    }
  }, [quarantineStoreFronts]);

  useEffect(() => {
    setOffset(selectedGridRow.index);
    setCurrentPage(selectedGridRow.index + 1);
  }, [selectedGridRow]);

  return (
    <div>
      {quarantineStoreFronts?.length === 0 && !loading ? (
        <EmptyReportListings />
      ) : (
        <div>
          {loading ? (
            <Loader className="h-screen" />
          ) : quarantineStoreFronts && quarantineStoreFronts.length > 0 ? (
            <StorefrontDetailedLayout
              pageType={REPORT_PAGES.LISTING_QUARANTINE}
              data={quarantineStoreFronts}
              activityData={activities || []}
              activityHeader={activityHeader}
              listingReportsCount={quarantineStorefrontsCount}
              numberOfFlags={numberOfFlags}
              numberOfWarnings={numberOfWarnings}
              fetchMoreData={fetchMoreData}
              currentPage={currentPage}
              openBanModal={onBanUserButtonClicked}
              openRestoreModal={onRestoreButtonClicked}
              openDeleteAndWarnModal={onWarnUserButtonClicked}
              openBanAndDeleteModal={onDetailedBanDeleteUserButtonClicked}
            />
          ) : (
            <></>
          )}
        </div>
      )}

      <RedActionModal
        title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
        isOpen={showBanModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
        username={quarantineStoreFronts && quarantineStoreFronts[0]?.user?.username}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
        isOpen={showReslistItemModal}
        onClosePrimaryActionModal={(value: boolean) =>
          onCloseRelistModalModal(value, 'listing-quarantine')
        }
        subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_CONFIRM_BUTTON}
        icon={<TagIcon />}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarnAndDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseWarnAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
      <RedActionModal
        title={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_TITLE}
        isOpen={showBanAndDeleteModal}
        onCloseRedActionModal={(value: boolean) => onCloseDetailedBanAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default QuarantineStorefrontsDetailedLayout;
