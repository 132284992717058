import clsx from 'clsx';
import React, { useRef } from 'react';
import { Typo } from '../Typo';

type Props = {
  id?: string;
  value?: string;
  errors?: any;
  placeholder?: string;
  register: (name: any, registerOptions: any) => any;
  onChange?: (option: any) => void;
  isSelected?: boolean;
  name?: string;
  size?: 'sm' | 'md';
  type?: 'checkbox' | 'radio';
  shape?: 'circle' | 'square';
  label?: any;
  description?: string;
  registerOptions?: any;
  disabled?: boolean;
  className?: string;
  verificationtext?: string;
  indeterminate?: boolean;
  labelColor?: string;
  isGroupItem?: boolean;
  roundedSize?: string;
  [x: string]: any;
};

const CheckboxField: React.FC<Props> = (props) => {
  const {
    id = '',
    value,
    errors,
    register,
    onChange,
    isSelected,
    name,
    registerOptions = {},
    type = 'checkbox',
    size = 'md',
    shape = 'square',
    label = '',
    description = '',
    isGroupItem = false,
    indeterminate = false,
    disabled = false,
    labelColor,
    roundedSize
  } = props;
  const checkboxRef = useRef<any>();
  // useEffect(() => {
  //   checkboxRef.current.indeterminate = indeterminate;
  // }, [indeterminate]);
  return (
    <div>
      <div className="mt-1 relative">
        <div className="relative flex items-center">
          <div className="flex flex-shrink-0 justify-center items-center ">
            <input
              id={id}
              {...register(name, registerOptions)}
              type={type}
              value={value}
              // ref={checkboxRef}
              onChange={onChange}
              checked={isSelected}
              disabled={disabled}
              className={clsx(
                size == 'md' ? ' w-5 h-5 ' : ' h-4 w-4 ',
                errors?.message == null && !isGroupItem
                  ? ' border border-gray-300 bg-white hover:border-yellow-600 hover:bg-yellow-100 focus:border-yellow-300 focus:right-4 focus:ring-yellow-300 focus:ring-offset-0  indeterminate:border-yellow-600 indeterminate:bg-yellow-50 indeterminate:hover:border-yellow-600 indeterminate:hover:bg-yellow-100 indeterminate:focus:border-yellow-600 indeterminate:focus:bg-yellow-50 indeterminate:focus:ring-4 indeterminate:focus:ring-yellow-300 indeterminate:disabled:border-gray-300 indeterminate:disabled:bg-gray-100 checked:border-yellow-600 checked:focus:ring-4 checked:focus:ring-yellow-300 checked:focus:ring-offset-0 checked:disabled:border-gray-300 checked:disabled:bg-gray-100 disabled:hover:bg-gray-100 disabled:hover:border-gray-300 disabled:cursor-not-allowed '
                  : ' ',
                type === 'radio' || (type == 'checkbox' && shape == 'circle')
                  ? ' rounded-full '
                  : roundedSize
                  ? roundedSize
                  : ' rounded ',
                shape == 'circle' && type == 'checkbox'
                  ? ' checked:bg-yellow-600 checked:hover:border-yellow-700 checked:hover:bg-yellow-700 checked:hover:ring-0 checked:focus:border-yellow-600 checked:focus:bg-yellow-600 '
                  : '  checked:border-yellow-600 checked:bg-yellow-50 checked:hover:border-yellow-600 checked:hover:bg-yellow-100 checked:hover:ring-0 checked:focus:border-yellow-600 checked:focus:bg-yellow-50 disabled:border-gray-300  ',
                'peer cursor-pointer'
              )}
            />
            {type == 'checkbox' && shape == 'square' && (
              <svg
                width={`${size === 'md' ? '14' : '12'}`}
                height={`${size === 'md' ? '14' : '12'}`}
                className=" absolute pointer-events-none stroke-yellow-600 hidden peer-checked:block peer-disabled:stroke-gray-300 peer-checked:disabled:stroke-gray-300 peer-checked:focus:disabled:stroke-gray-300"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d={`${
                    size === 'md' ? 'M11.6668 3.5L5.25016 9.91667L2.3335 7' : 'M10 3L4.5 8.5L2 6'
                  }`}
                  strokeWidth={`${size === 'md' ? '2' : '1.6666'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {type == 'checkbox' && shape == 'square' && (
              <svg
                width={`${size === 'md' ? '14' : '12'}`}
                height={`${size === 'md' ? '14' : '12'}`}
                className=" absolute stroke-yellow-600 pointer-events-none hidden peer-indeterminate:block peer-disabled:stroke-gray-300 peer-checked:disabled:stroke-gray-300 peer-checked:focus:disabled:stroke-gray-300"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d={`${size === 'md' ? 'M1.8 7H12.1' : 'M2.1 5.9H10.1'}`}
                  strokeWidth={`${size === 'md' ? '2.5' : '2'}`}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {type == 'radio' && (
              <svg
                width={`${size == 'md' ? '20' : '16'}`}
                height={`${size == 'md' ? '20' : '16'}`}
                className=" absolute pointer-events-none  hidden peer-checked:block peer-disabled:fill-gray-300 peer-checked:disabled:fill-gray-300 peer-checked:focus:disabled:fill-gray-300"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect
                  x="0.5"
                  y="0.5"
                  width={`${size == 'md' ? '19' : '15'}`}
                  height={`${size == 'md' ? '19' : '15'}`}
                  rx={`${size == 'md' ? '9.5' : '7.5'}`}
                  fill="#FFF9EB"
                />
                <circle
                  cx={`${size == 'md' ? '10' : '8'}`}
                  cy={`${size == 'md' ? '10' : '8'}`}
                  r={`${size == 'md' ? '4' : '3'}`}
                  fill="#B08503"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width={`${size == 'md' ? '19' : '15'}`}
                  height={`${size == 'md' ? '19' : '15'}`}
                  rx={`${size == 'md' ? '9.5' : '7.5'}`}
                  stroke="#B08503"
                />
              </svg>
            )}
          </div>
          <div className="ml-2">
            <Typo.textSm
              fontWeight="font-medium"
              className={disabled ? 'text-gray-300' : labelColor ? labelColor : 'text-gray-900'}>
              <label htmlFor={id}>{label}</label>
            </Typo.textSm>
            <div id="candidates-description">
              <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                {description}
              </Typo.textSm>
            </div>
          </div>
        </div>
      </div>
      {errors?.message && !isGroupItem && (
        <p className="mt-2 text-sm text-error-600">{errors?.message}</p>
      )}
    </div>
  );
};

export default CheckboxField;
