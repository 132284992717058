import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, useState } from 'react';
import { Typo } from '../Typo';
import Button from '../shared/Button';
import { useDispatch } from 'react-redux';
import { setAge } from '../../lib/redux/reducers/signup.slice';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { DayPicker } from 'react-day-picker';
import '../../../src/style/calander.css';
import 'react-day-picker/dist/style.css';

type Porps = {
  isOpen: boolean;
  onCancelDatePicker: () => void;
};

const DatePickerModal: FC<Porps> = ({ isOpen, onCancelDatePicker }) => {
  const intl = useIntl();
  const [selected, setSelected] = useState<Date>();
  const dispatch = useDispatch();
  const onApproveDate = () => {
    dispatch(setAge(selected?.toDateString()));
    onCancelDatePicker();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onCancelDatePicker}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 w-full  md:max-w-[350px] ">
                <div>
                  <div className="w-full flex justify-center">
                    <DayPicker
                      mode="single"
                      selected={selected}
                      showOutsideDays
                      fromYear={1970}
                      toYear={2024}
                      captionLayout="dropdown-buttons"
                      fixedWeeks
                      onSelect={setSelected}
                      modifiersClassNames={{
                        selected: 'my-selected',
                        today: 'my-today'
                      }}
                      modifiersStyles={{
                        disabled: { fontSize: '75%' }
                      }}
                    />
                  </div>

                  <div className="flex gap-x-3 p-4 border-t border-t-gray-200">
                    <Button onClick={onCancelDatePicker} size="md" theme="base" className="flex-1">
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CANCEL.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                        })}
                      </Typo.textSm>
                    </Button>
                    <Button
                      onClick={onApproveDate}
                      size="md"
                      theme="primary-yellow"
                      className="flex-1">
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.APPLY.id,
                          defaultMessage: TRANSLATED_CONSTANTS.APPLY.defaultMessage
                        })}
                      </Typo.textSm>
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DatePickerModal;
