// typePolicies.ts
import { FieldPolicy } from '@apollo/client';

// Define your field policies for each paginated field here
const messagesFieldPolicy: FieldPolicy = {
  // Specify which arguments should be used to identify each page
  keyArgs: ['filters', 'sort'],
  // Specify how to merge multiple pages into one list
  merge(existing = [], incoming) {
    // Concatenate the incoming messages to the existing ones
    if (incoming && incoming.data) {
      return [...existing, ...incoming.data];
    } else {
      return existing;
    }
  }
};

const chatsFieldPolicy: FieldPolicy = {
  // Specify which arguments should be used to identify each page
  keyArgs: ['filters', 'sort'],
  // Specify how to merge multiple pages into one list
  merge(existing = [], incoming) {
    // Check if incoming is an object with a data property
    if (incoming && incoming.data) {
      // Concatenate the incoming data array to the existing array
      return [...existing, ...incoming.data];
    } else {
      // Return the existing array as it is
      return existing;
    }
  }
};
const mapListingsFieldPolicy: FieldPolicy = {
  // Specify which arguments should be used to identify each page
  keyArgs: ['filters', 'sort'], // Specify how to merge multiple pages into one list
  merge(existing = [], incoming) {
    // Check if incoming is an object with a data property
    if (incoming && incoming.data) {
      // Concatenate the incoming data array to the existing array
      return [...existing, ...incoming.data];
    } else {
      // Return the existing array as it is
      return existing;
    }
  }
};
const mapStorefrontsFieldPolicy: FieldPolicy = {
  // Specify which arguments should be used to identify each page
  keyArgs: ['filters', 'sort'], // Specify how to merge multiple pages into one list
  merge(existing = [], incoming) {
    // Check if incoming is an object with a data property
    if (incoming && incoming.data) {
      // Concatenate the incoming data array to the existing array
      return [...existing, ...incoming.data];
    } else {
      // Return the existing array as it is
      return existing;
    }
  }
};
const mapListingsWithListingReportsFieldPolicy: FieldPolicy = {
  // Specify which arguments should be used to identify each page
  keyArgs: ['filters', 'sort'], // Specify how to merge multiple pages into one list
  merge(existing = [], incoming) {
    // Check if incoming is an object with a data property
    if (incoming && incoming.data) {
      // Concatenate the incoming data array to the existing array
      return [...existing, ...incoming.data];
    } else {
      // Return the existing array as it is
      return existing;
    }
  }
};

// Export an object with all your type policies
export const typePolicies = {
  Query: {
    fields: {
      messages: messagesFieldPolicy,
      chats: chatsFieldPolicy,
      listings: mapListingsFieldPolicy,
      storeFronts: mapStorefrontsFieldPolicy,
      listingsWithListingReports: mapListingsWithListingReportsFieldPolicy
    }
  }
};
