import React from 'react';
import { Typo } from '../Typo';
import { NavigationModel } from '../../model/Navigation';
import { Link } from 'react-router-dom';
type PriceProps = {
  navigation: NavigationModel;
  isActive: boolean;
  className?: string;
};
const SettingNavItem: React.FC<PriceProps> = (props) => {
  const { navigation, isActive, className = '' } = props;

  return (
    <Link
      to={navigation?.link || ''}
      className={`flex gap-x-2 items-center px-3 py-2 rounded-md  ${
        isActive
          ? 'focus:bg-gray-50 focus:ring-4 focus:ring-gray-100 hover:bg-gray-100  bg-gray-50'
          : 'focus:bg-white focus:ring-4 focus:ring-gray-100 hover:bg-gray-50  bg-white'
      } ${className}`}>
      {navigation.icon}
      <Typo.textMd
        fontWeight="font-medium"
        className={`${
          isActive ? ' text-gray-900' : ' text-gray-700 hover:text-gray-900 focus:text-gray-700'
        }`}>
        {navigation.title}
      </Typo.textMd>
    </Link>
  );
};

export default SettingNavItem;
