// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-selected:not([disabled]) {
  font-weight: medium;
  color: white;
  background-color: #b08503;
}

.my-selected:hover {
  font-weight: medium;
  background-color: #886307 !important;
  color: white;
}
.my-today {
  font-weight: medium;
  font-size: 140%;
  color: #344054 !important;
  background-color: #f2f4f7 !important;
}

.my-today:hover {
  background-color: #f9fafb !important;
}
`, "",{"version":3,"sources":["webpack://./src/style/calander.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;EACnB,oCAAoC;EACpC,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".my-selected:not([disabled]) {\r\n  font-weight: medium;\r\n  color: white;\r\n  background-color: #b08503;\r\n}\r\n\r\n.my-selected:hover {\r\n  font-weight: medium;\r\n  background-color: #886307 !important;\r\n  color: white;\r\n}\r\n.my-today {\r\n  font-weight: medium;\r\n  font-size: 140%;\r\n  color: #344054 !important;\r\n  background-color: #f2f4f7 !important;\r\n}\r\n\r\n.my-today:hover {\r\n  background-color: #f9fafb !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
