import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { transformListingsReports, transformStorefrontReports } from './transform';

export const GET_STOREFRONT_DETAILED_REPORTS = gql`
  query getListingReports($offset: Int, $limit: Int, $searchText: String) {
    storefrontReportsCount: storefrontWithReports(searchText: $searchText) {
      meta {
        pagination {
          total
        }
      }
    }
    storefrontWithReports(pagination: { limit: $limit, start: $offset }, searchText: $searchText) {
      data {
        id
        attributes {
          name
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
              }
            }
          }
          description
          user {
            data {
              id
              attributes {
                username
                name
                type
                email
                createdAt
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                store_fronts(filters: { storefront_reports: { id: { ne: null } } }) {
                  data {
                    attributes {
                      storefront_reports {
                        data {
                          id
                          attributes {
                            reporter {
                              data {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          storefront_reports {
            data {
              id
              attributes {
                claim
                detail
                date
                reporter {
                  data {
                    id
                    attributes {
                      name
                      username
                      type
                      email
                      profileImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetStorefrontDetailedReports = (
  offset: number,
  limit: number,
  searchText: string
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_STOREFRONT_DETAILED_REPORTS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  const flattenData = flattenObj(data?.storefrontWithReports);
  const formattedReports = transformStorefrontReports(flattenData ? flattenData[0] : undefined);

  return {
    storefrontReport: formattedReports,
    storefrontReportsCount: data?.storefrontReportsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
