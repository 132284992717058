import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { ROLES } from '../../constants/data';

export const GET_USER_PROFILE = gql`
  query getUserProfile($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          name
          username
          blocked
          phone
          type
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          sharingPoints
          referralCode
          decisions
          correctDecisions
          profileCompleted
          confirmed
          profileCompleted
          createdAt
          countryName
          countryCode
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          language_proficiencies {
            data {
              id
              attributes {
                level
                language {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    warnedCounts: warnedUsers(filters: { warned: { id: { eq: $id } } }) {
      meta {
        pagination {
          total
        }
      }
    }
    reportCounts: listingReports(filters: { listing: { user: { id: { eq: $id } } } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
export const GET_USER_PROFILE_SUPER_ADMIN = gql`
  query getUserProfile($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          name
          username
          email
          blocked
          phone
          type
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          sharingPoints
          referralCode
          decisions
          correctDecisions
          profileCompleted
          confirmed
          profileCompleted
          createdAt
          countryName
          countryCode
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          language_proficiencies {
            data {
              id
              attributes {
                level
                language {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    warnedCounts: warnedUsers(filters: { warned: { id: { eq: $id } } }) {
      meta {
        pagination {
          total
        }
      }
    }
    reportCounts: listingReports(filters: { listing: { user: { id: { eq: $id } } } }) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;
export const GET_PUBLIC_USER_PROFILE = gql`
  query getUserProfile($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          name
          username
          blocked
          phone
          type
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          sharingPoints
          referralCode
          decisions
          correctDecisions
          profileCompleted
          confirmed
          profileCompleted
          createdAt
          countryName
          countryCode
          language_proficiencies {
            data {
              id
              attributes {
                level
                language {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetProfile = (id: number, authUser: any) => {
  const skip = authUser || authUser?.role?.name === ROLES.SUPER_ADMIN ? false : true;

  const { loading, error, data, refetch } = useQuery(
    !authUser
      ? GET_PUBLIC_USER_PROFILE
      : authUser?.role?.name === ROLES.SUPER_ADMIN
      ? GET_USER_PROFILE_SUPER_ADMIN
      : GET_USER_PROFILE,
    {
      variables: { id, skip },
      fetchPolicy: 'cache-and-network'
    }
  );
  return {
    user: flattenObj(data?.usersPermissionsUser),
    reportCounts: data?.reportCounts?.meta?.pagination.total || 0,
    warnedCounts: data?.warnedCounts?.meta?.pagination.total || 0,
    refetch,
    loading,
    error
  };
};
