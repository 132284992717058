import { FC, createContext, useContext, useEffect, useState } from 'react';
import { getDoge } from '../util/GetDoge';

type Props = {
  children: React.ReactElement;
};

type PriceContextType = {
  dogePrice: number;
};

const PriceContext = createContext<PriceContextType>(null!);

const PriceProvider: FC<Props> = ({ children }) => {
  const [dogePrice, setDogePrice] = useState<any>();

  const fetchDogePrice = async () => {
    const doge = await getDoge();
    setDogePrice(doge);
  };

  useEffect(() => {
    // Initial fetch
    fetchDogePrice();

    // Schedule the repeated fetch every 10 seconds
    const intervalId = setInterval(fetchDogePrice, 10000);

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return <PriceContext.Provider value={{ dogePrice }}>{children}</PriceContext.Provider>;
};

export const PriceState = () => {
  const price = useContext(PriceContext);
  return price;
};

export default PriceProvider;
