export const customizeMessage = (message: {
  id: string;
  sender: { id: string };
  receiver: { id: string };
  content: string;
  publishedAt: string;
}) => {
  return {
    id: Number(message?.id),
    senderId: Number(message.sender?.id),
    receiverId: Number(message.receiver?.id),
    content: message.content,
    dateTime: message.publishedAt
  };
};

export const customizeChats = (chats: any, loggedInUser: string) => {
  let username = '';
  let name = '';
  let image = '';
  return chats?.map((chat: any) => {
    if (chat?.firstUser?.id === loggedInUser) {
      username = chat.secondUser?.username;
      name = chat.secondUser?.name;
      image = chat.seconUser?.profileImage?.url ? chat.seconUser?.profileImage?.url : '';
    } else if (chat.secondUser.id === loggedInUser) {
      username = chat.firstUser?.username;
      name = chat.firstUser?.name;
      image = chat.firstUser?.profileImage?.url ? chat.firstUser?.profileImage?.url : '';
    }

    return {
      userId:
        chat?.firstUser?.id === loggedInUser
          ? Number(chat?.secondUser?.id)
          : Number(chat?.firstUser?.id),
      userType:
        chat?.firstUser?.id === loggedInUser ? chat?.secondUser?.type : chat?.firstUser?.type,
      id: Number(chat.id),
      content: chat.messages?.length > 0 ? chat.messages[0].content : '',
      dateTime: chat.publishedAt,
      profileImage: image,
      name: name,
      username: username
    };
  });
};
