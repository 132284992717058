import { createSlice } from '@reduxjs/toolkit';

const emailSlice = createSlice({
  name: 'email',
  initialState: {
    email: ''
  },
  reducers: {
    setEmailData: (state, action) => {
      state.email = action.payload;
    }
  }
});

export const { setEmailData } = emailSlice.actions;
export default emailSlice.reducer;
