import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Typo } from '../../../Typo';
import { ApiSearchOrderEnum } from '../../../../util/enum/enum';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import { useGetStorefronts } from '../../../../hooks/storfront/useGetStorefronts';
import Button from '../../../shared/Button';
import ChevronIcon from '../../../icons/ChevronIcon';
import Image from '../../../shared/Image';
import OperationHourLabel from '../../../shared/Hours Operation/OperationHourLabel';
import Address from '../../../shared/Address';
import { Storefront } from '../../../../model/Storefront';
import ProfileStorefrontItems from './ProfileStorefrontItems';
import Loader from '../../../shared/Loader';
import Divider from '../../../shared/Divider';
type Props = {
  className?: string;
  userId: number;
  profileId: number;
};

const ProfileStorefronts: React.FC<Props> = (props) => {
  const { userId, profileId } = props;
  const [selectedStorefront, setSelectedStorefront] = useState<Storefront>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [fetched, setFetched] = useState(false);
  const { storeFronts, total, loading } = useGetStorefronts(
    profileId,
    -1,
    0,
    ApiSearchOrderEnum.PUBLISHED_DESC
  );

  useEffect(() => {
    if (storeFronts && storeFronts?.length > 0 && !fetched) {
      setFetched(true);
      setSelectedStorefront(storeFronts[0]);
    }
  }, [storeFronts]);

  const onChangeStorefront = (index: number) => {
    setSelectedIndex(index);
    setSelectedStorefront(storeFronts[index]);
  };

  return (
    <div id="profileStorefrontItemsContent" className=" h-screen overflow-y-scroll pb-24 space-y-4">
      <div>
        <div className="flex justify-between items-end">
          <div>
            <Typo.textSm fontWeight="font-medium" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.STOREFRONT.id}
                defaultMessage={TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage}
              />
            </Typo.textSm>
            <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
              {selectedStorefront ? selectedStorefront?.name : ''}
            </Typo.textSm>
          </div>

          {storeFronts && total > 1 ? (
            <div className="flex gap-x-3">
              <Button
                circle={true}
                className="border border-gray-200 w-9 h-9 disabled:bg-gray-50 text-gray-700 disabled:text-gray-400"
                size="md"
                onClick={() => onChangeStorefront(selectedIndex - 1)}
                disabled={selectedIndex == 0}
                icon={<ChevronIcon className=" -rotate-90 text-gray-700" />}></Button>
              <Button
                size="md"
                onClick={() => onChangeStorefront(selectedIndex + 1)}
                circle={true}
                disabled={selectedIndex == total - 1 || total < 2}
                className="border border-gray-200 w-9 h-9 disabled:bg-gray-50 text-gray-700 disabled:text-gray-400"
                icon={<ChevronIcon className=" rotate-90" />}></Button>
            </div>
          ) : (
            ''
          )}
        </div>
        {!selectedStorefront && loading ? (
          <Loader />
        ) : (
          selectedStorefront && (
            <div>
              <Image
                className=" h-[212px] sm:h-[236px] object-cover w-full rounded-lg my-4"
                src={selectedStorefront?.image[0].url}></Image>
              <div className="space-y-2 sm:space-y-0 sm:flex sm:gap-x-6">
                <OperationHourLabel
                  defaultValue={selectedStorefront?.operationHours}></OperationHourLabel>
                <Address address={selectedStorefront?.location?.address || ''}></Address>
              </div>
              <Divider className=" my-6 text-gray-200" />
            </div>
          )
        )}
        <div className=" space-y-6">
          {selectedStorefront ? (
            <ProfileStorefrontItems storefront={selectedStorefront} userId={userId} />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileStorefronts;
