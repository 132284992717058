import imgEmpty from '../../assets/imgEmpty.svg';
import React from 'react';
type ImagePorps = {
  alt?: string;
  src?: string | null | undefined;
  className?: string;
};
const Image: React.FC<ImagePorps> = (props) => {
  const { src, alt, className } = props;
  return <img src={src || imgEmpty} alt={alt || ''} className={` object-cover ${className}`} />;
};

export default Image;
