import React, { useState } from 'react';
import SlideScreen from './SlideScreen';
import { useIntl } from 'react-intl';
import { Storefront } from '../../../model/Storefront';
import { handleListingAndStorefrontBadge } from '../../../util/ListingBadgeHandler';
import { AuthUserState } from '../../../context/AuthUserProvider';
import { useGetStorefrontItems } from '../../../hooks/storfront/useGetStorefronts';
import { ListingNew } from '../../../model/Listing';
import InfiniteScroll from 'react-infinite-scroll-component';
import Divider from '../../shared/Divider';
import { Typo } from '../../Typo';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import DotIcon from '../../icons/DotIcon';
import Badge from '../../shared/Badge';
import OperationHourLabel from '../../shared/Hours Operation/OperationHourLabel';
import Address from '../../shared/Address';
import Button from '../../shared/Button';
import StorefrontItemCard from '../../shared/ListingCard/StorefrontItemCard';
import { LISTING_BREAKPOINT, LIST_PAGES } from '../../../constants/data';
import Image from '../../shared/Image';
import Loader from '../../shared/Loader';
import { useHandleSave } from '../../../hooks/useHandleSave';
type Props = {
  open: boolean;
  onClose: () => void;
  className?: string;
  storefront: Storefront;
  onReport?: () => void;
};

const StorefrontSlideScreen: React.FC<Props> = (props) => {
  const { open, onClose, storefront, onReport } = props;
  const LISTING_ITEMS = 5;
  const intl = useIntl();
  const { me } = AuthUserState();
  const [offset, setOffset] = useState(0);
  const listingNameAndThem = handleListingAndStorefrontBadge('storefront', intl);
  const [perPage, setPerPage] = useState(LISTING_ITEMS);
  const { listings, total, fetchMore, refetch } = useGetStorefrontItems(
    me?.id,
    Number(storefront?.id),
    perPage,
    0
  );
  const { save } = useHandleSave(refetch);

  const fetchMoreListing = async () => {
    const newOffset = offset + LISTING_ITEMS;
    const newPerPage = perPage + LISTING_ITEMS;
    await fetchMore({
      variables: { offset: newOffset, limit: LISTING_ITEMS }
    });
    setOffset(newOffset);
    setPerPage(newPerPage);
  };

  return (
    <SlideScreen
      title={intl.formatMessage({
        id: TRANSLATED_CONSTANTS.ITEMS_FOR_SALE.id,
        defaultMessage: TRANSLATED_CONSTANTS.ITEMS_FOR_SALE.defaultMessage
      })}
      className=" w-[350px] sm:w-[552px]"
      open={open}
      setOpen={onClose}>
      <div id="listingContent" className=" h-screen overflow-y-scroll px-6 pb-24 space-y-4">
        <Divider />
        <div>
          <Typo.textSm fontWeight="font-medium" className="text-gray-700">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.STOREFRONT.id,
              defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage
            })}
          </Typo.textSm>
          <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
            {storefront?.name}
          </Typo.textSm>
          <div className=" relative mt-4">
            <Image
              src={storefront?.image[0]?.url}
              className=" rounded-lg object-cover w-full h-[238px]"
            />
            <Badge
              size="sm"
              label={listingNameAndThem.name}
              icon={<DotIcon></DotIcon>}
              className="absolute bottom-2 left-2"
              theme={listingNameAndThem.theme}></Badge>
          </div>
          <div className="my-4 flex gap-x-6 items-center">
            <OperationHourLabel defaultValue={storefront?.operationHours}></OperationHourLabel>
            <Address address={storefront?.location.address || ''}></Address>
          </div>
          <div className="flex gap-x-3">
            <Button
              disabled={
                storefront?.storefront_reports && storefront?.storefront_reports?.length > 0
              }
              onClick={onReport}
              size="md"
              theme="base">
              <Typo.textSm fontWeight="font-medium">
                {intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.REPORT.id,
                  defaultMessage: TRANSLATED_CONSTANTS.REPORT.defaultMessage
                })}
              </Typo.textSm>
            </Button>
            <Button onClick={onClose} size="md" theme="primary-yellow">
              <Typo.textSm fontWeight="font-medium">
                {intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.VIEW_STOREFRONT.id,
                  defaultMessage: TRANSLATED_CONSTANTS.VIEW_STOREFRONT.defaultMessage
                })}
              </Typo.textSm>
            </Button>
          </div>
        </div>
        <Divider />
        <div>
          <Typo.textSm fontWeight="font-medium" className="text-gray-700">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.STOREFRONT_LISTINGS.id,
              defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_LISTINGS.defaultMessage
            })}
          </Typo.textSm>
          <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.VIEW_FOR_SALE.id,
              defaultMessage: TRANSLATED_CONSTANTS.VIEW_FOR_SALE.defaultMessage
            })}
          </Typo.textSm>
          <div className=" space-y-4 pt-6">
            <InfiniteScroll
              dataLength={total}
              next={fetchMoreListing}
              hasMore={total > offset} // You should implement a condition to stop when all data is fetched
              scrollableTarget="listingContent"
              className="space-y-4"
              loader={<Loader className="h-20 mt-6" />}>
              {listings &&
                listings?.map((listing: ListingNew) => {
                  return (
                    <StorefrontItemCard
                      onLike={save}
                      cardClickable={true}
                      size={LISTING_BREAKPOINT.SLIDE}
                      page={LIST_PAGES.MAP}
                      key={listing.id}
                      storeFront={storefront}
                      listing={listing}
                    />
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </SlideScreen>
  );
};

export default StorefrontSlideScreen;
