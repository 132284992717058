import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment, useState } from 'react';
import LocationDropdown from '../shared/location Dropdown/LocationDropdown';
import MoreFilterCategoryItem from '../shared/MoreFilterCategoryItem';
import CategoryIcon from '../icons/CategoryIcon';
import InputField from '../shared/InputField';
import { useGetFilterData } from '../../hooks/marketPlace/useGetFilterData';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocationBound, LocationType } from '../../model/Location';
import { CheckboxOption } from '../../model/CheckboxOption';
import { DEFAULT_LOCATION_BOUNDS, ROUTES } from '../../constants/data';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { addFilterModal } from '../../lib/redux/reducers/filter.slice';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

import SearchIcon from '../icons/SearchIcon';
import Button from '../shared/Button';
import { Typo } from '../Typo';
import MapIcon from '../icons/MapIcon';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onCloseFiltreModal: () => void;
};

const HomePageFilterModal: FC<Props> = ({ isOpen, onCloseFiltreModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories } = useGetFilterData();
  const allCategories = {
    value: '0',
    label: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
      defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
    })
  };
  const [defaultCategory, setDefaultCategory] = useState<CheckboxOption>(allCategories);
  const [locationData, setLocationData] = useState<LocationType>();
  const [locationBounds, setLocationBounds] = useState<LocationBound>(DEFAULT_LOCATION_BOUNDS);

  const onLocationSelect = (locationData: LocationType, locationBounds: LocationBound) => {
    setLocationData(locationData);
    setLocationBounds(locationBounds);
  };

  const searchFilter = yup.object().shape({
    searchInput: yup.string()
  });
  const { register, reset, handleSubmit } = useForm({
    resolver: yupResolver(searchFilter)
  });

  const onSubmit = (data: any) => {
    const searchText = data?.searchInput;
    dispatch(
      addFilterModal({
        filterModal: {
          searchText: searchText,
          categories: defaultCategory.value == '0' ? categories : [defaultCategory],
          locationData: locationData,
          locationBounds: locationBounds
        }
      })
    );
    navigate(ROUTES.MARKETPLACE_ROUTE);
    reset();
    setDefaultCategory(allCategories);
    setLocationBounds(DEFAULT_LOCATION_BOUNDS);
    onCloseFiltreModal();
  };

  const navigateToMarketPlace = () => {
    window.location.href = `${ROUTES.MARKETPLACE_ROUTE}`;
    onCloseFiltreModal();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onCloseFiltreModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden   text-left transition-all ">
                <div className="flex flex-col h-[460px] justify-between ">
                  <div className="p-4 shadow-xl bg-white rounded-xl ">
                    <div className="flex gap-3 w-full  flex-wrap ">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="flex flex-col md:flex-row gap-3">
                          <InputField
                            prefix={<SearchIcon className="h-5 w-5 text-gray-500" />}
                            name="searchInput"
                            label={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.ITEM.id,
                              defaultMessage: TRANSLATED_CONSTANTS.ITEM.defaultMessage
                            })}
                            placeholder={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.SEARCH_BY_ITEM_NAME.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.SEARCH_BY_ITEM_NAME.defaultMessage
                            })}
                            type="text"
                            register={register}
                          />
                          <div className="w-full ">
                            <LocationDropdown
                              label={intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.LOCATION.id,
                                defaultMessage: TRANSLATED_CONSTANTS.LOCATION.defaultMessage
                              })}
                              placeholder="Where to?"
                              location={locationData}
                              onLocationSelect={onLocationSelect}></LocationDropdown>
                          </div>

                          <div className=" w-full ">
                            <MoreFilterCategoryItem
                              name="categories"
                              label={intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.CATEGORY.id,
                                defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                              })}
                              prefix={<CategoryIcon></CategoryIcon>}
                              placeholder={intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.SELECT_CATEGORY.id,
                                defaultMessage: TRANSLATED_CONSTANTS.SELECT_CATEGORY.defaultMessage
                              })}
                              selectedOption={defaultCategory}
                              onChange={setDefaultCategory}
                              title={intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.CATEGORY.id,
                                defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                              })}
                              options={categories}></MoreFilterCategoryItem>
                          </div>
                          <div className="hidden md:block mt-6">
                            <Button
                              type="submit"
                              theme="primary-yellow"
                              size="lg"
                              icon={<SearchIcon className="text-gray-700" />}>
                              <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                                <FormattedMessage
                                  id={TRANSLATED_CONSTANTS.EXPLORE.id}
                                  defaultMessage={TRANSLATED_CONSTANTS.EXPLORE.defaultMessage}
                                />
                              </Typo.textMd>
                            </Button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="p-4 w-full flex justify-center  ">
                    <Button
                      theme="base-transparent"
                      iconPos="right"
                      size="xl"
                      onClick={navigateToMarketPlace}
                      icon={<MapIcon className="w-5 h-5 text-gray-100" />}
                      className=" ">
                      <Typo.textMd
                        fontWeight="font-normal"
                        className="text-gray-100 tracking-normal underline">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.SHOW_MAP.id}
                          defaultMessage={TRANSLATED_CONSTANTS.SHOW_MAP.defaultMessage}
                        />
                      </Typo.textMd>
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default HomePageFilterModal;
