import React, { FC } from 'react';
import Breadcrumb from '../../components/shared/Breadcrumb';
import { Typo } from '../../components/Typo';
import { User } from '../../model/UserModel';
import { TabModel } from '../../model/TabModel';
import { DropdownOption } from '../../model/DropdownOption';
import { handleUserRoleBadge } from '../../util/ListingBadgeHandler';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Badge from '../../components/shared/Badge';
import Button from '../../components/shared/Button';
import SettingIcon from '../../components/icons/SettingIcon';
import DownloadCloudIcon from '../../components/icons/DownloadCloudIcon';
import Divider from '../../components/shared/Divider';
import Tab from '../../components/shared/Tab';
import { useIntl } from 'react-intl';
import PlusIcon from '../../components/icons/PlusIcon';
import { useDispatch } from 'react-redux';
import { showModal } from '../../lib/redux/reducers/modal.slice';
import { useAppSelector } from '../../lib/redux/hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/data';

type Props = {
  user?: User;
  tabs: TabModel[];
  currentTab?: TabModel;
  onTabChange?: (tab: DropdownOption) => void;
};

const ReportHeader: FC<Props> = ({ user, tabs, currentTab, onTabChange }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const userRole = handleUserRoleBadge(user?.role?.name || '', intl);
  const gridLayout = useAppSelector((state) => state.report.reportLayout);
  return (
    <div className="w-full ">
      <div className="mt-[52px] lg:mt-0">
        <div className="mb-5">
          <Breadcrumb />
        </div>
        <div
          className={`flex w-full flex-col gap-y-4  ${'md:flex-row md:justify-between md:items-start'}`}>
          <div className="flex flex-col gap-y-1">
            <div className="flex items-center gap-x-2">
              <Typo.headingXs fontWeight="font-medium" className="text-gray-900 hidden sm:block">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.REPORTS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.REPORTS.defaultMessage}
                />
              </Typo.headingXs>

              <Badge theme={userRole.theme} label={userRole.name}></Badge>
            </div>
            <div className="flex items-center gap-x-2">
              <Typo.textMd fontWeight="font-normal" className=" text-gray-500">
                <FormattedMessage
                  id="page.reports.subtitle"
                  defaultMessage="All reports are displayed here."
                />
              </Typo.textMd>
            </div>
          </div>
          <div className=" flex gap-x-3 items-center">
            <Button
              onClick={() => navigate(ROUTES.PROFILE)}
              icon={<SettingIcon className="h-5 w-5 text-gray-500"></SettingIcon>}
              size="md"></Button>
            <Button onClick={() => navigate(ROUTES.BANNED_USERS_ROUTE)} theme="base" size="md">
              <Typo.textSm fontWeight="font-medium" className="text-gray-900">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.BANNED_USERS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.BANNED_USERS.defaultMessage}
                />
              </Typo.textSm>
            </Button>
            {currentTab?.id !== 4 && currentTab?.id !== 6 && (
              <Button
                disabled={gridLayout.type === 'grid' ? false : true}
                theme="primary-yellow"
                size="md"
                icon={<DownloadCloudIcon className="text-gray-700 w-5 h-5"></DownloadCloudIcon>}>
                <Typo.textSm fontWeight="font-medium" className="text-gray-900">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.DOWNLOAD.id}
                    defaultMessage={TRANSLATED_CONSTANTS.DOWNLOAD.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
            )}
            {currentTab?.id == 4 && (
              <Button
                onClick={() => dispatch(showModal('addAdmin'))}
                theme="primary-yellow"
                size="md"
                icon={<PlusIcon className="text-gray-700 w-5 h-5"></PlusIcon>}>
                <Typo.textSm fontWeight="font-medium" className="text-gray-900">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.ADD_ADMIN.id}
                    defaultMessage={TRANSLATED_CONSTANTS.ADD_ADMIN.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
            )}
            {currentTab?.id == 6 && (
              <Button
                onClick={() => dispatch(showModal('addEmailRecipient'))}
                theme="primary-yellow"
                size="md"
                icon={<PlusIcon className="text-gray-700 w-5 h-5"></PlusIcon>}>
                <Typo.textSm fontWeight="font-medium" className="text-gray-900">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.ADD_RECIPIENT.id}
                    defaultMessage={TRANSLATED_CONSTANTS.ADD_RECIPIENT.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
            )}
          </div>
          <Divider className="block md:hidden"></Divider>
        </div>
      </div>
      <div className="mt-6">
        <Tab
          type="underline"
          tabs={tabs}
          currentTab={currentTab ? currentTab : tabs[0]}
          onTabChange={onTabChange && onTabChange}></Tab>
      </div>
    </div>
  );
};

export default ReportHeader;
