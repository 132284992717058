import React from 'react';
import { Typo } from '../Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ROUTES } from '../../constants/data';
import Button from './Button';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../lib/redux/reducers/modal.slice';

type Props = {
  onChangeType?: (type: 'login' | 'signup') => void;
};
const SignupModalFooter = ({ onChangeType }: Props) => {
  const dispath = useDispatch();
  return (
    <div className="">
      <div className="flex gap-1 justify-center  items-center">
        <Typo.textSm fontWeight="font-normal" className="text-gray-600">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.ALREADY_HAVE_ACCOUNT.id}
            defaultMessage={TRANSLATED_CONSTANTS.ALREADY_HAVE_ACCOUNT.defaultMessage}
          />
        </Typo.textSm>

        <Button onClick={() => onChangeType && onChangeType('login')} size="sm" theme="gray-link">
          <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.LOGIN.id}
              defaultMessage={TRANSLATED_CONSTANTS.LOGIN.defaultMessage}
            />
          </Typo.textSm>
        </Button>
      </div>
      <div className="flex mt-3 justify-center  items-center">
        <Typo.textSm fontWeight="font-normal" className="text-gray-600 ">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.I_AGREE_WITH.id}
            defaultMessage={TRANSLATED_CONSTANTS.I_AGREE_WITH.defaultMessage}
          />
        </Typo.textSm>

        <Button
          onClick={() => dispath(hideModal('signup'))}
          className="pl-1"
          size="sm"
          theme="gray-link"
          to={ROUTES.DOGELIST_TERM_OF_SERVICE}>
          <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.DOGELIST_TERM.id}
              defaultMessage={TRANSLATED_CONSTANTS.DOGELIST_TERM.defaultMessage}
            />
          </Typo.textSm>
        </Button>
        <Typo.textSm fontWeight="font-normal" className="text-gray-600 px-1">
          &
        </Typo.textSm>
        <Button
          className="pl-1"
          size="sm"
          onClick={() => dispath(hideModal('signup'))}
          theme="gray-link"
          to={ROUTES.DOGELIST_PRIVACY_POLICY_ROUTE}>
          <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.PRIVACY_POLICY.id}
              defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY.defaultMessage}
            />
          </Typo.textSm>
        </Button>
      </div>
      <div className="flex mt-3 justify-center  items-center">
        <Typo.textSm fontWeight="font-normal" className="text-gray-600 ">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.I_AGREE_WITH.id}
            defaultMessage={TRANSLATED_CONSTANTS.I_AGREE_WITH.defaultMessage}
          />
        </Typo.textSm>

        <Button
          onClick={() => dispath(hideModal('signup'))}
          className="pl-1"
          size="sm"
          theme="gray-link"
          to={ROUTES.DOGELIST_TERM_OF_SERVICE}>
          <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.GOOGLE_TERM.id}
              defaultMessage={TRANSLATED_CONSTANTS.GOOGLE_TERM.defaultMessage}
            />
          </Typo.textSm>
        </Button>
        <Typo.textSm fontWeight="font-normal" className="text-gray-600 px-1">
          &
        </Typo.textSm>
        <Button className="pl-1" size="sm" theme="gray-link" to={ROUTES.LOGIN_ROUTE}>
          <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.PRIVACY_POLICY.id}
              defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY.defaultMessage}
            />
          </Typo.textSm>
        </Button>
      </div>
    </div>
  );
};

export default SignupModalFooter;
