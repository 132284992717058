import { ROUTES } from '../../constants/data';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { Typo } from '../Typo';
import CopyRightIcon from '../icons/CopyRightIcon';
import Button from '../shared/Button';
import { FormattedMessage } from 'react-intl';
import logo from '../../assets/logo.svg';
import Image from '../shared/Image';

const Footer = () => {
  return (
    <div className="w-full h-[478px] sm:h-[462px] lg:h-[482px] px-4 py-12 flex flex-col justify-between bg-gradient-to-r from-[#FBFAF9] to-[#F9FAFB] sm:px-[60px] sm:pt-16 sm:pb-12">
      <div className="flex flex-col lg:flex-row gap-y-12 justify-between items-start">
        <div>
          <Image src={logo} className="w-[186px] h-9" />
          <Typo.textMd
            fontWeight="font-normal"
            className="text-gray-500 mt-4 max-w-[420px] flex-wrap">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.DOGELIST_DESCRIPTION.id}
              defaultMessage={TRANSLATED_CONSTANTS.DOGELIST_DESCRIPTION.defaultMessage}
            />
          </Typo.textMd>
        </div>
        <div className="flex flex-col mb-12 lg:mb-0 justify-start items-start lg:flex-row gap-y-4 lg:gap-y-0 gap-x-8">
          <Button to={ROUTES.HOME_ROUTE} theme="gray-link">
            <Typo.textMd fontWeight="font-medium" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.HOME.id}
                defaultMessage={TRANSLATED_CONSTANTS.HOME.defaultMessage}
              />
            </Typo.textMd>
          </Button>
          <Button to={ROUTES.DASHBOARD_ROUTE} theme="gray-link">
            <Typo.textMd fontWeight="font-medium" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.DASHBOARD.id}
                defaultMessage={TRANSLATED_CONSTANTS.DASHBOARD.defaultMessage}
              />
            </Typo.textMd>
          </Button>
          <Button to={ROUTES.ABOUT_ROUTE} theme="gray-link">
            <Typo.textMd fontWeight="font-medium" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.ABOUT.id}
                defaultMessage={TRANSLATED_CONSTANTS.ABOUT.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        </div>
      </div>
      <div className=" flex flex-col-reverse sm:flex-row gap-y-4 sm:gap-y-0 justify-between w-full border-t border-gray-200 pt-8">
        <div className="flex gap-x-2 items-center text-gray-500">
          <CopyRightIcon className="h-4 w-4"></CopyRightIcon>
          <Typo.textMd fontWeight="font-normal">Dogelist.com 2022</Typo.textMd>
        </div>
        <div className="flex gap-x-4">
          <Button to={ROUTES.DOGELIST_TERM_OF_SERVICE} theme="gray-link">
            <Typo.textMd fontWeight="font-normal" className="text-gray-500">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.TERMS_OF_SERVICE.id}
                defaultMessage={TRANSLATED_CONSTANTS.TERMS_OF_SERVICE.defaultMessage}
              />
            </Typo.textMd>
          </Button>
          <Button to={ROUTES.DOGELIST_PRIVACY_POLICY_ROUTE} theme="gray-link">
            <Typo.textMd fontWeight="font-normal" className="text-gray-500">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.PRIVACY_POLICY.id}
                defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Footer;
