import { User } from '../../model/UserModel';
import { useDispatch } from 'react-redux';
import { setChatData, showSlide } from '../../lib/redux/reducers/slide.slice';
import { useGetChats } from '../../hooks/chat/useGetChats';
import { FC, useState } from 'react';
import { Chat } from '../../model/Message';
import { ROUTES } from '../../constants/data';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import DashboardSubHeader from '../../components/shared/Dashboard Header/DashboardSubHeader';
import Loader from '../../components/shared/Loader';
import EmptyListing from '../../components/dashboard/card/EmptyListing';
import MessageCircleIcon from '../../components/icons/MessageCircleIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import MessageCard from '../../components/shared/MessageCard';
import Pagination from '../../components/shared/Pagination';
import { useDeleteChat } from '../../hooks/chat/useDeleteChat';

type Props = {
  user: User;
};
const NUMBER_OF_RECORDS_PER_PAGE = 4;

const Messages: FC<Props> = ({ user }) => {
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const intl = useIntl();
  const deleteChat = useDeleteChat();
  const { chats, loading, fetchMore, totalChats } = useGetChats(
    user.id.toString(),
    offset,
    NUMBER_OF_RECORDS_PER_PAGE
  );

  const [currentPage, setCurrentPage] = useState(1);

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_PAGE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_PAGE }
    });
    setCurrentPage(pageNumber);
  };

  const onClickOpenMessage = (chat: Chat) => {
    dispatch(
      setChatData({
        receiver: {
          profileImage: { url: chat?.profileImage },
          username: chat?.username,
          id: chat?.userId?.toString(),
          name: chat?.name
        },
        sender: user
      })
    );
    dispatch(showSlide('message'));
  };

  const onDeleteMessage = async (id: number) => {
    await deleteChat({ variables: { id: id } });
  };

  return (
    <div className="w-full">
      <DashboardSubHeader
        title={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.MESSAGES.id,
          defaultMessage: TRANSLATED_CONSTANTS.MESSAGES.defaultMessage
        })}
        description={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.MESSAGES_DISPLAYED_HERE.id,
          defaultMessage: TRANSLATED_CONSTANTS.MESSAGES_DISPLAYED_HERE.defaultMessage
        })}
        amount={totalChats?.meta?.pagination?.total}></DashboardSubHeader>
      <div className="w-full py-6">
        {loading ? (
          <Loader className="h-screen" />
        ) : (
          <div>
            {chats?.length == 0 ? (
              <EmptyListing
                title={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.NO_MESSAGES_YET.id,
                  defaultMessage: TRANSLATED_CONSTANTS.NO_MESSAGES_YET.defaultMessage
                })}
                description={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.NO_MESSAGES_DESCRIPTION.id,
                  defaultMessage: TRANSLATED_CONSTANTS.NO_MESSAGES_DESCRIPTION.defaultMessage
                })}
                icon={<MessageCircleIcon />}
                btnIcon={<SearchIcon />}
                loadding={loading}
                to={ROUTES.MARKETPLACE_ROUTE}
                btnTitle={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
                  defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
                })}></EmptyListing>
            ) : (
              <div className=" space-y-4">
                {chats?.map((chat: Chat) => (
                  <MessageCard
                    onDelete={onDeleteMessage}
                    onClick={onClickOpenMessage}
                    key={chat?.id}
                    chat={chat}
                  />
                ))}
              </div>
            )}
          </div>
        )}

        <Pagination
          currentPage={currentPage}
          totalCount={totalChats?.meta?.pagination?.total}
          pageSize={NUMBER_OF_RECORDS_PER_PAGE}
          onPageChange={(pageNumber: number) => {
            paginate(pageNumber);
          }}
          type="centered-page-number"
        />
      </div>
    </div>
  );
};

export default Messages;
