type Props = {
  className?: string;
};
const DotIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-2 w-2 ${className} `}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="4.00001" cy="4" r="3" fill="currentColor" />
    </svg>
  );
};

export default DotIcon;
