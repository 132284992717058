import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { User } from '../../../model/UserModel';
import { Typo } from '../../../components/Typo';
import Loader from '../../../components/shared/Loader';
import Table from '../../../components/shared/table/Table';
import ReferralHistoryTableHead from './ReferralHistoryTableHead';

type Props = {
  user: User;
  recordPerPage: number;
  loading: boolean;
  histories: any;
  historyCounts: number;
  fetchMore: (data: any) => void;
  setOffset: (value: number) => void;
};

const ReferralHistoryTable: FC<Props> = (props) => {
  const { loading, histories, historyCounts, recordPerPage, fetchMore, setOffset } = props;

  return (
    <div className="w-full">
      <div className="w-full">
        {loading ? (
          <Loader className="h-[400px] w-full flex justify-center items-center" />
        ) : (
          <div className="min-w-full w-full overflow-x-auto  ">
            <Table>
              <ReferralHistoryTableHead />
              <tbody className="divide-y divide-gray-200 bg-white">
                <>
                  {histories?.map((history: any) => {
                    return (
                      <tr key={history.id}>
                        <td className="whitespace-nowrap px-6 py-4">
                          <Typo.textSm fontWeight="font-normal" className="text-gray-700">
                            {history?.reffered?.name}
                          </Typo.textSm>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <Typo.textSm fontWeight="font-normal" className="text-gray-700 ">
                            Active
                          </Typo.textSm>
                        </td>
                        <td className="whitespace-nowrap px-6 py-4">
                          <Typo.textSm fontWeight="font-normal" className="text-gray-700 ">
                            {history?.pointsEarned} pts
                          </Typo.textSm>
                        </td>
                      </tr>
                    );
                  })}
                </>
              </tbody>
            </Table>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReferralHistoryTable;
