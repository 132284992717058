import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment, useState } from 'react';
import LocationDropdown from '../shared/location Dropdown/LocationDropdown';
import MoreFilterCategoryItem from '../shared/MoreFilterCategoryItem';
import CategoryIcon from '../icons/CategoryIcon';
import InputField from '../shared/InputField';
import { useGetFilterData } from '../../hooks/marketPlace/useGetFilterData';
import { FormattedMessage, useIntl } from 'react-intl';
import { LocationBound, LocationType } from '../../model/Location';
import { CheckboxOption } from '../../model/CheckboxOption';
import { DEFAULT_LOCATION_BOUNDS, ROUTES } from '../../constants/data';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { addFilterModal } from '../../lib/redux/reducers/filter.slice';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

import SearchIcon from '../icons/SearchIcon';
import Button from '../shared/Button';
import { Typo } from '../Typo';
import MapIcon from '../icons/MapIcon';
import { useNavigate } from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onCloseFiltreModal: () => void;
};

const CommingSoonModal: FC<Props> = ({ isOpen, onCloseFiltreModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { categories } = useGetFilterData();
  const allCategories = {
    value: '0',
    label: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
      defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
    })
  };
  const [defaultCategory, setDefaultCategory] = useState<CheckboxOption>(allCategories);
  const [locationData, setLocationData] = useState<LocationType>();
  const [locationBounds, setLocationBounds] = useState<LocationBound>(DEFAULT_LOCATION_BOUNDS);

  const onLocationSelect = (locationData: LocationType, locationBounds: LocationBound) => {
    setLocationData(locationData);
    setLocationBounds(locationBounds);
  };

  const searchFilter = yup.object().shape({
    searchInput: yup.string()
  });
  const { register, reset, handleSubmit } = useForm({
    resolver: yupResolver(searchFilter)
  });

  const onSubmit = (data: any) => {
    const searchText = data.searchInput;
    dispatch(
      addFilterModal({
        filterModal: {
          searchText: searchText,
          categories: defaultCategory.value == '0' ? categories : [defaultCategory],
          locationData: locationData,
          locationBounds: locationBounds
        }
      })
    );
    navigate(ROUTES.MARKETPLACE_ROUTE);
    reset();
    setDefaultCategory(allCategories);
    setLocationBounds(DEFAULT_LOCATION_BOUNDS);
    onCloseFiltreModal();
  };

  const navigateToMarketPlace = () => {
    window.location.href = `${ROUTES.MARKETPLACE_ROUTE}`;
    onCloseFiltreModal();
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onCloseFiltreModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0  bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex mt-20 items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden   text-left transition-all ">
                <div className="flex flex-col h-[572px] lg:h-[353px]  bg-gray-500 w-[341px] md:w-[718px] lg:w-[1098px] text-white rounded-lg opacity-95 items-center justify-center px-8">
                  <Typo.headingMd className="text-center" fontWeight="font-semibold">
                    Leaderboard Coming Soon
                  </Typo.headingMd>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CommingSoonModal;
