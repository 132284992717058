import DashboardSquareIcon from '../components/icons/DashboardSquareIcon';
import DiamondIcon from '../components/icons/DiamondIcon';
import Gift1Icon from '../components/icons/Gift1Icon';
import HomeIcon from '../components/icons/HomeIcon';
import InfoHexagonIcon from '../components/icons/InfoHexagonIcon';
import LockUnlockedIcon from '../components/icons/LockUnlockedIcon';
import LogOutIcon from '../components/icons/LogOutIcon';
import LoginIcon from '../components/icons/LoginIcon';
import PieChart1Icon from '../components/icons/PieChart1Icon';
import SendIcon from '../components/icons/SendIcon';
import SettingIcon from '../components/icons/SettingIcon';
import SignUpIcon from '../components/icons/SignUpIcon';
import SlashCircleIcon from '../components/icons/SlashCircleIcon';
import TagIcon from '../components/icons/TagIcon';
import UserCircleIcon from '../components/icons/UserCircleIcon';
import UserXIcon from '../components/icons/UserXIcon';
import { ROLES, ROUTES } from '../constants/data';
import { DropdownOption } from '../model/DropdownOption';
import { NavigationModel } from '../model/Navigation';
import { User } from '../model/UserModel';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';

export const handleNavigationConstData = (intl: any, authUser: User) => {
  const authenticatedOptions: DropdownOption[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PROFILE.id,
        defaultMessage: TRANSLATED_CONSTANTS.PROFILE.defaultMessage
      }),
      to: ROUTES.PROFILE,
      icon: <UserCircleIcon className="h-5 w-5" />
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.MESSAGES.id,
        defaultMessage: TRANSLATED_CONSTANTS.MESSAGES.defaultMessage
      }),
      dashboardTab: { id: 3, title: 'Messages' },
      to: ROUTES.DASHBOARD_ROUTE,
      icon: <SendIcon className="h-5 w-5" />
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
        defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
      }),
      to: ROUTES.CREATE_LISTING_ROUTE,
      icon: <TagIcon className="h-5 w-5" />
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.LEADERBOARD.id,
        defaultMessage: TRANSLATED_CONSTANTS.LEADERBOARD.defaultMessage
      }),
      to: ROUTES.LEADER_BOARD,
      icon: <DiamondIcon className="h-5 w-5" />
    },
    //TODO:Uncomment when invite friend is done!
    // {
    //   id: 5,
    //   title: intl.formatMessage({
    //     id: TRANSLATED_CONSTANTS.INVITE_FREIND.id,
    //     defaultMessage: TRANSLATED_CONSTANTS.INVITE_FREIND.defaultMessage
    //   }),
    //   icon: <Gift1Icon className="h-5 w-5" />
    // },
    {
      id: 6,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SETTINGS.id,
        defaultMessage: TRANSLATED_CONSTANTS.SETTINGS.defaultMessage
      }),
      to: ROUTES.PROFILE,
      icon: <SettingIcon className="h-5 w-5" />
    },
    {
      id: 7,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.LOGOUT.id,
        defaultMessage: TRANSLATED_CONSTANTS.LOGOUT.defaultMessage
      }),
      icon: <LogOutIcon className="h-5 w-5" />
    }
  ];

  const unAuthenticatedOptions: DropdownOption[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.HOME.id,
        defaultMessage: TRANSLATED_CONSTANTS.HOME.defaultMessage
      }),
      to: ROUTES.HOME_ROUTE,
      icon: <HomeIcon className="h-5 w-5" />
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SETTINGS.id,
        defaultMessage: TRANSLATED_CONSTANTS.SETTINGS.defaultMessage
      }),
      to: ROUTES.SETTING_ROUTE,
      icon: <SettingIcon className="h-5 w-5" />
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SIGN_UP.id,
        defaultMessage: TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage
      }),
      icon: <SignUpIcon className="h-5 w- text-[#886307]" />
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.LOGIN.id,
        defaultMessage: TRANSLATED_CONSTANTS.LOGIN.defaultMessage
      }),
      icon: <LoginIcon className="h-5 w-5" />
    }
  ];

  const pagesNavigation: NavigationModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.HOME.id,
        defaultMessage: TRANSLATED_CONSTANTS.HOME.defaultMessage
      }),
      icon: <HomeIcon className="h-6 w-6" />,
      link: ROUTES.HOME_ROUTE,
      roles: ['', ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.DASHBOARD.id,
        defaultMessage: TRANSLATED_CONSTANTS.DASHBOARD.defaultMessage
      }),
      icon: <DashboardSquareIcon />,
      link: ROUTES.DASHBOARD_ROUTE,
      roles: authUser?.profileCompleted
        ? [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
        : []
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.ABOUT.id,
        defaultMessage: TRANSLATED_CONSTANTS.ABOUT.defaultMessage
      }),
      icon: <InfoHexagonIcon />,
      link: ROUTES.ABOUT_ROUTE,
      roles: ['', ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.LEADERBOARD.id,
        defaultMessage: TRANSLATED_CONSTANTS.LEADERBOARD.defaultMessage
      }),
      icon: <DiamondIcon className="h-6 w-6" />,
      link: ROUTES.LEADER_BOARD,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 5,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORTS.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORTS.defaultMessage
      }),
      icon: <PieChart1Icon className="h-6 w-6" />,
      link: ROUTES.REPORTS,
      roles: authUser?.profileCompleted ? [ROLES.SUPER_ADMIN] : []
    }
  ];

  const eventNavigation: NavigationModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.MESSAGES.id,
        defaultMessage: TRANSLATED_CONSTANTS.MESSAGES.defaultMessage
      }),
      icon: <SendIcon className="h-6 w-6" />,
      dashboardTab: { id: 3, title: 'Messages' },
      link: ROUTES.DASHBOARD_ROUTE,
      roles: authUser?.profileCompleted
        ? [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
        : []
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
        defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
      }),
      icon: <TagIcon className="h-6 w-6" />,
      link: ROUTES.CREATE_LISTING_ROUTE,
      roles: authUser?.profileCompleted
        ? [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
        : []
    },
    //TODO:Uncomment when invite friend is done!

    // {
    //   id: 3,
    //   title: intl.formatMessage({
    //     id: TRANSLATED_CONSTANTS.INVITE_FREIND.id,
    //     defaultMessage: TRANSLATED_CONSTANTS.INVITE_FREIND.defaultMessage
    //   }),
    //   icon: <Gift1Icon className="h-6 w-6" />,
    //   link: '',
    //   roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    // },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SETTINGS.id,
        defaultMessage: TRANSLATED_CONSTANTS.SETTINGS.defaultMessage
      }),
      icon: <SettingIcon className="h-6 w-6" />,
      link: ROUTES.PROFILE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    }
  ];

  const settingNavigation: NavigationModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PROFILE_SETTINGS.id,
        defaultMessage: TRANSLATED_CONSTANTS.PROFILE_SETTINGS.defaultMessage
      }),
      icon: <UserCircleIcon className="h-6 w-6" />,
      link: ROUTES.PROFILE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.CHANGE_PASSWORD.id,
        defaultMessage: TRANSLATED_CONSTANTS.CHANGE_PASSWORD.defaultMessage
      }),
      icon: <LockUnlockedIcon className="h-6 w-6" />,
      link: ROUTES.CHANGE_PASSWORD_ROUTE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BLOCKED_USERS.id,
        defaultMessage: TRANSLATED_CONSTANTS.BLOCKED_USERS.defaultMessage
      }),
      icon: <SlashCircleIcon className="h-6 w-6" />,
      link: ROUTES.BLOCKED_USERS_ROUTE,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BANNED_USERS.id,
        defaultMessage: TRANSLATED_CONSTANTS.BANNED_USERS.defaultMessage
      }),
      icon: <UserXIcon className="h-6 w-6" />,
      link: ROUTES.BANNED_USERS_ROUTE,
      roles: [ROLES.ADMIN, ROLES.SUPER_ADMIN]
    }
  ];

  const headerNavigations: NavigationModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.HOME.id,
        defaultMessage: TRANSLATED_CONSTANTS.HOME.defaultMessage
      }),
      link: ROUTES.HOME_ROUTE,
      roles: []
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.DASHBOARD.id,
        defaultMessage: TRANSLATED_CONSTANTS.DASHBOARD.defaultMessage
      }),
      link: ROUTES.DASHBOARD_ROUTE,
      roles: []
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.ABOUT.id,
        defaultMessage: TRANSLATED_CONSTANTS.ABOUT.defaultMessage
      }),
      link: ROUTES.ABOUT_ROUTE,
      roles: []
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORTS.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORTS.defaultMessage
      }),
      link: ROUTES.REPORTS,
      roles: []
    }
  ];

  return {
    authenticatedOptions,
    unAuthenticatedOptions,
    pagesNavigation,
    eventNavigation,
    settingNavigation,
    headerNavigations
  };
};
