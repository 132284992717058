import PersonTyping from '../../../assets/homepage/Person typing laptop-min.png';
import AvatarEmojeFemalePurple from '../../../assets/homepage/Avatar emoji female purple.png';
import HappyRegister from '../../../assets/homepage/Happy register transaction-min.png';
import AvatarCursorMaleBlue from '../../../assets/homepage/Avatar cursor male blue.png';
import NavigationIcon from '../../../components/icons/NavigationIcon';
import Image from '../../../components/shared/Image';
import { Typo } from '../../../components/Typo';
import Button from '../../../components/shared/Button';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/data';

const SellingPersonalBusiness = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full 2xl:px-0 px-4 sm:px-16 lg:px-[120px] bg-white">
      <div className="max-w-7xl relative mx-auto py-16 sm:py-[96px] justify-center">
        {/* selling as seller */}
        <div>
          <Typo.headingMd fontWeight="font-medium" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.SELLING_AS_PERSONAL.id}
              defaultMessage={TRANSLATED_CONSTANTS.SELLING_AS_PERSONAL.defaultMessage}
            />
          </Typo.headingMd>
          <div className="flex flex-col lg:flex-row gap-y-16 justify-between mt-6 sm:mt-12 gap-x-[60px]">
            <div className=" relative flex-1 lg:max-w-[477px] ">
              <Image
                className="rounded-2xl w-full h-[228px] sm:h-[360px] shadow-2xl object-cover"
                src={PersonTyping}></Image>
              <Image
                className="h-16 absolute top-4 -left-3 sm:top-16 sm:-left-4 lg:top-[70px] lg:left-auto lg:-right-[50px]"
                src={AvatarEmojeFemalePurple}></Image>
            </div>
            <div className="flex-1 lg:max-w-[560px]">
              <Typo.textXl fontWeight="font-medium" className="text-gray-600">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.SELLING_AS_PERSONAL_TITLE.id}
                  defaultMessage={TRANSLATED_CONSTANTS.SELLING_AS_PERSONAL_TITLE.defaultMessage}
                />
              </Typo.textXl>
              <Typo.textXl fontWeight="font-normal" className="text-gray-600 mt-5">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.SELLING_AS_PERSONAL_DESCRIPTION.id}
                  defaultMessage={
                    TRANSLATED_CONSTANTS.SELLING_AS_PERSONAL_DESCRIPTION.defaultMessage
                  }
                />
              </Typo.textXl>
              <Button
                onClick={() => navigate(ROUTES.ABOUT_ROUTE)}
                className=" mt-8 sm:mt-12"
                theme="gray-link"
                size="2xl"
                iconPos="right"
                icon={<NavigationIcon className=" -rotate-45 w-6 h-6"></NavigationIcon>}>
                <Typo.textLg fontWeight="font-semibold">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.LEARN_MORE.id}
                    defaultMessage={TRANSLATED_CONSTANTS.LEARN_MORE.defaultMessage}
                  />
                </Typo.textLg>
              </Button>
            </div>
          </div>
        </div>
        {/* selling as business */}
        <div className=" mt-16 sm:mt-24">
          <div className=" flex flex-col-reverse lg:flex-row justify-between gap-x-[60px]">
            <div className="flex-1 lg:max-w-[560px]">
              <Typo.headingMd fontWeight="font-medium" className="text-gray-900 hidden lg:block">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS.defaultMessage}
                />
              </Typo.headingMd>
              <Typo.textXl fontWeight="font-medium" className="text-gray-600 mt-16">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS_TITLE.id}
                  defaultMessage={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS_TITLE.defaultMessage}
                />
              </Typo.textXl>
              <Typo.textXl fontWeight="font-normal" className="text-gray-600 mt-5">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS_DESCRIPTION.id}
                  defaultMessage={
                    TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS_DESCRIPTION.defaultMessage
                  }
                />
              </Typo.textXl>
              <Button
                onClick={() => navigate(ROUTES.ABOUT_ROUTE)}
                className=" mt-8 sm:mt-12"
                theme="gray-link"
                size="2xl"
                iconPos="right"
                icon={<NavigationIcon className="-rotate-45 w-6 h-6"></NavigationIcon>}>
                <Typo.textLg fontWeight="font-semibold">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.LEARN_MORE.id}
                    defaultMessage={TRANSLATED_CONSTANTS.LEARN_MORE.defaultMessage}
                  />
                </Typo.textLg>
              </Button>
            </div>
            <div className=" relative flex-1 lg:max-w-[477px] ">
              <Typo.headingMd
                fontWeight="font-medium"
                className="text-gray-900 mb-6 block lg:hidden">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS.defaultMessage}
                />
              </Typo.headingMd>
              <Image
                className="rounded-2xl h-[228px] sm:h-[360px] w-full md:w-full shadow-2xl object-cover"
                src={HappyRegister}></Image>
              <Image
                className=" h-12 md:h-16 absolute right-4 bottom-20 sm:right-20 sm:bottom-12 md:bottom-[50px] md:right-[81px]"
                src={AvatarCursorMaleBlue}></Image>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellingPersonalBusiness;
