import { useAppSelector } from '../../../../lib/redux/hooks';
import FlaggedListingDetailedLayout from './FlaggedListingDetailedLayout';
import FlaggedListingsGridLayout from './FlaggedListingsGridLayout';

const FlagedListings = () => {
  const gridLayout = useAppSelector((state) => state.report.reportLayout);
  return (
    <>
      {gridLayout.type === 'grid' ? (
        <FlaggedListingsGridLayout />
      ) : (
        <FlaggedListingDetailedLayout />
      )}{' '}
    </>
  );
};

export default FlagedListings;
