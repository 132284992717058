import { createSlice } from '@reduxjs/toolkit';

const reportSlice = createSlice({
  name: 'report slice',
  initialState: {
    reportLayout: {
      type: 'grid',
      index: 0
    },
    listingSearchInput: '',
    clickedActivityData: null,
    deleltedListing: {
      userId: 0,
      listingId: 0
    }
  },
  reducers: {
    addReportLayout: (state, action) => {
      state.reportLayout = action.payload.reportLayout;
    },
    addListingSearchInput: (state, action) => {
      state.listingSearchInput = action.payload.listingSearchInput;
    },
    addClickedActivityData: (state, action) => {
      state.clickedActivityData = action.payload.clickedActivityData;
    },
    setDeletedListing: (state, action) => {
      state.deleltedListing = action.payload;
    }
  }
});

export const { addReportLayout, addListingSearchInput, addClickedActivityData, setDeletedListing } =
  reportSlice.actions;
export default reportSlice.reducer;
