import { ApolloError, gql, useMutation } from '@apollo/client';
import { GET_USER } from '../user/useGetUser';

export const CHANGE_PASSWORD = gql`
  mutation changePassword(
    $currentPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePassword(
      currentPassword: $currentPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      jwt
      user {
        id
      }
    }
  }
`;

export const useChangePassword = () => {
  const [changePasswordMutation] = useMutation(CHANGE_PASSWORD, {
    refetchQueries: [GET_USER, 'GetAccount']
  });

  // Return a function that accepts the required parameters
  const changePassword = async (
    currentPassword: string,
    password: string,
    passwordConfirmation: string
  ) => {
    try {
      const response = await changePasswordMutation({
        variables: { currentPassword, password, passwordConfirmation }
      });
      return response.data;
    } catch (error) {
      if (error instanceof ApolloError) {
        // ApolloError is a specific type for Apollo Client errors
        const errorMessage = error?.message;
        throw errorMessage;
      } else {
        // Handle non-Apollo Client errors
        throw error;
      }
    }
  };

  return changePassword;
};
