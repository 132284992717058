import React, { ReactElement, useEffect, useState } from 'react';
import { AuthUserState } from '../../context/AuthUserProvider';
import { TabModel } from '../../model/TabModel';
import { useRole } from '../../hooks/useRole';
import { ROLES, ROUTES } from '../../constants/data';
import PageWrapper from '../../components/wrapper/PageWrapper';
import ReportHeader from './ReportHeader';
import { User } from '../../model/UserModel';
import Messages from './messages/Messages';
import Admins from './admins/Admins';
import Mods from './moderators/Mods';
import Email from './emails/Email';
import { useAppSelector } from '../../lib/redux/hooks';
import Storefronts from './storefront/Storefronts';
import Listings from './listing/Listings';

const tabs: TabModel[] = [
  { id: 1, title: 'Listings' },
  { id: 2, title: 'Storefronts' },
  { id: 3, title: 'Messages' },
  { id: 4, title: 'Admins' },
  { id: 5, title: 'Mods' },
  { id: 6, title: 'Email' }
];

export const handleUserRoleBadge = (id: number, user: User) => {
  const tabComponentMap: { [key: string]: ReactElement | string } = {
    1: <Listings user={user} />,
    2: <Storefronts user={user} />,
    3: <Messages user={user} />,
    4: <Admins user={user} />,
    5: <Mods user={user} />,
    6: <Email user={user} />
  };
  return tabComponentMap[id];
};

const Reports = () => {
  const email = useAppSelector((state) => state.email.email);
  const isAdmin = useRole(ROLES.ADMIN);
  const { authUser, me } = AuthUserState();
  const [selectedTab, setSelectedTab] = useState<TabModel>(tabs[0]);

  useEffect(() => {
    if (email && email !== '') {
      setSelectedTab(tabs[5]);
    }
  }, [email]);

  return (
    <PageWrapper hasFooter={false} className="px-4 md:px-[60px]">
      <div>
        <ReportHeader
          user={me}
          tabs={isAdmin ? [tabs[0], tabs[1]] : tabs}
          onTabChange={(tab: TabModel) => setSelectedTab(tab)}
          currentTab={selectedTab}
        />
        <div className="w-full pt-8">
          {selectedTab ? handleUserRoleBadge(Number(selectedTab?.id) || 1, me) : ''}
        </div>
      </div>
    </PageWrapper>
  );
};

export default Reports;
