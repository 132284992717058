import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import Button from './Button';
import { Typo } from '../Typo';
import FeaturedIcon from './FeaturedIcon';
import UploadCloud2Icon from '../icons/UploadCloud2Icon';
import ImagePreview from './ImagePreview';
import { ImageFile } from '../../model/Image';

type ImageProps = {
  images?: ImageFile[];
  onChange?: (images: ImageFile[]) => void;
  className?: string;
  maxImages?: number;
};

const ImageUploader: React.FC<ImageProps> = (props) => {
  const { images = [], onChange, className, maxImages } = props;
  const [files, setFiles] = useState<ImageFile[]>([]);
  const [isFetched, setIsFetched] = useState(false);
  const MAX_IMAGES = maxImages ? maxImages : 4;

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const remainingSpace = MAX_IMAGES - files.length;
      if (acceptedFiles.length <= remainingSpace) {
        const newFiles: { id: number; file: File }[] = acceptedFiles.map((file, index) => ({
          id: files.length + index,
          file: file
        }));
        const updatedFiles = newFiles.slice(0, remainingSpace);
        onChange && onChange([...files, ...updatedFiles]);
        setFiles([...files, ...updatedFiles]);
      } else {
        // Show a warning or message to the user about the maximum limit
        alert('You can only upload up to 4 images.');
      }
    },
    [files, onChange]
  );

  useEffect(() => {
    // Update the state whenever the images prop changes
    if (images?.length > 0 && files?.length == 0 && !isFetched) {
      setIsFetched(true);
      setFiles(images);
    }
  }, [images]);

  const onDeleteImage = (imageId: number) => {
    setFiles((files) => files.filter((item) => item.id !== imageId));
    if (onChange) {
      const updatedImages = files.filter((item) => item.id !== imageId);
      onChange(updatedImages);
    }
  };

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg', '.svg']
    },
    maxFiles: MAX_IMAGES - files.length,
    multiple: true,
    noClick: true,
    noKeyboard: true
  };

  const { getRootProps, getInputProps, open } = useDropzone(dropzoneOptions);
  return (
    <div className={`w-full ${className}`}>
      {files && files?.length === MAX_IMAGES ? (
        <ImagePreview
          onClick={() => onDeleteImage((MAX_IMAGES !== 1 ? files[3]?.id : files[0]?.id) || 0)}
          showText={true}
          className="w-full h-64 md:h-72"
          src={
            MAX_IMAGES !== 1
              ? files[3]?.file instanceof File
                ? URL.createObjectURL(files[3]?.file)
                : files[3]?.file
              : files[0]?.file instanceof File
              ? URL.createObjectURL(files[0]?.file)
              : files[0]?.file
          }
        />
      ) : (
        <div
          {...getRootProps({
            className: 'dropzone border border-gray-200 rounded-xl py-4 px-6'
          })}>
          <input {...getInputProps()} />
          <div className="flex justify-center">
            <FeaturedIcon size="md" color="gray" theme="light-circle-outline">
              <UploadCloud2Icon />
            </FeaturedIcon>
          </div>
          <div className="flex w-full justify-center gap-x-1 mt-3">
            <Button size="md" theme="yellow-link" className=" underline" onClick={open}>
              <Typo.textSm fontWeight="font-semibold">Click to upload</Typo.textSm>
            </Button>
            <Typo.textSm fontWeight="font-normal" className="text-gray-600">
              or drag and drop {MAX_IMAGES !== 1 ? `(${MAX_IMAGES - files.length})` : ''}
            </Typo.textSm>
          </div>
          <Typo.textXs fontWeight="font-normal" className="text-gray-500 text-center mt-1">
            SVG, PNG, JPG (max. 800x400px)
          </Typo.textXs>
        </div>
      )}
      {MAX_IMAGES !== 1 && (
        <div className="w-full flex flex-col md:flex-row gap-y-4 md:gap-x-4 mt-4">
          {files?.slice(0, 3)?.map((file) => (
            <ImagePreview
              onClick={() => onDeleteImage(file?.id || 0)}
              className="rounded-xl h-64 md:h-[125px] md:flex-1"
              key={file.id}
              src={file.file instanceof File ? URL.createObjectURL(file.file) : file.file}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageUploader;
