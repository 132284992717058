import { FC, useEffect, useState } from 'react';
import ContainerModal from './CommandMenus';
import { useGetUnBlockedUsers } from '../../hooks/settings/useGetUnBlockedUsers';
import { User } from '../../model/UserModel';
import UserInfo from '../shared/User/UserInfo';
import ProfileAvatar from '../shared/ProfileAvatar';
import { Typo } from '../Typo';
import Badge from '../shared/Badge';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { getJoinDate } from '../../util/AppUtil';
import Button from '../shared/Button';
import Image from '../shared/Image';
import banner1 from '../../assets/images/Banner 1.png';
import banner2 from '../../assets/images/Banner 2.png';
import { ACCOUNT_TYPE } from '../../constants/data';
import { handleAccountTypeBadge } from '../../util/ListingBadgeHandler';
import { useIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../shared/Loader';
import { useBlockUser } from '../../hooks/profile/useBlockUser';
import PlusIcon from '../icons/PlusIcon';
import NoUsersFound from './NoUsersFound';
type Props = {
  isOpen: boolean;
  onClose: (user?: User) => void;
  user: User;
};

const BlockedUserCommandMenu: FC<Props> = ({ isOpen, onClose, user }) => {
  const [offset, setOffset] = useState(0);
  const [isFetched, setIsFetched] = useState(false);
  const [isBlocking, setIsBlocking] = useState(false);
  const [searchText, setSearchText] = useState('');
  const ITEMS_PER_PAGE = 6;
  const intl = useIntl();
  const blockUser = useBlockUser();
  const { error, loading, unBlockedUsers, unBlockedUsersCounts, refetch, fetchMore } =
    useGetUnBlockedUsers(0, ITEMS_PER_PAGE, '', user?.id);
  const [selectedUser, setSelectedUser] = useState<any>();
  const accountType = handleAccountTypeBadge(selectedUser?.type || '', intl);
  const onSearch = async (value: string) => {
    setSearchText(value);
    await refetch({ searchText: value });
  };

  useEffect(() => {
    if (unBlockedUsers?.length > 0 && !isFetched) {
      setIsFetched(true);
      setSelectedUser(unBlockedUsers[0]);
    }
  }, [unBlockedUsers]);

  const fetchMoreUsers = async () => {
    const newOffset = offset + ITEMS_PER_PAGE;
    await fetchMore({
      variables: { offset: newOffset, limit: ITEMS_PER_PAGE }
    });
    setOffset(newOffset);
  };

  const onBlockUser = async () => {
    setIsBlocking(true);
    const response = await blockUser({
      variables: {
        data: {
          blocked: selectedUser?.id,
          blocked_by: user?.id,
          date: new Date(),
          publishedAt: new Date()
        }
      }
    });
    if (response) {
      await refetch({ offset: 0, limit: ITEMS_PER_PAGE, searchText: '' });
      if (unBlockedUsers?.length > 0) {
        setSelectedUser(unBlockedUsers[0]);
      }
    }
    setIsBlocking(false);
  };

  return (
    <ContainerModal
      placeholder={intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SEARCH_BY_NAME_USERNAME.id,
        defaultMessage: TRANSLATED_CONSTANTS.SEARCH_BY_NAME_USERNAME.defaultMessage
      })}
      defaultValue={searchText}
      onSearch={onSearch}
      isOpen={isOpen}
      onClose={onClose}>
      <>
        {!loading ? (
          <div className="w-full">
            {unBlockedUsersCounts > 0 ? (
              <div className=" flex flex-col-reverse sm:divide-x w-full sm:w-auto sm:flex-row">
                <div className="py-4 px-2 flex-none h-[324px] border-t-2  sm:border-t-0 border-t-gray-200 min-w-[268px] overflow-y-auto">
                  <InfiniteScroll
                    dataLength={unBlockedUsersCounts || 0}
                    next={fetchMoreUsers}
                    hasMore={unBlockedUsersCounts > offset}
                    className="space-y-1"
                    loader={<Loader className="h-20 mt-6" />}>
                    {unBlockedUsers &&
                      unBlockedUsers?.map((user: any) => {
                        return (
                          <UserInfo
                            clickUser={setSelectedUser}
                            clickedUser={selectedUser}
                            key={user.id}
                            user={user}></UserInfo>
                        );
                      })}
                  </InfiniteScroll>
                </div>
                <div className=" w-full grow ">
                  {selectedUser && (
                    <div className="pb-4 w-full ">
                      <div className="w-full relative flex  ">
                        <div className="w-full">
                          <Image
                            className="flex w-full sm:w-[400px] h-[88px] "
                            src={
                              selectedUser?.type == ACCOUNT_TYPE.BUSINESS
                                ? banner2
                                : selectedUser?.headerImage
                                ? selectedUser?.headerImage?.url
                                : banner1
                            }></Image>
                        </div>
                        <ProfileAvatar
                          image={selectedUser?.profileImage?.url}
                          verified={false}
                          className=" w-[72px] h-[72px] absolute left-0 right-0 bottom-0 translate-y-[50%] mx-auto"></ProfileAvatar>
                      </div>
                      <div className="px-4 gap-2 w-full flex flex-col justify-center items-center mt-[52px]">
                        <div className="flex gap-2 w-full justify-center truncate">
                          <Typo.textMd fontWeight="font-medium" className="text-gray-900 truncate">
                            {selectedUser?.name}
                          </Typo.textMd>
                          <Typo.textSm fontWeight="font-normal" className="text-gray-600 truncate">
                            @{selectedUser?.username}
                          </Typo.textSm>
                        </div>
                        <Badge theme={accountType?.theme} label={accountType?.name}></Badge>
                        <div className="flex gap-2 items-center justify-center">
                          <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.JOINED.id}
                              defaultMessage={TRANSLATED_CONSTANTS.JOINED.defaultMessage}
                            />
                          </Typo.textMd>

                          <Typo.textSm fontWeight="font-normal" className="text-gray-700">
                            {selectedUser?.createdAt &&
                              getJoinDate(selectedUser?.createdAt?.toString() || '', true)}
                          </Typo.textSm>
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-center mt-6">
                        <Button
                          onClick={() => onClose(selectedUser)}
                          size="md"
                          theme="base"
                          className=" border border-gray-300">
                          <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                              defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
                            />
                          </Typo.textSm>
                        </Button>
                        <Button
                          icon={<PlusIcon className="h-5 w-5 text-gray-900" />}
                          size="md"
                          onClick={onBlockUser}
                          loading={isBlocking}
                          theme="primary-yellow">
                          <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.BLOCK_USER.id}
                              defaultMessage={TRANSLATED_CONSTANTS.BLOCK_USER.defaultMessage}
                            />
                          </Typo.textSm>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {/* <RedActionModal
          title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
          isOpen={showBanModal}
          onCloseRedActionModal={(value: boolean) => onCloseRedActionModal(value)}
          subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
          icon={<UserXIcon />}
          username={selectedUser?.username}
        /> */}
              </div>
            ) : (
              <NoUsersFound name={searchText} onSearch={onSearch} />
            )}
          </div>
        ) : (
          <div className="flex w-full h-full py-6 items-start justify-center">
            <Loader className="h-full" />
          </div>
        )}
      </>
    </ContainerModal>
  );
};

export default BlockedUserCommandMenu;
