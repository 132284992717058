import { FC, useEffect, useState } from 'react';
import { ListingNew } from '../../model/Listing';
import { Typo } from '../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { LISTING_BREAKPOINT, LIST_PAGES } from '../../constants/data';
import StorefrontItemCard from '../../components/shared/ListingCard/StorefrontItemCard';
import { Storefront } from '../../model/Storefront';
import clsx from 'clsx';
import Button from '../../components/shared/Button';
import ArrowRightIcon from '../../components/icons/ArrowRigthIcon';

type Props = {
  listings: ListingNew[];
  storefront: Storefront;
  onViewMore: () => void;
  onSave?: (id: number | string, type: string, like: boolean, savedListingId?: number) => void;
};

const StorefrontItems: FC<Props> = ({ listings, storefront, onViewMore, onSave }) => {
  return (
    <div className="w-full">
      <Typo.headingXs fontWeight="font-medium" className="text-gray-900 mb-1">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ITEMS_FOR_SALE.id}
          defaultMessage={TRANSLATED_CONSTANTS.ITEMS_FOR_SALE.defaultMessage}
        />
      </Typo.headingXs>
      <Typo.textMd fontWeight="font-normal" className="text-gray-500">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.VIEW_WHAT_STORE_HAS_FOR_SALE.id}
          defaultMessage={TRANSLATED_CONSTANTS.VIEW_WHAT_STORE_HAS_FOR_SALE.defaultMessage}
        />
      </Typo.textMd>
      <div className="py-6 gap-x-6 gap-y-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {listings?.slice(0, 3).map((listing, index) => (
          <StorefrontItemCard
            key={index}
            cardClickable={true}
            className={clsx({
              ' hidden lg:block': index == 2
            })}
            onLike={onSave}
            listing={listing}
            page={LIST_PAGES.MAP}
            size={LISTING_BREAKPOINT.MOBILE}
            storeFront={storefront}></StorefrontItemCard>
        ))}
      </div>
      <div className="w-full flex justify-end">
        {listings?.length > 2 ? (
          <Button
            onClick={onViewMore}
            size="lg"
            theme="blue-link"
            iconPos="right"
            icon={<ArrowRightIcon />}>
            <Typo.textMd fontWeight="font-semibold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.VIEW_MORE.id}
                defaultMessage={TRANSLATED_CONSTANTS.VIEW_MORE.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default StorefrontItems;
