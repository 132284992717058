import { GET_USER } from './useGetUser';
import { gql, useMutation } from '@apollo/client';

interface UsersPermissionsLoginInput {
  variables: {
    input: {
      // username: string;
      identifier: string;
      password: string;
    };
  };
}

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($input: UsersPermissionsLoginInput!) {
    loginResolver(input: $input) {
      jwt
      user {
        id
        email
        username
      }
    }
  }
`;

export const useLogin = (): ((createAccount: UsersPermissionsLoginInput) => any) => {
  const [loginResult] = useMutation(LOGIN_MUTATION, {
    refetchQueries: [GET_USER, 'GetAccount']
  });
  return loginResult;
};
