import React from 'react';

type Props = {
  icon?: any;
  theme?: 'light' | 'medium' | 'dark';
  color?:
    | 'primary'
    | 'gray'
    | 'error'
    | 'warning'
    | 'success'
    | 'purple'
    | 'blue'
    | 'pink'
    | 'indigo';
  title?: string;
  message?: string;
  className?: string;
  [x: string]: any;
};
const BadgeNotification: React.FC<Props> = (props) => {
  const { title = '', message = '', color = 'gray', theme = 'light', className, icon } = props;
  let classes =
    ' inline-flex items-center font-inter text-xs font-medium leading-[18px] p-1 rounded-2xl ';
  let labelClasses = ' py-[2px] px-2 rounded-2xl ';

  switch (color) {
    case 'primary':
      classes += ` text-yellow-700 ${theme == 'medium' ? ' bg-yellow-100' : 'bg-yellow-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-yellow-700'
          : theme == 'medium'
          ? ' bg-yellow-50 text-yellow-700'
          : ' bg-yellow-700 text-white'
      }`;
      break;
    case 'error':
      classes += ` text-error-700 ${theme == 'medium' ? ' bg-error-100' : 'bg-error-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-error-700'
          : theme == 'medium'
          ? ' bg-error-50 text-error-700'
          : ' bg-error-700 text-white'
      }`;
      break;
    case 'warning':
      classes += ` text-warning-700 ${theme == 'medium' ? ' bg-warning-100' : 'bg-warning-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-warning-700'
          : theme == 'medium'
          ? ' bg-warning-50 text-warning-700'
          : ' bg-warning-700 text-white'
      }`;
      break;
    case 'success':
      classes += ` text-success-700 ${theme == 'medium' ? ' bg-success-100' : 'bg-success-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-success-700'
          : theme == 'medium'
          ? ' bg-success-50 text-success-700'
          : ' bg-success-700 text-white'
      }`;
      break;
    case 'blue':
      classes += ` text-blue-700 ${theme == 'medium' ? ' bg-blue-100' : 'bg-blue-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-blue-700'
          : theme == 'medium'
          ? ' bg-blue-50 text-blue-700'
          : ' bg-blue-700 text-white'
      }`;
      break;
    case 'indigo':
      classes += ` text-indigo-700 ${theme == 'medium' ? ' bg-indigo-100' : 'bg-indigo-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-indigo-700'
          : theme == 'medium'
          ? ' bg-indigo-50 text-indigo-700'
          : ' bg-indigo-700 text-white'
      }`;
      break;
    case 'purple':
      classes += ` text-purple-700 ${theme == 'medium' ? ' bg-purple-100' : 'bg-purple-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-purple-700'
          : theme == 'medium'
          ? ' bg-purple-50 text-purple-700'
          : ' bg-purple-700 text-white'
      }`;
      break;
    case 'pink':
      classes += ` text-pink-700 ${theme == 'medium' ? ' bg-pink-100' : 'bg-pink-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-pink-700'
          : theme == 'medium'
          ? ' bg-pink-50 text-pink-700'
          : ' bg-pink-700 text-white'
      }`;
      break;
    case 'gray':
      classes += ` text-gray-700 ${theme == 'medium' ? ' bg-gray-100' : 'bg-gray-50'} `;
      labelClasses += ` ${
        theme == 'light'
          ? ' bg-white text-gray-700'
          : theme == 'medium'
          ? ' bg-gray-50 text-gray-700'
          : ' bg-gray-700 text-white'
      }`;
      break;
  }

  return (
    <div className={classes + '' + className}>
      <div className={labelClasses}>{title}</div>
      <span className={`ml-2  ${icon ? 'mr-3' : 'mr-2'}`}>{message}</span>
      {icon && <span className="mr-[10px]">{icon}</span>}
    </div>
  );
};

export default BadgeNotification;
