import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { Typo } from '../../../components/Typo';

const ReferralHistoryTableHead: FC = () => {
  return (
    <thead>
      <tr className="bg-gray-50">
        <th scope="col" className="min-w-[12rem] py-3 px-6 text-left">
          <Typo.textSm fontWeight="font-medium" className="  text-gray-600 ">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.NAME.id}
              defaultMessage={TRANSLATED_CONSTANTS.NAME.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="min-w-[12rem] py-3 px-6 text-left">
          <Typo.textSm fontWeight="font-medium" className="  text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.STATUS.id}
              defaultMessage={TRANSLATED_CONSTANTS.STATUS.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="min-w-[12rem] py-3 px-6 text-left">
          <Typo.textSm fontWeight="font-medium" className="  text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.POINTS_EARNED.id}
              defaultMessage={TRANSLATED_CONSTANTS.POINTS_EARNED.defaultMessage}
            />
          </Typo.textSm>
        </th>
      </tr>
    </thead>
  );
};

export default ReferralHistoryTableHead;
