import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';
import Modal from './Modal';
type Props = {
  title: MessageDescriptor;
  subTitle: MessageDescriptor;
  isOpen: boolean;
  onClosePrimaryActionModal: (arg0: boolean) => void;
  confirmText: MessageDescriptor;
  cancelText?: MessageDescriptor;
  icon?: React.ReactElement;
  actionButtonsFlow?: 'vertical' | 'horizontal';
  confirmButtonIcon?: React.ReactElement;
};

const PrimaryActionModal: FC<Props> = (props) => {
  const {
    onClosePrimaryActionModal,
    isOpen,
    confirmText,
    cancelText,
    title,
    subTitle,
    icon,
    confirmButtonIcon,
    actionButtonsFlow
  } = props;
  return (
    <Modal
      confirmButtonIcon={confirmButtonIcon}
      onCancel={() => onClosePrimaryActionModal(false)}
      onConfirm={() => onClosePrimaryActionModal(true)}
      isOpen={isOpen}
      isConfirm
      confirmText={confirmText}
      cancelText={cancelText}
      title={title}
      subTitle={subTitle}
      icon={icon}
      actionButtonsFlow={actionButtonsFlow}
      theme="primary"
      headerType="left"></Modal>
  );
};

export default PrimaryActionModal;
