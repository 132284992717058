import { geocodeByPlaceId } from 'react-places-autocomplete';
import { OperationHourModel } from '../model/OperationHourModel';
import { NUMBER_OF_ITEMS_PER_PAGE } from '../constants/data';
import { MarketPlaceFilters } from '../model/MarketPlace';
import moment from 'moment';
import { LocationBound, LocationType } from '../model/Location';

export function AppUtil() {
  return null;
}

export function getStrapiURL(path: string) {
  return `${process.env.NEXT_PUBLIC_STRAPI_API_URL}${path}`;
}
export function getStrapiMedia(media: { url: string }) {
  if (!media) return null;
  const imageUrl = media?.url?.startsWith('/') ? getStrapiURL(media.url) : media?.url;
  return imageUrl;
}

export function camalize(string: string) {
  if (string) {
    const updatedString = string?.replace(/_/g, '  ');
    return updatedString.charAt(0).toUpperCase() + updatedString.slice(1);
  }
  return;
}

export function chatDateTime(date: string) {
  const newDate = new Date(date);
  if (new Date().toDateString() === newDate.toDateString()) {
    let hours = newDate.getHours();
    const minutes = newDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formatedMinute = minutes < 10 ? '0' + minutes : minutes;
    const time = hours + ':' + formatedMinute + ' ' + ampm;
    return time;
  } else {
    return new Date(date).toLocaleDateString('en-us', {
      day: 'numeric',
      month: 'long'
    });
  }
}
export const shortenUsername = (username: string) => {
  if (!username) {
    return '';
  }
  return username?.replace(/^(.{5}).*(.{3})$/, '$1...');
};

export const shortenText = (text: string, maxLength: number) => {
  if (!text) {
    return '';
  }
  let trimmedString = text.substring(0, maxLength);

  const textLength = text?.length;
  //re-trim if we are in the middle of a word
  return (trimmedString =
    trimmedString.substring(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))) +
    `${textLength > maxLength ? '...' : ''}`);
};

export const get24OpenDays = (operationHours: OperationHourModel[]) => {
  let days = 0;
  operationHours?.map((item) =>
    item.isOpen && item.closesAt.title == item.opensAt.title && item.opensAt.title == '12:00 AM'
      ? days++
      : days
  );
  return days;
};

export const changeTime24 = (time: string) => {
  const currentTime = new Date(); // Current time
  const [hours, minutes]: any = time.split(':');
  currentTime.setHours((hours % 12) + 12 * (time.includes('PM') && hours !== '12' ? 1 : 0));
  currentTime.setMinutes(minutes?.replace(/\D/g, ''));
  return currentTime;
};

export const getJoinDate = (date: string, hasDay = false) => {
  // Assuming you have a Strapi created date stored in a variable called 'strapiCreatedDate'
  const dateTime = new Date(date); // Replace this with your Strapi created date
  // Get the month and year from the Strapi created date
  const month = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateTime);
  const day = new Intl.DateTimeFormat('en-US', { day: 'numeric' }).format(dateTime);
  const year = dateTime.getFullYear();
  // Combine the month and year into the desired format
  const formattedDate = `${month} ${hasDay ? day + ',' : ''} ${year}`;

  return formattedDate;
};

export const formatDate = (dateTime: string) => {
  const date = new Date(dateTime);
  const formattedDate = moment(date, 'ddd MMM DD YYYY HH:mm:ss Z').format('MMM DD, YYYY');
  return formattedDate;
};

export const getCreatedAt = (date: string) => {
  // Assuming you have a Strapi created date stored in a variable called 'strapiCreatedDate'
  const dateTime = new Date(date); // Replace this with your Strapi created date
  const year = dateTime.getFullYear();
  // Combine the month and year into the desired format
  const formattedDate = `${year}`;

  return formattedDate;
};

export const getMessageDateTime = (date: string) => {
  const dateTime = new Date(date);
  // Format the date and time as "Thursday 11:44am"
  const formattedDateTime = dateTime.toLocaleString('en-US', {
    weekday: 'long',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
  return formattedDateTime;
};

export const getDayName = (dateTimeString: string) => {
  const dateObject = new Date(dateTimeString);
  const dayName = dateObject.toLocaleString('en-US', { weekday: 'long' });
  return dayName;
};

export const compareDates = (date1: string, date2: any) => {
  const d1 = moment(date1, 'YYYY-MM-DD').toDate();
  const d2 = moment(date2, 'YYYY-MM-DD').toDate();

  const year1 = d1?.getUTCFullYear();
  const month1 = d1?.getUTCMonth();
  const day1 = d1?.getUTCDate();

  const year2 = d2?.getUTCFullYear();
  const month2 = d2?.getUTCMonth();
  const day2 = d2?.getUTCDate();

  if (year1 === year2 && month1 === month2 && day1 === day2) {
    return true;
  } else {
    return false;
  }
};

export const getCountryCode = async (placeId: string) => {
  try {
    const results = await geocodeByPlaceId(placeId);
    const countryCode = results[0].address_components.filter(
      (component) => component.types.includes('country') && component.short_name.toLowerCase()
    );
    return countryCode[0].short_name.toLowerCase() || 'us';
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

// Function to check if the difference between two dates is exactly 18 years
export const isVerifiedAge = (selectedDate: Date) => {
  const today = new Date();
  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365; // Number of milliseconds in a year

  // Calculate the difference in milliseconds between the two dates
  const differenceInMilliseconds = Math.abs(today.getTime() - selectedDate.getTime());

  // Calculate the number of years by dividing the difference in milliseconds by millisecondsPerYear
  const yearsDifference = differenceInMilliseconds / millisecondsPerYear;

  // Check if the difference is exactly 18 years
  return yearsDifference >= 18;
};

const storefrontsPerPage = NUMBER_OF_ITEMS_PER_PAGE / 2;
const listingsPerPage = NUMBER_OF_ITEMS_PER_PAGE / 2;

export const handleMapPageData = (listings: any, storefronts: any, type = 'list') => {
  let mapData: { listingsData: any; storefrontsData: any } = {
    listingsData: [],
    storefrontsData: []
  };
  if (type === 'list') {
    if (storefronts?.length >= storefrontsPerPage && listings?.length >= listingsPerPage) {
      const slicedStorefronts = storefronts.slice(0, storefrontsPerPage);
      const slicedListings = listings.slice(0, listingsPerPage);
      mapData = { listingsData: slicedListings, storefrontsData: slicedStorefronts };
    } else if (storefronts?.length < storefrontsPerPage && listings?.length > listingsPerPage) {
      const slicedListings = listings.slice(
        0,
        storefrontsPerPage - storefronts?.length + listingsPerPage
      );
      mapData = { listingsData: slicedListings, storefrontsData: storefronts };
    } else if (listings?.length < listingsPerPage && storefronts?.length >= storefrontsPerPage) {
      const slicedStorefronts = storefronts.slice(
        0,
        listingsPerPage - listings?.length + storefrontsPerPage
      );
      mapData = { listingsData: listings, storefrontsData: slicedStorefronts };
    } else {
      mapData = { listingsData: listings, storefrontsData: storefronts };
    }
  } else {
    mapData.listingsData = listings;
    mapData.storefrontsData = storefronts;
  }
  return mapData;
};

export const generateListingFilters = (variables: MarketPlaceFilters) => {
  const filters: any = {
    or: [
      {
        and: [
          { location: { latitude: { lt: variables.maxLat, gt: variables.minLat } } },
          { location: { longitude: { lt: variables.maxLng, gt: variables.minLng } } }
        ]
      },
      {
        store_front: {
          and: [
            { location: { latitude: { lt: variables.maxLat, gt: variables.minLat } } },
            { location: { longitude: { lt: variables.maxLng, gt: variables.minLng } } }
          ]
        }
      }
    ]
  };
  filters.name = { containsi: variables.searchText };
  filters.available = { eq: true };
  filters.deleted = { eq: false };
  if (variables.categoryIds?.length) {
    filters.category = { id: { in: variables.categoryIds } };
  }

  if (variables.types?.length) {
    filters.type = { in: variables.types };
  }

  if (variables?.conditions?.length) {
    filters.item_condition = { id: { in: variables.conditions } };
  }

  if (variables.alternatePriceOptions?.length || variables.priceType?.length) {
    if (variables.alternatePriceOptions?.length && !variables.priceType?.length) {
      filters.price = { alternatePriceOptions: { in: variables.alternatePriceOptions } };
    } else if (!variables.alternatePriceOptions?.length && variables.priceType?.length) {
      // filters.price = { priceType: { in: variables.priceType } };
    } else {
      filters.price = {
        // priceType: { in: variables.priceType },
        alternatePriceOptions: { in: variables.alternatePriceOptions }
      };
    }
  }
  return filters;
};

export const getStorefrontFilters = (variables: MarketPlaceFilters) => {
  const storefrontFilters: any = {
    and: [
      { location: { latitude: { lt: variables.maxLat, gt: variables.minLat } } },
      { location: { longitude: { lt: variables.maxLng, gt: variables.minLng } } }
    ]
  };
  storefrontFilters.available = { eq: true };
  if (variables.searchText) {
    storefrontFilters.name = { containsi: variables.searchText };
  }

  if (variables.categoryIds?.length) {
    storefrontFilters.category = { id: { in: variables.categoryIds } };
  }

  if (variables.types && !variables.types.includes('storefront')) {
    storefrontFilters.category = { id: { in: [-1] } };
  }
  return storefrontFilters;
};

export const getCountFilters = (variables: MarketPlaceFilters, type: string) => {
  const filters = generateListingFilters(variables);
  const countFilters = { ...filters };

  if (variables?.types && variables?.types?.length > 0) {
    if (variables.types?.includes(type)) {
      countFilters.type = { in: [type] };
    } else {
      countFilters.type = { in: [] };
    }
  } else {
    countFilters.type = { in: [type] };
  }

  return countFilters;
};

export const timeAgo = (datetime: string) => {
  const currentDate = new Date();
  const targetDate = new Date(datetime);

  const timeDifference = currentDate.getTime() - targetDate.getTime();
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (seconds < 60) {
    return seconds + ' secs ago';
  } else if (minutes < 60) {
    return minutes === 1 ? minutes + ' min ago' : minutes + ' mins ago';
  } else if (hours < 24) {
    return hours === 1 ? hours + ' hour ago' : hours + ' hours ago';
  } else if (days < 7) {
    return days === 1 ? days + ' day ago' : days + ' days ago';
  } else if (weeks < 4) {
    return weeks === 1 ? weeks + ' week ago' : weeks + ' weeks ago';
  } else if (months < 12) {
    return months === 1 ? months + ' month ago' : months + ' months ago';
  } else {
    return years === 1 ? years + ' year ago' : years + ' years ago';
  }
};

export const handlegetCurrentLocationSuccess = async (position: any) => {
  return new Promise<{ location: LocationType; locationBound: LocationBound }>(
    (resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const lat = position.coords.latitude;
      const lng = position.coords.longitude;
      const latlng = new google.maps.LatLng(lat, lng);
      geocoder.geocode({ location: latlng }, async (results, status) => {
        if (status === 'OK' && results) {
          // Check if this result contains a state component

          const result = results && results?.length > 0 ? results[0] : null;
          // Extract state/province
          const state = result?.address_components?.find((component) =>
            component?.types?.includes('administrative_area_level_1')
          )?.long_name;

          // Extract country
          const country = result?.address_components?.find((component) =>
            component?.types?.includes('country')
          )?.long_name;

          // Extract street
          const street = result?.address_components?.find((component) =>
            component?.types?.includes('route')
          )?.long_name;

          const locality = results
            ?.map((result) => {
              return result?.types?.includes('locality') ? result : null;
            })
            .filter((result) => result !== null);
          const level1 = results
            ?.map((result) => {
              return result?.types?.includes('administrative_area_level_1') ? result : null;
            })
            .filter((result) => result !== null);
          const level2 = results
            ?.map((result) => {
              return result?.types?.includes('administrative_area_level_2') ? result : null;
            })
            .filter((result) => result !== null);
          const selectedResult =
            locality?.length > 0 ? locality[0] : level1?.length > 0 ? level1[0] : level2[0];

          const formatedAddress = selectedResult?.formatted_address || '';
          const city = selectedResult?.address_components[0]?.long_name || '';
          const countryCode = selectedResult?.place_id
            ? await getCountryCode(selectedResult?.place_id)
            : 'US';
          const location = {
            id: '',
            city: city,
            address: formatedAddress,
            country: country,
            state: state,
            street: street,
            latitude: lat,
            longitude: lng,
            countryCode: countryCode || ''
          };

          const bounds = selectedResult?.geometry?.bounds;
          const jsonBounds = bounds?.toJSON();
          const boundingBox: LocationBound = {
            minLat: jsonBounds?.south || 0, // Use null as the default value
            maxLat: jsonBounds?.north || 0, // Use null as the default value
            minLng: jsonBounds?.west || 0, // Use null as the default value
            maxLng: jsonBounds?.east || 0 // Use null as the default value
          };
          const locationData = {
            location: location,
            locationBound: boundingBox
          };

          localStorage.setItem('locationData', JSON.stringify(locationData));
          localStorage.setItem('locationAccess', JSON.stringify(true));
          resolve(locationData);
        } else {
          reject(status); // Reject the promise with an error status
        }
      });
    }
  );
};

export const getDateDifferenceInDays = (comingDate: string) => {
  const date = new Date(comingDate);
  const currentDate = new Date();
  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(date.getTime() - currentDate.getTime());

  // Convert the difference to days
  const differenceInDays = Math.floor(differenceInMilliseconds / (24 * 60 * 60 * 1000));
  return differenceInDays;
};
