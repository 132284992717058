import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import SettingWrapper from '../../components/wrapper/SettingWrapper';
import { AuthUserState } from '../../context/AuthUserProvider';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useIntl } from 'react-intl';
import Loader from '../../components/shared/Loader';
import TableContainer from '../../components/shared/table/TableContainer';
import { useGetReferralHistory } from '../../hooks/settings/useGetReferralHistory';
import ReferralHistoryTable from './Referral History/ReferralHistoryTable';
import { useState } from 'react';
const ReferralHistory = () => {
  const userState = AuthUserState();
  const user = userState.authUser;
  const intl = useIntl();
  const SHOW_ITEMS_PER_PAGE = 2;
  const [offset, setOffset] = useState(0);
  const { referralHistories, referralHistoryCounts, loading, fetchMore } = useGetReferralHistory(
    0,
    SHOW_ITEMS_PER_PAGE,
    user?.id
  );

  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <>
          {!loading ? (
            <div className="w-full">
              <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
                <DashboardHeader
                  title={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.REFERRAL_HISTORY.id,
                    defaultMessage: TRANSLATED_CONSTANTS.REFERRAL_HISTORY.defaultMessage
                  })}
                  description={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.VIEW_YOUR_REFERRAL_HISTORY.id,
                    defaultMessage: TRANSLATED_CONSTANTS.VIEW_YOUR_REFERRAL_HISTORY.defaultMessage
                  })}
                  accountType={user?.type}
                />
              </div>

              <div className="mx-auto max-w-7xl w-full">
                <div className="divide-y divide-gray-200">
                  <div className="py-5 w-full space-y-8 sm:space-y-0">
                    <TableContainer>
                      <div className="w-full">
                        <ReferralHistoryTable
                          loading={loading}
                          histories={referralHistories}
                          historyCounts={referralHistoryCounts}
                          user={user}
                          recordPerPage={SHOW_ITEMS_PER_PAGE}
                          setOffset={setOffset}
                          fetchMore={fetchMore}
                        />
                      </div>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loader />
          )}
        </>
      </SettingWrapper>
    </div>
  );
};

export default ReferralHistory;
