/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { DOTS, usePagination } from '../../hooks/usePagination';
import clsx from 'clsx';
import Button from './Button';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import ArrowRightIcon from '../icons/ArrowRigthIcon';
import { Typo } from '../Typo';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
type PaginationPropsType = {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: 1 | number;
  currentPage: number;
  pageSize: number;
  type: 'centered-page-number' | 'simple-card-footer';
};

const Pagination: FC<PaginationPropsType> = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  type
}) => {
  const [currentPageNumber, setCurrentPageNumber] = useState(currentPage);
  const intl = useIntl();
  useEffect(() => {
    setCurrentPageNumber(currentPage);
  }, [currentPage]);

  const paginate = (pageNumber: number) => {
    onPageChange(pageNumber);
    setCurrentPageNumber(pageNumber);
  };
  const updatePage = (pageNumber: number) => {
    onPageChange(pageNumber);
    setCurrentPageNumber(pageNumber);
  };
  const paginationRange =
    (usePagination({
      currentPage,
      totalCount,
      siblingCount,
      pageSize
    }) as any[]) || [];

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    if (currentPage !== lastPage) {
      updatePage(currentPage + 1);
    }
  };

  const onPrevious = () => {
    if (currentPage !== 1) {
      updatePage(currentPage - 1);
    }
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  const totalFullPages = Math.floor(totalCount / pageSize);
  const hasRemainingItems = totalCount % pageSize !== 0;

  const totalPages = hasRemainingItems ? totalFullPages + 1 : totalFullPages;

  return (
    <nav
      className={clsx('flex items-center justify-between py-3 ', {
        'border-t-[1px] border-t-gray-200': type === 'simple-card-footer'
      })}>
      <div className="-mt-px flex w-0 flex-1">
        <Button onClick={onPrevious} className="px-[14px] " size="sm" theme="base">
          <div className="flex justify-center  gap-x-2 ">
            <div className={clsx({ 'block md:hidden': type === 'simple-card-footer' })}>
              <ArrowLeftIcon className=" w-5 h-5 " />
            </div>
            <span className="hidden md:block text-sm font-medium font-poppins text-gray-600">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.PREVIOUS.id,
                defaultMessage: TRANSLATED_CONSTANTS.PREVIOUS.defaultMessage
              })}
            </span>
          </div>
        </Button>
      </div>
      <div
        className={clsx({
          'hidden md:-mt-px md:flex': type === 'centered-page-number',
          hidden: type === 'simple-card-footer'
        })}>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <div key={index} className="h-[1.75rem] w-[1.75rem] flex justify-center items-center">
                <span className="text-[0.875rem] font-medium text-[#05173C]">. . .</span>
              </div>
            );
          }
          return (
            <Button
              size="sm"
              key={index}
              onClick={() => paginate(pageNumber)}
              className={`inline-flex items-center justify-center ${
                pageNumber === currentPageNumber
                  ? 'bg-gray-200 text-blue-gray-700 '
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } px-4 h-10 w-10 text-sm font-medium font-poppins`}>
              <span className="text-grray-500">{pageNumber}</span>
            </Button>
          );
        })}
      </div>
      <div className={clsx({ 'block md:hidden': type === 'centered-page-number' })}>
        <Typo.textSm
          fontWeight="font-medium"
          className="flex items-center justify-center gap-1 text-sm leading-tight font-poppins text-gray-700">
          <span className="font-normal">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.PAGE.id,
              defaultMessage: TRANSLATED_CONSTANTS.PAGE.defaultMessage
            })}
          </span>
          <span className="font-medium">{currentPageNumber} </span>
          <span className="font-normal">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.OF.id,
              defaultMessage: TRANSLATED_CONSTANTS.OF.defaultMessage
            })}
          </span>
          <span className="font-medium">{totalPages} </span>
        </Typo.textSm>
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end ">
        <Button onClick={onNext} className="px-[14px]" size="sm" theme="base">
          <div className="flex justify-center  gap-x-2 ">
            <span className="hidden md:block text-sm font-medium font-poppins text-gray-600">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.NEXT.id,
                defaultMessage: TRANSLATED_CONSTANTS.NEXT.defaultMessage
              })}
            </span>
            <div className={clsx({ 'block md:hidden': type === 'simple-card-footer' })}>
              <ArrowRightIcon className=" w-5 h-5  " />
            </div>
          </div>
        </Button>
      </div>
    </nav>
  );
};

export default Pagination;
