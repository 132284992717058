import clsx from 'clsx';
import React from 'react';

type DividerType = {
  className?: string;
};
const Divider = ({ className }: DividerType) => {
  return <div className={clsx('w-full h-[1px] bg-gray-200', className)} />;
};

export default Divider;
