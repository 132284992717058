type Props = {
  className?: string;
};
const StepIcon = ({ className }: Props) => {
  return (
    <>
      <svg className={`h-8 w-8 ${className}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.7952 9.85334L13.2485 19.0667L10.7152 16.36C10.2485 15.92 9.51516 15.8933 8.98182 16.2667C8.46182 16.6533 8.31515 17.3333 8.63515 17.88L11.6352 22.76C11.9285 23.2133 12.4352 23.4933 13.0085 23.4933C13.5552 23.4933 14.0752 23.2133 14.3685 22.76C14.8485 22.1333 24.0085 11.2133 24.0085 11.2133C25.2085 9.98668 23.7552 8.90668 22.7952 9.84001V9.85334Z"
          fill="currentColor"
        />
        <rect x="1" y="1" width="30" height="30" rx="15" stroke="#886307" strokeWidth="2" />
      </svg>
    </>
  );
};

export default StepIcon;
