import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import SettingWrapper from '../../components/wrapper/SettingWrapper';
import { AuthUserState } from '../../context/AuthUserProvider';
import { Typo } from '../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Button from '../../components/shared/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import SaveIcon from '../../components/icons/SaveIcon';
import Badge from '../../components/shared/Badge';
import Avatar from '../../components/shared/Avatar';
import User1Icon from '../../components/icons/User1Icon';
import { useCheckUserPendingRole } from '../../hooks/settings/useCheckUserPendingRole';
import { ROLE_TYPES, ROUTES } from '../../constants/data';
import Loader from '../../components/shared/Loader';
import MinusIcon from '../../components/icons/MinusIcon';
import { useState } from 'react';
import InfoIcon from '../../components/icons/InfoIcon';
import { useRemovePendingRole } from '../../hooks/settings/useRemovePedningRole';
import RedActionModal from '../../components/modal/RedActionModal';
const ModeratorSetting = () => {
  const userState = AuthUserState();
  const user = userState.authUser;
  const intl = useIntl();
  const removePendingRole = useRemovePendingRole();
  const { pendingRoles, loading } = useCheckUserPendingRole([ROLE_TYPES.MODERATOR], user?.id);
  const [changed, setChanged] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showLeaveModal, setShowLeaveModal] = useState(false);

  const onSaved = async () => {
    setIsSubmiting(true);
    await removePendingRole({ variables: { id: pendingRoles[0].id } });
    window.window.location.href = `${process.env.REACT_APP_BASE_URL}${ROUTES.PROFILE}`;
    setIsSubmiting(false);
  };

  const onCloseLeaveModal = (value: boolean) => {
    if (value) {
      setChanged(true);
    }
    setShowLeaveModal(false);
  };
  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <>
          {!loading ? (
            <div className="w-full">
              <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
                <DashboardHeader
                  title={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.MODERATOR_SETTINGS.id,
                    defaultMessage: TRANSLATED_CONSTANTS.MODERATOR_SETTINGS.defaultMessage
                  })}
                  description={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.REMOVE_MODERATOR_ROLE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.REMOVE_MODERATOR_ROLE.defaultMessage
                  })}
                  accountType={user?.type}
                  role={user?.role?.name}
                />
              </div>

              <div className="mx-auto max-w-7xl w-full">
                <div className="divide-y divide-gray-200">
                  <div className="py-5 px-4 md:px-0 w-full space-y-8 flex flex-col sm:flex-row  sm:col-span-1 sm:grid sm:grid-cols-3 sm:gap-x-8 sm:space-y-0">
                    <div className="col-span-1">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 ">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ROLE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ROLE.defaultMessage
                        })}
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.YOUR_CURRENT_ROLE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.YOUR_CURRENT_ROLE.defaultMessage
                        })}
                      </Typo.textSm>
                    </div>
                    <div className="sm:col-span-2">
                      {pendingRoles?.length > 0 && (
                        <div className="flex justify-between">
                          <div className="flex gap-x-3 items-center">
                            <div className="flex gap-x-3 items-center">
                              <Avatar
                                size="md"
                                icon={!user?.profileImage ? <User1Icon /> : ''}
                                image={user?.profileImage ? user?.profileImage?.url : ''}
                              />
                              <div>
                                <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                                  <FormattedMessage
                                    id={TRANSLATED_CONSTANTS.MODERATOR.id}
                                    defaultMessage={TRANSLATED_CONSTANTS.MODERATOR.defaultMessage}
                                  />
                                </Typo.textSm>
                                <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                  @{user?.username}
                                </Typo.textSm>
                              </div>
                            </div>
                            {pendingRoles?.length > 0 && pendingRoles[0]?.approved ? (
                              <Badge
                                size="sm"
                                theme={changed ? 'warning' : 'success'}
                                label={intl.formatMessage({
                                  id: changed
                                    ? TRANSLATED_CONSTANTS.LEAVE_ROLE_PENDING.id
                                    : TRANSLATED_CONSTANTS.APPROVED.id,
                                  defaultMessage: changed
                                    ? TRANSLATED_CONSTANTS.LEAVE_ROLE_PENDING.defaultMessage
                                    : TRANSLATED_CONSTANTS.APPROVED.defaultMessage
                                })}></Badge>
                            ) : (
                              <Badge
                                size="sm"
                                theme={changed ? 'warning' : 'gray'}
                                label={intl.formatMessage({
                                  id: changed
                                    ? TRANSLATED_CONSTANTS.REMOVE_APPLICATION_PENDING.id
                                    : TRANSLATED_CONSTANTS.PENGING.id,
                                  defaultMessage: changed
                                    ? TRANSLATED_CONSTANTS.REMOVE_APPLICATION_PENDING.defaultMessage
                                    : TRANSLATED_CONSTANTS.PENGING.defaultMessage
                                })}></Badge>
                            )}
                          </div>
                          {pendingRoles?.length > 0 && pendingRoles[0]?.approved ? (
                            <Button
                              icon={
                                changed ? <InfoIcon className="h-5 w-5 text-warning-700" /> : ''
                              }
                              size="md"
                              disabled={changed}
                              onClick={() => setShowLeaveModal(true)}
                              theme="gray-link">
                              <Typo.textSm
                                fontWeight="font-semibold"
                                className={` ${changed ? ' text-warning-700' : 'text-error-700'}`}>
                                <FormattedMessage
                                  id={
                                    changed
                                      ? TRANSLATED_CONSTANTS.LEAVING_ROLE.id
                                      : TRANSLATED_CONSTANTS.LEAVE_ROLE.id
                                  }
                                  defaultMessage={
                                    changed
                                      ? TRANSLATED_CONSTANTS.LEAVING_ROLE.defaultMessage
                                      : TRANSLATED_CONSTANTS.LEAVE_ROLE.defaultMessage
                                  }
                                />
                              </Typo.textSm>
                            </Button>
                          ) : (
                            <Button
                              disabled={changed}
                              onClick={() => setShowLeaveModal(true)}
                              loading={isSubmiting}
                              icon={
                                changed ? (
                                  <InfoIcon className="h-5 w-5 text-warning-700" />
                                ) : (
                                  <MinusIcon className="h-5 w-5 text-error-700" />
                                )
                              }
                              size="md"
                              theme="gray-link">
                              <Typo.textSm
                                fontWeight="font-semibold"
                                className={changed ? 'text-warning-600' : 'text-error-600'}>
                                <FormattedMessage
                                  id={
                                    changed
                                      ? TRANSLATED_CONSTANTS.REMOVING_APPLICATION.id
                                      : TRANSLATED_CONSTANTS.REMOVING_APPLICATION.id
                                  }
                                  defaultMessage={
                                    changed
                                      ? TRANSLATED_CONSTANTS.REMOVE_APPLICATION.defaultMessage
                                      : TRANSLATED_CONSTANTS.REMOVE_APPLICATION.defaultMessage
                                  }
                                />
                              </Typo.textSm>
                            </Button>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="flex justify-end gap-x-3  pt-5">
                    <Button
                      disabled={!changed}
                      onClick={() => setChanged(false)}
                      size="lg"
                      theme="base">
                      <Typo.textMd fontWeight="font-semibold">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CANCEL.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                        })}
                      </Typo.textMd>
                    </Button>
                    <Button
                      size="lg"
                      onClick={onSaved}
                      disabled={!changed}
                      theme="primary-yellow"
                      icon={<SaveIcon className="h-5 w-4 text-gray-900" />}>
                      <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.SAVE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.SAVE.defaultMessage
                        })}
                      </Typo.textMd>
                    </Button>
                  </div>
                </div>
              </div>
              <RedActionModal
                title={TRANSLATED_CONSTANTS.LEAVE_MOD_MODAL_TITLE}
                isOpen={showLeaveModal}
                onCloseRedActionModal={(value: boolean) => onCloseLeaveModal(value)}
                subTitle={TRANSLATED_CONSTANTS.LEAVE_MOD_MODAL_SUBTITLE}
                confirmText={TRANSLATED_CONSTANTS.LEAVE_MOD_MODAL_CONFIRM_BUTTON}
                cancelText={TRANSLATED_CONSTANTS.LEAVE_MOD_MODAL_CANCEL_BUTTON}
                icon={<InfoIcon />}
                actionButtonsFlow="vertical"
              />
            </div>
          ) : (
            <Loader />
          )}
        </>
      </SettingWrapper>
    </div>
  );
};

export default ModeratorSetting;
