import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_PENDING_ROLES = gql`
  query getPendingRoles($assigneeId: ID, $assignerId: ID) {
    pendingRoles(
      filters: {
        or: { assignee: { id: { eq: $assigneeId } }, assigner: { id: { eq: $assignerId } } }
      }
    ) {
      data {
        id
        attributes {
          role
          approved
          date
          assignee {
            data {
              id
            }
          }
          assigner {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const usePendingRoles = (assigneeId?: number, assignerId?: number) => {
  const { loading, error, data, refetch } = useQuery(GET_PENDING_ROLES, {
    fetchPolicy: 'cache-and-network',
    variables: { assigneeId: assigneeId, assignerId: assignerId }
  });
  return {
    pLoading: loading,
    pError: error,
    pendingRoles: flattenObj(data?.pendingRoles),
    pRefetch: refetch
  };
};
