import { FormattedMessage } from 'react-intl';
import { Typo } from '../components/Typo';
import PageWrapper from '../components/wrapper/PageWrapper';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';

function TermOfService() {
  return (
    <PageWrapper hasSpeicifWidth={true}>
      <div className="w-full items-center max-w-[720px] mx-auto">
        {/* main title  */}
        <Typo.textMd fontWeight="font-medium" className="w-full text-center text-yellow-600">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.TERMS_SERVICE_MAIN_TITLE.id}
            defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_MAIN_TITLE.defaultMessage}
          />
        </Typo.textMd>
        {/* title 1 */}
        <Typo.headingLg fontWeight="font-semibold" className="text-gray-900 mt-3 text-center">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.TERMS_SERVICE_TITLE1.id}
            defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_TITLE1.defaultMessage}
          />
        </Typo.headingLg>
        {/* p1 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 mt-6 text-center">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P1.id}
            defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P1.defaultMessage}
          />
        </Typo.textLg>
        {/* p2 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-24">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.TERMS_SERVICE_TITLE2.id}
            defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_TITLE2.defaultMessage}
          />
        </Typo.textLg>
        {/* title 2 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P2.id}
            defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P2.defaultMessage}
          />
        </Typo.headingSm>
        <div className="text-gray-600 space-y-5 mt-5">
          {/* p3 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P3.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P3.defaultMessage}
            />
          </Typo.textLg>
          {/* p4 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P4.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P4.defaultMessage}
            />
          </Typo.textLg>
          {/* p5 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P5.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P5.defaultMessage}
            />
          </Typo.textLg>
          {/* p6 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P6.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P6.defaultMessage}
            />
          </Typo.textLg>
          {/* p7 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P7.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P7.defaultMessage}
            />
          </Typo.textLg>
          {/* p8 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P8.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P8.defaultMessage}
            />
          </Typo.textLg>
          {/* p9 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P9.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P9.defaultMessage}
            />
          </Typo.textLg>
          {/* p10 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P10.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P10.defaultMessage}
            />
          </Typo.textLg>
          {/* p11 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P11.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P11.defaultMessage}
            />
          </Typo.textLg>
          {/* p12 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P12.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P12.defaultMessage}
            />
          </Typo.textLg>
          {/* p13 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P13.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P13.defaultMessage}
            />
          </Typo.textLg>
          {/* p14 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P14.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P14.defaultMessage}
            />
          </Typo.textLg>
          {/* p15 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P15.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P15.defaultMessage}
            />
          </Typo.textLg>
          {/* p16 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P16.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P16.defaultMessage}
            />
          </Typo.textLg>
          {/* p17 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P17.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P17.defaultMessage}
            />
          </Typo.textLg>
          {/* p18 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P18.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P18.defaultMessage}
            />
          </Typo.textLg>
          {/* p19 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P19.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P19.defaultMessage}
            />
          </Typo.textLg>
          {/* p20 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P20.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P20.defaultMessage}
            />
          </Typo.textLg>
          {/* p21 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P21.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P21.defaultMessage}
            />
          </Typo.textLg>
          {/* p22 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P22.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P22.defaultMessage}
            />
          </Typo.textLg>
          {/* p23 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P23.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P23.defaultMessage}
            />
          </Typo.textLg>
          {/* p24 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P24.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P24.defaultMessage}
            />
          </Typo.textLg>
          {/* p25 */}
          <Typo.textLg fontWeight="font-normal">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.TERMS_SERVICE_P25.id}
              defaultMessage={TRANSLATED_CONSTANTS.TERMS_SERVICE_P25.defaultMessage}
            />
          </Typo.textLg>
        </div>
      </div>
    </PageWrapper>
  );
}

export default TermOfService;
