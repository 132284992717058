import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_BLOCKED_USERS = gql`
  query getUnBlockedUser($blockerId: ID, $searchText: String, $limit: Int, $offset: Int) {
    unBlockedAmount: usersPermissionsUsers(
      filters: {
        and: [
          { id: { ne: $blockerId } }
          { blocked_user: { blocked_by: { or: [{ id: null }, { id: { ne: $blockerId } }] } } }
          { or: [{ name: { containsi: $searchText } }, { username: { containsi: $searchText } }] }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      pagination: { start: $offset, limit: $limit }
      filters: {
        and: [
          { id: { ne: $blockerId } }
          { blocked_user: { blocked_by: { or: [{ id: null }, { id: { ne: $blockerId } }] } } }
          { or: [{ name: { containsi: $searchText } }, { username: { containsi: $searchText } }] }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          type
          createdAt
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          headerImage {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUnBlockedUsers = (
  offset: number,
  limit: number,
  searchText: string,
  blockerId: number
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_BLOCKED_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText, blockerId }
  });
  return {
    unBlockedUsers: flattenObj(data?.usersPermissionsUsers),
    unBlockedUsersCounts: data?.unBlockedAmount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
