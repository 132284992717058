import { GET_USER } from './useGetUser';
import { gql, useMutation } from '@apollo/client';

interface UsersPermissionsRegisterInput {
  variables: {
    input: {
      username: string;
      email: string;
      password: string;
    };
  };
}

export const CREATE_ACCOUNT = gql`
  mutation SignupMutation($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        email
        username
      }
    }
  }
`;

export const useCreateAccount = (): ((createAccount: UsersPermissionsRegisterInput) => any) => {
  const [createAcountResult] = useMutation(CREATE_ACCOUNT, {
    refetchQueries: [GET_USER, 'GetAccount']
  });
  return createAcountResult;
};
