import React, { useState } from 'react';
import PageWrapper from '../../components/wrapper/PageWrapper';
import { Typo } from '../../components/Typo';
import Address from '../../components/shared/Address';
import Button from '../../components/shared/Button';
import ShareIcon from '../../components/icons/ShareIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Divider from '../../components/shared/Divider';
import StorefrontCard from './StorefrontCard';
import StoreHoursCard from './StoreHoursCard';
import AccountInfo from '../../components/shared/AccountInfo';
import DisLikeIcon from '../../components/icons/DisLikeIcon';
import LikeIcon from '../../components/icons/LikeButton';
import ReportButton from '../../components/shared/ReportButton';
import DirectionButton from './DirectionButton';
import { useGetStorefront } from '../../hooks/storfront/useGetStorefront';
import { AuthUserState } from '../../context/AuthUserProvider';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import Loader from '../../components/shared/Loader';
import OperationHourLabel from '../../components/shared/Hours Operation/OperationHourLabel';
import StorefrontItems from './StorefrontItems';
import ImageSlider from '../../components/shared/ImageSlider/ImageSlider';
import { Image } from '../../model/Image';
import Location from '../../components/shared/map/Location';
import { getCurrentDate } from '../../util/CurrentDate';
import StorefrontSlideScreen from '../../components/dashboard/slide/StorefrontSlide';
import { useReportStorefront } from '../../hooks/listing/useReportStorefront';
import { useNavigate } from 'react-router-dom';
import { useHandleSave } from '../../hooks/useHandleSave';
import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
import { useDispatch } from 'react-redux';
import { showModal } from '../../lib/redux/reducers/modal.slice';
import ReportModal from '../../components/modal/ReportModal';
import { setDirectionData } from '../../lib/redux/reducers/direction.slice';
import { setShareData } from '../../lib/redux/reducers/share.slice';
import { ROLES, ROLE_TYPES, ROUTES } from '../../constants/data';
import { useHandleUserDecisions } from '../../hooks/listing/useHandelUserDecisions';
import { addNotification } from '../../lib/redux/reducers/notification.slice';
import { useAppSelector } from '../../lib/redux/hooks';
import PrimaryActionModal from '../../components/modal/PrimaryActionModal';
import TrashIcon from '../../components/icons/TrashIcon';
import { useWarnDelete } from '../../hooks/reports/useWarnDelete';
import { useGetUserRole } from '../../hooks/useRole';
import { setProfileData, showSlide } from '../../lib/redux/reducers/slide.slice';
const StorefrontDetails = () => {
  const dispatch = useDispatch();
  const [showReportListingModal, setShowReportListingModal] = useState(false);
  const [showWarnAndDeleteModal, setShowWarnAndDeleteModal] = useState(false);
  const [showStorefrontSlide, setShowStorefrontSlide] = useState(false);
  const reportStorefront = useReportStorefront();
  const handleUserDecisions = useHandleUserDecisions();
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const navigate = useNavigate();
  const auth = AuthUserState();
  const user = auth.authUser;
  const intl = useIntl();
  const { id } = useParams();
  const { storefront, loading, refetch } = useGetStorefront(Number(id), user?.id || null, -1);
  const { save } = useHandleSave(refetch);
  const warnDelete = useWarnDelete();
  const storefrontUser = storefront?.user;
  const isUserOnline = useCheckOnlineUsers(Number(storefrontUser?.id));
  const profileState = useAppSelector((state) => state.slide.profile);

  const role = useGetUserRole();

  const onSave = async (liked: boolean, savedId: number | undefined) => {
    save(storefront?.id, 'storefront', liked, savedId);
  };

  const onCloseReportModal = (value: boolean) => {
    setShowReportListingModal(value);
  };

  const onReport = async (detail: string, claim: string) => {
    await reportStorefront({
      variables: {
        data: {
          detail: detail,
          claim: claim,
          store_front: Number(storefront?.id) || 0,
          reporter: user?.id,
          date: new Date(),
          publishedAt: getCurrentDate()
        }
      }
    }).then(async (response: any) => {
      if (response) {
        await handleUserDecisions.changeDecisions(user?.id, user?.decisions + 1);
        dispatch(
          addNotification({
            id:
              notifications && notifications?.length > 0
                ? notifications[notifications?.length - 1]?.id + 1
                : 1,
            title: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.STOREFRONT_REPORT_ALERT_TITLE.id,
              defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_REPORT_ALERT_TITLE.defaultMessage
            }),
            description: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_DESCRIPTION.id,
              defaultMessage: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_DESCRIPTION.defaultMessage
            }),
            type: 'flag',
            linkText: '',
            link: '',
            linkIcon: false
          })
        );
        setShowReportListingModal(false);
        navigate(-1);
      }
    });
  };

  const handleReportButtonClicked = () => {
    if (!user) {
      dispatch(showModal('login'));
    } else {
      setShowReportListingModal(true);
    }
  };

  const onGetDirection = () => {
    dispatch(showModal('direction'));
    dispatch(
      setDirectionData({
        current: {
          lat: user?.location?.latitude,
          long: user?.location?.longitude
        },
        destination: {
          lat: storefront?.location?.latitude,
          long: storefront?.location?.longitude
        }
      })
    );
  };

  const onShare = () => {
    dispatch(
      setShareData({ isOpen: true, url: `${ROUTES.STOREFRONT_DETAILS_ROUTE}/${storefront?.id}` })
    );
  };

  const onCloseWarnAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowWarnAndDeleteModal(false);
      return;
    }
    await warnDelete({
      warnedId: storefrontUser.id,
      warnerId: user.id,
      storefrontId: storefront.id,
      warnUserEmail: storefrontUser.email,
      userName: storefrontUser.username,
      claim: '',
      detail: ''
    }).then(() => {
      navigate(-1);
    });
    setShowWarnAndDeleteModal(false);
  };

  const openProfile = () => {
    // if (role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.SUPER_ADMIN) {
    if (!profileState) {
      dispatch(setProfileData({ userId: storefrontUser?.id }));
      dispatch(showSlide('profile'));
    }
    // }
  };

  return (
    <div>
      <PageWrapper hasSpeicifWidth={true}>
        {!loading && storefront ? (
          <div className="flex flex-col gap-8">
            <DashboardHeader
              title={storefront?.name}
              cancelButtonIcon={<ShareIcon className="text-gray-700 w-5 h-5" />}
              onCancelClick={() => onShare()}
              cancelButtonTitle={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SHARE.id,
                defaultMessage: TRANSLATED_CONSTANTS.SHARE.defaultMessage
              })}
              confirmDisabled={user?.id == storefront?.user?.id}
              onConfrimClick={
                storefront?.saved_storefronts && storefront?.saved_storefronts?.length > 0
                  ? () => onSave(true, Number(storefront?.saved_storefronts[0]?.id))
                  : () => onSave(false, undefined)
              }
              hasDeleteOption={role === ROLES.SUPER_ADMIN}
              deleteText={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.DELETE_STOREFRONT.id,
                defaultMessage: TRANSLATED_CONSTANTS.DELETE_STOREFRONT.defaultMessage
              })}
              onDeleteClick={() => setShowWarnAndDeleteModal(true)}
              confirmButtonTitle={intl.formatMessage({
                id:
                  storefront?.saved_storefronts && storefront?.saved_storefronts?.length > 0
                    ? TRANSLATED_CONSTANTS.UNSAVE.id
                    : TRANSLATED_CONSTANTS.SAVE.id,
                defaultMessage:
                  storefront?.saved_storefronts && storefront?.saved_storefronts?.length > 0
                    ? TRANSLATED_CONSTANTS.UNSAVE.defaultMessage
                    : TRANSLATED_CONSTANTS.SAVE.defaultMessage
              })}
              description={
                <div className="flex items-center mt-1 gap-6">
                  <Address address={storefront?.location?.address} />
                  <div className="hidden md:block"></div>
                </div>
              }></DashboardHeader>
            {/* Hero section  */}
            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-8">
              <ImageSlider
                type="storefront"
                className="w-full lg:col-span-2"
                imageUrls={storefront?.image?.map((img: Image) => img?.url)}
              />
              <div className="w-full flex flex-col md:flex-row lg:col-span-1 lg:flex-col gap-5">
                {/* Storefront basic info  */}
                <StorefrontCard
                  operationHours={storefront?.operationHours || []}
                  showReportModal={handleReportButtonClicked}
                  getDirection={() => onGetDirection()}
                  reportDisabled={
                    (storefront?.storefront_reports && storefront.storefront_reports?.length > 0) ||
                    user?.id == storefront?.user?.id
                  }
                />
                {/* Timing  */}
                <StoreHoursCard />
              </div>
            </div>
            {/* Listing Details section  */}
            <div className="w-full flex-col ">
              <div className="flex flex-col-reverse md:flex-row gap-5 mb-6">
                <div className="flex-1 ">
                  <Typo.headingXs fontWeight="font-medium" className="text-gray-900 mb-2">
                    {storefront?.name}
                  </Typo.headingXs>
                  <div className="flex gap-2 items-center">
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CATEGORY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                      })}
                    </Typo.textMd>
                    <div className="w-1.5 h-1.5 p-0.5 justify-center items-center inline-flex">
                      <div className="w-0.5 h-0.5 bg-gray-600 rounded-full" />
                    </div>
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {storefront?.category?.name}
                    </Typo.textMd>
                  </div>
                </div>
                <AccountInfo
                  openProfile={openProfile}
                  className="flex-1 flex justify-start gap-4 items-start"
                  name={storefrontUser?.name}
                  isOnline={isUserOnline}
                  username={storefrontUser?.username}
                  profileImage={storefrontUser?.profileImage?.url}
                />
              </div>
              <Divider />
              <div className="flex flex-col gap-6 mt-6 max-w-[824px] mb-6">
                <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.DESCRIPTION.id}
                    defaultMessage={TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage}
                  />
                </Typo.textMd>
                <Typo.textMd fontWeight="font-normal" className="text-gray-500">
                  {storefront?.description}
                </Typo.textMd>
                <div className="flex gap-4 flex-wrap md:gap-6">
                  <OperationHourLabel
                    defaultValue={storefront?.operationHours || []}></OperationHourLabel>
                  <Address address={storefront?.location?.address || ''} />
                </div>
                <div className="flex gap-3 w-full mt-5">
                  <Button
                    className=""
                    disabled={user?.id == storefront?.user?.id}
                    onClick={
                      storefront?.saved_storefronts && storefront?.saved_storefronts?.length > 0
                        ? () => onSave(true, Number(storefront?.saved_storefronts[0]?.id))
                        : () => onSave(false, undefined)
                    }
                    size="md"
                    theme="base"
                    icon={
                      storefront?.saved_storefronts && storefront?.saved_storefronts?.length > 0 ? (
                        <DisLikeIcon />
                      ) : (
                        <LikeIcon />
                      )
                    }></Button>
                  <ReportButton
                    disabled={
                      (storefront?.storefront_reports &&
                        storefront.storefront_reports?.length > 0) ||
                      user?.id == storefront?.user?.id
                    }
                    openReportModal={handleReportButtonClicked}
                  />
                  <DirectionButton getDirection={() => onGetDirection()} />
                </div>
              </div>
              <Divider />
            </div>
            {/* Storefront Items  */}
            {storefront && (
              <StorefrontItems
                listings={storefront?.listings || []}
                storefront={storefront}
                onSave={save}
                onViewMore={() => setShowStorefrontSlide(true)}
              />
            )}
            {/* Location  */}
            <div className="flex flex-col gap-6">
              <Typo.headingXs fontWeight="font-medium" className="text-gray-900">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.MEETUP_LOCATION.id}
                  defaultMessage={TRANSLATED_CONSTANTS.MEETUP_LOCATION.defaultMessage}
                />
              </Typo.headingXs>
              <div className="h-[400px] w-full bg-[#E2E2E2] rounded-lg">
                {storefront && <Location className="w-full h-[400px]" storeFronts={[storefront]} />}
              </div>
              <div className="flex flex-col-reverse md:flex-row justify-start items-start gap-6 md:gap-16 mb-6">
                <div className="flex flex-col gap-6">
                  <AccountInfo
                    // openProfile={openProfile}
                    className=" flex justify-start gap-4 items-start"
                    name={storefrontUser?.name}
                    isOnline={isUserOnline}
                    username={storefrontUser?.username}
                    profileImage={storefrontUser?.profileImage?.url}
                  />
                  <Button onClick={onGetDirection} theme="base" size="lg">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.GET_DIRECTIONS.id}
                        defaultMessage={TRANSLATED_CONSTANTS.GET_DIRECTIONS.defaultMessage}
                      />
                    </Typo.textMd>
                  </Button>
                </div>
                <div className="flex flex-col gap-2">
                  {storefront?.location?.address ? (
                    <Address address={storefront?.location.address} />
                  ) : (
                    ''
                  )}
                  <Typo.textMd fontWeight="font-normal" className="text-gray-500 max-w-[760px]">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.LISTING_ADDRESS_DESCRIPTION.id}
                      defaultMessage={
                        TRANSLATED_CONSTANTS.LISTING_ADDRESS_DESCRIPTION.defaultMessage
                      }
                    />
                  </Typo.textMd>
                </div>
              </div>
              <Divider />
            </div>
          </div>
        ) : (
          <Loader className=" h-screen" />
        )}
      </PageWrapper>
      <ReportModal
        onReport={onReport}
        isOpen={showReportListingModal}
        onCloseReportModal={(value: boolean) => onCloseReportModal(value)}
        type="listing"
        confirmText={TRANSLATED_CONSTANTS.REPORT_STOREFRONT_MODAL_CONFIRM_BUTTON}
        modalTitle={TRANSLATED_CONSTANTS.REPORT_STOREFRONT_MODAL_TITLE}
        dropDownLabel={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.REPORT_STOREFRONT_MODAL_LABEL.id,
          defaultMessage: TRANSLATED_CONSTANTS.REPORT_STOREFRONT_MODAL_LABEL.defaultMessage
        })}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarnAndDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseWarnAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
      {storefront && (
        <StorefrontSlideScreen
          storefront={storefront}
          onClose={() => setShowStorefrontSlide(false)}
          open={showStorefrontSlide}
          onReport={() => setShowReportListingModal(true)}
        />
      )}
    </div>
  );
};

export default StorefrontDetails;
