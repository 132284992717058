import React, { useEffect, useState } from 'react';
import { REPORT_PAGES } from '../../../../constants/data';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { ActivityItemPropsType } from '../../../../model/Activity';
import { useGetListingDetailedReports } from '../../../../hooks/reports/useGetDetailReportedListing';
import { useDispatch } from 'react-redux';
import { addClickedActivityData } from '../../../../lib/redux/reducers/report.slice';
import RedActionModal from '../../../../components/modal/RedActionModal';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import FlagIcon from '../../../../components/icons/FlagIcon';
import TrashIcon from '../../../../components/icons/TrashIcon';
import UserXIcon from '../../../../components/icons/UserXIcon';
import Loader from '../../../../components/shared/Loader';
import {
  handleListingDetailConstants,
  handleReportActivityInfo
} from '../../../../util/ReportUtil';
import EmptyReportListings from '../../EmptyReportListings';
import PrimaryActionModal from '../../../../components/modal/PrimaryActionModal';
import ListingDetailedProfileHeader from '../deletedAndQuarantine/StorefrontDetailedProfileHeader';
import Contact from '../../detailedLayoutComponents/Contact';
import Divider from '../../../../components/shared/Divider';
import { Typo } from '../../../../components/Typo';
import { handleAccountTypeBadge } from '../../../../util/ListingBadgeHandler';
import DetailedLayoutListingCards from '../../detailedLayoutComponents/DetailedLayoutListingCards';
import Reporter from '../../detailedLayoutComponents/Reporter';
import Reason from '../../detailedLayoutComponents/Reason';
import ActivityList from '../../../../components/activities/ActivityList';
import Pagination from '../../../../components/shared/Pagination';
import Button from '../../../../components/shared/Button';
import { useHandleStorefrontFlaggedReports } from '../../../../hooks/useHandleStorefrontFlaggedReports';
import { useGetStorefrontDetailedReports } from '../../../../hooks/reports/useGetDetailedStorefrontReports';

const FlaggedStorefrontDetailedLayout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const selectedGridRow = useAppSelector((state) => state.report.reportLayout);
  const clickedActivityItem: ActivityItemPropsType | any = useAppSelector(
    (state) => state.report.clickedActivityData
  );
  const [pageAndOffset, setPageAndOffset] = useState({ offset: 0, currentPage: 1 });
  const { loading, error, storefrontReport, storefrontReportsCount, refetch, fetchMore } =
    useGetStorefrontDetailedReports(pageAndOffset.offset, pageAndOffset.offset + 1, searchInput);
  const refetchData = () => {
    refetch();
  };
  const {
    showClearFlagModal,
    showBanModal,
    showBanAndDeleteModal,
    onClearAllFlagButtonClicked,
    onBanUserButtonClicked,
    onCloseBanModal,
    onCloseClearAllFlagModal,
    showWarnAndDeleteModal,
    onCloseWarnAndDeleteModal,
    onWarnUserButtonClicked,
    onCloseDetailedBanAndDeleteModal,
    onDetailedBanDeleteUserButtonClicked
  } = useHandleStorefrontFlaggedReports(refetchData);
  const activityHeader = handleReportActivityInfo(REPORT_PAGES.LISTING_FLAG, intl);

  const fetchMoreData = (pageNumber: number) => {
    dispatch(addClickedActivityData({ clickedActivityData: null }));
    const startIndex = pageNumber - 1;
    setPageAndOffset({
      offset: startIndex,
      currentPage: pageNumber
    });
    fetchMore({
      variables: { offset: startIndex, limit: startIndex + 1 }
    });
  };

  useEffect(() => {
    setPageAndOffset({
      offset: selectedGridRow.index,
      currentPage: selectedGridRow.index + 1
    });
  }, [selectedGridRow]);

  const visibleActivityItem = clickedActivityItem
    ? clickedActivityItem
    : storefrontReport?.activity[0];

  const accountType = handleAccountTypeBadge(visibleActivityItem?.accountType || '', intl);
  const issuedListingConstants = handleListingDetailConstants(
    accountType,
    REPORT_PAGES.LISTING_FLAG
  );
  return (
    <div>
      {!storefrontReport && !loading ? (
        <EmptyReportListings
          title={{
            id: 'page.reports.nothing_reported_title',
            defaultMessage: 'Nothing reported yet'
          }}
          subtitle={{
            id: 'page.reports.nothing_reported_subtitle',
            defaultMessage: 'You don’t have any flagged posts yet.'
          }}
          icon={<FlagIcon className="w-6 h-6 md:h-7 md:w-7 text-yellow-600" />}
        />
      ) : (
        <div>
          {loading ? (
            <Loader className="h-screen" />
          ) : (
            <div>
              <div className="w-full flex flex-col lg:flex-row lg:gap-16">
                <div className="w-full flex gap-8 flex-col">
                  <ListingDetailedProfileHeader
                    pageType={REPORT_PAGES.LISTING_FLAG}
                    user={storefrontReport?.user}
                    openBanModal={() =>
                      onBanUserButtonClicked(storefrontReport.user, storefrontReport)
                    }
                    warnings={0}
                    flags={storefrontReport?.flags}
                  />
                  <div className="md:px-4 lg:pl-4 lg:pr-0 pb-8">
                    <Contact email={storefrontReport?.user?.email} />
                    <div className="my-8">
                      <Divider />
                    </div>
                    {storefrontReport && (
                      <DetailedLayoutListingCards
                        report={storefrontReport}
                        pageType={REPORT_PAGES.LISTING_FLAG}
                        activityItemData={visibleActivityItem}
                        title={issuedListingConstants.title}
                        subtitle={issuedListingConstants.subtitle}
                      />
                    )}
                    <div className="my-8">
                      <Divider />
                    </div>
                    <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
                      <div className="w-[280px]">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                          {issuedListingConstants.reporterTitle}
                        </Typo.textSm>
                        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                          {issuedListingConstants.reporterSubTitle}
                        </Typo.textSm>
                      </div>
                      <Reporter activityItemData={visibleActivityItem} />
                    </div>
                    <div className="my-8">
                      <Divider />
                    </div>
                    <Reason
                      claim={clickedActivityItem?.claim || storefrontReport?.activity[0]?.claim}
                      detail={visibleActivityItem?.detail}
                    />
                    <div className="mt-8 mb-5">
                      <Divider />
                    </div>

                    <div className="flex flex-col md:flex-row w-full md:justify-between gap-3 ">
                      <Button
                        onClick={() => {
                          onClearAllFlagButtonClicked(storefrontReport);
                        }}
                        size="md"
                        theme="gray-link">
                        <Typo.textSm fontWeight="font-medium" className="text-error-600">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.CLEAR_FLAGS.id}
                            defaultMessage={TRANSLATED_CONSTANTS.CLEAR_FLAGS.defaultMessage}
                          />
                        </Typo.textSm>
                      </Button>
                      <div className="flex flex-col md:flex-row gap-3 ">
                        <Button
                          onClick={() => {
                            onWarnUserButtonClicked(
                              storefrontReport,
                              visibleActivityItem?.claim || '',
                              visibleActivityItem?.detail || ''
                            );
                          }}
                          size="md"
                          theme="base">
                          <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.DELETE_AND_WARNING.id}
                              defaultMessage={
                                TRANSLATED_CONSTANTS.DELETE_AND_WARNING.defaultMessage
                              }
                            />
                          </Typo.textSm>
                        </Button>
                        <Button
                          onClick={() => {
                            onDetailedBanDeleteUserButtonClicked(
                              storefrontReport,
                              visibleActivityItem?.claim || '',
                              visibleActivityItem?.detail || ''
                            );
                          }}
                          size="md"
                          theme="error-outline">
                          <Typo.textSm fontWeight="font-medium" className="text-error-600">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.DELETE_AND_BAN.id}
                              defaultMessage={TRANSLATED_CONSTANTS.DELETE_AND_BAN.defaultMessage}
                            />
                          </Typo.textSm>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-8 lg:mr-4 lg:mt-4 md:mx-4 ">
                  <ActivityList
                    activityHeader={activityHeader}
                    activityData={storefrontReport?.activity}
                  />
                </div>
              </div>
              <Divider />
              <div className="px-6">
                <Pagination
                  currentPage={pageAndOffset.currentPage}
                  totalCount={storefrontReportsCount}
                  pageSize={1}
                  onPageChange={fetchMoreData}
                  type="simple-card-footer"
                />
              </div>
            </div>
          )}
        </div>
      )}

      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarnAndDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseWarnAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />

      <RedActionModal
        title={TRANSLATED_CONSTANTS.CLEAR_FLAG_MODAL_TITLE}
        isOpen={showClearFlagModal}
        onCloseRedActionModal={(value: boolean) => onCloseClearAllFlagModal(value)}
        subTitle={TRANSLATED_CONSTANTS.CLEAR_FLAG_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.CLEAR_ALL_FLAG_MODAL_CONFIRM_BUTTON}
        icon={<FlagIcon />}
      />
      <RedActionModal
        title={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_TITLE}
        isOpen={showBanAndDeleteModal}
        onCloseRedActionModal={(value: boolean) => onCloseDetailedBanAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
      <RedActionModal
        title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
        isOpen={showBanModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
        username={storefrontReport?.user?.username}
      />
    </div>
  );
};

export default FlaggedStorefrontDetailedLayout;
