import React, { FC, useEffect, useState } from 'react';
import Modal from './Modal';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import AlertIcon from '../icons/AlertIcon';
import Divider from '../shared/Divider';
import { Typo } from '../Typo';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../shared/Button';
import CalendarIcon from '../icons/CalendarIcon';
import { useAppSelector } from '../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../lib/redux/reducers/modal.slice';
import { isVerifiedAge } from '../../util/AppUtil';
import clsx from 'clsx';
type Props = {
  isOpen: boolean;
  onCloseAgeVerificationModal: (arg0: boolean) => void;
};
const AgeVerificationModal: FC<Props> = ({ isOpen, onCloseAgeVerificationModal }) => {
  const ageState = useAppSelector((state) => state.signup.age);
  const intl = useIntl();
  const [isVerified, setIsVerified] = useState(false);
  const dispatch = useDispatch();
  const onOpenCalendar = () => {
    dispatch(showModal('date'));
    dispatch(hideModal('age'));
  };

  useEffect(() => {
    if (ageState) {
      setIsVerified(isVerifiedAge(new Date(ageState)));
    }
  }, [ageState]);
  return (
    <Modal
      onCancel={() => onCloseAgeVerificationModal(false)}
      onConfirm={() => onCloseAgeVerificationModal(false)}
      isOpen={isOpen}
      confirmText={TRANSLATED_CONSTANTS.AGE_VERIFICATION_MODAL_CONFIRM_BUTTON}
      title={TRANSLATED_CONSTANTS.AGE_VERIFICATION_MODAL_TITLE}
      subTitle={TRANSLATED_CONSTANTS.AGE_VERIFICATION_SUBTITLE}
      actionButtonsFlow="horizontal"
      theme="primary"
      headerType="one-row"
      formId="age-verification"
      confirmBtnDisabled={!isVerified}
      icon={<AlertIcon></AlertIcon>}>
      <div>
        <div className="mt-5 -mx-6">
          <Divider />
        </div>
        <div className="flex flex-row justify-between items-start mt-4 md:mt-6">
          <Typo.textSm fontWeight="font-medium" className="text-gray-700">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.DATE_OF_BIRTH.id}
              defaultMessage={TRANSLATED_CONSTANTS.DATE_OF_BIRTH.defaultMessage}
            />
            *
          </Typo.textSm>
          <Button
            onClick={onOpenCalendar}
            className={clsx(
              'border',
              ageState && !isVerified
                ? ' border-error-300'
                : 'border-gray-200 focus:ring-4 focus:ring-gray-100'
            )}
            icon={
              <CalendarIcon
                className={clsx(
                  'h-5 w-5 ',
                  ageState && !isVerified ? 'text-error-700' : 'text-gray-700'
                )}></CalendarIcon>
            }>
            <Typo.textSm
              fontWeight="font-semibold"
              className={clsx(ageState && !isVerified ? 'text-error-700' : 'text-gray-500')}>
              {ageState
                ? ageState
                : intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.SELECT_DATE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.SELECT_DATE.defaultMessage
                  })}
            </Typo.textSm>
          </Button>
        </div>
        {ageState && !isVerified && (
          <div className="mt-5">
            <Typo.textSm fontWeight="font-normal" className="text-error-500">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.AGE_VERIFICATION_SUBTITLE.id,
                defaultMessage: TRANSLATED_CONSTANTS.AGE_VERIFICATION_SUBTITLE.defaultMessage
              })}
            </Typo.textSm>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AgeVerificationModal;
