import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { useGetDetailedDeletedStorefronts } from '../../../../hooks/reports/useGetDetailedDeletedStorefronts';
import { useHandleIssuedListings } from '../../../../hooks/useHandleIssuedListings';
import Loader from '../../../../components/shared/Loader';
import { REPORT_PAGES } from '../../../../constants/data';
import RedActionModal from '../../../../components/modal/RedActionModal';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import UserXIcon from '../../../../components/icons/UserXIcon';
import PrimaryActionModal from '../../../../components/modal/PrimaryActionModal';
import TagIcon from '../../../../components/icons/TagIcon';
import { ActivityItemPropsType } from '../../../../model/Activity';
import { useDispatch } from 'react-redux';
import {
  handleListingDetailConstants,
  handleReportActivityInfo
} from '../../../../util/ReportUtil';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyReportListings from '../../EmptyReportListings';
import TrashIcon from '../../../../components/icons/TrashIcon';
import StorefrontDetailedProfileHeader from './StorefrontDetailedProfileHeader';
import Contact from '../../detailedLayoutComponents/Contact';
import Divider from '../../../../components/shared/Divider';
import DetailedLayoutListingCards from '../../detailedLayoutComponents/DetailedLayoutListingCards';
import { handleAccountTypeBadge, handleUserRoleBadge } from '../../../../util/ListingBadgeHandler';
import ActivityList from '../../../../components/activities/ActivityList';
import { Typo } from '../../../../components/Typo';
import UserInfo from '../../../../components/shared/table/UserInfo';
import { setChatData, setProfileData, showSlide } from '../../../../lib/redux/reducers/slide.slice';
import { AuthUserState } from '../../../../context/AuthUserProvider';
import Button from '../../../../components/shared/Button';
import Reason from '../../detailedLayoutComponents/Reason';
import RestoreIcon from '../../../../components/icons/RestoreIcon';
import Pagination from '../../../../components/shared/Pagination';
import { useHandleStorefrontFlaggedReports } from '../../../../hooks/useHandleStorefrontFlaggedReports';
import { StorefrontModel } from '../../../../model/Storefront';

const DeletedStorefrontDetailedLayout = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const profileState = useAppSelector((state) => state.slide.profile);
  const selectedDeletedListing = useAppSelector((state) => state.report.deleltedListing);
  const { authUser } = AuthUserState();

  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const clickedActivityItem: ActivityItemPropsType | any = useAppSelector(
    (state) => state.report.clickedActivityData
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [isDeletedStorefrontsFetched, setIsDeletedStorefrontsFetched] = useState(false);
  const [activeStorefront, setActiveStorefront] = useState<any>({
    storefront: null,
    issuedListingConstants: null
  });

  const { deletedStorefronts, deletedStorefrontsCounts, loading, error, refetch, fetchMore } =
    useGetDetailedDeletedStorefronts(offset, 1, searchInput, selectedDeletedListing?.userId);

  const {
    onRestoreButtonClicked,
    showReslistItemModal,
    onCloseRelistModalModal,
    showWarningInsteadModal,
    onCloseIssueWarningInsteadModal,
    onIssueWarningInsteadClicked
  } = useHandleIssuedListings(refetch);

  const { showBanModal, onBanUserButtonClicked, onCloseBanModal } =
    useHandleStorefrontFlaggedReports(refetch);

  const fetchMoreData = (pageNumber: number) => {
    const startIndex = pageNumber - 1;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: 1 }
    });
    setIsDeletedStorefrontsFetched(false);
    setCurrentPage(pageNumber);
  };
  const activityHeader = handleReportActivityInfo(REPORT_PAGES.LISTING_DELETED, intl);
  const handleActiveStorefrontt = (storefront: any) => {
    const accountType = handleAccountTypeBadge(storefront?.issued_listing?.issuer?.type, intl);
    const userRole = handleUserRoleBadge(
      storefront?.issued_listing?.issuer?.role?.name || '',
      intl
    );

    const issuedListingConstants = handleListingDetailConstants(
      accountType,
      storefront?.issued_listing?.actionType === 'delete_warning' ||
        storefront?.issued_listing?.actionType === 'delete'
        ? REPORT_PAGES.LISTING_DELETED_WARNED
        : REPORT_PAGES.LISTING_DELETED_BANED,
      userRole
    );
    setActiveStorefront({
      storefront: storefront,
      issuedListingConstants: issuedListingConstants
    });
  };

  useEffect(() => {
    if (
      deletedStorefronts &&
      deletedStorefronts?.store_fronts?.length > 0 &&
      !isDeletedStorefrontsFetched
    ) {
      const storefrontFinded = deletedStorefronts?.store_fronts?.filter(
        (li: any) => li?.id == selectedDeletedListing?.listingId
      );
      const storefront =
        selectedDeletedListing?.listingId && storefrontFinded?.length > 0
          ? storefrontFinded[0]
          : deletedStorefronts?.store_fronts[0];
      setIsDeletedStorefrontsFetched(true);
      handleActiveStorefrontt(storefront);
    }
  }, [deletedStorefronts]);

  useEffect(() => {
    const filteredStorefront = deletedStorefronts?.store_fronts?.filter(
      (item: any) => item.id === clickedActivityItem?.listingId
    );
    if (filteredStorefront?.length > 0) {
      handleActiveStorefrontt(filteredStorefront);
    }
  }, [clickedActivityItem]);

  const issuer = activeStorefront?.storfront?.issued_listing?.issuer;

  const openMessage = () => {
    dispatch(
      setChatData({
        sender: {
          profileImage: { url: authUser.profileImage?.url },
          name: authUser.name,
          username: authUser.username,
          id: authUser.id
        },
        receiver: {
          profileImage: {
            url: activeStorefront?.storefront?.issued_listing?.issuer?.profileImage?.url
          },
          name: activeStorefront?.storefront?.issued_listing?.issuer?.name,
          username: activeStorefront?.storefront?.issued_listing?.issuer?.username,
          id: activeStorefront?.storefront?.issued_listing?.issuer?.id
        }
      })
    );
    dispatch(showSlide('message'));
  };
  return (
    <div>
      {deletedStorefronts?.length === 0 && !loading ? (
        <EmptyReportListings
          title={TRANSLATED_CONSTANTS.NO_DELETED_YET}
          subtitle={TRANSLATED_CONSTANTS.NO_DELETED_YET_DESCRIPTION}
          icon={<TrashIcon className="w-6 h-6 md:h-7 md:w-7 " />}
        />
      ) : (
        <div>
          {loading ? (
            <Loader className="h-screen" />
          ) : (
            <div>
              <div className="w-full flex flex-col lg:flex-row lg:gap-16">
                <div className="w-full flex gap-8 flex-col">
                  <StorefrontDetailedProfileHeader
                    pageType={REPORT_PAGES.LISTING_DELETED_WARNED}
                    user={deletedStorefronts?.user}
                    openBanModal={() =>
                      onBanUserButtonClicked(deletedStorefronts?.user, activeStorefront)
                    }
                    warnings={deletedStorefronts?.warnings || 0}
                    flags={deletedStorefronts?.flags || 0}
                  />
                  <div className="md:px-4 lg:pl-4 lg:pr-0 pb-8">
                    <Contact email={deletedStorefronts?.user?.email} />

                    <div className="my-8">
                      <Divider />
                    </div>
                    {activeStorefront.storefront && (
                      <DetailedLayoutListingCards
                        report={activeStorefront.storefront}
                        pageType={REPORT_PAGES.LISTING_FLAG}
                        activityItemData={activeStorefront.storefront}
                        title={activeStorefront.issuedListingConstants?.title}
                        subtitle={activeStorefront.issuedListingConstants?.subtitle}
                      />
                    )}

                    <div className="my-8">
                      <Divider />
                    </div>
                    <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
                      <div className="w-[280px]">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                          {activeStorefront.issuedListingConstants?.reporterTitle}
                        </Typo.textSm>
                        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                          {activeStorefront.issuedListingConstants?.reporterSubTitle}
                        </Typo.textSm>
                      </div>
                      <div className="flex flex-col md:flex-row gap-6 flex-1 w-full  justify-between items-start ">
                        <UserInfo
                          profileImage={
                            activeStorefront?.storefronts?.issued_listing?.issuer?.profileImage?.url
                          }>
                          <div className="w-full">
                            <div className="flex  gap-2">
                              <Typo.textMd
                                fontWeight="font-medium"
                                className="text-gray-900 capitalize">
                                {issuer?.name}
                              </Typo.textMd>

                              {activeStorefront?.issuedListingConstants?.reporterBadge}
                            </div>
                            <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                              {issuer?.email}
                            </Typo.textMd>
                          </div>
                        </UserInfo>
                        <div className="w-full flex gap-4 justify-end">
                          <Button onClick={openMessage} size="md" theme="gray-link">
                            <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.MESSAGE.id}
                                defaultMessage={TRANSLATED_CONSTANTS.MESSAGE.defaultMessage}
                              />
                            </Typo.textSm>
                          </Button>
                          <Button
                            onClick={() => {
                              if (!profileState) {
                                dispatch(
                                  setProfileData({
                                    userId:
                                      activeStorefront?.storefronts?.issued_listing?.issuer?.id
                                  })
                                );
                                dispatch(showSlide('profile'));
                              }
                            }}
                            size="sm"
                            theme="yellow-link">
                            <Typo.textSm fontWeight="font-semibold" className="">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                                defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
                              />
                            </Typo.textSm>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="my-8">
                      <Divider />
                    </div>
                    <Reason
                      claim={activeStorefront?.storefronts?.issued_listing?.claim || ''}
                      detail={activeStorefront?.storefronts?.issued_listing?.detail || ''}
                    />
                    <div className="mt-8 mb-5">
                      <Divider />
                    </div>

                    <div className="w-full flex justify-end">
                      <div className="flex gap-3">
                        {activeStorefront?.storefront?.issued_listing?.actionType ===
                          'delete_ban' && (
                          <Button
                            onClick={() => {
                              onIssueWarningInsteadClicked(activeStorefront.listing);
                            }}
                            size="md"
                            theme="base">
                            <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.ISSUE_WARNING_INSTEAD.id}
                                defaultMessage={
                                  TRANSLATED_CONSTANTS.ISSUE_WARNING_INSTEAD.defaultMessage
                                }
                              />
                            </Typo.textSm>
                          </Button>
                        )}

                        <Button
                          onClick={() => {
                            onRestoreButtonClicked(activeStorefront.listing, true);
                          }}
                          icon={<RestoreIcon className="text-gray-900 w-5 h-5" />}
                          size="md"
                          theme="primary-yellow">
                          <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.RESTORE_LISTING.id}
                              defaultMessage={TRANSLATED_CONSTANTS.RESTORE_LISTING.defaultMessage}
                            />
                          </Typo.textSm>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-8 lg:mr-4 lg:mt-4 md:mx-4 ">
                  <ActivityList
                    activityHeader={activityHeader}
                    activityData={deletedStorefronts?.activityData}
                  />
                </div>
              </div>
              <Divider />
              <div className="px-6">
                <Pagination
                  currentPage={currentPage}
                  totalCount={deletedStorefrontsCounts}
                  pageSize={1}
                  onPageChange={fetchMoreData}
                  type="simple-card-footer"
                />
              </div>
            </div>
          )}
        </div>
      )}

      <RedActionModal
        title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
        isOpen={showBanModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
        username={deletedStorefronts && deletedStorefronts[0]?.user?.username}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
        isOpen={showReslistItemModal}
        onClosePrimaryActionModal={(value: boolean) =>
          onCloseRelistModalModal(value, 'listing-quarantine')
        }
        subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_CONFIRM_BUTTON}
        icon={<TagIcon />}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarningInsteadModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseIssueWarningInsteadModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default DeletedStorefrontDetailedLayout;
