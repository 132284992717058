import { useAppSelector } from '../../../../lib/redux/hooks';
import FlaggedStorefrontDetailedLayout from './FlaggedStorefrontDetailedLayout';
import FlaggedStorefrontsGridLayout from './FlaggedStorefrontsGridLayout';

const FlagedStorefronts = () => {
  const gridLayout = useAppSelector((state) => state.report.reportLayout);
  return (
    <>
      {gridLayout.type === 'grid' ? (
        <FlaggedStorefrontsGridLayout />
      ) : (
        <FlaggedStorefrontDetailedLayout />
      )}{' '}
    </>
  );
};

export default FlagedStorefronts;
