/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import Button from '../shared/Button';
import { Typo } from '../Typo';
import TrashIcon from '../icons/TrashIcon';
import clsx from 'clsx';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
type Props = {
  onConfirm?: () => void;
  onCancel?: () => void;
  cancelText?: MessageDescriptor;
  confirmText: MessageDescriptor;
  formId?: string;
  isConfirm?: boolean;
  actionButtonsFlow: 'vertical' | 'horizontal';
  theme: 'error' | 'primary';
  hasButtonIcon?: boolean;
  buttonIcon?: React.ReactElement;
  isSubmiting?: boolean;
  hasCancelButton?: boolean;
  confirmBtnDisabled?: boolean;
  cancelBtnDisabled?: boolean;
  isReversed?: boolean;
  confirmButtonIcon?: React.ReactElement;
};

const ModalActions: React.FC<Props> = (props) => {
  const {
    formId = '',
    confirmText,
    cancelText,
    onCancel = () => {},
    onConfirm,
    actionButtonsFlow,
    theme,
    hasButtonIcon = false,
    buttonIcon,
    isSubmiting,
    hasCancelButton = true,
    confirmBtnDisabled,
    cancelBtnDisabled,
    confirmButtonIcon,
    isReversed = false
  } = props;
  return (
    <div
      className={clsx('flex gap-3 w-full', {
        'flex-col md:flex-row': actionButtonsFlow === 'horizontal',
        'flex-col': actionButtonsFlow === 'vertical',
        'flex-col-reverse': isReversed
      })}>
      {hasCancelButton && (
        <Button
          theme="base"
          size="lg"
          disabled={cancelBtnDisabled}
          to={
            formId === 'login' || formId === 'signup'
              ? `${process.env.REACT_APP_STRAPI_API_URL}/api/connect/google`
              : ''
          }
          onClick={onCancel}
          // formId === 'login' || formId === 'signup' ? onCancel : undefined
          className="flex-grow w-full"
          icon={buttonIcon ? buttonIcon : <></>}>
          <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
            <FormattedMessage
              id={cancelText ? cancelText.id : TRANSLATED_CONSTANTS.CANCEL.id}
              defaultMessage={
                cancelText ? cancelText.defaultMessage : TRANSLATED_CONSTANTS.CANCEL.defaultMessage
              }
            />
          </Typo.textMd>
        </Button>
      )}
      <Button
        type="submit"
        loading={isSubmiting || false}
        theme={theme === 'error' ? 'error' : 'primary-yellow'}
        size="lg"
        disabled={confirmBtnDisabled}
        className="flex-grow w-full"
        onClick={onConfirm}
        form={formId}
        icon={confirmButtonIcon ? confirmButtonIcon : <></>}>
        <Typo.textMd fontWeight="font-semibold" className="whitespace-nowrap">
          <FormattedMessage id={confirmText.id} defaultMessage={confirmText.defaultMessage} />
        </Typo.textMd>
      </Button>
    </div>
  );
};

export default ModalActions;
