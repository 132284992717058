import './App.css';
import HomePage from '../../pages/homePage';
import { RequireAdminAndSuperAdmin, RequireAuthnMember, useAuth } from '../../context/Authn';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import CreateAccount from '../../pages/createAccount';
import Dashboard from '../../pages/dashboard';
import CreateStorefront from '../../pages/createStorefront/createStorefront';
import EditStorefront from '../../pages/createStorefront/editStorefront';
import { ROUTES } from '../../constants/data';
import Layout from '../layout/layout';
import CreateListing from '../../pages/createListing/CreateListing';
import MarketPlace from '../../pages/marketPlace';
import PrivaryPolicy from '../../pages/PrivacyPolicy';
import TermOfService from '../../pages/TermsofService';
import About from '../../pages/about';
import NotFound from '../../pages/error/NotFound';
import Listing from '../../pages/listing';
import Storefront from '../../pages/storefront';
import StorefrontItemDetails from '../../pages/storefrontItem';
import LoginRedirect from '../../pages/api/callback';
import EditListing from '../../pages/createListing/EditListing';
import io from 'socket.io-client';
import { useEffect, useState } from 'react';
import { ChatState } from '../../context/ChatNotificationProvider';
import baseUrl from '../../config/config';
import { AuthUserState } from '../../context/AuthUserProvider';
import TopLayerLayout from '../layout/topLayerLayout';
import LeaderBoard from '../../pages/leaderBoard.tsx';
import Profile from '../../pages/settings/profile';
import Reports from '../../pages/reports';
import LocationMeetUp from '../../pages/settings/meet-up-location';
import ChangePassword from '../../pages/settings/change-password';
import Notifications from '../../pages/settings/notifications';
import BlockedUsers from '../../pages/settings/Blocked User/blocked-users';
import BannedUsers from '../../pages/settings/Banner User/banned-users';
import AdminSetting from '../../pages/settings/admin';
import ModeratorSetting from '../../pages/settings/moderator';
import ReferralHistory from '../../pages/settings/referral-history';
import { BreadcrumbProvider } from '../../context/Breadcrumb';
import { useGetRoles } from '../../hooks/user/useGetRoles';
const ENDPOINT = baseUrl.BACKEND_URL || 'http://localhost:1337';
const socket = io(ENDPOINT);
const App = () => {
  const userAuth = useAuth();
  const me = userAuth.getMe();
  const user = userAuth.getUser();
  const [isAuthUserFetched, setIsAuthUserFetched] = useState(false);
  const [isMeFetched, setIsMeFetched] = useState(false);
  const [isRolesFetched, setIsRolesFetched] = useState(false);
  const { setSocket } = ChatState();
  const { setAuthUser, setMe, setBanned, setRoles } = AuthUserState();
  const { roles } = useGetRoles();
  useEffect(() => {
    setSocket(socket);
  }, []);

  useEffect(() => {
    if (me && !isMeFetched) {
      setIsMeFetched(true);
      socket.emit('setup', me.id);
      setMe(me);
    }
  }, [me]);

  useEffect(() => {
    if (roles && !isRolesFetched) {
      setIsRolesFetched(true);
      setRoles(roles);
    }
  }, [roles]);

  useEffect(() => {
    if (user && !isAuthUserFetched) {
      setIsAuthUserFetched(true);
      user.banned_user?.banned ? setBanned(true) : setBanned(false);
      if (user.banned_user?.banned) {
        localStorage.removeItem('token');
        setBanned(true);
        setMe(undefined);
        setAuthUser(undefined);
      } else {
        setAuthUser(user);
        setBanned(false);
      }
    }
  }, [user]);

  return (
    <div className="App">
      <BrowserRouter>
        <BreadcrumbProvider>
          <TopLayerLayout>
            <Routes>
              <Route path={ROUTES.LOGOUT_ROUTE} element={<Navigate to={ROUTES.HOME_ROUTE} />} />
              <Route path={ROUTES.HOME_ROUTE} element={<HomePage />} />
              <Route path={`${ROUTES.LISTING_DETAILS_ROUTE}/:id`} element={<Listing />} />
              <Route path={`${ROUTES.STOREFRONT_DETAILS_ROUTE}/:id`} element={<Storefront />} />
              <Route
                path={`${ROUTES.STOREFRONT_ITEM_DETAILS_ROUTE}/:id`}
                element={<StorefrontItemDetails />}
              />
              <Route path={ROUTES.AUTH_PROVIDER_ROUTE} element={<LoginRedirect />} />
              <Route path={ROUTES.MARKETPLACE_ROUTE} element={<MarketPlace />} />
              <Route path={ROUTES.CREATE_ACOUNT_ROUTE} element={<CreateAccount />} />
              <Route path={ROUTES.DOGELIST_PRIVACY_POLICY_ROUTE} element={<PrivaryPolicy />} />
              <Route path={ROUTES.DOGELIST_TERM_OF_SERVICE} element={<TermOfService />} />
              <Route path={ROUTES.ABOUT_ROUTE} element={<About />} />
              <Route
                path={ROUTES.REPORTS}
                element={
                  <RequireAdminAndSuperAdmin>
                    <Reports />
                  </RequireAdminAndSuperAdmin>
                }
              />
              <Route
                path={ROUTES.DASHBOARD_ROUTE}
                element={
                  <RequireAuthnMember>
                    <Layout />
                  </RequireAuthnMember>
                }>
                <Route path={ROUTES.DASHBOARD_ROUTE} element={<Dashboard />} />
                <Route path={`${ROUTES.CREATE_LISTING_ROUTE}`} element={<CreateListing />} />
                <Route path={`${ROUTES.CREATE_STOREFRONT_ROUTE}`} element={<CreateStorefront />} />
                <Route path={`${ROUTES.EDIT_STOREFRONT_ROUTE}/:id`} element={<EditStorefront />} />
                <Route path={`${ROUTES.EDIT_LISTING_ROUTE}/:id`} element={<EditListing />} />
                <Route path={`${ROUTES.LEADER_BOARD}`} element={<LeaderBoard />} />
                <Route path={`${ROUTES.PROFILE}`} element={<Profile />} />
                <Route path={`${ROUTES.LOCATION_MEETUP_ROUTE}`} element={<LocationMeetUp />} />
                <Route path={`${ROUTES.CHANGE_PASSWORD_ROUTE}`} element={<ChangePassword />} />
                <Route path={`${ROUTES.NOTIFICATIONS_ROUTE}`} element={<Notifications />} />
                <Route path={`${ROUTES.BLOCKED_USERS_ROUTE}`} element={<BlockedUsers />} />
                <Route path={`${ROUTES.BANNED_USERS_ROUTE}`} element={<BannedUsers />} />
                <Route path={`${ROUTES.ADMIN_SETTINGS_ROUTE}`} element={<AdminSetting />} />
                <Route path={`${ROUTES.MODERATOR_SETTINGS_ROUTE}`} element={<ModeratorSetting />} />
                <Route path={`${ROUTES.REFERRAL_HISTORY_ROUTE}`} element={<ReferralHistory />} />
              </Route>
              <Route path={ROUTES.NOT_FOUND_ROUTE} element={<NotFound />} />
            </Routes>
          </TopLayerLayout>
        </BreadcrumbProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
