import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect, useState } from 'react';
import { useUpdateUser } from '../../../hooks/user/useUpdateUser';
import { ACCOUNT_TYPE } from '../../../constants/data';
import LocationSelector from '../../../components/shared/location Dropdown/LocationDropdown';
import InputField from '../../../components/shared/InputField';
import Button from '../../../components/shared/Button';
import Location from '../../../components/shared/map/Location';
import React from 'react';
import { LocationType } from '../../../model/Location';
import Badge from '../../../components/shared/Badge';
import { handleAccountTypeBadge, handleValidationMessage } from '../../../util/ListingBadgeHandler';
import { Typo } from '../../../components/Typo';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import PrimaryActionModal from '../../../components/modal/PrimaryActionModal';
import RouteIcon from '../../../components/icons/RouteIcon';
import { AuthUserState } from '../../../context/AuthUserProvider';
type Props = {
  onNextStep: () => void;
  accountType: string;
};
const AddLocationStep: React.FC<Props> = (props) => {
  const { onNextStep, accountType } = props;
  const [isSubmiting, setIsSubmiting] = useState(false);
  const authUser = AuthUserState();
  const user = authUser.authUser;
  const updateUser = useUpdateUser();
  const intl = useIntl();
  const [coords, setCoords] = useState<number[]>([51.505, -0.09]);
  const [locationSkipModal, setLocationSkipModal] = useState(false);
  const validateMessages = handleValidationMessage(intl);
  const [isSelectedLocation, setIsSelectedLocation] = useState(false);
  const schema = yup.object().shape({
    latitude: yup
      .number()
      .nullable()
      .default(null)
      .transform((value, originalValue) =>
        originalValue === null || originalValue === '' ? null : value
      ),
    longitude: yup
      .number()
      .nullable()
      .default(null)
      .transform((value, originalValue) =>
        originalValue === null || originalValue === '' ? null : value
      ),
    address: yup.string().nullable().default(null),
    street: yup.string().nullable().default(null),
    city: yup.string().required(validateMessages['enter_city']),
    state: yup.string().required(validateMessages['enter_province']),
    country: yup.string().required(validateMessages['enter_country'])
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    try {
      setIsSubmiting(true);
      const response = await updateUser({
        variables: {
          data: {
            location: {
              latitude: data?.latitude,
              longitude: data?.longitude,
              address: data?.address,
              city: data.city,
              state: data.state,
              street: data?.street,
              country: data.country
            }
          },
          id: user?.id
        }
      });
      if (response) {
        onNextStep();
        setIsSubmiting(false);
      }
    } catch (e) {
      setIsSubmiting(false);
    }
  };

  const onPlaceSelected = (location: LocationType) => {
    setIsSelectedLocation(true);
    setCoords([location?.latitude, location?.longitude]);
    setValue('address', location?.address || '');
    setValue('street', location?.street || '');
    setValue('city', location?.city || '');
    setValue('country', location?.country || '', {
      shouldValidate: true
    });
    setValue('state', location?.state || '');
    setValue('latitude', location?.latitude || 0);
    setValue('longitude', location?.longitude || 0);
  };

  const accountNameAndThem = handleAccountTypeBadge(accountType, intl);

  const onCloseModal = (state: boolean) => {
    if (state) {
      onNextStep();
    }

    setLocationSkipModal(false);
  };

  useEffect(() => {
    if (user?.location) {
      onPlaceSelected(user?.location);
    }
  }, [user]);

  return (
    <div className="w-full pt-12 flex justify-center items-center sm:pt-24">
      <div className=" px-4 sm:max-w-lg">
        <div className="grid grid-cols-1 sm:grid-cols-5 gap-x-4">
          <div className="w-full sm:col-span-3  space-y-5">
            <div className="flex gap-x-4 items-center">
              <Typo.textXl fontWeight="font-medium" className="text-gray-900">
                {accountType === ACCOUNT_TYPE.PERSONAL
                  ? intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.MEET_UP_LOCATION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.MEET_UP_LOCATION.defaultMessage
                    })
                  : intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.STOREFRONT_LOCATION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_LOCATION.defaultMessage
                    })}
              </Typo.textXl>
              <Badge
                size="lg"
                label={accountNameAndThem.name}
                theme={accountNameAndThem.theme}></Badge>
            </div>
            <LocationSelector
              label={
                accountType === ACCOUNT_TYPE.PERSONAL
                  ? intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.WHERE_DO_YOU_WANT_MEET.id,
                      defaultMessage: TRANSLATED_CONSTANTS.WHERE_DO_YOU_WANT_MEET.defaultMessage
                    })
                  : intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.WHERE_ARE_YOU_LOCATED.id,
                      defaultMessage: TRANSLATED_CONSTANTS.WHERE_ARE_YOU_LOCATED.defaultMessage
                    })
              }
              placeholder="City, Neighborhood, ZIP, Ad..."
              hintText={
                accountType === ACCOUNT_TYPE.PERSONAL
                  ? `*${intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ADDRESS_AND_PHONE_KEEP_PRIVATE.id,
                      defaultMessage:
                        TRANSLATED_CONSTANTS.ADDRESS_AND_PHONE_KEEP_PRIVATE.defaultMessage
                    })}`
                  : ''
              }
              onLocationSelect={onPlaceSelected}></LocationSelector>
          </div>
          <div className="w-[200px] sm:col-span-2 h-[150px] overflow-hidden hidden sm:block bg-[#E2E2E2] rounded-lg">
            <Location coords={coords} />
          </div>
        </div>

        {isSelectedLocation && (
          <div>
            <Typo.textXl fontWeight="font-medium" className="my-8 text-gray-900">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.id,
                defaultMessage: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.defaultMessage
              })}
            </Typo.textXl>
            <form id="add-location" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
              <div className="overflow-visible sm:rounded-md space-y-5 ">
                <div className="grid grid-cols-2 gap-x-3">
                  <InputField
                    name="latitude"
                    label={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.LATITUDE.id,
                      defaultMessage: TRANSLATED_CONSTANTS.LATITUDE.defaultMessage
                    })}
                    placeholder="35.8973748"
                    register={register}
                    type="text"
                    errors={errors?.latitude}
                  />
                  <InputField
                    name="longitude"
                    label={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.LONGITUDE.id,
                      defaultMessage: TRANSLATED_CONSTANTS.LONGITUDE.defaultMessage
                    })}
                    placeholder="-119.887349"
                    register={register}
                    type="text"
                    errors={errors?.longitude}
                  />
                </div>
                <div className="grid grid-cols-2 gap-x-3">
                  <InputField
                    name="city"
                    label={`${intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.CITY.id,
                      defaultMessage: TRANSLATED_CONSTANTS.CITY.defaultMessage
                    })}*`}
                    placeholder="San Diego"
                    register={register}
                    type="text"
                    errors={errors?.city}
                  />
                  <InputField
                    name="state"
                    label={`${intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.id,
                      defaultMessage: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.defaultMessage
                    })}*`}
                    placeholder="California"
                    register={register}
                    type="text"
                    errors={errors?.state}
                  />
                </div>
                <div className="w-full">
                  <InputField
                    name={accountType === ACCOUNT_TYPE.PERSONAL ? 'street' : 'address'}
                    label={`${
                      accountType === ACCOUNT_TYPE.PERSONAL
                        ? intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.STREET.id,
                            defaultMessage: TRANSLATED_CONSTANTS.STREET.defaultMessage
                          })
                        : intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ADDRESS.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ADDRESS.defaultMessage
                          })
                    }`}
                    placeholder={
                      accountType === ACCOUNT_TYPE.PERSONAL
                        ? intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ENTER_A_STREET.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ENTER_A_STREET.defaultMessage
                          })
                        : intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ENTER_AN_ADDRESS.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ENTER_AN_ADDRESS.defaultMessage
                          })
                    }
                    helpIcon={true}
                    register={register}
                    type="text"
                    errors={
                      accountType === ACCOUNT_TYPE.PERSONAL ? errors?.street : errors?.address
                    }
                  />
                </div>

                <div className="w-full">
                  <InputField
                    name="country"
                    label={`${intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.COUNTRY.id,
                      defaultMessage: TRANSLATED_CONSTANTS.COUNTRY.defaultMessage
                    })}*`}
                    placeholder="United States"
                    register={register}
                    type="text"
                    errors={errors?.country}
                  />
                </div>
                <Typo.textSm fontWeight="font-normal" className="w-full text-gray-800">
                  *
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
                    defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
                  })}
                </Typo.textSm>
                <div className="space-y-2">
                  <Button
                    size="lg"
                    loading={isSubmiting}
                    type="submit"
                    theme="primary-yellow"
                    className="w-full">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                      {accountType === ACCOUNT_TYPE.PERSONAL
                        ? intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ADD_MEET_UP_LOCATION.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ADD_MEET_UP_LOCATION.defaultMessage
                          })
                        : intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ADD_STORE_LOCATION.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ADD_STORE_LOCATION.defaultMessage
                          })}
                    </Typo.textMd>
                  </Button>
                  <Button
                    size="lg"
                    theme="gray"
                    onClick={() => setLocationSkipModal(true)}
                    className={accountType === ACCOUNT_TYPE.PERSONAL ? 'w-full block' : 'hidden'}>
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-600">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.SKIP_FOR_NOW.id,
                        defaultMessage: TRANSLATED_CONSTANTS.SKIP_FOR_NOW.defaultMessage
                      })}
                    </Typo.textMd>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.MEET_UP_LOCATION_MODAL_TITLE}
        isOpen={locationSkipModal}
        onClosePrimaryActionModal={onCloseModal}
        subTitle={TRANSLATED_CONSTANTS.MEET_UP_LOCATION_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.MEET_UP_LOCATION_MODAL_CONFIRM_BUTTON}
        icon={<RouteIcon />}
        actionButtonsFlow="vertical"
      />
    </div>
  );
};

export default AddLocationStep;
