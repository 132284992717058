import { gql, useMutation } from '@apollo/client';
interface NotificationInput {
  variables: {
    data: {
      count: number;
      isRead: boolean;
    };
    id: number;
  };
}

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification($id: ID!, $data: NotificationInput!) {
    updateNotification(id: $id, data: $data) {
      data {
        id
        attributes {
          content
          isRead
          count
        }
      }
    }
  }
`;

export const useUpdateNotification = (): ((updateNotification: NotificationInput) => any) => {
  const [updateNotificationResult] = useMutation(UPDATE_NOTIFICATION, {
    update(cache, { data: { updateNotification } }) {
      cache.modify({
        fields: {
          GetNotifications(existingNotifications = []) {
            return [...existingNotifications, ...updateNotification.data];
          }
        }
      });
    },
    refetchQueries: ['GetNotifications']
  });

  return updateNotificationResult;
};
