import { gql, useMutation } from '@apollo/client';

interface SendEmailInput {
  emails: string[];
  content: string;
  subject: string;
}
const SEND_EMAIL = gql`
  mutation SendEmail($emails: [String!], $content: String!, $subject: String) {
    sendEmail(emails: $emails, content: $content, subject: $subject) {
      count
    }
  }
`;

export const useSendEmail = (): ((sendEmail: SendEmailInput) => any) => {
  const [sendEmail] = useMutation(SEND_EMAIL);
  return (input: SendEmailInput) => sendEmail({ variables: input });
};
