import { createSlice } from '@reduxjs/toolkit';

const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    alertType: '',
    alertData: {
      title: '',
      description: '',
      link: '',
      confirmTitle: '',
      cancelTitle: '',
      image: ''
    },
    isBannerOpen: false
    // Add more modal states as needed
  },
  reducers: {
    setAlertType: (state, action) => {
      state.alertType = action.payload;
    },
    setAlertData: (state, action) => {
      state.alertData = action.payload;
    },
    setIsBannerOpen: (state, action) => {
      state.isBannerOpen = action.payload;
    }
  }
});

export const { setAlertData, setAlertType, setIsBannerOpen } = alertSlice.actions;
export default alertSlice.reducer;
