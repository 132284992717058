/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { useGetDeletedListings } from '../../../../hooks/reports/useGetDeletedListings';
import { NUMBER_OF_RECORDS_PER_TABLE } from '../../../../constants/data';
import EmptyReportListings from '../../EmptyReportListings';
import Loader from '../../../../components/shared/Loader';
import ListingGridLayout from './ListingGridLayout';
import TrashIcon from '../../../../components/icons/TrashIcon';

const DeletedListingsGridLayout = () => {
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const { deletedListings, deletedListingsCount, loading, error, refetch, fetchMore } =
    useGetDeletedListings(offset, NUMBER_OF_RECORDS_PER_TABLE, searchInput);

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full">
      {deletedListings?.length === 0 && !loading ? (
        <EmptyReportListings
          title={{
            id: 'page.reports.nothing_deleted_title',
            defaultMessage: 'No deleted yet'
          }}
          subtitle={{
            id: 'page.reports.nothing_deleted_subtitle',
            defaultMessage: 'You don’t have any deleted posts yet.'
          }}
          icon={<TrashIcon className="w-6 h-6 md:h-7 md:w-7 " />}
        />
      ) : (
        <div className="w-full">
          {loading ? (
            <Loader className="h-[400px] w-full flex justify-center items-center" />
          ) : (
            <ListingGridLayout
              pageType="listing-deleted"
              data={deletedListings}
              listingsCount={deletedListingsCount}
              paginate={paginate}
              currentPage={currentPage}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DeletedListingsGridLayout;
