import { Switch } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

type Props = {
  defaultValue?: boolean;
  onChange: (state: boolean) => void;
  size?: 'sm' | 'md';
  theme?: 'light' | 'dark';
  label?: string;
  description?: string;
  disabled?: boolean;
  className?: string;
  [x: string]: any;
};

const Toggle: React.FC<Props> = (props) => {
  const {
    defaultValue = false,
    onChange,
    size = 'md',
    theme = 'dark',
    label,
    description,
    disabled = false,
    className = ''
  } = props;

  return (
    <Switch.Group as="div" className="flex items-center">
      <div className="flex items-start">
        {' '}
        <Switch
          checked={defaultValue}
          onChange={onChange}
          disabled={disabled}
          className={clsx(
            defaultValue
              ? `${
                  theme == 'dark'
                    ? 'bg-yellow-700 hover:bg-yellow-800  focus:bg-yellow-700 '
                    : ' bg-yellow-200 hover:bg-yellow-300 focus:bg-yellow-200'
                }`
              : 'bg-gray-100 hover:bg-gray-200 focus:bg-gray-100 ',
            size == 'sm' ? 'h-5 w-9 ' : ' h-6 w-11 ',
            'relative focus:shadow-xs  inline-flex items-center flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out focus:outline-none focus:ring-4 focus:ring-yellow-300 disabled:bg-gray-100',
            className
          )}>
          <span
            aria-hidden="true"
            className={clsx(
              defaultValue
                ? size == 'sm'
                  ? 'translate-x-[18px]'
                  : 'translate-x-[22px]'
                : 'translate-x-[2px]',
              size == 'sm' ? ' h-4 w-4 ' : ' h-5 w-5 ',
              'pointer-events-none inline-block  transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            )}
          />
        </Switch>
        <Switch.Label as="span" className="ml-2 text-sm cursor-pointer">
          {label && (
            <span className="font-medium text-gray-700 font-inter text-base leading-6">
              {label}
            </span>
          )}
          {description && <span className="text-gray-500">{description}</span>}
        </Switch.Label>
      </div>
    </Switch.Group>
  );
};

export default Toggle;
