import { Dialog, Transition } from '@headlessui/react';
import React, { FC, Fragment } from 'react';
import SharingPiointBadge from '../shared/SharingPiointBadge';
import giftImage from '../../assets/gift-image.svg';
import ModalBodyText from './ModalBodyText';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Button from '../shared/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import CopyIcon from '../icons/CopyIcon';
import clsx from 'clsx';
import { Typo } from '../Typo';
import { generateReferralCode } from '../../util/GenerateCode';
import Divider from '../shared/Divider';
import TwitterIcon from '../icons/TwitterIcon';
import FacebookIcon from '../icons/FacebookIcon';
import EmailIcon from '../icons/EmailIcon';
import { FacebookShareButton, TwitterShareButton, EmailShareButton } from 'react-share';
import baseUrl from '../../config/config';
type Porps = {
  isOpen: boolean;
  onCloseInviteModal: () => void;
  lastName: string;
};

const InviteModal: FC<Porps> = ({ isOpen, onCloseInviteModal, lastName }) => {
  const intl = useIntl();
  const { register } = useForm();
  const errors = {
    message: null
  };
  const referralCodeLabel = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.REFERRAL_CODE.id,
    defaultMessage: TRANSLATED_CONSTANTS.REFERRAL_CODE.defaultMessage
  });
  //TODO:Referral code should be stored in the database so we can understand which user it belongs to
  const referralCodeInputValue = `[${lastName}] ${generateReferralCode()}`;
  const url = `${baseUrl.BASE_URL}/sign-up/?code=${referralCodeInputValue}`;
  const handleCopy = async () => {
    await navigator.clipboard.writeText(referralCodeInputValue);
  };
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onCloseInviteModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 w-full  md:max-w-[400px] ">
                <div className="p-0 md:px-6 md:pt-6">
                  <div className=" w-full   h-[208px] bg-gradient-to-b from-blue-100 to-blue-100 rounded-md relative">
                    <div className="w-full h-full flex justify-center items-center ">
                      <img src={giftImage} />
                      <div className="absolute top-4 right-4">
                        <SharingPiointBadge />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-5 md:p-6">
                  <ModalBodyText
                    title={TRANSLATED_CONSTANTS.INVITE_MODAL_TITLE}
                    subTitle={TRANSLATED_CONSTANTS.INVITE_SUBTITLE}
                  />
                  <div className="flex items-end mt-5">
                    <div className="w-full relative space-y-1.5">
                      <label htmlFor="referral-code" className="left-0 text-left">
                        <Typo.textSm fontWeight="font-normal" className={` text-gray-700 `}>
                          {referralCodeLabel}
                        </Typo.textSm>
                      </label>
                      <input
                        id="referral-code"
                        {...register}
                        type="text"
                        defaultValue={referralCodeInputValue}
                        disabled={true}
                        className={clsx(
                          ` pr-[14px]  rounded-l-lg border shadow-xs w-full py-[10px]`,
                          errors?.message == null
                            ? ' border-gray-300  text-gray-500 hover:text-gray-900 focus:ring-0 focus:border-gray-300 focus:shadow-base  disabled:bg-gray-50 disabled:text-gray-400 '
                            : '  border-error-300 text-gray-400 focus:ring-4 focus:border-error-300 focus:ring-error-100 '
                        )}
                      />
                    </div>
                    <div className="rounded-r-lg border border-t-gray-300 border-b-gray-300 border-r-gray-300">
                      <Button
                        onClick={handleCopy}
                        className="mb-1 p-[10px]"
                        size="md"
                        theme="gray-link"
                        icon={<CopyIcon className="h-5 w-5 text-gray-700"></CopyIcon>}></Button>
                    </div>
                  </div>
                  <div className="-mx-5 md:-mx-6 mt-6 md:mt-8 mb-1 md:mb-6">
                    <Divider />
                  </div>
                  <div className="flex flex-col gap-3">
                    <TwitterShareButton url={url}>
                      <Button
                        type="submit"
                        theme="base"
                        size="lg"
                        className="flex-grow w-full"
                        icon={<TwitterIcon className="text-[#1DA1F2]"></TwitterIcon>}>
                        <Typo.textMd fontWeight="font-semibold">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.SHARE_VIA_TWITTER.id}
                            defaultMessage={TRANSLATED_CONSTANTS.SHARE_VIA_TWITTER.defaultMessage}
                          />
                        </Typo.textMd>
                      </Button>
                    </TwitterShareButton>
                    <FacebookShareButton url={url}>
                      <Button
                        type="submit"
                        theme="base"
                        size="lg"
                        className="flex-grow w-full"
                        icon={<FacebookIcon className="text-[#1DA1F2]"></FacebookIcon>}>
                        <Typo.textMd fontWeight="font-semibold">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.SHARE_VIA_FACEBOOK.id}
                            defaultMessage={TRANSLATED_CONSTANTS.SHARE_VIA_FACEBOOK.defaultMessage}
                          />
                        </Typo.textMd>
                      </Button>
                    </FacebookShareButton>
                    <EmailShareButton url={url}>
                      <Button
                        type="submit"
                        theme="primary-yellow"
                        size="lg"
                        className="flex-grow w-full"
                        icon={<EmailIcon className="text-gray-900"></EmailIcon>}>
                        <Typo.textMd fontWeight="font-semibold">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.SHARE_VIA_EMAIL.id}
                            defaultMessage={TRANSLATED_CONSTANTS.SHARE_VIA_EMAIL.defaultMessage}
                          />
                        </Typo.textMd>
                      </Button>
                    </EmailShareButton>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default InviteModal;
