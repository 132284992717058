import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_ME = gql`
  query GetMe {
    me {
      id
      email
      username
      role {
        id
        name
        description
      }
    }
  }
`;

export const useGetMe = () => {
  const { loading, error, data, refetch } = useQuery(GET_ME, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    meLoading: loading,
    meError: error,
    me: flattenObj(data?.me),
    meRefetch: refetch
  };
};
