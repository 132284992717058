import React from 'react';

type Props = {
  userId: null | number | undefined;
  className?: string;
  [x: string]: any;
};
const OnlineIndicator: React.FC<Props> = (props) => {
  const { userId, className } = props;
  return (
    <div className={`h-2.5 w-2.5 flex items-center rounded-full bg-success-500 ${className}`} />
  );
};

export default OnlineIndicator;
