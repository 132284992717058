import React, { useEffect, useState } from 'react';
import DogeIcon from '../icons/DogeIcon';
import { Typo } from '../Typo';
import clsx from 'clsx';
import { ConvertToDoge, convertToUsd } from '../../util/GetDoge';
import { ALTERNATE_PRICE_OPTION } from '../../constants/data';
import { PriceState } from '../../context/PriceProvider';
type PriceProps = {
  priceInUSD: number;
  priceInDoge: number;
  className?: string;
  alternatePriceOptions: string;
};
const Price: React.FC<PriceProps> = (props) => {
  const { priceInUSD, priceInDoge, alternatePriceOptions, className = '' } = props;
  const [dogePrice, setDogePrice] = useState(0);
  const [usdPrice, setUsdPrice] = useState<number | string>(0);
  const priceState = PriceState();
  if (!priceInUSD) {
    return <></>;
  }
  useEffect(() => {
    if (priceState) {
      const doge =
        alternatePriceOptions === ALTERNATE_PRICE_OPTION.DOGE_DOGE
          ? priceInDoge
          : ConvertToDoge(priceInUSD, priceState.dogePrice);
      const usd =
        alternatePriceOptions === ALTERNATE_PRICE_OPTION.DOGE_DOGE
          ? convertToUsd(priceInDoge, priceState.dogePrice)
          : priceInUSD?.toFixed(2);
      const formattedUsd = Number(usd)?.toFixed(2);
      setUsdPrice(formattedUsd);
      setDogePrice(Number(Math.round(doge)));
    }
  }, [priceState]);
  return (
    <div className={clsx('flex gap-x-2 items-center ', className)}>
      <Typo.textXl fontWeight="font-semibold" className=" text-gray-900 flex items-center">
        <DogeIcon className="h-5 w-5"></DogeIcon> {dogePrice} DOGE
      </Typo.textXl>
      <Typo.textMd fontWeight="font-normal" className=" text-gray-600">
        ${usdPrice} <span>USD</span>
      </Typo.textMd>
    </div>
  );
};

export default Price;
