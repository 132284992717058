type Props = {
  className?: string;
};
const LinkIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5898 15.8033L9.41128 16.9818C7.7841 18.609 5.14591 18.609 3.51873 16.9818C1.89154 15.3546 1.89154 12.7164 3.51873 11.0892L4.69724 9.91074M15.3038 11.0892L16.4824 9.91074C18.1095 8.28355 18.1095 5.64536 16.4824 4.01818C14.8552 2.39099 12.217 2.39099 10.5898 4.01818L9.41128 5.19669M7.08387 13.4166L12.9172 7.58331"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LinkIcon;
