import React from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import QuarantineStorefrontsGridLayout from './QuarantineStorefrontsGridLayout';
import QuarantineStorefrontsDetailedLayout from './QuarantineStorefrontsDetailedLayout';

const QuarantineStorefronts = () => {
  const gridLayout = useAppSelector((state) => state.report.reportLayout);

  return (
    <div>
      {gridLayout.type === 'grid' ? (
        <QuarantineStorefrontsGridLayout />
      ) : (
        <QuarantineStorefrontsDetailedLayout />
      )}
    </div>
  );
};

export default QuarantineStorefronts;
