/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { Transition, Dialog } from '@headlessui/react';
import ModalActions from './ModalActions';
import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import clsx from 'clsx';
import { MessageDescriptor } from 'react-intl';
import LoginSignupLink from '../shared/LoginSignupLink';
import SignupModalFooter from '../shared/SignupModalFooter';

type Props = {
  children?: React.ReactNode;
  title?: MessageDescriptor;
  subTitle?: MessageDescriptor;
  onConfirm?: () => void;
  onCancel: () => void;
  onGoogleConfirm?: () => void;
  onChangeType?: (type: 'login' | 'signup') => void;
  confirmText: MessageDescriptor;
  cancelText?: MessageDescriptor;
  isOpen: boolean;
  error?: string | null;
  isConfirm?: boolean;
  formId?: string;
  hasHeader?: boolean;
  icon?: React.ReactElement;
  actionButtonsFlow: 'vertical' | 'horizontal' | undefined;
  username?: string;
  hasButtonIcon?: boolean;
  theme: 'primary' | 'error';
  headerType?: 'center' | 'left' | 'one-row';
  type?: 'login' | 'signup';
  buttonIcon?: React.ReactElement;
  confirmButtonIcon?: React.ReactElement;
  containsLogo?: boolean;
  isSubmiting?: boolean;
  hasCancelButton?: boolean;
  confirmBtnDisabled?: boolean;
  cancelBtnDisabled?: boolean;
  isReversed?: boolean;
  // All other props
  [x: string]: any;
};

const Modal: React.FC<Props> = ({
  children,
  title,
  subTitle,
  onConfirm,
  onCancel,
  cancelText,
  confirmText,
  isOpen,
  isConfirm,
  formId,
  hasHeader = true,
  icon,
  actionButtonsFlow = 'horizontal',
  username,
  hasButtonIcon,
  theme,
  headerType,
  type,
  onGoogleConfirm,
  onChangeType,
  buttonIcon,
  containsLogo,
  isSubmiting,
  hasCancelButton,
  confirmBtnDisabled,
  cancelBtnDisabled,
  confirmButtonIcon,
  isReversed = false
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30 " onClose={onCancel}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed z-10 inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full  justify-center p-4 text-center items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-xl bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all md:my-8 w-full  md:max-w-[400px] md:p-6">
                {hasHeader && (
                  <ModalHeader
                    title={title}
                    subTitle={subTitle}
                    icon={icon}
                    type="left"
                    setShow={onCancel}
                    theme={theme}
                    username={username}
                    headerType={headerType}
                    containsLogo={containsLogo}
                  />
                )}
                {children && <ModalBody>{children}</ModalBody>}
                <div className={clsx('mt-6 md:mt-8')}>
                  <ModalActions
                    onCancel={type === 'login' || type === 'signup' ? onGoogleConfirm : onCancel}
                    onConfirm={onConfirm}
                    cancelText={cancelText}
                    confirmText={confirmText}
                    isConfirm={isConfirm}
                    formId={formId}
                    actionButtonsFlow={actionButtonsFlow}
                    hasButtonIcon={hasButtonIcon}
                    confirmButtonIcon={confirmButtonIcon}
                    theme={theme}
                    buttonIcon={buttonIcon}
                    isSubmiting={isSubmiting}
                    hasCancelButton={hasCancelButton}
                    confirmBtnDisabled={confirmBtnDisabled}
                    cancelBtnDisabled={cancelBtnDisabled}
                    isReversed={isReversed}
                  />
                  {type === 'login' && (
                    <div className="mt-5">
                      <LoginSignupLink onChangeType={onChangeType} />
                    </div>
                  )}
                  {type === 'signup' && (
                    <div className="mt-5">
                      <SignupModalFooter onChangeType={onChangeType} />
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
