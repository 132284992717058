import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { typePolicies } from './typePolicies';
import { jwtDecode } from 'jwt-decode';
const strapiUrl = process.env.REACT_APP_STRAPI_API_URL;
const httpLink = createHttpLink({
  uri: `${strapiUrl}/graphql`,
  // requied in order to send auth cookie
  credentials: 'include'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem('token');
  // removes expired token from localstorage
  if (token) {
    const decodedToken: {
      exp: number;
    } = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (decodedToken.exp < currentTime) {
      localStorage.removeItem('token');
      token = '';
    }
  }
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    );

  if (networkError) {
    // On network errors, go to the maintenance page
    console.log(`[Network error]: ${networkError}`);
    if (networkError.message.includes('503') && window.location.pathname !== '/maintenance') {
      window.location.href = '/maintenance';
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  // link: from([errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies
  })
});

export default client;
