import { gql } from '@apollo/client';

const queryText = `
query GetMapListings(
  $listingOffset: Int!
  $storefrontOffset: Int!
  $limit: Int!
  $userId: ID
  $order: [String!]
  $filters: ListingFiltersInput
  $personalCountFilters: ListingFiltersInput
  $wantedCountFilters: ListingFiltersInput
  $storefrontItemsCountFilters: ListingFiltersInput
  $storefrontFilters: StoreFrontFiltersInput
  $skip: Boolean!
) {
  personalListingsCount: listings(filters: $personalCountFilters) {
    meta {
      pagination {
        total
      }
    }
  }
  wantedListingsCount: listings(filters: $wantedCountFilters) {
    meta {
      pagination {
        total
      }
    }
  }
  storefrontItemsCount: listings(filters: $storefrontItemsCountFilters) {
    meta {
      pagination {
        total
      }
    }
  }
  listings(
    pagination: { start: $listingOffset, limit: $limit }
    sort: $order
    filters: $filters
  ) {
    data {
      id
      attributes {
        image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
          data {
            attributes {
              url
              formats
            }
          }
        }
        type
        name
        description
        location {
          address
          latitude
          longitude
        }
        price {
          alternatePriceOptions
          priceType
          priceInUSD
          priceInDoge
        }
        store_front {
          data {
            id
            attributes {
              name
              location {
                address
                latitude
                longitude
              }
            }
          }
        }
        saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
          data {
            id
            attributes {
              user {
                data {
                  id
                }
              }
            }
          }
        }
        user {
          data {
            id
            attributes {
              name
              username
              type
            }
          }
        }
        publishedAt
      }
    }
  }
  storeFrontsCount: storeFronts(filters: $storefrontFilters) {
    meta {
      pagination {
        total
      }
    }
  }
  storeFronts(
    pagination: { start: $storefrontOffset, limit: $limit }
    sort: $order
    filters: $storefrontFilters
  ) {
    data {
      id
      attributes {
        name
        description
        image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
          data {
            attributes {
              url
              formats
            }
          }
        }
        user {
          data {
            id
            attributes {
              name
              username
              type
            }
          }
        }
        saved_storefronts(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
          data {
            id
            attributes {
              user {
                data {
                  id
                }
              }
            }
          }
        }
        location {
          address
          latitude
          longitude
        }
        publishedAt
      }
    }
  }
}
`;

const mapQueryText = `
query GetMapListings(
  $listingOffset: Int!
  $storefrontOffset: Int!
  $limit: Int!
  $userId: ID
  $order: [String!]
  $filters: ListingFiltersInput
  $storefrontFilters: StoreFrontFiltersInput
  $skip: Boolean!
) {

  listings(
    pagination: { start: $listingOffset, limit: $limit }
    sort: $order
    filters: $filters
  ) {
    data {
      id
      attributes {
        image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
          data {
            attributes {
              url
              formats
            }
          }
        }
        type
        name
        location {
          address
          latitude
          longitude
        }
        price {
          alternatePriceOptions
          priceType
          priceInUSD
          priceInDoge
        }
        store_front {
          data {
            id
            attributes {
              name
              location {
                address
                latitude
                longitude
              }
            }
          }
        }
        saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
          data {
            id
            attributes {
              user {
                data {
                  id
                }
              }
            }
          }
        }
        user {
          data {
            id
            attributes {
              name
              username
            }
          }
        }
        publishedAt
      }
    }
  }

  storeFronts(
    pagination: { start: $storefrontOffset, limit: $limit }
    sort: $order
    filters: $storefrontFilters
  ) {
    data {
      id
      attributes {
        name
        image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
          data {
            attributes {
              url
              formats
            }
          }
        }
        user {
          data {
            id
            attributes {
              name
              username
            }
          }
        }
        saved_storefronts(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
          data {
            id
            attributes {
              user {
                data {
                  id
                }
              }
            }
          }
        }
        location {
          address
          latitude
          longitude
        }
        publishedAt
      }
    }
  }
}
`;

export const GET_LISTINGS_AND_STOREFRONTS = gql`
  ${queryText}
`;

export const GET_LISTINGS_AND_STOREFRONTS_STR = mapQueryText;
