import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_USER = gql`
  query getUsersByRole($searchText: String, $roles: [String], $offset: Int, $limit: Int) {
    userCounts: usersPermissionsUsers(
      filters: {
        role: { name: { in: $roles } }
        or: [{ name: { containsi: $searchText } }, { username: { containsi: $searchText } }]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      pagination: { limit: $limit, start: $offset }
      filters: {
        role: { name: { in: $roles } }
        or: [{ name: { containsi: $searchText } }, { username: { containsi: $searchText } }]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          type
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          wrongDecisions
          decisions
          correctDecisions
          email
          profileCompleted
          confirmed
          createdAt
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUsersByRole = (
  offset: number,
  limit: number,
  searchText: string,
  role: string
) => {
  let customRole = [role];
  if (role === 'all-users') {
    customRole = ['admin', 'super-admin', 'moderator', 'regular-user'];
  } else if (role === 'team-members') {
    customRole = ['admin', 'super-admin'];
  } else {
    customRole = [role];
  }
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_USER, {
    variables: { searchText, roles: customRole, offset, limit },
    fetchPolicy: 'cache-and-network'
  });
  return {
    users: flattenObj(data?.usersPermissionsUsers),
    userCounts: data?.userCounts?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
