import React, { FC, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Image from '../shared/Image';
import banner1 from '../../assets/images/Banner 1.png';
import ProfileAvatar from '../shared/ProfileAvatar';
import { Typo } from '../Typo';
import Badge from '../shared/Badge';
import Button from '../shared/Button';
import { getJoinDate } from '../../util/AppUtil';
import { User } from '../../model/UserModel';
import { useAppSelector } from '../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { setProfileData, showSlide } from '../../lib/redux/reducers/slide.slice';
import { useUpdateUserRole } from '../../hooks/reports/useUpdateUserRole';
import { AuthUserState } from '../../context/AuthUserProvider';
import { NUMBER_OF_ITEMS_COMMAND_MEMU, ROLES } from '../../constants/data';
import { useGetUsersByRole } from '../../hooks/reports/useGetUsersByRole';
import { handleAccountTypeBadge } from '../../util/ListingBadgeHandler';
import UserInfo from '../shared/User/UserInfo';
import ModalContainer from './CommandMenus';
import { getCurrentDate, getCurrentDateFormatted } from '../../util/CurrentDate';
import { useCreatePendingRole } from '../../hooks/user/useCreatePendingRole';
import Divider from '../shared/Divider';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../shared/Loader';
import { useGetUsersByRoleAndAccuracy } from '../../hooks/reports/useGetUserByRoleAndAccuracy';

type Props = {
  isOpen: boolean;
  onCloseAddAdminAndModModal: () => void;
  role: string;
};

const AddAdminAndModCommandMenu: FC<Props> = ({ isOpen, onCloseAddAdminAndModModal, role }) => {
  const intl = useIntl();
  const { roles, authUser } = AuthUserState();
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [isProspect, setIsProspect] = useState(false);
  const profileState = useAppSelector((state) => state.slide.profile);
  const [nonProspectOffset, setNonProspectOffset] = useState(0);
  const [prospectOffset, setProspectOffset] = useState(0);
  const {
    users: prospects,
    usersCount: prospectCounts,
    refetch: prospectRefetch,
    fetchMore: prospectFetchMore
  } = useGetUsersByRoleAndAccuracy(prospectOffset, 6, searchText, role, 100, 70);
  const {
    users: nonProspects,
    usersCount: nonProspectsCounts,
    refetch: nonProspectsRefetch,
    fetchMore: nonProspectsFetchMore
  } = useGetUsersByRoleAndAccuracy(nonProspectOffset, 6, searchText, role, 70, 0);

  const [selectedUser, setSeletedUser] = useState<User>(prospects && prospects[0]);
  const updateUserRole = useUpdateUserRole();
  const createPendingRole = useCreatePendingRole();

  const search = (value: string) => {
    setSearchText(value);
  };
  const admin = roles?.filter((role) => role?.name === ROLES.ADMIN);
  const accountType = handleAccountTypeBadge(selectedUser?.type || '', intl);

  const makeAdmin = async (user: User) => {
    const response = await updateUserRole({
      variables: {
        data: {
          role: admin[0].id
        },
        id: user.id
      }
    });
    if (response) {
      setSeletedUser(prospects[0]);
      isProspect ? prospectRefetch() : nonProspectsRefetch();
    }
  };

  const inviteUser = async (user: User) => {
    await createPendingRole({
      variables: {
        data: {
          date: getCurrentDateFormatted(),
          role: 'moderator',
          assignee: user.id,
          assigner: authUser.id,
          approved: false,
          publishedAt: getCurrentDate()
        }
      }
    }).then(() => {
      isProspect ? prospectRefetch() : nonProspectsRefetch();
    });
  };

  const fetchMoreProspectUsers = async () => {
    const newOffset = prospectOffset + NUMBER_OF_ITEMS_COMMAND_MEMU;
    await prospectFetchMore({
      variables: { offset: newOffset, limit: NUMBER_OF_ITEMS_COMMAND_MEMU }
    });
    setProspectOffset(newOffset);
  };
  const fetchMoreNonProspectUsers = async () => {
    const newOffset = nonProspectOffset + NUMBER_OF_ITEMS_COMMAND_MEMU;
    await nonProspectsFetchMore({
      variables: { offset: newOffset, limit: NUMBER_OF_ITEMS_COMMAND_MEMU }
    });
    setNonProspectOffset(newOffset);
  };

  return (
    <ModalContainer
      placeholder={intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_LISTING_SEARCH_PLACEHOLDER.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_SEARCH_PLACEHOLDER.defaultMessage
      })}
      isOpen={isOpen}
      onClose={onCloseAddAdminAndModModal}
      onSearch={search}
      defaultValue={searchText}>
      <div className="w-full flex ">
        <div className="w-[280px] min-w-[280px]">
          <div className="h-[180px] py-4 px-2 flex flex-col justify-start items-start">
            <Typo.textSm fontWeight="font-medium" className="text-gray-600 px-2">
              {role === 'moderator' ? (
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.ADMIN_PROSPECTS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.ADMIN_PROSPECTS.defaultMessage}
                />
              ) : (
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.MODERATORS_PROSPECTS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.MODERATORS_PROSPECTS.defaultMessage}
                />
              )}
            </Typo.textSm>
            <div className="h-[168px] mt-[2px] gap-[2px] flex flex-col  overflow-y-auto w-full">
              <InfiniteScroll
                dataLength={prospectCounts || 0}
                next={fetchMoreProspectUsers}
                hasMore={prospectCounts > prospectOffset}
                className="space-y-1"
                loader={<Loader className="h-20 mt-6" />}>
                {prospects?.map((user: User) => (
                  <UserInfo
                    key={user?.id}
                    clickedUser={selectedUser}
                    user={user}
                    clickUser={(user: User) => {
                      setSeletedUser(user);
                      setIsProspect(true);
                    }}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
          <Divider />
          <div className="h-[170px] pb-4 px-2 flex flex-col justify-start items-start mt-4 ">
            <Typo.textSm fontWeight="font-medium" className="text-gray-600 px-2">
              {role === 'moderator' ? (
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.MODERATORS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.MODERATORS.defaultMessage}
                />
              ) : (
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.USERS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.USERS.defaultMessage}
                />
              )}
            </Typo.textSm>
            <div className="h-[158px] w-full mt-[2px] gap-[2px] flex flex-col overflow-y-auto">
              <InfiniteScroll
                dataLength={nonProspectsCounts || 0}
                next={fetchMoreNonProspectUsers}
                hasMore={nonProspectsCounts > nonProspectOffset}
                className="space-y-1"
                loader={<Loader className="h-20 mt-6" />}>
                {nonProspects?.map((user: User) => (
                  <UserInfo
                    key={user?.id}
                    user={user}
                    clickedUser={selectedUser}
                    clickUser={(user: User) => {
                      setSeletedUser(user);
                      setIsProspect(false);
                    }}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>

        {selectedUser && (
          <div className="w-full flex ">
            <div className="h-full w-[1px] bg-gray-200" />
            <div className="pb-4 w-full">
              <div className="w-full relative ">
                <Image className="w-full h-[88px]" src={banner1}></Image>
                <ProfileAvatar
                  image=""
                  verified={false}
                  verfiedClassName=""
                  className=" w-[72px] h-[72px] absolute left-0 right-0 bottom-0 translate-y-[50%] mx-auto"></ProfileAvatar>
              </div>
              <div className="px-4 gap-2 w-full flex flex-col justify-center items-center mt-[52px]">
                <div className="flex gap-2 w-full justify-center truncate">
                  <Typo.textMd fontWeight="font-medium" className="text-gray-900 truncate">
                    {selectedUser?.name}
                  </Typo.textMd>
                  <Typo.textMd fontWeight="font-normal" className="text-gray-600 truncate">
                    @{selectedUser?.username}
                  </Typo.textMd>
                </div>
                <div className="flex gap-2">
                  <Badge theme={accountType?.theme} label={accountType?.name}></Badge>
                  {role === 'moderator' && (
                    <Badge
                      theme="gray"
                      label={
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.MODERATOR.id}
                          defaultMessage={TRANSLATED_CONSTANTS.MODERATOR.defaultMessage}
                        />
                      }></Badge>
                  )}
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.RATING.id}
                      defaultMessage={TRANSLATED_CONSTANTS.RATING.defaultMessage}
                    />
                  </Typo.textMd>
                  <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                    {selectedUser?.correctDecisions === 0
                      ? 0
                      : Math.round(
                          ((selectedUser?.correctDecisions || 0) /
                            ((selectedUser?.correctDecisions || 0) +
                              (selectedUser?.wrongDecisions || 0))) *
                            100
                        )}
                    %
                  </Typo.textMd>
                  <div className="Dot w-1.5 h-1.5 p-0.5 justify-center items-center inline-flex">
                    <div className="Dot w-0.5 h-0.5 bg-gray-400 rounded-full" />
                  </div>
                  <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                    {selectedUser?.correctDecisions || 0}/
                    {(selectedUser?.correctDecisions || 0) + (selectedUser?.wrongDecisions || 0)}
                  </Typo.textMd>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.JOINED.id}
                      defaultMessage={TRANSLATED_CONSTANTS.JOINED.defaultMessage}
                    />
                  </Typo.textMd>

                  <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                    {selectedUser?.createdAt &&
                      getJoinDate(selectedUser?.createdAt?.toString() || '', true)}
                  </Typo.textMd>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-center mt-6">
                <Button
                  onClick={() => {
                    if (!profileState) {
                      dispatch(
                        setProfileData({
                          userId: selectedUser.id
                        })
                      );
                      dispatch(showSlide('profile'));
                    }
                  }}
                  size="md"
                  theme="base">
                  <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                      defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
                    />
                  </Typo.textSm>
                </Button>
                <Button
                  onClick={() =>
                    role === 'moderator' ? makeAdmin(selectedUser) : inviteUser(selectedUser)
                  }
                  size="md"
                  theme="primary-yellow">
                  <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                    {role === 'moderator' ? (
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.ADD_ADMIN.id}
                        defaultMessage={TRANSLATED_CONSTANTS.ADD_ADMIN.defaultMessage}
                      />
                    ) : (
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.INVITE_USER.id}
                        defaultMessage={TRANSLATED_CONSTANTS.INVITE_USER.defaultMessage}
                      />
                    )}
                  </Typo.textSm>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};

export default AddAdminAndModCommandMenu;
