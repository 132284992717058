import { useQuery, gql } from '@apollo/client';

export const GET_USER_BY_USERNAME = gql`
  query checkUsername($username: String, $id: ID!) {
    usersPermissionsUsers(filters: { username: { eqi: $username }, and: { id: { ne: $id } } }) {
      data {
        id
      }
    }
  }
`;

export const useCheckUsername = (username: string, id: number) => {
  const { loading, error, data, refetch } = useQuery(GET_USER_BY_USERNAME, {
    variables: { username, id },
    fetchPolicy: 'cache-and-network'
  });
  return {
    user: data?.usersPermissionsUsers,
    loading,
    error,
    refetch
  };
};
