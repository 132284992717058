import Button from '../../../components/shared/Button';
import React, { useState } from 'react';
import Avatar from '../../../components/shared/Avatar';
import { Typo } from '../../../components/Typo';
import DashboardSquareIcon from '../../../components/icons/DashboardSquareIcon';
import { ACCOUNT_TYPE, ROUTES } from '../../../constants/data';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import shibaAvatarGray from '../../../assets/avatar/Shiba avatar grey.png';
import shibaAvatarPurple from '../../../assets/avatar/Shiba avatar purple.png';
import { useDispatch } from 'react-redux';
import { setAlertData, setAlertType } from '../../../lib/redux/reducers/alert.slice';
import { AuthUserState } from '../../../context/AuthUserProvider';
import { useUpdateUser } from '../../../hooks/user/useUpdateUser';
type Props = {
  accountType: string;
};
const CongratulationStep: React.FC<Props> = (props) => {
  const { accountType } = props;
  const intl = useIntl();
  const authUser = AuthUserState();
  const user = authUser.authUser;
  const dispatch = useDispatch();
  const updateUser = useUpdateUser();
  const [loading, setLoading] = useState(false);

  const showSuccessAlert = () => {
    dispatch(setAlertType('success'));
    dispatch(
      setAlertData({
        title: intl.formatMessage(
          {
            id: TRANSLATED_CONSTANTS.CREATE_ACCOUNT_SUCCESS_ALERT_TITLE.id,
            defaultMessage: TRANSLATED_CONSTANTS.CREATE_ACCOUNT_SUCCESS_ALERT_TITLE.defaultMessage
          },
          { name: user.username }
        ),
        description: intl.formatMessage(
          {
            id: TRANSLATED_CONSTANTS.CREATE_ACCOUNT_SUCCESS_ALERT_DESCRIPTION.id,
            defaultMessage:
              TRANSLATED_CONSTANTS.CREATE_ACCOUNT_SUCCESS_ALERT_DESCRIPTION.defaultMessage
          },
          { name: user.username }
        ),
        link: ROUTES.DASHBOARD_ROUTE,
        confirmTitle: intl.formatMessage({
          id: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.id,
          defaultMessage: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.defaultMessage
        }),
        cancelTitle: intl.formatMessage({
          id: TRANSLATED_CONSTANTS.DISMISS.id,
          defaultMessage: TRANSLATED_CONSTANTS.DISMISS.defaultMessage
        }),
        image: ''
      })
    );
  };

  const onCompleteProfile = async () => {
    setLoading(true);
    try {
      await updateUser({
        variables: {
          data: {
            profileCompleted: true
          },
          id: user?.id
        }
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  return (
    <div className="w-full px-4 pt-12 flex justify-center items-center sm:pt-24">
      <div className="max-w-lg mx-auto">
        <Avatar
          disabled={true}
          size="2xl"
          image={
            accountType == ACCOUNT_TYPE.PERSONAL ? shibaAvatarGray : shibaAvatarPurple
          }></Avatar>
        <Typo.headingXs fontWeight="font-medium" className="text-gray-900 mt-6">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.CONGRATULATIONS.id,
            defaultMessage: TRANSLATED_CONSTANTS.CONGRATULATIONS.defaultMessage
          })}
        </Typo.headingXs>
        <Typo.textMd fontWeight="font-normal" className="text-gray-700 mt-5">
          {accountType == ACCOUNT_TYPE.PERSONAL
            ? intl.formatMessage({
                id: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT_SUCCESSFULY_CREATED.id,
                defaultMessage:
                  TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT_SUCCESSFULY_CREATED.defaultMessage
              })
            : intl.formatMessage({
                id: TRANSLATED_CONSTANTS.BUSINESS_ACCOUNT_SUCCESSFULY_CREATED.id,
                defaultMessage:
                  TRANSLATED_CONSTANTS.BUSINESS_ACCOUNT_SUCCESSFULY_CREATED.defaultMessage
              })}
        </Typo.textMd>
        <Typo.textMd fontWeight="font-normal" className="text-gray-500 mt-2">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.CONGRATULATION_DESCRIPTION_MESSAGE.id,
            defaultMessage: TRANSLATED_CONSTANTS.CONGRATULATION_DESCRIPTION_MESSAGE.defaultMessage
          })}
        </Typo.textMd>

        <div className="flex flex-col gap-y-3 sm:flex-row sm:gap-x-3 mt-8">
          <Button
            size="lg"
            theme="base"
            iconPos="right"
            loading={loading}
            onClick={async () => {
              await onCompleteProfile();
              window.window.location.href = `${ROUTES.DASHBOARD_ROUTE}`;
              showSuccessAlert();
            }}
            icon={<DashboardSquareIcon className="h-5 w-5 text-gray-700" />}>
            <Typo.textMd fontWeight="font-semibold">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.id,
                defaultMessage: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.defaultMessage
              })}
            </Typo.textMd>
          </Button>
          <Button
            onClick={async () => {
              await onCompleteProfile();
              window.window.location.href = `${ROUTES.MARKETPLACE_ROUTE}`;
              showSuccessAlert();
            }}
            size="lg"
            loading={loading}
            theme="primary-yellow">
            <Typo.textMd fontWeight="font-semibold">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
                defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
              })}
            </Typo.textMd>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CongratulationStep;
