import { flattenObj } from '../../util/flattenObj';
import { useQuery, gql } from '@apollo/client';
import { customizeMessage } from './transform';

export const GET_MESSAGE = gql`
  query GetMessages($chatId: ID, $offset: Int, $limit: Int) {
    messages(
      sort: "publishedAt:ASC"
      pagination: { start: $offset, limit: $limit }
      filters: { chat: { id: { eq: $chatId } } }
    ) {
      data {
        id
        attributes {
          content
          publishedAt
          sender {
            data {
              id
            }
          }
          receiver {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const useGetMessages = (chatId: string, offset: number, limit: number) => {
  const { loading, error, data, fetchMore, updateQuery, refetch } = useQuery(GET_MESSAGE, {
    variables: { chatId, offset, limit },
    fetchPolicy: 'cache-and-network'
  });
  const flattedData = flattenObj(data?.messages);
  const customizedData = flattedData?.map((message: any) => {
    return customizeMessage(message);
  });

  return {
    updateQuery,
    messages: customizedData,
    fetchMore,
    loading,
    error,
    refetch
  };
};
