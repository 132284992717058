import React, { FC, useState } from 'react';
import '../../../style/slick.scss';
import Slider from 'react-slick';
import Image from '../Image';
import NextArrow from './NextArrow';
import PrevArrow from './PrevArrow';
import Indicators from './Indicators';
import Badge from '../Badge';
import DotIcon from '../../icons/DotIcon';
import { handleListingAndStorefrontBadge } from '../../../util/ListingBadgeHandler';
import { LISTING_CARD_TYPES, LISTING_TYPES } from '../../../constants/data';
import TagItemIcon from '../../icons/TagItemIcon';
import { useIntl } from 'react-intl';
import defaultWantedImage from '../../../assets/images/Wanted default image.png';
type Props = {
  imageUrls: string[];
  type: string;
  className?: string;
};

const ImageSlider: FC<Props> = ({ imageUrls, type, className }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const intl = useIntl();
  const listingNameAndThem = handleListingAndStorefrontBadge(type, intl);
  let images = imageUrls;
  if (imageUrls.length === 0 && type === LISTING_TYPES.BUYING) {
    images = [defaultWantedImage];
  }
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    afterChange: (index: number) => setCurrentSlide(index)
  };

  return (
    <div className={`w-full  max-h-[500px] relative ${className}`}>
      <Indicators count={imageUrls?.length} activeSlide={currentSlide} />
      <div className="absolute bottom-2.5 w-full left-2 z-[2]">
        <Badge
          size="sm"
          label={listingNameAndThem.name}
          icon={
            type !== LISTING_CARD_TYPES.STOREFRONT_ITEM ? (
              <DotIcon></DotIcon>
            ) : (
              <TagItemIcon></TagItemIcon>
            )
          }
          className="absolute bottom-2 left-2"
          theme={listingNameAndThem.theme}></Badge>
      </div>
      <Slider {...settings} className="w-full rounded-lg overflow-hidden">
        {images?.map((image, index) => (
          <Image
            key={index}
            alt=""
            src={image}
            className="rounded-lg h-[250px] md:h-[438px] lg:h-[500px] w-full object-cover max-h-[500px] "
          />
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
