/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import { User } from '../../../model/UserModel';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../lib/redux/reducers/modal.slice';
import { useGetUsersByRole } from '../../../hooks/reports/useGetUsersByRole';
import { NUMBER_OF_RECORDS_PER_TABLE, ROLES } from '../../../constants/data';
import TableTitle from '../../../components/shared/table/TableTitle';
import SearchIcon from '../../../components/icons/SearchIcon';
import { useIntl } from 'react-intl';
import InputField from '../../../components/shared/InputField';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import Table from '../../../components/shared/table/Table';
import TableHead from './TableHead';
import CheckboxField from '../../../components/shared/CheckboxField';
import UserInfo from '../../../components/shared/table/UserInfo';
import { Typo } from '../../../components/Typo';
import { handleAccountTypeBadge } from '../../../util/ListingBadgeHandler';
import Badge from '../../../components/shared/Badge';
import { formatDate } from '../../../util/AppUtil';
import Button from '../../../components/shared/Button';
import TrashIcon from '../../../components/icons/TrashIcon';
import ProgressBar from '../../../components/shared/ProgressBar';
import { AuthUserState } from '../../../context/AuthUserProvider';
import Divider from '../../../components/shared/Divider';
import Pagination from '../../../components/shared/Pagination';
import PrimaryActionModal from '../../../components/modal/PrimaryActionModal';
import { useUpdateRoles } from '../../../hooks/reports/useUpdateRoles';
import Loader from '../../../components/shared/Loader';
import EmptyReportListings from '../EmptyReportListings';
import AddAdminAndModCommandMenu from '../../../components/Command Menus/AddAdminAndModCommandMenu';
import InlineAlert from '../../../components/shared/InlineAlert';

type Props = {
  user: User;
};

const Admins: FC<Props> = ({ user }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const register = () => {};
  const { roles } = AuthUserState();

  const openAddAdminModal = useAppSelector((state) => state.modal.addAdmin);
  const [searchText, setSearchText] = useState('');
  const [checked, setChecked] = useState(false);
  const [selectedUsers, setSeletedUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const { users, userCounts, fetchMore, loading, error, refetch } = useGetUsersByRole(
    offset,
    NUMBER_OF_RECORDS_PER_TABLE,
    searchText,
    ROLES.ADMIN
  );
  const updateRole = useUpdateRoles();
  const regularUser = roles?.filter((role) => role?.name === ROLES.REGULAR_USER);

  const onCloseModal = () => {
    dispatch(hideModal('addAdmin'));
    refetch();
  };

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSeletedUsers(users);
    } else {
      setSeletedUsers([]);
    }
  };

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };

  const onDeleteUserButtonClicked = (user: User) => {
    const exists = selectedUsers.some((selectedUser) => selectedUser.id === user.id);
    if (!exists) {
      setSeletedUsers([...selectedUsers, user]);
    }
    setShowDeleteModal(true);
  };

  const onCloseDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowDeleteModal(false);
      return;
    }

    const usersIds = selectedUsers.map((user) => user.id);
    const response = await updateRole({ usersIds: usersIds, roleId: regularUser[0].id });
    if (response) {
      refetch();
    }
    setShowDeleteModal(false);
  };
  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row gap-4 md:justify-between md:items-center pb-8">
        <TableTitle
          title={intl.formatMessage({
            id: 'page.report.admin.table.title',
            defaultMessage: 'Admins'
          })}
          description={intl.formatMessage({
            id: 'page.report.admin.table.subtitle',
            defaultMessage: 'All admins are displayed here.'
          })}
        />
        <div className="md:max-w-[400px] w-full ">
          <InputField
            prefix={<SearchIcon className="h-5 w-5 text-gray-500" />}
            name="searchInput"
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.SEARCH.id,
              defaultMessage: TRANSLATED_CONSTANTS.SEARCH.defaultMessage
            })}
            type="text"
            className="h-10"
            onChange={(e: any) => setSearchText(e.target.value)}
            register={register}
          />
        </div>
      </div>
      <div className="w-full">
        {users?.length === 0 && !loading ? (
          <InlineAlert
            className="flex justify-center"
            text={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NOTHING_HERE_TO_DISPLAY.id,
              defaultMessage: TRANSLATED_CONSTANTS.NOTHING_HERE_TO_DISPLAY.defaultMessage
            })}
            type="default"
            state={true}
          />
        ) : (
          <div className="w-full">
            {loading ? (
              <Loader className="h-[400px] min-w-full w-full flex justify-center items-center" />
            ) : (
              <div className="min-w-full w-full overflow-x-auto  ">
                <Table>
                  <TableHead checked={checked} toggleAll={toggleAll} />

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {users?.map((user: User) => {
                      const userExistsInSelectedUsers = selectedUsers.some(
                        (eachObj: any) => eachObj.id === user.id
                      );
                      const accountType = handleAccountTypeBadge(user?.type || '', intl);

                      return (
                        <tr
                          key={user.id}
                          className={userExistsInSelectedUsers ? 'bg-gray-50' : undefined}>
                          <td className="relative  sm:w-12 sm:px-6 py-3">
                            <div className="absolute left-6 top-1/2 -mt-3">
                              <CheckboxField
                                name="state"
                                value="check"
                                label=""
                                size="md"
                                type="checkbox"
                                roundedSize="rounded-md"
                                isSelected={userExistsInSelectedUsers}
                                onChange={(e) =>
                                  setSeletedUsers(
                                    e.target.checked
                                      ? [...selectedUsers, user]
                                      : selectedUsers.filter((u: any) => u.id !== user.id)
                                  )
                                }
                                register={() => {}}
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4">
                            <UserInfo profileImage={user?.profileImage?.url || ''}>
                              <div className="">
                                <Typo.textSm
                                  fontWeight="font-medium"
                                  className="text-gray-900 capitalize">
                                  {user?.name || ''}
                                </Typo.textSm>

                                <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                  @{user?.username || ''}
                                </Typo.textSm>
                              </div>
                            </UserInfo>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4">
                            <Badge theme={accountType.theme} label={accountType.name}></Badge>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4">
                            <Typo.textSm fontWeight="font-medium" className="text-gray-400">
                              {formatDate(user?.createdAt?.toString() || '')}
                            </Typo.textSm>
                          </td>

                          <td className="whitespace-nowrap  py-4 ">
                            <Typo.textSm fontWeight="font-medium" className="text-gray-500">
                              {user?.email}
                            </Typo.textSm>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 ">
                            <ProgressBar
                              value={Math.round(
                                ((user?.correctDecisions || 0) /
                                  ((user?.correctDecisions || 0) + (user?.wrongDecisions || 0))) *
                                  100
                              )}
                              showLabel={true}></ProgressBar>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-5 ">
                            <Typo.textSm
                              fontWeight="font-medium"
                              className="text-gray-500 capitalize">
                              {user?.correctDecisions} /
                              {(user?.correctDecisions || 0) + (user?.wrongDecisions || 0)}
                            </Typo.textSm>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 ">
                            <Button
                              onClick={() => onDeleteUserButtonClicked(user)}
                              size="md"
                              theme="gray-link"
                              icon={<TrashIcon className="w-5 h-5 text-gray-600" />}></Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Divider />
                <div className="px-6">
                  <Pagination
                    currentPage={currentPage}
                    totalCount={userCounts}
                    pageSize={NUMBER_OF_RECORDS_PER_TABLE}
                    onPageChange={paginate}
                    type="centered-page-number"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {openAddAdminModal && (
        <AddAdminAndModCommandMenu
          isOpen={openAddAdminModal}
          onCloseAddAdminAndModModal={onCloseModal}
          role={ROLES.MODERATOR}
        />
      )}
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
        isOpen={showDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default Admins;
