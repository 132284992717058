import { createSlice } from '@reduxjs/toolkit';

const shareSlice = createSlice({
  name: 'share',
  initialState: {
    isOpen: false,
    url: ''
    // Add more modal states as needed
  },
  reducers: {
    setShareData: (state, action) => {
      state.url = action.payload.url;
      state.isOpen = action.payload.isOpen;
    }
  }
});

export const { setShareData } = shareSlice.actions;
export default shareSlice.reducer;
