import React, { useState } from 'react';
import { ListingNew } from '../../../../model/Listing';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LISTING_BREAKPOINT, LIST_PAGES } from '../../../../constants/data';
import Loader from '../../../shared/Loader';
import { useHandleSave } from '../../../../hooks/useHandleSave';
import { useGetStorefrontItems } from '../../../../hooks/storfront/useGetStorefronts';
import StorefrontItemCard from '../../../shared/ListingCard/StorefrontItemCard';
import { Storefront } from '../../../../model/Storefront';
type Props = {
  className?: string;
  userId: number;
  storefront: Storefront;
};

const ProfileStorefrontItems: React.FC<Props> = (props) => {
  const { userId, storefront } = props;
  const NUMBER_OF_ITEMS_PER_PAGE = 3;
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(NUMBER_OF_ITEMS_PER_PAGE);
  const { listings, total, loading, fetchMore, refetch } = useGetStorefrontItems(
    userId,
    Number(storefront.id),
    perPage,
    0
  );

  const { save } = useHandleSave(refetch);

  const fetchMoreListing = async () => {
    const newOffset = offset + NUMBER_OF_ITEMS_PER_PAGE;
    const newPerPage = perPage + NUMBER_OF_ITEMS_PER_PAGE;

    fetchMore({
      variables: { offset: newOffset, limit: NUMBER_OF_ITEMS_PER_PAGE }
    });
    setOffset(newOffset);
    setPerPage(newPerPage);
  };

  return (
    <div className="w-full">
      {!loading && listings ? (
        <InfiniteScroll
          dataLength={total || 0}
          next={fetchMoreListing}
          hasMore={total > offset} // You should implement a condition to stop when all data is fetched
          scrollableTarget="profileStorefrontItemsContent"
          className="space-y-4"
          loader={<Loader className="h-20 mt-6" />}>
          {listings?.map((listing: ListingNew) => {
            return (
              <StorefrontItemCard
                onLike={save}
                cardClickable={true}
                size={LISTING_BREAKPOINT.SLIDE}
                page={LIST_PAGES.MAP}
                key={listing.id}
                storeFront={storefront}
                listing={listing}
              />
            );
          })}
        </InfiniteScroll>
      ) : (
        <Loader className=" h-screen" />
      )}
    </div>
  );
};

export default ProfileStorefrontItems;
