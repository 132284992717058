import Button from '../Button';
import { ListingNew } from '../../../model/Listing';
import React from 'react';
import { Typo } from '../../Typo';
import Price from '../Price';
import RefreshIcon from '../../icons/RefreshIcon';
import { useIntl } from 'react-intl';
import { shortenText } from '../../../util/AppUtil';
import {
  ACCOUNT_TYPE,
  ALTERNATE_PRICE_OPTION,
  LISTING_BREAKPOINT,
  LISTING_CARD_TYPES,
  LISTING_TYPES,
  LIST_PAGES,
  ROUTES
} from '../../../constants/data';
import clsx from 'clsx';
import ShoppingBagIcon from '../../icons/ShoppingBagIcon';
import MessageIcon from '../../icons/MessageIcon';
import EyeIcon from '../../icons/EyeIcon';
import PriceOption from '../PriceOption';
import CardWrapper from './CardWrapper';
import ListingCardAction from './ListingCardAction';
import FavoriteAction from './FavoriteAction';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useHandleMessageSenderAuth } from '../../../hooks/useHandleMessageSenderAuth';
import { AuthUserState } from '../../../context/AuthUserProvider';
import GetDirectionAction from './GetDirectionAction';
type PriceProps = {
  page?:
    | LIST_PAGES.MAP
    | LIST_PAGES.SAVED
    | LIST_PAGES.ARCHIVE
    | LIST_PAGES.DASHBOARD
    | LIST_PAGES.SLIDE;
  size?: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  onEdit?: (id: number | string) => void;
  onDelete?: (id: number) => void;
  onShare?: (id: number | string) => void;
  onRelist?: (id: number) => void;
  onMarkAsSold?: (id: number) => void;
  onLike?: (id: number, type: string, like: boolean, savedListingId?: number) => void;
  listing: ListingNew;
  cardClickable?: boolean;
  className?: string;
};
const ListingCard: React.FC<PriceProps> = (props) => {
  const { onSendMessage } = useHandleMessageSenderAuth(props.listing);
  const { me, authUser } = AuthUserState();
  const {
    page = LIST_PAGES.MAP,
    size = LISTING_BREAKPOINT.DEFAULT,
    listing,
    onDelete,
    onEdit,
    onShare,
    onMarkAsSold,
    onRelist,
    onLike,
    cardClickable = false,
    className
  } = props;
  const intl = useIntl();
  const onSave = (id: number, type: string) => {
    onLike &&
      onLike(
        id,
        type,
        listing?.saved_listings && listing?.saved_listings?.length > 0 ? true : false,
        listing?.saved_listings && listing?.saved_listings?.length > 0
          ? Number(listing?.saved_listings[0]?.id)
          : undefined
      );
  };

  return (
    <CardWrapper
      type="listing"
      cardClickable={cardClickable}
      slug={listing?.slug}
      id={listing?.id}
      size={size}
      intl={intl}
      className={className}
      imageUrl={listing?.image[0]?.url}
      hasAward={listing?.price?.priceType == ALTERNATE_PRICE_OPTION.DOGE_DOGE}
      listingType={
        listing?.type == LISTING_TYPES.SELLING
          ? LISTING_CARD_TYPES.PERSONAL
          : LISTING_CARD_TYPES.WANTED
      }>
      {/* list description  */}
      <div className="w-full">
        {/* listing and user description   */}
        <div className="flex flex-col">
          <div className="flex justify-between items-start">
            <div className="">
              <div
                className={clsx('mb-4', {
                  ' block': size == LISTING_BREAKPOINT.SLIDE || LISTING_BREAKPOINT.MOBILE,
                  ' block sm:hidden': size === LISTING_BREAKPOINT.DEFAULT
                })}>
                {listing?.price.priceInUSD && (
                  <Price
                    alternatePriceOptions={listing?.price?.alternatePriceOptions}
                    priceInUSD={listing?.price?.priceInUSD || 0}
                    priceInDoge={listing?.price?.priceInDoge || 0}></Price>
                )}
              </div>

              <div>
                <Typo.textSm fontWeight="font-medium" className="text-blue-gray-500">
                  {`@${listing?.user && listing?.user.username}`}
                </Typo.textSm>
                <Typo.textLg fontWeight="font-medium" className=" text-gray-900 mt-1">
                  {listing?.name}
                </Typo.textLg>
              </div>
            </div>
            {/* card ation button */}
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}>
              {/*  like and dislike button */}
              {(page === LIST_PAGES.MAP || page === LIST_PAGES.SAVED) && (
                <FavoriteAction
                  disabled={me?.id == listing?.user?.id}
                  liked={
                    listing?.saved_listings && listing?.saved_listings.length > 0 ? true : false
                  }
                  size={size}
                  id={listing?.id || 0}
                  onLike={() => onSave(listing?.id, 'listing')}></FavoriteAction>
              )}

              {/* reslit button on archive */}
              {page === 'archive' && (
                <>
                  <Button
                    size="lg"
                    iconPos="right"
                    theme="gray"
                    className={clsx({
                      'hidden sm:block': size == LISTING_BREAKPOINT.DEFAULT,
                      ' hidden':
                        size == LISTING_BREAKPOINT.MOBILE || size === LISTING_BREAKPOINT.SLIDE
                    })}
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      onRelist && onRelist(listing?.id || 0);
                    }}
                    icon={<RefreshIcon></RefreshIcon>}>
                    <Typo.textMd fontWeight="font-semibold">
                      {intl.formatMessage({ id: 'shared.relist', defaultMessage: 'Relist' })}
                    </Typo.textMd>
                  </Button>
                  <Button
                    size="sm"
                    iconPos="right"
                    className={clsx({
                      ' block sm:hidden ': size == LISTING_BREAKPOINT.DEFAULT,
                      ' block':
                        size == LISTING_BREAKPOINT.MOBILE || size == LISTING_BREAKPOINT.SLIDE
                    })}
                    theme="gray"
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      onRelist && onRelist(listing?.id || 0);
                    }}
                    icon={<RefreshIcon></RefreshIcon>}></Button>
                </>
              )}

              {/* share , edit and delete on dashboard  */}
              {page === 'dashboard' && (
                <ListingCardAction
                  id={listing?.id || 0}
                  onDelete={() => onDelete && onDelete(listing.id)}
                  onEdit={onEdit}
                  onShare={onShare}
                />
              )}
            </div>
          </div>
          <Typo.textMd className=" text-gray-500 w-full mt-4 mb-4" fontWeight="font-normal">
            {shortenText(listing?.description || '', 60)}
          </Typo.textMd>
        </div>

        <div
          className={clsx(
            'flex justify-between items-start',
            size == LISTING_BREAKPOINT.SLIDE ? ' block sm:hidden ' : ' block '
          )}>
          <div className="flex justify-between items-center gap-x-2">
            {/* send message button on map */}
            {(page == LIST_PAGES.MAP || page === LIST_PAGES.SAVED) &&
            listing?.user?.type === ACCOUNT_TYPE.PERSONAL ? (
              <Button
                type="button"
                theme="blue-link"
                className="underline "
                disabled={me && me?.id == listing?.user?.id}
                onClick={(event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                  onSendMessage();
                }}
                icon={<MessageIcon className="text-blue-gray-600"></MessageIcon>}>
                <Typo.textMd fontWeight="font-semibold">
                  {listing?.type === LISTING_TYPES.SELLING &&
                    intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.MESSAGE_SELLER.id,
                      defaultMessage: TRANSLATED_CONSTANTS.MESSAGE_SELLER.defaultMessage
                    })}
                  {listing?.type === LISTING_TYPES.BUYING &&
                    intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.MESSAGE_BUYER.id,
                      defaultMessage: TRANSLATED_CONSTANTS.MESSAGE_BUYER.defaultMessage
                    })}
                </Typo.textMd>
              </Button>
            ) : (
              <GetDirectionAction
                size={size}
                currentLat={authUser?.location?.latitude || 0}
                currentLong={authUser?.location?.longitude || 0}
                destinationLat={listing?.location?.latitude || 0}
                destinationLong={listing?.location?.longitude || 0}
                destinationAddress={listing?.location?.address || ''}></GetDirectionAction>
            )}

            {/* Mark as sold button on dashboard */}
            {(page == LIST_PAGES.ARCHIVE || page == LIST_PAGES.DASHBOARD) && (
              <Button
                type="button"
                theme="blue-link"
                className=" underline "
                onClick={(event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                  onMarkAsSold ? onMarkAsSold(listing?.id || 0) : {};
                }}
                disabled={listing?.isSold}
                icon={<ShoppingBagIcon></ShoppingBagIcon>}>
                <Typo.textMd fontWeight="font-semibold">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.MARK_AS_SOLD.id,
                    defaultMessage: TRANSLATED_CONSTANTS.MARK_AS_SOLD.defaultMessage
                  })}
                </Typo.textMd>
              </Button>
            )}

            {/* Preview button */}
            {page === LIST_PAGES.DASHBOARD && (
              <div className="flex gap-x-6">
                <Button
                  type="button"
                  theme="blue-link"
                  className="underline "
                  to={`${ROUTES.LISTING_DETAILS_ROUTE}/${listing?.id}`}
                  icon={<EyeIcon className=" stroke-blue-gray-600"></EyeIcon>}>
                  <Typo.textMd fontWeight="font-semibold">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.PREVIEW.id,
                      defaultMessage: TRANSLATED_CONSTANTS.PREVIEW.defaultMessage
                    })}
                  </Typo.textMd>
                </Button>
                <PriceOption title={listing?.price?.priceType}></PriceOption>
              </div>
            )}
          </div>
          {/* card price */}
          <div
            className={clsx('mb-4', {
              ' hidden': size === LISTING_BREAKPOINT.MOBILE || size == LISTING_BREAKPOINT.SLIDE,
              ' hidden sm:block': size === LISTING_BREAKPOINT.DEFAULT
            })}>
            <Price
              alternatePriceOptions={listing?.price?.alternatePriceOptions}
              priceInUSD={listing.price.priceInUSD}
              priceInDoge={listing.price.priceInDoge}></Price>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default ListingCard;
