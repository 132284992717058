/* eslint-disable no-useless-catch */
import axios from 'axios';
import { ImageFile } from '../../model/Image';
export const useUploadFiles = () => {
  const token = localStorage.getItem('token');
  const onUpload = async (images: ImageFile[], ref: string, refId: string, field: string) => {
    try {
      const formData = new FormData();
      formData.append('ref', ref);
      formData.append('refId', refId);
      formData.append('field', field);
      images?.map((file) => {
        formData.append(`files`, file.file);
      });
      const uploadResponse = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_STRAPI_API_URL}/api/upload`,
        data: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      return uploadResponse;
    } catch (error) {
      throw error;
    }
  };

  const onDelete = async (files: ImageFile[]) => {
    try {
      const ids = files.map((file) => file.id);
      ids?.map(async (id) => {
        await axios({
          method: 'DELETE',
          url: `${process.env.REACT_APP_STRAPI_API_URL}/api/upload/files/${id}`,
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      });
    } catch (error) {
      throw error;
    }
  };

  return { onUpload, onDelete };
};
