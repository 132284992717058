import Button from '../../../components/shared/Button';
import InputField from '../../../components/shared/InputField';
import { CONSTANT } from '../../../static/constants';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { Typo } from '../../../components/Typo';
import EmailIcon from '../../../components/icons/EmailIcon';
import TwitterIcon from '../../../components/icons/TwitterIcon';
import LinkedInIcon from '../../../components/icons/LinkedInIcon';
import InstagramIcon from '../../../components/icons/InstagramIcon';
import TikTokIcon from '../../../components/icons/TikTokIcon';
import FacebookIcon from '../../../components/icons/FacebookIcon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useCreateEmailList } from '../../../hooks/useSubscribe';
import { getCurrentDate } from '../../../util/CurrentDate';
import DiscordIcon from '../../../components/icons/DiscordIcon';
const Subscriber = ({
  className = '',
  maxWidth = ''
}: {
  className?: string;
  maxWidth?: string;
}) => {
  const subscribe = useCreateEmailList();
  const schema = yup.object().shape({
    email: yup.string().required().email()
  });
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    await subscribe({
      variables: {
        data: { email: data.email, publishedAt: getCurrentDate() }
      }
    });
  };

  return (
    <div className={'w-full 2xl:px-0 lg:px-[120px] mb-32 ' + className}>
      <div className={`max-w-7xl relative mx-auto flex justify-center ${maxWidth}`}>
        <div className=" py-12 bg-gradient-to-r px-4 sm:px-16 from-[#FBFAF9] to-[#F9FAFB] flex justify-center w-full gap-12 md:rounded-2xl">
          <div className="w-full sm:max-w-[518px] flex justify-center text-center flex-col md:items-center">
            <Typo.headingMd fontWeight="font-medium" className="text-gray-900">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.JSON_EMAIL_LIST.id}
                defaultMessage={TRANSLATED_CONSTANTS.JSON_EMAIL_LIST.defaultMessage}
              />
            </Typo.headingMd>
            <Typo.textXl fontWeight="font-normal" className="text-gray-600 mt-6">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.JSON_EMAIL_LIST_TITLE.id}
                defaultMessage={TRANSLATED_CONSTANTS.JSON_EMAIL_LIST_TITLE.defaultMessage}
              />
            </Typo.textXl>
            <div className="my-8">
              <form id="subscribe" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
                <div className=" flex flex-col gap-y-3 sm:flex-row items-start sm:items-start  gap-x-3 ">
                  <InputField
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    className="md:min-w-[320px]"
                    prefix={<EmailIcon className="text-gray-500 h-5 w-5"></EmailIcon>}
                    register={register}
                    errors={errors?.email}
                    verificationtext={CONSTANT.USER_NAME_VERIFICATION_NOTE}
                  />
                  <Button
                    size="lg"
                    theme="primary-yellow"
                    className="w-full sm:w-auto min-w-fit"
                    type="submit">
                    <Typo.textMd fontWeight="font-semibold">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.SIGN_UP.id}
                        defaultMessage={TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage}
                      />
                    </Typo.textMd>
                  </Button>
                </div>
              </form>
            </div>

            <div className=" flex justify-center gap-x-6 text-gray-400">
              <TwitterIcon className="w-6 h-6" />

              <LinkedInIcon className="w-6 h-6 " />

              <FacebookIcon className="w-6 h-6 " />

              <InstagramIcon className="w-6 h-6 " />

              <TikTokIcon className="w-6 h-6 " />

              <a href={process.env.REACT_APP_DISCORD_URL} rel="noreferrer" target="_blank">
                <DiscordIcon className="w-6 h-6 " />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Subscriber;
