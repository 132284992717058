import { defineMessage } from 'react-intl';

export const TRANSLATED_CONSTANTS = {
  DELETE_LISTING: defineMessage({
    id: 'modal.listing_delete_button',
    defaultMessage: 'Delete Listing'
  }),
  DELETE_STOREFRONT: defineMessage({
    id: 'modal.storefront_delete_button',
    defaultMessage: 'Delete Storefront'
  }),
  DELETE_STOREFRONT_ITEM: defineMessage({
    id: 'modal.storefront_item_delete_button',
    defaultMessage: 'Delete Storefront Item'
  }),
  DELETE_CHAT: defineMessage({
    id: 'delete_chat',
    defaultMessage: 'Delete Chat'
  }),
  LISTING_DELETE_MODAL_SUBTITLE: defineMessage({
    id: 'modal.listing_delete_subtitle',
    defaultMessage: 'Are you sure you want to delete this listing? This action cannot be undone.'
  }),
  LISTING_DELETE_MODAL_TITLE: defineMessage({
    id: 'modal.listing_delete_title',
    defaultMessage: 'Delete listing?'
  }),
  CHAT_DELETE_MODAL_TITLE: defineMessage({
    id: 'chat.chat_delete_title',
    defaultMessage: 'Message'
  }),
  CLEAR_FLAG_MODAL_TITLE: defineMessage({
    id: 'modal.clear_flag_title',
    defaultMessage: 'Clear all flags for this listing?'
  }),
  CLEAR_FLAG_MODAL_SUBTITLE: defineMessage({
    id: 'modal.clear_flag_subtitle',
    defaultMessage:
      'Clearing all flags removes past reports made on this listing. This cannot be undone.'
  }),
  CLEAR_ALL_FLAG_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.clear_all_flags_confirm_button',
    defaultMessage: 'Clear all flags'
  }),
  LEAVE_MOD_MODAL_TITLE: defineMessage({
    id: 'modal.leave_mod_title',
    defaultMessage: 'Leave the Mod Squad?'
  }),
  LEAVE_MOD_MODAL_SUBTITLE: defineMessage({
    id: 'modal.leave_mod_subtitle',
    defaultMessage: 'Are you sure you want to leave your role as moderator? This cannot be undone.'
  }),
  LEAVE_MOD_ADMIN_SUBTITLE: defineMessage({
    id: 'modal.leave_mod_admin_subtitle',
    defaultMessage: 'Are you sure you want to leave your role as admin? This cannot be undone.'
  }),
  LEAVE_MOD_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.leave_mod_confirm_button',
    defaultMessage: 'Yes, I’m leaving'
  }),
  LEAVE_MOD_MODAL_CANCEL_BUTTON: defineMessage({
    id: 'modal.leave_mod_cancel_button',
    defaultMessage: 'Nevermind, I’ll stay'
  }),
  BAN_MODAL_TITLE: defineMessage({
    id: 'modal.ban_title',
    defaultMessage: 'Are you sure you want to ban {username}?'
  }),
  DEACTIVATE_MODAL_TITLE: defineMessage({
    id: 'modal.deactivate_title',
    defaultMessage: 'Are you sure you want to deactivate your account?'
  }),
  DEACTIVATE_MODAL_SUBTITLE: defineMessage({
    id: 'modal.deactivate_subtitle',
    defaultMessage: 'Your account will be perminantly deleted.'
  }),
  DEACTIVATE_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.deactivate_confirm_button',
    defaultMessage: 'Deactivate'
  }),
  BAN_MODAL_SUBTITLE: defineMessage({
    id: 'modal.ban_subtitle',
    defaultMessage:
      'All listings from {username} will be removed and will lose access to their account until unblocked. '
  }),
  BAN_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.ban_confirm_button',
    defaultMessage: 'Ban user'
  }),
  QUARANTINE_MODAL_TITLE: defineMessage({
    id: 'modal.quarantine_title',
    defaultMessage: 'Quarantine this listing?'
  }),
  QUARANTINE_MODAL_SUBTITLE: defineMessage({
    id: 'modal.quarantine_subtitle',
    defaultMessage:
      'This listing will be removed from the marketplace for reasons that go against the Dogelist terms of service. This cannot be undone.'
  }),
  QUARANTINE_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.quarantine_confirm_button',
    defaultMessage: 'Quarantine listing'
  }),
  BLOCK_MODAL_TITLE: defineMessage({
    id: 'modal.block_title',
    defaultMessage: 'Are you sure you want to block {username}?'
  }),
  BLOCK_MODAL_SUBTITLE: defineMessage({
    id: 'modal.block_subtitle',
    defaultMessage: 'You will no longer be able to send or receive messages from them.'
  }),
  BLOCK_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.block_confirm_button',
    defaultMessage: 'BLOCK'
  }),
  DELETE_BAN_MODAL_TITLE: defineMessage({
    id: 'modal.delete_ban_title',
    defaultMessage: 'Delete listing & ban user'
  }),
  DELETE_MESSAGE_REPORT_MODAL_TITLE: defineMessage({
    id: 'modal.delete_message_report_title',
    defaultMessage: 'Delete message report'
  }),
  DELETE_BAN_MODAL_SUBTITLE: defineMessage({
    id: 'modal.delete_ban_subtitle',
    defaultMessage:
      'Are you sure you want to delete this listing & ban this user? This cannot be undone.'
  }),
  DELETE_MESSAGE_REPORT_MODAL_SUBTITLE: defineMessage({
    id: 'modal.delete_message_report_subtitle',
    defaultMessage: 'Are you sure you want to delete this message report? This cannot be undone.'
  }),
  DELETE_BAN_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.delete_ban_confirm_button',
    defaultMessage: 'Delete & ban'
  }),

  MEET_UP_LOCATION_MODAL_TITLE: defineMessage({
    id: 'modal.meet_up_location_title',
    defaultMessage: 'Are you sure you want to skip adding your meet-up location?'
  }),
  MEET_UP_LOCATION_MODAL_SUBTITLE: defineMessage({
    id: 'modal.meet_up_location_subtitle',
    defaultMessage:
      'Adding a meet-up location helps buyers know where to meet you to complete a purchase. You can always add one later in your location settings.'
  }),
  MEET_UP_LOCATION_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.meet_up_location_confirm_button',
    defaultMessage: 'Skip meet-up location'
  }),
  DOGE_EQUAL_DOGE_MODAL_TITLE: defineMessage({
    id: 'modal.doge_equal_doge_title',
    defaultMessage: 'Are you sure you want to make your listing price 1 Doge = 1 Doge?'
  }),
  DOGE_EQUAL_DOGE_MODAL_SUBTITLE: defineMessage({
    id: 'modal.doge_equal_doge_subtitle',
    defaultMessage:
      'Making your item’s price 1 Doge = 1 Doge locks the number of Dogecoin asked, so the value of your item will change if the price of Dogecoin goes up or down. In short, 1 Doge stays 1 Doge.'
  }),
  DOGE_EQUAL_DOGE_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.doge_equal_doge_confirm_button',
    defaultMessage: 'Make price 1 Doge=1 Doge'
  }),
  DOGE_EQUAL_DOGE_MODAL_CANCEL_BUTTON: defineMessage({
    id: 'modal.doge_equal_doge_cancel_button',
    defaultMessage: 'I’ll stick with dollars 😅'
  }),
  CLEAR_ALL_FLAG_DM_MODAL_TITLE: defineMessage({
    id: 'modal.clear_all_flag_dm_title',
    defaultMessage: 'Clear all flagged messages?'
  }),
  CLEAR_ALL_FLAG_DM_MODAL_SUBTITLE: defineMessage({
    id: 'modal.clear_all_flag_dm_subtitle',
    defaultMessage:
      'Clearing all flagged messages removes all reported messages made on this user. This cannot be undone.'
  }),
  CLEAR_ALL_FLAG_DM_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.clear_all_flag_dm_confirm_button',
    defaultMessage: 'Clear flagged messages'
  }),
  VERIFY_LOCATION_MODAL_TITLE: defineMessage({
    id: 'modal.verify_location_title',
    defaultMessage: 'Always verify locations before traveling'
  }),
  VERIFY_LOCATION_MODAL_SUBTITLE: defineMessage({
    id: 'modal.verify_location_subtitle',
    defaultMessage: 'Your safety matters to us! Always verify where your going before traveling.'
  }),
  VERIFY_LOCATION_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.verify_location_confirm_button',
    defaultMessage: 'Get directions'
  }),
  REPORT_LISTING_MODAL_TITLE: defineMessage({
    id: 'modal.report_listing_title',
    defaultMessage: 'Report this listing'
  }),
  REPORT_STOREFRONT_MODAL_TITLE: defineMessage({
    id: 'modal.report_storefront_title',
    defaultMessage: 'Report this storefront'
  }),
  REPORT_MESSAGE_MODAL_TITLE: defineMessage({
    id: 'modal.report_message_title',
    defaultMessage: 'Report this message'
  }),
  REPORT_LISTING_MODAL_LABEL: defineMessage({
    id: 'modal.report_listing_subtitle',
    defaultMessage: 'I think this listing is...'
  }),
  REPORT_STOREFRONT_MODAL_LABEL: defineMessage({
    id: 'modal.report_storefront_subtitle',
    defaultMessage: 'I think this storefront is...'
  }),
  REPORT_MESSAGE_MODAL_LABEL: defineMessage({
    id: 'modal.report_message_subtitle',
    defaultMessage: 'Report this message as...'
  }),
  REPORT_LISTING_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.report_listing_confirm_button',
    defaultMessage: 'Report listing'
  }),
  REPORT_STOREFRONT_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.report_storefront_confirm_button',
    defaultMessage: 'Report storefront'
  }),
  REPORT_MESSAGE_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.report_message_confirm_button',
    defaultMessage: 'Report message'
  }),
  COMPLETE_ACCOUNT_MODAL_TITLE_TO_CREATE_LISTING: defineMessage({
    id: 'modal.complete_account_title_to_create_listing',
    defaultMessage: 'Complete your account to create a listing'
  }),
  COMPLETE_ACCOUNT_MODAL_TITLE_TO_SEND_MESSAGE: defineMessage({
    id: 'modal.complete_account_title_to_send_message',
    defaultMessage: 'Complete your account first to message the seller'
  }),
  COMPLETE_ACCOUNT_MODAL_SUBTITLE: defineMessage({
    id: 'modal.complete_account_subtitle',
    defaultMessage:
      'Before you start selling, we need to know what kind of seller you want to be. Finish creating your account to add your first listing.'
  }),
  COMPLETE_ACCOUNT_MODAL_TO_SEND_MESSAGE_SUBTITLE: defineMessage({
    id: 'modal.complete_account_to_send_message_subtitle',
    defaultMessage:
      'Finish creating your account to message the seller to meet up and make a purchase. Click the heart icon in the upper right corner of the listing to save it for later.'
  }),
  COMPLETE_ACCOUNT_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.complete_account_confirm_button',
    defaultMessage: 'Complete my account'
  }),
  RELIST_ITEM_MODAL_TITLE: defineMessage({
    id: 'modal.relist_item_title',
    defaultMessage: 'Relist your item?'
  }),
  WARN_DELETE_LISTING_MODAL_TITLE: defineMessage({
    id: 'modal.warn_delete_listing_title',
    defaultMessage: 'Delete listing & issue a warning'
  }),

  WARN_DELETE_LISTING_MODAL_SUBTITLE: defineMessage({
    id: 'modal.warn_delete_listing_subtitle',
    defaultMessage:
      'Are you sure you want to delete this listing & issue a warning? This cannot be undone.'
  }),
  WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.warn_delete_listing_confirm_button',
    defaultMessage: 'Delete & Warning'
  }),
  UNBAN_AND_ISSUE_WARN_DELETE_LISTING_MODAL_TITLE: defineMessage({
    id: 'modal.uban_and_issue_warn_delete_listing_title',
    defaultMessage: 'Are you sure you want to unban the user and issue a warning?'
  }),
  UNBAN_MODAL_TITLE: defineMessage({
    id: 'modal.unban_title',
    defaultMessage: 'Are you sure you want to unban the user?'
  }),
  UNBAN_MODAL_SUBTITLE: defineMessage({
    id: 'modal.unban_subtitle',
    defaultMessage: 'Unbanning the user will restore their account!'
  }),
  UNBAN_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.unban_confirm_button',
    defaultMessage: 'unban'
  }),

  UNBAN_AND_ISSUE_WARN_DELETE_LISTING_MODAL_SUBTITLE: defineMessage({
    id: 'modal.uban_and_issue_warn_delete_listing_subtitle',
    defaultMessage:
      'Unbanning the user will restore their account and their listing stays deleted with a warning instead. All other deleted listings will remain deleted until further review.'
  }),
  UNBAN_AND_ISSUE_WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.uban_and_issue_warn_delete_listing_confirm_button',
    defaultMessage: 'Unban & issue warning'
  }),
  UNBAN_AND_RESTORE_LISTING_MODAL_TITLE: defineMessage({
    id: 'modal.unban_and_restore_listing_title',
    defaultMessage: 'You sure you want to unban the user and restore this listing?'
  }),

  UNBAN_AND_RESTORE_LISTING_MODAL_SUBTITLE: defineMessage({
    id: 'modal.unban_and_restore_listing_subtitle',
    defaultMessage:
      'Unbanning the user will restore their account along with this listing. All other deleted listings will remain deleted until further review.'
  }),
  UNBAN_AND_RESTORE_LISTING_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.uban_and_restore_listing_confirm_button',
    defaultMessage: 'Unban & restore listing'
  }),
  RELIST_ITEM_MODAL_SUBTITLE: defineMessage({
    id: 'modal.relist_item_subtitle',
    defaultMessage:
      'Relisting your item restores it to the map, and it will be available to purchase again. Are you sure you want to relist your item? '
  }),
  RELIST_ITEM_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.relist_item_confirm_button',
    defaultMessage: 'Relist my item'
  }),
  RELIST_STOREFRONT_MODAL_TITLE: defineMessage({
    id: 'modal.relist_storefront_title',
    defaultMessage: 'Relist your storefront?'
  }),
  RELIST_STOREFRONT_MODAL_SUBTITLE: defineMessage({
    id: 'modal.relist_storefront_subtitle',
    defaultMessage:
      'Relisting your storefront restores it to the map. Are you sure you want to relist your storefront?'
  }),
  RELIST_STOREFRONT_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.relist_storefront_confirm_button',
    defaultMessage: 'Relist my storefront'
  }),
  UNSAVED_CHANGES_MODAL_TITLE: defineMessage({
    id: 'modal.unsaved_changes_title',
    defaultMessage: 'Unsaved changes'
  }),
  UNSAVED_CHANGES_MODAL_SUBTITLE: defineMessage({
    id: 'modal.unsaved_changes_subtitle',
    defaultMessage: 'Do you want to save or discard changes?'
  }),
  UNSAVED_CHANGES_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.unsaved_changes_confirm_button',
    defaultMessage: 'Save changes'
  }),
  LOGIN_MODAL_TITLE: defineMessage({
    id: 'modal.login_title',
    defaultMessage: 'Log in to continue'
  }),
  LOGIN_MODAL_SUBTITLE: defineMessage({
    id: 'modal.login_subtitle',
    defaultMessage: 'Welcome back! Please enter your details.'
  }),
  LOGIN: defineMessage({
    id: 'login',
    defaultMessage: 'Log in'
  }),
  LOGIN_MODAL_GOOGLE_CONFIRM_BUTTON: defineMessage({
    id: 'modal.login_google_confirm_button',
    defaultMessage: 'Log in with Google'
  }),
  FORGOT_PASSWORD_MODAL_SUBTITLE: defineMessage({
    id: 'modal.forgot_password_subtitle',
    defaultMessage: 'Not to worry, we’ll send you reset instructions.'
  }),
  FORGOT_PASSWORD_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.forgot_password_confirm_button',
    defaultMessage: 'Reset my password'
  }),
  New_PASSWORD_MODAL_TITLE: defineMessage({
    id: 'modal.new_password_title',
    defaultMessage: 'Enter your new password'
  }),
  New_PASSWORD_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.new_password_confirm_button',
    defaultMessage: 'Reset my password'
  }),
  New_PASSWORD: defineMessage({
    id: 'new_password',
    defaultMessage: 'New password'
  }),
  MUST_BE_AT_LEAST_EIGHT_CHARACTERS: defineMessage({
    id: 'must_be_at_least_eighth_characters',
    defaultMessage: 'Must be at least 8 characters.'
  }),
  CONFIRM_PASSWORD: defineMessage({
    id: 'confirm_password',
    defaultMessage: 'Confirm password'
  }),
  CONFIRM_NEW_PASSWORD: defineMessage({
    id: 'confirm_new_password',
    defaultMessage: 'Confirm new password'
  }),
  DONT_HAVE_ACCOUNT: defineMessage({
    id: 'login.dont_have_account',
    defaultMessage: 'Don’t have an account?'
  }),
  SIGN_UP: defineMessage({
    id: 'signup',
    defaultMessage: 'Sign up'
  }),
  EMAIL: defineMessage({
    id: 'email',
    defaultMessage: 'Email'
  }),
  EMAIL_USER: defineMessage({
    id: 'email.email_user',
    defaultMessage: 'Email user'
  }),
  ACCURACY_SCORE: defineMessage({
    id: 'shared.accuracy_score',
    defaultMessage: 'Accuracy score'
  }),
  PASSWORD: defineMessage({
    id: 'password',
    defaultMessage: 'Password'
  }),
  FORGOT_PASSWORD: defineMessage({
    id: 'forgot_password',
    defaultMessage: 'Forgot password?'
  }),

  CANCEL: defineMessage({
    id: 'shared.button.cancel',
    defaultMessage: 'Cancel'
  }),
  SEND_EMAIL: defineMessage({
    id: 'shared.send_email',
    defaultMessage: 'Send email'
  }),
  REQUIRED_FIELDS: defineMessage({
    id: 'required_fields',
    defaultMessage: 'Required fields'
  }),
  CONFIRM_ADMIN_MODAL_TITLE: defineMessage({
    id: 'modal.confirm_admin_title',
    defaultMessage: 'Log in to continue'
  }),
  CONFIRM_ADMIN_MODAL_SUBTITLE: defineMessage({
    id: 'modal.confirm_admin_subtitle',
    defaultMessage: 'Thanks for accepting the invite. You now have admin access.'
  }),
  SHARE_MODAL_TITLE: defineMessage({
    id: 'modal.share_title',
    defaultMessage: 'Share this listing'
  }),
  SHARE_MODAL_SUBTITLE: defineMessage({
    id: 'modal.share_subtitle',
    defaultMessage:
      'Attract more buyers by sharing this link .Paste the link anywhere to get more eyes on your listing.'
  }),
  SHARE: defineMessage({
    id: 'share',
    defaultMessage: 'Share'
  }),
  SHARE_LINK: defineMessage({
    id: 'modal.share_link',
    defaultMessage: 'Share link'
  }),
  CONFIRM: defineMessage({
    id: 'confirm',
    defaultMessage: 'Confirm'
  }),
  AGE_VERIFICATION_MODAL_TITLE: defineMessage({
    id: 'modal.age_verification_title',
    defaultMessage: 'Age verification'
  }),
  AGE_VERIFICATION_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.age_verification_confirm_button',
    defaultMessage: 'Verify my age'
  }),
  AGE_VERIFICATION_SUBTITLE: defineMessage({
    id: 'modal.age_verification_subtitle',
    defaultMessage: 'Must be 18 years or older.'
  }),
  DATE_OF_BIRTH: defineMessage({
    id: 'date_of_birth',
    defaultMessage: 'Date of birth'
  }),
  WELCOME_MODAL_TITLE: defineMessage({
    id: 'modal.welcome_title',
    defaultMessage: 'Welcome to your dashboard! 🎉'
  }),
  WELCOME_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'lets_go',
    defaultMessage: 'Let’s go!'
  }),
  WELCOME_SUBTITLE: defineMessage({
    id: 'modal.welcome_subtitle',
    defaultMessage:
      'Your buying & selling hub awaits! Manage your listings, track your favorites, and seize incredible deals. Your dashboard is where it all happens!'
  }),
  ADMIN: defineMessage({
    id: 'shared.admin',
    defaultMessage: 'Admin'
  }),
  SUPER_ADMIN: defineMessage({
    id: 'shared.super_admin',
    defaultMessage: 'Super Admin'
  }),
  ADMIN_DASHBOARD: defineMessage({
    id: 'shared.admin_dashboard',
    defaultMessage: 'Admin Dashboard'
  }),
  SUPER_ADMIN_DASHBOARD: defineMessage({
    id: 'shared.super_admin_dashboard',
    defaultMessage: 'Super Admin Dashboard'
  }),
  USER_DASHBOARD: defineMessage({
    id: 'shared.user_dashboard',
    defaultMessage: 'User Dashboard'
  }),
  BUSINESS: defineMessage({
    id: 'shared.business',
    defaultMessage: 'Business'
  }),
  PERSONAL: defineMessage({
    id: 'shared.personal',
    defaultMessage: 'Personal'
  }),
  DEACTIVE_ACCOUNT: defineMessage({
    id: 'shared.deactive_account',
    defaultMessage: 'Deactivate account'
  }),
  VIEW_PROFILE: defineMessage({
    id: 'shared.view_profile',
    defaultMessage: 'View Profile'
  }),
  INVITE_MODAL_TITLE: defineMessage({
    id: 'modal.invite_title',
    defaultMessage: 'Invite a friend & earn 150 points 💫'
  }),
  INVITE_SUBTITLE: defineMessage({
    id: 'modal.invite_subtitle',
    defaultMessage:
      'Invite a friend and earn points towards the leaderboard when they sign up using your referral link or code.'
  }),
  SHARE_VIA_FACEBOOK: defineMessage({
    id: 'share_via_facebook',
    defaultMessage: 'Share via Facebook'
  }),
  SHARE_VIA_TWITTER: defineMessage({
    id: 'share_via_twitter',
    defaultMessage: 'Share via Twitter'
  }),
  SHARE_VIA_EMAIL: defineMessage({
    id: 'share_via_email',
    defaultMessage: 'Share via Email'
  }),
  REFERRAL_CODE: defineMessage({
    id: 'referral_code',
    defaultMessage: 'Referral code'
  }),
  REPORT_LISTING_FIRST_REASON: defineMessage({
    id: 'report_listing_first_reason',
    defaultMessage: 'Misrepresentation of business'
  }),
  REPORT_LISTING_SECOND_REASON: defineMessage({
    id: 'report_listing_second_reason',
    defaultMessage: 'Promoting banned item'
  }),
  REPORT_MESSAGE_FIRST_REASON: defineMessage({
    id: 'report_message_first_reason',
    defaultMessage: 'Harassment'
  }),
  REPORT_MESSAGE_SECOND_REASON: defineMessage({
    id: 'report_message_second_reason',
    defaultMessage: 'Report as spam'
  }),
  REPORT_MESSAGE_THIRD_REASON: defineMessage({
    id: 'report_message_third_reason',
    defaultMessage: 'Report as junk'
  }),
  REPORT_LISTING_DESCRIPTION_PLACEHOLDER: defineMessage({
    id: 'modal.report_listing_description_placeholder',
    defaultMessage: 'Is there anything else you want to add?'
  }),
  OTHER: defineMessage({
    id: 'other',
    defaultMessage: 'Other'
  }),
  REPORT_LISTIN_SELECT_A_REASON: defineMessage({
    id: 'report_listing_select_reason',
    defaultMessage: 'Select a reason'
  }),
  DESCRIPTION: defineMessage({
    id: 'description',
    defaultMessage: 'Description'
  }),
  RELIST_STOREFRONT_LISTING_MODAL_TITLE: defineMessage({
    id: 'modal.relist_listing_title',
    defaultMessage: 'Pick a storefront to relist your item'
  }),
  RELIST_STOREFRONT_LISTING_MODAL_SUBTITLE: defineMessage({
    id: 'modal.relist_listing_subtitle',
    defaultMessage: 'Before relisting your item, pick the storefront you want to restore it to. '
  }),
  RELIST_STOREFRONT_LISTING_MODAL_INPUT_LABEL: defineMessage({
    id: 'modal.relist_listing_input_label',
    defaultMessage: 'Relist item to...'
  }),
  RELIST_STOREFRONT_LISTING_MODAL_INPUT_PLACEHOLDER: defineMessage({
    id: 'modal.relist_listing_input_placeholder',
    defaultMessage: 'Pick a storefront'
  }),
  RELIST_STOREFRONT_LISTING_MODAL_CONFIRM_BUTTON: defineMessage({
    id: 'modal.relist_listing_confirm_button',
    defaultMessage: 'Relist to my storefront'
  }),
  SIGNUP_MODAL_SUBTITLE: defineMessage({
    id: 'modal.signup_subtitle',
    defaultMessage: 'Start buying and selling today.'
  }),
  SIGNUP_MODAL_GOOGLE_CONFIRM_BUTTON: defineMessage({
    id: 'modal.signup_google_confirm_button',
    defaultMessage: 'Sign up with Google'
  }),
  ALREADY_HAVE_ACCOUNT: defineMessage({
    id: 'already_have_account',
    defaultMessage: 'Already have an account?'
  }),
  I_AGREE_WITH: defineMessage({
    id: 'i_agree_with',
    defaultMessage: 'I agree with'
  }),
  I_AM_18_YEARS_OLD: defineMessage({
    id: 'i_am_18_years_old',
    defaultMessage: 'I am 18+ years old'
  }),
  EMAIL_PLACEHOLDER: defineMessage({
    id: 'enter_your_email',
    defaultMessage: 'Enter your email'
  }),
  CREATE_PASSWORD_PLACEHOLDER: defineMessage({
    id: 'create_password',
    defaultMessage: 'Create a password'
  }),
  PASTE_CODE: defineMessage({
    id: 'paste_code',
    defaultMessage: 'Paste code'
  }),
  DOGELIST_TERM: defineMessage({
    id: 'dogelist_term',
    defaultMessage: 'Dogelist Terms'
  }),
  PRIVACY_POLICY: defineMessage({
    id: 'shared.privacy_policy',
    defaultMessage: 'Privacy policy'
  }),
  GOOGLE_TERM: defineMessage({
    id: 'google_term',
    defaultMessage: 'Google Terms'
  }),
  VOLENTEER_MODAL_MOD_ONE_TITLE: defineMessage({
    id: 'modal.volenteer_mod_one_title',
    defaultMessage: 'Apply to join the Mod Squad!'
  }),
  VOLENTEER_MODAL_MOD_TWO_TITLE: defineMessage({
    id: 'modal.volenteer_mod_two_title',
    defaultMessage: 'Remove reported listings 🛡️'
  }),
  VOLENTEER_MODAL_MOD_THREE_TITLE: defineMessage({
    id: 'modal.volenteer_mod_three_title',
    defaultMessage: 'Move up the leaderboard 📈'
  }),
  VOLENTEER_MODAL_MOD_ONE_SUBTITLE: defineMessage({
    id: 'modal.volenteer_mod_one_subtitle',
    defaultMessage:
      "As moderator, you'll have the power to remove listings to safeguard the trading experience for everyone. It's time to unleash your inner watchdog and make a difference!"
  }),
  VOLENTEER_MODAL_MOD_TWO_SUBTITLE: defineMessage({
    id: 'modal.volenteer_mod_two_subtitle',
    defaultMessage:
      'As a moderator, you can remove reported listings from the marketplace by placing them in Quarantine.'
  }),
  VOLENTEER_MODAL_MOD_THREE_SUBTITLE: defineMessage({
    id: 'modal.volenteer_mod_three_subtitle',
    defaultMessage: 'Become a moderator and earn points as soon as your application is accepted!'
  }),
  APPLY_NOW: defineMessage({
    id: 'apply_now',
    defaultMessage: 'Apply now'
  }),
  DISMISS: defineMessage({
    id: 'shared.dismiss',
    defaultMessage: 'Dismiss'
  }),

  PROFILE: defineMessage({
    id: 'navigation.profile',
    defaultMessage: 'Profile'
  }),
  LEADERBOARD: defineMessage({
    id: 'navigation.leaderboard',
    defaultMessage: 'Leaderboard'
  }),
  CREATE_LISTING: defineMessage({
    id: 'navigation.create_listing',
    defaultMessage: 'Create Listing'
  }),
  INVITE_FREIND: defineMessage({
    id: 'navigation.invite_freind',
    defaultMessage: 'Invite a Freind'
  }),
  SETTINGS: defineMessage({
    id: 'navigation.settings',
    defaultMessage: 'Settings'
  }),
  MESSAGES: defineMessage({
    id: 'navigation.messages',
    defaultMessage: 'Messages'
  }),
  MESSAGE: defineMessage({
    id: 'shared.message',
    defaultMessage: 'Message'
  }),
  LOGOUT: defineMessage({
    id: 'navigation.logout',
    defaultMessage: 'Logout'
  }),
  REPORTS: defineMessage({
    id: 'navigation.reports',
    defaultMessage: 'Reports'
  }),
  REPORT: defineMessage({
    id: 'shared.report',
    defaultMessage: 'Report'
  }),
  PROFILE_SETTINGS: defineMessage({
    id: 'navigation.profile_settings',
    defaultMessage: 'Profile settings'
  }),
  CHANGE_PASSWORD: defineMessage({
    id: 'navigation.change_password',
    defaultMessage: 'Change password'
  }),
  BLOCKED_USERS: defineMessage({
    id: 'navigation.blocked_users',
    defaultMessage: 'Blocked users'
  }),
  BANNED_USERS: defineMessage({
    id: 'navigation.banned_uses',
    defaultMessage: 'Banned users'
  }),
  EXPLORE_MARKETPLACE: defineMessage({
    id: 'navigation.explore_marketplace',
    defaultMessage: 'Explore marketplace'
  }),
  HOME: defineMessage({
    id: 'shared.home',
    defaultMessage: 'Home'
  }),
  DASHBOARD: defineMessage({
    id: 'shared.dashboard',
    defaultMessage: 'Dashboard'
  }),
  ABOUT: defineMessage({
    id: 'shared.about',
    defaultMessage: 'About'
  }),
  SAVE: defineMessage({
    id: 'shared.save',
    defaultMessage: 'Save'
  }),
  UNSAVE: defineMessage({
    id: 'shared.unsave',
    defaultMessage: 'Unsave'
  }),
  PRICE: defineMessage({
    id: 'shared.price',
    defaultMessage: 'Price'
  }),
  BACK: defineMessage({
    id: 'shared.back',
    defaultMessage: 'Back'
  }),
  MEETUP_LOCATION: defineMessage({
    id: 'meetup_location',
    defaultMessage: 'Meet up location'
  }),
  PRICE_TRANSACTION_TEXT: defineMessage({
    id: 'listing.price_transaction_text',
    defaultMessage: 'Send a message to the buyer to schedule a meet up for your transaction.'
  }),
  STANDARD_CURRENCY_DESCRIPTION: defineMessage({
    id: 'listing.standard_currency_description',
    defaultMessage:
      'Pricing for this listing is at a fixed dollar amount, and will not be influenced by the crypto market.'
  }),
  DOGE_CURRENCY_DESCRIPTION: defineMessage({
    id: 'listing.doge_currency_description',
    defaultMessage:
      'The price of this listing will be fixed in Dogecoin and will not adjust to match a fiat currency amount.'
  }),
  MODERATOR: defineMessage({
    id: 'shared.moderator',
    defaultMessage: 'Moderator'
  }),
  MODERATORS: defineMessage({
    id: 'shared.moderators',
    defaultMessage: 'Moderators'
  }),
  ADMIN_PROSPECTS: defineMessage({
    id: 'modal.add_admin.admin_prospects',
    defaultMessage: 'Admin prospects'
  }),
  MODERATORS_PROSPECTS: defineMessage({
    id: 'modal.invite_user.moderator_prospects',
    defaultMessage: 'Moderator prospects'
  }),
  RATING: defineMessage({
    id: 'shared.rating',
    defaultMessage: 'Rating'
  }),
  BLOCK_USER: defineMessage({
    id: 'shared.block_user',
    defaultMessage: 'Block user'
  }),
  DIRECT_MESSAGE: defineMessage({
    id: 'shared.direct_message',
    defaultMessage: 'Direct message'
  }),
  INFO: defineMessage({
    id: 'shared.info',
    defaultMessage: 'Info'
  }),
  LANGUAGE_PROFICIENCY: defineMessage({
    id: 'shared.language_proficiency',
    defaultMessage: 'Language proficiency'
  }),
  COUNTRY_REGION: defineMessage({
    id: 'shared.country_region',
    defaultMessage: 'Country & region'
  }),
  MOD_SCORE: defineMessage({
    id: 'shared.mod_score',
    defaultMessage: 'Mod score'
  }),
  MEMBER_SINCE: defineMessage({
    id: 'shared.member_since',
    defaultMessage: 'Member since'
  }),
  YOU: defineMessage({
    id: 'shared.you',
    defaultMessage: 'You'
  }),
  BLOCKED: defineMessage({
    id: 'shared.blocked',
    defaultMessage: 'Blocked'
  }),
  ERROR_404: defineMessage({
    id: 'pages.not_found.404error',
    defaultMessage: '404 error'
  }),
  PAGE_NOT_FOUND: defineMessage({
    id: 'pages.not_found.page_not_found',
    defaultMessage: 'Page not found...'
  }),
  PAGE_NOT_FOUND_DESCRIPTION: defineMessage({
    id: 'pages.not_found.page_not_found_description',
    defaultMessage:
      "This is awkward, the page you’re looking for doesn't exist. Try again another time."
  }),
  CONTACT_SELLER: defineMessage({
    id: 'listing.contact_seller',
    defaultMessage: 'Contact seller'
  }),
  CONTACT_BUYER: defineMessage({
    id: 'listing.contact_buyer',
    defaultMessage: 'Contact buyer'
  }),
  LISTING_ADDRESS_DESCRIPTION: defineMessage({
    id: 'listing.listing_address_description',
    defaultMessage:
      'This is where you meet with the seller to purchase this item. If this location doesn’t suit you, request another meet-up location with the seller.'
  }),
  DIRECTIONS: defineMessage({
    id: 'storefront.directions',
    defaultMessage: 'Directions'
  }),
  GET_DIRECTIONS: defineMessage({
    id: 'storefront.get_directions',
    defaultMessage: 'Get directions'
  }),
  STOREFRONT: defineMessage({
    id: 'shared.storefront',
    defaultMessage: 'Storefront'
  }),
  STOREFRONTS: defineMessage({
    id: 'shared.storefronts',
    defaultMessage: 'Storefronts'
  }),
  STOREFRONT_FINDOUT_TEXT: defineMessage({
    id: 'storefront.findout_text',
    defaultMessage:
      'Find out what this store has to offer. Make sure to verify the location before going.'
  }),
  OPEN: defineMessage({
    id: 'shared.open',
    defaultMessage: 'Open'
  }),
  CLOSE: defineMessage({
    id: 'shared.close',
    defaultMessage: 'Close'
  }),
  CLOSES: defineMessage({
    id: 'shared.closes',
    defaultMessage: 'Closes'
  }),
  STORE_HOURS: defineMessage({
    id: 'storefront.store_hours',
    defaultMessage: 'Store hours'
  }),
  STORE_HOURS_DESCRIPTION: defineMessage({
    id: 'storefront.store_hours_description',
    defaultMessage:
      'Find out what time the store is open by viewing the store’s hours. Not all stores will list their hours of operation, but it’s a plus if they do.'
  }),
  ITEMS_FOR_SALE: defineMessage({
    id: 'storefront.items_for_sale',
    defaultMessage: 'Items for sale'
  }),
  VIEW_WHAT_STORE_HAS_FOR_SALE: defineMessage({
    id: 'storefront.view_what_store_has_for_sale',
    defaultMessage: 'View what this store has for sale.'
  }),
  VIEW_MORE: defineMessage({
    id: 'shared.view_more',
    defaultMessage: 'View more'
  }),
  CLEAR: defineMessage({
    id: 'shared.clear',
    defaultMessage: 'Clear'
  }),
  SEARCH: defineMessage({
    id: 'shared.search',
    defaultMessage: 'Search'
  }),
  APPLY: defineMessage({
    id: 'shared.apply',
    defaultMessage: 'Apply'
  }),
  NO_LISTING_FOUND: defineMessage({
    id: 'map.no_listing_found',
    defaultMessage: 'No listings found'
  }),
  NO_LISTING_FOUND_DETAILS_FIRST_PART: defineMessage({
    id: 'map.not_found_details_first_part',
    defaultMessage: 'Your search'
  }),
  NO_LISTING_FOUND_DETAILS_SECOND_PART: defineMessage({
    id: 'map.not_found_details_second_part',
    defaultMessage: 'did not match any listings. Please try again.'
  }),
  CLeAR_SEARCH: defineMessage({
    id: 'map.clear_search',
    defaultMessage: 'Clear search'
  }),
  NEW_SEARCH: defineMessage({
    id: 'map.new_search',
    defaultMessage: 'New search'
  }),
  MARKET_PLACE_SUBTITLE: defineMessage({
    id: 'map.market_place_subtitle',
    defaultMessage: 'Discover items available in your area.'
  }),
  MARKET_PLACE_TITLE: defineMessage({
    id: 'map.market_place_title',
    defaultMessage: 'search results in'
  }),
  SELECT_CATEGORY: defineMessage({
    id: 'shared.select_category',
    defaultMessage: 'Select a category'
  }),
  FILTERS: defineMessage({
    id: 'shared.filters',
    defaultMessage: 'Filters'
  }),
  APPLY_FILTERS_TO_SEARCH: defineMessage({
    id: 'more_filter.apply_filter_to_search',
    defaultMessage: 'Apply filters to your search.'
  }),
  ALL: defineMessage({
    id: 'shared.all',
    defaultMessage: 'All'
  }),
  ALL_FILTERS: defineMessage({
    id: 'shared.all_filters',
    defaultMessage: 'All filters'
  }),
  MORE_FILTERS: defineMessage({
    id: 'shared.more_filters',
    defaultMessage: 'More filters'
  }),
  LISTINGS: defineMessage({
    id: 'shared.listings',
    defaultMessage: 'Listings'
  }),
  CONDITION: defineMessage({
    id: 'shared.condition',
    defaultMessage: 'Condition'
  }),
  ALTERNATE_PRICE_OPTION: defineMessage({
    id: 'shared.alternate_price_options',
    defaultMessage: 'Alternate price options'
  }),
  PRICE_TYPE: defineMessage({
    id: 'shared.price_type',
    defaultMessage: 'Price type'
  }),
  OFFER_TYPE: defineMessage({
    id: 'shared.offer_type',
    defaultMessage: 'Offer type'
  }),
  CATEGORY: defineMessage({
    id: 'shared.category',
    defaultMessage: 'Category'
  }),
  NOTHING_FOUND: defineMessage({
    id: 'shared.nothing_found',
    defaultMessage: 'Nothing found.'
  }),
  CURRENT_LOCATION: defineMessage({
    id: 'shared.current_location',
    defaultMessage: 'Current Location'
  }),
  LOADING: defineMessage({
    id: 'shared.loading',
    defaultMessage: 'Loading...'
  }),
  SHOW_10_MORE: defineMessage({
    id: 'shared.show_10_more',
    defaultMessage: 'Show 10 more'
  }),
  NETWORK_ERROR: defineMessage({
    id: 'shared.network_error',
    defaultMessage: 'Network error occurred. Please check your internet connection'
  }),
  SUCCESSFULY_ACCOUNT_CREATED: defineMessage({
    id: 'shared.successfuly_account_created',
    defaultMessage: 'Your account successfuly created! '
  }),
  SELECT_DATE: defineMessage({
    id: 'signup.select_date',
    defaultMessage: 'Select date '
  }),
  ENTER_NEW_PASSWORD: defineMessage({
    id: 'signup.enter_new_password',
    defaultMessage: 'Please enter a new password. '
  }),
  DONOT_MATCH_PASSWORD: defineMessage({
    id: 'signup.donot_match_passwrod',
    defaultMessage: 'Password does not match. Please make sure both passwords match.'
  }),
  PASSWORD_VALIDATION_MESSAGE: defineMessage({
    id: 'signup.password_validation',
    defaultMessage:
      'Must include at least one: uppercase letter, lowercase letter, number and symbol. '
  }),
  RESET_PASSWORD_EMAIL: defineMessage({
    id: 'signup.reset_password_email',
    defaultMessage: 'Reset password email sent please check your email'
  }),
  ENTER_A_PASSWORD: defineMessage({
    id: 'signup.enter_a_password',
    defaultMessage: 'Please enter a password.'
  }),
  SELLING_AS_PERSONAL: defineMessage({
    id: 'page.home.selling_as_personal',
    defaultMessage: 'Selling as a personal seller'
  }),
  SELLING_AS_PERSONAL_TITLE: defineMessage({
    id: 'page.home.selling_as_personal_title',
    defaultMessage: 'Sell anything you want while using Dogecoin as the currency.'
  }),
  SELLING_AS_PERSONAL_DESCRIPTION: defineMessage({
    id: 'page.home.selling_as_personal_description',
    defaultMessage:
      'Create a meet-up location anywhere you feel comfortable for safe exchanges. Your address and phone number are always kept private.'
  }),
  LEARN_MORE: defineMessage({
    id: 'page.home.learn_more',
    defaultMessage: 'Learn more'
  }),
  SELLING_AS_BUSINESS: defineMessage({
    id: 'page.home.selling_as_business',
    defaultMessage: 'Selling as a business'
  }),
  SELLING_AS_BUSINESS_TITLE: defineMessage({
    id: 'page.home.selling_as_business_title',
    defaultMessage: 'Does your business accept Dogecoin?'
  }),
  SELLING_AS_BUSINESS_DESCRIPTION: defineMessage({
    id: 'page.home.selling_as_business_description',
    defaultMessage:
      'Add a storefront to the map and attract new customers to your business’s location. Business owners also have the option to post individual items they have for sale.'
  }),
  JSON_EMAIL_LIST: defineMessage({
    id: 'page.home.join_email_list',
    defaultMessage: 'Join our email list'
  }),
  JSON_EMAIL_LIST_TITLE: defineMessage({
    id: 'page.home.join_email_list_title',
    defaultMessage: 'Join our email list to stay up to date with new updates and features.'
  }),
  CREATE_PERSONAL_PROFILE: defineMessage({
    id: 'page.create_account.create_personal_profile',
    defaultMessage: 'Create personal profile'
  }),
  CREATE_A_STOREFRONT: defineMessage({
    id: 'page.create_account.create_storefront',
    defaultMessage: 'Create a storefront'
  }),
  CREATE_STOREFRONT_PROFILE: defineMessage({
    id: 'page.create_account.create_storefront_profile',
    defaultMessage: 'Create user profile'
  }),

  NAME: defineMessage({
    id: 'page.create_account.name',
    defaultMessage: 'Name'
  }),
  NAME_OF_STOREFRONT: defineMessage({
    id: 'page.create_account.name_of_storefront',
    defaultMessage: 'Name of storefront'
  }),
  YOUR_NAME: defineMessage({
    id: 'page.create_account.your_name',
    defaultMessage: 'Your name'
  }),
  STORE_LLC: defineMessage({
    id: 'page.create_account.store_llc',
    defaultMessage: 'Store LLC'
  }),
  USERNAME: defineMessage({
    id: 'page.create_account.username',
    defaultMessage: 'Username'
  }),
  USERNAME_VALIDATION_MESSAGE: defineMessage({
    id: 'page.create_account.username_validation',
    defaultMessage: 'Symbols, profanity, racial or sexual references are not allowed.'
  }),
  CREATE_MY_PERSONAL_PROFILE: defineMessage({
    id: 'page.create_account.create_my_personal_profile',
    defaultMessage: 'Create my personal profile'
  }),
  CREATE_MY_STOREFRONT: defineMessage({
    id: 'page.create_account.create_my_storefront',
    defaultMessage: 'Create my storefront'
  }),
  TWEAK_YOUR_LOCATION: defineMessage({
    id: 'page.create_account.tweak_your_location',
    defaultMessage: 'Tweak your location'
  }),
  LATITUDE: defineMessage({
    id: 'page.create_account.latitude',
    defaultMessage: 'Latitude'
  }),
  LONGITUDE: defineMessage({
    id: 'page.create_account.logitude',
    defaultMessage: 'Longitude'
  }),
  CITY: defineMessage({
    id: 'page.create_account.city',
    defaultMessage: 'City'
  }),
  STATE_OR_PROVINE: defineMessage({
    id: 'page.create_account.state_or_province',
    defaultMessage: 'State/Province'
  }),
  STREET: defineMessage({
    id: 'page.create_account.street',
    defaultMessage: 'Street'
  }),
  ADDRESS: defineMessage({
    id: 'page.create_account.address',
    defaultMessage: 'Address'
  }),
  ADD_YOUR_LOCATION: defineMessage({
    id: 'page.create_account.add_your_location',
    defaultMessage: 'Add your location'
  }),
  WHERE_DO_YOU_WANT_MEET: defineMessage({
    id: 'page.create_account.where_do_you_want_meet',
    defaultMessage: 'Where do you want to meet?'
  }),
  WHERE_ARE_YOU_LOCATED: defineMessage({
    id: 'page.create_account.where_are_you_located',
    defaultMessage: 'Where are you located?'
  }),
  ADDRESS_AND_PHONE_KEEP_PRIVATE: defineMessage({
    id: 'page.create_account.address_and_phone_keep_private',
    defaultMessage: 'As a private seller your address and phone number are always kept private.'
  }),
  ENTER_A_STREET: defineMessage({
    id: 'page.create_account.enter_a_street',
    defaultMessage: 'Enter a street'
  }),
  ENTER_AN_ADDRESS: defineMessage({
    id: 'page.create_account.enter_an_address',
    defaultMessage: 'Enter an address'
  }),
  COUNTRY: defineMessage({
    id: 'page.create_account.country',
    defaultMessage: 'Country'
  }),
  SKIP_FOR_NOW: defineMessage({
    id: 'page.create_account.skip_for_now',
    defaultMessage: 'Skip for now'
  }),
  ADD_MEET_UP_LOCATION: defineMessage({
    id: 'page.create_account.add_meet_up_location',
    defaultMessage: 'Add meet up location'
  }),
  ADD_STORE_LOCATION: defineMessage({
    id: 'page.create_account.add_store_location',
    defaultMessage: 'Add storefront location'
  }),
  CONGRATULATIONS: defineMessage({
    id: 'page.create_account.congratulations',
    defaultMessage: 'Congratulations!'
  }),
  PERSONAL_ACCOUNT_SUCCESSFULY_CREATED: defineMessage({
    id: 'page.create_account.personal_account_successfuly_created',
    defaultMessage: 'You’re personal account was successfully created.'
  }),
  BUSINESS_ACCOUNT_SUCCESSFULY_CREATED: defineMessage({
    id: 'page.create_account.business_account_successfuly_created',
    defaultMessage: 'You’re business account was successfully created.'
  }),
  CONGRATULATION_DESCRIPTION_MESSAGE: defineMessage({
    id: 'page.create_account.congratulation_description_message',
    defaultMessage:
      'Explore the marketplace to discover what’s available in your area. Click ‘View dashboard’ to manage your listings.'
  }),
  VIEW_DASHBOARD: defineMessage({
    id: 'page.create_account.view_dashboard',
    defaultMessage: 'View dashboard'
  }),
  CONTINUE: defineMessage({
    id: 'page.create_account.continue',
    defaultMessage: 'Continue'
  }),
  CHOOSE_YOUR_ACCOUNT_TYPE: defineMessage({
    id: 'page.create_account.choose_your_account_type',
    defaultMessage: 'Choose your account type'
  }),
  PERSONAL_ACCOUNT: defineMessage({
    id: 'page.create_account.personal_account',
    defaultMessage: 'Personal account'
  }),
  PERSONAL_ACCOUNT_DESCRIPTION: defineMessage({
    id: 'page.create_account.personal_account_description',
    defaultMessage: 'I want to add personal listings to the map.'
  }),
  PERSONAL_ACCOUNT_MESSAGE: defineMessage({
    id: 'page.create_account.personal_account_message',
    defaultMessage: 'As a personal seller your address is kept private.'
  }),
  BUSINESS_ACCOUNT_DESCRIPTION: defineMessage({
    id: 'page.create_account.business_account_description',
    defaultMessage: 'I want to add my business to the map.'
  }),
  BUSINESS_ACCOUNT_MESSAGE: defineMessage({
    id: 'page.create_account.business_account_message',
    defaultMessage: 'Direct messaging is currently disabled for business accounts'
  }),
  SUCCESS_ALERT_TITLE: defineMessage({
    id: 'shared.success_alert_title',
    defaultMessage: 'Success! You’re in, {name}! ✌️'
  }),
  SUCCESS_ALERT_DESCRIPTION: defineMessage({
    id: 'shared.success_alert_description',
    defaultMessage:
      'Welcome back {name}! Explore the map or manage your listings in your dashboard.'
  }),
  WELCOME_ALERT_TITLE: defineMessage({
    id: 'shared.welcome_alert_title',
    defaultMessage: 'Welcome to TheDogelist.com 🎉'
  }),
  WELCOME_ALERT_DESCRIPTION: defineMessage({
    id: 'shared.welcome_alert_description',
    defaultMessage:
      'Hi {name}, welcome to TheDogelist.com! To unlock the full power of our marketplace, take a sec to complete your profile to make a purchase or list your first item to sell.'
  }),
  STOREFRONT_ITEMS: defineMessage({
    id: 'shared.storefront-items',
    defaultMessage: 'Storefront Items'
  }),
  STOREFRONT_ITEM: defineMessage({
    id: 'shared.storefront-item',
    defaultMessage: 'Storefront Item'
  }),
  WANTED: defineMessage({
    id: 'shared.wanted',
    defaultMessage: 'Wanted'
  }),
  JOINED: defineMessage({
    id: 'shared.joined',
    defaultMessage: 'Joined'
  }),
  ACTIVE_LISTINGS: defineMessage({
    id: 'page.dashboard.active_listings',
    defaultMessage: 'Active listings'
  }),
  ACTIVE_LISTING_MESSAGE: defineMessage({
    id: 'page.dashboard.active_listings_message',
    defaultMessage: 'Your active listings are displayed here.'
  }),
  NO_LISTING_YET: defineMessage({
    id: 'page.dashboard.no_listing_yet',
    defaultMessage: 'No listings yet'
  }),
  NO_LISTING_YET_CONTENT: defineMessage({
    id: 'page.dashboard.not_listing_yet_content',
    defaultMessage:
      'You don’t have any listings yet. Click the ‘Create listing’ button to add a listing.'
  }),
  LISTING_TYPE: defineMessage({
    id: 'page.dashboard.create_listing.listing_type',
    defaultMessage: 'Listing type'
  }),
  CHOOSE_LISTING_TYPE: defineMessage({
    id: 'page.dashboard.create_listing.choose_listing_type',
    defaultMessage: 'Choose the listing type'
  }),
  ENTER_NAME_OF_YOUR_ITEM: defineMessage({
    id: 'page.dashboard.create_listing.enter_name_of_your_item',
    defaultMessage: 'Enter the name of your item'
  }),
  ENTER_YOUR_ITEMS_NAME: defineMessage({
    id: 'page.dashboard.create_listing.enter_your_item_name',
    defaultMessage: 'Enter your item’s name'
  }),
  SELECT_YOUR_ITEM_CATEGORY: defineMessage({
    id: 'page.dashboard.create_listing.select_your_items_category',
    defaultMessage: 'Select your item’s category'
  }),
  SELECT_A_CATEGORY: defineMessage({
    id: 'page.dashboard.create_listing.select_category',
    defaultMessage: 'Select a category'
  }),
  ITEM_CONDITION: defineMessage({
    id: 'page.dashboard.create_listing.item_condition',
    defaultMessage: 'Item Condition'
  }),
  GOOD_CONDITION: defineMessage({
    id: 'page.dashboard.create_listing.good_condition',
    defaultMessage: 'Good Condition'
  }),
  SELLING_PRICE: defineMessage({
    id: 'page.dashboard.create_listing.selling_price',
    defaultMessage: 'Selling Price'
  }),
  WANTED_PRICE: defineMessage({
    id: 'page.dashboard.create_listing.wanted_price',
    defaultMessage: 'Wanted Price'
  }),
  WHAT_IS_YOUR_ITEMS_PRICE: defineMessage({
    id: 'page.dashboard.create_listing.what_is_your_items_price',
    defaultMessage: 'What is your item’s price?'
  }),
  HOW_MUCH_DO_YOU_WANT_PAY: defineMessage({
    id: 'page.dashboard.create_listing.how_much_do_you_want_pay',
    defaultMessage: 'How much do you want to pay'
  }),
  ENTER_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_listing.enter_a_description',
    defaultMessage: 'Enter a description...'
  }),
  ADD_MEETUP_LOCATION: defineMessage({
    id: 'page.dashboard.create_listing.add_meetup_location',
    defaultMessage: 'Add a meet up location you feel safest'
  }),
  LOCATION_PLACEHOLDER: defineMessage({
    id: 'page.dashboard.create_listing.location_placeholder',
    defaultMessage: 'City, Neighborhood, ZIP, Address'
  }),
  ADD_PHOTOS_FOR_YOUR_ITEM: defineMessage({
    id: 'page.dashboard.create_listing.add_photos_for_your_item',
    defaultMessage: 'Add photos for your item'
  }),
  ADD_UPTO_FOUR_PHOTOS: defineMessage({
    id: 'page.dashboard.create_listing.add_upto_four_photos',
    defaultMessage: 'Add up to four images so buyers know what they’re buying.'
  }),
  PERSONAL_LISTING: defineMessage({
    id: 'page.dashboard.create_listing.personal_listing',
    defaultMessage: 'Personal Listing'
  }),
  PERSONAL_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_listing.personal_listing_description',
    defaultMessage: 'You want to list a personal item you wish to sell.'
  }),
  WANTED_LISTING: defineMessage({
    id: 'page.dashboard.create_listing.wanted_listing',
    defaultMessage: 'Wanted Listing'
  }),
  WANTED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_listing.wanted_listing_description',
    defaultMessage: 'You want to list an item you wish to purchase.'
  }),
  CREATE_STOREFRONT: defineMessage({
    id: 'page.dashboard.create_storefront.create_storefront',
    defaultMessage: 'Create Storefront'
  }),
  ADD_STOREFRONT_HERE: defineMessage({
    id: 'page.dashboard.create_storefront.add_storefront',
    defaultMessage: 'Add a storefront here.'
  }),
  ADD_PHOTOS_FOR_STOREFRONT: defineMessage({
    id: 'page.dashboard.create_storefront.add_storefront_photos',
    defaultMessage: 'Add photos for your storefront '
  }),
  ADD_PHOTOS_FOR_STOREFRONT_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_storefront.add_storefront_photos_description',
    defaultMessage: 'Add up to four images to attract customers to your storefront.'
  }),
  IMAGES_ARE_REQUIRED: defineMessage({
    id: 'page.dashboard.create_storefront.images_are_required',
    defaultMessage: 'Images are required'
  }),
  ENTER_NAME_OF_YOUR_STORE: defineMessage({
    id: 'page.dashboard.create_storefront.enter_name_of_your_store',
    defaultMessage: 'Enter the name of your store'
  }),
  ENTER_YOUR_STORE_NAME: defineMessage({
    id: 'page.dashboard.create_storefront.enter_your_store_name',
    defaultMessage: 'Enter your store’s name'
  }),
  SELECT_YOUR_STORE_CATEGORY: defineMessage({
    id: 'page.dashboard.create_storefront.select_your_store_category',
    defaultMessage: 'Select your store’s category'
  }),
  STOREFRONT_LOCATION: defineMessage({
    id: 'page.dashboard.create_storefront.storefront_location',
    defaultMessage: 'Storefront location'
  }),
  WHERE_STORE_LOCATED: defineMessage({
    id: 'page.dashboard.create_storefront.where_store_located',
    defaultMessage: 'Where is the store located'
  }),
  HOURS_OF_OPERATION: defineMessage({
    id: 'page.storefront.hours-of-operation',
    defaultMessage: 'Hours of operation'
  }),
  CONFIGURE_STOREFRONT_HOURS: defineMessage({
    id: 'page.storefront.configure-storefront',
    defaultMessage: 'Configure your storefront’s hours'
  }),
  EDIT_STOREFRONT: defineMessage({
    id: 'page.dashboard.edit_storefront',
    defaultMessage: 'Edit Storefront'
  }),
  SAVE_CHANGES: defineMessage({
    id: 'page.dashboard.save_changes',
    defaultMessage: 'Save changes'
  }),
  YOUR_STOREFRONT: defineMessage({
    id: 'page.dashboard.your_storefront',
    defaultMessage: 'Your storefront'
  }),
  YOUR_STOREFRONT_DESCRIPTION: defineMessage({
    id: 'page.dashboard.your_storefront_description',
    defaultMessage: 'Your storefronts and storefront listings are displayed here.'
  }),
  SELECT_HOURS: defineMessage({
    id: 'share.select-hours',
    defaultMessage: 'Select hours'
  }),
  HOURS_24: defineMessage({
    id: 'share.24-hours',
    defaultMessage: '24-hours'
  }),
  CLOSED: defineMessage({
    id: 'shared.closed',
    defaultMessage: 'Closed'
  }),
  SKIP: defineMessage({
    id: 'share.skip',
    defaultMessage: 'Skip'
  }),
  ADD_LISTING_TO_STOREFRONT: defineMessage({
    id: 'page.dashboard.add_listing_to_storefront',
    defaultMessage: 'Add a listing to your storefront.'
  }),
  CHOOSE_A_STOREFRONT: defineMessage({
    id: 'page.dashboard.choose_a_storefront',
    defaultMessage: 'Choose a storefront'
  }),
  ADD_ITEM_TO_STOREFRONT: defineMessage({
    id: 'page.dashboard.add_item_to_storefront',
    defaultMessage: 'Add this item to your storefront.'
  }),
  LISTING_CREATED_SUCCESSFULY: defineMessage({
    id: 'page.dashboard.listing_create_successfuly',
    defaultMessage: 'Listing created successfuly!'
  }),
  NO_WANTED_LISTING: defineMessage({
    id: 'page.dashboard.no_wanted_listing',
    defaultMessage: 'No wanted listings yet'
  }),
  NO_WANTED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.no_wanted_listing_description',
    defaultMessage: 'You don’t have any wanted listings yet. Click ‘Create listing’ to create one.'
  }),
  WANTED_LISTINGS: defineMessage({
    id: 'page.dashboard.wanted_listing',
    defaultMessage: 'Wanted listings'
  }),
  WANTED_LISTINGS_DESCRIPTION: defineMessage({
    id: 'page.dashboard.wanted_listing_description',
    defaultMessage: 'Your wanted listings are displayed here.'
  }),
  SAVED_LISTINGS: defineMessage({
    id: 'page.dashboard.saved_listings',
    defaultMessage: 'Saved listings'
  }),
  SAVED_LISTINGS_DESCRIPTION: defineMessage({
    id: 'page.dashboard.saved_listings_description',
    defaultMessage: 'Your saved listings are displayed here.'
  }),
  NOTHING_SAVED_LISTING: defineMessage({
    id: 'page.dashboard.nothing_saved_listing',
    defaultMessage: 'Nothing saved yet'
  }),
  NOTHING_SAVED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.nothing_saved_listing_description',
    defaultMessage: 'You don’t have any saved listings. Explore the marketplace to save a listing.'
  }),
  ARCHIVED_LISTINGS: defineMessage({
    id: 'page.dashboard.archived_listings',
    defaultMessage: 'Archived listings'
  }),
  ARCHIVED_LISTINGS_DESCRIPTION: defineMessage({
    id: 'page.dashboard.archived_listings_description',
    defaultMessage: 'Your archived listings are displayed here.'
  }),
  NOTHING_ARCHIVED_LISTING: defineMessage({
    id: 'page.dashboard.nothing_archived_listing',
    defaultMessage: 'Nothing archived yet'
  }),
  NOTHING_ARCHIVED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.nothing_archived_listing_description',
    defaultMessage: 'You don’t have any archived listings yet.'
  }),
  SEARCH_STOREFRONT_OR_LISTING: defineMessage({
    id: 'page.dashboard.search_storefront_or_listing',
    defaultMessage: 'Search storefront/listing'
  }),
  CLEAR_ALL: defineMessage({
    id: 'page.dashboard.clear_all',
    defaultMessage: 'Clear all'
  }),
  UPDATED_SUCCESSFULY: defineMessage({
    id: 'page.dashboard.updated_successfuly',
    defaultMessage: 'updated successfuly!'
  }),
  EDIT_LISTING: defineMessage({
    id: 'page.dashboard.edit_listing',
    defaultMessage: 'Edit Listing'
  }),
  UPDATE_YOUR_LISTING_HERE: defineMessage({
    id: 'page.dashboard.update_your_listings_here',
    defaultMessage: 'Update your listings here.'
  }),
  PRIME_IS_FIRM: defineMessage({
    id: 'page.dashboard.price_is_firm',
    defaultMessage: 'Price is firm'
  }),
  OPEN_FOR_OFFERS: defineMessage({
    id: 'page.dashboard.open_for_offers',
    defaultMessage: 'Open for offers'
  }),
  NEGOTIABLE_NO_PRICE: defineMessage({
    id: 'page.dashboard.negotiable_no_price',
    defaultMessage: 'Negotiable'
  }),
  DOGE_DOGE: defineMessage({
    id: 'page.dashboard.doge_doge',
    defaultMessage: '1 Doge = 1 Doge'
  }),
  DOGE_DOGE_DESCRIPTION: defineMessage({
    id: 'page.dashboard.doge_doge_description',
    defaultMessage:
      'By checking 1D=1D, the price of your item is set at a fixed amount in Dogecoin and not in fiat currency.'
  }),
  MESSAGE_SELLER: defineMessage({
    id: 'shared.message-seller',
    defaultMessage: 'Message Seller'
  }),
  MESSAGE_BUYER: defineMessage({
    id: 'shared.message-buyer',
    defaultMessage: 'Message Buyer'
  }),
  MARK_AS_SOLD: defineMessage({
    id: 'shared.mark_as_sold',
    defaultMessage: 'Mark as sold'
  }),
  PREVIEW: defineMessage({
    id: 'shared.preview',
    defaultMessage: 'Preview'
  }),
  EDIT: defineMessage({
    id: 'shared.edit',
    defaultMessage: 'Edit'
  }),
  DELETE: defineMessage({
    id: 'shared.delete',
    defaultMessage: 'Delete'
  }),
  RELIST: defineMessage({
    id: 'shared.relist',
    defaultMessage: 'Relist'
  }),
  STANDARD_PRICE: defineMessage({
    id: 'shared.standard-price',
    defaultMessage: 'Standard price'
  }),
  NO_STOREFRONTS_YET: defineMessage({
    id: 'page.dashboard.no_storefront_yet',
    defaultMessage: 'No storefronts yet'
  }),
  NO_STOREFRONTS_YET_CONTENT: defineMessage({
    id: 'page.dashboard.not_storefront_content',
    defaultMessage:
      'You don’t have any storefrons yet. Click the ‘Create storefront’ button to add a storefton.'
  }),
  PREVIOUS: defineMessage({
    id: 'pagination.previous',
    defaultMessage: 'Previous'
  }),
  PAGE: defineMessage({
    id: 'pagination.page',
    defaultMessage: 'Page'
  }),
  OF: defineMessage({
    id: 'pagination.of',
    defaultMessage: 'of'
  }),
  NEXT: defineMessage({
    id: 'pagination.next',
    defaultMessage: 'Next'
  }),
  NOTIFICATIONS: defineMessage({
    id: 'shared.notifications',
    defaultMessage: 'Notifications'
  }),
  SENT_A_MESSAGE: defineMessage({
    id: 'notification.sent_a_message',
    defaultMessage: 'Sent a message'
  }),
  DECLINE: defineMessage({
    id: 'shared.decline',
    defaultMessage: 'Decline'
  }),
  ALLOW: defineMessage({
    id: 'shared.allow',
    defaultMessage: 'Allow'
  }),
  EXPLORE: defineMessage({
    id: 'shared.explore',
    defaultMessage: 'Explore'
  }),
  SHOW_MAP: defineMessage({
    id: 'shared.show_map',
    defaultMessage: 'Show map'
  }),
  ITEM: defineMessage({
    id: 'shared.item',
    defaultMessage: 'Item'
  }),
  LOCATION: defineMessage({
    id: 'shared.location',
    defaultMessage: 'Location'
  }),
  ADD_WANTED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_listing.wanted_description',
    defaultMessage: 'You want to list an item you wish to purchase. '
  }),
  ADD_PERSONAL_LISTING_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_listing.personal_description',
    defaultMessage: 'You want to list a personal item you wish to sell.  '
  }),
  ADD_STOREFRONT_ITEM_DESCRIPTION: defineMessage({
    id: 'page.dashboard.create_listing.storefront_item_description',
    defaultMessage: 'You want to list an item you sell in your storefront. '
  }),
  LISTING_CREATED_TITLE: defineMessage({
    id: 'page.dashboard.listing_created_title',
    defaultMessage: 'You created a new listing 🤩'
  }),
  LISTING_CREATED_DESCRIPTION: defineMessage({
    id: 'page.dashboard.listing_created_description',
    defaultMessage:
      'Much impressive! You created a new listing like a true crypto pro! Click view listing to see how your listing looks to buyers.'
  }),
  COPIED: defineMessage({
    id: 'shared.copied',
    defaultMessage: 'Copied'
  }),
  NO_MESSAGES_YET: defineMessage({
    id: 'page.dashboard.messages.no_messages_yet',
    defaultMessage: 'No messages yet'
  }),
  NO_MESSAGES_DESCRIPTION: defineMessage({
    id: 'page.dashboard.messages.no_messages_description',
    defaultMessage:
      'You don’t have any messages. Explore the map to start a conversation with a seller.'
  }),
  MESSAGES_DISPLAYED_HERE: defineMessage({
    id: 'page.dashboard.messages.your_messages_displayed_here',
    defaultMessage: 'Your messages are displayed here.'
  }),
  VIEW_STOREFRONT: defineMessage({
    id: 'page.dahboard.view_storefront',
    defaultMessage: 'View storefront'
  }),
  STOREFRONT_LISTINGS: defineMessage({
    id: 'page.dashboard.storefront_listings',
    defaultMessage: 'Storefront listings'
  }),
  VIEW_FOR_SALE: defineMessage({
    id: 'page.dashboard.view_for_sale',
    defaultMessage: 'View what this store has for sale'
  }),
  STOREFRONT_FILTERS: defineMessage({
    id: 'page.dashbard.storefront_filters',
    defaultMessage: 'Storefront filters'
  }),
  FILTER_YOUR_SEARCH_BY_STOREFRONT: defineMessage({
    id: 'page.dashboard.filter_your_search_by_storefront',
    defaultMessage: 'Filter your search by storefront here.'
  }),
  DOWNLOAD: defineMessage({
    id: 'shared.download',
    defaultMessage: 'Download'
  }),
  ADD_ADMIN: defineMessage({
    id: 'shared.add_admin',
    defaultMessage: 'Add admin'
  }),
  ADD_LANGUAGE_PROFICIENCY: defineMessage({
    id: 'shared.add_language_proficiency',
    defaultMessage: 'Add language proficiency'
  }),
  ADD_USER: defineMessage({
    id: 'shared.add_user',
    defaultMessage: 'Add user'
  }),
  INVITE_USER: defineMessage({
    id: 'shared.invite_user',
    defaultMessage: 'Invite user'
  }),
  ADD_RECIPIENT: defineMessage({
    id: 'shared.add_recipient',
    defaultMessage: 'Add recipient'
  }),
  MANAGE_USERS: defineMessage({
    id: 'shared.button_manage_users',
    defaultMessage: 'Manage users'
  }),
  USERS: defineMessage({
    id: 'shared.users',
    defaultMessage: 'Users'
  }),
  USER_TYPE: defineMessage({
    id: 'shared.user_type',
    defaultMessage: 'User type'
  }),
  FLAGS: defineMessage({
    id: 'shared.flags',
    defaultMessage: 'Flags'
  }),
  VIEW: defineMessage({
    id: 'page.dashboard.view',
    defaultMessage: 'View'
  }),
  WARNINGS: defineMessage({
    id: 'page.dashboard.warnings',
    defaultMessage: 'Warnings'
  }),
  UNBLOCK: defineMessage({
    id: 'page.dashboard.unblock',
    defaultMessage: 'Unblock'
  }),
  YOU_BLOCKED_TITLE: defineMessage({
    id: 'page.dashboard.you_blocked_title',
    defaultMessage: 'You blocked @{name}'
  }),
  YOU_BLOCKED_MESSAGE: defineMessage({
    id: 'page.dashboard.you_blocked_message',
    defaultMessage:
      'You successfully blocked @{name}. They can no longer view your profile or contact you until you unblock them.'
  }),
  VIEW_MY_BLOCKED_USERS: defineMessage({
    id: 'page.dashboard.view_my_blocked_users',
    defaultMessage: 'View my blocked users'
  }),
  RECENT_LISTINGS: defineMessage({
    id: 'page.dashboard.recent_listings',
    defaultMessage: 'Recent listings'
  }),
  VIEW_USERS_RECENT_LISTINGS: defineMessage({
    id: 'page.dashboard.view_users_recent_listings',
    defaultMessage: 'View the user’s recent listings.'
  }),
  REPORT_LISTING_SEARCH_PLACEHOLDER: defineMessage({
    id: 'page.report.listing_search_placeholder',
    defaultMessage: 'Search by listing, name, username'
  }),
  REPORT_FLAGGED_LISTING_TITLE: defineMessage({
    id: 'page.report.flagged_listing_title',
    defaultMessage: 'Flagged listings'
  }),
  REPORT_FLAGGED_STOREFRONT_TITLE: defineMessage({
    id: 'page.report.flagged_storefront_title',
    defaultMessage: 'Flagged storefronts'
  }),
  REPORT_FLAGGED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.report.flagged_listing_description',
    defaultMessage: 'All flagged listings are displayed here.'
  }),
  REPORT_FLAGGED_STOREFRONT_DESCRIPTION: defineMessage({
    id: 'page.report.flagged_storefront_description',
    defaultMessage: 'All flagged storefronts are displayed here.'
  }),
  REPORT_QUARANTINE_LISTING_TITLE: defineMessage({
    id: 'page.report.quarantine_listing_title',
    defaultMessage: 'Quarantine listings'
  }),
  REPORT_QUARANTINE_STOREFRONT_TITLE: defineMessage({
    id: 'page.report.quarantine_storefront_title',
    defaultMessage: 'Quarantine storefronts'
  }),
  REPORT_QUARANTINE_LISTING_DESCRIPTION: defineMessage({
    id: 'page.report.quarantine_listing_description',
    defaultMessage: 'All quarantine listings are displayed here.'
  }),
  REPORT_QUARANTINE_STOREFRONT_DESCRIPTION: defineMessage({
    id: 'page.report.quarantine_storefront_description',
    defaultMessage: 'All quarantine storefronts are displayed here.'
  }),
  REPORT_DELETED_LISTING_TITLE: defineMessage({
    id: 'page.report.deleted_listing_title',
    defaultMessage: 'Deleted listings'
  }),
  REPORT_DELETED_STOREFRONT_TITLE: defineMessage({
    id: 'page.report.deleted_storefront_title',
    defaultMessage: 'Deleted storefronts'
  }),
  REPORT_DELETED_LISTING_DESCRIPTION: defineMessage({
    id: 'page.report.deleted_listing_description',
    defaultMessage: 'All deleted listings are displayed here.'
  }),
  REPORT_DELETED_STOREFRONT_DESCRIPTION: defineMessage({
    id: 'page.report.deleted_storefront_description',
    defaultMessage: 'All deleted storefronts are displayed here.'
  }),
  NOTHING_TO_DISPLAY: defineMessage({
    id: 'shared.nothing_to_display',
    defaultMessage: 'Nothing to display'
  }),
  CONTACT: defineMessage({
    id: 'shared.contact',
    defaultMessage: 'Contact'
  }),
  COPY: defineMessage({
    id: 'shared.copy',
    defaultMessage: 'Copy'
  }),
  REPORTED_LISTING: defineMessage({
    id: 'shared.reported_listing',
    defaultMessage: 'Reported listing'
  }),
  QUARANTINED_LISTING: defineMessage({
    id: 'shared.quarantined_listing',
    defaultMessage: 'Quarantined listing'
  }),
  REASON: defineMessage({
    id: 'shared.reason',
    defaultMessage: 'Reason'
  }),
  FLAGE_ISSUED_BY: defineMessage({
    id: 'shared.flag_issued_by',
    defaultMessage: 'Flag issued by '
  }),
  WARNING_DELETION_ISSUED_BY: defineMessage({
    id: 'shared.warning_deletion_issued_by',
    defaultMessage: 'Warning & deletion issued by'
  }),
  BAN_DELETION_ISSUED_BY: defineMessage({
    id: 'shared.ban_deletion_issued_by',
    defaultMessage: 'Ban & deletion issued by'
  }),
  QUARANTINE_ISSUED_BY: defineMessage({
    id: 'shared.quarantine_issued_by',
    defaultMessage: 'Quarntine issued by'
  }),
  CLEAR_FLAGS: defineMessage({
    id: 'shared.clear_flags',
    defaultMessage: 'Clear flags'
  }),
  CLEAR_FLAGGED_MESSAGES: defineMessage({
    id: 'shared.clear_flaged_messages',
    defaultMessage: 'Clear flagged messages'
  }),
  DELETE_AND_WARNING: defineMessage({
    id: 'shared.delete_and_warning',
    defaultMessage: 'Delete & warning'
  }),
  ISSUE_WARNING_INSTEAD: defineMessage({
    id: 'shared.issue_warning_instead',
    defaultMessage: 'Issue warning instead'
  }),
  DELETE_AND_BAN: defineMessage({
    id: 'shared.delete_and_ban',
    defaultMessage: 'Delete & ban'
  }),
  RESTORE_LISTING: defineMessage({
    id: 'shared.restore_listing',
    defaultMessage: 'Restore listing'
  }),
  BECOME_MODERATOR: defineMessage({
    id: 'page.dashboard.become_moderator',
    defaultMessage: 'Apply to become a Moderator!'
  }),
  BECOME_MODERATOR_DESCRIPTION: defineMessage({
    id: 'page.dashboard.become_moderator_description',
    defaultMessage:
      'Apply to be one of our moderators and earn extra points. Get started as soon as we accept your application.'
  }),
  START_APPLYING: defineMessage({
    id: 'page.dashboard.start_applying',
    defaultMessage: 'Start applying'
  }),
  MODERATOR_APPLICATION_SUBMITTED: defineMessage({
    id: 'page.dsahboard.moderator_application_submitted',
    defaultMessage: 'Moderator application submitted!'
  }),
  MODERATOR_APPLICATION_SUBMITTED_DETAILS: defineMessage({
    id: 'page.dashboard.moderator_application_sumitted_details',
    defaultMessage: 'Be on the lookout. We’ll send you a welcome email if you’re selected.'
  }),
  MUST_BE_NUMBER: defineMessage({
    id: 'page.dashboard.validation.must_ba_number',
    defaultMessage: 'Must be a number'
  }),
  FIELD_REQUIRED: defineMessage({
    id: 'page.dashboard.validation.field_required',
    defaultMessage: 'Field is required'
  }),
  AN_OPTION_MUST_BE_SELECTED: defineMessage({
    id: 'page.dashboard.validation.an_option_must_be_selected',
    defaultMessage: 'An option must be selected'
  }),
  VIEW_FLAGS: defineMessage({
    id: 'page.dashboard.view_flags',
    defaultMessage: 'View Flags'
  }),
  USER: defineMessage({
    id: 'shared.user',
    defaultMessage: 'User'
  }),
  ACTION: defineMessage({
    id: 'shared.action',
    defaultMessage: 'Action'
  }),
  DATE: defineMessage({
    id: 'shared.date',
    defaultMessage: 'Date'
  }),
  RESTORE: defineMessage({
    id: 'shared.restore',
    defaultMessage: 'Restore'
  }),
  PRIVACY_POLICY_MAIN_TITLE: defineMessage({
    id: 'page.privacy_policy.main_title',
    defaultMessage: 'Current as of Nov 28th, 2022'
  }),
  PRIVACY_POLICY_TITLE1: defineMessage({
    id: 'page.privacy_policy.title1',
    defaultMessage: 'Privacy Policy'
  }),
  PRIVACY_POLICY_TITLE2: defineMessage({
    id: 'page.privacy_policy.title2',
    defaultMessage: 'Information we collect'
  }),
  PRIVACY_POLICY_TITLE3: defineMessage({
    id: 'page.privacy_policy.title3',
    defaultMessage: 'Use of information'
  }),
  PRIVACY_POLICY_TITLE4: defineMessage({
    id: 'page.privacy_policy.title4',
    defaultMessage: 'Sharing of information'
  }),
  PRIVACY_POLICY_TITLE5: defineMessage({
    id: 'page.privacy_policy.title5',
    defaultMessage: 'Data retention'
  }),
  PRIVACY_POLICY_TITLE6: defineMessage({
    id: 'page.privacy_policy.title6',
    defaultMessage: 'Security'
  }),
  PRIVACY_POLICY_TITLE7: defineMessage({
    id: 'page.privacy_policy.title7',
    defaultMessage: 'Cookies policy'
  }),
  PRIVACY_POLICY_TITLE8: defineMessage({
    id: 'page.privacy_policy.title8',
    defaultMessage: 'How do we use cookies?'
  }),
  PRIVACY_POLICY_TITLE9: defineMessage({
    id: 'page.privacy_policy.title9',
    defaultMessage: 'Managing cookies'
  }),
  PRIVACY_POLICY_TITLE10: defineMessage({
    id: 'page.privacy_policy.title10',
    defaultMessage: 'Changes to our privacy policy'
  }),
  PRIVACY_POLICY_TITLE11: defineMessage({
    id: 'page.privacy_policy.title11',
    defaultMessage: 'Transparent disclosure policy'
  }),
  PRIVACY_POLICY_TITLE12: defineMessage({
    id: 'page.privacy_policy.title12',
    defaultMessage: 'Contact us'
  }),
  PRIVACY_POLICY_P1: defineMessage({
    id: 'page.privacy_policy.p1',
    defaultMessage:
      'Welcome to The DogeList, a classifieds website owned by The DogeList LLC in California, USA that allows personal sellers and brick-and-mortar businesses to post "For Sale" or "Wanted" listings on a map for transactions using dogecoin.'
  }),
  PRIVACY_POLICY_P2: defineMessage({
    id: 'page.privacy_policy.p2',
    defaultMessage:
      'Our platform is designed to facilitate communication between buyers and sellers, but we do not act as an escrow service for transactions.'
  }),
  PRIVACY_POLICY_P3: defineMessage({
    id: 'page.privacy_policy.p3',
    defaultMessage:
      'At The DogeList, we take your privacy seriously. This Privacy Policy explains what information we collect from you and how we use it. By using our website, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.'
  }),
  PRIVACY_POLICY_P4: defineMessage({
    id: 'page.privacy_policy.p4',
    defaultMessage:
      'When you use our website, we may collect personal information, such as your name, email address, and phone number, as well as non-personal information, such as your IP address, device type, and browsing history.'
  }),
  PRIVACY_POLICY_P5: defineMessage({
    id: 'page.privacy_policy.p5',
    defaultMessage:
      'We may also collect information about your transactions, such as the items you have listed or the items you have purchased.'
  }),
  PRIVACY_POLICY_P6: defineMessage({
    id: 'page.privacy_policy.p6',
    defaultMessage:
      'We may use your information to provide and improve our services, communicate with you, and display advertising. We may also use your information to prevent fraud or abuse of our website and to enforce our terms of service.'
  }),
  PRIVACY_POLICY_P7: defineMessage({
    id: 'page.privacy_policy.p7',
    defaultMessage:
      'We may share your information with third parties for the purpose of providing our services, such as displaying targeted advertising. We may also share your information as required by United States law or to protect the rights and safety of The DogeList LLC and others.'
  }),
  PRIVACY_POLICY_P8: defineMessage({
    id: 'page.privacy_policy.p8',
    defaultMessage:
      'We will retain your information for as long as necessary to provide our services or as required by law.'
  }),
  PRIVACY_POLICY_P9: defineMessage({
    id: 'page.privacy_policy.p9',
    defaultMessage:
      'We take reasonable steps to protect your information from unauthorized access, use, or disclosure. However, no website can fully eliminate security risks.'
  }),
  PRIVACY_POLICY_P10: defineMessage({
    id: 'page.privacy_policy.p10',
    defaultMessage:
      'Our website uses cookies and similar technologies to improve your browsing experience. By using our website, you consent to the use of cookies in accordance with this policy.'
  }),
  PRIVACY_POLICY_P11: defineMessage({
    id: 'page.privacy_policy.p11',
    defaultMessage: 'We use cookies for two reasons, they include:'
  }),
  PRIVACY_POLICY_P12: defineMessage({
    id: 'page.privacy_policy.p12',
    defaultMessage:
      '1.Essential cookies: Essential cookies are necessary for the operation of our website. They enable features like page navigation, access to secure areas, and other core features.'
  }),
  PRIVACY_POLICY_P13: defineMessage({
    id: 'page.privacy_policy.p13',
    defaultMessage:
      '2.Functionality cookies: Functionality cookies allow our website to remember your choices to personalize your experience. These choices include location settings and your preferred language or region.'
  }),
  PRIVACY_POLICY_P14: defineMessage({
    id: 'page.privacy_policy.p14',
    defaultMessage:
      'To manage cookies, go to the settings page. Click the location services tab on the left navigation panel. Deactivate the location services choice by clicking the radio button to deactivate it. However, please note that disabling cookies may affect the functionality of our website and your ability to access certain features.'
  }),
  PRIVACY_POLICY_P15: defineMessage({
    id: 'page.privacy_policy.p15',
    defaultMessage:
      'We may update this Privacy Policy from time to time. Your continued use of our website following any changes to this Privacy Policy will be considered acceptance of those changes.'
  }),
  PRIVACY_POLICY_P16: defineMessage({
    id: 'page.privacy_policy.p16',
    defaultMessage:
      'We may disclose your information to government or legal authorities if required by United States law. This includes, but is not limited to, court orders, subpoenas, or other legal process. We will make every effort to notify you if your information is requested by government or legal authorities, unless prohibited by law or court order.'
  }),
  PRIVACY_POLICY_P17: defineMessage({
    id: 'page.privacy_policy.p17',
    defaultMessage:
      'If you have any questions or concerns about this Privacy Policy or your information, please contact us at support@dogelist.com'
  }),
  PRIVACY_POLICY_P18: defineMessage({
    id: 'page.privacy_policy.p18',
    defaultMessage:
      'Please also review our separate terms of service for information on how we handle and protect your personal information.'
  }),
  TERMS_SERVICE_MAIN_TITLE: defineMessage({
    id: 'page.terms_service.main_title',
    defaultMessage: 'Current as of Nov 28th, 2022'
  }),
  TERMS_SERVICE_TITLE1: defineMessage({
    id: 'page.terms_service.title1',
    defaultMessage: 'Terms of Service'
  }),
  TERMS_SERVICE_TITLE2: defineMessage({
    id: 'page.terms_service.title2',
    defaultMessage: 'By using our website, you agree to the following terms of service'
  }),
  TERMS_SERVICE_P1: defineMessage({
    id: 'page.terms_service.p1',
    defaultMessage:
      'Welcome to The DogeList, a classifieds website owned by The DogeList LLC in California, USA that allows personal sellers and brick-and-mortar businesses to post "For Sale" or "Wanted" listings on a map for transactions using dogecoin.'
  }),
  TERMS_SERVICE_P2: defineMessage({
    id: 'page.terms_service.p2',
    defaultMessage:
      'Our platform is designed to facilitate communication between buyers and sellers, but we do not act as an escrow service for transactions.'
  }),
  TERMS_SERVICE_P3: defineMessage({
    id: 'page.terms_service.p3',
    defaultMessage: 'You must be 18 years or older to use our platform.'
  }),
  TERMS_SERVICE_P4: defineMessage({
    id: 'page.terms_service.p4',
    defaultMessage:
      'You are responsible for the accuracy and legality of all information posted on our website, including but not limited to product descriptions, prices, and contact information.'
  }),
  TERMS_SERVICE_P5: defineMessage({
    id: 'page.terms_service.p5',
    defaultMessage:
      'The DogeList does not guarantee the accuracy or legality of any information posted on our website and does not verify listings or protect against misrepresentation of businesses.'
  }),
  TERMS_SERVICE_P6: defineMessage({
    id: 'page.terms_service.p6',
    defaultMessage:
      'You are responsible for verifying and confirming the identities of businesses, especially their locations.'
  }),
  TERMS_SERVICE_P7: defineMessage({
    id: 'page.terms_service.p7',
    defaultMessage:
      'You may not use our platform to post any illegal, fraudulent, or discriminatory content. This includes, but is not limited to, pornographic materials, drugs, weapons, or adult items. Additionally, you may not: Interfere with or access the platform or its services using a method other than what is explicitly provided.'
  }),
  TERMS_SERVICE_P8: defineMessage({
    id: 'page.terms_service.p8',
    defaultMessage:
      'Use the platform in any way that causes, or may cause, damage to the platform or impairment of the availability or accessibility of the platform, or use it in any way that is unlawful, illegal, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity. Use the platform or any of its content for any purposes related to marketing.'
  }),
  TERMS_SERVICE_P9: defineMessage({
    id: 'page.terms_service.p9',
    defaultMessage:
      'Use the platform or any of its content for commercial purposes or to offer to sell any commercial products or the following: controlled substances; illegal drugs and drug contraband; weapons; pirated materials; instructions on making, assembling, or obtaining illegal goods or weapons to attack others; information used to violate the copyright(s) of, violate the trademark(s) of, or destroy others’ intellectual property or information; information used to illegally harm any people or animals; or pornography, nudity, escort services, or other content deemed adult-related.'
  }),
  TERMS_SERVICE_P10: defineMessage({
    id: 'page.terms_service.p10',
    defaultMessage:
      'Use the platform or any of its content to violate or encourage the violation of, the legal rights of others (for example, this may include the infringement or misappropriation of intellectual property rights of others in violation of the Digital Millennium Copyright Act).'
  }),
  TERMS_SERVICE_P11: defineMessage({
    id: 'page.terms_service.p11',
    defaultMessage:
      'Use the platform to send or distribute viruses, worms, Trojan horses, corrupted files, hoaxes, or other items of a destructive or deceptive nature.'
  }),
  TERMS_SERVICE_P12: defineMessage({
    id: 'page.terms_service.p12',
    defaultMessage:
      'Use the platform to engage in any activity that is determined by us, in our sole and absolute discretion, to be illegal.'
  }),
  TERMS_SERVICE_P13: defineMessage({
    id: 'page.terms_service.p13',
    defaultMessage:
      'Use the platform to post, transmit, display, or otherwise make available obscene, profane, defamatory, harassing, abusive, or threatening language.'
  }),
  TERMS_SERVICE_P14: defineMessage({
    id: 'page.terms_service.p14',
    defaultMessage:
      'Use the platform or its content for any unlawful, invasive, infringing, defamatory, or fraudulent purpose (for example, this may include phishing, creating a pyramid scheme, or mirroring a website). Use the platform to generate, distribute, publish, or facilitate unsolicited mass email, promotions, advertisings, or other solicitations (“spam”).'
  }),
  TERMS_SERVICE_P15: defineMessage({
    id: 'page.terms_service.p15',
    defaultMessage:
      'All listings have a reporting button that will be viewed and managed by content moderators, but starting out the website may only have volunteer moderators.'
  }),
  TERMS_SERVICE_P16: defineMessage({
    id: 'page.terms_service.p16',
    defaultMessage:
      'The DogeList reserves the right to remove any content that violates our terms of service or that we deem inappropriate. The DogeList reserves the right to remove content and/or ban users who violate this agreement.'
  }),
  TERMS_SERVICE_P17: defineMessage({
    id: 'page.terms_service.p17',
    defaultMessage:
      'The DogeList will receive donations and funds through advertising, but will not act as an escrow service for transactions.'
  }),
  TERMS_SERVICE_P18: defineMessage({
    id: 'page.terms_service.p18',
    defaultMessage: 'Currency conversion calculations are approximate and not immune from error.'
  }),
  TERMS_SERVICE_P19: defineMessage({
    id: 'page.terms_service.p19',
    defaultMessage:
      'Users of the site are responsible for ensuring that their listing advertises as intended. Dogecoin amounts may be automatically rounded to the nearest 10 Dogecoin.'
  }),
  TERMS_SERVICE_P20: defineMessage({
    id: 'page.terms_service.p20',
    defaultMessage:
      "By using The DogeList, you agree to indemnify and hold harmless The DogeList LLC, its affiliates, directors, officers, and employees from any and all claims, damages, and expenses, including attorney's fees, arising from your use of our platform."
  }),
  TERMS_SERVICE_P21: defineMessage({
    id: 'page.terms_service.p21',
    defaultMessage:
      'Our platform is provided "as is" and we make no warranties or representations of any kind regarding the platform or any content or information provided on the platform.'
  }),
  TERMS_SERVICE_P22: defineMessage({
    id: 'page.terms_service.p22',
    defaultMessage:
      'These terms of service are governed by the laws of the state of California. Any disputes arising from or related to these terms of service or the use of our platform will be resolved exclusively in the courts located in California.'
  }),
  TERMS_SERVICE_P23: defineMessage({
    id: 'page.terms_service.p23',
    defaultMessage:
      ' The DogeList LLC reserves the right to modify these terms of service at any time. Your continued use of our platform following any changes to these terms of service will be considered acceptance of those changes.'
  }),
  TERMS_SERVICE_P24: defineMessage({
    id: 'page.terms_service.p24',
    defaultMessage:
      'Thank you for using The DogeList. We hope you enjoy using our platform to find and sell items using dogecoin!'
  }),
  TERMS_SERVICE_P25: defineMessage({
    id: 'page.terms_service.p25',
    defaultMessage:
      'Please also review our separate privacy policy for information on how we handle and protect your personal information.'
  }),
  LISTING_REPORT_ALERT_TITLE: defineMessage({
    id: 'share.listing.report.alert.title',
    defaultMessage: 'You just reported a listing'
  }),
  STOREFRONT_REPORT_ALERT_TITLE: defineMessage({
    id: 'share.storefront.report.alert.title',
    defaultMessage: 'You just reported a storefront'
  }),
  LISTING_REPORT_ALERT_DESCRIPTION: defineMessage({
    id: 'share.listing.report.alert.description',
    defaultMessage:
      'Your efforts help maintain the integrity of our marketplace. We will look into it. Thank you.'
  }),
  QUARANTINE: defineMessage({
    id: 'share.listing.quarantine',
    defaultMessage: 'Quarantine'
  }),
  DELETE_WARNING: defineMessage({
    id: 'share.listing.delete_warning',
    defaultMessage: 'Delete Warning'
  }),
  DELETE_BAN: defineMessage({
    id: 'share.listing.delete_ban',
    defaultMessage: 'Delete Ban'
  }),
  QUARANTINE_ALERT_MESSAGE: defineMessage({
    id: 'share.quarantine.alert.message',
    defaultMessage:
      'Your listing is under quarantine and is being reviewed by our team. Please allow 24-48 hours for a resolution. Contact support for any questions.'
  }),
  QUARANTINE_ALERT_LINK_TEXT: defineMessage({
    id: 'share.quarantine.alert.link_text',
    defaultMessage: 'Contact support'
  }),
  VALIDATION_USERNAME_TAKEN: defineMessage({
    id: 'page.create_account.username_token',
    defaultMessage: 'Oops, this username is already taken. Please try again.'
  }),
  CREATE_ACCOUNT_SUCCESS_ALERT_TITLE: defineMessage({
    id: 'page.create_account.success_alert.title',
    defaultMessage: 'Your account is good to go, [{name}]! 🎉'
  }),
  CREATE_ACCOUNT_SUCCESS_ALERT_DESCRIPTION: defineMessage({
    id: 'page.create_account.success_alert.description',
    defaultMessage:
      'Hi [{name}], welcome to TheDogeList.com! To get started, explore the map or view your dashboard to manage your listings.'
  }),
  REFERRAL_HISTORY: defineMessage({
    id: 'page.settings.referral_history',
    defaultMessage: 'Referral history'
  }),
  CURRENT_PASSWORD: defineMessage({
    id: 'page.settings.current_password',
    defaultMessage: 'Current password'
  }),
  ENTER_CURRENT_PASSWORD: defineMessage({
    id: 'page.settings.enter_current_password',
    defaultMessage: 'Enter your current password'
  }),
  YOU_CAN_ADD_REMOVE_BLOCKED_USERS: defineMessage({
    id: 'page.settings.you_can_add_remove_blocked_users',
    defaultMessage: 'You can add or remove blocked users here.'
  }),
  CLICK_BLOCKED_USER_TO_ADD_USER: defineMessage({
    id: 'page.settings.click_block_user',
    defaultMessage: 'Click ‘Block user’ to add a user you want to block.'
  }),
  ACCOUNTS: defineMessage({
    id: 'page.settings.accounts',
    defaultMessage: 'Accounts'
  }),
  BANNED_USERS_LISTED_HERE: defineMessage({
    id: 'page.settings.banned_users_listed_here',
    defaultMessage: 'Banned users are listed here.'
  }),
  DATE_ADDED: defineMessage({
    id: 'page.settings.date_added',
    defaultMessage: 'Date added'
  }),
  ROLE: defineMessage({
    id: 'page.settings.role',
    defaultMessage: 'Role'
  }),
  YOUR_CURRENT_ROLE: defineMessage({
    id: 'page.settings.your_current_role',
    defaultMessage: 'Your current role is listed here.'
  }),
  LEAVE: defineMessage({
    id: 'page.settings.leave',
    defaultMessage: 'Leave'
  }),
  LEAVE_ROLE: defineMessage({
    id: 'page.settings.leave_role',
    defaultMessage: 'Leave role'
  }),
  LEAVING_ROLE: defineMessage({
    id: 'page.settings.leaving_role',
    defaultMessage: 'Leaving role'
  }),
  LEAVE_ROLE_PENDING: defineMessage({
    id: 'page.settings.leave_role_pending',
    defaultMessage: 'Leave role pending'
  }),
  ADMIN_SETTINGS: defineMessage({
    id: 'page.settings.admin_settings',
    defaultMessage: 'Admin settings'
  }),
  REMOVE_ADMIN_ROLE: defineMessage({
    id: 'page.settings.remove_admin_role',
    defaultMessage: 'You can remove yourself as admin here.'
  }),
  APPROVED: defineMessage({
    id: 'page.settings.approved',
    defaultMessage: 'Approved'
  }),
  PENGING: defineMessage({
    id: 'page.settings.pending',
    defaultMessage: 'Pending'
  }),
  REMOVE_APPLICATION: defineMessage({
    id: 'page.settings.remove_application',
    defaultMessage: 'Remove application'
  }),
  REMOVE_APPLICATION_PENDING: defineMessage({
    id: 'page.settings.remove_application_pending',
    defaultMessage: 'emove application pending'
  }),
  REMOVING_APPLICATION: defineMessage({
    id: 'page.settings.removing_application',
    defaultMessage: 'Removing application'
  }),
  MODERATOR_SETTINGS: defineMessage({
    id: 'page.settings.moderator_settings',
    defaultMessage: 'Moderator settings'
  }),
  REMOVE_MODERATOR_ROLE: defineMessage({
    id: 'page.settings.remove.moderator_role',
    defaultMessage: 'You can remove yourself as moderator here.'
  }),
  VIEW_YOUR_REFERRAL_HISTORY: defineMessage({
    id: 'page.settings.view_your_referral_history',
    defaultMessage: 'You can view your referral history here.'
  }),
  WELCOME_TO_DODELIST: defineMessage({
    id: 'page.home.welcome_to_dogelist',
    defaultMessage: 'Welcome to The TheDogeList.com'
  }),
  EXPLORE_BUY_SELL: defineMessage({
    id: 'page.home.explore_buy_sell',
    defaultMessage: 'Explore, buy, and sell with Dogecoin'
  }),
  EXPLORE_BUY_SELL_DETAILS: defineMessage({
    id: 'page.home.explore_buy_sell_details',
    defaultMessage:
      'Find local buyers and sellers who want to transact in Dogecoin – the people’s crypto. Sign up in just a few easy clicks!'
  }),
  ADD_YOUR_LOCAL_BUSINESS: defineMessage({
    id: 'page.home.add_your_local_business',
    defaultMessage: 'Add your local business to the map.'
  }),
  BUYING_SELLING_ON_DEGELIST: defineMessage({
    id: 'page.home.buying_selling_on_dogelist',
    defaultMessage: 'Buying & selling on TheDogeList.com'
  }),
  BUY_SELL_ANYTHING: defineMessage({
    id: 'page.home.buy_sell_anything',
    defaultMessage: 'Buy & sell anything locally using Dogecoin'
  }),
  BUY_SELL_ANYTHING_TITLE: defineMessage({
    id: 'page.home.buy_sell_anything_title',
    defaultMessage: 'Our marketplace is built to support local communities.'
  }),
  BUY_SELL_ANYTHING_DETAILS: defineMessage({
    id: 'page.home.buy_sell_anything_details',
    defaultMessage:
      'Discover what local sellers and businesses are selling in your area — wherever you may be.'
  }),
  ADD_WANTED_LISTING: defineMessage({
    id: 'page.home.add_wanted_listing',
    defaultMessage: 'Add a wanted listing'
  }),
  ADD_WANTED_LISTING_DETAILS: defineMessage({
    id: 'page.home.add_wanted_listing_details',
    defaultMessage:
      'Create a wanted listing for item’s you’re looking to purchase and someone will reach out to you if they have it for sale.'
  }),
  ADD_PERSONAL_LISTING: defineMessage({
    id: 'page.home.add_personal_listing',
    defaultMessage: 'Add a personal listing'
  }),
  ADD_PERSONAL_LISTING_DETAILS: defineMessage({
    id: 'page.home.add_personal_listing_details',
    defaultMessage:
      'List anything from your old pairs of shoes or unused electronics. The DogeList is the right place to sell your unwanted items.'
  }),
  ADD_A_STOREFRONT: defineMessage({
    id: 'page.home.add_storefront',
    defaultMessage: 'Add a storefront'
  }),
  ADD_A_STOREFRONT_DETAILS: defineMessage({
    id: 'page.home.add_storefront_details',
    defaultMessage:
      'If you own a business you can list your local business as a storefront that other The DogeList users can discover on the map.'
  }),
  THE_DOGELIST_MAP: defineMessage({
    id: 'page.home.the_dogelist_map',
    defaultMessage: 'The DogeList map'
  }),
  ADD_LISTING_TO_MAP: defineMessage({
    id: 'page.home.add_listing_to_map',
    defaultMessage: 'Add listings to the map'
  }),
  ADD_LISTING_TO_MAP_DETAILS: defineMessage({
    id: 'page.home.add_listing_to_map_details',
    defaultMessage:
      'All listings on the Dogelist map are available for anyone in the area to see. Buyers can search by category, name, and location to find items they want to purchase or discover.'
  }),
  DOGELIST_DESCRIPTION: defineMessage({
    id: 'shared.dogelist-description',
    defaultMessage:
      'Find local buyers and sellers who want to transact in Dogecoin – the people’s crypto.'
  }),
  TERMS_OF_SERVICE: defineMessage({
    id: 'shared.term-of-service',
    defaultMessage: 'Terms of service'
  }),
  UPLOAD_PHOTO_AND_PERSONAL_DETAILS: defineMessage({
    id: 'page.settings.upload_photo_and_personal_details',
    defaultMessage: 'Update your photo and personal details here.'
  }),
  ENTER_YOUR_NAME: defineMessage({
    id: 'share.placeholder.enter_your_name',
    defaultMessage: 'Enter your name'
  }),
  ADD_ANOTHER: defineMessage({
    id: 'page.settings.add_another',
    defaultMessage: 'Add another'
  }),
  YOUR_PHOTO: defineMessage({
    id: 'page.settings.your_photo',
    defaultMessage: 'Your photo'
  }),
  DISPLAYED_ON_YOUR_PROFILE: defineMessage({
    id: 'page.settings.displayed_on_your_profile',
    defaultMessage: 'This will be displayed on your profile.'
  }),
  UPLOAD_PHOTO: defineMessage({
    id: 'page.settings.upload_photo',
    defaultMessage: 'Upload photo'
  }),
  HEADER_PHOTO: defineMessage({
    id: 'page.settings.header_photo',
    defaultMessage: 'Header photo'
  }),
  DISPLAYED_YOUR_DASHBOARD_AND_PROFILE: defineMessage({
    id: 'page.settings.displayed_on_your_dashboard_and_profile',
    defaultMessage: 'This will be displayed at the top of your dashboard and profile.'
  }),
  LOCATION_BANNER_DESCRIPTION: defineMessage({
    id: 'banner.dual_action_we_use_location',
    defaultMessage: 'We use cookies and other technology to personalize your experience'
  }),
  DAYS_ACTIVE: defineMessage({
    id: 'page.report.moderator.table.header.days_active',
    defaultMessage: 'Days active'
  }),
  YOU_CAN_CHANGE_PASSWORD: defineMessage({
    id: 'page.settings.you_can_change_password',
    defaultMessage: 'You can change your password here.'
  }),
  PASSWORD_MUST_MATCH: defineMessage({
    id: 'page.setting.password_must_match',
    defaultMessage: 'Passwords must match'
  }),
  SEARCH_BY_NAME_USERNAME: defineMessage({
    id: 'page.settings.search_by_name_username',
    defaultMessage: 'Search by name or username'
  }),
  ALLOW_PUSH_NOTIFICATIONS: defineMessage({
    id: 'page.settings.allow_push_notifications',
    defaultMessage: 'Allow push notifications'
  }),
  ALLOW_PUSH_NOTIFICATIONS_DETAILS: defineMessage({
    id: 'page.settings.allow_push_notifications_details',
    defaultMessage:
      'By allowing push notifications you agree to receiving updates and promotions that we announce.'
  }),
  NOTIFICATION_DETAILS: defineMessage({
    id: 'page.settings.notification_details',
    defaultMessage:
      'You still might receive important notifications about your account outside of your notification settings.'
  }),
  STATUS: defineMessage({
    id: 'page.settings.status',
    defaultMessage: 'Status'
  }),
  POINTS_EARNED: defineMessage({
    id: 'page.settings.points_earned',
    defaultMessage: 'Points earned'
  }),
  SEARCH_USER: defineMessage({
    id: 'page.shared.search_user',
    defaultMessage: 'Search user'
  }),
  NO_USERS_FOUND: defineMessage({
    id: 'page.shared.no_users_found',
    defaultMessage: 'No users found'
  }),
  DID_NOT_MATCH_ANY_USER: defineMessage({
    id: 'page.shared.did_not_match_any_user',
    defaultMessage: '“{name}” did not match any users. Would you like to try again?'
  }),
  YOU_CAN_CHANGE_MEETUP_LOCATION: defineMessage({
    id: 'page.settings.meetup_location.you_can_change_meetup_location',
    defaultMessage: 'You can change your meet-up location here.'
  }),
  YOU_CAN_CHANGE_LOCATION_PERMISSION: defineMessage({
    id: 'page.settings.meetup_location.you_can_change_location_permission',
    defaultMessage: 'You can change your location permission here.'
  }),
  MEET_UP_LOCATION: defineMessage({
    id: 'page.settings.meetup_location.meetup_location',
    defaultMessage: 'Meet-up location'
  }),
  LOCATION_SERVICES: defineMessage({
    id: 'page.settings.meetup_location.location_services',
    defaultMessage: 'Location services'
  }),
  ADD_YOUR_STOREFRONT_LOCATION: defineMessage({
    id: 'page.settings.meetup_location.add_your_storefront_location',
    defaultMessage: 'Add your storefront location here.'
  }),
  YOU_MUST_BE_18: defineMessage({
    id: 'shared.you_must_be_18',
    defaultMessage: 'You must be 18 years or older to sign up.'
  }),
  PLEASE_ENTER_YOUR_CITY: defineMessage({
    id: 'shared.please_enter_your_city',
    defaultMessage: 'Please enter your city'
  }),
  PLEASE_ENTER_YOUR_STATE_PROVINCE: defineMessage({
    id: 'shared.please_enter_your_state_province',
    defaultMessage: 'Please enter your state/province'
  }),
  PLEASE_ENTER_YOUR_COUNTRY: defineMessage({
    id: 'shared.please_enter_your_country',
    defaultMessage: 'Please enter your country'
  }),
  SEARCH_BY_ITEM_NAME: defineMessage({
    id: 'shared.search_by_item_name',
    defaultMessage: 'Search by item name'
  }),
  PROVIDE_MEETUP_LOCATION: defineMessage({
    id: 'page.create_account.provide_meetup_location',
    defaultMessage: 'Provide a meet up location you feel safest'
  }),
  PROVIDE_MEETUP_LOCATION_DETAILS: defineMessage({
    id: 'page.create_account.provide_meetup_location_details',
    defaultMessage:
      'The meet up location is the area you want to meet when transacting with a potential buyer.'
  }),
  COPY_LINK: defineMessage({
    id: 'shared.share.copy_link',
    defaultMessage: 'Copy Link'
  }),
  ALL_SELECTED: defineMessage({
    id: 'shared.all_selected',
    defaultMessage: 'All Selected'
  }),
  ALL_CATEGORIES: defineMessage({
    id: 'shared.all_categories',
    defaultMessage: 'All Categories'
  }),
  FLAGS_ISSUED: defineMessage({
    id: 'page.report.flagged_listing_activity_title',
    defaultMessage: 'Flags issued'
  }),
  FLAGS_ISSUED_DESCRIPTION: defineMessage({
    id: 'page.report.flagged_listing_acitivity_description',
    defaultMessage: 'See what users are reporting about this listing.'
  }),
  NO_QUARANTINE_YET: defineMessage({
    id: 'page.reports.nothing_quarantined_title',
    defaultMessage: 'No quarantine yet'
  }),
  NO_QUARANTINE_YET_DESCRIPTION: defineMessage({
    id: 'page.reports.nothing_quarantined_subtitle',
    defaultMessage: 'You don’t have any quarantine posts yet.'
  }),
  NO_DELETED_YET: defineMessage({
    id: 'page.reports.nothing_deleted_title',
    defaultMessage: 'No deleted yet'
  }),
  NO_DELETED_YET_DESCRIPTION: defineMessage({
    id: 'page.reports.nothing_deleted_subtitle',
    defaultMessage: 'You don’t have any deleted posts yet.'
  }),
  NOTHING_HERE_TO_DISPLAY: defineMessage({
    id: 'page.report.nothing_here_to_display',
    defaultMessage: 'Nothing here to display yet.'
  }),
  aboutTitle: defineMessage({
    id: 'page.about.title',
    defaultMessage: 'About us'
  }),
  aboutDescription: defineMessage({
    id: 'page.about.description',
    defaultMessage: 'Welcome to The DogeList - The Future of Dogecoin Commerce'
  }),
  dogelistDescription: defineMessage({
    id: 'page.dogelist.description',
    defaultMessage: `The DogeList is more than a marketplace; it's a movement. Born from a spark of inspiration
            in 2021, our vision has blossomed into a dynamic platform, championing the practical use
            of Dogecoin in everyday commerce.`
  }),
  donationTitle: defineMessage({
    id: 'page.donation.title',
    defaultMessage: 'Donate Today'
  }),

  firstSupporter: {
    id: 'about.first_supporter',
    defaultMessage: 'Be our first supporter ⎯ Your donation matters'
  },
  donateViaDogecoin: {
    id: 'about.donate_via_dogecoin',
    defaultMessage: 'Donate via Dogecoin'
  },
  donateViaPayPal: {
    id: 'about.donate_via_paypal',
    defaultMessage: 'Donate via PayPal'
  },

  donationDescription: defineMessage({
    id: 'page.donation.description',
    defaultMessage:
      'All donations are used to build a better platform for the people. Dogecoin to the moon!'
  }),
  missionTitle: defineMessage({
    id: 'page.mission.title',
    defaultMessage: 'Our mission: Beyond price, towards utility'
  }),
  missionDescription: defineMessage({
    id: 'page.mission.description',
    defaultMessage: `While the crypto world often fixates on market fluctuations,The DogeList takes a different
            path. Our core belief is that the true value of Dogecoin lies in its widespread,
            practical use. We're dedicated to empowering individuals, local merchants, and
            businesses to seamlessly engage in buying and selling with Dogecoin. Our dream? A world
            where Dogecoin isn't just a digital asset, but a widely accepted, enjoyable medium for
            transactions big and small.`
  }),
  envisioningTitle: defineMessage({
    id: 'page.envisioning.title',
    defaultMessage: 'Envisioning tomorrow'
  }),
  envisioningDescription: defineMessage({
    id: 'page.envisioning.description',
    defaultMessage: `At The DogeList, our eyes are always on the horizon. We're gearing up to support additional languages and more currency conversions, broadening Dogecoin's global reach. Stay tuned for innovative features and incentives that will make The DogeList an integral, vibrant part of the Dogecoin ecosystem.`
  }),
  whyDogelistTitle: defineMessage({
    id: 'page.whyDogelist.title',
    defaultMessage: 'Why Dogelist?'
  }),
  whyDogelistDescription1: defineMessage({
    id: 'page.whyDogelist.description1',
    defaultMessage: `Connecting Locally: We understand the value of local connections. Our interactive map allows you to explore items listed by users in your area. Whether you're looking for unique finds, everyday essentials, or special treasures, you can discover it all right in your community.`
  }),
  whyDogelistDescription2: defineMessage({
    id: 'page.whyDogelist.description2',
    defaultMessage: `Dual Currency Options: Embrace the future of commerce with the flexibility to transact in Dogecoin or USD. We believe in giving you the freedom to choose the currency that suits you best. It's a new way to experience the exchange of goods and services.`
  }),
  whyDogelistDescription3: defineMessage({
    id: 'page.whyDogelist.description3',
    defaultMessage: `User-Friendly Listings: Creating and browsing listings is a breeze on The DogeList. Showcase your items with ease and precision. Our platform prioritizes user-friendly interfaces, ensuring that your experience is as smooth as possible.`
  }),
  whyDogelistDescription4: defineMessage({
    id: 'page.whyDogelist.description4',
    defaultMessage: `Personalized Transactions: Our map isn't just a display; it's a meeting place. Connect with other users, negotiate deals, and arrange meet-ups directly on the platform. The DogeList is more than a marketplace; it's a community where transactions are personal and meaningful.`
  }),
  magicTitle: defineMessage({
    id: 'page.magic.title',
    defaultMessage: 'How the magic happens'
  }),
  magicDescription1: defineMessage({
    id: 'page.magic.description1',
    defaultMessage: `List Your Items: Showcase what you have to offer by creating detailed listings. Add images, descriptions, and your preferred currency.`
  }),
  magicDescription2: defineMessage({
    id: 'page.magic.description2',
    defaultMessage: `Explore the Map: Dive into our interactive map to discover items listed by users near you. The map is a visual representation of a thriving local marketplace.`
  }),
  magicDescription3: defineMessage({
    id: 'page.magic.description3',
    defaultMessage: `Connect and Communicate: Interact with other users directly through our platform. Discuss details, negotiate prices, and establish a connection before meeting up for the transaction.`
  }),
  magicDescription4: defineMessage({
    id: 'page.magic.description4',
    defaultMessage: `Secure Transactions: With The DogeList, security is our priority. Feel confident in your transactions, whether you're using Dogecoin or USD. Our platform is designed to facilitate secure exchanges between buyers and sellers.`
  }),
  joinUsTitle: defineMessage({
    id: 'page.joinUs.title',
    defaultMessage: 'Join us and shape the future'
  }),
  joinUsDescription: defineMessage({
    id: 'page.joinUs.description',
    defaultMessage: `Thank you for joining us on this remarkable journey. With your support, we're transforming Dogecoin from a meme to a mainstream currency, redefining the way the world transacts. Embrace the change. Embrace The DogeList. Let's chart a new course in the world of cryptocurrency together.`
  }),
  copyToClipboard: defineMessage({
    id: 'page.copyClipboard',
    defaultMessage: `Copy to clipboard`
  }),
  dogeWalletModalTitle: defineMessage({
    id: 'page.dogeWalletModalTitle',
    defaultMessage: `Scan with phone`
  }),
  DONATION_SUCCESS_TITLE: defineMessage({
    id: 'page.about.donationSuccessTitle',
    defaultMessage: `Donation success!`
  }),
  DONATION_SUCCESS_DES: defineMessage({
    id: 'page.about.donationSuccessDes',
    defaultMessage: `Hey generous shibes! Thanks for making the platform better for your fellow shibes!🔥`
  })
};
