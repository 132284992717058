import React, { FC } from 'react';
import { ActivityItemPropsType } from '../../model/Activity';
import { Typo } from '../Typo';
import Badge from '../shared/Badge';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Divider from '../shared/Divider';
import ActivityItem from './ActivityItem';

type Props = {
  activityHeader: { title: string; description: string };
  activityData: ActivityItemPropsType[];
};

const ActivityList: FC<Props> = ({ activityHeader, activityData }) => {
  return (
    <div className="w-full md:w-full lg:w-[360px] py-6 px-4 sm:py-6 sm:px-6 bg-gray-50 rounded-2xl">
      <div className="w-full flex justify-start ">
        {activityData?.length > 0 ? (
          <div className="w-full">
            <div className="flex flex-col gap-1 mb-5">
              <div className="flex items-center gap-1">
                <Typo.textLg fontWeight="font-medium" className="text-gray-900">
                  {activityHeader?.title}
                </Typo.textLg>
                <Badge label={activityData?.length} theme="gray" shape="circle" size="sm" />
              </div>
              <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                {activityHeader?.description}
              </Typo.textSm>
            </div>
            <div className="mt-5 flex flex-col">
              {activityData?.map((activityItem, index) => (
                <div key={index}>
                  <Divider />
                  <ActivityItem {...activityItem} />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Typo.textLg fontWeight="font-semibold" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.NOTHING_TO_DISPLAY.id}
              defaultMessage={TRANSLATED_CONSTANTS.NOTHING_TO_DISPLAY.defaultMessage}
            />
          </Typo.textLg>
        )}
      </div>
    </div>
  );
};

export default ActivityList;
