/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useState } from 'react';
import MoreFilter from '../../../components/shared/MoreFilter';
import { CheckboxOption } from '../../../model/CheckboxOption';
import Badge from '../../../components/shared/Badge';
import InputField from '../../../components/shared/InputField';
import SearchIcon from '../../../components/icons/SearchIcon';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import Button from '../../../components/shared/Button';
import { Typo } from '../../../components/Typo';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useAppSelector } from '../../../lib/redux/hooks';
import { addFilter, addFilterModal, addLatLng } from '../../../lib/redux/reducers/filter.slice';
import { useDispatch } from 'react-redux';
import LocationDropdown from '../../../components/shared/location Dropdown/LocationDropdown';
import { LocationBound, LocationType } from '../../../model/Location';
import { useGetFilterData } from '../../../hooks/marketPlace/useGetFilterData';
import MoreFilterCategoryItem from '../../../components/shared/MoreFilterCategoryItem';
import CategoryIcon from '../../../components/icons/CategoryIcon';
import { DEFAULT_LAT_LNG, DEFAULT_LOCATION_BOUNDS } from '../../../constants/data';
import { alertnatePriceOptions, dataTypes, priceType } from '../../../util/MoreFilterUtil';

const Filters = () => {
  const dispatch = useDispatch();

  const intl = useIntl();
  const { categories, itemConditions } = useGetFilterData();
  const filterFromModal = useAppSelector((state) => state.filter.filterModal);
  const defaultSearchText = filterFromModal.searchText || '';
  const allCategories = {
    value: '0',
    label: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
      defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
    })
  };

  const clearFilterState = useAppSelector((state) => state.filter.toBeCleared);
  const [defaultCategory, setDefaultCategory] = useState<CheckboxOption>(
    filterFromModal?.categories?.length == 1 ? filterFromModal?.categories[0] : allCategories
  );
  const [locationData, setLocationData] = useState<LocationType | any>(
    filterFromModal.locationData
  );
  const [locationBounds, setLocationBounds] = useState<LocationBound>(
    filterFromModal.locationBounds
  );
  const [latLng, setLatLng] = useState<any>(DEFAULT_LAT_LNG);
  const [resetState, setOnResetState] = useState({ state: false, key: 0 });
  const [moreFilter, setMoreFilter] = useState<any>();

  const onLocationSelect = (
    locationData: LocationType,
    locationBounds: LocationBound,
    latLng: any
  ) => {
    setLocationData(locationData);
    setLocationBounds(locationBounds);
    setLatLng(latLng);
  };

  useEffect(() => {
    if (clearFilterState) {
      handleReset();
      dispatch(addFilter({ state: false }));
    }
  }, [clearFilterState]);

  //if map moves dropdown location changes to empty location
  useEffect(() => {
    if (filterFromModal.locationBounds !== locationBounds) {
      setLocationData(filterFromModal.locationData);
      setLocationBounds(filterFromModal.locationBounds);
    }
  }, [filterFromModal]);

  const searchFilter = yup.object().shape({
    searchInput: yup.string()
  });
  const { register, reset, handleSubmit } = useForm({
    resolver: yupResolver(searchFilter)
  });

  const handleReset = () => {
    setOnResetState({ state: true, key: Math.random() });
    reset();
    setDefaultCategory(allCategories);
    const latLngData = {
      lat: DEFAULT_LAT_LNG.lat, // Set your default latitude here
      lng: DEFAULT_LAT_LNG.lng, // Set your default longitude here
      zoom: DEFAULT_LAT_LNG.zoom // Set your default zoom level here latLngData
    };
    onLocationSelect(
      {
        id: '',
        address: '',
        latitude: 0,
        longitude: 0,
        countryCode: ''
      },
      DEFAULT_LOCATION_BOUNDS,
      latLngData
    );
    dispatch(
      addFilterModal({
        filterModal: {
          searchText: '',
          categories: null,
          locationData: null,
          locationBounds: DEFAULT_LOCATION_BOUNDS,
          address: ''
        }
      })
    );

    dispatch(
      addLatLng({
        latLng: latLngData
      })
    );
  };

  const onSubmit = (data: any) => {
    const searchText = data?.searchInput;
    handleSearch(searchText, moreFilter);
  };

  const handleSearch = (searchText: string, moreFilter: any) => {
    dispatch(addFilter({ searchText: searchText }));
    dispatch(
      addFilterModal({
        filterModal: {
          moreFilter: moreFilter ?? undefined,
          searchText: searchText ?? '',
          categories: defaultCategory?.value == '0' ? categories : [defaultCategory],
          locationData: locationData,
          locationBounds: locationBounds,
          address: locationData?.address ?? '',
          isFirstTime: false
        }
      })
    );
    dispatch(addLatLng({ latLng }));
  };

  return (
    <div className="mt-8 w-full ">
      <div className="flex gap-3 w-full mb-4">
        <div className="w-full md:flex-1 md:gap-3 flex">
          <div className="w-full md:flex-1">
            <LocationDropdown
              placeholder="City, Neighborhood..."
              location={locationData}
              onLocationSelect={onLocationSelect}></LocationDropdown>
          </div>

          <div className=" w-full flex-1">
            <div className="hidden md:block">
              <MoreFilterCategoryItem
                name="categories"
                prefix={<CategoryIcon></CategoryIcon>}
                placeholder={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.SELECT_CATEGORY.id,
                  defaultMessage: TRANSLATED_CONSTANTS.SELECT_CATEGORY.defaultMessage
                })}
                selectedOption={defaultCategory}
                onChange={setDefaultCategory}
                title={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.CATEGORY.id,
                  defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                })}
                options={categories}
                usedOnFilter={true}></MoreFilterCategoryItem>
            </div>
          </div>
        </div>
        <div className="min-w-[140px] ">
          <MoreFilter
            key={resetState.key}
            resetState={resetState.state}
            type="map"
            listings={dataTypes}
            conditions={itemConditions}
            onFilter={(filter) => {
              setMoreFilter(filter);
              handleSearch(defaultSearchText, filter);
            }}
            priceType={priceType}
            alertnatePriceOptions={alertnatePriceOptions}
            categories={categories}
          />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-3">
          <InputField
            prefix={<SearchIcon className="h-5 w-5 text-gray-500" />}
            name="searchInput"
            label=""
            defaultValue={defaultSearchText}
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.SEARCH_BY_ITEM_NAME.id,
              defaultMessage: TRANSLATED_CONSTANTS.SEARCH_BY_ITEM_NAME.defaultMessage
            })}
            type="text"
            register={register}
          />
          <div className="md:block">
            <div className="flex gap-x-3">
              <Button onClick={handleReset} theme="base" size="lg">
                <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.CLEAR.id}
                    defaultMessage={TRANSLATED_CONSTANTS.CLEAR.defaultMessage}
                  />
                </Typo.textMd>
              </Button>
              <Button type="submit" theme="primary-yellow" size="lg">
                <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.SEARCH.id}
                    defaultMessage={TRANSLATED_CONSTANTS.SEARCH.defaultMessage}
                  />
                </Typo.textMd>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Filters;
