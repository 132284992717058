import { FC, useEffect, useState } from 'react';
import Modal from './Modal';
import googleIcon from '../../assets/googleIcon.svg';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import InputField from '../shared/InputField';
import Button from '../shared/Button';
import { Typo } from '../Typo';
import { useHandleLogin } from '../../hooks/useHandlLogin';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../lib/redux/reducers/modal.slice';
import { ApolloError } from '@apollo/client/errors';
import InlineAlert from '../shared/InlineAlert';
import { ChatState } from '../../context/ChatNotificationProvider';
import { setAlertData, setAlertType } from '../../lib/redux/reducers/alert.slice';
import { ROUTES } from '../../constants/data';
import { useAuth } from '../../context/Authn';
type Props = {
  isOpen: boolean;
  onCloseLoginModal: (arg0: boolean) => void;
};

const LoginModal: FC<Props> = ({ isOpen, onCloseLoginModal }) => {
  const { socket } = ChatState();
  const auth = useAuth();
  const { register, errors, handleSubmit, onSubmit, reset } = useHandleLogin();
  const [submiting, setSubmiting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, SetAlertType] = useState<'default' | 'success' | 'error'>('success');
  const dispatch = useDispatch();
  const onGoogleConfirm = () => {
    window.location.href = `${process.env.REACT_APP_STRAPI_API_URL}/api/connect/google`;
  };

  const intl = useIntl();

  const onChangeType = (type: 'login' | 'signup') => {
    dispatch(showModal(type));
    dispatch(hideModal('login'));
  };

  const onLoging = async (data: any) => {
    try {
      setSubmiting(true);
      const response = await onSubmit(data);
      if (response) {
        const jwtToken = response.data.loginResolver.jwt;
        if (jwtToken) {
          localStorage.setItem('token', jwtToken);
          auth.signin();
          dispatch(hideModal('login'));
          dispatch(setAlertType('success'));
          dispatch(
            setAlertData({
              title: intl.formatMessage(
                {
                  id: TRANSLATED_CONSTANTS.SUCCESS_ALERT_TITLE.id,
                  defaultMessage: TRANSLATED_CONSTANTS.SUCCESS_ALERT_TITLE.defaultMessage
                },
                { name: response.data.loginResolver.user.username }
              ),
              description: intl.formatMessage(
                {
                  id: TRANSLATED_CONSTANTS.SUCCESS_ALERT_DESCRIPTION.id,
                  defaultMessage: TRANSLATED_CONSTANTS.SUCCESS_ALERT_DESCRIPTION.defaultMessage
                },
                { name: response.data.loginResolver.user.username }
              ),
              link: ROUTES.DASHBOARD_ROUTE,
              confirmTitle: intl.formatMessage({
                id: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.id,
                defaultMessage: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.defaultMessage
              }),
              cancelTitle: intl.formatMessage({
                id: TRANSLATED_CONSTANTS.DISMISS.id,
                defaultMessage: TRANSLATED_CONSTANTS.DISMISS.defaultMessage
              }),
              image: ''
            })
          );
          socket.emit('setup', response.data.login.user.id);
          onCloseLoginModal(false);
        }
      }
    } catch (error) {
      setShowAlert(true);
      SetAlertType('error');

      if (error instanceof ApolloError) {
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
    } finally {
      setSubmiting(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      reset(); // Call reset to clear previous errors
    }
  }, [isOpen, reset]);

  return (
    <Modal
      onCancel={() => onCloseLoginModal(false)}
      onGoogleConfirm={onGoogleConfirm}
      isOpen={isOpen}
      onChangeType={onChangeType}
      isConfirm
      confirmText={TRANSLATED_CONSTANTS.LOGIN}
      cancelText={TRANSLATED_CONSTANTS.LOGIN_MODAL_GOOGLE_CONFIRM_BUTTON}
      title={TRANSLATED_CONSTANTS.LOGIN_MODAL_TITLE}
      subTitle={TRANSLATED_CONSTANTS.LOGIN_MODAL_SUBTITLE}
      actionButtonsFlow="vertical"
      theme="primary"
      headerType="center"
      buttonIcon={<img src={googleIcon}></img>}
      containsLogo={true}
      type="login"
      formId="login"
      isSubmiting={submiting}>
      <form id="login" action="#" method="POST" onSubmit={handleSubmit(onLoging)}>
        <InlineAlert
          state={showAlert}
          onChangeState={setShowAlert}
          type={alertType}
          className="mt-4"
          text={alertMessage}></InlineAlert>
        <div className="w-full mt-5">
          <InputField
            name="email"
            label={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.EMAIL.id,
              defaultMessage: TRANSLATED_CONSTANTS.EMAIL.defaultMessage
            })}
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.id,
              defaultMessage: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.defaultMessage
            })}
            type="email"
            register={register}
            errors={errors?.email}
            required={true}
          />
        </div>
        <div className="w-full  mt-4">
          <InputField
            name="password"
            label={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.PASSWORD.id,
              defaultMessage: TRANSLATED_CONSTANTS.PASSWORD.defaultMessage
            })}
            type="password"
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.PASSWORD.id,
              defaultMessage: TRANSLATED_CONSTANTS.PASSWORD.defaultMessage
            })}
            register={register}
            errors={errors?.password}
            required={true}
          />
        </div>
        <div className="flex justify-end w-full items-center mt-5">
          <Button
            onClick={() => {
              dispatch(hideModal('login'));
              dispatch(showModal('forgotPassword'));
            }}
            size="md"
            theme="gray-link">
            <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.FORGOT_PASSWORD.id}
                defaultMessage={TRANSLATED_CONSTANTS.FORGOT_PASSWORD.defaultMessage}
              />
            </Typo.textSm>
          </Button>
        </div>
      </form>
    </Modal>
  );
};

export default LoginModal;
