import React from 'react';
import { Typo } from '../Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Button from './Button';

type Props = {
  onChangeType?: (type: 'login' | 'signup') => void;
};

const LoginSignupLink = ({ onChangeType }: Props) => {
  return (
    <div className="flex gap-1 justify-center  items-center">
      <Typo.textSm fontWeight="font-normal" className="text-gray-600">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.DONT_HAVE_ACCOUNT.id}
          defaultMessage={TRANSLATED_CONSTANTS.DONT_HAVE_ACCOUNT.defaultMessage}
        />
      </Typo.textSm>

      <Button onClick={() => onChangeType && onChangeType('signup')} size="sm" theme="gray-link">
        <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.SIGN_UP.id}
            defaultMessage={TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage}
          />
        </Typo.textSm>
      </Button>
    </div>
  );
};

export default LoginSignupLink;
