import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import ProfileHeader from '../../components/shared/Dashboard Header/ProfileHeader';
import SettingWrapper from '../../components/wrapper/SettingWrapper';
import { AuthUserState } from '../../context/AuthUserProvider';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Typo } from '../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import InputField from '../../components/shared/InputField';
import Button from '../../components/shared/Button';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Country } from '../../model/Location';
import EmailIcon from '../../components/icons/EmailIcon';
import Avatar from '../../components/shared/Avatar';
import User1Icon from '../../components/icons/User1Icon';
import ImageUploader from '../../components/shared/ImageUploader';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import SaveIcon from '../../components/icons/SaveIcon';
import CountryDropdown from '../../components/shared/location Dropdown/CountryDropdown';
import { ACCOUNT_TYPE, ROUTES } from '../../constants/data';
import LanguageProficency from '../../components/shared/LanguageProficency/LanguageProficency';
import { LanguageProficiencyModel } from '../../model/LanguageProficiencyModel';
import { ImageFile } from '../../model/Image';
import { useUpdateUser } from '../../hooks/user/useUpdateUser';
import { useUploadFiles } from '../../hooks/user/useUploadImages';
import { useCreateLanguageProficiency } from '../../hooks/settings/useCreateLanguage';
import { useUpdateLanguage } from '../../hooks/settings/useUpdateLanguage';
import { getCurrentDate } from '../../util/CurrentDate';
import { useGetLanguages } from '../../hooks/settings/useGetLanguages';
import { useDeleteLanguageProficiency } from '../../hooks/settings/useDeleteLanguageProficiency';
import { useCheckUsername } from '../../hooks/user/useCheckUsername';
import { handleValidationMessage } from '../../util/ListingBadgeHandler';
import { useDeactiveUser } from '../../hooks/profile/useDeactiveUser';
import RedActionModal from '../../components/modal/RedActionModal';
import UserXIcon from '../../components/icons/UserXIcon';
const Profile = () => {
  const userState = AuthUserState();
  const uploadFiles = useUploadFiles();
  const user = userState?.authUser;
  const intl = useIntl();

  const schema = yup.object().shape({
    name: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().required()
  });

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [proficiencyData, setProficiencyData] = useState<LanguageProficiencyModel[]>([]);
  const [headerFile, setHeaderFile] = useState<ImageFile[]>([]);
  const [profileFile, setProfileFile] = useState<ImageFile>();
  const [country, setCountry] = useState<Country>();
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);
  const updateLanguage = useUpdateLanguage();
  const deleteLanguageProficiency = useDeleteLanguageProficiency();
  const { languages } = useGetLanguages(false);
  const createLanguageProficiency = useCreateLanguageProficiency();
  const updateUser = useUpdateUser();
  const validationMessages = handleValidationMessage(intl);
  const { refetch } = useCheckUsername('', user?.id);
  const deactiveUser = useDeactiveUser();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors
  } = useForm<any>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange'
  });

  const currentLanguages = user?.language_proficiencies?.map((proficiency: any) => proficiency);
  const onUpdateLanguages = async () => {
    proficiencyData?.map(async (newLang: LanguageProficiencyModel) => {
      const existItem = currentLanguages?.filter(
        (currentLan: any) => currentLan?.language?.id == newLang?.language?.id
      );
      if (existItem && existItem?.length > 0) {
        if (newLang?.level?.title != existItem[0]?.level) {
          await updateLanguage({
            variables: {
              data: { language: Number(newLang?.language?.id), level: newLang?.level?.title },
              id: existItem[0]?.id
            }
          });
        }
      } else if (existItem?.length == 0) {
        await createLanguageProficiency({
          variables: {
            data: {
              level: newLang?.level?.title,
              language: Number(newLang?.language?.id),
              users_permissions_user: user?.id,
              publishedAt: getCurrentDate()
            }
          }
        });
      }
    });

    currentLanguages?.map(async (current: any) => {
      const existItem = proficiencyData?.filter(
        (currentLan: LanguageProficiencyModel) => currentLan?.language?.id == current?.language?.id
      );
      if (existItem?.length == 0) {
        await deleteLanguageProficiency({ variables: { id: current?.id } });
      }
    });
  };

  const onSubmit = async (data: any) => {
    setIsSubmiting(true);
    await updateUser({
      variables: {
        data: {
          name: data?.name,
          username: data?.username,
          email: data?.email,
          countryCode: country?.countryCode || '',
          countryName: country?.countryName || ''
        },
        id: user?.id
      }
    }).then(async (response: any) => {
      if (response) {
        if (profileFile) {
          if (profileFile?.file instanceof File) {
            await uploadFiles.onUpload(
              [profileFile],
              'plugin::users-permissions.user',
              user?.id,
              'profileImage'
            );
            if (user?.profileImage?.id) {
              await uploadFiles?.onDelete([
                { id: user?.profileImage?.id, file: user?.profileImage?.url }
              ]);
            }
          }
        } else if (!profileFile && user?.profileImage?.id) {
          await uploadFiles?.onDelete([
            { id: user?.profileImage?.id, file: user?.profileImage?.url }
          ]);
        }

        if (headerFile && headerFile?.length > 0) {
          if (headerFile[0]?.file instanceof File) {
            await uploadFiles.onUpload(
              headerFile,
              'plugin::users-permissions.user',
              user?.id,
              'headerImage'
            );
            if (user?.headerImage?.id) {
              await uploadFiles?.onDelete([
                { id: user?.headerImage?.id, file: user?.headerImage?.url }
              ]);
            }
          }
        } else if (headerFile?.length == 0 && user?.headerImage?.id) {
          await uploadFiles?.onDelete([
            { id: user?.headerImage?.id, file: user?.headerImage?.url }
          ]);
        }

        await onUpdateLanguages().then(() => {
          // window.location.href = `${process.env.REACT_APP_BASE_URL}${ROUTES.PROFILE}`;
          window.location.reload();
        });
        setIsSubmiting(false);
      }
    });
  };

  const onCancel = async () => {
    reset();
    // navigate(ROUTES.DASHBOARD_ROUTE);
  };

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const newFiles = { id: 1, file: acceptedFiles[0] };
    setProfileFile(newFiles);
  }, []);

  const onCloseDeactivateModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowDeactivateModal(false);
      return;
    }
    await deactiveUser({ variables: { id: user.id } })
      .then(() => {
        localStorage.removeItem('token');
        window.location.href = `${ROUTES.HOME_ROUTE}`;
      })
      .catch((error: any) => {
        console.log(error);
      });
    setShowDeactivateModal(false);
  };
  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      'image/png': ['.png', '.jpg', '.jpeg', '.svg']
    },
    maxFiles: 1,
    multiple: false,
    noClick: true,
    noKeyboard: true
  };

  const { getRootProps, getInputProps, open } = useDropzone(dropzoneOptions);

  useEffect(() => {
    if (user) {
      const selectedLanguages = user?.language_proficiencies?.map((proficiency: any) => ({
        id: proficiency?.id,
        language: { id: proficiency?.language?.id, title: proficiency?.language?.title },
        level: { id: proficiency?.level, title: proficiency?.level }
      }));
      setProficiencyData(selectedLanguages || []);
      setValue('name', user?.name);
      setValue('username', user?.username);
      setValue('email', user?.email);
      setCountry({ countryName: user?.countryName, countryCode: user?.countryCode });
      setProfileFile({ id: user?.profileImage?.id, file: user?.profileImage?.url });
      if (user?.headerImage?.id) {
        setHeaderFile([{ id: user?.headerImage?.id, file: user?.headerImage?.url }]);
      }
    }
  }, [user]);

  const onChangeUsername = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      refetch({ username: e.target.value, id: user.id }).then((response) => {
        if (response?.data?.usersPermissionsUsers?.data?.length > 0) {
          setError('username', {
            message: validationMessages['username_taken']
          });
        } else {
          clearErrors('username');
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <div>
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
            <DashboardHeader
              title={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.PROFILE_SETTINGS.id,
                defaultMessage: TRANSLATED_CONSTANTS.PROFILE_SETTINGS.defaultMessage
              })}
              description={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.UPLOAD_PHOTO_AND_PERSONAL_DETAILS.id,
                defaultMessage:
                  TRANSLATED_CONSTANTS.UPLOAD_PHOTO_AND_PERSONAL_DETAILS.defaultMessage
              })}
              accountType={user?.type}
            />
          </div>
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto ">
            <ProfileHeader
              title={user?.name}
              showBreadCrumb={false}
              onDeactive={() => setShowDeactivateModal(true)}
              user={user}></ProfileHeader>
          </div>
          <form id="add-location" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
            <div className="md:max-w-[640px] lg:max-w-[1064px] mx-auto lg:px-8">
              <div className="px-4 divide-y divide-gray-200">
                {user?.type == ACCOUNT_TYPE.PERSONAL && (
                  <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                    <div className="pt-8 flex flex-col md:col-span-4 lg:col-span-1 w-full lg:grid lg:grid-cols-4 lg:gap-x-8 pb-5 space-y-5 lg:space-y-0">
                      <div className=" lg:col-span-1">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.PROFILE_SETTINGS.id}
                            defaultMessage={TRANSLATED_CONSTANTS.PROFILE_SETTINGS.defaultMessage}
                          />
                        </Typo.textSm>
                        <Typo.textSm
                          fontWeight="font-normal"
                          className="text-gray-500 max-w-[280px]">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.UPLOAD_PHOTO_AND_PERSONAL_DETAILS.id}
                            defaultMessage={
                              TRANSLATED_CONSTANTS.UPLOAD_PHOTO_AND_PERSONAL_DETAILS.defaultMessage
                            }
                          />
                        </Typo.textSm>
                      </div>
                    </div>
                  </div>
                )}
                {user?.type == ACCOUNT_TYPE.PERSONAL && (
                  <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                    <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.NAME.id,
                          defaultMessage: TRANSLATED_CONSTANTS.NAME.defaultMessage
                        })}
                      </Typo.textSm>
                      <div className="lg:col-span-3">
                        <InputField
                          name="name"
                          type="text"
                          placeholder={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ENTER_YOUR_NAME.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ENTER_YOUR_NAME.defaultMessage
                          })}
                          register={register}
                          errors={errors.name}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.USERNAME.id,
                        defaultMessage: TRANSLATED_CONSTANTS.USERNAME.defaultMessage
                      })}
                    </Typo.textSm>
                    <div className="lg:col-span-3">
                      <InputField
                        name="username"
                        type="text"
                        onChange={onChangeUsername}
                        prefixHasBorder={true}
                        prefix={`@${user?.username}`}
                        placeholder={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ENTER_YOUR_ITEMS_NAME.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ENTER_YOUR_ITEMS_NAME.defaultMessage
                        })}
                        register={register}
                        errors={errors.username}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.EMAIL.id,
                        defaultMessage: TRANSLATED_CONSTANTS.EMAIL.defaultMessage
                      })}
                    </Typo.textSm>
                    <div className="lg:col-span-3">
                      <InputField
                        name="email"
                        type="text"
                        prefix={<EmailIcon className=" text-gray-400 h-5 w-5" />}
                        placeholder="you@example.com"
                        register={register}
                        errors={errors.email}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.COUNTRY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.COUNTRY.defaultMessage
                      })}
                    </Typo.textSm>
                    <div className="lg:col-span-3">
                      <CountryDropdown
                        country={country}
                        onSelectCountry={setCountry}
                        placeholder="Country"></CountryDropdown>
                    </div>
                  </div>
                </div>
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0 ">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.LANGUAGE_PROFICIENCY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.LANGUAGE_PROFICIENCY.defaultMessage
                      })}
                    </Typo.textSm>
                    <div className=" lg:col-span-3 space-y-5">
                      <LanguageProficency
                        defaultLanguages={currentLanguages}
                        selectedLanagues={proficiencyData}
                        languages={languages}
                        onChange={setProficiencyData}
                      />
                    </div>
                  </div>
                </div>
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                    <div className=" lg:col-span-2">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.YOUR_PHOTO.id}
                          defaultMessage={TRANSLATED_CONSTANTS.YOUR_PHOTO.defaultMessage}
                        />
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.DISPLAYED_ON_YOUR_PROFILE.id}
                          defaultMessage={
                            TRANSLATED_CONSTANTS.DISPLAYED_ON_YOUR_PROFILE.defaultMessage
                          }
                        />
                      </Typo.textSm>
                      <Typo.textXs fontWeight="font-normal" className="text-gray-400">
                        SVG, PNG, JPG (max. 800x400px)
                      </Typo.textXs>
                    </div>
                    <div className=" lg:col-span-3">
                      <div className=" flex justify-between items-start">
                        <Avatar
                          size="2xl"
                          disabled={true}
                          image={
                            profileFile?.file instanceof File
                              ? URL.createObjectURL(profileFile.file)
                              : profileFile?.file
                          }
                          icon={!profileFile?.file ? <User1Icon className="" /> : ''}
                        />
                        <div className="flex gap-x-4">
                          <Button
                            onClick={() => setProfileFile(undefined)}
                            size="md"
                            theme="gray-link"
                            className="">
                            <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.DELETE.id}
                                defaultMessage={TRANSLATED_CONSTANTS.DELETE.defaultMessage}
                              />
                            </Typo.textSm>
                          </Button>

                          <div
                            {...getRootProps({
                              className: 'dropzone'
                            })}>
                            <input {...getInputProps()} />
                            <Button onClick={open} size="md" theme="yellow-link" className="">
                              <Typo.textSm
                                fontWeight="font-medium"
                                className="text-yellow-600 underline">
                                <FormattedMessage
                                  id={TRANSLATED_CONSTANTS.UPLOAD_PHOTO.id}
                                  defaultMessage={TRANSLATED_CONSTANTS.UPLOAD_PHOTO.defaultMessage}
                                />
                              </Typo.textSm>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {user?.type == ACCOUNT_TYPE.PERSONAL && (
                  <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                    <div className="py-5 w-full space-y-5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                      <div className=" lg:col-span-2">
                        <Typo.textSm
                          fontWeight="font-medium"
                          className="text-gray-700 lg:col-span-1">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.HEADER_PHOTO.id}
                            defaultMessage={TRANSLATED_CONSTANTS.HEADER_PHOTO.defaultMessage}
                          />
                        </Typo.textSm>
                        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.DISPLAYED_YOUR_DASHBOARD_AND_PROFILE.id}
                            defaultMessage={
                              TRANSLATED_CONSTANTS.DISPLAYED_YOUR_DASHBOARD_AND_PROFILE
                                .defaultMessage
                            }
                          />
                        </Typo.textSm>
                        <Typo.textXs fontWeight="font-normal" className="text-gray-400">
                          SVG, PNG, JPG (max. 1600x480px)
                        </Typo.textXs>
                      </div>
                      <div className=" lg:col-span-3">
                        <ImageUploader images={headerFile} maxImages={1} onChange={setHeaderFile} />
                      </div>
                    </div>
                  </div>
                )}
                <div className="flex justify-end gap-x-3  pt-5">
                  <Button onClick={onCancel} size="lg" theme="base">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CANCEL.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                      })}
                    </Typo.textMd>
                  </Button>
                  <Button
                    size="lg"
                    loading={isSubmiting}
                    type="submit"
                    theme="primary-yellow"
                    icon={<SaveIcon className="h-5 w-4 text-gray-900" />}>
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.SAVE.id,
                        defaultMessage: TRANSLATED_CONSTANTS.SAVE.defaultMessage
                      })}
                    </Typo.textMd>
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </SettingWrapper>
      <RedActionModal
        title={TRANSLATED_CONSTANTS.DEACTIVATE_MODAL_TITLE}
        isOpen={showDeactivateModal}
        onCloseRedActionModal={(value: boolean) => onCloseDeactivateModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DEACTIVATE_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DEACTIVATE_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
      />
    </div>
  );
};

export default Profile;
