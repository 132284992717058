import { gql, useMutation } from '@apollo/client';
import { ListingModel } from '../../model/Listing';
interface ListingInput {
  variables: {
    data: ListingModel;
    id: number;
  };
}

export const ARCHIVE_LISTING = gql`
  mutation UpdateListing($id: ID!, $data: ListingInput!) {
    updateListing(data: $data, id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const useArchiveListing = (): ((updateListing: ListingInput) => any) => {
  const [updateListingResult] = useMutation(ARCHIVE_LISTING);

  return updateListingResult;
};
