import clsx from 'clsx';
import React from 'react';
import { Typo } from '../Typo';

type Props = {
  icon?: any;
  size?: 'sm' | 'md' | 'lg';
  image?: string;
  iconPos?: 'left' | 'right';
  theme?:
    | 'primary'
    | 'error'
    | 'warning'
    | 'success'
    | 'blue-light'
    | 'blue'
    | 'indigo'
    | 'purple'
    | 'pink'
    | 'orange'
    | 'blue-gray'
    | 'gray';
  label?: any;
  hasDot?: boolean;
  className?: string;
  [x: string]: any;
};
const Badge: React.FC<Props> = (props) => {
  const {
    label,
    iconPos = 'left',
    theme = 'gray-light',
    size = 'sm',
    image,
    className,
    icon,
    hasDot = false
  } = props;

  const iconColorMap: { [key: string]: string } = {
    primary: 'text-yellow-500',
    error: 'text-error-500',
    warning: 'text-warning-500',
    success: 'text-success-500',
    'blue-light': 'text-blue-light-500',
    blue: 'text-blue-500',
    indigo: 'text-indigo-500',
    purple: 'text-purple-500',
    pink: 'text-pink-500',
    orange: 'text-orange-500',
    'blue-gray': 'text-blue-gray-500',
    gray: 'text-gray-500'
  };

  return (
    <div
      className={clsx(
        'inline-flex items-center justify-center font-inter text-xs font-medium leading-[18px]',
        {
          'bg-yellow-50 text-yellow-700': theme === 'primary',
          'bg-error-50 text-error-700': theme === 'error',
          'bg-warning-50 text-warning-700': theme === 'warning',
          'bg-success-50 text-success-700 ': theme === 'success',
          'bg-blue-light-50 text-blue-light-700': theme === 'blue-light',
          'bg-blue-50 text-blue-700': theme === 'blue',
          'bg-indigo-50 text-indigo-700': theme === 'indigo',
          'bg-purple-50 text-purple-700': theme === 'purple',
          'bg-pink-50 text-pink-700': theme === 'pink',
          'bg-orange-50 text-orange-700': theme === 'orange',
          'bg-blue-gray-50 text-blue-gray-700': theme === 'blue-gray',
          'bg-gray-100 text-gray-700': theme === 'gray'
        },
        {
          'p-1': size === 'sm' && !label,
          'p-1.5': size === 'md' && !label,
          'p-2': size === 'lg' && !label,
          'px-2 py-0.5': size === 'sm' && label && !icon && !image,
          'px-2.5 py-0.5': size === 'md' && label && !icon && !image,
          'px-3 py-1': size === 'lg' && label && !icon && !image
        },
        image && label && iconPos === 'left'
          ? {
              'py-0.5 pl-[3px] pr-2': size === 'sm',
              'py-0.5 pl-1 pr-2.5': size === 'md',
              'py-1 pl-1.5 pr-3': size === 'lg'
            }
          : '',
        image && label && iconPos === 'right'
          ? {
              'py-0.5 pl-2 pr-[3px]': size === 'sm',
              'py-0.5 pl-2.5 pr-1 ': size === 'md',
              'py-1 pl-3 pr-1.5 ': size === 'lg'
            }
          : '',
        icon && label && iconPos === 'left'
          ? {
              'py-0.5 pl-1.5 pr-2 ': size === 'sm',
              'py-0.5 pl-2 pr-2.5 ': size === 'md',
              'py-1 pl-2.5 pr-3 ': size === 'lg'
            }
          : '',
        icon && label && iconPos === 'right'
          ? {
              'py-0.5 pr-1.5 pl-2 ': size === 'sm',
              'py-0.5 pr-2 pl-2.5 ': size === 'md',
              'py-1 pr-2.5 pl-3 ': size === 'lg'
            }
          : '',
        label ? 'rounded-2xl' : 'rounded-full',
        className
      )}>
      <div
        className={clsx(
          'flex gap-x-1.5 items-center justify-center',
          iconPos === 'left' ? 'flex-row' : 'flex-row-reverse'
        )}>
        {icon && <span className={iconColorMap[theme]}>{icon}</span>}
        {image && (
          <img src={image} className="h-4 w-4 rounded-full object-cover" alt="avatar"></img>
        )}
        {hasDot && (
          <span
            className={clsx('mr-[6px] w-2 h-2 rounded-full', {
              'bg-purple-700': theme === 'purple',
              'bg-blue-700': theme === 'blue'
            })}></span>
        )}
        {label && (
          <span>
            <Typo.textSm fontWeight="font-medium" className=" leading-5 tracking-[0%] ">
              {label}
            </Typo.textSm>
          </span>
        )}
      </div>
    </div>
  );
};

export default Badge;
