/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { NUMBER_OF_RECORDS_PER_TABLE } from '../../../../constants/data';
import EmptyReportListings from '../../EmptyReportListings';
import Loader from '../../../../components/shared/Loader';
import StorefrontsGridLayout from './StorefrontsGridLayout';
import { useGetDeletedStorefronts } from '../../../../hooks/reports/useGetDeletedStorefronts';

const DeletedStorefrontsGridLayout = () => {
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const { deletedStorefronts, deletedStorefrontsCount, loading, error, refetch, fetchMore } =
    useGetDeletedStorefronts(offset, NUMBER_OF_RECORDS_PER_TABLE, searchInput);

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };

  return (
    <div className="w-full">
      {deletedStorefronts?.length === 0 && !loading ? (
        <EmptyReportListings />
      ) : (
        <div className="w-full">
          {loading ? (
            <Loader className="h-[400px] w-full flex justify-center items-center" />
          ) : (
            <StorefrontsGridLayout
              pageType="listing-deleted"
              data={deletedStorefronts}
              listingsCount={deletedStorefrontsCount}
              paginate={paginate}
              currentPage={currentPage}
              refetch={refetch}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DeletedStorefrontsGridLayout;
