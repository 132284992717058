type Props = {
  className?: string;
};
const HelpIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-4 h-4 ' + className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2127_4565)">
        <path
          d="M6.06 6.00001C6.21674 5.55446 6.5261 5.17875 6.93331 4.93943C7.34051 4.70012 7.81927 4.61264 8.28479 4.69248C8.75031 4.77233 9.17255 5.01436 9.47672 5.3757C9.78089 5.73703 9.94737 6.19436 9.94667 6.66668C9.94667 8.00001 7.94667 8.66668 7.94667 8.66668M8 11.3333H8.00667M14.6667 8.00001C14.6667 11.6819 11.6819 14.6667 8 14.6667C4.3181 14.6667 1.33334 11.6819 1.33334 8.00001C1.33334 4.31811 4.3181 1.33334 8 1.33334C11.6819 1.33334 14.6667 4.31811 14.6667 8.00001Z"
          strokeWidth="1.33333"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2127_4565">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpIcon;
