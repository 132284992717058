export const customizeNotifications = (notification: any) => {
  const message = {
    chatId: notification?.chat?.id,
    content: notification.content,
    count: notification.count,
    isRead: notification.isRead,
    dateTime: notification.updatedAt
  };
  return {
    notificationId: Number(notification.id),
    message: message,
    sender: notification.sender,
    receiver: notification.receiver
  };
};
