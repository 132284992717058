import { GET_USER } from './useGetUser';
import { gql, useMutation } from '@apollo/client';

interface PendingRoleInput {
  variables: {
    data: {
      date: string;
      role: string;
      assignee?: number;
      assigner?: number;
      approved: boolean;
      publishedAt: string;
    };
  };
}

export const CREATE_PENDING_ROLE = gql`
  mutation createPendingRole($data: PendingRoleInput!) {
    createPendingRole(data: $data) {
      data {
        id
        attributes {
          role
        }
      }
    }
  }
`;

export const useCreatePendingRole = (): ((createPendingRole: PendingRoleInput) => any) => {
  const [createPendingRoleResult] = useMutation(CREATE_PENDING_ROLE, {
    refetchQueries: [GET_USER, 'GetAccount']
  });
  return createPendingRoleResult;
};
