import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { customizeListings, customizeStorefronts } from '../marketPlace/transform';
import { handleMapPageData } from '../../util/AppUtil';

export const GET_STOREFRONT_LISTINGS = gql`
  query GetMapListings(
    $listingOffset: Int
    $storefrontOffset: Int
    $limit: Int
    $title: String
    $userId: ID
    $storefrontId: ID
  ) {
    storefrontItemsCount: listings(
      filters: {
        and: [
          { type: { eq: "storefrontItem" } }
          { name: { contains: $title } }
          { user: { id: { eq: $userId } } }
          { store_front: { id: { eq: $storefrontId } } }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    listings(
      pagination: { start: $listingOffset, limit: $limit }
      filters: {
        and: [
          { type: { eq: "storefrontItem" } }
          { name: { contains: $title } }
          { user: { id: { eq: $userId } } }
          { store_front: { id: { eq: $storefrontId } } }
        ]
      }
    ) {
      data {
        id
        attributes {
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
              }
            }
          }
          type
          name
          isSold
          description
          location {
            address
            latitude
            longitude
          }
          price {
            alternatePriceOptions
            priceType
            priceInUSD
            priceInDoge
          }
          user {
            data {
              attributes {
                username
              }
            }
          }
          store_front {
            data {
              id
              attributes {
                name
                location {
                  address
                  latitude
                  longitude
                }
              }
            }
          }
          publishedAt
        }
      }
    }
    storeFrontsCount: storeFronts(
      filters: {
        and: [
          { user: { id: { eq: $userId } } }
          { name: { contains: $title } }
          { id: { eq: $storefrontId } }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    storeFronts(
      pagination: { start: $storefrontOffset, limit: $limit }
      filters: {
        and: [
          { user: { id: { eq: $userId } } }
          { name: { contains: $title } }
          { id: { eq: $storefrontId } }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          description
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              attributes {
                username
              }
            }
          }
          location {
            address
            latitude
            longitude
          }
          publishedAt
        }
      }
    }
  }
`;

export const useGetStorefrontListings = (
  listingOffset: number,
  storefrontOffset: number,
  limit: number,
  order: string,
  title?: string,
  userId?: number,
  storefrontId?: number
) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_STOREFRONT_LISTINGS, {
    variables: { listingOffset, storefrontOffset, limit, order, title, userId, storefrontId },
    fetchPolicy: 'cache-and-network'
  });

  const listings = flattenObj(data?.listings);
  const storefronts = flattenObj(data?.storeFronts);
  const customizedListings = customizeListings(listings);
  const customizedStorefronts = customizeStorefronts(storefronts);
  const mapData = handleMapPageData(customizedListings, customizedStorefronts);

  return {
    listings: mapData.listingsData,
    storefrontItemsCount: flattenObj(data?.storefrontItemsCount)?.meta?.pagination?.total,
    storefronts: mapData.storefrontsData,
    storeFrontsCount: flattenObj(data?.storeFrontsCount)?.meta?.pagination?.total,
    fetchMore,
    refetch,
    loading,
    error
  };
};
