import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_LISTING = gql`
  query getListing($id: ID!, $userId: ID, $skip: Boolean!) {
    listing(id: $id) {
      data {
        id
        attributes {
          image(sort: ["createdAt:DESC"]) {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          name
          description
          isSold
          available
          location {
            address
            latitude
            longitude
            state
            country
            city
            street
          }
          slug
          price {
            priceType
            priceInUSD
            priceInDoge
            alternatePriceOptions
          }
          type
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          user {
            data {
              id
              attributes {
                name
                username
                email
                profileImage {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          item_condition {
            data {
              id
              attributes {
                name
              }
            }
          }
          saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          listing_reports(filters: { reporter: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                detail
                claim
                date
              }
            }
          }
          issued_listing @skip(if: $skip) {
            data {
              id
              attributes {
                detail
                claim
                date
                actionType
              }
            }
          }
          store_front {
            data {
              id
              attributes {
                name
                description
                image(sort: ["createdAt:DESC"]) {
                  data {
                    id
                    attributes {
                      url
                      formats
                    }
                  }
                }
                location {
                  id
                  latitude
                  longitude
                  state
                  street
                  address
                  country
                  city
                }
                operationHours {
                  id
                  day
                  opensAt
                  closesAt
                  isOpen
                }
              }
            }
          }
        }
      }
    }
    reportCounts: listingReports(filters: { listing: { id: { eq: $id } } }) @skip(if: $skip) {
      meta {
        pagination {
          total
        }
      }
    }
  }
`;

export const useGetListing = (id: number, userId?: number) => {
  let skip = true;
  if (userId) {
    skip = false;
  }
  const { loading, error, data, refetch } = useQuery(GET_LISTING, {
    fetchPolicy: 'cache-and-network',
    variables: { id: id, userId: userId, skip }
  });
  return {
    listing: flattenObj(data?.listing, true),
    reportCounts: data?.reportCounts?.meta?.pagination?.total || 0,
    loading,
    error,
    refetch
  };
};
