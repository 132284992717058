import { ChangeEvent, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useGeCategories } from '../../hooks/category/useGetCategory';
import { DropdownOption } from '../../model/DropdownOption';
import { useGetItemCondations } from '../../hooks/item-codation/useGetItemCondation';
import { useCreateListing } from '../../hooks/listing/useCreateBusinessListing';
import {
  ACCOUNT_TYPE,
  ALERT_TYPE,
  ALTERNATE_PRICE_OPTION,
  LISTING_TYPES,
  PRICE_OPTIONS,
  ROUTES
} from '../../constants/data';
import { AuthUserState } from '../../context/AuthUserProvider';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import RadioGroupComponent from '../../components/shared/RadioGroupComponent';
import { Radio } from '../../model/Radio';
import TagIcon from '../../components/icons/TagIcon';
import Wanted2Icon from '../../components/icons/Wanted2Icon';
import { Typo } from '../../components/Typo';
import InputField from '../../components/shared/InputField';
import InputDropdown from '../../components/shared/location Dropdown/InputDropdown';
import Dotpoints1Icon from '../../components/icons/Dotpoints1Icon';
import DogeIcon from '../../components/icons/DogeIcon';
import CheckboxField from '../../components/shared/CheckboxField';
import TextAreaField from '../../components/shared/TextAreaField';
import LocationDropdown from '../../components/shared/location Dropdown/LocationDropdown';
import Location from '../../components/shared/map/Location';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Button from '../../components/shared/Button';
import PlusIcon from '../../components/icons/PlusIcon';
import ImageUploader from '../../components/shared/ImageUploader';
import { LocationType } from '../../model/Location';
import { ImageFile } from '../../model/Image';
import { useUploadFiles } from '../../hooks/user/useUploadImages';
import { getCurrentDate } from '../../util/CurrentDate';
import HelpIcon from '../../components/icons/HelpIcon';
import { flattenObj } from '../../util/flattenObj';
import { ApolloError } from '@apollo/client/errors';
import InlineAlert from '../../components/shared/InlineAlert';
import { useGetStorefronts } from '../../hooks/storfront/useGetStorefronts';
import { handleListigTypeContent, handleValidationMessage } from '../../util/ListingBadgeHandler';
import { ConvertToDoge, convertToUsd } from '../../util/GetDoge';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../components/shared/Tooltip';
import { setAlertData, setAlertType } from '../../lib/redux/reducers/alert.slice';
import { useDispatch } from 'react-redux';
import { PriceState } from '../../context/PriceProvider';
import { useAppSelector } from '../../lib/redux/hooks';
const CreateListing = () => {
  const auth = AuthUserState();
  const user = auth.authUser;
  const createListing = useCreateListing();
  const uploadFiles = useUploadFiles();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { categories } = useGeCategories();
  const { condations } = useGetItemCondations();
  const { storeFronts } = useGetStorefronts(user?.id, -1, 0);
  const createListingType = useAppSelector((state) => state.dashboard.createListingType);
  const [selectedCategory, setSelectedCategory] = useState<DropdownOption>();
  const [selectedStorefront, setSelectedStorefront] = useState<DropdownOption>();
  const [selectedCondation, setSelectedCondation] = useState<DropdownOption>();
  const [coords, setCoords] = useState<number[]>([51.505, -0.09]);
  const [files, setFiles] = useState<ImageFile[]>([]);
  const [priceType, setPriceType] = useState<string>('');
  const [priceOption, setPriceOption] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [imageRequired, setImageRequired] = useState(false);
  const [alert, setAlert] = useState<'default' | 'success' | 'error'>('success');
  const dispatch = useDispatch();
  const intl = useIntl();
  const navigate = useNavigate();
  const validationMessages = handleValidationMessage(intl);
  const priceState = PriceState();

  const wantedListingType = handleListigTypeContent(LISTING_TYPES.BUYING, intl);
  const sellingListingType = handleListigTypeContent(
    user?.type == ACCOUNT_TYPE.PERSONAL ? LISTING_TYPES.SELLING : LISTING_TYPES.STOREFRONT_ITEM,
    intl
  );
  const listingTypes: Radio[] = [
    {
      id: 1,
      type: sellingListingType.type,
      icon: <TagIcon />,
      description: sellingListingType.description,
      title: sellingListingType.title,
      message: ''
    },
    {
      id: 2,
      type: wantedListingType.type,
      icon: <Wanted2Icon />,
      description: wantedListingType.description,
      title: wantedListingType.title,
      message: ''
    }
  ];
  const [selectedListing, setSelectedListing] = useState<Radio>(
    createListingType === LISTING_TYPES.SELLING ? listingTypes[0] : listingTypes[1]
  );

  useEffect(() => {
    if (categories && categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);
  useEffect(() => {
    if (condations && condations.length > 0 && !selectedCondation) {
      setSelectedCondation(condations[0]);
    }
  }, [condations]);

  useEffect(() => {
    if (
      storeFronts &&
      storeFronts.length > 0 &&
      !selectedStorefront &&
      user?.type === ACCOUNT_TYPE.BUSINESS
    ) {
      setSelectedStorefront(storeFronts[0]);
    }
  }, [storeFronts]);

  const listingSchema = yup.object().shape({
    name: yup.string().required(),
    priceInDoge:
      priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE
        ? yup.string()
        : yup
            .number()
            .typeError(validationMessages['must_be_number'])
            .required(validationMessages['required_field']),
    priceInUSD:
      priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE
        ? yup.string()
        : yup
            .number()
            .typeError(validationMessages['must_be_number'])
            .required(validationMessages['required_field']),
    description: yup.string().required(),
    latitude: yup.number().typeError(validationMessages['must_be_number']),
    longitude: yup.number().typeError(validationMessages['must_be_number']),
    city: yup.string().required(),
    state: yup.string().required(),
    street: yup.string(),
    country: yup.string().required()
  });

  const storefrontSchema = yup.object().shape({
    name: yup.string().required(),
    priceInDoge:
      priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE
        ? yup.string()
        : yup
            .number()
            .typeError(validationMessages['must_be_number'])
            .required(validationMessages['required_field']),
    priceInUSD:
      priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE
        ? yup.string()
        : yup
            .number()
            .typeError(validationMessages['must_be_number'])
            .required(validationMessages['required_field']),
    description: yup.string().required()
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    clearErrors
  } = useForm<any>({
    resolver: yupResolver(user?.type == ACCOUNT_TYPE.PERSONAL ? listingSchema : storefrontSchema),
    reValidateMode: 'onChange'
  });

  const onCancel = async () => {
    reset();
    navigate(ROUTES.DASHBOARD_ROUTE);
  };

  useEffect(() => {
    if (user?.location) {
      onPlaceSelected(user?.location);
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      if (files.length > 0 || selectedListing.id === listingTypes[1].id) {
        setIsSubmiting(true);
        const response = await createListing({
          variables: {
            data: {
              image: [],
              type:
                selectedListing?.id == 1
                  ? user.type == ACCOUNT_TYPE.PERSONAL
                    ? LISTING_TYPES.SELLING
                    : LISTING_TYPES.STOREFRONT_ITEM
                  : LISTING_TYPES.BUYING,
              price: {
                priceInUSD:
                  priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE ? null : data?.priceInUSD,
                priceInDoge:
                  priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE ? null : data?.priceInDoge,
                alternatePriceOptions: priceOption !== '' ? priceOption : null,
                priceType: priceType !== '' ? priceType : null
              },
              location: {
                latitude: Number(data?.latitude || 0),
                longitude: Number(data?.longitude || 0),
                address: '',
                city: data?.city || '',
                state: data?.state || '',
                country: data?.country || '',
                street: data?.street
              },
              name: data.name,
              description: data.description,
              item_condition: Number(selectedCondation?.id) || 0,
              category: Number(selectedCategory?.id) || 0,
              isSold: false,
              store_front: Number(selectedStorefront?.id) || undefined,
              user: user?.id,
              publishedAt: getCurrentDate()
            }
          }
        });
        if (response) {
          if (selectedListing.id !== listingTypes[1].id) {
            const listingId = flattenObj(response?.data)?.createListing?.id;
            await uploadFiles.onUpload(files, 'api::listing.listing', listingId, 'image');
            dispatch(setAlertType(ALERT_TYPE.SUCCESS));
            dispatch(
              setAlertData({
                title: intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.LISTING_CREATED_TITLE.id,
                  defaultMessage: TRANSLATED_CONSTANTS.LISTING_CREATED_TITLE.defaultMessage
                }),
                description: intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.LISTING_CREATED_DESCRIPTION.id,
                  defaultMessage: TRANSLATED_CONSTANTS.LISTING_CREATED_DESCRIPTION.defaultMessage
                }),
                confirmTitle: 'View listing',
                cancelTitle: '',
                image: '',
                link: `${ROUTES.LISTING_DETAILS_ROUTE}/${listingId}`
              })
            );
          }
          navigate(ROUTES.DASHBOARD_ROUTE);
        }
      } else {
        setImageRequired(true);
      }
    } catch (error) {
      setAlert('error');
      if (error instanceof ApolloError) {
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
      setShowAlert(true);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onPlaceSelected = (place: LocationType) => {
    setCoords([place.latitude, place.longitude]);
    if (user?.type == ACCOUNT_TYPE.PERSONAL) {
      setValue('street', place?.street || '', { shouldValidate: true });
      setValue('city', place.city || '', { shouldValidate: true });
      setValue('country', place.country || '', {
        shouldValidate: true
      });
      setValue('state', place?.state || '', { shouldValidate: true });
      setValue('latitude', place.latitude || null, { shouldValidate: true });
      setValue('longitude', place.longitude || null, { shouldValidate: true });
    }
  };

  const onChangePriceOption = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.checked) {
      setPriceOption(e.target?.checked && e.target.value);
      if (e.target.value === ALTERNATE_PRICE_OPTION.NEGOTIABLE) {
        setPriceType('');
      }
    } else {
      setPriceOption('');
    }
  };

  const onChangePriceType = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target?.checked) {
      setPriceType(e.target?.checked && e.target.value);
    } else {
      setPriceType('priceType');
    }
  };

  const convertUsdToDoge = async (e: ChangeEvent<HTMLInputElement>) => {
    const doge = e?.target ? ConvertToDoge(Number(e?.target?.value), priceState?.dogePrice) : 0;
    setValue('priceInDoge', doge);
    await trigger('priceInDoge');
    if (doge && doge != null) {
      clearErrors('priceInUSD');
    }
  };

  const convertDogeToUsd = async (e: ChangeEvent<HTMLInputElement>) => {
    const usd = e?.target ? convertToUsd(Number(e?.target?.value), priceState?.dogePrice) : 0;
    setValue('priceInUSD', usd);
    await trigger('priceInUSD');
    if (usd && usd != null) {
      clearErrors('priceInDoge');
    }
  };

  return (
    <>
      <form id="add-location" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
        <div className="pt-16 pb-24 md:max-w-[640px] lg:max-w-[1064px] mx-auto">
          <DashboardHeader
            title={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
            })}
            cancelButtonTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CANCEL.id,
              defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
            })}
            onCancelClick={onCancel}
            confirmButtonTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
            })}
            confirmType="submit"
            confirmButtonIcon={<PlusIcon />}
            description={
              user?.type == ACCOUNT_TYPE.PERSONAL
                ? intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.ACTIVE_LISTING_MESSAGE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.ACTIVE_LISTINGS.defaultMessage
                  })
                : intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.ADD_LISTING_TO_STOREFRONT.id,
                    defaultMessage: TRANSLATED_CONSTANTS.ADD_LISTING_TO_STOREFRONT.defaultMessage
                  })
            }></DashboardHeader>
          <InlineAlert
            state={showAlert}
            onChangeState={setShowAlert}
            type={alert}
            className="my-6"
            text={alertMessage}></InlineAlert>
          <div className="px-4 divide-y divide-gray-200">
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="pt-8 flex flex-col md:col-span-4 lg:col-span-1 w-full lg:grid lg:grid-cols-4 lg:gap-x-8  pb-5 space-y-5 lg:space-y-0">
                <div className=" lg:col-span-1">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_YOUR_ITEM.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_YOUR_ITEM.defaultMessage
                    })}{' '}
                    *
                  </Typo.textSm>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500 max-w-[280px]">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ADD_UPTO_FOUR_PHOTOS.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ADD_UPTO_FOUR_PHOTOS.defaultMessage
                    })}
                  </Typo.textSm>
                  {imageRequired && files.length == 0 ? (
                    <Typo.textSm fontWeight="font-normal" className="text-error-600">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.IMAGES_ARE_REQUIRED.id,
                        defaultMessage: TRANSLATED_CONSTANTS.IMAGES_ARE_REQUIRED.defaultMessage
                      })}
                    </Typo.textSm>
                  ) : (
                    ''
                  )}
                </div>
                <ImageUploader images={[]} className=" lg:col-span-2" onChange={setFiles} />
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1 ">
              <div className="py-5 flex flex-col md:col-span-4 lg:col-span-1 w-full lg:grid lg:grid-cols-4 lg:gap-x-8 space-y-5 lg:space-y-0">
                <div className="flex w-full flex-col lg:col-span-1">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.LISTING_TYPE.id,
                      defaultMessage: TRANSLATED_CONSTANTS.LISTING_TYPE.defaultMessage
                    })}
                    *
                  </Typo.textSm>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.CHOOSE_LISTING_TYPE.id,
                      defaultMessage: TRANSLATED_CONSTANTS.CHOOSE_LISTING_TYPE.defaultMessage
                    })}
                  </Typo.textSm>
                </div>
                <div className=" lg:col-span-2 ">
                  <RadioGroupComponent
                    options={listingTypes}
                    selectedOption={selectedListing}
                    className="w-full"
                    onChange={(value: any) => {
                      setSelectedListing(value);
                      value.id === listingTypes[1].id
                        ? setImageRequired(false)
                        : setImageRequired(true);
                    }}></RadioGroupComponent>
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.ENTER_NAME_OF_YOUR_ITEM.id,
                    defaultMessage: TRANSLATED_CONSTANTS.ENTER_NAME_OF_YOUR_ITEM.defaultMessage
                  })}{' '}
                  *
                </Typo.textSm>
                <div className="lg:col-span-2">
                  <InputField
                    name="name"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ENTER_YOUR_ITEMS_NAME.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ENTER_YOUR_ITEMS_NAME.defaultMessage
                    })}
                    register={register}
                    errors={errors.name}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.SELECT_YOUR_ITEM_CATEGORY.id,
                    defaultMessage: TRANSLATED_CONSTANTS.SELECT_YOUR_ITEM_CATEGORY.defaultMessage
                  })}
                  *
                </Typo.textSm>
                <div className=" lg:col-span-2">
                  <InputDropdown
                    prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                    onChange={setSelectedCategory}
                    defaultValue={selectedCategory}
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.SELECT_A_CATEGORY.id,
                      defaultMessage: TRANSLATED_CONSTANTS.SELECT_A_CATEGORY.defaultMessage
                    })}
                    options={categories}></InputDropdown>
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.ITEM_CONDITION.id,
                    defaultMessage: TRANSLATED_CONSTANTS.ITEM_CONDITION.defaultMessage
                  })}
                  *
                </Typo.textSm>
                <div className=" lg:col-span-2">
                  <InputDropdown
                    prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                    onChange={setSelectedCondation}
                    defaultValue={selectedCondation}
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.GOOD_CONDITION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.GOOD_CONDITION.defaultMessage
                    })}
                    options={condations}></InputDropdown>
                </div>
              </div>
            </div>
            {user?.type == ACCOUNT_TYPE.BUSINESS ? (
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                  <div className="flex flex-col lg:col-span-1">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CHOOSE_A_STOREFRONT.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CHOOSE_A_STOREFRONT.defaultMessage
                      })}
                      *
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.ADD_ITEM_TO_STOREFRONT.id,
                        defaultMessage: TRANSLATED_CONSTANTS.ADD_ITEM_TO_STOREFRONT.defaultMessage
                      })}
                    </Typo.textSm>
                  </div>
                  <div className=" lg:col-span-2">
                    <InputDropdown
                      prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                      onChange={setSelectedStorefront}
                      defaultValue={selectedStorefront}
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.GOOD_CONDITION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.GOOD_CONDITION.defaultMessage
                      })}
                      options={storeFronts?.map((storeFront: any) => ({
                        id: storeFront?.id,
                        title: storeFront?.name
                      }))}></InputDropdown>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full md:col-span-4 lg:col-span-1 ">
                <div className=" w-full space-y-1.5 flex flex-col lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0 ">
                  <div className="flex flex-col lg:col-span-1">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {selectedListing?.id == 1
                        ? intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.SELLING_PRICE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.SELLING_PRICE.defaultMessage
                          })
                        : intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.WANTED_PRICE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.WANTED_PRICE.defaultMessage
                          })}
                      *
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                      {selectedListing?.id == 1
                        ? intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.WHAT_IS_YOUR_ITEMS_PRICE.id,
                            defaultMessage:
                              TRANSLATED_CONSTANTS.WHAT_IS_YOUR_ITEMS_PRICE.defaultMessage
                          })
                        : intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.HOW_MUCH_DO_YOU_WANT_PAY.id,
                            defaultMessage:
                              TRANSLATED_CONSTANTS.HOW_MUCH_DO_YOU_WANT_PAY.defaultMessage
                          })}
                    </Typo.textSm>
                  </div>
                  <div className="flex mt-5 flex-col lg:col-span-2">
                    <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-x-3">
                      <InputField
                        name="priceInUSD"
                        prefix="$"
                        type="text"
                        placeholder="1,000.00"
                        suffix="USD"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          convertUsdToDoge(e);
                          setPriceOption('');
                        }}
                        register={register}
                        errors={errors.priceInUSD}
                      />
                      <InputField
                        name="priceInDoge"
                        prefix={<DogeIcon className="text-gray-400 h-4" />}
                        type="text"
                        placeholder="1,000.00"
                        suffix="DOGE"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          convertDogeToUsd(e);
                          setPriceOption('');
                        }}
                        register={register}
                        errors={errors.priceInDoge}
                      />
                    </div>
                    <div>
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 my-5">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ALTERNATE_PRICE_OPTION.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ALTERNATE_PRICE_OPTION.defaultMessage
                        })}
                      </Typo.textSm>
                      <div className=" space-y-4">
                        <CheckboxField
                          id="1"
                          type="checkbox"
                          shape="square"
                          name="alternatePriceOptions"
                          value={ALTERNATE_PRICE_OPTION.DOGE_DOGE}
                          onChange={onChangePriceOption}
                          isGroupItem={true}
                          errors=""
                          disabled={priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE}
                          isSelected={priceOption == ALTERNATE_PRICE_OPTION.DOGE_DOGE}
                          label={
                            <div className="flex items-center gap-x-2">
                              <span>
                                {intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.DOGE_DOGE.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.DOGE_DOGE.defaultMessage
                                })}
                              </span>
                              <Tooltip
                                title={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.DOGE_DOGE.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.DOGE_DOGE.defaultMessage
                                })}
                                supportingTitle={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.DOGE_DOGE_DESCRIPTION.id,
                                  defaultMessage:
                                    TRANSLATED_CONSTANTS.DOGE_DOGE_DESCRIPTION.defaultMessage
                                })}
                                arrow="bottom-center">
                                <HelpIcon className="text-gray-400" />
                              </Tooltip>
                            </div>
                          }
                          register={() => ({})}></CheckboxField>

                        <CheckboxField
                          id="1"
                          type="checkbox"
                          shape="square"
                          name="alternatePriceOptions"
                          value={ALTERNATE_PRICE_OPTION.NEGOTIABLE}
                          label={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage
                          })}
                          isGroupItem={true}
                          onChange={onChangePriceOption}
                          isSelected={priceOption == ALTERNATE_PRICE_OPTION.NEGOTIABLE}
                          errors=""
                          register={() => ({})}></CheckboxField>
                      </div>
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 my-5">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.PRICE_TYPE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.PRICE_TYPE.defaultMessage
                        })}
                      </Typo.textSm>
                      <div className=" space-y-4">
                        <CheckboxField
                          id="2"
                          type="checkbox"
                          shape="square"
                          name="priceType"
                          value={PRICE_OPTIONS.PRICE_FIRM}
                          isGroupItem={true}
                          isSelected={priceType == PRICE_OPTIONS.PRICE_FIRM}
                          disabled={priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE}
                          label={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.PRIME_IS_FIRM.id,
                            defaultMessage: TRANSLATED_CONSTANTS.PRIME_IS_FIRM.defaultMessage
                          })}
                          onChange={onChangePriceType}
                          errors=""
                          register={() => ({})}></CheckboxField>
                        <CheckboxField
                          id="3"
                          type="checkbox"
                          shape="square"
                          name="priceType"
                          value={PRICE_OPTIONS.OPEN_FOR_OFFERS}
                          disabled={priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE}
                          isGroupItem={true}
                          onChange={onChangePriceType}
                          isSelected={priceType == PRICE_OPTIONS.OPEN_FOR_OFFERS}
                          label={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.OPEN_FOR_OFFERS.id,
                            defaultMessage: TRANSLATED_CONSTANTS.OPEN_FOR_OFFERS.defaultMessage
                          })}
                          register={() => ({})}
                          errors=""></CheckboxField>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.DESCRIPTION.id,
                    defaultMessage: TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage
                  })}
                  *
                </Typo.textSm>
                <div className=" lg:col-span-2">
                  <TextAreaField
                    name="description"
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ENTER_DESCRIPTION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ENTER_DESCRIPTION.defaultMessage
                    })}
                    register={register}
                    errors={errors.description}
                  />
                </div>
              </div>
            </div>
            {user?.type == ACCOUNT_TYPE.PERSONAL ? (
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col  md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                  <div className=" lg:col-span-1">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.MEETUP_LOCATION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.MEETUP_LOCATION.defaultMessage
                      })}
                      *
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.ADD_MEETUP_LOCATION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.ADD_MEETUP_LOCATION.defaultMessage
                      })}
                    </Typo.textSm>
                  </div>
                  <div className=" flex flex-col lg:col-span-2 w-full">
                    <LocationDropdown
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.LOCATION_PLACEHOLDER.id,
                        defaultMessage: TRANSLATED_CONSTANTS.LOCATION_PLACEHOLDER.defaultMessage
                      })}
                      className="mt-5"
                      onLocationSelect={onPlaceSelected}></LocationDropdown>
                    <div className="w-full">
                      <Location coords={coords} className=" min-w-full mt-3 h-[200px]" />
                    </div>
                    <div>
                      <Typo.textXl fontWeight="font-medium" className="my-8 text-gray-900">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.id,
                          defaultMessage: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.defaultMessage
                        })}
                      </Typo.textXl>

                      <div className="overflow-visible sm:rounded-md space-y-5 ">
                        <div className="grid grid-cols-2 gap-x-3">
                          <InputField
                            name="latitude"
                            label={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.LATITUDE.id,
                              defaultMessage: TRANSLATED_CONSTANTS.LATITUDE.defaultMessage
                            })}
                            placeholder="35.8973748"
                            register={register}
                            type="text"
                            errors={errors.latitude}
                          />
                          <InputField
                            name="longitude"
                            label={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.LONGITUDE.id,
                              defaultMessage: TRANSLATED_CONSTANTS.LONGITUDE.defaultMessage
                            })}
                            placeholder="-119.887349"
                            register={register}
                            type="text"
                            errors={errors.longitude}
                          />
                        </div>
                        <div className="w-full">
                          <InputField
                            name="street"
                            label={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.STREET.id,
                              defaultMessage: TRANSLATED_CONSTANTS.STREET.defaultMessage
                            })}
                            placeholder={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.ENTER_A_STREET.id,
                              defaultMessage: TRANSLATED_CONSTANTS.ENTER_A_STREET.defaultMessage
                            })}
                            helpIcon={true}
                            register={register}
                            type="text"
                            errors={errors.street}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-x-3">
                          <InputField
                            name="city"
                            label={`${intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.CITY.id,
                              defaultMessage: TRANSLATED_CONSTANTS.CITY.defaultMessage
                            })}*`}
                            placeholder="San Diego"
                            register={register}
                            type="text"
                            errors={errors.city}
                          />
                          <InputField
                            name="state"
                            label={`${intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.id,
                              defaultMessage: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.defaultMessage
                            })}*`}
                            placeholder="California"
                            register={register}
                            type="text"
                            errors={errors.state}
                          />
                        </div>

                        <div className="w-full">
                          <InputField
                            name="country"
                            label={`${intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.COUNTRY.id,
                              defaultMessage: TRANSLATED_CONSTANTS.COUNTRY.defaultMessage
                            })}*`}
                            placeholder="United States"
                            register={register}
                            type="text"
                            errors={errors.country}
                          />
                        </div>
                        <Typo.textSm fontWeight="font-normal" className="w-full text-gray-800">
                          *
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
                            defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
                          })}
                        </Typo.textSm>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className="flex justify-end gap-x-3  pt-5">
              <Button onClick={onCancel} size="lg" theme="base">
                <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.CANCEL.id,
                    defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                  })}
                </Typo.textMd>
              </Button>
              <Button
                size="lg"
                loading={isSubmiting}
                type="submit"
                theme="primary-yellow"
                icon={<PlusIcon />}>
                <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
                    defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
                  })}
                </Typo.textMd>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateListing;
