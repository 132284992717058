type Props = {
  className?: string;
};
const Featured2Icon = ({ className }: Props) => {
  return (
    <>
      <svg
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#FEF2CD" />
        <path
          d="M24.5 24H24.51M18.5 21.2L18.5 25.6745C18.5 26.1637 18.5 26.4083 18.5553 26.6385C18.6043 26.8425 18.6851 27.0376 18.7947 27.2166C18.9184 27.4184 19.0914 27.5914 19.4373 27.9373L27.1059 35.6059C28.2939 36.7939 28.888 37.388 29.5729 37.6105C30.1755 37.8063 30.8245 37.8063 31.427 37.6105C32.112 37.388 32.7061 36.7939 33.8941 35.6059L36.1059 33.3941C37.2939 32.2061 37.888 31.612 38.1105 30.927C38.3063 30.3245 38.3063 29.6755 38.1105 29.0729C37.888 28.388 37.2939 27.7939 36.1059 26.6059L28.4373 18.9373C28.0914 18.5914 27.9184 18.4184 27.7166 18.2947C27.5376 18.1851 27.3425 18.1043 27.1385 18.0553C26.9083 18 26.6637 18 26.1745 18L21.7 18C20.5799 18 20.0198 18 19.592 18.218C19.2157 18.4097 18.9097 18.7157 18.718 19.092C18.5 19.5198 18.5 20.0799 18.5 21.2ZM25 24C25 24.2761 24.7761 24.5 24.5 24.5C24.2239 24.5 24 24.2761 24 24C24 23.7239 24.2239 23.5 24.5 23.5C24.7761 23.5 25 23.7239 25 24Z"
          stroke="#B08503"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#FFF9EB" strokeWidth="8" />
      </svg>
    </>
  );
};

export default Featured2Icon;
