import React, { FC } from 'react';
import Avatar from './Avatar';
import { Typo } from '../Typo';
import User1Icon from '../icons/User1Icon';

type Props = {
  profileImage: string;
  name: string;
  username: string;
  className: string;
  isOnline: boolean;
  openProfile?: () => void;
};

const AccountInfo: FC<Props> = ({
  className,
  profileImage,
  name,
  username,
  isOnline,
  openProfile
}) => {
  return (
    <div onClick={openProfile} className={className}>
      <Avatar
        image={profileImage ? profileImage : undefined}
        icon={!profileImage ? <User1Icon className="text-gray-500" /> : undefined}
        showState={true}
        isOnline={isOnline}
        size="lg"
      />
      <div className="">
        <Typo.textLg fontWeight="font-semibold" className="text-gray-700">
          {name}
        </Typo.textLg>
        <Typo.textMd fontWeight="font-normal" className="text-gray-600">
          {username ? `@${username}` : ''}
        </Typo.textMd>
      </div>
    </div>
  );
};

export default AccountInfo;
