import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { transformListingsReports } from './transform';

export const GET_LISTING_DETAILED_REPORTS = gql`
  query getListingReports($offset: Int, $limit: Int, $searchText: String) {
    listingReportsCount: listingsWithListingReports(searchText: $searchText) {
      meta {
        pagination {
          total
        }
      }
    }
    listingsWithListingReports(
      pagination: { limit: $limit, start: $offset }
      searchText: $searchText
    ) {
      data {
        id
        attributes {
          name
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
              }
            }
          }
          type
          description
          price {
            alternatePriceOptions
            priceType
            priceInUSD
            priceInDoge
          }
          user {
            data {
              id
              attributes {
                username
                name
                type
                email
                createdAt
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                listings(filters: { listing_reports: { id: { ne: null } } }) {
                  data {
                    attributes {
                      listing_reports {
                        data {
                          id
                          attributes {
                            reporter {
                              data {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          store_front {
            data {
              id
              attributes {
                name
                location {
                  address
                  latitude
                  longitude
                }
              }
            }
          }
          listing_reports {
            data {
              id
              attributes {
                claim
                detail
                date
                reporter {
                  data {
                    id
                    attributes {
                      name
                      username
                      type
                      email
                      profileImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetListingDetailedReports = (offset: number, limit: number, searchText: string) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_LISTING_DETAILED_REPORTS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  const flattenData = flattenObj(data?.listingsWithListingReports);
  const formattedReports = transformListingsReports(flattenData ? flattenData[0] : undefined);

  return {
    listingReport: formattedReports,
    listingReportsCount: data?.listingReportsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
