import React, { useState } from 'react';
import SlideScreen from './SlideScreen';
import { useIntl } from 'react-intl';
import { AuthUserState } from '../../../context/AuthUserProvider';
import { ListingNew } from '../../../model/Listing';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { LISTING_BREAKPOINT, LIST_PAGES } from '../../../constants/data';
import Loader from '../../shared/Loader';
import { useHandleSave } from '../../../hooks/useHandleSave';
import { useGetListings } from '../../../hooks/listing/useGetListings';
import ListingCard from '../../shared/ListingCard/ListingCard';
type Props = {
  open: boolean;
  onClose: () => void;
  className?: string;
  profileId: number;
};

const ListingSlideScreen: React.FC<Props> = (props) => {
  const { open, onClose, profileId } = props;
  const LISTING_ITEMS = 5;
  const intl = useIntl();
  const { me } = AuthUserState();
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(LISTING_ITEMS);
  const { listings, pagination, fetchMore, refetch } = useGetListings(
    me?.id,
    profileId,
    0,
    perPage
  );
  const { save } = useHandleSave(refetch);

  const fetchMoreListing = async () => {
    const newOffset = offset + LISTING_ITEMS;
    const newPerPage = perPage + LISTING_ITEMS;
    await fetchMore({
      variables: { offset: newOffset, limit: LISTING_ITEMS }
    });
    setOffset(newOffset);
    setPerPage(newPerPage);
  };

  return (
    <SlideScreen
      title={intl.formatMessage({
        id: TRANSLATED_CONSTANTS.RECENT_LISTINGS.id,
        defaultMessage: TRANSLATED_CONSTANTS.RECENT_LISTINGS.defaultMessage
      })}
      className=" w-[350px] sm:w-[552px]"
      open={open}
      setOpen={onClose}>
      <div id="listingContent" className=" h-screen overflow-y-scroll px-6 pb-24 space-y-4">
        <div>
          <div className=" space-y-4 pt-6">
            <InfiniteScroll
              dataLength={pagination?.total}
              next={fetchMoreListing}
              hasMore={pagination?.total > offset} // You should implement a condition to stop when all data is fetched
              scrollableTarget="listingContent"
              className="space-y-4"
              loader={<Loader className="h-20 mt-6" />}>
              {listings &&
                listings?.map((listing: ListingNew) => {
                  return (
                    <ListingCard
                      onLike={save}
                      cardClickable={true}
                      size={LISTING_BREAKPOINT.SLIDE}
                      page={LIST_PAGES.MAP}
                      key={listing.id}
                      listing={listing}
                    />
                  );
                })}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </SlideScreen>
  );
};

export default ListingSlideScreen;
