import clsx from 'clsx';
import { User } from '../../../model/UserModel';
import { FC } from 'react';
import Avatar from '../Avatar';
import User1Icon from '../../icons/User1Icon';
import { Typo } from '../../Typo';

type UserProps = { user: User; clickUser: (user: User) => void; clickedUser: User };
const UserInfo: FC<UserProps> = ({ user, clickUser, clickedUser }) => {
  return (
    <div
      className={clsx(
        'flex h-10 pl-2.5 pr-2 gap-2 rounded-lg items-center cursor-pointer focus:bg-gray-50 hover:bg-gray-50 ',
        clickedUser?.id === user?.id ? 'bg-gray-50' : ''
      )}
      onClick={() => clickUser(user)}>
      <Avatar
        icon={!user.profileImage?.url && <User1Icon className=" text-gray-600" />}
        image={user.profileImage?.url}
        showState={false}
        size="xs"
      />
      <div className="flex gap-x-2 whitespace-nowrap overflow-x-hidden text-ellipsis">
        <Typo.textSm fontWeight="font-medium" className="text-gray-900 truncate">
          {user?.name}
        </Typo.textSm>
        <Typo.textSm fontWeight="font-normal" className="text-gray-600 truncate">
          @{user?.username}
        </Typo.textSm>
      </div>
    </div>
  );
};

export default UserInfo;
