import React, { ChangeEvent, useEffect, useState } from 'react';
import CheckboxField from './CheckboxField';
import { CheckboxOption } from '../../model/CheckboxOption';
import SearchIcon from '../icons/SearchIcon';
import InputField from './InputField';
import Button from './Button';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { Typo } from '../Typo';

type Props = {
  title?: string;
  options: Array<CheckboxOption>;
  hasSearch?: boolean;
  placeholder?: string;
  selectedOptions?: Array<CheckboxOption>;
  onChange?: (option: Array<CheckboxOption>) => void;
  name: string;
  size?: 'md' | 'sm';
  [x: string]: any;
};

const MoreFiltetItem: React.FC<Props> = (props) => {
  const {
    title,
    options,
    onChange,
    name,
    size = 'sm',
    hasSearch = false,
    selectedOptions,
    placeholder = ''
  } = props;
  const [query, setQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState<CheckboxOption[]>([]);
  const [showItems, setShowItems] = useState(5);
  const intl = useIntl();
  const checkboxOptions =
    query === ''
      ? options
      : options.filter((option: CheckboxOption) =>
          option?.label
            ?.toString()
            .toLowerCase()
            ?.replace(/\s+/g, '')
            .includes(query.toLowerCase()?.replace(/\s+/g, ''))
        );

  const onChangeOption = (e: any) => {
    let tempItems: CheckboxOption[] = [];
    if (e.target.checked) {
      checkboxOptions?.map((option) => {
        if (option.value == e.target.value) {
          tempItems = [...selectedItems, option];
          setSelectedItems(tempItems);
        }
      });
    } else {
      tempItems = selectedItems.filter((option) => option.value != e.target.value);
      setSelectedItems(tempItems);
    }
    onChange && onChange(tempItems);
  };

  useEffect(() => {
    if (selectedOptions) {
      setSelectedItems(selectedOptions);
    }
  }, [selectedOptions]);

  return (
    <div className="w-full p-6 space-y-4">
      <Typo.textSm fontWeight="font-medium" className="text-gray-700">
        {title}
      </Typo.textSm>
      {hasSearch && (
        <InputField
          name="search"
          className="mt-4 mb-5"
          prefix={<SearchIcon></SearchIcon>}
          placeholder={placeholder}
          defaultValue={query}
          onChange={(event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value)}
          register={() => void {}}></InputField>
      )}
      {checkboxOptions?.slice(0, showItems)?.map((option, index: number) => {
        return (
          <CheckboxField
            key={`${name}-${index}`}
            id={`${name}-${index}`}
            size={size}
            name={name}
            isSelected={selectedItems.some((item) => item.value === option.value)}
            onChange={onChangeOption}
            value={option.value}
            label={<span>{option.label}</span>}
            description={option.description}
            register={() => void {}}></CheckboxField>
        );
      })}
      {checkboxOptions?.length > showItems && (
        <Button
          onClick={() => setShowItems(showItems + 10)}
          size="md"
          className=" underline"
          theme="yellow-link">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.SHOW_10_MORE.id,
            defaultMessage: TRANSLATED_CONSTANTS.SHOW_10_MORE.defaultMessage
          })}
        </Button>
      )}
    </div>
  );
};

export default MoreFiltetItem;
