import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForgotPassword } from './user/useForgotPassword';

export const useForgotPasswordHandler = () => {
  const forgotPassword = useForgotPassword();

  const schema = yup.object().shape({
    email: yup.string().required().email()
  });

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    const forgotPasswordResponse = await forgotPassword({
      variables: {
        email: data.email
      }
    });
    return forgotPasswordResponse;
  };
  return { register, onSubmit, handleSubmit, errors };
};
