import React from 'react';
import { DropdownOption } from '../../../model/DropdownOption';
import Avatar from './../Avatar';
import OnlineIndicator from './../OnlineIndicator';
import CheckIcon from '../../icons/CheckIcon';
import { Typo } from '../../Typo';
type Props = {
  selected?: boolean;
  value: DropdownOption | undefined;
  iconType?: 'none' | 'image' | 'icon' | 'onlineIndicatro';
  [x: string]: any;
};

type LabelProps = {
  placeholder?: string;
  prefix?: any;
  value: DropdownOption | undefined;
  iconType?: 'none' | 'image' | 'icon' | 'onlineIndicatro';
};
const InputDropdownOption: React.FC<Props> = (props) => {
  const { value, iconType, selected } = props;

  return (
    <div
      className={`flex justify-between h-full py-2.5 px-3.5 hover:bg-gray-100 ${
        selected ? ' bg-gray-100' : ' bg-white'
      } `}>
      <div className=" flex gap-x-2 text-md font-medium items-center justify-center">
        {iconType === 'image' && value?.imageUrl && (
          <Avatar size="xs" image={value?.imageUrl}></Avatar>
        )}
        {iconType === 'icon' && value?.icon && <span>{value.icon}</span>}
        {iconType === 'onlineIndicatro' && (
          <OnlineIndicator userId={Number(value?.id)}></OnlineIndicator>
        )}
        <Typo.textMd fontWeight="font-medium" className="text-gray-900">
          {value?.title}
        </Typo.textMd>
        {value?.supportingTitle && (
          <Typo.textMd fontWeight="font-normal" className="text-gray-500">
            {value.supportingTitle}
          </Typo.textMd>
        )}
      </div>
      {selected && (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-blue-gray-600">
          <CheckIcon></CheckIcon>
        </span>
      )}
    </div>
  );
};

export default InputDropdownOption;

export const InputDropdownLabel: React.FC<LabelProps> = (props) => {
  const { value, iconType, placeholder, prefix } = props;

  return (
    <div className=" flex justify-between">
      <div className=" flex gap-x-2 text-md items-center justify-center">
        {((prefix && iconType === 'none') || (prefix && iconType !== 'none' && !value)) && prefix}
        {iconType === 'image' && value?.imageUrl && (
          <Avatar size="xs" image={value?.imageUrl}></Avatar>
        )}
        {iconType === 'icon' && value?.icon && <span>{value.icon}</span>}
        {iconType === 'onlineIndicatro' && value && (
          <OnlineIndicator userId={Number(value?.id)}></OnlineIndicator>
        )}
        {value ? (
          <Typo.textMd fontWeight="font-medium" className="flex gap-x-2 text-gray-900">
            {value?.title}
            {value?.supportingTitle && (
              <span className=" text-gray-500 font-normal">{value?.supportingTitle}</span>
            )}
          </Typo.textMd>
        ) : (
          <Typo.textMd fontWeight="font-normal" className="text-gray-500">
            {placeholder}
          </Typo.textMd>
        )}
      </div>
    </div>
  );
};
