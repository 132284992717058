import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ROLE_TYPES } from '../../constants/data';
import SettingNavItem from '../shared/SettingNavItem';
import { useLocation } from 'react-router-dom';
import { Typo } from '../Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useGetUserRole } from '../../hooks/useRole';
import { useCheckUserPendingRole } from '../../hooks/settings/useCheckUserPendingRole';
import { AuthUserState } from '../../context/AuthUserProvider';
import { settingsMenu } from '../../util/SettingsUtil';

type Props = {
  children: React.ReactElement;
  className?: string;
};

const SettingWrapper: FC<Props> = ({ children }) => {
  const location = useLocation();
  const intl = useIntl();
  const userRole = useGetUserRole();
  const user = AuthUserState();
  const { pendingRoles } = useCheckUserPendingRole(
    [ROLE_TYPES.ADMIN, ROLE_TYPES.ADMIN],
    user?.authUser?.id
  );
  const settingItems = settingsMenu(intl, pendingRoles);
  const filteredSettingItems =
    user?.authUser?.type === 'business'
      ? settingItems.filter((item) => item.id !== 6)
      : settingItems;
  return (
    <div className="flex gap-x-16 max-w-[1320px] mx-auto">
      <div className="bg-red-500 flex-nowrap min-w-[230px] hidden lg:block space-y-1">
        <Typo.textSm fontWeight="font-medium" className="text-gray-600 mb-4">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.SETTINGS.id}
            defaultMessage={TRANSLATED_CONSTANTS.SETTINGS.defaultMessage}
          />
        </Typo.textSm>
        {filteredSettingItems?.map((item) => {
          return (
            <SettingNavItem
              className={`${item.roles.includes(userRole || '') ? ' block' : ' hidden'}`}
              key={item.id}
              navigation={item}
              isActive={item.link == location?.pathname}
            />
          );
        })}
      </div>
      <div className="grow">{children}</div>
    </div>
  );
};

export default SettingWrapper;
