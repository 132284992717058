/* eslint-disable @typescript-eslint/no-empty-function */
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import CheckboxField from '../../../components/shared/CheckboxField';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { Typo } from '../../../components/Typo';

type Props = {
  checked: boolean;
  toggleAll: (e: any) => void;
};

const BlockedUserTableHead: FC<Props> = ({ checked, toggleAll }) => {
  return (
    <thead>
      <tr className="bg-gray-50">
        <th scope="col" className="relative px-7  sm:w-12 sm:px-7 py-3 ">
          <div className="absolute left-6 top-1/2 -mt-3.5">
            <CheckboxField
              name="state"
              value="check"
              label=""
              size="md"
              type="checkbox"
              isSelected={checked}
              onChange={(e) => toggleAll(e)}
              roundedSize="rounded-md"
              register={() => {}}
            />
          </div>
        </th>
        <th scope="col" className="min-w-[12rem] py-3 pr-3 text-left">
          <Typo.textSm fontWeight="font-medium" className="  text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.NAME.id}
              defaultMessage={TRANSLATED_CONSTANTS.NAME.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="px-3 py-3 text-left  ">
          <Typo.textSm fontWeight="font-medium" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.USER_TYPE.id}
              defaultMessage={TRANSLATED_CONSTANTS.USER_TYPE.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-3"></th>
      </tr>
    </thead>
  );
};

export default BlockedUserTableHead;
