/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useEffect, useState } from 'react';
import { User } from '../../../model/UserModel';
import { Typo } from '../../../components/Typo';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import InputField from '../../../components/shared/InputField';
import { useForm } from 'react-hook-form';
import MailIcon from '../../../components/icons/MailIcon';
import InputDropdown from '../../../components/shared/location Dropdown/InputDropdown';
import ArrowDownIcon from '../../../components/icons/ArrowDownIcon';
import { DropdownOption } from '../../../model/DropdownOption';
import Button from '../../../components/shared/Button';
import PlusIcon from '../../../components/icons/PlusIcon';
import Divider from '../../../components/shared/Divider';
import RichTextEditor from './RichTextEditor';
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { handleEmailRadioButtonsConstants } from '../../../util/ReportUtil';
import { useSendEmail } from '../../../hooks/reports/useSendEmail';
import { useGetUsersByRole } from '../../../hooks/reports/useGetUsersByRole';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../lib/redux/reducers/modal.slice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import AddRecipientCommandMenu from '../../../components/Command Menus/AddRecipientCommandMenu';
type Props = {
  user: User;
};
const emailTypeOptions: DropdownOption[] = [
  {
    id: 1,
    title: 'Support'
  }
];

const Email: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const email = useAppSelector((state) => state.email.email);
  const radioButtons = handleEmailRadioButtonsConstants(intl);
  const openAddRecipientModal = useAppSelector((state) => state.modal.addEmailRecipient);
  const [selectedEmailType, setSelectedEmailType] = useState<DropdownOption>(emailTypeOptions[0]);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [recipients, setRecipients] = useState([{ id: 1, defaultValue: '' }]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [emailContent, setEmailContent] = useState('');
  const [selectedUsersType, setSelectedUsersType] = useState(radioButtons[0]);
  const { users } = useGetUsersByRole(0, -1, '', selectedUsersType.id);

  // define a custom validator function
  const uniqueEmails = (values: any) => {
    const emailsSet = new Set(values);
    return emailsSet.size === values.length;
  };

  // define a validation schema using yup
  const validationSchema = yup.object().shape({
    email: yup.array().test('unique-emails', 'Emails must be unique', uniqueEmails)
  });
  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors }
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  useEffect(() => {
    const markup = stateToHTML(editorState.getCurrentContent());
    setEmailContent(markup);
  }, [editorState]);
  const sendEmail = useSendEmail();

  const onCancel = async () => {
    reset();
  };

  const onSubmit = async (data: any) => {
    setIsSubmiting(true);
    const response = await sendEmail({
      emails: data.email,
      content: emailContent,
      subject: 'Email from support team'
    });
    if (response) {
      setIsSubmiting(false);
    }
  };
  const onRadioChange = (e: any) => {
    const selectedUser = radioButtons.filter((radioButton) => e.target.id === radioButton.id);
    setSelectedUsersType(selectedUser[0]);
  };

  const onCloseModal = (user: User) => {
    setRecipients([
      ...recipients,
      {
        id: recipients.length + 1,
        defaultValue: user?.email || ''
      }
    ]);
    dispatch(hideModal('addEmailRecipient'));
  };

  return (
    <div className="w-full">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col md:flex-row gap-8 lg:gap-16 justify-between items-start">
          <div className="md:w-[280px]">
            <Typo.textSm fontWeight="font-medium" className="text-gray-600">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.EMAIL_USER.id}
                defaultMessage={TRANSLATED_CONSTANTS.EMAIL_USER.defaultMessage}
              />
            </Typo.textSm>
            <Typo.textSm fontWeight="font-normal" className="text-gray-500">
              <FormattedMessage
                id="page.report.email.email_user_subtitle"
                defaultMessage="You can send an email to a user here."
              />
            </Typo.textSm>
          </div>
          <div className="flex flex-1 items-end w-full">
            <div className="w-full relative  ">
              {recipients.map((recipient, index: number) => (
                <div key={recipient.id} className="flex gap-4 w-full items-center ">
                  <div className="w-full md:w-[464px] ">
                    <InputField
                      defaultValue={email ? email : recipient?.defaultValue}
                      name={`email.${index}`}
                      label={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.EMAIL.id,
                        defaultMessage: TRANSLATED_CONSTANTS.EMAIL.defaultMessage
                      })}
                      prefix={<MailIcon className="w-5 h-5 text-gray-400" />}
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.id,
                        defaultMessage: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.defaultMessage
                      })}
                      onkeydown={() => clearErrors('email')}
                      type="email"
                      register={register}
                      required={false}
                    />
                  </div>
                  <div className="w-full md:w-[160px] md:mt-4">
                    <InputDropdown
                      iconType="none"
                      prefix={<ArrowDownIcon />}
                      onChange={setSelectedEmailType}
                      defaultValue={selectedEmailType}
                      placeholder=""
                      options={emailTypeOptions}></InputDropdown>
                  </div>
                </div>
              ))}
              <Typo.textSm fontWeight="font-normal" className="w-full text-left text-error-500">
                {errors?.email?.root?.message ? `${errors?.email?.root?.message}` : ''}
              </Typo.textSm>

              <div className="flex justify-start">
                <Button
                  theme="gray-link"
                  className="mt-1.5 mb-5"
                  size="sm"
                  onClick={() =>
                    setRecipients([
                      ...recipients,
                      {
                        id: recipients.length + 1,
                        defaultValue: ''
                      }
                    ])
                  }
                  icon={<PlusIcon className="w-5 h-5 text-gray-600" />}>
                  <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
                    <FormattedMessage
                      id="page.report.email.button.add_another"
                      defaultMessage="Add another"
                    />
                  </Typo.textSm>
                </Button>
              </div>
              <div className="max-w-[640px]">
                <Divider />
              </div>
              <div className="w-fit"></div>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 lg:gap-16 justify-between items-start">
          <div className="md:w-[280px]">
            <Typo.textSm fontWeight="font-medium" className="text-gray-600">
              <FormattedMessage
                id="page.report.email.recipients.title"
                defaultMessage="Recipients"
              />
            </Typo.textSm>
            <Typo.textSm fontWeight="font-normal" className="text-gray-500">
              <FormattedMessage
                id="page.report.email.recipients.subtitle"
                defaultMessage="Control who receives this email."
              />
            </Typo.textSm>
          </div>
          <div className="flex-1 w-full mt-6 ">
            <div className="flex flex-wrap gap-4 max-w-[640px] ">
              {radioButtons.map((radioButton) => (
                <div key={radioButton.id} className="flex gap-x-2 items-start w-[300px]">
                  <input
                    checked={radioButton.id === selectedUsersType.id}
                    id={radioButton.id}
                    name={radioButton.name}
                    type="radio"
                    onChange={(e) => onRadioChange(e)}
                    className="h-4 w-4 mt-0.5 border-gray-300 text-yellow-600 focus:ring-yellow-600 cursor-pointer"
                  />
                  <label
                    htmlFor="push-everything"
                    className="block text-sm font-medium leading-6 text-gray-900 items-start">
                    <div>
                      <Typo.textSm fontWeight="font-medium" className="text-gray-500">
                        {radioButton.labelTitle}
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        {radioButton.labelSubtitle}
                      </Typo.textSm>
                    </div>
                  </label>
                </div>
              ))}
            </div>
            <div className="my-6">
              <Divider />
            </div>
            <RichTextEditor editorState={editorState} setEditorState={setEditorState} />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-8 lg:gap-16 justify-between items-start">
          <div className="md:w-[300px]"></div>
          <div className="mt-8 w-full">
            <Divider />
            <div className="flex gap-3 justify-end mt-4">
              <Button onClick={onCancel} theme="base" className="mt-1.5 mb-5" size="md">
                <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.CANCEL.id}
                    defaultMessage={TRANSLATED_CONSTANTS.CANCEL.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
              <Button
                theme="primary-yellow"
                className="mt-1.5 mb-5"
                loading={isSubmiting}
                type="submit"
                size="md"
                icon={<MailIcon className="w-5 h-5 text-gray-900" />}>
                <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.SEND_EMAIL.id}
                    defaultMessage={TRANSLATED_CONSTANTS.SEND_EMAIL.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
            </div>
          </div>
        </div>
      </form>
      {openAddRecipientModal && (
        <AddRecipientCommandMenu
          isOpen={openAddRecipientModal}
          onCloseAddRecipientCommandMenu={onCloseModal}
        />
      )}
    </div>
  );
};

export default Email;
