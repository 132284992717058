import React, { FC } from 'react';
import ListingCard from '../../../components/shared/ListingCard/ListingCard';
import {
  ISSUE_LISTING_ACTION_TYPE,
  LISTING_BREAKPOINT,
  LIST_PAGES,
  REPORT_PAGES
} from '../../../constants/data';
import StorefrontItemCard from '../../../components/shared/ListingCard/StorefrontItemCard';
import { handleFlagsBadge } from '../../../util/ReportUtil';
import Badge from '../../../components/shared/Badge';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { ActivityItemPropsType } from '../../../model/Activity';
import { Typo } from '../../../components/Typo';
import StorefrontCard from '../../../components/shared/ListingCard/StorefrontCard';

type Props = {
  report: any;
  save?: () => void;
  pageType?: string;
  activityItemData: ActivityItemPropsType | any;
  title: React.ReactElement;
  subtitle: React.ReactElement;
};
const DetailedLayoutListingCards: FC<Props> = ({
  report,
  save,
  pageType,
  activityItemData,
  title,
  subtitle
}) => {
  const intl = useIntl();
  return (
    <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
      <div className="w-[280px]">
        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
          {title}
        </Typo.textSm>
        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
          {subtitle}
        </Typo.textSm>
      </div>
      <div className="flex flex-1 w-full flex-col ">
        {report?.type === 'personal' || report?.type === 'wanted' ? (
          <ListingCard
            cardClickable={false}
            listing={report}
            page={LIST_PAGES.MAP}
            size={LISTING_BREAKPOINT.SLIDE}
            onLike={save}></ListingCard>
        ) : report.type === 'storefrontItem' ? (
          <StorefrontItemCard
            cardClickable={false}
            listing={report}
            storeFront={report?.storefront}
            page={LIST_PAGES.MAP}
            size={LISTING_BREAKPOINT.SLIDE}
            onLike={save}></StorefrontItemCard>
        ) : (
          <StorefrontCard
            cardClickable={false}
            storeFront={report}
            page={LIST_PAGES.MAP}
            size={LISTING_BREAKPOINT.SLIDE}
            onLike={save}
          />
        )}
        <div className="flex justify-end mt-4 gap-4">
          <Badge
            theme="error"
            label={handleFlagsBadge(
              report?.listing_reports?.length || report?.storefront_reports?.length || 0,
              intl
            )}
          />
          {pageType === REPORT_PAGES.LISTING_QUARANTINE && (
            <Badge
              theme="gray"
              label={
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.QUARANTINE.id}
                  defaultMessage={TRANSLATED_CONSTANTS.QUARANTINE.defaultMessage}
                />
              }></Badge>
          )}
          {pageType === REPORT_PAGES.LISTING_DELETED_WARNED && (
            <>
              {activityItemData?.type === ISSUE_LISTING_ACTION_TYPE.DELETE_BAN ? (
                <Badge
                  theme="error"
                  label={
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.DELETE_AND_BAN.id}
                      defaultMessage={TRANSLATED_CONSTANTS.DELETE_AND_BAN.defaultMessage}
                    />
                  }></Badge>
              ) : (
                <Badge
                  theme="primary"
                  label={
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.DELETE_AND_WARNING.id}
                      defaultMessage={TRANSLATED_CONSTANTS.DELETE_AND_WARNING.defaultMessage}
                    />
                  }></Badge>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default DetailedLayoutListingCards;
