import React, { useEffect, useState } from 'react';
import LanguageProficencyItem from './LanguageProficencyItem';
import clsx from 'clsx';
import { LanguageProficiencyModel } from '../../../model/LanguageProficiencyModel';
import { DropdownOption } from '../../../model/DropdownOption';
import { languageLevels } from '../../../constants/data';
import Button from '../Button';
import PlusIcon from '../../icons/PlusIcon';
import { Typo } from '../../Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';

type LanguageProficencyProps = {
  defaultLanguages: LanguageProficiencyModel[];
  selectedLanagues: LanguageProficiencyModel[];
  onChange: (value: LanguageProficiencyModel[]) => void;
  className?: string;
  languages: DropdownOption[];
};

const LanguageProficency: React.FC<LanguageProficencyProps> = (props) => {
  const { defaultLanguages, selectedLanagues, onChange, className, languages } = props;
  const [isFetched, setIsFetched] = useState(false);
  const [availableLanguages, setAvailableLanguages] = useState<DropdownOption[]>([]);

  const onChangeItem = (value: LanguageProficiencyModel) => {
    const updatedData = selectedLanagues?.map((item) => {
      if (item?.id === value?.id) {
        setAvailableLanguages(
          availableLanguages?.map((available: any) => {
            if (available?.id == value?.language?.id) {
              return item.language;
            } else {
              return available;
            }
          })
        );
        return value;
      } else {
        return item;
      }
    });
    onChange && onChange(updatedData || []);
  };

  const onAddAnother = () => {
    if (availableLanguages?.length > 0) {
      const newLanguage = availableLanguages[0]; // Get the first available language
      const newProficiency = {
        id: selectedLanagues?.length + 1, // Generate a unique ID (you can adjust this logic)
        language: newLanguage,
        level: languageLevels[0] // Set the default level
      };
      const updatedData = [...selectedLanagues, newProficiency];
      onChange && onChange(updatedData);
      setAvailableLanguages(availableLanguages.filter((lang) => lang?.id !== newLanguage?.id));
    }
  };

  const onDeleteItem = (value: LanguageProficiencyModel) => {
    setAvailableLanguages([...availableLanguages, value.language]);
    const updatedData = selectedLanagues.filter((item) => item.id !== value.id);
    onChange && onChange(updatedData);
  };

  useEffect(() => {
    if (languages && !isFetched && defaultLanguages) {
      setIsFetched(true);
      const availables = languages?.filter((language: DropdownOption) => {
        return !defaultLanguages?.some(
          (LanguageProficency) => language?.id === LanguageProficency?.language?.id
        );
      });
      setAvailableLanguages(availables);
    }
  }, [languages]);

  return (
    <>
      <div
        className={clsx(
          'w-full grid grid-cols-1 items-center justify-center gap-x-12 gap-y-6 mt-6 space-y-6 ',
          className
        )}>
        {selectedLanagues?.map((item) => (
          <LanguageProficencyItem
            key={item?.id}
            defaultValue={item}
            availableLanguages={availableLanguages}
            onChange={onChangeItem}
            onDelete={onDeleteItem}
          />
        ))}
      </div>
      <Button
        onClick={onAddAnother}
        icon={<PlusIcon className="h-5 w-5 text-gray-600" />}
        iconPos="right"
        size="md"
        theme="gray-link">
        <Typo.textSm fontWeight="font-semibold" className="text-gray-600">
          {selectedLanagues?.length > 0 ? (
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ADD_ANOTHER.id}
              defaultMessage={TRANSLATED_CONSTANTS.ADD_ANOTHER.defaultMessage}
            />
          ) : (
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ADD_LANGUAGE_PROFICIENCY.id}
              defaultMessage={TRANSLATED_CONSTANTS.ADD_LANGUAGE_PROFICIENCY.defaultMessage}
            />
          )}
        </Typo.textSm>
      </Button>
    </>
  );
};

export default LanguageProficency;
