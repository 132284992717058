import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as yup from 'yup';
import { useCreateAccount } from './user/userCreateAccount';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';
import generateUniqueId from 'generate-unique-id';
export const useHandleSignup = () => {
  const intl = useIntl();
  const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup
      .string()
      .required(
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.ENTER_A_PASSWORD.id,
          defaultMessage: TRANSLATED_CONSTANTS.ENTER_A_PASSWORD.defaultMessage
        })
      )
      .min(
        8,
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.id,
          defaultMessage: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.defaultMessage
        })
      )
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])/,
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.PASSWORD_VALIDATION_MESSAGE.id,
          defaultMessage: TRANSLATED_CONSTANTS.PASSWORD_VALIDATION_MESSAGE.defaultMessage
        })
      )
  });

  const createAccount = useCreateAccount();
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    localStorage?.removeItem('token');
    const username = generateUniqueId({ length: 5, useLetters: false });
    const response = await createAccount({
      variables: {
        input: { email: data.email, username: 'user' + username, password: data.password }
      }
    });
    return response;
  };

  return { onSubmit, register, handleSubmit, errors };
};
