import { hideSlide } from '../../../lib/redux/reducers/slide.slice';
import { MessageSlide } from '../../../model/Message';
import MessageSlideScreen from './MessageSlideScreen';
import ProfileSlideScreen from './ProfileSlideScreen';
import SlideScreen from './SlideScreen';
import React from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  openMessage: boolean;
  openProfile: boolean;
  onClose: (state: boolean) => void;
  className?: string;
  chatData?: MessageSlide;
};
const MessageProfileSlide: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { openProfile, openMessage, onClose, chatData, className } = props;
  return (
    <SlideScreen className={className} open={openMessage || openProfile} setOpen={onClose}>
      <div className="md:flex">
        <ProfileSlideScreen
          className="h-full"
          open={openProfile}
          onClose={() => {
            if (openMessage && openProfile) {
              dispatch(hideSlide('profile'));
            } else if (openProfile && !openMessage) {
              onClose(true);
            }
          }}
        />
        {chatData?.receiver?.id && chatData?.sender?.id ? (
          <MessageSlideScreen
            className="h-full"
            open={openMessage}
            chatData={chatData}
            onClose={() => {
              if (openMessage && openProfile) {
                dispatch(hideSlide('message'));
              } else if (!openProfile && openMessage) {
                onClose(true);
              }
            }}
          />
        ) : (
          ''
        )}
      </div>
    </SlideScreen>
  );
};

export default MessageProfileSlide;
