import React, { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Image from '../shared/Image';
import banner1 from '../../assets/images/Banner 1.png';
import ProfileAvatar from '../shared/ProfileAvatar';
import { Typo } from '../Typo';
import Badge from '../shared/Badge';
import Button from '../shared/Button';
import { getJoinDate } from '../../util/AppUtil';
import { User } from '../../model/UserModel';
import { useAppSelector } from '../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { setProfileData, showSlide } from '../../lib/redux/reducers/slide.slice';
import { useGetUsersByRole } from '../../hooks/reports/useGetUsersByRole';
import { handleAccountTypeBadge } from '../../util/ListingBadgeHandler';
import UserInfo from '../shared/User/UserInfo';
import CommandMenus from './CommandMenus';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader from '../shared/Loader';

type Props = {
  isOpen: boolean;
  onCloseAddRecipientCommandMenu: (user: User) => void;
};

const AddRecipientCommandMenu: FC<Props> = ({ isOpen, onCloseAddRecipientCommandMenu }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const ITEMS_PER_PAGE = 6;
  const [searchText, setSearchText] = useState('');
  const [offset, setOffset] = useState(0);
  const profileState = useAppSelector((state) => state.slide.profile);
  const { users, userCounts, fetchMore } = useGetUsersByRole(
    0,
    ITEMS_PER_PAGE,
    searchText,
    'all-users'
  );
  const [selectedUser, setSelectedUser] = useState<User>(users && users[0]);

  const search = (value: string) => {
    setSearchText(value);
  };
  const accountType = handleAccountTypeBadge(selectedUser?.type || '', intl);

  const fetchMoreUsers = async () => {
    const newOffset = offset + ITEMS_PER_PAGE;
    await fetchMore({
      variables: { offset: newOffset, limit: ITEMS_PER_PAGE }
    });
    setOffset(newOffset);
  };
  return (
    <CommandMenus
      placeholder={intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_LISTING_SEARCH_PLACEHOLDER.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_SEARCH_PLACEHOLDER.defaultMessage
      })}
      isOpen={isOpen}
      onClose={() => onCloseAddRecipientCommandMenu(selectedUser)}
      onSearch={search}>
      <div className="w-full flex ">
        <div className="w-[280px] min-w-[280px]">
          <div className=" py-4 px-2 flex flex-col justify-start items-start">
            <div className=" mt-[2px] gap-[2px] flex flex-col  overflow-y-auto w-full">
              <InfiniteScroll
                dataLength={userCounts || 0}
                next={fetchMoreUsers}
                hasMore={userCounts > offset}
                className="space-y-1"
                loader={<Loader className="h-20 mt-6" />}>
                {users &&
                  users?.map((user: User) => {
                    return (
                      <UserInfo
                        clickUser={setSelectedUser}
                        clickedUser={selectedUser}
                        key={user.id}
                        user={user}></UserInfo>
                    );
                  })}
              </InfiniteScroll>
            </div>
          </div>
        </div>

        {selectedUser && (
          <div className="w-full flex ">
            <div className="h-full w-[1px] bg-gray-200" />
            <div className="pb-4 w-full">
              <div className="w-full relative ">
                <Image className="w-full h-[88px]" src={banner1}></Image>
                <ProfileAvatar
                  image=""
                  verified={false}
                  verfiedClassName=""
                  className=" w-[72px] h-[72px] absolute left-0 right-0 bottom-0 translate-y-[50%] mx-auto"></ProfileAvatar>
              </div>
              <div className="px-4 gap-2 w-full flex flex-col justify-center items-center mt-[52px]">
                <div className="flex gap-2 w-full justify-center truncate">
                  <Typo.textMd fontWeight="font-medium" className="text-gray-900 truncate">
                    {selectedUser?.name}
                  </Typo.textMd>
                  <Typo.textMd fontWeight="font-normal" className="text-gray-600 truncate">
                    @{selectedUser?.username}
                  </Typo.textMd>
                </div>
                <div className="flex gap-2">
                  <Badge theme={accountType?.theme} label={accountType?.name}></Badge>
                </div>
                <div className="flex gap-2 items-center justify-center">
                  <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.JOINED.id}
                      defaultMessage={TRANSLATED_CONSTANTS.JOINED.defaultMessage}
                    />
                  </Typo.textMd>

                  <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                    {selectedUser?.createdAt &&
                      getJoinDate(selectedUser?.createdAt?.toString() || '', true)}
                  </Typo.textMd>
                </div>
              </div>
              <div className="flex gap-2 items-center justify-center mt-6">
                <Button
                  onClick={() => {
                    if (!profileState) {
                      dispatch(
                        setProfileData({
                          userId: selectedUser.id
                        })
                      );
                      dispatch(showSlide('profile'));
                    }
                  }}
                  size="md"
                  theme="base">
                  <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                      defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
                    />
                  </Typo.textSm>
                </Button>
                <Button
                  onClick={() => onCloseAddRecipientCommandMenu(selectedUser)}
                  size="md"
                  theme="primary-yellow">
                  <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.ADD_USER.id}
                      defaultMessage={TRANSLATED_CONSTANTS.ADD_USER.defaultMessage}
                    />
                  </Typo.textSm>
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </CommandMenus>
  );
};

export default AddRecipientCommandMenu;
