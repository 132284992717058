/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, ReactElement } from 'react';
import DescriptionLabel from './DescriptionLabel';
import { useIntl } from 'react-intl';
import ScalesIcon from '../icons/ScalesIcon';
import CreditCardLockIcon from '../icons/CreditCardLock';
import CurrencyDollarCircleIcon from '../icons/CurrencyDollarCircleIcon';
import AwardIcon from '../icons/AwardIcon';

type OperationPropsType = {
  title?: string;
  className?: string;
};

const PriceOption: FC<OperationPropsType> = ({ title, className }) => {
  const intl = useIntl();

  const typePriceOption: { [key: string]: ReactElement } = {
    'Open for offers': (
      <DescriptionLabel icon={<ScalesIcon className="text-gray-400"></ScalesIcon>}>
        {intl.formatMessage({ id: 'shared.open-for-offers', defaultMessage: 'Open for offers' })}
      </DescriptionLabel>
    ),
    'Price firm': (
      <DescriptionLabel icon={<CreditCardLockIcon className="text-gray-400"></CreditCardLockIcon>}>
        {intl.formatMessage({ id: 'shared.price-firm', defaultMessage: 'Price firm' })}
      </DescriptionLabel>
    ),
    'Standard price': (
      <DescriptionLabel
        icon={<CurrencyDollarCircleIcon className="text-gray-400"></CurrencyDollarCircleIcon>}>
        {intl.formatMessage({ id: 'shared.standard-price', defaultMessage: 'Standard price' })}
      </DescriptionLabel>
    ),
    'Doge = Doge': (
      <DescriptionLabel icon={<AwardIcon className=" text-yellow-600"></AwardIcon>}>
        Doge = Doge
      </DescriptionLabel>
    ),
    Negotiable: (
      <DescriptionLabel
        icon={<CurrencyDollarCircleIcon className="text-gray-400"></CurrencyDollarCircleIcon>}>
        {intl.formatMessage({ id: 'shared.negotiable', defaultMessage: 'Negotiable' })}
      </DescriptionLabel>
    )
  };

  return <div className={className}>{title && typePriceOption[title]}</div>;
};

export default PriceOption;
