/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
type Props = {
  children: React.ReactNode;
  // All other props
  [x: string]: any;
};

const ModalBody: React.FC<Props> = (props) => {
  const { children, ...rest } = props;
  return (
    <div className="" {...rest}>
      {children}
    </div>
  );
};

export default ModalBody;
