import React, { FC } from 'react';
import { MessageNotification } from '../../model/Message';
import { ChatState } from '../../context/ChatNotificationProvider';
import Avatar from '../shared/Avatar';
import { timeAgo } from '../../util/AppUtil';
import { Typo } from '../Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import User1Icon from '../icons/User1Icon';

const NotificationItem: FC<MessageNotification> = ({ sender, receiver, message }) => {
  const { onlineUsers = [] } = ChatState();
  return (
    <div className="w-full h-[72px] justify-start items-start gap-3 inline-flex my-4">
      <div className="flex justify-end items-end">
        <Avatar
          image={sender?.profileImage?.url}
          icon={!sender?.profileImage?.url && <User1Icon className="text-gray-600" />}
          isOnline={onlineUsers[Number(sender?.id)]?.online || false}
          size="lg"
        />
      </div>
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex truncate">
        <div className="self-stretch h-10 flex-col justify-start items-start flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex">
            <Typo.textSm fontWeight="font-semibold" className="text-gray-700  truncate ">
              {sender?.name}
            </Typo.textSm>
            <Typo.textXs fontWeight="font-normal" className="text-gray-600  truncate ">
              @{sender?.username}
            </Typo.textXs>
            <Typo.textXs fontWeight="font-normal" className="text-gray-600 ">
              {timeAgo(message?.dateTime)}
            </Typo.textXs>
          </div>
          <Typo.textSm fontWeight="font-normal" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.SENT_A_MESSAGE.id}
              defaultMessage={TRANSLATED_CONSTANTS.SENT_A_MESSAGE.defaultMessage}
            />
          </Typo.textSm>
        </div>
        <Typo.textSm fontWeight="font-normal" className="self-stretch text-gray-600 truncate w-44">
          {`"${message.content}"`}
        </Typo.textSm>
      </div>
      {!message.isRead && (
        <>
          {message.count && message?.count > 1 ? (
            <div className="w-6 h-[22px] justify-start items-start inline-flex">
              <div className="px-2 py-0.5 bg-emerald-50 rounded-2xl justify-center items-center flex bg-success-50">
                <div className="text-center text-emerald-700 text-xs font-medium leading-[18px]">
                  {message.count}
                </div>
              </div>
            </div>
          ) : (
            <div className="w-2.5 h-2.5 relative">
              <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-success-500 rounded-full" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default NotificationItem;
