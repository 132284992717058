import { useUpdateListing } from './useUpdateListing';

export const useHandleListingAvailabel = () => {
  const updateListing = useUpdateListing();
  const changeAvailable = async (listingId: number, available: boolean) => {
    await updateListing({
      variables: {
        data: {
          available: available
        },
        id: listingId
      }
    });
  };
  return { changeAvailable };
};
