import { gql, useMutation } from '@apollo/client';

interface SavedStorefrontInput {
  variables: {
    id: number;
  };
}

const DELETE_SAVED_STOREFRONT = gql`
  mutation DeleteSavedStorefront($id: ID!) {
    deleteSavedStorefront(id: $id) {
      data {
        id
        attributes {
          __typename
        }
      }
    }
  }
`;

export const useUnSaveStorefront = (): ((unSaveStorefront: SavedStorefrontInput) => any) => {
  const [deleteFavoriteStorefront, { loading, error }] = useMutation(DELETE_SAVED_STOREFRONT, {});
  return deleteFavoriteStorefront;
};
