import { ChangeEvent, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import InputField from '../../components/shared/InputField';
import Button from '../../components/shared/Button';
import TextAreaField from '../../components/shared/TextAreaField';
import { useGeCategories } from '../../hooks/category/useGetCategory';
import { useGetItemCondations } from '../../hooks/item-codation/useGetItemCondation';
import { DropdownOption } from '../../model/DropdownOption';
import { ACCOUNT_TYPE, LISTING_TYPES, ROUTES } from '../../constants/data';
import CheckboxField from '../../components/shared/CheckboxField';
import { AuthUserState } from '../../context/AuthUserProvider';
import Location from '../../components/shared/map/Location';
import { Typo } from '../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import LocationDropdown from '../../components/shared/location Dropdown/LocationDropdown';
import InputDropdown from '../../components/shared/location Dropdown/InputDropdown';
import Dotpoints1Icon from '../../components/icons/Dotpoints1Icon';
import RadioGroupComponent from '../../components/shared/RadioGroupComponent';
import ImageUploader from '../../components/shared/ImageUploader';
import InlineAlert from '../../components/shared/InlineAlert';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import { LocationType } from '../../model/Location';
import { Radio } from '../../model/Radio';
import TagIcon from '../../components/icons/TagIcon';
import { ImageFile } from '../../model/Image';
import { useIntl } from 'react-intl';
import { ApolloError } from '@apollo/client/errors';
import Wanted2Icon from '../../components/icons/Wanted2Icon';
import DogeIcon from '../../components/icons/DogeIcon';
import HelpIcon from '../../components/icons/HelpIcon';
import { useUploadFiles } from '../../hooks/user/useUploadImages';
import { useParams } from 'react-router-dom';
import { useGetListing } from '../../hooks/listing/useGetListing';
import { useUpdateListing } from '../../hooks/listing/useUpdateListing';
import Spinner from '../../components/shared/Spinner';
import { useGetStorefronts } from '../../hooks/storfront/useGetStorefronts';
import { handleListigTypeContent, handleValidationMessage } from '../../util/ListingBadgeHandler';
import { ConvertToDoge, convertToUsd } from '../../util/GetDoge';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../../components/shared/Tooltip';
import RedActionModal from '../../components/modal/RedActionModal';
import TrashIcon from '../../components/icons/TrashIcon';
import { useDeleteListing } from '../../hooks/listing/useDeleteListing';
import { PriceState } from '../../context/PriceProvider';
import { useArchiveListing } from '../../hooks/listing/useArchiveListing';

const EditListing = () => {
  const auth = AuthUserState();
  const user = auth.authUser;
  const uploadFiles = useUploadFiles();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { categories } = useGeCategories();
  const { condations } = useGetItemCondations();
  const [isFetchedListing, setIsFetchedListing] = useState(false);
  const { storeFronts } = useGetStorefronts(user?.id, -1, 0);
  const [selectedCategory, setSelectedCategory] = useState<DropdownOption>();
  const [selectedCondation, setSelectedCondation] = useState<DropdownOption>();
  const [selectedStorefront, setSelectedStorefront] = useState<DropdownOption>();
  const [coords, setCoords] = useState<number[]>([51.505, -0.09]);
  const [files, setFiles] = useState<ImageFile[]>([]);
  const [priceType, setPriceType] = useState<string>('');
  const [priceOption, setPriceOption] = useState<string>('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [imageRequired, setImageRequired] = useState(false);
  const [alertType, setAlertType] = useState<'default' | 'success' | 'error'>('success');
  const intl = useIntl();
  const { id } = useParams();
  const { loading, listing } = useGetListing(Number(id));
  const updateListing = useUpdateListing();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteListing = useArchiveListing();
  const validationMessages = handleValidationMessage(intl);
  const priceState = PriceState();

  const listingSchema = yup.object().shape({
    name: yup.string().required(),
    priceInDoge: yup
      .number()
      .typeError(validationMessages['must_be_number'])
      .required(validationMessages['required_field']),
    priceInUSD: yup
      .number()
      .typeError(validationMessages['must_be_number'])
      .required(validationMessages['required_field']),
    description: yup.string().required(),
    latitude: yup.number().typeError(validationMessages['must_be_number']),
    longitude: yup.number().typeError(validationMessages['must_be_number']),
    city: yup.string().required(),
    state: yup.string().required(),
    street: yup.string().nullable(),
    country: yup.string().required()
  });

  const storefrontSchema = yup.object().shape({
    name: yup.string().required(),
    priceInDoge: yup
      .number()
      .typeError(validationMessages['must_be_number'])
      .required(validationMessages['required_field']),
    priceInUSD: yup
      .number()
      .typeError(validationMessages['must_be_number'])
      .required(validationMessages['required_field']),
    description: yup.string().required()
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    clearErrors
  } = useForm<any>({
    resolver: yupResolver(user?.type == ACCOUNT_TYPE.PERSONAL ? listingSchema : storefrontSchema)
  });

  const onCancel = async () => {
    reset();
    navigate(ROUTES.DASHBOARD_ROUTE);
  };

  const onSubmit = async (data: any) => {
    try {
      if (files?.length > 0) {
        setIsSubmiting(true);
        await updateListing({
          variables: {
            data: {
              type:
                selectedListing?.id == 1
                  ? user?.type == ACCOUNT_TYPE.PERSONAL
                    ? LISTING_TYPES.SELLING
                    : LISTING_TYPES.STOREFRONT_ITEM
                  : LISTING_TYPES.BUYING,
              price: {
                priceInUSD: data?.priceInUSD,
                priceInDoge: data?.priceInDoge,
                alternatePriceOptions: priceOption !== '' ? priceOption : null,
                priceType: priceType !== '' ? priceType : null
              },
              location: {
                latitude: Number(data?.latitude || 0),
                longitude: Number(data?.longitude || 0),
                address: data?.address || '',
                city: data?.city || '',
                state: data?.state || '',
                country: data?.country || '',
                street: data?.street
              },
              name: data?.name,
              description: data.description,
              item_condition: Number(selectedCondation?.id) || 0,
              category: Number(selectedCategory?.id) || 0
            },
            id: Number(id)
          }
        }).then(async (response: any) => {
          if (response) {
            const filesToUpload = files.filter((file) => file.file instanceof File);
            const remainingFiles = files.filter((file) => !(file.file instanceof File));
            const allFiles = listing?.image?.map((image: any) => ({
              id: image.id,
              file: image.url
            }));
            const filesToDelete = allFiles.filter(
              (mainImage: ImageFile) =>
                !remainingFiles.some((remainingFile) => mainImage.id === remainingFile.id)
            );
            if (filesToUpload.length > 0) {
              await uploadFiles.onUpload(
                filesToUpload,
                'api::listing.listing',
                listing.id,
                'image'
              );
            }
            if (filesToDelete.length > 0) {
              await uploadFiles.onDelete(filesToDelete);
            }
            navigate(ROUTES.DASHBOARD_ROUTE);
          }
        });
      } else {
        setImageRequired(true);
      }
    } catch (error) {
      setAlertType('error');
      if (error instanceof ApolloError) {
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
      setShowAlert(true);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onPlaceSelected = (place: LocationType) => {
    setCoords([place.latitude, place.longitude]);
    setValue('street', place?.street || '', { shouldValidate: true });
    setValue('city', place?.city || '', { shouldValidate: true });
    setValue('country', place.country || '', {
      shouldValidate: true
    });
    setValue('state', place?.state || '', { shouldValidate: true });
    setValue('latitude', place.latitude || 0, { shouldValidate: true });
    setValue('longitude', place.longitude || 0, { shouldValidate: true });
  };

  const onChangePriceOption = (e: any) => {
    if (e.target?.checked) {
      setPriceOption(e.target?.checked && e.target.value);
    } else {
      setPriceOption('');
    }
  };

  const onChangePriceType = (e: any) => {
    if (e.target?.checked) {
      setPriceType(e.target?.checked && e.target.value);
    } else {
      setPriceType('');
    }
  };

  const wantedListingType = handleListigTypeContent(LISTING_TYPES.BUYING, intl);
  const sellingListingType = handleListigTypeContent(
    user?.type == ACCOUNT_TYPE.PERSONAL ? LISTING_TYPES.SELLING : LISTING_TYPES.STOREFRONT_ITEM,
    intl
  );
  const listingTypes: Radio[] = [
    {
      id: 1,
      type: sellingListingType.type,
      icon: <TagIcon />,
      description: sellingListingType.description,
      title: sellingListingType.title,
      message: ''
    },
    {
      id: 2,
      type: wantedListingType.type,
      icon: <Wanted2Icon />,
      description: wantedListingType.description,
      title: wantedListingType.title,
      message: ''
    }
  ];

  const onDelete = async (state: boolean) => {
    if (state) {
      await deleteListing({ variables: { id: listing.id, data: { deleted: true } } }).then(
        (response: any) => {
          if (response) {
            navigate(ROUTES.DASHBOARD_ROUTE);
          }
        }
      );
    }
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (listing && !isFetchedListing) {
      setIsFetchedListing(true);
      setFiles(listing?.image?.map((image: any) => ({ id: image.id, file: image.url })));
      setSelectedCategory({ id: listing?.category?.id, title: listing.category?.name });
      setSelectedCondation({
        id: listing?.item_condition?.id,
        title: listing?.item_condition?.name
      });
      user?.type == ACCOUNT_TYPE.BUSINESS
        ? setSelectedStorefront({ id: listing?.store_front?.id, title: listing.store_front?.name })
        : '';
      setSelectedListing(
        listing?.type == LISTING_TYPES.SELLING || listing?.type == LISTING_TYPES.STOREFRONT_ITEM
          ? listingTypes[0]
          : listingTypes[1]
      );
      setPriceType(listing?.price?.priceType);
      setPriceOption(listing?.price?.alternatePriceOptions);
      setValue('name', listing?.name);
      setValue('description', listing?.description);
      setValue('address', listing?.location?.address);
      setValue('city', listing?.location?.city);
      setValue('country', listing?.location?.country);
      setValue('state', listing?.location?.state);
      setValue('street', listing?.location?.street);
      setValue('latitude', listing?.location?.latitude);
      setValue('longitude', listing?.location?.longitude);
      setValue('priceInDoge', listing?.price?.priceInDoge),
        setValue('priceInUSD', listing?.price?.priceInUSD),
        setValue('listingType', listing?.listingType);
      setValue('alternatePriceOptions', [listing?.price?.alternatePriceOptions]);
      setValue('priceType', [listing?.price?.priceType]);
    }
  }, [listing]);

  const [selectedListing, setSelectedListing] = useState<Radio>(listingTypes[0]);

  const convertUsdToDoge = async (e: ChangeEvent<HTMLInputElement>) => {
    const doge = e?.target ? ConvertToDoge(Number(e?.target?.value), priceState?.dogePrice) : 0;
    setValue('priceInDoge', doge);
    await trigger('priceInDoge');
    if (doge && doge != null) {
      clearErrors('priceInUSD');
    }
  };

  const convertDogeToUsd = async (e: ChangeEvent<HTMLInputElement>) => {
    const usd = e?.target ? convertToUsd(Number(e?.target?.value), priceState?.dogePrice) : 0;
    setValue('priceInUSD', usd);
    await trigger('priceInUSD');
    if (usd && usd != null) {
      clearErrors('priceInDoge');
    }
  };

  return (
    <>
      <form id="add-location" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
        {!loading ? (
          <div className="pt-16 pb-24 md:max-w-[640px] lg:max-w-[1064px] mx-auto">
            <DashboardHeader
              title={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.EDIT_LISTING.id,
                defaultMessage: TRANSLATED_CONSTANTS.EDIT_LISTING.defaultMessage
              })}
              cancelButtonTitle={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.CANCEL.id,
                defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
              })}
              onCancelClick={onCancel}
              confirmButtonTitle={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SAVE_CHANGES.id,
                defaultMessage: TRANSLATED_CONSTANTS.SAVE_CHANGES.defaultMessage
              })}
              confirmType="submit"
              description={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.UPDATE_YOUR_LISTING_HERE.id,
                defaultMessage: TRANSLATED_CONSTANTS.UPDATE_YOUR_LISTING_HERE.defaultMessage
              })}
              hasDeleteOption={true}
              onDeleteClick={() => setShowDeleteModal(true)}></DashboardHeader>
            <InlineAlert
              state={showAlert}
              onChangeState={setShowAlert}
              type={alertType}
              className="my-6"
              text={alertMessage}></InlineAlert>
            <div className="px-4 divide-y divide-gray-200">
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="pt-8 flex flex-col md:col-span-4 lg:col-span-1 w-full lg:grid lg:grid-cols-4 lg:gap-x-8  pb-5 space-y-5 lg:space-y-0">
                  <div className=" lg:col-span-1">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_YOUR_ITEM.id,
                        defaultMessage: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_YOUR_ITEM.defaultMessage
                      })}{' '}
                      *
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500 max-w-[280px]">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.ADD_UPTO_FOUR_PHOTOS.id,
                        defaultMessage: TRANSLATED_CONSTANTS.ADD_UPTO_FOUR_PHOTOS.defaultMessage
                      })}
                    </Typo.textSm>
                    {imageRequired && files?.length == 0 ? (
                      <Typo.textSm fontWeight="font-normal" className="text-error-600">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.IMAGES_ARE_REQUIRED.id,
                          defaultMessage: TRANSLATED_CONSTANTS.IMAGES_ARE_REQUIRED.defaultMessage
                        })}
                      </Typo.textSm>
                    ) : (
                      ''
                    )}
                  </div>
                  <ImageUploader images={files} className=" lg:col-span-2" onChange={setFiles} />
                </div>
              </div>
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1 ">
                <div className="py-5 flex flex-col md:col-span-4 lg:col-span-1 w-full lg:grid lg:grid-cols-4 lg:gap-x-8 space-y-5 lg:space-y-0">
                  <div className="flex w-full flex-col lg:col-span-1">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.LISTING_TYPE.id,
                        defaultMessage: TRANSLATED_CONSTANTS.LISTING_TYPE.defaultMessage
                      })}
                      *
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CHOOSE_LISTING_TYPE.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CHOOSE_LISTING_TYPE.defaultMessage
                      })}
                    </Typo.textSm>
                  </div>
                  <div className=" lg:col-span-2 ">
                    <RadioGroupComponent
                      options={listingTypes}
                      selectedOption={selectedListing}
                      className="w-full"
                      onChange={setSelectedListing}></RadioGroupComponent>
                  </div>
                </div>
              </div>
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ENTER_NAME_OF_YOUR_ITEM.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ENTER_NAME_OF_YOUR_ITEM.defaultMessage
                    })}{' '}
                    *
                  </Typo.textSm>
                  <div className="lg:col-span-2">
                    <InputField
                      name="name"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.ENTER_YOUR_ITEMS_NAME.id,
                        defaultMessage: TRANSLATED_CONSTANTS.ENTER_YOUR_ITEMS_NAME.defaultMessage
                      })}
                      register={register}
                      errors={errors.name}
                    />
                  </div>
                </div>
              </div>
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.SELECT_YOUR_ITEM_CATEGORY.id,
                      defaultMessage: TRANSLATED_CONSTANTS.SELECT_YOUR_ITEM_CATEGORY.defaultMessage
                    })}
                    *
                  </Typo.textSm>
                  <div className=" lg:col-span-2">
                    <InputDropdown
                      prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                      onChange={setSelectedCategory}
                      defaultValue={selectedCategory}
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.SELECT_A_CATEGORY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.SELECT_A_CATEGORY.defaultMessage
                      })}
                      options={categories}></InputDropdown>
                  </div>
                </div>
              </div>
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ITEM_CONDITION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ITEM_CONDITION.defaultMessage
                    })}
                    *
                  </Typo.textSm>
                  <div className=" lg:col-span-2">
                    <InputDropdown
                      prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                      onChange={setSelectedCondation}
                      defaultValue={selectedCondation}
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.GOOD_CONDITION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.GOOD_CONDITION.defaultMessage
                      })}
                      options={condations}></InputDropdown>
                  </div>
                </div>
              </div>
              {user?.type == ACCOUNT_TYPE.BUSINESS ? (
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                    <div className="flex flex-col lg:col-span-1">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CHOOSE_A_STOREFRONT.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CHOOSE_A_STOREFRONT.defaultMessage
                        })}
                        *
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ADD_ITEM_TO_STOREFRONT.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ADD_ITEM_TO_STOREFRONT.defaultMessage
                        })}
                      </Typo.textSm>
                    </div>
                    <div className=" lg:col-span-2">
                      <InputDropdown
                        prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                        onChange={setSelectedStorefront}
                        defaultValue={selectedStorefront}
                        placeholder={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.GOOD_CONDITION.id,
                          defaultMessage: TRANSLATED_CONSTANTS.GOOD_CONDITION.defaultMessage
                        })}
                        options={storeFronts?.map((storeFront: any) => ({
                          id: storeFront?.id,
                          title: storeFront?.name
                        }))}></InputDropdown>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full md:col-span-4 lg:col-span-1 ">
                  <div className=" w-full space-y-1.5 flex flex-col lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0 ">
                    <div className="flex flex-col lg:col-span-1">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                        {selectedListing.id == 1
                          ? intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.SELLING_PRICE.id,
                              defaultMessage: TRANSLATED_CONSTANTS.SELLING_PRICE.defaultMessage
                            })
                          : intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.WANTED_PRICE.id,
                              defaultMessage: TRANSLATED_CONSTANTS.WANTED_PRICE.defaultMessage
                            })}
                        *
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        {selectedListing.id == 1
                          ? intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.WHAT_IS_YOUR_ITEMS_PRICE.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.WHAT_IS_YOUR_ITEMS_PRICE.defaultMessage
                            })
                          : intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.HOW_MUCH_DO_YOU_WANT_PAY.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.HOW_MUCH_DO_YOU_WANT_PAY.defaultMessage
                            })}
                      </Typo.textSm>
                    </div>
                    <div className="flex mt-5 flex-col lg:col-span-2">
                      <div className="flex flex-col sm:flex-row gap-y-3 sm:gap-x-3">
                        <InputField
                          name="priceInUSD"
                          prefix="$"
                          type="text"
                          placeholder="1,000.00"
                          suffix="USD"
                          onChange={convertUsdToDoge}
                          register={register}
                          errors={errors.priceInUSD}
                        />
                        <InputField
                          name="priceInDoge"
                          prefix={<DogeIcon className="text-gray-400 h-4" />}
                          type="text"
                          placeholder="1,000.00"
                          suffix="DOGE"
                          onChange={convertDogeToUsd}
                          register={register}
                          errors={errors.priceInDoge}
                        />
                      </div>
                      <div>
                        <Typo.textSm fontWeight="font-medium" className="text-gray-700 my-5">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ALTERNATE_PRICE_OPTION.id,
                            defaultMessage:
                              TRANSLATED_CONSTANTS.ALTERNATE_PRICE_OPTION.defaultMessage
                          })}
                        </Typo.textSm>
                        <div className=" space-y-4">
                          <CheckboxField
                            id="1"
                            type="checkbox"
                            shape="square"
                            name="alternatePriceOptions"
                            value="Doge_Doge"
                            isGroupItem={true}
                            onChange={onChangePriceOption}
                            errors=""
                            isSelected={priceOption == 'Doge_Doge'}
                            label={
                              <div className="flex items-center gap-x-2">
                                <span>
                                  {intl.formatMessage({
                                    id: TRANSLATED_CONSTANTS.DOGE_DOGE.id,
                                    defaultMessage: TRANSLATED_CONSTANTS.DOGE_DOGE.defaultMessage
                                  })}
                                </span>
                                <Tooltip
                                  title={intl.formatMessage({
                                    id: TRANSLATED_CONSTANTS.DOGE_DOGE.id,
                                    defaultMessage: TRANSLATED_CONSTANTS.DOGE_DOGE.defaultMessage
                                  })}
                                  supportingTitle={intl.formatMessage({
                                    id: TRANSLATED_CONSTANTS.DOGE_DOGE_DESCRIPTION.id,
                                    defaultMessage:
                                      TRANSLATED_CONSTANTS.DOGE_DOGE_DESCRIPTION.defaultMessage
                                  })}
                                  arrow="bottom-center">
                                  <HelpIcon className="text-gray-400" />
                                </Tooltip>
                              </div>
                            }
                            register={() => ({})}></CheckboxField>
                          <CheckboxField
                            id="1"
                            type="checkbox"
                            shape="square"
                            name="alternatePriceOptions"
                            value="Negotiable_no_price"
                            isGroupItem={true}
                            label={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage
                            })}
                            onChange={onChangePriceOption}
                            isSelected={priceOption == 'Negotiable_no_price'}
                            errors=""
                            register={() => ({})}></CheckboxField>
                        </div>
                        <Typo.textSm fontWeight="font-medium" className="text-gray-700 my-5">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.PRICE_TYPE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.PRICE_TYPE.defaultMessage
                          })}
                        </Typo.textSm>
                        <div className=" space-y-4">
                          <CheckboxField
                            id="1"
                            type="checkbox"
                            shape="square"
                            name="priceType"
                            value="Price_is_firm"
                            isGroupItem={true}
                            isSelected={priceType == 'Price_is_firm'}
                            label="Price is firm"
                            onChange={onChangePriceType}
                            errors=""
                            register={() => ({})}></CheckboxField>
                          <CheckboxField
                            id="1"
                            type="checkbox"
                            shape="square"
                            name="priceType"
                            isGroupItem={true}
                            value="Open_for_offers"
                            onChange={onChangePriceType}
                            isSelected={priceType == 'Open_for_offers'}
                            label={intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.OPEN_FOR_OFFERS.id,
                              defaultMessage: TRANSLATED_CONSTANTS.OPEN_FOR_OFFERS.defaultMessage
                            })}
                            register={() => ({})}
                            errors=""></CheckboxField>
                          <Typo.textSm fontWeight="font-normal" className="text-error-600">
                            {errors?.priceType && validationMessages['an_option_must_select']}
                          </Typo.textSm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.DESCRIPTION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage
                    })}
                    *
                  </Typo.textSm>
                  <div className=" lg:col-span-2">
                    <TextAreaField
                      name="description"
                      rows={6}
                      placeholder={intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.ENTER_DESCRIPTION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.ENTER_DESCRIPTION.defaultMessage
                      })}
                      register={register}
                      errors={errors.description}
                    />
                  </div>
                </div>
              </div>
              {user?.type == ACCOUNT_TYPE.PERSONAL ? (
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                  <div className="py-5 w-full space-y-1.5 flex flex-col  md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                    <div className=" lg:col-span-1">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.MEETUP_LOCATION.id,
                          defaultMessage: TRANSLATED_CONSTANTS.MEETUP_LOCATION.defaultMessage
                        })}
                        *
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ADD_MEETUP_LOCATION.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ADD_MEETUP_LOCATION.defaultMessage
                        })}
                      </Typo.textSm>
                    </div>
                    <div className=" flex flex-col lg:col-span-2 w-full">
                      <LocationDropdown
                        placeholder={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.LOCATION_PLACEHOLDER.id,
                          defaultMessage: TRANSLATED_CONSTANTS.LOCATION_PLACEHOLDER.defaultMessage
                        })}
                        className="mt-5"
                        onLocationSelect={onPlaceSelected}></LocationDropdown>
                      <div className="w-full">
                        <Location coords={coords} className=" min-w-full mt-3 h-[200px]" />
                      </div>
                      <div>
                        <Typo.textXl fontWeight="font-medium" className="my-8 text-gray-900">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.id,
                            defaultMessage: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.defaultMessage
                          })}
                        </Typo.textXl>

                        <div className="overflow-visible sm:rounded-md space-y-5 ">
                          <div className="grid grid-cols-2 gap-x-3">
                            <InputField
                              name="latitude"
                              label={intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.LATITUDE.id,
                                defaultMessage: TRANSLATED_CONSTANTS.LATITUDE.defaultMessage
                              })}
                              placeholder="35.8973748"
                              register={register}
                              type="text"
                              errors={errors.latitude}
                            />
                            <InputField
                              name="longitude"
                              label={intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.LONGITUDE.id,
                                defaultMessage: TRANSLATED_CONSTANTS.LONGITUDE.defaultMessage
                              })}
                              placeholder="-119.887349"
                              register={register}
                              type="text"
                              errors={errors.longitude}
                            />
                          </div>
                          <div className="w-full">
                            <InputField
                              name="street"
                              label={`${
                                user?.type === ACCOUNT_TYPE.PERSONAL
                                  ? intl.formatMessage({
                                      id: TRANSLATED_CONSTANTS.STREET.id,
                                      defaultMessage: TRANSLATED_CONSTANTS.STREET.defaultMessage
                                    })
                                  : intl.formatMessage({
                                      id: TRANSLATED_CONSTANTS.ADDRESS.id,
                                      defaultMessage: TRANSLATED_CONSTANTS.ADDRESS.defaultMessage
                                    })
                              }`}
                              placeholder={
                                user?.type === ACCOUNT_TYPE.PERSONAL
                                  ? intl.formatMessage({
                                      id: TRANSLATED_CONSTANTS.ENTER_A_STREET.id,
                                      defaultMessage:
                                        TRANSLATED_CONSTANTS.ENTER_A_STREET.defaultMessage
                                    })
                                  : intl.formatMessage({
                                      id: TRANSLATED_CONSTANTS.ENTER_AN_ADDRESS.id,
                                      defaultMessage:
                                        TRANSLATED_CONSTANTS.ENTER_AN_ADDRESS.defaultMessage
                                    })
                              }
                              helpIcon={true}
                              register={register}
                              type="text"
                              errors={errors.street}
                            />
                          </div>
                          <div className="grid grid-cols-2 gap-x-3">
                            <InputField
                              name="city"
                              label={`${intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.CITY.id,
                                defaultMessage: TRANSLATED_CONSTANTS.CITY.defaultMessage
                              })}*`}
                              placeholder="San Diego"
                              register={register}
                              type="text"
                              errors={errors.city}
                            />
                            <InputField
                              name="state"
                              label={`${intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.id,
                                defaultMessage: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.defaultMessage
                              })}*`}
                              placeholder="California"
                              register={register}
                              type="text"
                              errors={errors.state}
                            />
                          </div>

                          <div className="w-full">
                            <InputField
                              name="country"
                              label={`${intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.COUNTRY.id,
                                defaultMessage: TRANSLATED_CONSTANTS.COUNTRY.defaultMessage
                              })}*`}
                              placeholder="United States"
                              register={register}
                              type="text"
                              errors={errors?.country || ''}
                            />
                          </div>
                          <Typo.textSm fontWeight="font-normal" className="w-full text-gray-800">
                            *
                            {intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
                              defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
                            })}
                          </Typo.textSm>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className="flex justify-end gap-x-3  pt-5">
                <Button onClick={onCancel} size="lg" theme="base">
                  <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.CANCEL.id,
                      defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                    })}
                  </Typo.textMd>
                </Button>
                <Button size="lg" loading={isSubmiting} type="submit" theme="primary-yellow">
                  <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.SAVE_CHANGES.id,
                      defaultMessage: TRANSLATED_CONSTANTS.SAVE_CHANGES.defaultMessage
                    })}
                  </Typo.textMd>
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center">
            <Spinner className="h-10 w-10 mx-auto mt-[50%]"></Spinner>
          </div>
        )}
      </form>
      <RedActionModal
        title={TRANSLATED_CONSTANTS.LISTING_DELETE_MODAL_TITLE}
        isOpen={showDeleteModal}
        onCloseRedActionModal={(value: boolean) => onDelete(value)}
        subTitle={TRANSLATED_CONSTANTS.LISTING_DELETE_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE_LISTING}
        icon={<TrashIcon></TrashIcon>}
        hasButtonIcon={true}
      />
    </>
  );
};

export default EditListing;
