/* eslint-disable react/prop-types */
import { useEffect, useState } from 'react';
import { languageLevels } from '../../../constants/data';
import { DropdownOption } from '../../../model/DropdownOption';
import { LanguageProficiencyModel } from '../../../model/LanguageProficiencyModel';
import TrashIcon from '../../icons/TrashIcon';
import Button from '../Button';
import InputDropdown from '../location Dropdown/InputDropdown';

type LanguageProficencyItemProps = {
  defaultValue: LanguageProficiencyModel;
  onChange?: (value: LanguageProficiencyModel) => void;
  onDelete: (value: LanguageProficiencyModel) => void;
  availableLanguages: DropdownOption[]; // New prop to pass available languages
};

const LanguageProficencyItem: React.FC<LanguageProficencyItemProps> = (props) => {
  const { defaultValue, onChange, onDelete, availableLanguages } = props;
  const [languages, setLanguages] = useState<DropdownOption[]>([]);
  const [language, setLanguage] = useState<DropdownOption>();

  const onChangeLanguage = (option: DropdownOption) => {
    const tempItem = { ...defaultValue, language: option };
    onChange && onChange(tempItem);
  };

  const onChangeLevel = (option: DropdownOption) => {
    const tempItem = { ...defaultValue, level: option };
    onChange && onChange(tempItem);
  };

  useEffect(() => {
    if (availableLanguages) {
      setLanguages([...availableLanguages, defaultValue?.language]);
      setLanguage(defaultValue?.language);
    }
  }, [availableLanguages]);

  return (
    <div className="grid grid-cols-6 w-full gap-x-4 sm:col-span-3">
      <div className="col-span-3">
        <InputDropdown
          options={languages} // Use availableLanguages prop here
          defaultValue={language}
          onChange={onChangeLanguage}></InputDropdown>
      </div>
      <div className="col-span-2">
        <InputDropdown
          options={languageLevels}
          onChange={onChangeLevel}
          defaultValue={
            defaultValue?.level ? defaultValue?.level : languageLevels[0]
          }></InputDropdown>
      </div>
      <div className="col-span-1">
        <Button
          onClick={() => onDelete(defaultValue)}
          icon={<TrashIcon className="h-5 w-5 text-gray-700" />}></Button>
      </div>
    </div>
  );
};

export default LanguageProficencyItem;
