import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { useGetDetailedQuarantineListings } from '../../../../hooks/reports/useGetDetailedQuarantinListing';
import Loader from '../../../../components/shared/Loader';
import ListingDetailedLayout from './ListingDetailedLayout';
import { REPORT_PAGES } from '../../../../constants/data';
import { useHandleListingFlaggedReports } from '../../../../hooks/useHandleListingFlaggedReports';
import RedActionModal from '../../../../components/modal/RedActionModal';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import UserXIcon from '../../../../components/icons/UserXIcon';
import PrimaryActionModal from '../../../../components/modal/PrimaryActionModal';
import TagIcon from '../../../../components/icons/TagIcon';
import { useHandleIssuedListings } from '../../../../hooks/useHandleIssuedListings';
import EmptyReportListings from '../../EmptyReportListings';
import TrashIcon from '../../../../components/icons/TrashIcon';
import AlertHexagonIcon from '../../../../components/icons/AlertHexagonIcon';

import { ActivityItemPropsType } from '../../../../model/Activity';
import { handleReportActivityInfo } from '../../../../util/ReportUtil';
import { useIntl } from 'react-intl';
import { User } from '../../../../model/UserModel';
const QuarantineListingsDetailedLayout = () => {
  const intl = useIntl();
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const selectedGridRow = useAppSelector((state) => state.report.reportLayout);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [isQuarantineFetched, setIsQuarantineFetched] = useState(false);
  const [numberOfFlags, setNumberOfFlags] = useState(0);
  const [numberOfWarnings, setNumberOfWarnings] = useState(0);
  const [activities, setActivities] = useState<ActivityItemPropsType[]>([]);
  const { quarantineListings, quarantineListingsCount, loading, error, refetch, fetchMore } =
    useGetDetailedQuarantineListings(offset, 1, searchInput);

  const { onRestoreButtonClicked, showReslistItemModal, onCloseRelistModalModal } =
    useHandleIssuedListings(refetch);
  const {
    showBanModal,
    onBanUserButtonClicked,
    onCloseBanModal,
    showWarnAndDeleteModal,
    onWarnUserButtonClicked,
    onCloseWarnAndDeleteModal,
    showBanAndDeleteModal,
    onCloseDetailedBanAndDeleteModal,
    onDetailedBanDeleteUserButtonClicked
  } = useHandleListingFlaggedReports(refetch);
  const fetchMoreData = (pageNumber: number) => {
    const startIndex = pageNumber - 1;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: 1 }
    });
    setIsQuarantineFetched(false);
    setCurrentPage(pageNumber);
  };
  const activityHeader = handleReportActivityInfo(REPORT_PAGES.LISTING_QUARANTINE, intl);
  useEffect(() => {
    if (quarantineListings && quarantineListings?.length > 0 && !isQuarantineFetched) {
      setIsQuarantineFetched(true);
      // eslint-disable-next-line no-unsafe-optional-chaining
      const [flags, warnings] = quarantineListings[0]?.user?.listings?.reduce(
        ([totalFlags, totalWarnings]: any, listing: any) => {
          // Use the nullish coalescing operator ?? to provide default values
          const flags = totalFlags + (listing?.listing_reports?.length ?? 0);
          const warnings = totalWarnings + (listing?.issued_listing ? 1 : 0);
          return [flags, warnings];
        },
        [0, 0] // Initial values
      );

      // Call the setter functions only once
      setNumberOfFlags(flags);
      setNumberOfWarnings(warnings);
      setActivities(
        quarantineListings[0]?.listing_reports.map((report: any) => {
          return {
            name: report?.reporter?.name,
            username: report?.reporter?.username,
            time: report?.date,
            claim: report?.claim,
            detail: report?.detail,
            type: 'quarantine',
            opened: true,
            userId: report?.reporter?.id,
            userImageUrl: report?.reporter?.profileImage?.url
          };
        })
      );
    }
  }, [quarantineListings]);

  useEffect(() => {
    setOffset(selectedGridRow.index);
    setCurrentPage(selectedGridRow.index + 1);
  }, [selectedGridRow]);

  return (
    <div>
      {quarantineListings?.length === 0 && !loading ? (
        <EmptyReportListings
          title={{
            id: 'page.reports.nothing_quarantined_title',
            defaultMessage: 'No quarantine yet'
          }}
          subtitle={{
            id: 'page.reports.nothing_quarantined_subtitle',
            defaultMessage: 'You don’t have any quarantine posts yet.'
          }}
          icon={<AlertHexagonIcon />}
        />
      ) : (
        <div>
          {loading ? (
            <Loader className="h-screen" />
          ) : quarantineListings && quarantineListings?.length > 0 ? (
            <ListingDetailedLayout
              pageType={REPORT_PAGES.LISTING_QUARANTINE}
              data={quarantineListings}
              activityData={activities}
              activityHeader={activityHeader}
              listingReportsCount={quarantineListingsCount}
              numberOfFlags={numberOfFlags}
              numberOfWarnings={numberOfWarnings}
              fetchMoreData={fetchMoreData}
              currentPage={currentPage}
              openBanModal={(user: User) => onBanUserButtonClicked(user, quarantineListings)}
              openRestoreModal={onRestoreButtonClicked}
              openDeleteAndWarnModal={onWarnUserButtonClicked}
              openBanAndDeleteModal={onDetailedBanDeleteUserButtonClicked}
            />
          ) : (
            <></>
          )}
        </div>
      )}

      <RedActionModal
        title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
        isOpen={showBanModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
        username={quarantineListings && quarantineListings[0]?.user?.username}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
        isOpen={showReslistItemModal}
        onClosePrimaryActionModal={(value: boolean) =>
          onCloseRelistModalModal(value, 'listing-quarantine')
        }
        subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_CONFIRM_BUTTON}
        icon={<TagIcon />}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarnAndDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseWarnAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
      <RedActionModal
        title={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_TITLE}
        isOpen={showBanAndDeleteModal}
        onCloseRedActionModal={(value: boolean) => onCloseDetailedBanAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default QuarantineListingsDetailedLayout;
