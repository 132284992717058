type Props = {
  className?: string;
};
const CopyRightIcon = ({ className }: Props) => (
  <svg
    width="16"
    height="16"
    className={`h-4 w-4 text-gray-500 ${className}`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M8 1C4.13438 1 1 4.13438 1 8C1 11.8656 4.13438 15 8 15C11.8656 15 15 11.8656 15 8C15 4.13438 11.8656 1 8 1ZM8 13.8125C4.79063 13.8125 2.1875 11.2094 2.1875 8C2.1875 4.79063 4.79063 2.1875 8 2.1875C11.2094 2.1875 13.8125 4.79063 13.8125 8C13.8125 11.2094 11.2094 13.8125 8 13.8125ZM8.0875 5.48906C8.91562 5.48906 9.47812 6.01719 9.54062 6.79219C9.54531 6.85781 9.6 6.90781 9.66562 6.90781H10.5516C10.5922 6.90781 10.625 6.875 10.625 6.83437C10.625 5.47969 9.55625 4.53125 8.08281 4.53125C6.36562 4.53125 5.375 5.69062 5.375 7.60625V8.42344C5.375 10.325 6.36562 11.4688 8.08281 11.4688C9.55156 11.4688 10.625 10.55 10.625 9.25937C10.625 9.21875 10.5922 9.18594 10.5516 9.18594H9.66406C9.59844 9.18594 9.54531 9.23594 9.53906 9.3C9.47344 10.0203 8.9125 10.5156 8.08594 10.5156C7.06563 10.5156 6.49062 9.76719 6.49062 8.42813V7.60625C6.49219 6.24687 7.06875 5.48906 8.0875 5.48906Z"
    />
  </svg>
);

export default CopyRightIcon;
