type Props = {
  className?: string;
};
const FlagIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className} `}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.7427 5.60189H16.7042C17.0977 5.60189 17.2944 5.60189 17.4094 5.68461C17.5098 5.75679 17.5752 5.86788 17.5895 5.99069C17.606 6.13143 17.5104 6.3034 17.3193 6.64734L16.1353 8.77866C16.066 8.9034 16.0313 8.96576 16.0177 9.03181C16.0057 9.09027 16.0057 9.15056 16.0177 9.20901C16.0313 9.27506 16.066 9.33743 16.1353 9.46216L17.3193 11.5935C17.5104 11.9374 17.606 12.1094 17.5895 12.2501C17.5752 12.3729 17.5098 12.484 17.4094 12.5562C17.2944 12.6389 17.0977 12.6389 16.7042 12.6389H10.5113C10.0186 12.6389 9.7723 12.6389 9.58414 12.5431C9.41862 12.4587 9.28406 12.3242 9.19972 12.1586C9.10385 11.9705 9.10385 11.7242 9.10385 11.2315V9.12041M6.02515 17.9167L2.50663 3.84263M3.82611 9.12041H10.3353C10.828 9.12041 11.0743 9.12041 11.2625 9.02454C11.428 8.9402 11.5625 8.80564 11.6469 8.64012C11.7427 8.45196 11.7427 8.20564 11.7427 7.713V3.49078C11.7427 2.99814 11.7427 2.75182 11.6469 2.56366C11.5625 2.39815 11.428 2.26358 11.2625 2.17925C11.0743 2.08337 10.828 2.08337 10.3353 2.08337H3.86937C3.25493 2.08337 2.94771 2.08337 2.73759 2.21069C2.55342 2.32227 2.41658 2.49753 2.35299 2.70327C2.28045 2.938 2.35496 3.23605 2.50399 3.83214L3.82611 9.12041Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FlagIcon;
