import { gql, useMutation } from '@apollo/client';

interface LanguageProficiencyInput {
  variables: {
    id: number;
  };
}

const DELETE_LANGUAGE_PROFICIENCY = gql`
  mutation DeleteLanguageProficiency($id: ID!) {
    deleteLanguageProficiency(id: $id) {
      data {
        id
      }
    }
  }
`;

export const useDeleteLanguageProficiency = (): ((
  deleteLanguageProficiency: LanguageProficiencyInput
) => any) => {
  const [deleteLanguageProficiency] = useMutation(DELETE_LANGUAGE_PROFICIENCY);
  return deleteLanguageProficiency;
};
