import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import filterSlice from './reducers/filter.slice';
import modalSlice from './reducers/modal.slice';
import signupSlice from './reducers/signup.slice';
import alertSlice from './reducers/alert.slice';
import slideSlice from './reducers/slide.slice';
import shareSlice from './reducers/share.slice';
import dashboardSlice from './reducers/dashboard.slice';
import reportSlice from './reducers/report.slice';
import notificationSlice from './reducers/notification.slice';
import emailSlice from './reducers/email.slice';
import directionSlice from './reducers/direction.slice';
export const store = configureStore({
  reducer: {
    filter: filterSlice,
    modal: modalSlice,
    signup: signupSlice,
    alert: alertSlice,
    slide: slideSlice,
    dashboard: dashboardSlice,
    share: shareSlice,
    report: reportSlice,
    notifications: notificationSlice,
    email: emailSlice,
    direction: directionSlice
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
