import React from 'react';
import { Typo } from '../../Typo';
import { getMessageDateTime } from '../../../util/AppUtil';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
type Props = {
  message: string;
  dateTime: string;
};
const SendMessage: React.FC<Props> = (props) => {
  const { message, dateTime } = props;
  const intl = useIntl();
  return (
    <div className="w-full flex justify-end">
      <div className="flex w-fit items-end right-0 flex-col gap-y-[6px] ">
        <div className="flex w-full gap-2 justify-between">
          <Typo.textSm fontWeight="font-medium" className="text-gray-700">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.YOU.id,
              defaultMessage: TRANSLATED_CONSTANTS.YOU.defaultMessage
            })}
          </Typo.textSm>
          <Typo.textXs fontWeight="font-normal" className="text-gray-600">
            {getMessageDateTime(dateTime)}
          </Typo.textXs>
        </div>
        <div className="py-2.5 px-3.5 bg-yellow-600 rounded-l-lg flex justify-end w-fit rounded-b-lg text-white">
          <Typo.textMd fontWeight="font-normal">{message}</Typo.textMd>
        </div>
      </div>
    </div>
  );
};

export default SendMessage;
