export const customizeListings = (listing: any) => {
  const customizedListings = listing?.map((listing: any) => {
    if ((listing?.type === 'storefrontItem' || listing?.type === 'wanted') && listing.store_front) {
      listing.location = listing?.store_front.location;
    }
    listing.saved_listings =
      listing.saved_listings && listing.saved_listings?.length > 0 ? listing.saved_listings : null;
    return listing;
  });
  return customizedListings;
};

export const customizeStorefronts = (storefront: any) => {
  const customizedStorefronts = storefront?.map((storefront: any) => {
    storefront.type = 'storefront';
    storefront.saved_storefronts =
      storefront.saved_storefronts && storefront.saved_storefronts?.length > 0
        ? storefront.saved_storefronts
        : null;
    return storefront;
  });
  return customizedStorefronts;
};
