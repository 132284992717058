import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { CheckboxOption } from '../../model/CheckboxOption';

export const GET_MODERATORS = gql`
  query getModerators(
    $offset: Int
    $limit: Int
    $accuracyScoresValues: [String]
    $countriesValues: [String]
    $languagesValues: [String]
    $userTypesValues: [String]
    $searchText: String
  ) {
    moderatorsCount: moderators(
      accuracyScoresValues: $accuracyScoresValues
      countriesValues: $countriesValues
      languagesValues: $languagesValues
      userTypesValues: $userTypesValues
      searchText: $searchText
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    moderators(
      pagination: { limit: $limit, start: $offset }
      accuracyScoresValues: $accuracyScoresValues
      countriesValues: $countriesValues
      languagesValues: $languagesValues
      userTypesValues: $userTypesValues
      searchText: $searchText
    ) {
      data {
        id
        attributes {
          name
          countryName
          type
          correctDecisions
          wrongDecisions
          decisions
          moderatorStartDate
          username
          countryCode
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          profileImage {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetModerators = (
  offset: number,
  limit: number,
  searchText: string,
  accuracyScores: CheckboxOption[],
  countries: CheckboxOption[],
  languages: CheckboxOption[],
  userTypes: CheckboxOption[]
) => {
  const accuracyScoresValues = accuracyScores?.map((accuracy) => accuracy.value) || [];
  const countriesValues = countries?.map((country) => country.value) || [];
  const languagesValues = languages?.map((language) => language.value) || [];
  const userTypesValues = userTypes?.map((type) => type.value) || [];

  const { loading, error, data, refetch, fetchMore } = useQuery(GET_MODERATORS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: offset,
      limit: limit,
      accuracyScoresValues,
      countriesValues,
      languagesValues,
      userTypesValues,
      searchText
    }
  });
  return {
    moderators: flattenObj(data?.moderators),
    moderatorsCount: data?.moderatorsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
