import { gql, useMutation } from '@apollo/client';
interface IssuedListingInput {
  variables: {
    data: {
      claim: string;
      detail: string;
      date: string | Date;
      actionType: string;
      listing: number;
      issuer: number;
      publishedAt: string;
    };
  };
}

export const CREATE_ISSUED_LISTING = gql`
  mutation CreateIssuedListing($data: IssuedListingInput!) {
    createIssuedListing(data: $data) {
      data {
        id
        attributes {
          listing {
            data {
              id
            }
          }
          issuer {
            data {
              id
              attributes {
                name
                username
              }
            }
          }
        }
      }
    }
  }
`;

export const useCreateIssuedListing = (): ((issuedListing: IssuedListingInput) => any) => {
  const [createIssuedListingResult] = useMutation(CREATE_ISSUED_LISTING);
  return createIssuedListingResult;
};
