import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_STOREFRONT = gql`
  query getStorefront($id: ID!, $userId: ID, $limit: Int, $skip: Boolean!) {
    storeFront(id: $id) {
      data {
        id
        attributes {
          name
          description
          location {
            id
            address
            latitude
            longitude
            city
            country
            state
            street
          }
          user {
            data {
              id
              attributes {
                name
                username
                email
                profileImage {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          saved_storefronts(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          storefront_reports(filters: { reporter: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
            }
          }
          image(sort: ["createdAt:DESC"]) {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          operationHours {
            id
            day
            isOpen
            opensAt
            closesAt
          }
          listings(
            filters: { type: { eq: "storefrontItem" }, and: { available: { eq: true } } }
            pagination: { limit: $limit }
          ) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                    attributes {
                      username
                      name
                    }
                  }
                }
                image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
                  data {
                    id
                    attributes {
                      url
                      formats
                    }
                  }
                }
                name
                description
                isSold
                location {
                  address
                  latitude
                  longitude
                  state
                  country
                  city
                  street
                }
                slug
                price {
                  priceType
                  priceInUSD
                  priceInDoge
                  alternatePriceOptions
                }
                type
                category {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                item_condition {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                user {
                  data {
                    id
                    attributes {
                      username
                      name
                    }
                  }
                }
                saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
                  data {
                    id
                    attributes {
                      user {
                        data {
                          id
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetStorefront = (id: number, userId?: number, limit?: number) => {
  let skip = true;
  if (userId) {
    skip = false;
  }
  const { loading, error, data, refetch } = useQuery(GET_STOREFRONT, {
    fetchPolicy: 'cache-and-network',
    variables: { id: id, userId: userId, limit: limit, skip }
  });
  return {
    storefront: flattenObj(data?.storeFront, true),
    loading,
    error,
    refetch
  };
};
