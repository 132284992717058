import React, { FC } from 'react';
import Button from './Button';
import { FormattedMessage } from 'react-intl';
import { Typo } from '../Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

type Props = {
  openReportModal: (value: boolean) => void;
  className?: string;
  disabled?: boolean;
};

const ReportButton: FC<Props> = ({ openReportModal, className, disabled = false }) => {
  return (
    <Button
      disabled={disabled}
      className={className}
      onClick={() => openReportModal(true)}
      size="lg"
      theme="base">
      <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.REPORT.id}
          defaultMessage={TRANSLATED_CONSTANTS.REPORT.defaultMessage}
        />
      </Typo.textSm>
    </Button>
  );
};

export default ReportButton;
