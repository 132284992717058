type Props = {
  className?: string;
};
const MessageIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-4 h-4 ' + className}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.99903 9.00004L13.999 2.00004M7.08408 9.21875L8.83616 13.7241C8.99051 14.121 9.06768 14.3194 9.17888 14.3774C9.27528 14.4276 9.39012 14.4277 9.48658 14.3775C9.59785 14.3197 9.67525 14.1214 9.83007 13.7247L14.2236 2.46617C14.3634 2.10805 14.4333 1.92899 14.395 1.81457C14.3618 1.71521 14.2839 1.63723 14.1845 1.60403C14.0701 1.56581 13.891 1.63569 13.5329 1.77544L2.2744 6.169C1.87768 6.32382 1.67933 6.40122 1.62152 6.51249C1.57141 6.60895 1.57148 6.72379 1.6217 6.82018C1.67964 6.93139 1.87809 7.00856 2.27498 7.16291L6.78032 8.91498C6.86089 8.94632 6.90117 8.96198 6.93509 8.98618C6.96515 9.00762 6.99145 9.03391 7.01289 9.06398C7.03709 9.0979 7.05275 9.13818 7.08408 9.21875Z"
        strokeWidth="1.67"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageIcon;
