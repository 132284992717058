import React, { ReactElement } from 'react';
import DashboardSubHeader from './DashboardSubHeader';
import Breadcrumb from '../Breadcrumb';
type Props = {
  title?: string;
  description?: any;
  cancelButtonIcon?: ReactElement;
  confirmButtonIcon?: ReactElement;
  amount?: number;
  confirmType?: 'button' | 'submit';
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  onCancelClick?: () => void;
  onConfrimClick?: () => void;
  onDeleteClick?: () => void;
  onSearch?: (value: string) => void;
  hasDeleteOption?: boolean;
  storefrontUrl?: string;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  accountType?: string;
  role?: string;
  editBtn?: ReactElement;
  showBreadCrumb?: boolean;
  deleteText?: string;
};
const DashboardHeader: React.FC<Props> = (props) => {
  const {
    title,
    description,
    amount,
    cancelButtonIcon,
    cancelButtonTitle,
    confirmButtonIcon,
    confirmButtonTitle,
    confirmType = 'button',
    hasDeleteOption = false,
    storefrontUrl,
    onCancelClick,
    onConfrimClick,
    onDeleteClick,
    onSearch,
    cancelDisabled,
    confirmDisabled,
    accountType,
    role,
    editBtn,
    showBreadCrumb = true,
    deleteText
  } = props;
  return (
    <div className="w-full">
      {showBreadCrumb && <Breadcrumb />}
      <DashboardSubHeader
        title={title}
        onConfrimClick={onConfrimClick && onConfrimClick}
        onCancelClick={onCancelClick && onCancelClick}
        onSearch={onSearch}
        confirmType={confirmType}
        description={description}
        confirmButtonIcon={confirmButtonIcon}
        confirmButtonTitle={confirmButtonTitle}
        cancelButtonIcon={cancelButtonIcon}
        cancelButtonTitle={cancelButtonTitle}
        hasDeleteOption={hasDeleteOption}
        onDeleteClick={onDeleteClick}
        storefrontUrl={storefrontUrl}
        cancelDisabled={cancelDisabled}
        confirmDisabled={confirmDisabled}
        amount={amount}
        accountType={accountType}
        role={role}
        deleteText={deleteText}
        editBtn={editBtn}></DashboardSubHeader>
    </div>
  );
};

export default DashboardHeader;
