import { gql, useMutation } from '@apollo/client';
interface UsersPermissionsUserInput {
  variables: {
    data: any;
    id: number;
  };
}

export const UPDATE_USER = gql`
  mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
        attributes {
          username
          name
          phone
          type
          sharingPoints
          referralCode
          decisions
          correctDecisions
          profileCompleted
          birthDate
        }
      }
    }
  }
`;

export const useUpdateUser = (): ((updateUser: UsersPermissionsUserInput) => any) => {
  const [updateUserResult] = useMutation(UPDATE_USER);

  return updateUserResult;
};
