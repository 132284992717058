import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { EllipsisVerticalIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { DropdownOption } from '../../model/DropdownOption';
import { Typo } from '../Typo';
import ChevronIcon from '../icons/ChevronIcon';
import { useNavigate } from 'react-router-dom';
import Avatar from './Avatar';
import User1Icon from '../icons/User1Icon';
import { useDispatch } from 'react-redux';
import { addDashboardTab } from '../../lib/redux/reducers/dashboard.slice';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ROUTES } from '../../constants/data';
import { showModal } from '../../lib/redux/reducers/modal.slice';
type Props = {
  options?: Array<DropdownOption>;
  type?: 'v-dots' | 'h-dots' | 'icon' | 'image';
  header?: any;
  image?: string;
  icon?: any;
  hasChevron?: boolean;
  className?: string;
  contentClassName?: string;
  onChange?: (option: DropdownOption) => void;
};
const DropdownMenu: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    type = 'icon',
    options = [],
    onChange,
    header,
    image,
    className,
    contentClassName,
    hasChevron = false
  } = props;

  const handleClick = (option: DropdownOption) => {
    if (option?.dashboardTab) {
      dispatch(addDashboardTab(option.dashboardTab));
    }
    //TODO:Remove when leaderboard is complete
    if (option.to === ROUTES.LEADER_BOARD) {
      dispatch(showModal('commingSoon'));
    } else if (option?.to) {
      navigate(option?.to);
    }
  };
  return (
    <Menu as="div" className={`relative inline-block text-left ${className}`}>
      {({ open, close }: any) => (
        <>
          <div>
            <Menu.Button className="flex items-center group outline-none">
              <span className="sr-only">Open options</span>
              {type === 'v-dots' ? (
                <EllipsisVerticalIcon
                  className={clsx(
                    open ? ' fill-gray-700 ' : ' fill-gray-400 ',
                    'h-5 w-5  group-hover:fill-gray-700 group-focus:fill-gray-700 '
                  )}
                  aria-hidden="true"
                />
              ) : (
                ''
              )}
              {type === 'h-dots' ? (
                <EllipsisHorizontalIcon
                  className={clsx(
                    open
                      ? ' ring-4 ring-gray-100 fill-gray-700 rounded-lg w-10 h-10 p-[10px] shadow-xs '
                      : 'fill-gray-500 h-5 w-5 ',
                    ''
                  )}
                  aria-hidden="true"
                />
              ) : (
                ''
              )}
              {type === 'image' ? (
                <Avatar
                  size="md"
                  image={image ? image : ''}
                  icon={!image ? <User1Icon className="text-gray-600" /> : ''}></Avatar>
              ) : (
                ''
              )}
              {hasChevron && (
                <ChevronIcon
                  className={`text-gray-700 hidden lg:block ml-2 ${
                    open ? '' : ' rotate-180'
                  } `}></ChevronIcon>
              )}
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              className={`absolute right-0 z-20 mt-2 w-60 origin-top-right rounded-lg border border-gray-100 bg-white shadow-lg focus:outline-none ${contentClassName}`}>
              {header && header}
              <div>
                {options?.map((option: DropdownOption, index: number) => {
                  return (
                    <Menu.Item key={option.id}>
                      {({ active }: any) => (
                        <div>
                          {option?.to ? (
                            <button
                              onClick={() => handleClick(option)}
                              className={clsx(
                                ' flex items-center gap-x-3 w-full justify-start px-4 h-10 py-2.5 font-inter text-sm text-gray-700 font-medium bg-white hover:bg-gray-50 disabled:text-gray-50',
                                {
                                  ' rounded-t-lg': index === 0,
                                  ' rounded-b-lg': index === options?.length - 1
                                }
                              )}>
                              {option?.icon ? <span className="">{option.icon}</span> : ''}
                              <Typo.textSm fontWeight="font-medium">{option.title}</Typo.textSm>
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                                e.stopPropagation();
                                onChange ? onChange(option) : {};
                                close();
                              }}
                              className={clsx(
                                ' flex items-center gap-x-3 w-full justify-start px-4 h-10 py-2.5 font-inter text-sm text-gray-700 font-medium bg-white hover:bg-gray-50 disabled:text-gray-50',
                                {
                                  ' rounded-t-lg': index === 0,
                                  ' rounded-b-lg': index === options?.length - 1
                                }
                              )}>
                              {option?.icon ? <span className="">{option.icon}</span> : ''}
                              <Typo.textSm
                                fontWeight="font-medium"
                                className={`${
                                  option.title == TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage
                                    ? 'text-[#886307]'
                                    : ''
                                }`}>
                                {option.title}
                              </Typo.textSm>
                            </button>
                          )}
                        </div>
                      )}
                    </Menu.Item>
                  );
                })}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default DropdownMenu;
