import React from 'react';

type Props = {
  value?: number;
  showLabel?: boolean;
  [x: string]: any;
};

const mapValue = (value: number) => {
  // Ensure the input value is within the range of 0-100
  const clampedValue = Math.min(Math.max(value, 0), 100);

  // Calculate the corresponding value in the range of 10-100
  const mappedValue = clampedValue * 0.9 + 10;

  // Round the mapped value to the nearest integer
  return Math.round(mappedValue);
};

const ProgressBar: React.FC<Props> = (props) => {
  const { value = 0, showLabel = false } = props;
  return (
    <div className=" flex w-full items-center gap-x-3">
      <div className="w-full flex-grow bg-gray-200 rounded-[4px] h-2 min-w-[100px]">
        <div
          className="bg-yellow-600 p-0.5 leading-none rounded-[4px] h-2 min-w-[1px]"
          style={{
            width: showLabel
              ? value && value !== 0
                ? Math.round(value) + '%'
                : '8px'
              : mapValue(value) + '%'
          }}></div>
      </div>
      {showLabel && (
        <span className=" flex-grow-0 text-sm font-medium font-inter text-gray-700">
          {value ? Math.round(value) : 0} %
        </span>
      )}
    </div>
  );
};

export default ProgressBar;
