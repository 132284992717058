import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import SettingWrapper from '../../components/wrapper/SettingWrapper';
import { AuthUserState } from '../../context/AuthUserProvider';

import { Typo } from '../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import InputField from '../../components/shared/InputField';
import Button from '../../components/shared/Button';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SaveIcon from '../../components/icons/SaveIcon';
import { useHandleChangePassword } from '../../hooks/useHandleChangePassword';
const ChangePasswrod = () => {
  const userState = AuthUserState();
  const user = userState.authUser;
  const intl = useIntl();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const { errors, onCancel, onSubmit, handleSubmit, register, setError, reset } =
    useHandleChangePassword();

  const onChangePassword = async (data: any) => {
    setIsSubmiting(true);
    try {
      await onSubmit(data).then(() => {
        setShowPassword(false);
        reset();
      });
    } catch (error) {
      if (typeof error === 'string') {
        setError('currentPassword', { type: 'server', message: error });
      }
    }
    setIsSubmiting(false);
  };

  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <div className="w-full">
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
            <DashboardHeader
              title={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.CHANGE_PASSWORD.id,
                defaultMessage: TRANSLATED_CONSTANTS.CHANGE_PASSWORD.defaultMessage
              })}
              description={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.YOU_CAN_CHANGE_PASSWORD.id,
                defaultMessage: TRANSLATED_CONSTANTS.YOU_CAN_CHANGE_PASSWORD.defaultMessage
              })}
              accountType={user?.type}
            />
          </div>

          <div className="mx-auto max-w-7xl w-full">
            <div className="px-4 md:px-0 divide-y divide-gray-200">
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.CURRENT_PASSWORD.id,
                      defaultMessage: TRANSLATED_CONSTANTS.CURRENT_PASSWORD.defaultMessage
                    })}
                  </Typo.textSm>
                  <div className="lg:col-span-3">
                    <InputField
                      name="placeholder"
                      type="password"
                      placeholder="password"
                      disabled={true}
                      register={() => ({})}
                    />
                    <div className="flex justify-end">
                      <Button
                        onClick={() => setShowPassword(true)}
                        size="md"
                        theme="primary-yellow">
                        <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.CHANGE_PASSWORD.id}
                            defaultMessage={TRANSLATED_CONSTANTS.CHANGE_PASSWORD.defaultMessage}
                          />
                        </Typo.textSm>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              {showPassword && (
                <form
                  id="add-location"
                  onSubmit={handleSubmit(onChangePassword)}
                  action="#"
                  method="POST">
                  <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
                    <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.ENTER_CURRENT_PASSWORD.id,
                          defaultMessage: TRANSLATED_CONSTANTS.ENTER_CURRENT_PASSWORD.defaultMessage
                        })}{' '}
                        *
                      </Typo.textSm>
                      <div className="lg:col-span-3">
                        <InputField
                          name="currentPassword"
                          type="password"
                          placeholder="password"
                          register={register}
                          errors={errors.currentPassword}
                        />
                      </div>
                    </div>
                    <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.New_PASSWORD.id,
                          defaultMessage: TRANSLATED_CONSTANTS.New_PASSWORD.defaultMessage
                        })}
                        *
                      </Typo.textSm>
                      <div className="lg:col-span-3">
                        <InputField
                          name="password"
                          type="password"
                          placeholder="password"
                          register={register}
                          errors={errors.password}
                        />
                      </div>
                    </div>
                    <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-2">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CONFIRM_NEW_PASSWORD.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CONFIRM_NEW_PASSWORD.defaultMessage
                        })}{' '}
                        *
                      </Typo.textSm>
                      <div className="lg:col-span-3">
                        <InputField
                          name="passwordConfirmation"
                          type="password"
                          placeholder="password"
                          register={register}
                          errors={errors.passwordConfirmation}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end gap-x-3  pt-5">
                    <Button onClick={onCancel} size="lg" theme="base">
                      <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CANCEL.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                        })}
                      </Typo.textMd>
                    </Button>
                    <Button
                      size="lg"
                      loading={isSubmiting}
                      type="submit"
                      theme="primary-yellow"
                      icon={<SaveIcon className="h-5 w-4 text-gray-900" />}>
                      <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.SAVE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.SAVE.defaultMessage
                        })}
                      </Typo.textMd>
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </SettingWrapper>
    </div>
  );
};

export default ChangePasswrod;
