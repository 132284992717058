import { useQuery, gql, useMutation } from '@apollo/client';
import { StorefrontModel } from '../../model/Storefront';
interface StoreFrontInput {
  variables: {
    data: StorefrontModel;
    id: number;
  };
}

export const UPDATE_STOREFRONT = gql`
  mutation editStorefront($id: ID!, $data: StoreFrontInput!) {
    updateStoreFront(id: $id, data: $data) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const useUpdateStorefront = (): ((updateStorefront: StoreFrontInput) => any) => {
  const [updateStorfrontResult] = useMutation(UPDATE_STOREFRONT, {
    update(cache, { data: { updateStorefront } }) {
      cache.modify({
        fields: {
          storeFront(existingLocation = []) {
            return updateStorefront?.storeFront;
          }
        }
      });
    }
  });

  return updateStorfrontResult;
};
