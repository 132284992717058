type Props = {
  className?: string;
};
const TikTokIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-5 h-[23px] text-gray-400 ' + className}
      viewBox="0 0 20 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M17.51 4.91847C17.3542 4.83818 17.2026 4.75017 17.0557 4.65476C16.6287 4.3734 16.2372 4.04189 15.8896 3.66739C15.0189 2.6749 14.6938 1.66811 14.5748 0.963865H14.5789C14.4795 0.378187 14.5205 0 14.5277 0H10.5639V15.2736C10.5639 15.4781 10.5639 15.6815 10.5557 15.8818C10.5557 15.9063 10.5526 15.9288 10.5516 15.9564C10.5516 15.9666 10.5516 15.9789 10.5485 15.9901V15.9993C10.5069 16.5472 10.3308 17.0765 10.0358 17.5406C9.74068 18.0048 9.33568 18.3895 8.85639 18.661C8.35636 18.9445 7.7908 19.0932 7.21551 19.0923C5.36952 19.0923 3.87222 17.5918 3.87222 15.7387C3.87222 13.8846 5.36952 12.3841 7.21551 12.3841C7.56522 12.3841 7.91288 12.4393 8.24516 12.5476L8.25029 8.52453C7.24116 8.39478 6.21601 8.47493 5.23952 8.75993C4.26304 9.04493 3.35645 9.52859 2.57696 10.1804C1.89379 10.7717 1.31941 11.4774 0.879674 12.2655C0.712509 12.5527 0.0807715 13.7088 0.00488093 15.5833C-0.0433198 16.6464 0.277677 17.7502 0.430484 18.2051V18.2153C0.52586 18.4831 0.899159 19.3989 1.50628 20.1706C1.99588 20.7901 2.57434 21.3342 3.22305 21.7856V21.7754L3.23228 21.7856C5.15006 23.0837 7.27807 22.9989 7.27807 22.9989C7.64624 22.9835 8.87998 22.9989 10.2809 22.3376C11.8346 21.6037 12.7186 20.511 12.7186 20.511C13.2838 19.858 13.7333 19.1138 14.0477 18.3104C14.4067 17.37 14.5256 16.2436 14.5256 15.7939V7.68946C14.5738 7.71808 15.2148 8.14021 15.2148 8.14021C15.2148 8.14021 16.1378 8.72998 17.5766 9.11328C18.6083 9.38619 20 9.44445 20 9.44445V5.52255C19.5129 5.5757 18.5232 5.42238 17.5089 4.91949L17.51 4.91847Z"
      />
    </svg>
  );
};

export default TikTokIcon;
