import React, { useEffect, useState } from 'react';
import Image from '../Image';
import ProfileAvatar from '../ProfileAvatar';
import Breadcrumb from '../Breadcrumb';
import Button from '../Button';
import SettingIcon from '../../icons/SettingIcon';
import { Typo } from '../../Typo';
import Badge from '../Badge';
import Tab from '../Tab';
import { DropdownOption } from '../../../model/DropdownOption';
import { User } from '../../../model/UserModel';
import { ACCOUNT_TYPE, ROUTES } from '../../../constants/data';
import banner1 from '../../../../src/assets/images/Banner 1.png';
import banner2 from '../../../../src/assets/images/Banner 2.png';
import { getJoinDate } from '../../../util/AppUtil';
import clsx from 'clsx';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useIntl } from 'react-intl';
import { TabModel } from '../../../model/TabModel';
import { handleAccountTypeBadge, handleUserRoleBadge } from '../../../util/ListingBadgeHandler';
import Divider from '../Divider';
import { useDispatch } from 'react-redux';
import { setProfileData, showSlide } from '../../../lib/redux/reducers/slide.slice';
import { useAppSelector } from '../../../lib/redux/hooks';
type Props = {
  user?: User;
  tabs?: TabModel[];
  currentTab?: TabModel;
  onTabChange?: (tab: DropdownOption) => void;
  showBreadCrumb?: boolean;
  title?: string;
  onDeactive?: () => void;
};
const ProfileHeader: React.FC<Props> = (props) => {
  const {
    user,
    tabs = [],
    currentTab,
    onTabChange,
    showBreadCrumb = true,
    title,
    onDeactive
  } = props;
  const [tabsData, setTabsData] = useState<DropdownOption[]>([]);
  const intl = useIntl();
  const accountType = handleAccountTypeBadge(user?.type || '', intl);
  const userRole = handleUserRoleBadge(user?.role?.name || '', intl);
  const dispatch = useDispatch();
  const profileState = useAppSelector((state) => state?.slide.profile);

  useEffect(() => {
    if (user) {
      setTabsData(
        tabs.filter(
          (tab) =>
            (user?.type === ACCOUNT_TYPE.BUSINESS && tab.id !== 1) ||
            (user?.type === ACCOUNT_TYPE.PERSONAL && tab.id !== 2)
        )
      );
    }
  }, [user]);

  return (
    <div className="w-full">
      <div className="w-full relative flex justify-center">
        <Image
          className="w-full h-40 md:h-60"
          src={
            user?.type == ACCOUNT_TYPE.BUSINESS
              ? banner2
              : user?.headerImage?.url
              ? user?.headerImage?.url
              : banner1
          }></Image>
      </div>
      <div className="relative px-4 sm:pl-[60px] sm:pr-[20px] w-full max-w-[95rem] mx-auto">
        <ProfileAvatar
          image={user?.profileImage?.url}
          verfiedClassName="h-5 w-5 sm:h-6 sm:w-6 lg:h-8 lg:w-8 right-[3px] bottom-[3px] sm:right-1 sm:bottom-1 "
          className="absolute top-0 -translate-y-[110%] lg:-translate-y-[60%] left-4 md:left-16  lg:w-40 lg:h-40"></ProfileAvatar>
        <div
          className={`lg:pl-[190px] xl:pl-[180px] mt-[52px] lg:mt-0 ${
            !showBreadCrumb ? ' md:pt-16' : ''
          }`}>
          {showBreadCrumb && (
            <div className={clsx('hidden md:block mb-5')}>
              <Breadcrumb />
            </div>
          )}
          <div
            className={`flex w-full flex-col gap-y-4  ${
              userRole?.name
                ? 'lg:flex-row lg:justify-between lg:items-start'
                : 'md:flex-row md:justify-between md:items-start'
            }`}>
            <div className="flex flex-col gap-y-1">
              <div className="flex items-center gap-x-2">
                <Typo.headingMd fontWeight="font-medium" className="text-gray-900 block sm:hidden">
                  {title ? title : userRole.dashboardName}
                </Typo.headingMd>
                <Typo.headingMd fontWeight="font-medium" className="text-gray-900 hidden sm:block">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.DASHBOARD.id,
                    defaultMessage: TRANSLATED_CONSTANTS.DASHBOARD.defaultMessage
                  })}
                </Typo.headingMd>
                <Badge theme={accountType.theme} label={accountType.name}></Badge>
                <Badge theme={userRole.theme} label={userRole.name}></Badge>
              </div>
              <div className="flex items-center gap-x-2">
                <Typo.textMd fontWeight="font-medium" className=" text-blue-gray-600">
                  @{user?.username}
                </Typo.textMd>
                {user?.createdAt && (
                  <Typo.textMd fontWeight="font-normal" className=" text-gray-400">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.JOINED.id,
                      defaultMessage: TRANSLATED_CONSTANTS.JOINED.defaultMessage
                    })}{' '}
                    {getJoinDate(user?.createdAt)}
                  </Typo.textMd>
                )}
              </div>
            </div>
            <div className=" flex gap-x-3 items-center">
              {onDeactive && (
                <Button onClick={onDeactive} size="md" className=" bg-error-50">
                  <Typo.textSm
                    fontWeight="font-semibold"
                    className="text-error-700 whitespace-nowrap">
                    {`${intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.DEACTIVE_ACCOUNT.id,
                      defaultMessage: TRANSLATED_CONSTANTS.DEACTIVE_ACCOUNT.defaultMessage
                    })}`}
                  </Typo.textSm>
                </Button>
              )}
              <Button
                // disabled={true}
                to={ROUTES.PROFILE}
                icon={<SettingIcon className="h-5 w-5 text-gray-500"></SettingIcon>}
                size="md">
                <Typo.textSm fontWeight="font-semibold" className="text-gray-900 whitespace-nowrap">
                  {`${intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.SETTINGS.id,
                    defaultMessage: TRANSLATED_CONSTANTS.SETTINGS.defaultMessage
                  })}`}
                </Typo.textSm>
              </Button>
              <Button
                onClick={() => {
                  if (!profileState) {
                    dispatch(setProfileData({ userId: user?.id }));
                    dispatch(showSlide('profile'));
                  }
                }}
                theme="primary-yellow"
                size="md">
                <Typo.textSm fontWeight="font-semibold" className="text-gray-900 whitespace-nowrap">
                  {`${intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.VIEW_PROFILE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage
                  })}`}
                </Typo.textSm>
              </Button>
            </div>
            <Divider className="block md:hidden"></Divider>
          </div>
        </div>
        <div className="mt-6 md:mt-12">
          {tabsData?.length > 0 && (
            <Tab
              type="underline"
              tabs={tabsData}
              currentTab={currentTab ? currentTab : tabsData[0]}
              onTabChange={onTabChange && onTabChange}></Tab>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileHeader;
