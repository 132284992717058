import NavigationIcon from '../../../components/icons/NavigationIcon';
import { Typo } from '../../../components/Typo';
import { ROUTES } from '../../../constants/data';
import Button from '../../../components/shared/Button';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import BusinessIcon from '../../../components/icons/BusinessIcon';

const AddBusinessToMap = () => {
  return (
    <div className="w-full 2xl:px-0 px-0 lg:px-[120px]">
      <div className="max-w-7xl relative mx-auto py-16 md:py-24 flex justify-center">
        <div className=" bg-gradient-to-b from-[#FBFAF9] to-[#F9FAFB] py-12 text-center flex-col w-full gap-12 lg:rounded-2xl justify-center">
          <Typo.headingMd fontWeight="font-medium" className=" text-gray-900 -tracking-[1px] ">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS_TITLE.id}
              defaultMessage={TRANSLATED_CONSTANTS.SELLING_AS_BUSINESS_TITLE.defaultMessage}
            />
          </Typo.headingMd>
          <Typo.textXl fontWeight="font-normal" className="text-gray-600 mt-6">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ADD_YOUR_LOCAL_BUSINESS.id}
              defaultMessage={TRANSLATED_CONSTANTS.ADD_YOUR_LOCAL_BUSINESS.defaultMessage}
            />
          </Typo.textXl>

          <div className=" flex gap-x-3 justify-center items-center mt-6 lg:mt-12 hover:cursor-pointer">
            <BusinessIcon />
            <Button
              icon={<NavigationIcon className="w-6 h-6" />}
              iconPos="right"
              theme="gray-link"
              to={ROUTES.CREATE_STOREFRONT_ROUTE}
              size="2xl">
              <Typo.textLg fontWeight="font-semibold" className="text-gray-700 my-auto">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.CREATE_A_STOREFRONT.id}
                  defaultMessage={TRANSLATED_CONSTANTS.CREATE_A_STOREFRONT.defaultMessage}
                />
              </Typo.textLg>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBusinessToMap;
