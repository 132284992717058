import { Transition } from '@headlessui/react';
import React, { FC, Fragment, ReactElement, useState } from 'react';
import Button from './Button';
import CloseIcon from '../icons/CloseIcon';
import clsx from 'clsx';
import ArrowRightIcon from '../icons/ArrowRigthIcon';
import { Typo } from '../Typo';
import { FormattedMessage } from 'react-intl';
import FeaturedIcon from './FeaturedIcon';
import AlertIcon from '../icons/AlertIcon';
import AlertHexagon from '../icons/AlertHexagonIcon';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import FlagIcon from '../icons/FlagIcon';
import InfoIcon from '../icons/InfoIcon';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useDispatch } from 'react-redux';
import { removeNotification } from '../../lib/redux/reducers/notification.slice';

type Props = {
  id: number;
  title: string;
  description: string;
  image?: string;
  type: 'block-ban' | 'flag' | 'warning' | 'quarantine' | 'default' | 'success';
  link?: string;
  linkText?: string;
  linkIcon?: boolean;
};

const Notification: FC<Props> = ({
  id,
  title,
  description,
  image,
  type,
  link,
  linkText,
  linkIcon = false
}) => {
  const dispatch = useDispatch();
  const iconMap: { [key: string]: ReactElement } = {
    'block-ban': (
      <FeaturedIcon theme="light-circle-outline" size="md" color="error">
        <InfoIcon />
      </FeaturedIcon>
    ),
    warning: (
      <FeaturedIcon theme="light-circle-outline" size="md" color="primary">
        <AlertIcon />
      </FeaturedIcon>
    ),
    quarantine: (
      <FeaturedIcon theme="light-circle-outline" size="md" color="warning">
        <AlertHexagon />
      </FeaturedIcon>
    ),
    flag: (
      <FeaturedIcon theme="light-circle-outline" size="md" color="error">
        <FlagIcon />
      </FeaturedIcon>
    ),
    success: (
      <FeaturedIcon theme="light-circle-outline" size="md" color="success">
        <CheckCircleIcon />
      </FeaturedIcon>
    )
  };
  const icon = iconMap[type];

  const [show, setShow] = useState(true);
  return (
    <div
      aria-live="assertive"
      className={`pointer-events-none w-full px-4 sm:items-start ${show ? 'block' : ' hidden'}`}>
      <div className="flex flex-col z-30 items-center sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="pointer-events-auto max-w-[400px] overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
            <div className={clsx({ 'pb-4': !image, ' md:pr-4': image })}>
              <div className="flex justify-between">
                <div
                  className={clsx('flex flex-col md:flex-row gap-3 md:gap-4', {
                    'p-4 md:p-0': image
                  })}>
                  {type !== 'default' && !image && <div className="pt-4 pl-4">{icon}</div>}
                  {image && (
                    <div className="hidden md:block">
                      <img src={image} className="w-[80px] h-full  object-cover " />
                    </div>
                  )}
                  <div
                    className={clsx('flex flex-col flex-1', {
                      'md:my-4': image,
                      'md:pt-4 pl-4 md:pl-0': !image
                    })}>
                    <div className={clsx({ 'mr-4 mb-4 md:mr-0 md:mb-0': image }, '')}>
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-900 mb-1">
                        {title}
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                        {description}
                      </Typo.textSm>
                    </div>

                    {image && (
                      <div className="block mb-4 md:hidden">
                        <img src={image} className="w-full h-[122px]  object-cover rounded-lg" />
                      </div>
                    )}
                    <div className="flex gap-3">
                      <Button
                        onClick={() => {
                          setShow(false);
                          dispatch(removeNotification(id));
                        }}
                        size="sm"
                        theme="gray-link">
                        <Typo.textSm fontWeight="font-semibold" className="text-gray-600 mt-3">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.DISMISS.id}
                            defaultMessage={TRANSLATED_CONSTANTS.DISMISS.defaultMessage}
                          />
                        </Typo.textSm>
                      </Button>
                      {link && (
                        <Button
                          to={link}
                          size="sm"
                          theme="yellow-link"
                          className="flex gap-2 items-center flex-nowrap mt-3">
                          <Typo.textSm
                            fontWeight="font-semibold"
                            className="text-yellow-700 whitespace-nowrap">
                            {linkText}
                          </Typo.textSm>
                          {linkIcon && (
                            <ArrowRightIcon className=" w-5 h-5 text-yellow-700 hover:stroke-yellow-800  " />
                          )}
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={clsx('flex items-start h-10', {
                    'absolute right-9 md:relative md:right-0': image,
                    'pr-4 pt-4': !image
                  })}>
                  <Button
                    size="sm"
                    onClick={() => {
                      setShow(false);
                      dispatch(removeNotification(id));
                    }}
                    className={clsx(image ? 'mt-2' : '-mt-2 ', '-mr-2 ')}>
                    <span>
                      <CloseIcon className="stroke-gray-500 cursor-pointer" />
                    </span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Notification;
