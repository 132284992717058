/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useState } from 'react';
import Divider from '../../../components/shared/Divider';
import Pagination from '../../../components/shared/Pagination';
import BadgeNotification from '../../../components/shared/BadgeNotification';
import ListingCard from '../../../components/shared/ListingCard/ListingCard';
import { LISTING_BREAKPOINT, LIST_PAGES, NUMBER_OF_ITEMS_PER_PAGE } from '../../../constants/data';
import StorefrontItemCard from '../../../components/shared/ListingCard/StorefrontItemCard';
import StorefrontCard from '../../../components/shared/ListingCard/StorefrontCard';
import useDeviceDetect from '../../../hooks/useDeviceDetect';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useHandleSave } from '../../../hooks/useHandleSave';
import Loader from '../../../components/shared/Loader';

type Props = {
  listings: any;
  storefronts: any;
  personalListingsCount: number;
  storefrontsCount: number;
  wantedListingsCount: number;
  storefrontItemsCount: number;
  setPage?: (value: number) => void;
  fetchMore: (value: number) => void;
  updateQuery: (value: any) => void;
  refetch: () => void;
  currentPage: number;
  loading: boolean;
};

const SearchResult: FC<Props> = ({
  listings,
  storefronts,
  personalListingsCount,
  storefrontsCount,
  wantedListingsCount,
  storefrontItemsCount,
  fetchMore,
  updateQuery,
  refetch,
  currentPage,
  loading
}) => {
  const { save } = useHandleSave(refetch);
  const { isMobile } = useDeviceDetect();

  const intl = useIntl();
  const mapData = [...(listings || []), ...(storefronts || [])];

  const totalPages = personalListingsCount + storefrontsCount + wantedListingsCount;
  const paginate = (pageNumber: number) => {
    fetchMore(pageNumber);
  };
  const listingsText = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.LISTINGS.id,
    defaultMessage: TRANSLATED_CONSTANTS.LISTINGS.defaultMessage
  });
  const personalText = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.PERSONAL.id,
    defaultMessage: TRANSLATED_CONSTANTS.PERSONAL.defaultMessage
  });
  const wantedText = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.WANTED.id,
    defaultMessage: TRANSLATED_CONSTANTS.WANTED.defaultMessage
  });
  const storefrontsText = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.STOREFRONTS.id,
    defaultMessage: TRANSLATED_CONSTANTS.STOREFRONTS.defaultMessage
  });
  const storefrontsItemsText = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.STOREFRONT_ITEMS.id,
    defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_ITEMS.defaultMessage
  });
  const wantedListingsText = isMobile ? wantedText : wantedText + ' ' + listingsText;
  const personalListingsText = isMobile ? personalText : personalText + ' ' + listingsText;

  return (
    <div className="">
      <div className="flex justify-start items-center gap-4 flex-wrap">
        <BadgeNotification
          theme="light"
          color="blue"
          title={personalListingsCount.toString()}
          message={personalListingsText}
        />
        <BadgeNotification
          theme="light"
          color="purple"
          title={storefrontsCount.toString()}
          message={storefrontsText}
        />
        <BadgeNotification
          theme="light"
          color="pink"
          title={wantedListingsCount.toString()}
          message={wantedListingsText}
        />
        <BadgeNotification
          theme="light"
          color="indigo"
          title={storefrontItemsCount.toString()}
          message={storefrontsItemsText}
        />
      </div>
      {loading ? (
        <Loader className="h-[400px] flex justify-center items-center " />
      ) : (
        <div className="my-4 pb-4 flex flex-col gap-4 min-h-[300px]  max-h-[calc(100vh-300px)] overflow-y-auto">
          {mapData
            ?.sort(
              (a: any, b: any) =>
                new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime()
            )
            .map((data: any, index: any) => {
              if (data?.type === 'personal' || data?.type === 'wanted') {
                return (
                  <ListingCard
                    cardClickable={true}
                    key={index}
                    listing={data}
                    page={LIST_PAGES.MAP}
                    size={LISTING_BREAKPOINT.DEFAULT}
                    onLike={save}></ListingCard>
                );
              } else if (data?.type === 'storefrontItem') {
                return (
                  <StorefrontItemCard
                    cardClickable={true}
                    key={index}
                    listing={data}
                    storeFront={data.storefront}
                    page={LIST_PAGES.MAP}
                    size={LISTING_BREAKPOINT.DEFAULT}
                    onLike={save}></StorefrontItemCard>
                );
              } else {
                return (
                  <StorefrontCard
                    cardClickable={true}
                    key={index}
                    storeFront={data}
                    page={LIST_PAGES.MAP}
                    size={LISTING_BREAKPOINT.DEFAULT}
                    onLike={save}
                  />
                );
              }
            })}
        </div>
      )}
      <Divider />
      <Pagination
        currentPage={currentPage}
        totalCount={totalPages}
        pageSize={NUMBER_OF_ITEMS_PER_PAGE}
        onPageChange={paginate}
        type="centered-page-number"
      />
    </div>
  );
};

export default SearchResult;
