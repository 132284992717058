import { FC } from 'react';
import { Typo } from '../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import ReportButton from '../../components/shared/ReportButton';
import DirectionButton from './DirectionButton';
import OperationHourLabel from '../../components/shared/Hours Operation/OperationHourLabel';
import { OperationHourModel } from '../../model/OperationHourModel';

type Props = {
  operationHours: OperationHourModel[];
  showReportModal: (value: boolean) => void;
  getDirection: () => void;
  reportDisabled?: boolean;
};

const StorefrontCard: FC<Props> = ({
  operationHours,
  reportDisabled,
  showReportModal,
  getDirection
}) => {
  return (
    <div className="p-6 bg-gray-50 w-full rounded-xl border border-gray-200 flex-col justify-start items-center lg:h-full">
      <Typo.textLg fontWeight="font-medium" className="text-gray-900 mb-2">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.STOREFRONT.id}
          defaultMessage={TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage}
        />
      </Typo.textLg>
      <Typo.textSm fontWeight="font-normal" className="text-gray-500 mb-6">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.STOREFRONT_FINDOUT_TEXT.id}
          defaultMessage={TRANSLATED_CONSTANTS.STOREFRONT_FINDOUT_TEXT.defaultMessage}
        />
      </Typo.textSm>
      <div className="w-auto flex">
        <OperationHourLabel defaultValue={operationHours}></OperationHourLabel>
      </div>
      <div className="flex gap-3 w-full mt-5">
        <ReportButton
          className="w-full"
          disabled={reportDisabled}
          openReportModal={(value: boolean) => showReportModal(value)}
        />
        <DirectionButton className="w-full" getDirection={() => getDirection()} />
      </div>
    </div>
  );
};

export default StorefrontCard;
