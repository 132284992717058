import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, ReactElement } from 'react';
import InputField from '../shared/InputField';
import SearchIcon from '../icons/SearchIcon';
import Divider from '../shared/Divider';
import Tooltip from '../shared/Tooltip';
import HelpIcon from '../icons/HelpIcon';

type Props = {
  isOpen: boolean;
  defaultValue?: string;
  onSearch: (value: string) => void;
  onClose: () => void;
  children: ReactElement;
  placeholder: string;
};
const CommandMenus: FC<Props> = ({
  isOpen,
  onClose,
  onSearch,
  children,
  placeholder,
  defaultValue = ''
}) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full  justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 w-full sm:w-[640px]  md:max-w-[640px] ">
                <div className="w-full">
                  <div className="w-full pt-4 flex">
                    <InputField
                      prefix={<SearchIcon className="h-4 w-4 text-gray-400" />}
                      name="searchInput"
                      placeholder={placeholder}
                      value={defaultValue}
                      type="text"
                      className="h-10"
                      inputStyle="border-transparent focus:border-transparent focus:ring-0"
                      onChange={(e: any) => onSearch(e?.target?.value)}
                      register={() => ({})}
                    />
                    <div className="mr-4 mt-2">
                      <Tooltip title="This is a tooltip" theme="dark" arrow="right">
                        <HelpIcon className="text-gray-400" />
                      </Tooltip>
                    </div>
                  </div>
                  <Divider />
                  <div className="w-full flex ">{children}</div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default CommandMenus;
