import React, { FC } from 'react';
import { User } from '../../../../model/UserModel';
import { ACCOUNT_TYPE, REPORT_PAGES } from '../../../../constants/data';
import ProfileAvatar from '../../../../components/shared/ProfileAvatar';
import Image from '../../../../components/shared/Image';
import banner1 from '../../../../assets/images/Banner 1.png';
import banner2 from '../../../../assets/images/Banner 2.png';
import { Typo } from '../../../../components/Typo';
import { handleAccountTypeBadge } from '../../../../util/ListingBadgeHandler';
import { FormattedMessage, useIntl } from 'react-intl';
import Badge from '../../../../components/shared/Badge';
import Divider from '../../../../components/shared/Divider';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import { getJoinDate } from '../../../../util/AppUtil';
import Button from '../../../../components/shared/Button';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { setProfileData, showSlide } from '../../../../lib/redux/reducers/slide.slice';
import { handleFlagsBadge, handleWarningsBadge } from '../../../../util/ReportUtil';
type Props = {
  pageType: 'listing-flag' | 'listing-quarantine' | 'listing-deleted-warned' | 'message-report';
  user: User;
  openBanModal?: (user: User) => void;
  warnings: number;
  flags: number;
};
const ListingDetailedProfileHeader: FC<Props> = (props) => {
  const { pageType, user, openBanModal, warnings, flags } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const profileState = useAppSelector((state) => state.slide.profile);
  const accountType = handleAccountTypeBadge(user?.type || '', intl);

  return (
    <div className="w-full">
      <div className="h-fit">
        <div className="w-full relative flex">
          <Image
            className="w-full h-40 md:h-[200px]"
            src={user?.type == ACCOUNT_TYPE.BUSINESS ? banner2 : banner1}></Image>
          <ProfileAvatar
            image={user?.profileImage?.url}
            verfiedClassName=" h-5 w-5 sm:h-6 sm:w-6 lg:h-8 lg:w-8 right-[3px] bottom-[3px] sm:right-1 sm:bottom-1 "
            className=" absolute bottom-0 translate-y-[50%] lg:translate-y-[50%] left-4 md:left-8 lg:w-24 lg:h-24"></ProfileAvatar>
        </div>
      </div>
      <div className="mt-12 pl-8">
        <div className="pt-6  w-full">
          <div className="flex items-center justify-start truncate">
            <Typo.headingSm fontWeight="font-medium" className="text-gray-900 truncate">
              {user?.name}
            </Typo.headingSm>
            <div className="pl-2 flex gap-1">
              <Badge hasDot={false} theme={accountType.theme} label={accountType.name}></Badge>{' '}
              <Badge theme="error" label={handleFlagsBadge(flags, intl)} />
              {(pageType === REPORT_PAGES.LISTING_QUARANTINE ||
                pageType === REPORT_PAGES.LISTING_DELETED_WARNED ||
                pageType === REPORT_PAGES.MESSAGE_REPORT) && (
                <Badge theme="primary" label={handleWarningsBadge(warnings, intl)} />
              )}
            </div>
          </div>
        </div>
        <div className="mt-1 flex gap-2 ">
          <Typo.textMd fontWeight="font-medium" className="text-gray-600">
            @{user?.username}
          </Typo.textMd>
          {user?.createdAt && (
            <Typo.textMd fontWeight="font-normal" className=" text-gray-400">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.JOINED.id,
                defaultMessage: TRANSLATED_CONSTANTS.JOINED.defaultMessage
              })}{' '}
              {getJoinDate(user?.createdAt)}
            </Typo.textMd>
          )}
        </div>
        <div className="pb-6 mt-4 flex gap-3">
          <Button
            size="md"
            theme="base"
            onClick={() => {
              openBanModal && openBanModal(user);
            }}>
            <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON.id}
                defaultMessage={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON.defaultMessage}
              />
            </Typo.textSm>
          </Button>
          <Button
            size="md"
            theme="primary-yellow"
            onClick={() => {
              if (!profileState) {
                dispatch(setProfileData({ userId: user?.id }));
                dispatch(showSlide('profile'));
              }
            }}>
            <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
              />
            </Typo.textSm>
          </Button>
        </div>
        <Divider />
      </div>
    </div>
  );
};

export default ListingDetailedProfileHeader;
