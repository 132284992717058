import React, { useMemo, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import clsx from 'clsx';
import { getCountryCode } from '../../../util/AppUtil';
import { Country } from '../../../model/Location';
import MapPinIcon from '../../icons/MapPinIcon';
import LocationDropdownItem from './LocationDropdownItem';
import { CircleFlag } from 'react-circle-flags';
import { Typo } from '../../Typo';
type Props = {
  country?: Country;
  onSelectCountry?: (country: Country) => void;
  placeholder?: string;
  label?: string;
  hintText?: string;
  className?: string;
};

const CountryDropdown: React.FC<Props> = (props) => {
  const { onSelectCountry, country, placeholder, label, hintText, className } = props;
  const [place, setPlace] = useState<Country | undefined>(country);
  const handleChange = (address: string) => {
    setPlace({ countryName: address });
  };

  useMemo(() => {
    setPlace(country);
  }, [country]);

  const handleSelect = async (address: string, placeId: string) => {
    const countryCode = await getCountryCode(placeId);
    setPlace({ countryName: address, countryCode: countryCode });
    onSelectCountry && onSelectCountry({ countryName: address, countryCode: countryCode || '' });
  };

  const searchOptions = {
    types: ['country']
  };
  return (
    <div className={clsx('realative flex flex-col', className)}>
      {label && (
        <Typo.textSm fontWeight="font-normal" className="text-gray-700 mb-1.5">
          {label}
        </Typo.textSm>
      )}
      <PlacesAutocomplete
        value={place?.countryName}
        onSelect={handleSelect}
        onChange={handleChange}
        searchOptions={searchOptions}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className="relative w-full">
            <input
              {...getInputProps({
                placeholder: placeholder,
                className: clsx(
                  ' relative w-full rounded-lg border border-gray-300 pl-10 shadow-xs bg-white py-2.5 px-3.5 font-inter text-base placeholder:text-gray-500 text-gray-900 focus:outline-none  focus:ring-4 focus:ring-gray-100 focus:border-gray-300'
                )
              })}
            />

            {place?.countryCode ? (
              <CircleFlag
                countryCode={place.countryCode}
                height="24"
                width="24"
                className=" h-6 w-6 text-gray-500 absolute top-2.5 left-2"
              />
            ) : (
              <MapPinIcon className="h-5 w-5 text-gray-500 absolute top-3 left-2"></MapPinIcon>
            )}

            <div
              className={` z-10 absolute w-full rounded-lg mt-[6px] ${
                suggestions.length > 0 ? ' border border-gray-300 block' : ' hidden '
              }`}>
              {suggestions?.map((suggestion, index: number) => {
                const className = suggestion.active ? ' text-gray-900' : ' text-gray-700 ';
                return (
                  <div key={index}>
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: clsx(className)
                      })}>
                      <LocationDropdownItem
                        placeId={suggestion.placeId}
                        isSelected={suggestion.active}
                        description={suggestion.description}
                        className={`${suggestions.length - 1 === index ? 'rounded-b-lg' : ''} ${
                          index == 0 ? 'rounded-t-lg' : ''
                        }`}></LocationDropdownItem>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {hintText ? (
        <Typo.textSm fontWeight="font-normal" className=" mt-2 text-gray-400">
          {hintText}
        </Typo.textSm>
      ) : (
        ''
      )}
    </div>
  );
};

export default CountryDropdown;
