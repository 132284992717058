import { useEffect, useState } from 'react';
import { useUpdateUser } from '../../../hooks/user/useUpdateUser';
import { ACCOUNT_TYPE } from '../../../constants/data';
import RadioGroupComponent from '../../../components/shared/RadioGroupComponent';
import { Radio } from '../../../model/Radio';
import React from 'react';
import avatarGroupBusiness from '../../../assets/avatar/Avatar group business.png';
import avatarGroupSellers from '../../../assets/avatar/Avatar group sellers.png';
import { Typo } from '../../../components/Typo';
import Button from '../../../components/shared/Button';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useDispatch } from 'react-redux';

type Props = {
  onSelectedType: (accountType: string) => void;
  auth: any;
};

const ChooseAccountStep: React.FC<Props> = (props) => {
  const { onSelectedType, auth } = props;
  const updateUser = useUpdateUser();
  const user = auth?.getUser();
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const radioGroupOptions: Radio[] = [
    {
      id: 1,
      type: '',
      image: avatarGroupSellers,
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT_DESCRIPTION.defaultMessage
      }),
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT.id,
        defaultMessage: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT.defaultMessage
      }),
      message: `*${intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT_MESSAGE.id,
        defaultMessage: TRANSLATED_CONSTANTS.PERSONAL_ACCOUNT_MESSAGE.defaultMessage
      })}`
    },
    {
      id: 2,
      type: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.STOREFRONT.id,
        defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage
      }),
      image: avatarGroupBusiness,
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BUSINESS_ACCOUNT_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.BUSINESS_ACCOUNT_DESCRIPTION.defaultMessage
      }),
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BUSINESS.id,
        defaultMessage: TRANSLATED_CONSTANTS.BUSINESS.defaultMessage
      }),
      message: `*${intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BUSINESS_ACCOUNT_MESSAGE.id,
        defaultMessage: TRANSLATED_CONSTANTS.BUSINESS_ACCOUNT_MESSAGE.defaultMessage
      })}`
    }
  ];

  const [selectedOption, setSelectedOption] = useState<Radio>(radioGroupOptions[0]);

  const onSubmit = async () => {
    const account_type = selectedOption?.id == 1 ? ACCOUNT_TYPE.PERSONAL : ACCOUNT_TYPE.BUSINESS;
    try {
      setIsSubmiting(true);
      const response = await updateUser({
        variables: {
          data: {
            type: account_type
          },
          id: auth?.getMe()?.id
        }
      });
      if (response) {
        onSelectedType(account_type);
        auth?.userRefetch();
      }
    } catch (e) {
    } finally {
      setIsSubmiting(false);
    }
  };

  useEffect(() => {
    setSelectedOption(
      user?.type == ACCOUNT_TYPE.PERSONAL ? radioGroupOptions[0] : radioGroupOptions[1]
    );
  }, [user]);

  return (
    <div className="w-full px-4 pt-12 flex flex-col justify-center items-center sm:pt-24">
      <div className="max-w-xl">
        <Typo.textXl fontWeight="font-medium" className="text-gray-900">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.CHOOSE_YOUR_ACCOUNT_TYPE.id,
            defaultMessage: TRANSLATED_CONSTANTS.CHOOSE_YOUR_ACCOUNT_TYPE.defaultMessage
          })}
        </Typo.textXl>
        <RadioGroupComponent
          options={radioGroupOptions}
          selectedOption={selectedOption}
          className="w-full mt-8"
          onChange={setSelectedOption}></RadioGroupComponent>
        <Button
          onClick={onSubmit}
          theme="primary-yellow"
          loading={isSubmiting}
          type="button"
          className="w-full mt-8">
          <Typo.textMd fontWeight="font-semibold">
            {intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CONTINUE.id,
              defaultMessage: TRANSLATED_CONSTANTS.CONTINUE.defaultMessage
            })}
          </Typo.textMd>
        </Button>
      </div>
    </div>
  );
};

export default ChooseAccountStep;
