import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_QUARANTINE_STOREFRONTS = gql`
  query getQuarantineStorefronts($offset: Int, $limit: Int, $searchText: String) {
    quarantineStorefrontsCount: listings(
      filters: {
        issued_listing: { actionType: { eq: "quarantine" } }
        or: [
          { user: { username: { contains: $searchText } } }
          { user: { name: { contains: $searchText } } }
          { name: { contains: $searchText } }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    storeFronts(
      pagination: { limit: $limit, start: $offset }
      filters: {
        issued_listing: { actionType: { eq: "quarantine" } }
        or: [
          { user: { username: { contains: $searchText } } }
          { user: { name: { contains: $searchText } } }
          { name: { contains: $searchText } }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          issued_listing {
            data {
              id
              attributes {
                claim
                detail
                date
                actionType
                issuer {
                  data {
                    id
                  }
                }
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
                name
                type
                email
                createdAt
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetQuarantineStorefronts = (offset: number, limit: number, searchText: string) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_QUARANTINE_STOREFRONTS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  return {
    quarantineStorefronts: flattenObj(data?.storeFronts),
    quarantineStorefrontsCount: data?.quarantineStorefrontsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
