import ClockRewindIcon from '../components/icons/ClockRewindIcon';
import GlassesIcon from '../components/icons/GlassesIcon';
import KeyIcon from '../components/icons/KeyIcon';
import LockUnlockedIcon from '../components/icons/LockUnlockedIcon';
import NotificationPointerIcon from '../components/icons/NavigationPointerIcon';
import NotificationBoxIcon from '../components/icons/NotificationBoxIcon';
import SlashCircleIcon from '../components/icons/SlashCircleIcon';
import User1Icon from '../components/icons/User1Icon';
import UserXIcon from '../components/icons/UserXIcon';
import { ROLES, ROLE_TYPES, ROUTES } from '../constants/data';
import { NavigationModel } from '../model/Navigation';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';

export const settingsMenu = (intl: any, pendingRoles?: any) => {
  const settingItems: NavigationModel[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PROFILE.id,
        defaultMessage: TRANSLATED_CONSTANTS.PROFILE.defaultMessage
      }),
      icon: <User1Icon className=" h-6 w-6 text-gray-500" />,
      link: ROUTES.PROFILE,
      roles: ['', ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.NOTIFICATIONS.id,
        defaultMessage: TRANSLATED_CONSTANTS.NOTIFICATIONS.defaultMessage
      }),
      icon: <NotificationBoxIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.NOTIFICATIONS_ROUTE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REFERRAL_HISTORY.id,
        defaultMessage: TRANSLATED_CONSTANTS.REFERRAL_HISTORY.defaultMessage
      }),
      icon: <ClockRewindIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.REFERRAL_HISTORY_ROUTE,
      roles: ['', ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 4,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.CHANGE_PASSWORD.id,
        defaultMessage: TRANSLATED_CONSTANTS.CHANGE_PASSWORD.defaultMessage
      }),
      icon: <LockUnlockedIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.CHANGE_PASSWORD_ROUTE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 5,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BLOCKED_USERS.id,
        defaultMessage: TRANSLATED_CONSTANTS.BLOCKED_USERS.defaultMessage
      }),
      icon: <SlashCircleIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.BLOCKED_USERS_ROUTE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 6,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.MEET_UP_LOCATION.id,
        defaultMessage: TRANSLATED_CONSTANTS.MEET_UP_LOCATION.defaultMessage
      }),
      icon: <NotificationPointerIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.LOCATION_MEETUP_ROUTE,
      roles: [ROLES.MODERATOR, ROLES.ADMIN, ROLES.REGULAR_USER, ROLES.SUPER_ADMIN]
    },
    {
      id: 7,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.BANNED_USERS.id,
        defaultMessage: TRANSLATED_CONSTANTS.BANNED_USERS.defaultMessage
      }),
      icon: <UserXIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.BANNED_USERS_ROUTE,
      roles: [ROLES.SUPER_ADMIN, ROLES.ADMIN]
    },
    {
      id: 8,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.ADMIN.id,
        defaultMessage: TRANSLATED_CONSTANTS.ADMIN.defaultMessage
      }),
      icon: <KeyIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.ADMIN_SETTINGS_ROUTE,
      roles:
        pendingRoles?.length > 0 && pendingRoles[0]?.role == ROLE_TYPES.ADMIN
          ? [ROLES.MODERATOR]
          : []
    },
    {
      id: 9,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.MODERATOR.id,
        defaultMessage: TRANSLATED_CONSTANTS.MODERATOR.defaultMessage
      }),
      icon: <GlassesIcon className="h-6 w-6 text-gray-500" />,
      link: ROUTES.MODERATOR_SETTINGS_ROUTE,
      roles:
        pendingRoles?.length > 0 && pendingRoles[0]?.role == ROLE_TYPES.MODERATOR
          ? [ROLES.REGULAR_USER]
          : []
    }
  ];
  return settingItems;
};
