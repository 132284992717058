type Props = {
  className?: string;
};
const FilterIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-5 h-5 text-gray-700 ' + className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 10H15M2.5 5H17.5M7.5 15H12.5"
        strokeWidth="1.67"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FilterIcon;
