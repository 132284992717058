/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import ClockIcon from '../../icons/ClockIcon';
import DescriptionLabel from '../DescriptionLabel';
import DotIcon from '../../icons/DotIcon';
import { FormattedMessage } from 'react-intl';
import { OperationHourModel } from '../../../model/OperationHourModel';
import { changeTime24, get24OpenDays } from '../../../util/AppUtil';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';

type OperationPropsType = {
  defaultValue?: OperationHourModel[];
  className?: string;
};

const OperationHourLabel: FC<OperationPropsType> = ({ defaultValue, className }) => {
  const currentDate = new Date();
  const currentDayIndex = currentDate.getDay();
  // Get the current date and time
  const openDays = get24OpenDays(defaultValue || []);
  const currentHourOperation = defaultValue && defaultValue[currentDayIndex];

  const currentTime = new Date();
  const closeTime =
    currentHourOperation?.closesAt && changeTime24(currentHourOperation?.closesAt.toString());
  const openTime =
    currentHourOperation?.opensAt && changeTime24(currentHourOperation?.opensAt.toString());

  const isOpen =
    (openTime && closeTime && currentTime > openTime && currentTime < closeTime) ||
    currentHourOperation?.isOpen;
  return (
    <div className={className}>
      {isOpen ? (
        <div className=" flex items-center">
          <DescriptionLabel icon={<ClockIcon className="text-gray-400"></ClockIcon>}>
            <div className="flex items-center">
              <span>
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.OPEN.id}
                  defaultMessage={TRANSLATED_CONSTANTS.OPEN.defaultMessage}></FormattedMessage>
              </span>
              <DotIcon className=" w-[3px] h-[3px]  text-gray-400 mx-1"></DotIcon>
              <span>
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.CLOSES.id}
                  defaultMessage={TRANSLATED_CONSTANTS.CLOSES.defaultMessage}></FormattedMessage>
                {' ' + currentHourOperation?.closesAt}
              </span>
            </div>
          </DescriptionLabel>
        </div>
      ) : openDays == 7 ? (
        <DescriptionLabel
          className="uppercase"
          icon={<ClockIcon className="text-gray-400 "></ClockIcon>}>
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.HOURS_24.id}
            defaultMessage={TRANSLATED_CONSTANTS.HOURS_24.defaultMessage}></FormattedMessage>
        </DescriptionLabel>
      ) : (
        <DescriptionLabel
          className="uppercase"
          icon={<ClockIcon className="text-gray-400 "></ClockIcon>}>
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.CLOSED.id}
            defaultMessage={TRANSLATED_CONSTANTS.CLOSED.defaultMessage}></FormattedMessage>
        </DescriptionLabel>
      )}
    </div>
  );
};

export default OperationHourLabel;
