import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_STOREFRONTS_ITEMS = gql`
  query getStorefrontItems(
    $userId: ID
    $storefrontId: ID
    $offset: Int
    $limit: Int
    $skip: Boolean!
  ) {
    listings(
      filters: {
        and: [{ type: { eq: "storefrontItem" } }, { store_front: { id: { eq: $storefrontId } } }]
      }
      pagination: { limit: $limit, start: $offset }
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          user {
            data {
              id
              attributes {
                username
                name
              }
            }
          }
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              id
              attributes {
                url
              }
            }
          }
          name
          description
          isSold
          location {
            address
            latitude
            longitude
            state
            country
            city
            street
          }
          slug
          price {
            priceType
            priceInUSD
            priceInDoge
            alternatePriceOptions
          }
          type
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          item_condition {
            data {
              id
              attributes {
                name
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
                name
              }
            }
          }
          saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_STOREFRONTS = gql`
  query getStorefronts($userId: ID, $limit: Int, $offset: Int, $order: [String]) {
    storeFronts(
      pagination: { start: $offset, limit: $limit }
      sort: $order
      filters: { user: { id: { eq: $userId } } }
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          image(sort: ["createdAt:DESC"]) {
            data {
              id
              attributes {
                url
              }
            }
          }
          name
          description
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          user {
            data {
              id
              attributes {
                name
              }
            }
          }
          saved_storefronts(filters: { user: { id: { eq: $userId } } }) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          location {
            address
            latitude
            longitude
          }
          operationHours {
            id
            day
            isOpen
            opensAt
            closesAt
          }
          publishedAt
        }
      }
    }
  }
`;

export const useGetStorefrontItems = (
  userId: string | number,
  storefrontId: number,
  limit: number,
  offset: number
) => {
  let skip = true;
  if (userId) {
    skip = false;
  }
  const { loading, error, data, fetchMore, updateQuery, refetch } = useQuery(
    GET_STOREFRONTS_ITEMS,
    {
      variables: { userId, storefrontId, limit, offset, skip },
      fetchPolicy: 'cache-and-network'
    }
  );

  return {
    listings: flattenObj(data?.listings),
    loading: loading,
    error: error,
    total: data?.listings?.meta?.pagination?.total,
    fetchMore,
    refetch,
    updateQuery
  };
};

export const useGetStorefronts = (
  userId: string | number,
  limit?: number,
  offset?: number,
  order?: string
) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_STOREFRONTS, {
    variables: { userId, limit, offset, order },
    fetchPolicy: 'cache-and-network'
  });
  return {
    storeFronts: flattenObj(data?.storeFronts),
    total: data?.storeFronts?.meta?.pagination?.total,
    loading,
    error,
    fetchMore,
    refetch
  };
};
