/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from '@apollo/client';
import { GET_LISTINGS_AND_STOREFRONTS } from './marketPlaceQueries';
import { CheckboxOption } from '../../model/CheckboxOption';
import { LocationBound } from '../../model/Location';
import { flattenObj } from '../../util/flattenObj';
import { MarketPlaceFilters } from '../../model/MarketPlace';
import {
  generateListingFilters,
  getCountFilters,
  getStorefrontFilters,
  handleMapPageData
} from '../../util/AppUtil';
import { customizeListings, customizeStorefronts } from './transform';

const getMapData = (variables: MarketPlaceFilters) => {
  const { loading, error, data, fetchMore, updateQuery, refetch } = useQuery(
    GET_LISTINGS_AND_STOREFRONTS,
    {
      variables: {
        ...variables,
        filters: generateListingFilters(variables),
        personalCountFilters: getCountFilters(variables, 'personal'),
        wantedCountFilters: getCountFilters(variables, 'wanted'),
        storefrontItemsCountFilters: getCountFilters(variables, 'storefrontItem'),
        storefrontFilters: getStorefrontFilters(variables)
      },
      fetchPolicy: 'cache-and-network'
    }
  );

  const listings = flattenObj(data?.listings);
  const storefronts = flattenObj(data?.storeFronts);
  const customizedListings = customizeListings(listings);
  const customizedStorefronts = customizeStorefronts(storefronts);
  const mapData = handleMapPageData(customizedListings, customizedStorefronts, variables.type);

  return {
    loading,
    error,
    fetchMore,
    refetch,
    updateQuery,
    listings: mapData?.listingsData,
    personalListingsCount: data?.personalListingsCount?.meta?.pagination.total,
    wantedListingsCount: data?.wantedListingsCount?.meta?.pagination.total,
    storefrontItemsCount: data?.storefrontItemsCount?.meta?.pagination.total,
    storefronts: mapData?.storefrontsData,
    storefrontsCount: data?.storeFrontsCount?.meta?.pagination.total
  };
};
export const useGetMarketPlaceData = (
  limit: number,
  type: string,
  moreFilter: any,
  categories: CheckboxOption[] | undefined,
  searchText: string,
  locationBounds: LocationBound,
  listingOffset: number,
  storefrontOffset: number,
  order?: string,
  userId?: string
) => {
  let skip = true;
  const { maxLat, maxLng, minLat, minLng } = locationBounds;
  const categoryIds =
    categories && categories?.length > 0
      ? categories?.map((category: { value: string }) => category.value)
      : moreFilter?.categories?.map((category: { value: string }) => category.value);
  const conditions = moreFilter?.conditions?.map(
    (item_condition: { value: string }) => item_condition.value
  );
  const types = moreFilter?.listings?.map((listing: { value: string }) => listing.value);
  const alternatePriceOptions = moreFilter?.alternatePriceOptions?.map(
    (alternatePrice: { value: string }) => alternatePrice.value
  );
  const priceType = moreFilter?.priceType?.map((priceType: { value: string }) => priceType.value);
  if (userId) {
    skip = false;
  }
  return getMapData({
    type,
    listingOffset,
    storefrontOffset,
    limit,
    order,
    searchText,
    categoryIds,
    types,
    conditions,
    maxLat,
    maxLng,
    minLat,
    minLng,
    alternatePriceOptions,
    priceType,
    userId,
    skip
  });
};
