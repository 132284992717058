import { gql, useMutation } from '@apollo/client';
interface SavedListingInput {
  variables: {
    data: { user: number; listing: number; publishedAt?: string };
  };
}

export const CREATE_SAVED_LISTING = gql`
  mutation createSavedListing($data: SavedListingInput!) {
    createSavedListing(data: $data) {
      data {
        id
      }
    }
  }
`;

export const useSaveListing = (): ((storeSavedListing: SavedListingInput) => any) => {
  const [createSavedListingResult] = useMutation(CREATE_SAVED_LISTING);
  return createSavedListingResult;
};
