import Badge from '../components/shared/Badge';
import { CheckboxOption } from '../model/CheckboxOption';

export const dataTypes: CheckboxOption[] = [
  {
    value: 'storefront',
    label: <Badge size="sm" theme="purple" label="Storefront"></Badge>
  },
  {
    value: 'storefrontItem',
    label: <Badge size="sm" theme="indigo" label="Storefront Items"></Badge>
  },
  {
    value: 'personal',
    label: <Badge size="sm" theme="blue" label="Personal Listings"></Badge>
  },
  {
    value: 'wanted',
    label: <Badge size="sm" theme="pink" label="Wanted Listing"></Badge>
  }
];

export const alertnatePriceOptions: CheckboxOption[] = [
  {
    value: 'Negotiable (no price)',
    label: 'Negotiable (no price)'
  },
  {
    value: 'Doge = Doge',
    label: '1 Doge = 1 Doge'
  }
];
export const priceType: CheckboxOption[] = [
  {
    value: 'Price is firm',
    label: 'Price is firm'
  },
  {
    value: 'Open for offers',
    label: 'Open for offers'
  }
];

export const defaultConditions: CheckboxOption[] = [
  {
    value: 'Brand new',
    label: 'Brand new'
  },
  {
    value: 'Excellent condition',
    label: 'Excellent condition'
  },
  {
    value: 'Fair condition',
    label: 'Fair condition'
  },
  {
    value: 'Good condition',
    label: 'Good condition'
  }
];
