import Button from '../Button';
import React from 'react';
import ShareIcon from '../../icons/ShareIcon';
import EditIcon from '../../icons/EditIcon';
import TrashIcon from '../../icons/TrashIcon';
import DropdownMenu from '../DropdownMenu';
import { DropdownOption } from '../../../model/DropdownOption';
import { useIntl } from 'react-intl';
import { LISTING_BREAKPOINT } from '../../../constants/data';
import clsx from 'clsx';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
type PriceProps = {
  id: number | string;
  size?: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  onEdit?: (id: number | string) => void;
  onDelete?: (id: number | string) => void;
  onShare?: (id: number | string) => void;
};
const ListingCardAction: React.FC<PriceProps> = (props) => {
  const { size = LISTING_BREAKPOINT.DEFAULT, onDelete, onEdit, onShare, id } = props;
  const intl = useIntl();
  const actions: DropdownOption[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SHARE.id,
        defaultMessage: TRANSLATED_CONSTANTS.SHARE.defaultMessage
      }),
      icon: <ShareIcon className="h-5 w-5 text-gray-500"></ShareIcon>
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.EDIT.id,
        defaultMessage: TRANSLATED_CONSTANTS.EDIT.defaultMessage
      }),
      icon: <EditIcon></EditIcon>
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.DELETE.id,
        defaultMessage: TRANSLATED_CONSTANTS.DELETE.defaultMessage
      }),
      icon: <TrashIcon className="h-5 w-5"></TrashIcon>
    }
  ];

  const onChangeAcction = (option: DropdownOption) => {
    if (option.id === 1) {
      onShare && onShare(id);
    } else if (option.id === 2) {
      onEdit && onEdit(id);
    } else if (option.id === 3) {
      onDelete && onDelete(id);
    }
  };
  return (
    <div>
      <div
        className={clsx('flex gap-x-2', {
          '  items-start hidden sm:block': size == LISTING_BREAKPOINT.DEFAULT,
          ' hidden': size == LISTING_BREAKPOINT.MOBILE || LISTING_BREAKPOINT.SLIDE
        })}>
        <Button
          size="lg"
          theme="gray"
          hasOpacity={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation(); // Prevent link navigation
            onChangeAcction(actions[0]);
          }}
          icon={<ShareIcon className="h-5 w-5 text-gray-500 "></ShareIcon>}></Button>
        <Button
          size="lg"
          theme="gray"
          hasOpacity={false}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation(); // Prevent link navigation
            onChangeAcction(actions[1]);
          }}
          icon={<EditIcon className="h-5 w-5 text-gray-500"></EditIcon>}></Button>
        <Button
          size="lg"
          hasOpacity={false}
          theme="gray"
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            e.stopPropagation(); // Prevent link navigation
            onChangeAcction(actions[2]);
          }}
          icon={<TrashIcon className="h-5 w-5 text-gray-500"></TrashIcon>}></Button>
      </div>
      <DropdownMenu
        onChange={onChangeAcction}
        type="v-dots"
        className={clsx({
          'block sm:hidden': size == LISTING_BREAKPOINT.DEFAULT,
          'block ': size == LISTING_BREAKPOINT.MOBILE || size == LISTING_BREAKPOINT.SLIDE
        })}
        options={actions}></DropdownMenu>
    </div>
  );
};

export default ListingCardAction;
