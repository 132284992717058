/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useEffect, useState } from 'react';
import ButtonGroup from './../ButtonGroup';
import Button from './../Button';
import LayoutGridIcon from '../../icons/LayoutGridIcon';
import LayoutAltIcon from '../../icons/LayoutAltIcon';
import { useRole } from '../../../hooks/useRole';
import { ROLES } from '../../../constants/data';
import { useDispatch } from 'react-redux';
import { addListingSearchInput, addReportLayout } from '../../../lib/redux/reducers/report.slice';
import { ButtonGroupModel } from '../../../model/ButtonGroupModel';
import clsx from 'clsx';
import InputField from '../InputField';
import SearchIcon from '../../icons/SearchIcon';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useAppSelector } from '../../../lib/redux/hooks';

type Props = {
  searchHandler?: () => void;
  addHandler?: () => void;
  groupButtonHandler?: (option: ButtonGroupModel) => void;
  filterHandler?: () => void;
  type?: 'with-group-button' | 'with-filter' | 'none';
};

const layoutButtons = [
  {
    id: 1,
    icon: <LayoutGridIcon className=" w-5 h-5 " />,
    content: 'grid'
  },
  {
    id: 2,
    icon: <LayoutAltIcon className=" w-5 h-5 " />,
    content: 'alt'
  }
];

const TableBanner: FC<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { searchHandler, addHandler, groupButtonHandler, filterHandler, type } = props;
  const gridLayout = useAppSelector((state) => state.report.reportLayout);

  const isAdmin = useRole(ROLES.ADMIN);
  const [clickedButton, setClickedButton] = useState<ButtonGroupModel>(layoutButtons[0]);

  useEffect(() => {
    if (gridLayout.type === 'grid') {
      setClickedButton(layoutButtons[0]);
    } else {
      setClickedButton(layoutButtons[1]);
    }
  }, [gridLayout]);

  const handleLayoutClick = (layoutButton: ButtonGroupModel) => {
    setClickedButton(layoutButton);
    if (layoutButton.id === 1) {
      dispatch(addReportLayout({ reportLayout: { type: 'grid', index: 0 } }));
    } else {
      dispatch(addReportLayout({ reportLayout: { type: 'detailed', index: 0 } }));
    }
  };

  const register = () => {};
  return (
    <div className="w-full px-4 py-3 border-y-[1px] border-y-gray-200 flex flex-col gap-4 md:flex-row md:justify-between">
      <div>
        {type === 'with-group-button' ? (
          <ButtonGroup
            onChange={(option: ButtonGroupModel) =>
              groupButtonHandler && groupButtonHandler(option)
            }
            items={[
              { id: 1, content: 'Flags', disabled: false },
              { id: 2, content: 'Quarantine', disabled: false },
              { id: 3, content: 'Deleted', disabled: isAdmin ? true : false }
            ]}></ButtonGroup>
        ) : (
          <div></div>
        )}
      </div>
      <div className="flex justify-end gap-3 ">
        <div className="lg:w-96">
          <InputField
            prefix={<SearchIcon className="h-5 w-5 text-gray-500" />}
            name="searchInput"
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.REPORT_LISTING_SEARCH_PLACEHOLDER.id,
              defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_SEARCH_PLACEHOLDER.defaultMessage
            })}
            type="text"
            className="h-10"
            onChange={(e: any) =>
              dispatch(addListingSearchInput({ listingSearchInput: e.target.value }))
            }
            register={register}
          />
        </div>
        {layoutButtons.map((layoutButton) => (
          <Button
            key={layoutButton.id}
            onClick={() => handleLayoutClick(layoutButton)}
            className={clsx(
              'h-10 w-10 ',
              layoutButton.id === clickedButton.id ? 'border-gray-300 shadow-base' : ''
            )}
            size="sm"
            theme="base">
            <div className="">{layoutButton.icon}</div>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default TableBanner;
