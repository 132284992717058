type Props = {
  className?: string;
};
const NavigationIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 12H19.5M19.5 12L12.5 5M19.5 12L12.5 19"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NavigationIcon;
