import React, { useState } from 'react';
import { User } from '../../model/UserModel';
import DashboardSubHeader from '../../components/shared/Dashboard Header/DashboardSubHeader';
import EmptyListing from '../../components/dashboard/card/EmptyListing';
import Pagination from '../../components/shared/Pagination';
import { ApiSearchOrderEnum } from '../../util/enum/enum';
import ListingCard from '../../components/shared/ListingCard/ListingCard';
import { ListingNew } from '../../model/Listing';
import { LIST_PAGES, NUMBER_OF_ITEMS_PER_PAGE, ROUTES } from '../../constants/data';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useGetArchivedListings } from '../../hooks/listing/useGetArchivedListings';
import PlusIcon from '../../components/icons/PlusIcon';
import ArchiveIcon from '../../components/icons/ArchiveIcon';
import { useUpdateListing } from '../../hooks/listing/useUpdateListing';
import PrimaryActionModal from '../../components/modal/PrimaryActionModal';
import { useDeleteIssuedListing } from '../../hooks/listing/useDeleteIssued';
import TagIcon from '../../components/icons/TagIcon';
import InlineAlert from '../../components/shared/InlineAlert';
import { useArchiveListing } from '../../hooks/listing/useArchiveListing';
type Props = {
  user?: User;
};
const ArchivedListings: React.FC<Props> = (props) => {
  const { user } = props;
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const updateListing = useUpdateListing();
  const [offset, setOffset] = useState(0);
  const [relistModal, setRelistModal] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const relist = useArchiveListing();
  const { listings, pagination, loading, fetchMore, refetch } = useGetArchivedListings(
    user?.id + '',
    offset,
    NUMBER_OF_ITEMS_PER_PAGE,
    ApiSearchOrderEnum.PUBLISHED_DESC
  );

  const onPageChange = async (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_ITEMS_PER_PAGE;
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_ITEMS_PER_PAGE }
    });
    setOffset(startIndex);
    setCurrentPage(pageNumber);
  };

  const onMarkAsSold = async (id: number) => {
    await updateListing({ variables: { data: { isSold: true }, id: id } }).then(
      async (res: any) => {
        if (res) {
          refetch();
        }
      }
    );
  };

  const onRelist = async (state: boolean) => {
    if (state && selectedId !== 0) {
      await relist({ variables: { id: selectedId, data: { deleted: false } } }).then(
        async (res: any) => {
          if (res) {
            refetch();
          }
        }
      );
    }
    setRelistModal(false);
  };

  const onRelistClick = async (id: number) => {
    setRelistModal(true);
    setSelectedId(id);
  };

  return (
    <div className="w-full">
      <DashboardSubHeader
        title={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.ARCHIVED_LISTINGS.id,
          defaultMessage: TRANSLATED_CONSTANTS.ARCHIVED_LISTINGS.defaultMessage
        })}
        description={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.ARCHIVED_LISTINGS_DESCRIPTION.id,
          defaultMessage: TRANSLATED_CONSTANTS.ARCHIVED_LISTINGS_DESCRIPTION.defaultMessage
        })}
        amount={pagination?.total}
        confirmButtonIcon={<PlusIcon />}
        confirmButtonTitle={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
          defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
        })}
        onConfrimClick={() => navigate(ROUTES.CREATE_LISTING_ROUTE)}></DashboardSubHeader>
      <div className="w-full py-6">
        {listings?.length == 0 || loading ? (
          <EmptyListing
            title={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NOTHING_ARCHIVED_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.NOTHING_ARCHIVED_LISTING.defaultMessage
            })}
            description={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NOTHING_ARCHIVED_LISTING_DESCRIPTION.id,
              defaultMessage:
                TRANSLATED_CONSTANTS.NOTHING_ARCHIVED_LISTING_DESCRIPTION.defaultMessage
            })}
            to={ROUTES.CREATE_LISTING_ROUTE}
            icon={<ArchiveIcon className="h-7 w-7" />}
            btnIcon={<PlusIcon />}
            loadding={loading}
            btnTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
            })}></EmptyListing>
        ) : (
          <div className=" space-y-6">
            {listings?.map((listing: ListingNew) => {
              return (
                <ListingCard
                  cardClickable={true}
                  onMarkAsSold={(id: number) => onMarkAsSold(id)}
                  onRelist={(id: number) => onRelistClick(id)}
                  key={listing?.id}
                  page={LIST_PAGES.ARCHIVE}
                  listing={listing}
                />
              );
            })}
          </div>
        )}
        {/* <InlineAlert
          text={intl.formatMessage({
            id: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_MESSAGE.id,
            defaultMessage: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_MESSAGE.defaultMessage
          })}
          className=" my-6 w-full"
          type="default"
          linkText={intl.formatMessage({
            id: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_LINK_TEXT.id,
            defaultMessage: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_LINK_TEXT.defaultMessage
          })}
          state={true}
          hasIcon={true}
          link={ROUTES.HOME_ROUTE}
        /> */}

        <Pagination
          currentPage={currentPage}
          totalCount={pagination?.total || 0}
          pageSize={NUMBER_OF_ITEMS_PER_PAGE}
          onPageChange={onPageChange}
          type="centered-page-number"
        />
        <PrimaryActionModal
          title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
          isOpen={relistModal}
          onClosePrimaryActionModal={(value: boolean) => onRelist(value)}
          subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_CONFIRM_BUTTON}
          icon={<TagIcon />}
        />
      </div>
    </div>
  );
};

export default ArchivedListings;
