import { useCallback, useEffect, useState, useMemo } from 'react';
import { CheckboxOption } from '../../model/CheckboxOption';
import { LocationBound } from '../../model/Location';
import { GET_LISTINGS_AND_STOREFRONTS_STR } from './marketPlaceQueries';
import { generateListingFilters, getCountFilters, getStorefrontFilters } from '../../util/AppUtil';
import { flattenObj } from '../../util/flattenObj';
import { customizeListings, customizeStorefronts } from './transform';

const transformDataToGeojson = (data: any) => {
  const features = [];
  const groups: any = {};
  for (const item of data) {
    const lat = item.location.latitude;
    const lng = item.location.longitude;
    const key = lat + ',' + lng;
    if (groups[key]) {
      groups[key].push(item);
    } else {
      groups[key] = [item];
    }
  }
  for (const key in groups) {
    const arr = groups[key];
    const [lat, lng] = key.split(',').map(Number);
    const itemObject = {
      type: 'Feature',
      properties: arr,
      geometry: {
        coordinates: [lat, lng],
        type: 'Point'
      }
    };
    features.push(itemObject);
  }

  const geojsonData = {
    type: 'FeatureCollection',
    features
  };

  return data ? JSON.stringify(geojsonData) : null;
};

export const useGetMarketPlaceMapData = (
  moreFilter: any,
  categories: CheckboxOption[] | undefined,
  searchText: string,
  locationBounds: LocationBound,
  listingOffset: number,
  storefrontOffset: number,
  order?: string,
  userId?: string
) => {
  const [geoData, setGeoData] = useState<any>(null);
  const [loading, setLoading] = useState<any>(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    const type = 'map';
    let skip = true;
    const { maxLat, maxLng, minLat, minLng } = locationBounds;
    const categoryIds =
      categories?.length ?? -1 > 0
        ? categories?.map((category) => category.value)
        : moreFilter?.categories?.map((category: any) => category.value);
    const types = moreFilter?.listings?.map((listing: any) => listing.value);
    const alternatePriceOptions = moreFilter?.alternatePriceOptions?.map(
      (alternatePrice: any) => alternatePrice.value
    );
    const priceType = moreFilter?.priceType?.map((priceType: any) => priceType.value);
    if (userId) {
      skip = false;
    }
    const limit = -1;

    const variables = {
      type,
      listingOffset,
      storefrontOffset,
      limit,
      order,
      searchText,
      categoryIds,
      types,
      maxLat,
      maxLng,
      minLat,
      minLng,
      alternatePriceOptions,
      priceType,
      userId,
      skip
    };

    const conditions = {
      filters: generateListingFilters(variables),
      storefrontFilters: getStorefrontFilters(variables)
    };

    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: token ? `Bearer ${token}` : ''
      },
      body: JSON.stringify({
        query: GET_LISTINGS_AND_STOREFRONTS_STR,
        variables: { ...variables, ...conditions }
      })
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_STRAPI_API_URL}/graphql`,
        requestOptions
      );
      const responseData = await response.json();

      const listings = flattenObj(responseData?.data?.listings);
      const storefronts = flattenObj(responseData?.data?.storeFronts);
      const customizedListings = customizeListings(listings);
      const customizedStorefronts = customizeStorefronts(storefronts);
      const geojsonData = transformDataToGeojson([
        ...(customizedListings || []),
        ...(customizedStorefronts || [])
      ]);

      setGeoData(geojsonData ?? '');
      setLoading(false);
      setError(null);
    } catch (error: any) {
      setLoading(false);
      setError(error.message || 'An error occurred');
    }
  }, [
    moreFilter,
    categories,
    searchText,
    locationBounds,
    listingOffset,
    storefrontOffset,
    order,
    userId
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return useMemo(
    () => ({
      geoData,
      loading,
      error
    }),
    [geoData, loading, error]
  );
};
