import { gql, useMutation } from '@apollo/client';
interface MessageReportEntityResponse {
  variables: {
    data: any;
    id: number;
  };
}

export const UPDATE_MESSAGE_REPORT = gql`
  mutation updateMessageReport($id: ID!, $data: MessageReportInput!) {
    updateMessageReport(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const useUpdateMessageReport = (): ((
  updateMessageReport: MessageReportEntityResponse
) => any) => {
  const [updateMessageReportResult] = useMutation(UPDATE_MESSAGE_REPORT);

  return updateMessageReportResult;
};
