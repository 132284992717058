import React, { useState } from 'react';
import { User } from '../../model/UserModel';
import { useGetListings } from '../../hooks/listing/useGetListings';
import { ApiSearchOrderEnum } from '../../util/enum/enum';
import { ListingNew } from '../../model/Listing';
import { LISTING_TYPES, LIST_PAGES, NUMBER_OF_ITEMS_PER_PAGE, ROUTES } from '../../constants/data';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useDeleteListing } from '../../hooks/listing/useDeleteListing';
import { useUpdateListing } from '../../hooks/listing/useUpdateListing';
import { useDispatch } from 'react-redux';
import { setShareData } from '../../lib/redux/reducers/share.slice';
import DashboardSubHeader from '../../components/shared/Dashboard Header/DashboardSubHeader';
import PlusIcon from '../../components/icons/PlusIcon';
import EmptyListing from '../../components/dashboard/card/EmptyListing';
import Wanted2Icon from '../../components/icons/Wanted2Icon';
import ListingCard from '../../components/shared/ListingCard/ListingCard';
import Pagination from '../../components/shared/Pagination';
import RedActionModal from '../../components/modal/RedActionModal';
import TrashIcon from '../../components/icons/TrashIcon';
import { addCreateListingType } from '../../lib/redux/reducers/dashboard.slice';
import { useArchiveListing } from '../../hooks/listing/useArchiveListing';
type Props = {
  user?: User;
};
const Listings: React.FC<Props> = (props) => {
  const { user } = props;
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const deleteListing = useArchiveListing();
  const updateListing = useUpdateListing();
  const [listingId, setListingId] = useState(0);
  const dispatch = useDispatch();
  const [eventLoading, setEventLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [offset, setOffset] = useState(0);
  const { listings, pagination, loading, fetchMore, refetch } = useGetListings(
    Number(user?.id),
    Number(user?.id),
    offset,
    NUMBER_OF_ITEMS_PER_PAGE,
    ApiSearchOrderEnum.PUBLISHED_DESC,
    LISTING_TYPES.BUYING
  );

  const onPageChange = async (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_ITEMS_PER_PAGE;
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_ITEMS_PER_PAGE }
    });
    setOffset(startIndex);
    setCurrentPage(pageNumber);
  };

  const onDelete = async (state: boolean) => {
    setEventLoading(true);
    setShowDeleteModal(false);
    if (state) {
      await deleteListing({ variables: { id: listingId, data: { deleted: true } } }).then(
        async (response: any) => {
          if (response) {
            await onPageChange(listings.length > 1 ? currentPage : currentPage - 1);
          }
        }
      );
    }
    setEventLoading(false);
  };

  const onMarkAsSold = async (id: number) => {
    setEventLoading(true);
    await updateListing({ variables: { data: { isSold: true }, id: id } }).then(
      async (res: any) => {
        if (res) {
          refetch();
        }
      }
    );
    setEventLoading(false);
  };

  const onRedirectToCreateListing = () => {
    navigate(ROUTES.CREATE_LISTING_ROUTE);
    dispatch(addCreateListingType(LISTING_TYPES.BUYING));
  };

  return (
    <div className="w-full">
      <DashboardSubHeader
        title={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.WANTED_LISTINGS.id,
          defaultMessage: TRANSLATED_CONSTANTS.WANTED_LISTINGS.defaultMessage
        })}
        description={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.WANTED_LISTINGS_DESCRIPTION.id,
          defaultMessage: TRANSLATED_CONSTANTS.WANTED_LISTINGS_DESCRIPTION.defaultMessage
        })}
        amount={pagination?.total}
        confirmButtonIcon={<PlusIcon />}
        confirmButtonTitle={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
          defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
        })}
        onConfrimClick={onRedirectToCreateListing}></DashboardSubHeader>
      <div className="w-full py-6">
        {listings?.length == 0 || loading || eventLoading ? (
          <EmptyListing
            title={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NO_WANTED_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.NO_WANTED_LISTING.defaultMessage
            })}
            description={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NO_WANTED_LISTING_DESCRIPTION.id,
              defaultMessage: TRANSLATED_CONSTANTS.NO_WANTED_LISTING_DESCRIPTION.defaultMessage
            })}
            to={ROUTES.CREATE_LISTING_ROUTE}
            icon={<Wanted2Icon />}
            btnIcon={<PlusIcon />}
            loadding={loading || eventLoading}
            btnTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
            })}></EmptyListing>
        ) : (
          <div className=" space-y-6">
            {listings?.map((listing: ListingNew) => {
              return (
                <ListingCard
                  cardClickable={true}
                  onEdit={() => navigate(`${ROUTES.EDIT_LISTING_ROUTE}/${listing.id}`)}
                  key={listing?.id}
                  page={LIST_PAGES.DASHBOARD}
                  onDelete={(id: number) => {
                    setListingId(id);
                    setShowDeleteModal(true);
                  }}
                  onMarkAsSold={(id: number) => onMarkAsSold(id)}
                  onShare={() => {
                    dispatch(
                      setShareData({
                        isOpen: true,
                        url: `${ROUTES.LISTING_DETAILS_ROUTE}/${listing.id}`
                      })
                    );
                  }}
                  listing={listing}
                />
              );
            })}
          </div>
        )}

        <Pagination
          currentPage={currentPage}
          totalCount={pagination?.total || 0}
          pageSize={NUMBER_OF_ITEMS_PER_PAGE}
          onPageChange={onPageChange}
          type="centered-page-number"
        />

        <RedActionModal
          title={TRANSLATED_CONSTANTS.LISTING_DELETE_MODAL_TITLE}
          isOpen={showDeleteModal}
          onCloseRedActionModal={(value: boolean) => onDelete(value)}
          subTitle={TRANSLATED_CONSTANTS.LISTING_DELETE_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.DELETE_LISTING}
          icon={<TrashIcon></TrashIcon>}
          hasButtonIcon={true}
        />
      </div>
    </div>
  );
};

export default Listings;
