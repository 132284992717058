import { gql, useMutation } from '@apollo/client';

interface PendingRoleInput {
  variables: {
    id: number;
  };
}

const REMOVE_PEDNING_REOLE = gql`
  mutation removePendingRole($id: ID!) {
    deletePendingRole(id: $id) {
      data {
        id
      }
    }
  }
`;

export const useRemovePendingRole = (): ((pendingRole: PendingRoleInput) => any) => {
  const [pendingRoleResult] = useMutation(REMOVE_PEDNING_REOLE);
  return pendingRoleResult;
};
