import { FC } from 'react';
import clsx from 'clsx';
import DogeDomain from '../DogeDomain';
import BackNavigation from '../BackNavigation';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';

type Props = {
  borderClassName?: string;
  className?: string;
  onBackNavClick: () => void;
};
const CreateAccountHead: FC<Props> = (props) => {
  const { className, borderClassName, onBackNavClick } = props;
  return (
    <div className={clsx('w-full', className)}>
      <div className="w-full hidden sm:block lg:hidden">
        <div
          className={clsx(
            'h-2 w-full bg-gradient-to-l from-[#F5DCA3] via-[#F2C3CC] to-[#C3E9EC]',
            borderClassName
          )}></div>
        <div className={clsx('text-xl px-[60px] space-y-12 mt-12')}>
          <DogeDomain />
          <BackNavigation onBackNavClick={onBackNavClick} />
        </div>
      </div>
      <div className="flex items-center sm:hidden h-[72px]  bg-gradient-to-r from-[#FBFAF9] to-[#F9FAFB ]">
        <div onClick={onBackNavClick} className="flex gap-x-2 items-center px-4">
          <ArrowLeftIcon className="w-[22px] text-gray-800" /> <DogeDomain />
        </div>
      </div>
    </div>
  );
};

export default CreateAccountHead;
