import { gql, useMutation } from '@apollo/client';
interface LanguageProficiencyInput {
  variables: {
    data: {
      level: string;
      language: number;
      users_permissions_user: number;
      publishedAt: string;
    };
  };
}

export const CREATE_LANGUAGE_PROFICIENCY = gql`
  mutation createLanguageProficiency($data: LanguageProficiencyInput!) {
    createLanguageProficiency(data: $data) {
      data {
        id
        attributes {
          publishedAt
          level
          language {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;

export const useCreateLanguageProficiency = (): ((
  LanguageProficiency: LanguageProficiencyInput
) => any) => {
  const [createLanguageProficiencyResult] = useMutation(CREATE_LANGUAGE_PROFICIENCY);
  return createLanguageProficiencyResult;
};
