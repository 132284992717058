import { FC } from 'react';
import { Typo } from '../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { LISTING_BREAKPOINT, LIST_PAGES } from '../../constants/data';
import ListingCard from '../../components/shared/ListingCard/ListingCard';
import { useGetListings } from '../../hooks/listing/useGetListings';
import { User } from '../../model/UserModel';
import { ApiSearchOrderEnum } from '../../util/enum/enum';
import { ListingNew } from '../../model/Listing';
import { useHandleSave } from '../../hooks/useHandleSave';
import Button from '../../components/shared/Button';
import ArrowRightIcon from '../../components/icons/ArrowRigthIcon';
import clsx from 'clsx';

type Props = {
  listingUser: User;
  userId: number;
  exceptId?: number;
  onViewMore: () => void;
};

const ListingItems: FC<Props> = ({ listingUser, userId, exceptId, onViewMore }) => {
  const { listings, refetch } = useGetListings(
    userId,
    Number(listingUser?.id),
    0,
    4,
    ApiSearchOrderEnum.PUBLISHED_DESC,
    '',
    exceptId
  );
  const { save } = useHandleSave(refetch);

  return (
    <div className="w-full">
      <Typo.headingXs fontWeight="font-medium" className="text-gray-900 mb-1">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.RECENT_LISTINGS.id}
          defaultMessage={TRANSLATED_CONSTANTS.RECENT_LISTINGS.defaultMessage}
        />
      </Typo.headingXs>
      <Typo.textMd fontWeight="font-normal" className="text-gray-500">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.VIEW_WHAT_STORE_HAS_FOR_SALE.id}
          defaultMessage={TRANSLATED_CONSTANTS.VIEW_WHAT_STORE_HAS_FOR_SALE.defaultMessage}
        />
      </Typo.textMd>
      <div className="py-6 gap-x-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-3">
        {listings?.slice(0, 3).map((listing: ListingNew, index: number) => (
          <ListingCard
            key={index}
            cardClickable={true}
            onLike={save}
            listing={listing}
            className={clsx({
              ' hidden lg:block': index == 2
            })}
            page={LIST_PAGES.MAP}
            size={LISTING_BREAKPOINT.MOBILE}></ListingCard>
        ))}
      </div>
      <div className="w-full flex justify-end">
        {listings?.length > 3 ? (
          <Button
            onClick={onViewMore}
            size="lg"
            theme="blue-link"
            iconPos="right"
            icon={<ArrowRightIcon />}>
            <Typo.textMd fontWeight="font-semibold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.VIEW_MORE.id}
                defaultMessage={TRANSLATED_CONSTANTS.VIEW_MORE.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ListingItems;
