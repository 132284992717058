type Props = {
  className?: string;
};
const User1Icon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.3333 24.5C23.3333 22.8718 23.3333 22.0578 23.1323 21.3953C22.6799 19.9039 21.5128 18.7367 20.0213 18.2843C19.3589 18.0833 18.5448 18.0833 16.9166 18.0833H11.0833C9.45514 18.0833 8.64106 18.0833 7.97863 18.2843C6.48716 18.7367 5.32001 19.9039 4.86757 21.3953C4.66663 22.0578 4.66663 22.8718 4.66663 24.5M19.25 8.75C19.25 11.6495 16.8995 14 14 14C11.1005 14 8.74996 11.6495 8.74996 8.75C8.74996 5.85051 11.1005 3.5 14 3.5C16.8995 3.5 19.25 5.85051 19.25 8.75Z"
        stroke="currentColor"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default User1Icon;
