import { gql, useMutation } from '@apollo/client';

interface UserInput {
  variables: {
    id: number;
  };
}

const DEACTIVE_USER = gql`
  mutation deleteAccount($id: ID!) {
    deactiveUser(id: $id) {
      count
    }
  }
`;

export const useDeactiveUser = (): ((deactiveUser: UserInput) => any) => {
  const [deactiveUserResult] = useMutation(DEACTIVE_USER);
  return deactiveUserResult;
};
