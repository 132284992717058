import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { customizeUsersBanned } from './transfrom';

export const GET_UN_BANNED_USERS = gql`
  query usersUnBanned($bannerId: ID, $searchText: String, $limit: Int, $offset: Int) {
    usersUnBannedCount: usersPermissionsUsers(
      filters: {
        and: [
          { id: { ne: $bannerId } }
          { banned_user: { banned_by: { or: [{ id: null }, { id: { ne: $bannerId } }] } } }
          { or: [{ name: { containsi: $searchText } }, { username: { containsi: $searchText } }] }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      pagination: { limit: $limit, start: $offset }
      filters: {
        and: [
          { id: { ne: $bannerId } }
          { banned_user: { banned_by: { or: [{ id: null }, { id: { ne: $bannerId } }] } } }
          { or: [{ name: { containsi: $searchText } }, { username: { containsi: $searchText } }] }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          type
          createdAt
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          headerImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          banned_user {
            data {
              id
              attributes {
                date
              }
            }
          }
          listings {
            data {
              attributes {
                listing_reports {
                  data {
                    id
                  }
                }
                issued_listing {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUsersUnBanned = (
  offset: number,
  limit: number,
  searchText: string,
  bannerId: number
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_UN_BANNED_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText, bannerId }
  });

  return {
    unBannedUsers: customizeUsersBanned(flattenObj(data?.usersPermissionsUsers)),
    unBannedUsersCounts: data?.usersUnBannedCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
