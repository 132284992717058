import React, { ReactElement } from 'react';
import FeaturedIcon from '../../shared/FeaturedIcon';
import { Typo } from '../../Typo';
import Button from '../../shared/Button';
import Spinner from '../../shared/Spinner';
type Props = {
  icon: ReactElement;
  title: string;
  description?: string;
  btnTitle: string;
  btnIcon: ReactElement;
  loadding?: boolean;
  to?: string;
  onClick?: () => void;
};
const EmptyListing: React.FC<Props> = (props) => {
  const { icon, title, description, btnTitle, btnIcon, loadding = false, to, onClick } = props;
  return (
    <div className="w-full rounded-xl border border-gray-200 shadow-sm bg-white py-12 flex justify-center">
      {!loadding ? (
        <div className="flex flex-col justify-center text-center max-w-xs items-center">
          <FeaturedIcon size="xl" className=" mx-auto" theme="light-circle-outline" color="primary">
            {icon}
          </FeaturedIcon>
          <Typo.textXl fontWeight="font-medium" className="text-gray-900 mt-5">
            {title}
          </Typo.textXl>
          <Typo.textMd fontWeight="font-normal" className="text-gray-500 mt-2">
            {description}
          </Typo.textMd>
          <Button
            to={to}
            onClick={to ? () => ({}) : () => onClick && onClick()}
            size="lg"
            theme="primary-yellow"
            className="mt-8"
            icon={btnIcon}>
            <Typo.textMd fontWeight="font-semibold">{btnTitle}</Typo.textMd>
          </Button>
        </div>
      ) : (
        <Spinner className="h-10 w-10" />
      )}
    </div>
  );
};

export default EmptyListing;
