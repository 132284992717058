import React, { FC } from 'react';
import Modal from './Modal';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Image from '../shared/Image';
import applyImage from '../../assets/man-running.png';
import { useIntl } from 'react-intl';
import ApplyModalBodySlide from './ApplyModalBodySlide';
type Props = {
  isOpen: boolean;
  onCloseApplyModal: () => void;
  onConfirmApply: (value: any) => void;
};

const ApplyModal: FC<Props> = ({ isOpen, onCloseApplyModal, onConfirmApply }) => {
  return (
    <Modal
      onCancel={() => onCloseApplyModal()}
      onConfirm={() => onConfirmApply({})}
      isOpen={isOpen}
      confirmText={TRANSLATED_CONSTANTS.APPLY_NOW}
      actionButtonsFlow="horizontal"
      theme="primary"
      hasHeader={false}>
      <div>
        <Image className="rounded-lg" src={applyImage} />
        <ApplyModalBodySlide />
      </div>
    </Modal>
  );
};

export default ApplyModal;
