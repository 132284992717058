type Props = {
  className?: string;
};
const BellIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-4.5 h-5 ' + className}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4417 17.5C10.2952 17.7526 10.0849 17.9622 9.83185 18.1079C9.57884 18.2537 9.29198 18.3304 9 18.3304C8.70802 18.3304 8.42116 18.2537 8.16814 18.1079C7.91513 17.9622 7.70484 17.7526 7.55833 17.5M14 6.66667C14 5.34058 13.4732 4.06881 12.5355 3.13113C11.5979 2.19345 10.3261 1.66667 9 1.66667C7.67392 1.66667 6.40215 2.19345 5.46447 3.13113C4.52678 4.06881 4 5.34058 4 6.66667C4 12.5 1.5 14.1667 1.5 14.1667H16.5C16.5 14.1667 14 12.5 14 6.66667Z"
        strokeWidth="1.67"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BellIcon;
