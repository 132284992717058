type Props = {
  className?: string;
};
const AlertIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className} `}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99979 7.49995V10.8333M9.99979 14.1666H10.0081M8.84589 3.24305L1.99182 15.0819C1.61165 15.7386 1.42157 16.0669 1.44966 16.3364C1.47416 16.5714 1.59731 16.785 1.78844 16.924C2.00757 17.0833 2.38695 17.0833 3.14572 17.0833H16.8539C17.6126 17.0833 17.992 17.0833 18.2112 16.924C18.4023 16.785 18.5254 16.5714 18.5499 16.3364C18.578 16.0669 18.3879 15.7386 18.0078 15.0819L11.1537 3.24305C10.7749 2.58875 10.5855 2.26159 10.3384 2.15172C10.1228 2.05587 9.87676 2.05587 9.66121 2.15172C9.4141 2.26159 9.2247 2.58875 8.84589 3.24305Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
