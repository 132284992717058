import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface SlideState {
  profile: boolean;
  message: boolean;
}

const slideSlice = createSlice({
  name: 'slide',
  initialState: {
    profile: false, // Set initial state for each modal here
    message: false,
    chatData: {
      notificationId: 0,
      message: {
        chatId: 0,
        content: '',
        dateTime: '',
        isRead: false
      },
      receiver: {
        profileImage: { url: '' },
        username: '',
        id: 0,
        name: ''
      },
      sender: {
        profileImage: { url: '' },
        username: '',
        id: 0,
        name: ''
      }
    },
    profileData: {
      userId: 0
    }
  },
  reducers: {
    showSlide: (state, action: PayloadAction<keyof SlideState>) => {
      state[action?.payload] = true;
    },
    hideSlide: (state, action: PayloadAction<keyof SlideState>) => {
      state[action.payload] = false;
    },
    setChatData: (state, action) => {
      state.chatData = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    }
  }
});

export const { showSlide, hideSlide, setChatData, setProfileData } = slideSlice.actions;
export default slideSlice.reducer;
