import React, { FC } from 'react';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useIntl } from 'react-intl';
import Badge from '../../../components/shared/Badge';
import DotIcon from '../../../components/icons/DotIcon';
import Image from '../../../components/shared/Image';
import { LISTING_CARD_TYPES, LISTING_TYPES, ROUTES } from '../../../constants/data';
import clsx from 'clsx';
import AwardIcon from '../../../components/icons/AwardIcon';
import { Typo } from '../../../components/Typo';
import DogeIcon from '../../../components/icons/DogeIcon';
import Button from '../../../components/shared/Button';
import { AuthUserState } from '../../../context/AuthUserProvider';
import { useHandleMessageSenderAuth } from '../../../hooks/useHandleMessageSenderAuth';
import MessageIcon from '../../../components/icons/MessageIcon';
import MapPinIcon from '../../../components/icons/MapPinIcon';
import { useHandleSave } from '../../../hooks/useHandleSave';
import DisLikeIcon from '../../../components/icons/DisLikeIcon';
import LikeIcon from '../../../components/icons/LikeButton';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../lib/redux/reducers/modal.slice';
import { setDirectionData } from '../../../lib/redux/reducers/direction.slice';
import { Link, useNavigate } from 'react-router-dom';
import wantedDefaultImage from '../../../assets/images/Wanted default image.png';
type Props = {
  listing: any;
  refetch?: () => void;
};

const CustomPopup: FC<Props> = ({ listing, refetch }) => {
  const listingType = listing ? listing.type : 'storefront';
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = AuthUserState();
  const user = auth.authUser;
  const themeMap: { [key: string]: any } = {
    wanted: 'pink',
    personal: 'blue',
    storefrontItem: 'indigo',
    storefront: 'purple'
  };

  const typeMap: { [key: string]: string } = {
    wanted: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.WANTED.id,
      defaultMessage: TRANSLATED_CONSTANTS.WANTED.defaultMessage
    }),
    personal: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PERSONAL.id,
      defaultMessage: TRANSLATED_CONSTANTS.PERSONAL.defaultMessage
    }),
    storefrontItem: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.id,
      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.defaultMessage
    }),
    storefront: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.STOREFRONT.id,
      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage
    })
  };

  const { onSendMessage } = useHandleMessageSenderAuth(listing);
  const { save } = useHandleSave(refetch);

  const onGetDirection = () => {
    dispatch(showModal('direction'));
    dispatch(
      setDirectionData({
        current: {
          lat: user?.location?.latitude,
          long: user?.location?.longitude
        },
        destination: {
          lat: listing?.location?.latitude,
          long: listing?.location?.longitude
        }
      })
    );
  };

  const handleNavigation = () => {
    const route =
      listingType === LISTING_CARD_TYPES.STOREFRONT
        ? `${ROUTES.STOREFRONT_DETAILS_ROUTE}/${listing?.id}`
        : listingType === LISTING_CARD_TYPES.PERSONAL
        ? `${ROUTES.LISTING_DETAILS_ROUTE}/${listing?.id}`
        : `${ROUTES.STOREFRONT_ITEM_DETAILS_ROUTE}/${listing?.id}`;
    navigate(route);
  };

  return (
    <div className="rounded-xl w-[200px] sm:w-[240px] font-inter text-sx bg-white h-[208px] ">
      <div onClick={handleNavigation} className="cursor-pointer">
        <div className="w-full">
          <div className="relative ">
            <Image
              className="h-[104px] w-full rounded-t-xl"
              src={listing?.image?.length > 0 ? listing?.image[0].url : wantedDefaultImage}></Image>
            <Badge
              size="sm"
              className="absolute bottom-2 left-2"
              icon={<DotIcon></DotIcon>}
              label={typeMap[listingType]}
              theme={themeMap[listingType]}></Badge>
            {listingType !== LISTING_CARD_TYPES.STOREFRONT && (
              <div
                className={clsx(
                  'rounded-full absolute top-2 left-2 p-1 bg-yellow-50 z-10 hidden sm:block',
                  listing?.price?.priceType === 'Doge = Doge' ? ' block' : ' hidden'
                )}>
                <AwardIcon></AwardIcon>
              </div>
            )}
          </div>
          <div className="p-3 flex justify-between items-start">
            <div className="flex flex-col items-start space-y-1 text-gray-900">
              {listingType !== LISTING_CARD_TYPES.STOREFRONT && (
                <div className={clsx(' hidden gap-x-2 items-center sm:flex justify-start')}>
                  <Typo.textMd
                    fontWeight="font-semibold"
                    className=" text-gray-900 flex items-center">
                    <DogeIcon className="w-4 h-4" /> {Math.round(listing?.price?.priceInDoge)} DOGE
                  </Typo.textMd>
                  <Typo.textXs fontWeight="font-normal" className=" text-gray-600">
                    ${Number(listing?.price?.priceInUSD)?.toFixed(2)}
                  </Typo.textXs>
                </div>
              )}
              <Typo.textXs
                fontWeight="font-normal"
                className={clsx('text-blue-gray-600 block ', {
                  'sm:hidden': listingType !== LISTING_CARD_TYPES.STOREFRONT
                })}>
                {listing?.user?.name}
              </Typo.textXs>
              <Typo.textXs fontWeight="font-medium">{listing?.name}</Typo.textXs>
              {listingType !== LISTING_CARD_TYPES.STOREFRONT &&
                listingType !== LISTING_CARD_TYPES.STOREFRONT_ITEM && (
                  <Button
                    type="button"
                    theme="blue-link"
                    className=" mt-[10px]"
                    disabled={user?.id == listing?.user?.id}
                    onClick={(event: any) => {
                      onSendMessage();
                      event.stopPropagation();
                    }}
                    icon={<MessageIcon className="text-blue-gray-600"></MessageIcon>}>
                    <Typo.textSm
                      fontWeight="font-semibold"
                      className="text-blue-gray-600 underline">
                      {(listingType === LISTING_CARD_TYPES.PERSONAL ||
                        listing?.type === LISTING_TYPES.SELLING) &&
                        intl.formatMessage({
                          id: 'shared.message-seller',
                          defaultMessage: 'Message Seller'
                        })}
                      {(listingType === LISTING_CARD_TYPES.WANTED ||
                        listing?.type === LISTING_TYPES.BUYING) &&
                        intl.formatMessage({
                          id: 'shared.message-buyer',
                          defaultMessage: 'Message Buyer'
                        })}
                    </Typo.textSm>
                  </Button>
                )}

              {(listingType == LISTING_CARD_TYPES.STOREFRONT ||
                listingType === LISTING_CARD_TYPES.STOREFRONT_ITEM) && (
                <Button
                  type="button"
                  theme="blue-link"
                  className="underline mt-[10px]"
                  onClick={(event: any) => {
                    onGetDirection();
                    event.stopPropagation();
                  }}
                  icon={<MapPinIcon className=" stroke-blue-gray-600"></MapPinIcon>}>
                  <Typo.textMd fontWeight="font-semibold">
                    {intl.formatMessage({
                      id: 'shared.get-direction',
                      defaultMessage: 'Get Directions'
                    })}
                  </Typo.textMd>
                </Button>
              )}
            </div>
            {listing?.type !== 'storefront' && (
              <Button
                size="sm"
                className="text-gray-500"
                onClick={(event: any) => {
                  save(
                    listing.id,
                    'listing',
                    listing?.saved_listings?.length > 0 ? true : false,
                    listing?.saved_listings?.length > 0
                      ? Number(listing.saved_listings[0].id)
                      : undefined
                  );
                  event.stopPropagation();
                }}
                icon={
                  listing?.saved_listings?.length > 0 ? (
                    <DisLikeIcon className="text-gray-700"></DisLikeIcon>
                  ) : (
                    <LikeIcon className="text-gray-500"></LikeIcon>
                  )
                }></Button>
            )}
            {listing?.type === 'storefront' && (
              <Button
                size="sm"
                className="text-gray-500"
                onClick={(event: any) => {
                  save(
                    listing.id,
                    'storefront',
                    listing?.saved_storefronts && listing?.saved_storefronts?.length > 0
                      ? true
                      : false,
                    listing.saved_storefronts && listing?.saved_storefronts.length > 0
                      ? Number(listing.saved_storefronts[0].id)
                      : undefined
                  );
                  event.stopPropagation();
                }}
                icon={
                  listing?.saved_storefronts ? (
                    <DisLikeIcon className="text-gray-700"></DisLikeIcon>
                  ) : (
                    <LikeIcon className="text-gray-500"></LikeIcon>
                  )
                }></Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomPopup;
