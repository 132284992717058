import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_BLOCKED_USERS = gql`
  query getBlockedUsers($offset: Int, $blockerId: ID, $limit: Int, $searchText: String) {
    blockedUsersCount: blockedUsers(
      filters: {
        and: [
          { blocked_by: { id: { eq: $blockerId } } }
          {
            or: [
              { blocked: { username: { containsi: $searchText } } }
              { blocked: { name: { containsi: $searchText } } }
            ]
          }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    blockedUsers(
      pagination: { limit: $limit, start: $offset }
      filters: {
        and: [
          { blocked_by: { id: { eq: $blockerId } } }
          {
            or: [
              { blocked: { username: { containsi: $searchText } } }
              { blocked: { name: { containsi: $searchText } } }
            ]
          }
        ]
      }
    ) {
      data {
        id
        attributes {
          blocked {
            data {
              id
              attributes {
                username
                name
                type
                profileImage {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          blocked_by {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const useGetBlockedUsers = (
  offset: number,
  limit: number,
  searchText: string,
  blockerId: number
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_BLOCKED_USERS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText, blockerId }
  });
  return {
    blockedUsers: flattenObj(data?.blockedUsers),
    blockedUsersCounts: data?.blockedUsersCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
