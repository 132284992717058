import { useQuery, gql } from '@apollo/client';
import { DropdownOption } from '../../model/DropdownOption';

export const GET_CATEGORIES = gql`
  query getCategory {
    categories {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const changeData = (categories: any) => {
  const options: DropdownOption[] = [];
  categories?.data?.map((item: any) => {
    options.push({ id: item?.id, title: item.attributes.name } as DropdownOption);
  });
  return options;
};

export const useGeCategories = () => {
  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    categories: changeData(data?.categories),
    loading,
    error
  };
};
