import React, { useMemo, useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import clsx from 'clsx';
import { getCountryCode, handlegetCurrentLocationSuccess } from '../../../util/AppUtil';
import { LocationBound, LocationType } from '../../../model/Location';
import MapPinIcon from '../../icons/MapPinIcon';
import LocationDropdownItem from './LocationDropdownItem';
import { CircleFlag } from 'react-circle-flags';
import { Typo } from '../../Typo';
import CheckIcon from '../../icons/CheckIcon';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import axios from 'axios';
import config from '../../../config/config';
import { LOCATIONDROPDOWN_ZOOM } from '../../../constants/data';
type Props = {
  location?: LocationType;
  onLocationSelect: (location: LocationType, locationBoundry: LocationBound, latLng: any) => void;
  placeholder?: string;
  label?: string;
  hintText?: string;
  className?: string;
};

const LocationDropdown: React.FC<Props> = (props) => {
  const { onLocationSelect, location, placeholder, label, hintText, className } = props;
  const intl = useIntl();
  const [place, setPlace] = useState(location?.address || '');
  const handleChange = (address: string) => {
    setPlace(address);
  };

  useMemo(() => {
    setPlace(location?.address || '');
  }, [location]);

  const handleSelect = async (address: string, placeId: string) => {
    if (placeId !== 'current') {
      setPlace(address);
      let locationBound = {
        minLat: -90,
        maxLat: 90,
        minLng: -180,
        maxLng: 180
      };
      // let latLng = { lat: 52.6376, lng: -1.135171 };
      const countryCode = await getCountryCode(placeId);
      const geoAddress = await geocodeByAddress(address);
      const location = geoAddress && geoAddress?.length > 0 ? geoAddress[0] : null;

      try {
        // Make an HTTP request to the API with the address and the API key as parameters
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${config.GOOGLE_MAP_API_KEY}`
        );
        // Parse the response to get the location object that contains the lat and lng
        const viewport = response.data.results[0].geometry.viewport;
        // Set the location state to the location object
        locationBound = {
          minLat: viewport?.southwest.lat,
          maxLat: viewport?.northeast.lat,
          minLng: viewport?.southwest.lng,
          maxLng: viewport?.northeast.lng
        };
      } catch (error) {
        // Handle any errors
        console.error(error);
      }

      // Extract LatLng
      const latLng = await getLatLng(geoAddress[0]);
      const locationParam: any = latLng;
      locationParam.zoom = LOCATIONDROPDOWN_ZOOM;

      // Extract city
      const city = location?.address_components?.find((component) =>
        component?.types?.includes('locality')
      )?.long_name;

      // Extract state/province
      const state = location?.address_components?.find((component) =>
        component?.types?.includes('administrative_area_level_1')
      )?.long_name;

      // Extract address
      const formatted_address = location?.formatted_address;

      // Extract country
      const country = location?.address_components?.find((component) =>
        component?.types?.includes('country')
      )?.long_name;

      // Extract street
      const street = location?.address_components?.find((component) =>
        component?.types?.includes('route')
      )?.long_name;
      onLocationSelect(
        {
          address: formatted_address || address,
          latitude: latLng.lat,
          longitude: latLng.lng,
          countryCode: countryCode,
          country: country,
          state: state,
          city: city,
          street: street,
          id: ''
        },
        locationBound,
        locationParam
      );
    } else {
      const locationAccess = localStorage.getItem('locationAccess');
      const parsedLocationAccess = locationAccess ? JSON.parse(locationAccess) : false;
      if (parsedLocationAccess) {
        getLocation();
      }
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log('Geolocation not supported');
    }
    function success(position: any) {
      const latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
        zoom: LOCATIONDROPDOWN_ZOOM
      };
      handlegetCurrentLocationSuccess(position)
        .then((locationInfo) => {
          // You can access the locationInfo here
          if (locationInfo) {
            onLocationSelect(
              {
                address: locationInfo?.location?.address,
                latitude: locationInfo?.location?.latitude,
                longitude: locationInfo?.location?.longitude,
                countryCode: locationInfo?.location?.countryCode,
                country: locationInfo?.location?.country,
                state: locationInfo?.location?.state,
                city: locationInfo?.location?.city,
                street: locationInfo?.location?.state,
                id: ''
              },
              locationInfo.locationBound,
              latLng
            );
          }
        })
        .catch((error) => {
          // Handle errors here
          console.error('Failed to retrieve location: ' + error);
        });
    }

    function error() {
      console.log('Unable to retrieve your location');
    }
  };

  return (
    <div className={clsx('realative flex flex-col', className)}>
      {label ? (
        <Typo.textSm fontWeight="font-normal" className="text-gray-700 mb-1.5">
          {label}
        </Typo.textSm>
      ) : (
        ''
      )}
      <PlacesAutocomplete value={place} onSelect={handleSelect} onChange={handleChange}>
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className="relative w-full">
            <input
              {...getInputProps({
                placeholder: placeholder,
                className: clsx(
                  ' relative w-full rounded-lg border border-gray-300 pl-8 shadow-xs bg-white py-2.5 px-3.5 font-inter text-base placeholder:text-gray-500 text-gray-900 focus:outline-none  focus:ring-4 focus:ring-gray-100 focus:border-gray-300'
                )
              })}
            />

            {location?.countryCode ? (
              <CircleFlag
                countryCode={location?.countryCode}
                height="24"
                width="24"
                className=" h-6 w-6 text-gray-500 absolute top-2.5 left-2"
              />
            ) : (
              <MapPinIcon className="h-5 w-5 text-gray-500 absolute top-3 left-2"></MapPinIcon>
            )}

            <div
              className={` z-10 absolute w-full rounded-lg mt-[6px] ${
                suggestions.length > 0 ? ' border border-gray-300 block' : ' hidden '
              }`}>
              <button
                {...getSuggestionItemProps({
                  placeId: 'current',
                  active: false,
                  description: '',
                  id: '0',
                  index: 0,
                  formattedSuggestion: { mainText: '', secondaryText: '' },
                  matchedSubstrings: [],
                  types: [''],
                  terms: []
                })}
                type="button"
                className="relative  group flex item-center py-2.5 rounded-t-lg gap-x-2 px-3.5 h-11 w-full bg-white hover:bg-gray-100">
                <div className="flex gap-x-2">
                  <MapPinIcon className="h-6 w-6 text-gray-500"></MapPinIcon>
                  <Typo.textMd fontWeight="font-medium" className="text-gray-900 ">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.CURRENT_LOCATION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.CURRENT_LOCATION.defaultMessage
                    })}
                  </Typo.textMd>
                </div>
                <span className="absolute rounded-tr-lg hidden group-hover:flex inset-y-0 right-0 bg-gray-100 items-center pr-3 text-blue-gray-600">
                  <CheckIcon></CheckIcon>
                </span>
              </button>
              {suggestions?.map((suggestion, index: number) => {
                const className = suggestion.active ? ' text-gray-900' : ' text-gray-700 ';
                return (
                  <div key={index}>
                    <div
                      {...getSuggestionItemProps(suggestion, {
                        className: clsx(className)
                      })}>
                      <LocationDropdownItem
                        placeId={suggestion.placeId}
                        isSelected={suggestion.active}
                        description={suggestion.description}
                        className={
                          suggestions.length - 1 == index ? ' rounded-b-lg' : ''
                        }></LocationDropdownItem>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      {hintText ? (
        <Typo.textSm fontWeight="font-normal" className=" mt-2 text-gray-400">
          {hintText}
        </Typo.textSm>
      ) : (
        ''
      )}
    </div>
  );
};

export default LocationDropdown;
