import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_REFERRAL_HISTORY = gql`
  query getReferralHistory($refferedId: ID!, $offset: Int, $limit: Int) {
    referralHistoryCounts: referralHistories(
      pagination: { start: $offset, limit: $limit }
      filters: { referrer: { id: { eq: $refferedId } } }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    referralHistories(
      pagination: { start: $offset, limit: $limit }
      filters: { referrer: { id: { eq: $refferedId } } }
    ) {
      data {
        id
        attributes {
          pointsEarned
          reffered {
            data {
              id
              attributes {
                name
                username
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetReferralHistory = (offset: number, limit: number, refferedId: number) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_REFERRAL_HISTORY, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, refferedId: refferedId }
  });
  return {
    referralHistories: flattenObj(data?.referralHistories),
    referralHistoryCounts: data?.referralHistoryCounts?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
