type Props = {
  className?: string;
};
const LoginIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-[14px] h-4 text-gray-600 ' + className}
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 11.3333C3 11.5675 3 11.6846 3.01045 11.7864C3.09716 12.6316 3.7084 13.3298 4.53471 13.5274C4.63423 13.5512 4.75034 13.5667 4.98246 13.5977L9.37708 14.1836C10.628 14.3504 11.2535 14.4338 11.739 14.2408C12.1652 14.0713 12.5202 13.7605 12.7445 13.3604C13 12.9047 13 12.2737 13 11.0117V4.98831C13 3.72629 13 3.09527 12.7445 2.63959C12.5202 2.23951 12.1652 1.92869 11.739 1.75922C11.2535 1.56619 10.628 1.64959 9.37708 1.81638L4.98246 2.40233C4.75031 2.43329 4.63423 2.44876 4.53471 2.47257C3.7084 2.67023 3.09716 3.36838 3.01044 4.21356C3 4.31536 3 4.43246 3 4.66666M7 5.33333L9.66667 8M9.66667 8L7 10.6667M9.66667 8H1"
        strokeWidth="1.67"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LoginIcon;
