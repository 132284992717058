import React, { FC, useState } from 'react';
import Modal from './Modal';
import AlertIcon from '../icons/AlertIcon';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { DropdownOption } from '../../model/DropdownOption';
import InputDropdown from '../shared/location Dropdown/InputDropdown';
import { MessageDescriptor, useIntl } from 'react-intl';
import TextAreaField from '../shared/TextAreaField';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { handleReportDropdownContent } from '../../util/ReportUtil';

type Props = {
  isOpen: boolean;
  onCloseReportModal: (value: boolean) => void;
  onReport: (detail: string, claim: string) => void;
  type: 'listing' | 'message' | 'quarantine';
  confirmText: MessageDescriptor;
  modalTitle?: MessageDescriptor;
  dropDownLabel: string;
};

const ReportModal: FC<Props> = ({
  isOpen,
  onCloseReportModal,
  onReport,
  type,
  confirmText,
  modalTitle,
  dropDownLabel
}) => {
  const intl = useIntl();
  const dropdownOptions = handleReportDropdownContent(intl, type) || [];
  const [selectedIDOption, setSelectedIDOption] = useState<DropdownOption | undefined>(undefined);
  const schema = yup.object().shape({
    description: yup.string()
  });
  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });
  const onSubmit = (data: any) => {
    if (selectedIDOption?.title) {
      onReport(data.description, selectedIDOption?.title);
    }
  };

  return (
    <Modal
      onCancel={() => onCloseReportModal(false)}
      isOpen={isOpen}
      id="modal"
      confirmText={confirmText}
      title={modalTitle}
      icon={<AlertIcon></AlertIcon>}
      actionButtonsFlow="vertical"
      theme="primary"
      headerType="left"
      hasCancelButton={false}
      formId="report-listing">
      <div className="mt-5">
        <form id="report-listing" action="#" method="POST" onSubmit={handleSubmit(onSubmit)}>
          <InputDropdown
            name="reason"
            label={dropDownLabel}
            onChange={setSelectedIDOption}
            position="relative"
            defaultValue={selectedIDOption}
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.REPORT_LISTIN_SELECT_A_REASON.id,
              defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTIN_SELECT_A_REASON.defaultMessage
            })}
            options={dropdownOptions}></InputDropdown>
          {selectedIDOption?.id === 3 && (
            <div className="mt-4 ">
              <TextAreaField
                name="description"
                label={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.DESCRIPTION.id,
                  defaultMessage: TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage
                })}
                labelStyle=" "
                placeholder={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.REPORT_LISTING_DESCRIPTION_PLACEHOLDER.id,
                  defaultMessage:
                    TRANSLATED_CONSTANTS.REPORT_LISTING_DESCRIPTION_PLACEHOLDER.defaultMessage
                })}
                register={register}
                errors={errors?.description}
                className=""
                rows={5}
              />
            </div>
          )}
        </form>
      </div>
    </Modal>
  );
};

export default ReportModal;
