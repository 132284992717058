type Props = {
  className?: string;
};
const Menu2Icon = ({ className }: Props) => {
  return (
    <svg
      className={`h-6 w-4 ${className}`}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 12H7M21 6H3M21 18H3"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Menu2Icon;
