/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Typo } from '../Typo';
import FeaturedIcon from '../shared/FeaturedIcon';
import dogeIcon from '../../assets/dogelist-Icon.svg';
import Button from '../shared/Button';
import CloseIcon from '../icons/CloseIcon';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import clsx from 'clsx';
type Props = {
  title?: MessageDescriptor;
  subTitle?: MessageDescriptor;
  icon?: React.ReactElement;
  headerType?: 'center' | 'left' | 'one-row';
  setShow: (value: boolean) => void;
  username?: string;
  theme: 'error' | 'primary';
  containsLogo?: boolean;
  // All other props
  [x: string]: any;
};

const ModalHeader: React.FC<Props> = ({
  title,
  subTitle,
  icon,
  headerType,
  setShow,
  username,
  theme,
  containsLogo = false
}) => {
  return (
    <div
      className={clsx('flex', {
        'flex-col': headerType === 'left',
        'gap-4 ': headerType === 'one-row',
        'flex-col items-center': headerType === 'center'
      })}>
      <div className="">
        {!containsLogo && icon ? (
          <FeaturedIcon theme="light-circle-outline" color={theme} size="lg">
            {icon}
          </FeaturedIcon>
        ) : (
          <div>
            <FeaturedIcon
              theme="light-circle-outline"
              color={theme}
              size="lg"
              type="image"
              className="hidden md:block">
              <img src={dogeIcon} />
            </FeaturedIcon>
            <FeaturedIcon
              theme="light-circle-outline"
              color={theme}
              size="md"
              type="image"
              className="block md:hidden">
              <img src={dogeIcon} />
            </FeaturedIcon>
          </div>
        )}
      </div>
      <div
        className={clsx('flext gap-1', {
          'mt-4 items-center text-center': headerType === 'center',
          'mt-4 items-start ': headerType === 'left',
          'items-start': headerType === 'one-row'
        })}>
        <Typo.textLg fontWeight="font-medium" className="text-gray-900 ">
          <FormattedMessage
            id={title?.id}
            defaultMessage={title?.defaultMessage}
            values={{ username: username }}
          />
        </Typo.textLg>
        {subTitle && (
          <Typo.textSm fontWeight="font-normal" className="text-gray-600">
            <FormattedMessage
              id={subTitle?.id}
              defaultMessage={subTitle?.defaultMessage}
              values={{ username: username }}
            />
          </Typo.textSm>
        )}
      </div>
      <div className="absolute top-0 right-0 p-6 ">
        <Button
          size="lg"
          onClick={() => {
            setShow(false);
          }}
          className=""
          icon={<CloseIcon className="text-gray-500"></CloseIcon>}></Button>
      </div>
    </div>
  );
};

export default ModalHeader;
