// /* eslint-disable unused-imports/no-unused-vars */
// /* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useGetMapListings } from '../../hooks/marketPlace/useGetMapListings';
import { ApiSearchOrderEnum } from '../../util/enum/enum';
import { Typo } from '../../components/Typo';
import Divider from '../../components/shared/Divider';
import Filters from './components/Filters';
import NoListingsFound from './components/NoListingsFound';
import SearchResult from './components/SearchResult';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import {
  CLICKED_ZOOM,
  DEFAULT_LAT_LNG,
  DEFAULT_LOCATION_BOUNDS,
  NUMBER_OF_ITEMS_PER_PAGE
} from '../../constants/data';
import { AuthUserState } from '../../context/AuthUserProvider';
import { useAppSelector } from '../../lib/redux/hooks';
import { useBreadcrumb } from '../../context/Breadcrumb';
import { useLocation } from 'react-router-dom';
import useDeviceDetect from '../../hooks/useDeviceDetect';
import MapboxLe from './components/MapboxLe';
import { useDispatch } from 'react-redux';
import { addFilterModal, addLatLng } from '../../lib/redux/reducers/filter.slice';
import useCurrentLocation from '../../hooks/useLocation';
import { isEmpty } from 'lodash';

const MarketPlace = () => {
  const { authUser } = AuthUserState();
  const { addBreadcrumb, breadcrumbs } = useBreadcrumb();
  const location = useLocation();
  const filterFromModal = useAppSelector((state) => state.filter.filterModal);
  const [offset, setOffset] = useState({ listing: 0, storefront: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const { isDesktop, isMobile, isTablet } = useDeviceDetect();
  const dispatch = useDispatch();

  const { selectedLocation, isCurrentLocatinLoading } = useCurrentLocation();
  const locationData = useMemo(() => localStorage.getItem('locationData'), []);

  const currentLocation = useMemo(() => {
    return locationData ? JSON.parse(locationData ?? '') : null;
  }, [locationData]);

  useLayoutEffect(() => {
    const defaultGlobalLatLng = {
      lat: DEFAULT_LAT_LNG.lat, // Set your default latitude here
      lng: DEFAULT_LAT_LNG.lng, // Set your default longitude here
      zoom: DEFAULT_LAT_LNG.zoom // Set your default zoom level here
    };
    if (isCurrentLocatinLoading === -1) {
      let lat = defaultGlobalLatLng.lat;
      let lng = defaultGlobalLatLng.lng;
      let zoom = defaultGlobalLatLng.zoom;
      let locData = null;
      let address = '';
      let locationBounds = DEFAULT_LOCATION_BOUNDS;

      if (currentLocation && !isEmpty(currentLocation)) {
        lat = currentLocation?.location?.latitude;
        lng = currentLocation?.location?.longitude;
        zoom = CLICKED_ZOOM;
        locData = currentLocation?.location;
        locationBounds = currentLocation?.locationBound;
        address = currentLocation?.location?.address;
      }
      if (
        JSON.stringify(filterFromModal.locationBounds) ===
        JSON.stringify(selectedLocation.locationBound)
      ) {
        dispatch(
          addLatLng({
            latLng: {
              lat,
              lng,
              zoom
            }
          })
        );

        dispatch(
          addFilterModal({
            filterModal: {
              locationData: locData,
              locationBounds,
              address,
              isFirstValue: true,
              searchText: '',
              moreFilter: null,
              categories: []
            }
          })
        );
      }
    }
  }, [isCurrentLocatinLoading]);

  useEffect(() => {
    addBreadcrumb({ link: location.pathname, name: 'Map', index: breadcrumbs.length });
  }, []);

  const {
    loading,
    error,
    fetchMore,
    updateQuery,
    refetch,
    listings = [],
    storefronts = [],
    wantedListingsCount = 0,
    personalListingsCount = 0,
    storefrontItemsCount = 0,
    storefrontsCount = 0
  } = useGetMapListings(
    filterFromModal?.moreFilter,
    filterFromModal.categories,
    filterFromModal.searchText,
    filterFromModal.locationBounds,
    offset.listing,
    offset.storefront,
    ApiSearchOrderEnum.PUBLISHED_DESC,
    authUser?.id || null
  );

  const mapDataLength =
    wantedListingsCount + personalListingsCount + storefrontsCount + storefrontItemsCount;

  const fetchMoreData = (pageNumber: number) => {
    const listingStartIndex = (pageNumber - 1) * listings.length;
    const storefrontStartIndex = (pageNumber - 1) * storefronts.length;
    setOffset({
      listing: listingStartIndex,
      storefront: storefrontStartIndex
    });
    fetchMore({
      variables: {
        listingOffset: listingStartIndex,
        storefrontOffset: storefrontStartIndex,
        limit: NUMBER_OF_ITEMS_PER_PAGE
      }
    });
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <div className=" grid grid-cols-1 relative lg:grid-cols-2 lg:divide-x lg:divide-gray-200 w-full lg:mx-auto">
        {isCurrentLocatinLoading === -1 && (
          <>
            <div className="pt-12 pb-2 lg:pt-8 lg:pb-2">
              <div className="px-3 md:px-12 lg:pr-8 lg:pl-[60px]">
                <div className="mb-6">
                  <div className="font-poppins text-2xl mb-1 leading-8 md:text-3xl md:leading-[44px] text-gray-900 font-normal">
                    {mapDataLength}{' '}
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.MARKET_PLACE_TITLE.id}
                      defaultMessage={TRANSLATED_CONSTANTS.MARKET_PLACE_TITLE.defaultMessage}
                    />{' '}
                    <span className="font-semibold">{filterFromModal.address}</span>
                  </div>
                  <Typo.textMd fontWeight="font-normal" className="text-gray-500">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.MARKET_PLACE_SUBTITLE.id}
                      defaultMessage={TRANSLATED_CONSTANTS.MARKET_PLACE_SUBTITLE.defaultMessage}
                    />
                  </Typo.textMd>
                </div>
                <Divider />

                <Filters />
                {isMobile && (
                  <div className="block md:hidden w-full">
                    <div className="flex-1 ">
                      <MapboxLe width="94vw" height="40vh" isMobile={isMobile} />
                    </div>
                  </div>
                )}
                {isTablet && (
                  <div className="hidden md:block lg:hidden mt-8 w-full">
                    <MapboxLe width="90vw" height="60vh" />
                  </div>
                )}
                <div className="mt-8">
                  {mapDataLength < 1 && !loading ? (
                    <NoListingsFound />
                  ) : (
                    <SearchResult
                      loading={loading}
                      storefronts={storefronts}
                      listings={listings}
                      personalListingsCount={personalListingsCount}
                      storefrontsCount={storefrontsCount}
                      wantedListingsCount={wantedListingsCount}
                      storefrontItemsCount={storefrontItemsCount}
                      fetchMore={fetchMoreData}
                      currentPage={currentPage}
                      updateQuery={updateQuery}
                      refetch={refetch}
                    />
                  )}
                </div>
              </div>
            </div>
            {isDesktop && (
              <div className=" hidden lg:block relative p-3  ">
                <div className="fixed  ">
                  {/* <Mapbox width="48.5vw" /> */}
                  <MapboxLe width="48.5vw" />
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default MarketPlace;
