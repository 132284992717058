type Props = {
  className?: string;
};
const HeartIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.3131 5.37833C23.7173 4.78217 23.0098 4.30925 22.2311 3.98659C21.4524 3.66394 20.6177 3.49786 19.7748 3.49786C18.9319 3.49786 18.0973 3.66394 17.3186 3.98659C16.5399 4.30925 15.8324 4.78217 15.2365 5.37833L13.9998 6.61499L12.7631 5.37833C11.5595 4.17469 9.92702 3.49849 8.22481 3.49849C6.52261 3.49849 4.89012 4.17469 3.68648 5.37833C2.48284 6.58197 1.80664 8.21446 1.80664 9.91666C1.80664 11.6189 2.48284 13.2514 3.68648 14.455L4.92315 15.6917L13.9998 24.7683L23.0765 15.6917L24.3131 14.455C24.9093 13.8591 25.3822 13.1516 25.7049 12.3729C26.0275 11.5942 26.1936 10.7596 26.1936 9.91666C26.1936 9.07376 26.0275 8.23911 25.7049 7.46041C25.3822 6.68171 24.9093 5.97421 24.3131 5.37833Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HeartIcon;
