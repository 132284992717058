import React, { FC, useState } from 'react';
import { useForgotPasswordHandler } from '../../hooks/useHandleForgotPassword';
import Modal from './Modal';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useIntl } from 'react-intl';
import InputField from '../shared/InputField';
import InlineAlert from '../shared/InlineAlert';
import { ApolloError } from '@apollo/client/errors';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../lib/redux/reducers/modal.slice';

type Props = {
  isOpen: boolean;
  onCloseForgotPasswordModal: (arg0: boolean) => void;
};

const ForgotPasswordModal: FC<Props> = ({ isOpen, onCloseForgotPasswordModal }) => {
  const { register, onSubmit, handleSubmit, errors } = useForgotPasswordHandler();
  const disptach = useDispatch();
  const [submiting, setSubmiting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, SetAlertType] = useState<'default' | 'success' | 'error'>('success');
  const intl = useIntl();

  const onForgotPassword = async (data: any) => {
    try {
      setSubmiting(true);
      const response = await onSubmit(data);
      if (response?.data?.forgottenPassword?.ok) {
        setShowAlert(true);
        SetAlertType('success');
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.RESET_PASSWORD_EMAIL.id,
            defaultMessage: TRANSLATED_CONSTANTS.RESET_PASSWORD_EMAIL.defaultMessage
          })
        );
      }
    } catch (error) {
      setShowAlert(true);
      SetAlertType('error');
      if (error instanceof ApolloError) {
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
    } finally {
      setSubmiting(false);
    }
  };

  const signup = () => {
    disptach(hideModal('forgotPassword'));
    disptach(showModal('signup'));
  };
  return (
    <Modal
      onCancel={() => onCloseForgotPasswordModal(false)}
      isOpen={isOpen}
      isConfirm
      confirmText={TRANSLATED_CONSTANTS.FORGOT_PASSWORD_MODAL_CONFIRM_BUTTON}
      title={TRANSLATED_CONSTANTS.FORGOT_PASSWORD}
      subTitle={TRANSLATED_CONSTANTS.FORGOT_PASSWORD_MODAL_SUBTITLE}
      actionButtonsFlow="vertical"
      theme="primary"
      headerType="center"
      containsLogo={true}
      formId="forgot-password"
      isSubmiting={submiting}
      onChangeType={signup}
      type="login">
      <form id="forgot-password" action="#" method="POST" onSubmit={handleSubmit(onForgotPassword)}>
        <InlineAlert
          state={showAlert}
          onChangeState={setShowAlert}
          type={alertType}
          className="mt-4"
          text={alertMessage}></InlineAlert>
        <div className="w-full mt-5">
          <InputField
            name="email"
            label={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.EMAIL.id,
              defaultMessage: TRANSLATED_CONSTANTS.EMAIL.defaultMessage
            })}
            placeholder={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.id,
              defaultMessage: TRANSLATED_CONSTANTS.EMAIL_PLACEHOLDER.defaultMessage
            })}
            type="email"
            register={register}
            errors={errors?.email}
          />
        </div>
      </form>
    </Modal>
  );
};

export default ForgotPasswordModal;
