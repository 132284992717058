import { ChatState } from '../context/ChatNotificationProvider';

export const useCheckOnlineUsers = (userId: number): boolean => {
  const { onlineUsers, socket } = ChatState();
  // check if the onlineUsers object has the userId property
  if (socket && socket.connected) {
    if (onlineUsers && userId in onlineUsers) {
      // return the online value of the user object
      return onlineUsers[userId]?.online;
    } else {
      // return false by default if the user does not exist
      return false;
    }
  } else {
    return false;
  }
};
