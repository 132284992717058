type Props = {
  className?: string;
};
const Flag2Icon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.33334 8.74996C2.33334 8.74996 2.91668 8.16663 4.66668 8.16663C6.41668 8.16663 7.58334 9.33329 9.33334 9.33329C11.0833 9.33329 11.6667 8.74996 11.6667 8.74996V2.33329C11.6667 2.33329 11.0833 2.91663 9.33334 2.91663C7.58334 2.91663 6.41668 1.74996 4.66668 1.74996C2.91668 1.74996 2.33334 2.33329 2.33334 2.33329M2.33334 12.8333L2.33334 1.16663"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Flag2Icon;
