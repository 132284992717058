import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_DETAILED_REPORTED_MESSAGES = gql`
  query GetMessageReports($offset: Int, $limit: Int, $searchText: String) {
    messageReportsCount: usersPermissionsUsers(
      filters: {
        banned_user: { banned: { id: { eq: null } } }
        message_reported_user: { id: { ne: null } }
        or: [{ username: { contains: $searchText } }, { name: { contains: $searchText } }]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      pagination: { limit: $limit, start: $offset }
      sort: "createdAt:DESC"
      filters: {
        banned_user: { banned: { id: { eq: null } } }
        message_reported_user: { id: { ne: null } }
        or: [{ username: { contains: $searchText } }, { name: { contains: $searchText } }]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          createdAt
          type
          email
          profileImage {
            data {
              attributes {
                url
              }
            }
          }

          flags: listings(filters: { listing_reports: { id: { ne: null } } }) {
            data {
              attributes {
                listing_reports {
                  data {
                    id
                  }
                }
              }
            }
          }
          warnings: listings(
            filters: { issued_listing: { actionType: { eq: "delete-warning" } } }
          ) {
            data {
              id
              attributes {
                issued_listing {
                  data {
                    id
                  }
                }
              }
            }
          }

          message_reported_user {
            data {
              id
              attributes {
                message {
                  data {
                    id
                    attributes {
                      content
                      createdAt
                    }
                  }
                }
                claim
                detail
                date
                opened
                reporter {
                  data {
                    id
                    attributes {
                      name
                      username
                      type
                      email
                      profileImage {
                        data {
                          attributes {
                            url
                            formats
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetDetailedReportedMessages = (
  offset: number,
  limit: number,
  searchText: string
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_DETAILED_REPORTED_MESSAGES, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });

  return {
    messageReports: flattenObj(data?.usersPermissionsUsers),
    messageReportsCount: data?.messageReportsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
