/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC } from 'react';
import CheckboxField from '../../../../components/shared/CheckboxField';
import { Typo } from '../../../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';

type Props = {
  checked: boolean;
  toggleAll: (e: any) => void;
};
const TableHead: FC<Props> = ({ checked, toggleAll }) => {
  return (
    <thead className="bg-gray-50">
      <tr>
        <th scope="col" className="relative px-7  sm:w-12 sm:px-7 py-3 ">
          <div className="absolute left-6 top-1/2 -mt-3.5">
            <CheckboxField
              name="state"
              value="check"
              label=""
              size="md"
              type="checkbox"
              isSelected={checked}
              onChange={(e) => toggleAll(e)}
              roundedSize="rounded-md"
              register={() => {}}
            />
          </div>
        </th>
        <th scope="col" className="min-w-[12rem] px-3 py-3 text-left  ">
          <Typo.textSm fontWeight="font-medium" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.LISTINGS.id}
              defaultMessage={TRANSLATED_CONSTANTS.LISTINGS.defaultMessage}
            />
          </Typo.textSm>
        </th>

        <th scope="col" className="px-3 py-3 text-left  ">
          <Typo.textSm fontWeight="font-medium" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.REASON.id}
              defaultMessage={TRANSLATED_CONSTANTS.REASON.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="px-3 py-3 text-left  ">
          <Typo.textSm fontWeight="font-medium" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.DATE.id}
              defaultMessage={TRANSLATED_CONSTANTS.DATE.defaultMessage}
            />
          </Typo.textSm>
        </th>

        <th scope="col" className="px-3 py-3 text-left  flex gap-1 items-center">
          <Typo.textSm fontWeight="font-medium" className="text-gray-600 ">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ACTION.id}
              defaultMessage={TRANSLATED_CONSTANTS.ACTION.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="py-3 pr-3 text-left">
          <Typo.textSm fontWeight="font-medium" className="  text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.USER.id}
              defaultMessage={TRANSLATED_CONSTANTS.USER.defaultMessage}
            />
          </Typo.textSm>
        </th>
        <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-3"></th>
      </tr>
    </thead>
  );
};

export default TableHead;
