type Props = {
  className?: string;
};
const MailIcon = ({ className }: Props) => {
  return (
    <svg
      className={className ? className : ' w-4 h-4 '}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66602 5.83334L8.47012 10.5962C9.02109 10.9819 9.29658 11.1747 9.59624 11.2494C9.86093 11.3154 10.1378 11.3154 10.4025 11.2494C10.7021 11.1747 10.9776 10.9819 11.5286 10.5962L18.3327 5.83334M5.66602 16.6667H14.3327C15.7328 16.6667 16.4329 16.6667 16.9677 16.3942C17.4381 16.1545 17.8205 15.7721 18.0602 15.3017C18.3327 14.7669 18.3327 14.0668 18.3327 12.6667V7.33334C18.3327 5.93321 18.3327 5.23315 18.0602 4.69837C17.8205 4.22796 17.4381 3.84551 16.9677 3.60583C16.4329 3.33334 15.7328 3.33334 14.3327 3.33334H5.66602C4.26588 3.33334 3.56582 3.33334 3.03104 3.60583C2.56063 3.84551 2.17818 4.22796 1.9385 4.69837C1.66602 5.23315 1.66602 5.93321 1.66602 7.33334V12.6667C1.66602 14.0668 1.66602 14.7669 1.9385 15.3017C2.17818 15.7721 2.56063 16.1545 3.03104 16.3942C3.56582 16.6667 4.26588 16.6667 5.66602 16.6667Z"
        strokeWidth="1.67"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailIcon;
