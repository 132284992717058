type Props = {
  className?: string;
};
const ArrowDownIcon = ({ className }: Props) => {
  return (
    <svg
      className={className ? className : ' text-gray-600'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99992 3.33331V12.6666M7.99992 12.6666L12.6666 7.99998M7.99992 12.6666L3.33325 7.99998"
        strokeWidth="1.3333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDownIcon;
