import { Typo } from '../Typo';
import TrashIcon from '../icons/TrashIcon';
import Button from './Button';
import Image from './Image';

import React from 'react';
type ImagePorps = {
  alt?: string;
  src?: string | null | undefined;
  className?: string;
  onClick?: () => void;
  showText?: boolean;
};
const ImagePreview: React.FC<ImagePorps> = (props) => {
  const { src, alt, className, onClick, showText = false } = props;
  return (
    <div className={`relative w-full group ${className}`}>
      <div className=" absolute hidden group-hover:flex bg-[#34405480]  h-full w-full items-center justify-center rounded-xl">
        <Button
          onClick={onClick}
          className="border border-white cursor-pointer"
          icon={<TrashIcon className="h-5 text-white" />}>
          {showText ? (
            <Typo.textMd fontWeight="font-semibold" className="text-white">
              Delete Image
            </Typo.textMd>
          ) : (
            ''
          )}
        </Button>
      </div>
      <Image className=" rounded-xl h-full w-full object-cover " src={src} alt={alt}></Image>
    </div>
  );
};

export default ImagePreview;
