type Props = {
  className?: string;
};
const Featured1Icon = ({ className }: Props) => {
  return (
    <>
      <svg
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#FEF2CD" />
        <path
          d="M20.5 29.9999L22.0746 36.2985C22.1189 36.4756 22.1411 36.5642 22.1673 36.6415C22.4232 37.397 23.1035 37.9282 23.8985 37.9933C23.9799 37.9999 24.0712 37.9999 24.2538 37.9999C24.4824 37.9999 24.5968 37.9999 24.6931 37.9906C25.645 37.8982 26.3983 37.1449 26.4907 36.193C26.5 36.0967 26.5 35.9823 26.5 35.7537V21.4999M35 29.4999C36.933 29.4999 38.5 27.9329 38.5 25.9999C38.5 24.0669 36.933 22.4999 35 22.4999M26.75 21.4999H23C20.5147 21.4999 18.5 23.5146 18.5 25.9999C18.5 28.4852 20.5147 30.4999 23 30.4999H26.75C28.5164 30.4999 30.6772 31.4468 32.3443 32.3556C33.3168 32.8857 33.8031 33.1508 34.1216 33.1118C34.4169 33.0756 34.6402 32.943 34.8133 32.701C35 32.4401 35 31.9179 35 30.8736V21.1262C35 20.0819 35 19.5598 34.8133 19.2988C34.6402 19.0568 34.4169 18.9242 34.1216 18.888C33.8031 18.849 33.3168 19.1141 32.3443 19.6443C30.6772 20.553 28.5164 21.4999 26.75 21.4999Z"
          stroke="#B08503"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#FFF9EB" strokeWidth="8" />
      </svg>
    </>
  );
};

export default Featured1Icon;
