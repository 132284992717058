import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../lib/redux/hooks';
import { ChatState } from '../../context/ChatNotificationProvider';
import { hideSlide } from '../../lib/redux/reducers/slide.slice';
import { hideModal } from '../../lib/redux/reducers/modal.slice';
import HomePageFilterModal from '../modal/HomePageFilterModal';
import Alert from '../shared/Alert';
import { setAlertType, setIsBannerOpen } from '../../lib/redux/reducers/alert.slice';
import ShareModal from '../modal/ShareModal';
import { setShareData } from '../../lib/redux/reducers/share.slice';
import MessageProfileSlide from '../dashboard/slide/MessageProfileSlide';
import { BREADCRUMB_ROUTES, ROUTES } from '../../constants/data';
import { useAuthRedirect } from '../../hooks/useAuthRedirect';
import PrimaryActionModal from '../modal/PrimaryActionModal';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useNavigate } from 'react-router-dom';
import InfoIcon from '../icons/InfoIcon';
import Notification from '../shared/Notification';
import Navigation from '../header/Navigation';
import Banner from '../shared/Banner';
import { handlegetCurrentLocationSuccess } from '../../util/AppUtil';
import { useBreadcrumb } from '../../context/Breadcrumb';
import { useLocation } from 'react-router-dom';
import MapIcon from '../icons/MapIcon';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import CommingSoonModal from '../modal/ComingSoonModal';
type Props = {
  children: React.ReactElement;
};

const authRoutes = [
  ROUTES.REPORTS,
  ROUTES.DASHBOARD_ROUTE,
  ROUTES.CREATE_LISTING_ROUTE,
  ROUTES.CREATE_STOREFRONT_ROUTE,
  ROUTES.EDIT_STOREFRONT_ROUTE,
  ROUTES.EDIT_LISTING_ROUTE,
  ROUTES.LEADER_BOARD,
  ROUTES.PROFILE
];

const TopLayerLayout: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const location = useLocation();
  const intl = useIntl();
  const { chatId, setOnlineUsers, socket } = ChatState();
  const profileSlide = useAppSelector((state) => state.slide.profile);
  const messageSlide = useAppSelector((state) => state.slide.message);
  const chatData = useAppSelector((state) => state.slide.chatData);
  const alertData = useAppSelector((state) => state.alert.alertData);
  const alertType = useAppSelector((state) => state.alert.alertType);
  const shareData = useAppSelector((state) => state.share);
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const direction = useAppSelector((state) => state.modal.direction);
  const directionData = useAppSelector((state) => state.direction.directionData);
  const { setBreadcrumbs } = useBreadcrumb();
  const showCompleteAccountModalToCreateListing = useAppSelector(
    (state) => state.modal.completeAccountToCreateListing
  );
  const showCompleteAccountModalToSendMessage = useAppSelector(
    (state) => state.modal.completeAccountToSendMessage
  );
  const showCommingSoonModl = useAppSelector((state) => state.modal.commingSoon);
  const locationAccess = localStorage.getItem('locationAccess');
  const stringifyLocationAccess = locationAccess ? JSON.parse(locationAccess) : false;
  const [showLocationBanner, setShowLocationBanner] = useState(
    stringifyLocationAccess === true ? false : true
  );

  useAuthRedirect(authRoutes);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (!messageSlide && socket) {
      socket.emit('leave chat', chatId);
    }
  }, [socket, messageSlide]);

  const filterModalState = useAppSelector((state) => state.modal.filter);

  const closeFilterModal = () => {
    dispatch(hideModal('filter'));
  };

  const closeCommingSoonModal = () => {
    dispatch(hideModal('commingSoon'));
  };
  useEffect(() => {
    if (socket && socket.connected) {
      socket.on('user status', (users: any) => {
        setOnlineUsers(users);
      });
    }
  }, [socket]);

  const onCloseCompleteAccountModal = (value: boolean) => {
    dispatch(hideModal('completeAccountToCreateListing'));
    if (value) {
      navigate(ROUTES.CREATE_ACOUNT_ROUTE);
    }
  };
  const onCloseCompleteAccountSendMessageModal = (value: boolean) => {
    dispatch(hideModal('completeAccountToSendMessage'));
    if (value) {
      navigate(ROUTES.CREATE_ACOUNT_ROUTE);
    }
  };

  const onAllowLocationDetection = () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(handleSuccess);
    }
  };
  // Define a function to handle the success callback
  const handleSuccess = (position: any) => {
    handlegetCurrentLocationSuccess(position);
    setShowLocationBanner(false);
  };

  const onDeclineLocationAccess = () => {
    const locationAccess = JSON.stringify(false);
    localStorage.setItem('locationAccess', locationAccess);
    setShowLocationBanner(false);
  };

  useEffect(() => {
    const curr = BREADCRUMB_ROUTES.filter((route) => route.pathName === location.pathname);
    setBreadcrumbs([{ link: location.pathname, name: curr[0]?.name, index: 0 }]);
  }, []);

  const onCloseLocationModal = (value: boolean) => {
    if (value) {
      window.open(
        `https://www.google.com/maps/place/${directionData.destination.address}/@${directionData.destination.lat},${directionData.destination.long}/`,
        '_blank'
      );
    }
    dispatch(hideModal('direction'));
  };

  function fallbackRender({ error, resetErrorBoundary }: any) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.

    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: 'red' }}>{error.message}</pre>
      </div>
    );
  }
  return (
    <ErrorBoundary
      fallbackRender={fallbackRender}
      onReset={(details) => {
        // Reset the state of your app so the error doesn't happen again
      }}>
      <div className="relative">
        <Banner
          state={showLocationBanner}
          onCloseClicked={() => {
            setShowLocationBanner(false);
            dispatch(setIsBannerOpen(false));
          }}
          onAllowClicked={onAllowLocationDetection}
          onDeclineClicked={onDeclineLocationAccess}
          icon={<InfoIcon />}
          type="dual-action"
          theme="primary-light"
          dualActionText={`${intl.formatMessage({
            id: TRANSLATED_CONSTANTS.LOCATION_BANNER_DESCRIPTION.id,
            defaultMessage: TRANSLATED_CONSTANTS.LOCATION_BANNER_DESCRIPTION.defaultMessage
          })}`}></Banner>
        <Navigation />
        <div className="overflow-hidden">{children}</div>
        <MessageProfileSlide
          chatData={chatData}
          openProfile={profileSlide}
          openMessage={messageSlide}
          onClose={() => {
            dispatch(hideSlide('profile'));
            dispatch(hideSlide('message'));
          }}
        />
        <HomePageFilterModal isOpen={filterModalState} onCloseFiltreModal={closeFilterModal} />
        <CommingSoonModal isOpen={showCommingSoonModl} onCloseFiltreModal={closeCommingSoonModal} />

        <ShareModal
          isOpen={shareData.isOpen}
          url={shareData.url}
          onCloseShareModal={() => dispatch(setShareData({ isOpen: false, url: '' }))}
        />
        <Alert
          title={alertData.title}
          description={alertData.description}
          type={alertType}
          isOpen={alertType !== ''}
          onCloseAlert={() => dispatch(setAlertType(''))}
          firstButtonText={alertData.cancelTitle}
          secondButtonText={alertData.confirmTitle}
          secondButtonLink={alertData.link}
        />
        <div className=" fixed max-w-[400px] z-30 h-auto space-y-4 top-20 right-5 flex flex-col">
          {notifications?.map((notification) => {
            return (
              <Notification
                id={notification?.id}
                key={notification.title}
                title={notification.title}
                description={notification.description}
                type={notification.type}
                link={notification.link}
                linkText={notification.linkText}
                linkIcon={notification.linkIcon}
              />
            );
          })}
        </div>

        <PrimaryActionModal
          title={TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_TITLE_TO_CREATE_LISTING}
          isOpen={showCompleteAccountModalToCreateListing}
          onClosePrimaryActionModal={(value: boolean) => onCloseCompleteAccountModal(value)}
          subTitle={TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_CONFIRM_BUTTON}
          icon={<InfoIcon />}
          actionButtonsFlow="vertical"
        />
        <PrimaryActionModal
          title={TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_TITLE_TO_SEND_MESSAGE}
          isOpen={showCompleteAccountModalToSendMessage}
          onClosePrimaryActionModal={(value: boolean) =>
            onCloseCompleteAccountSendMessageModal(value)
          }
          subTitle={TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_TO_SEND_MESSAGE_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.COMPLETE_ACCOUNT_MODAL_CONFIRM_BUTTON}
          icon={<InfoIcon />}
          actionButtonsFlow="vertical"
        />

        <PrimaryActionModal
          title={TRANSLATED_CONSTANTS.VERIFY_LOCATION_MODAL_TITLE}
          isOpen={direction}
          onClosePrimaryActionModal={(value: boolean) => onCloseLocationModal(value)}
          subTitle={TRANSLATED_CONSTANTS.VERIFY_LOCATION_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.VERIFY_LOCATION_MODAL_CONFIRM_BUTTON}
          icon={<MapIcon />}
        />
      </div>
    </ErrorBoundary>
  );
};

export default TopLayerLayout;
