import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useLogin } from './user/useLogin';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';
export const useHandleLogin = () => {
  const login = useLogin();
  const intl = useIntl();

  const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(
      intl.formatMessage({
        id: TRANSLATED_CONSTANTS.ENTER_A_PASSWORD.id,
        defaultMessage: TRANSLATED_CONSTANTS.ENTER_A_PASSWORD.defaultMessage
      })
    )
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange'
  });

  const onSubmit = async (data: any) => {
    localStorage.removeItem('token');
    const loginResponse = await login({
      variables: {
        input: { identifier: data.email, password: data.password }
      }
    });
    return loginResponse;
  };

  return { register, errors, handleSubmit, onSubmit, reset };
};
