import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_STOREFRONT_ITEM = gql`
  query getStorefrontItem($id: ID!, $userId: ID, $skip: Boolean!) {
    listing(id: $id) {
      data {
        id
        attributes {
          image(sort: ["createdAt:DESC"]) {
            data {
              id
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              id
              attributes {
                name
                username
                email
                profileImage {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          name
          description
          isSold
          location {
            address
            latitude
            longitude
            state
            country
            city
            street
          }
          slug
          price {
            priceType
            priceInUSD
            priceInDoge
            alternatePriceOptions
          }
          type
          category {
            data {
              id
              attributes {
                name
              }
            }
          }
          item_condition {
            data {
              id
              attributes {
                name
              }
            }
          }
          saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          listing_reports(filters: { reporter: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                detail
                date
              }
            }
          }
          store_front {
            data {
              id
              attributes {
                name
                description
                image(sort: ["createdAt:DESC"]) {
                  data {
                    id
                    attributes {
                      url
                      formats
                    }
                  }
                }
                location {
                  id
                  latitude
                  longitude
                  state
                  street
                  address
                  country
                  city
                }
                listings(pagination: { limit: 4, start: 0 }, filters: { id: { ne: $id } }) {
                  data {
                    id
                    attributes {
                      image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
                        data {
                          id
                          attributes {
                            url
                            formats
                          }
                        }
                      }
                      name
                      description
                      isSold
                      slug
                      price {
                        priceType
                        priceInUSD
                        priceInDoge
                        alternatePriceOptions
                      }
                      type
                      saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
                        data {
                          id
                          attributes {
                            user {
                              data {
                                id
                              }
                            }
                          }
                        }
                      }
                      user {
                        data {
                          id
                          attributes {
                            name
                            username
                          }
                        }
                      }
                    }
                  }
                }
                operationHours {
                  id
                  day
                  opensAt
                  closesAt
                  isOpen
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetStorefrontItem = (id: number, userId?: number) => {
  let skip = true;
  if (userId) {
    skip = false;
  }
  const { loading, error, data, refetch } = useQuery(GET_STOREFRONT_ITEM, {
    fetchPolicy: 'cache-and-network',
    variables: { id: id, userId: userId, skip }
  });
  return {
    listing: flattenObj(data?.listing, true),
    refetch,
    loading,
    error
  };
};
