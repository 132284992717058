import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, ReactElement } from 'react';
import XIcon from '../../icons/XIcon';
import { Typo } from '../../Typo';
import Button from '../../shared/Button';
type Props = {
  open: boolean;
  title?: string;
  setOpen: (state: boolean) => void;
  children: ReactElement;
  className?: string;
};
const SlideScreen: React.FC<Props> = (props) => {
  const { open, setOpen, children, className, title } = props;
  return (
    <div className="relative">
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed z-10 inset-0 overflow-hidden  ">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className={`pointer-events-none pt-[72px] fixed inset-y-0 right-0 flex max-w-full pl-10 `}>
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full">
                  <Dialog.Panel className={`pointer-events-auto ${className}`}>
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="flex min-h-0 flex-1 flex-col">
                        {title ? (
                          <div className="flex items-start justify-between py-5 px-4">
                            <Dialog.Title>
                              <Typo.textXl fontWeight="font-medium" className="text-gray-900">
                                {title}
                              </Typo.textXl>
                            </Dialog.Title>
                            <div className=" flex gap-3 h-7 items-center">
                              <Button
                                onClick={() => setOpen(false)}
                                theme="gray"
                                size="md"
                                icon={<XIcon className="h-5 w-5" aria-hidden="true" />}>
                                <span className="sr-only">Close panel</span>
                              </Button>
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                        <div className="relative h-full z-10">{children}</div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default SlideScreen;
