/* This example requires Tailwind CSS v2.0+ */
import Modal from './Modal';
import React, { FC } from 'react';
import { MessageDescriptor } from 'react-intl';
import TrashIcon from '../icons/TrashIcon';

type Props = {
  title: MessageDescriptor;
  subTitle: MessageDescriptor;
  isOpen: boolean;
  onCloseRedActionModal: (arg0: boolean) => void;
  confirmText: MessageDescriptor;
  cancelText?: MessageDescriptor;
  icon?: React.ReactElement;
  actionButtonsFlow?: 'vertical' | 'horizontal';
  username?: string;
  hasButtonIcon?: boolean;
  buttonIcon?: React.ReactElement;
  confirmButtonIcon?: React.ReactElement;
};
const RedActionModal: FC<Props> = (props) => {
  const {
    onCloseRedActionModal,
    isOpen,
    confirmText,
    cancelText,
    title,
    subTitle,
    icon,
    actionButtonsFlow,
    username,
    hasButtonIcon,
    buttonIcon,
    confirmButtonIcon = <TrashIcon className="text-white w-[18px]"></TrashIcon>
  } = props;
  return (
    <Modal
      onCancel={() => onCloseRedActionModal(false)}
      onConfirm={() => onCloseRedActionModal(true)}
      isOpen={isOpen}
      isConfirm
      confirmText={confirmText}
      cancelText={cancelText}
      title={title}
      subTitle={subTitle}
      icon={icon}
      actionButtonsFlow={actionButtonsFlow}
      username={username}
      hasButtonIcon={hasButtonIcon}
      buttonIcon={buttonIcon}
      theme="error"
      confirmButtonIcon={confirmButtonIcon}
      headerType="left"></Modal>
  );
};

export default RedActionModal;
