import { FC, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useGeCategories } from '../../hooks/category/useGetCategory';
import { DropdownOption } from '../../model/DropdownOption';
import { ACCOUNT_TYPE, ROUTES, shiftData } from '../../constants/data';
import { AuthUserState } from '../../context/AuthUserProvider';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import { Typo } from '../../components/Typo';
import InputField from '../../components/shared/InputField';
import InputDropdown from '../../components/shared/location Dropdown/InputDropdown';
import Dotpoints1Icon from '../../components/icons/Dotpoints1Icon';
import TextAreaField from '../../components/shared/TextAreaField';
import LocationDropdown from '../../components/shared/location Dropdown/LocationDropdown';
import Location from '../../components/shared/map/Location';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Button from '../../components/shared/Button';
import PlusIcon from '../../components/icons/PlusIcon';
import ImageUploader from '../../components/shared/ImageUploader';
import { LocationType } from '../../model/Location';
import { ImageFile } from '../../model/Image';
import { useUploadFiles } from '../../hooks/user/useUploadImages';
import { ApolloError } from '@apollo/client/errors';
import InlineAlert from '../../components/shared/InlineAlert';
import { OperationHourModel } from '../../model/OperationHourModel';
import OperationHour from '../../components/shared/Hours Operation/OperationHour';
import { useCreateStorefront } from '../../hooks/storfront/useCreateStorefront';
import { getCurrentDate } from '../../util/CurrentDate';
import { flattenObj } from '../../util/flattenObj';
import { handleValidationMessage } from '../../util/ListingBadgeHandler';
import { useNavigate } from 'react-router-dom';

type Props = {
  isFirstStorefront?: boolean;
  cancelCreation?: () => void;
  create?: () => void;
};

const CreateStorefront: FC<Props> = ({ isFirstStorefront = false, cancelCreation, create }) => {
  const auth = AuthUserState();
  const user = auth.authUser;
  const createStorefront = useCreateStorefront();
  const uploadFiles = useUploadFiles();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { categories } = useGeCategories();
  const [selectedCategory, setSelectedCategory] = useState<DropdownOption>();
  const [coords, setCoords] = useState<number[]>([51.505, -0.09]);
  const [files, setFiles] = useState<ImageFile[]>([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [imageRequired, setImageRequired] = useState(false);
  const [alertType, setAlertType] = useState<'default' | 'success' | 'error'>('success');
  const [oprationHours, setOperationHours] = useState<OperationHourModel[]>([]);
  const intl = useIntl();
  const navigate = useNavigate();
  const validationMessages = handleValidationMessage(intl);

  useEffect(() => {
    if (categories && categories.length > 0 && !selectedCategory) {
      setSelectedCategory(categories[0]);
    }
  }, [categories]);
  const listingSchema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string().required(),
    latitude: yup.number().typeError(validationMessages['must_be_number']),
    longitude: yup.number().typeError(validationMessages['must_be_number']),
    city: yup.string().required(),
    state: yup.string().required(),
    address: yup.string().nullable(),
    country: yup.string().required()
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(listingSchema)
  });

  const onCancel = async () => {
    reset();
    if (isFirstStorefront && cancelCreation) {
      cancelCreation();
    } else {
      navigate(ROUTES.DASHBOARD_ROUTE);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (files.length > 0) {
        setIsSubmiting(true);
        const response = await createStorefront({
          variables: {
            data: {
              image: [],
              name: data.name,
              description: data.description,
              category: Number(selectedCategory?.id) || 0,
              user: user?.id,
              location: {
                latitude: Number(data?.latitude || 0),
                longitude: Number(data?.longitude || 0),
                address: data?.address || '',
                city: data?.city || '',
                state: data?.state || '',
                country: data?.country || ''
              },
              operationHours: oprationHours.map((operationHour) => ({
                day: operationHour.day,
                isOpen: operationHour.isOpen,
                opensAt: operationHour.opensAt.title,
                closesAt: operationHour.closesAt.title
              })),
              publishedAt: getCurrentDate()
            }
          }
        });

        if (response) {
          const storefrontId = flattenObj(response?.data).createStoreFront.id;
          await uploadFiles.onUpload(files, 'api::store-front.store-front', storefrontId, 'image');
          reset();
          setFiles([]);
          if (isFirstStorefront && create) {
            create();
          } else {
            navigate(ROUTES.DASHBOARD_ROUTE);
          }
        }
      } else {
        setImageRequired(true);
      }
    } catch (error) {
      setAlertType('error');
      if (error instanceof ApolloError) {
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
      setShowAlert(true);
    } finally {
      setIsSubmiting(false);
    }
  };

  const onPlaceSelected = (place: LocationType) => {
    setCoords([place.latitude, place.longitude]);
    setValue('address', place?.address || '', { shouldValidate: true });
    setValue('city', place.city || '', { shouldValidate: true });
    setValue('country', place.country || '', {
      shouldValidate: true
    });
    setValue('state', place?.state || '', { shouldValidate: true });
    setValue('latitude', place.latitude || 0, { shouldValidate: true });
    setValue('longitude', place.longitude || 0, { shouldValidate: true });
  };

  useEffect(() => {
    if (user?.location) {
      onPlaceSelected(user?.location);
    }
  }, [user]);

  return (
    <>
      <form id="add-location" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
        <div className="pt-16 pb-24 md:max-w-[640px] lg:max-w-[1064px] mx-auto">
          <DashboardHeader
            showBreadCrumb={isFirstStorefront ? false : true}
            title={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.defaultMessage
            })}
            accountType={isFirstStorefront ? ACCOUNT_TYPE.BUSINESS : ''}
            cancelButtonTitle={intl.formatMessage({
              id: isFirstStorefront
                ? TRANSLATED_CONSTANTS.SKIP_FOR_NOW.id
                : TRANSLATED_CONSTANTS.CANCEL.id,
              defaultMessage: isFirstStorefront
                ? TRANSLATED_CONSTANTS.SKIP_FOR_NOW.defaultMessage
                : TRANSLATED_CONSTANTS.CANCEL.defaultMessage
            })}
            confirmButtonTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.defaultMessage
            })}
            confirmType="submit"
            confirmButtonIcon={<PlusIcon />}
            onCancelClick={onCancel}
            description={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.ADD_STOREFRONT_HERE.id,
              defaultMessage: TRANSLATED_CONSTANTS.ADD_STOREFRONT_HERE.defaultMessage
            })}></DashboardHeader>
          <InlineAlert
            state={showAlert}
            onChangeState={setShowAlert}
            type={alertType}
            className="my-6"
            text={alertMessage}></InlineAlert>
          <div className="px-4 divide-y divide-gray-200">
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="pt-8 flex flex-col md:col-span-4 lg:col-span-1 w-full lg:grid lg:grid-cols-4 lg:gap-x-8  pb-5 space-y-5 lg:space-y-0">
                <div className=" lg:col-span-1">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_STOREFRONT.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_STOREFRONT.defaultMessage
                    })}{' '}
                    *
                  </Typo.textSm>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500 max-w-[280px]">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_STOREFRONT_DESCRIPTION.id,
                      defaultMessage:
                        TRANSLATED_CONSTANTS.ADD_PHOTOS_FOR_STOREFRONT_DESCRIPTION.defaultMessage
                    })}
                  </Typo.textSm>
                  {imageRequired && files.length == 0 ? (
                    <Typo.textSm fontWeight="font-normal" className="text-error-600">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.IMAGES_ARE_REQUIRED.id,
                        defaultMessage: TRANSLATED_CONSTANTS.IMAGES_ARE_REQUIRED.defaultMessage
                      })}
                    </Typo.textSm>
                  ) : (
                    ''
                  )}
                </div>
                <ImageUploader images={files} className=" lg:col-span-2" onChange={setFiles} />
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.ENTER_NAME_OF_YOUR_STORE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.ENTER_NAME_OF_YOUR_STORE.defaultMessage
                  })}{' '}
                  *
                </Typo.textSm>
                <div className="lg:col-span-2">
                  <InputField
                    name="name"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ENTER_YOUR_STORE_NAME.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ENTER_YOUR_STORE_NAME.defaultMessage
                    })}
                    register={register}
                    errors={errors.name}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.SELECT_YOUR_STORE_CATEGORY.id,
                    defaultMessage: TRANSLATED_CONSTANTS.SELECT_YOUR_STORE_CATEGORY.defaultMessage
                  })}{' '}
                  *
                </Typo.textSm>
                <div className=" lg:col-span-2">
                  <InputDropdown
                    prefix={<Dotpoints1Icon className="h-5 text-gray-500" />}
                    onChange={setSelectedCategory}
                    defaultValue={selectedCategory}
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.SELECT_A_CATEGORY.id,
                      defaultMessage: TRANSLATED_CONSTANTS.SELECT_A_CATEGORY.defaultMessage
                    })}
                    options={categories}></InputDropdown>
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.DESCRIPTION.id,
                    defaultMessage: TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage
                  })}
                  *
                </Typo.textSm>
                <div className=" lg:col-span-2">
                  <TextAreaField
                    name="description"
                    rows={6}
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ENTER_DESCRIPTION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ENTER_DESCRIPTION.defaultMessage
                    })}
                    register={register}
                    errors={errors.description}
                  />
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <div>
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.HOURS_OF_OPERATION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.HOURS_OF_OPERATION.defaultMessage
                    })}
                  </Typo.textSm>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.CONFIGURE_STOREFRONT_HOURS.id,
                      defaultMessage: TRANSLATED_CONSTANTS.CONFIGURE_STOREFRONT_HOURS.defaultMessage
                    })}
                  </Typo.textSm>
                </div>
                <div className=" lg:col-span-2">
                  <OperationHour
                    defaultValue={shiftData}
                    onChange={setOperationHours}></OperationHour>
                </div>
              </div>
            </div>
            <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1">
              <div className="py-5 w-full space-y-1.5 flex flex-col  md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-4 lg:gap-x-8 lg:space-y-0">
                <div className=" lg:col-span-1">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.STOREFRONT_LOCATION.id,
                      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_LOCATION.defaultMessage
                    })}
                    *
                  </Typo.textSm>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.WHERE_STORE_LOCATED.id,
                      defaultMessage: TRANSLATED_CONSTANTS.WHERE_STORE_LOCATED.defaultMessage
                    })}
                    ?
                  </Typo.textSm>
                </div>
                <div className=" flex flex-col lg:col-span-2 w-full">
                  <LocationDropdown
                    placeholder={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.LOCATION_PLACEHOLDER.id,
                      defaultMessage: TRANSLATED_CONSTANTS.LOCATION_PLACEHOLDER.defaultMessage
                    })}
                    className="mt-5"
                    onLocationSelect={onPlaceSelected}></LocationDropdown>
                  <div className="w-full">
                    <Location
                      coords={coords}
                      type="storefront"
                      className=" min-w-full mt-3 h-[200px]"
                    />
                  </div>
                  <div>
                    <Typo.textXl fontWeight="font-medium" className="my-8 text-gray-900">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.defaultMessage
                      })}
                    </Typo.textXl>

                    <div className="overflow-visible sm:rounded-md space-y-5 ">
                      <div className="grid grid-cols-2 gap-x-3">
                        <InputField
                          name="latitude"
                          label={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.LATITUDE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.LATITUDE.defaultMessage
                          })}
                          placeholder="35.8973748"
                          register={register}
                          type="text"
                          errors={errors.latitude}
                        />
                        <InputField
                          name="longitude"
                          label={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.LONGITUDE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.LONGITUDE.defaultMessage
                          })}
                          placeholder="-119.887349"
                          register={register}
                          type="text"
                          errors={errors.longitude}
                        />
                      </div>
                      <div className="w-full">
                        <InputField
                          name="address"
                          label={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ADDRESS.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ADDRESS.defaultMessage
                          })}
                          placeholder={intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.ENTER_AN_ADDRESS.id,
                            defaultMessage: TRANSLATED_CONSTANTS.ENTER_AN_ADDRESS.defaultMessage
                          })}
                          helpIcon={true}
                          register={register}
                          type="text"
                          errors={errors.address}
                        />
                      </div>
                      <div className="grid grid-cols-2 gap-x-3">
                        <InputField
                          name="city"
                          label={`${intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.CITY.id,
                            defaultMessage: TRANSLATED_CONSTANTS.CITY.defaultMessage
                          })}*`}
                          placeholder="San Diego"
                          register={register}
                          type="text"
                          errors={errors.city}
                        />
                        <InputField
                          name="state"
                          label={`${intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.defaultMessage
                          })}*`}
                          placeholder="California"
                          register={register}
                          type="text"
                          errors={errors.state}
                        />
                      </div>

                      <div className="w-full">
                        <InputField
                          name="country"
                          label={`${intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.COUNTRY.id,
                            defaultMessage: TRANSLATED_CONSTANTS.COUNTRY.defaultMessage
                          })}*`}
                          placeholder="United States"
                          register={register}
                          type="text"
                          errors={errors.country}
                        />
                      </div>
                      <Typo.textSm fontWeight="font-normal" className="w-full text-gray-800">
                        *
                        {intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
                          defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
                        })}
                      </Typo.textSm>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-x-3  pt-5">
              <Button onClick={onCancel} size="lg" theme="base">
                <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                  {intl.formatMessage({
                    id: isFirstStorefront
                      ? TRANSLATED_CONSTANTS.SKIP_FOR_NOW.id
                      : TRANSLATED_CONSTANTS.CANCEL.id,
                    defaultMessage: isFirstStorefront
                      ? TRANSLATED_CONSTANTS.SKIP_FOR_NOW.defaultMessage
                      : TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                  })}
                </Typo.textMd>
              </Button>
              <Button
                size="lg"
                loading={isSubmiting}
                type="submit"
                theme="primary-yellow"
                icon={<PlusIcon />}>
                <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.id,
                    defaultMessage: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.defaultMessage
                  })}
                </Typo.textMd>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CreateStorefront;
