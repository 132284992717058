import React, { FC } from 'react';
type TableTitleProps = {
  title: string;
  description: string;
};

const TableTitle: FC<TableTitleProps> = ({ title, description }) => {
  return (
    <div className="w-full flex flex-col gap-1 items-start justify-start font-inter px-6 ">
      <span className="text-xl text-gray-900 leading-loose font-medium">{title}</span>
      <span className="text-sm text-gray-500 leading-tight font-normal">{description}</span>
    </div>
  );
};

export default TableTitle;
