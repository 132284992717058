import { ReactElement } from 'react';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';
import { FormattedMessage } from 'react-intl';
import Badge from '../components/shared/Badge';
import { DropdownOption } from '../model/DropdownOption';
import { REPORT_PAGES } from '../constants/data';

export const handleReportPageInfo = (id: number | string, intl: any) => {
  const pageInfo: { [key: string]: any } = {
    1: {
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_FLAGGED_LISTING_TITLE.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_FLAGGED_LISTING_TITLE.defaultMessage
      }),
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_FLAGGED_LISTING_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_FLAGGED_LISTING_DESCRIPTION.defaultMessage
      })
    },
    2: {
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_LISTING_TITLE.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_LISTING_TITLE.defaultMessage
      }),
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_LISTING_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_LISTING_DESCRIPTION.defaultMessage
      })
    },
    3: {
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_DELETED_LISTING_TITLE.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_DELETED_LISTING_TITLE.defaultMessage
      }),
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_DELETED_LISTING_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_DELETED_LISTING_DESCRIPTION.defaultMessage
      })
    }
  };
  return pageInfo[id];
};
export const handleStorefrontReportPageInfo = (id: number | string, intl: any) => {
  const pageInfo: { [key: string]: any } = {
    1: {
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_FLAGGED_STOREFRONT_TITLE.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_FLAGGED_STOREFRONT_TITLE.defaultMessage
      }),
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_FLAGGED_STOREFRONT_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_FLAGGED_STOREFRONT_DESCRIPTION.defaultMessage
      })
    },
    2: {
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_STOREFRONT_TITLE.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_STOREFRONT_TITLE.defaultMessage
      }),
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_STOREFRONT_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_QUARANTINE_STOREFRONT_DESCRIPTION.defaultMessage
      })
    },
    3: {
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_DELETED_STOREFRONT_TITLE.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_DELETED_STOREFRONT_TITLE.defaultMessage
      }),
      description: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_DELETED_STOREFRONT_DESCRIPTION.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_DELETED_STOREFRONT_DESCRIPTION.defaultMessage
      })
    }
  };
  return pageInfo[id];
};
export const handleReportActivityInfo = (type: string, intl: any) => {
  const activityInfo: { [key: string]: any } = {
    'listing-flag': {
      title: intl.formatMessage({
        id: 'page.report.flagged_listing_activity_title',
        defaultMessage: 'See what users are reporting about this listing.'
      }),
      description: intl.formatMessage({
        id: 'page.report.flagged_listing_acitivity_description',
        defaultMessage: 'See what users are reporting about this listing.'
      })
    },
    'listing-deleted': {
      title: intl.formatMessage({
        id: 'page.report.deleted_listing_activity_title',
        defaultMessage: 'Deleted listings'
      }),
      description: intl.formatMessage({
        id: 'page.report.deleted_listing_activity_description',
        defaultMessage: 'See all deleted listings associated with this user here.'
      })
    },
    'message-report': {
      title: intl.formatMessage({
        id: 'page.report.message.title',
        defaultMessage: 'Reported messages'
      }),
      description: intl.formatMessage({
        id: 'page.report.reported_message_activity_description',
        defaultMessage: 'See what messages users are reporting about this user.'
      })
    },
    'listing-quarantine': {
      title: intl.formatMessage({
        id: 'page.report.flagged_listing_activity_title',
        defaultMessage: 'Flags issued'
      }),
      description: intl.formatMessage({
        id: 'page.report.flagged_listing_acitivity_description',
        defaultMessage: 'See what users are reporting about this listing.'
      })
    }
  };
  return activityInfo[type];
};

export const handleFlagsBadge = (flagsLength: number, intl: any) => {
  return (
    flagsLength +
    ' ' +
    intl.formatMessage({
      id: TRANSLATED_CONSTANTS.FLAGS.id,
      defaultMessage: TRANSLATED_CONSTANTS.FLAGS.defaultMessage
    })
  );
};
export const handleWarningsBadge = (warningsLength: number, intl: any) => {
  return (
    warningsLength +
    ' ' +
    intl.formatMessage({
      id: TRANSLATED_CONSTANTS.WARNINGS.id,
      defaultMessage: TRANSLATED_CONSTANTS.WARNINGS.defaultMessage
    })
  );
};

export const handleListingDetailConstants = (
  accountType: { theme: any; name: any },
  type: string,
  issuerRole?: { theme: any; name: any }
) => {
  console.log(type, 'tttt');
  console.log(accountType, 'aaaaaaaaaa');
  const issuedListing: {
    [key: string]: {
      title: ReactElement;
      subtitle: ReactElement;
      reporterTitle: ReactElement;
      reporterSubTitle: ReactElement;
      reporterBadge: ReactElement;
    };
  } = {
    'listing-flag': {
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.REPORTED_LISTING.id}
          defaultMessage={TRANSLATED_CONSTANTS.REPORTED_LISTING.defaultMessage}
        />
      ),
      subtitle: (
        <FormattedMessage
          id="page.report.detailed_reported_listing_subtitle"
          defaultMessage="This is a view of the reported listing."
        />
      ),
      reporterTitle: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.FLAGE_ISSUED_BY.id}
          defaultMessage={TRANSLATED_CONSTANTS.FLAGE_ISSUED_BY.defaultMessage}
        />
      ),
      reporterSubTitle: (
        <FormattedMessage
          id="page.report.detailed_report_flag_issued_by_subtitle"
          defaultMessage="You can view the profile that flagged the listing."
        />
      ),
      reporterBadge: (
        <Badge hasDot={false} theme={accountType.theme} label={accountType.name}></Badge>
      )
    },
    [REPORT_PAGES.LISTING_QUARANTINE]: {
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.QUARANTINED_LISTING.id}
          defaultMessage={TRANSLATED_CONSTANTS.QUARANTINED_LISTING.defaultMessage}
        />
      ),
      subtitle: (
        <FormattedMessage
          id="page.report.detailed_quarantined_listing_subtitle"
          defaultMessage="This is a view of the quarantined listing."
        />
      ),
      reporterTitle: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.QUARANTINE_ISSUED_BY.id}
          defaultMessage={TRANSLATED_CONSTANTS.QUARANTINE_ISSUED_BY.defaultMessage}
        />
      ),
      reporterSubTitle: (
        <FormattedMessage
          id="page.report.detailed_report_quarantine_issued_by_subtitle"
          defaultMessage="You can view the profile of the user that removed this listing here."
        />
      ),
      reporterBadge: (
        <Badge
          theme="gray"
          label={
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.MODERATOR.id}
              defaultMessage={TRANSLATED_CONSTANTS.MODERATOR.defaultMessage}
            />
          }></Badge>
      )
    },
    [REPORT_PAGES.LISTING_DELETED_WARNED]: {
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.REPORTED_LISTING.id}
          defaultMessage={TRANSLATED_CONSTANTS.REPORTED_LISTING.defaultMessage}
        />
      ),
      subtitle: (
        <FormattedMessage
          id="page.report.detailed_reported_listing_subtitle"
          defaultMessage="This is a view of the reported listing."
        />
      ),
      reporterTitle: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.WARNING_DELETION_ISSUED_BY.id}
          defaultMessage={TRANSLATED_CONSTANTS.WARNING_DELETION_ISSUED_BY.defaultMessage}
        />
      ),
      reporterSubTitle: (
        <FormattedMessage
          id="page.report.detailed_warning_deletion_listing_subtitle"
          defaultMessage="You can view the profile of the user that removed this listing here."
        />
      ),
      reporterBadge: <Badge theme={issuerRole?.theme} label={issuerRole?.name}></Badge>
    },
    [REPORT_PAGES.LISTING_DELETED_BANED]: {
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.REPORTED_LISTING.id}
          defaultMessage={TRANSLATED_CONSTANTS.REPORTED_LISTING.defaultMessage}
        />
      ),
      subtitle: (
        <FormattedMessage
          id="page.report.detailed_reported_listing_subtitle"
          defaultMessage="This is a view of the reported listing."
        />
      ),
      reporterTitle: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.BAN_DELETION_ISSUED_BY.id}
          defaultMessage={TRANSLATED_CONSTANTS.BAN_DELETION_ISSUED_BY.defaultMessage}
        />
      ),
      reporterSubTitle: (
        <FormattedMessage
          id="page.report.detailed_warning_deletion_listing_subtitle"
          defaultMessage="You can view the profile of the user that removed this listing here."
        />
      ),
      reporterBadge: <Badge theme={issuerRole?.theme} label={issuerRole?.name}></Badge>
    }
  };
  return issuedListing[type];
};

export const handleEmailRadioButtonsConstants = (intl: any) => {
  const radioButtons = [
    {
      id: 'all-users',
      name: 'radio',
      // checked: true,
      labelTitle: intl.formatMessage({
        id: 'page.report.email.radio_button.all_users',
        defaultMessage: 'All users'
      }),
      labelSubtitle: intl.formatMessage({
        id: 'page.report.email.radio_button.all_users_subtitle',
        defaultMessage: 'Send an email to all users.'
      })
    },
    {
      id: 'moderator',
      name: 'radio',
      labelTitle: intl.formatMessage({
        id: 'page.report.email.radio_button.moderators',
        defaultMessage: 'Moderators only'
      }),
      labelSubtitle: intl.formatMessage({
        id: 'page.report.email.radio_button.moderators_subtitle',
        defaultMessage: 'Send an email to moderators only.'
      })
    },
    {
      id: 'team-members',
      name: 'radio',
      labelTitle: intl.formatMessage({
        id: 'page.report.email.radio_button.team_members',
        defaultMessage: 'All team members'
      }),
      labelSubtitle: intl.formatMessage({
        id: 'page.report.email.radio_button.team_memebers_subtitle',
        defaultMessage: 'Send an email to the team.'
      })
    }
  ];
  return radioButtons;
};

export const handleReportDropdownContent = (intl: any, type: string) => {
  const listingReportDropdown: DropdownOption[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_LISTING_FIRST_REASON.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_FIRST_REASON.defaultMessage
      })
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_LISTING_SECOND_REASON.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_SECOND_REASON.defaultMessage
      })
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.OTHER.id,
        defaultMessage: TRANSLATED_CONSTANTS.OTHER.defaultMessage
      })
    }
  ];
  const messageReportDropdown: DropdownOption[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_MESSAGE_FIRST_REASON.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_MESSAGE_FIRST_REASON.defaultMessage
      })
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_MESSAGE_SECOND_REASON.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_MESSAGE_SECOND_REASON.defaultMessage
      })
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.REPORT_MESSAGE_THIRD_REASON.id,
        defaultMessage: TRANSLATED_CONSTANTS.REPORT_MESSAGE_THIRD_REASON.defaultMessage
      })
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.OTHER.id,
        defaultMessage: TRANSLATED_CONSTANTS.OTHER.defaultMessage
      })
    }
  ];

  if (type === 'listing') return listingReportDropdown;
  if (type === 'message') return messageReportDropdown;
};

export const findNumberOfFlags = (listings: any) => {
  const flags = listings?.reduce((total: number, listing: any) => {
    // Use optional chaining and nullish coalescing operators
    return total + (listing?.listing_reports?.length ?? 0);
  }, 0);
  return flags;
};
export const findNumberOfWarnings = (listings: any) => {
  const warnings = listings?.reduce((total: number, listing: any) => {
    return total + (listing?.issued_listing ? 1 : 0);
  }, 0);
  return warnings;
};
