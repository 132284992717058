import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Button from './Button';
import FilterIcon from '../icons/FilterIcon';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { Typo } from '../Typo';
import { DropdownOption } from '../../model/DropdownOption';
import InputDropdown from './location Dropdown/InputDropdown';

export type MoreFilterProps = {
  type?: 'map' | 'modarator';
  label?: string;
  storefronts?: Array<DropdownOption>;
  selectedStorefront?: DropdownOption;
  onFilter?: (selectedStorefront: DropdownOption) => void;
  onClear?: () => void;
  resetState?: boolean;
  className?: string;
  btnClassName?: string;
};
const StorefrontMoreFilter: React.FC<MoreFilterProps> = (props) => {
  const {
    onFilter,
    onClear,
    label = '',
    storefronts = [],
    className,
    btnClassName,
    selectedStorefront
  } = props;
  const [selectedOption, setSelectedOption] = useState<DropdownOption>();

  const intl = useIntl();

  useEffect(() => {
    if (selectedStorefront) {
      setSelectedOption(selectedStorefront);
    }
  }, [selectedStorefront]);
  return (
    <Menu as="div" className={`relative inline-block text-left ${className}`}>
      {({ open, close }: any) => (
        <>
          <div>
            <Menu.Button className={`flex items-center group w-full ${btnClassName}`}>
              <Button
                size="sm"
                theme="base"
                className={clsx({ 'ring-4 ring-gray-100 ': open }, 'w-full')}>
                <div className=" flex items-center justify-center gap-x-2 py-0.5">
                  <FilterIcon></FilterIcon>{' '}
                  <span>
                    {label
                      ? label
                      : `${intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.MORE_FILTERS.id,
                          defaultMessage: TRANSLATED_CONSTANTS.MORE_FILTERS.defaultMessage
                        })}`}
                  </span>
                </div>
              </Button>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 z-10 mt-2 w-[343px] max-h-screen overflow-y-auto md:w-[400px] origin-top-right rounded-lg border border-gray-200 bg-white shadow-xl focus:outline-none">
              <div className=" w-full divide-y">
                <div className="w-full p-6">
                  <Typo.textXl fontWeight="font-medium" className=" text-gray-900">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.STOREFRONT_FILTERS.id}
                      defaultMessage={TRANSLATED_CONSTANTS.STOREFRONT_FILTERS.defaultMessage}
                    />
                  </Typo.textXl>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.FILTER_YOUR_SEARCH_BY_STOREFRONT.id}
                      defaultMessage={
                        TRANSLATED_CONSTANTS.FILTER_YOUR_SEARCH_BY_STOREFRONT.defaultMessage
                      }
                    />
                  </Typo.textSm>
                </div>
                <div className="p-6">
                  <InputDropdown
                    onChange={setSelectedOption}
                    defaultValue={selectedOption}
                    label="Storefronts"
                    options={storefronts}></InputDropdown>
                </div>
                <div className="p-6 flex gap-x-3 justify-end">
                  <Button
                    onClick={() => {
                      close();
                      //   onClear && onClear();
                    }}
                    theme="base"
                    size="md">
                    <Typo.textSm fontWeight="font-medium">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.CLEAR.id}
                        defaultMessage={TRANSLATED_CONSTANTS.CLEAR.defaultMessage}
                      />
                    </Typo.textSm>
                  </Button>
                  <Button
                    onClick={() => {
                      close();
                      onFilter && selectedOption && onFilter(selectedOption);
                    }}
                    theme="primary-yellow"
                    size="md">
                    <Typo.textSm fontWeight="font-medium">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.APPLY.id}
                        defaultMessage={TRANSLATED_CONSTANTS.APPLY.defaultMessage}
                      />
                    </Typo.textSm>
                  </Button>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default StorefrontMoreFilter;
