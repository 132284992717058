import React from 'react';
import { DropdownOption } from '../../model/DropdownOption';
import InputDropdown from './location Dropdown/InputDropdown';
import Button from './Button';
import clsx from 'clsx';
import { Typo } from '../Typo';
type TabProps = {
  currentTab?: DropdownOption;
  tabs?: DropdownOption[];
  label?: string;
  type?: 'button' | 'underline';
  onTabChange?: (tab: DropdownOption) => void;
};
const Tab: React.FC<TabProps> = (props) => {
  const { currentTab, type = 'button', tabs = [], onTabChange } = props;
  return (
    <div>
      <div className="sm:hidden flex relative justify-center mx-auto ">
        <InputDropdown
          onChange={onTabChange}
          placeholder="Select a tab"
          options={tabs}
          defaultValue={currentTab}></InputDropdown>
      </div>
      <div className="hidden sm:block">
        <div
          className={`${
            type == 'underline'
              ? 'border-b border-gray-200'
              : ' bg-gray-50 border border-gray-100 p-[6px] rounded-lg'
          }`}>
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs?.map((tab) => (
              <Button
                onClick={() => onTabChange && onTabChange(tab)}
                size="md"
                theme="gray-link"
                key={tab.title}>
                <div
                  className={clsx(
                    'flex items-center gap-x-2',
                    type == 'underline'
                      ? clsx(
                          'flex whitespace-nowrap group pb-3 rounded-none text-gray-500 px-1 border-b-2 border-b-transparent disabled:bg-opacity-50',
                          tab.id == currentTab?.id
                            ? ' border-b-yellow-700 text-yellow-700 hover:border-b-yellow-700 hover:text-yellow-700 focus:border-b-yellow-700 focus:text-yellow-700'
                            : ' hover:border-b-yellow-700 hover:text-yellow-700 focus:text-gray-500'
                        )
                      : clsx(
                          ' text-gray-500 hover:text-gray-700 px-[14px] py-[10px] focus:text-gray-500 ',
                          tab.id === currentTab?.id
                            ? ' shadow-sm bg-white rounded-md text-gray-700 hover:text-gray-700 focus:text-gray-700 '
                            : 'shadow-none'
                        )
                  )}>
                  <Typo.textMd fontWeight={type === 'button' ? 'font-medium' : 'font-semibold'}>
                    {tab.title}
                  </Typo.textMd>
                  {tab?.supportingTitle && (
                    <div
                      className={clsx(
                        'py-0.5 px-3.5 rounded-2xl ',
                        tab.id === currentTab?.id
                          ? ' bg-yellow-50 text-yellow-700'
                          : 'bg-gray-100 text-gray-700 group-hover:bg-yellow-50 group-hover:text-yellow-700 group-focus:bg-gray-100 group-focus:text-gray-700 '
                      )}>
                      <Typo.textSm fontWeight="font-normal">{tab.supportingTitle}</Typo.textSm>
                    </div>
                  )}
                </div>
              </Button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tab;
