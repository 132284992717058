import { useUpdateUser } from '../user/useUpdateUser';

export const useHandleUserDecisions = () => {
  const updateUser = useUpdateUser();
  const changeDecisions = async (userId: number, decisions: number) => {
    await updateUser({
      variables: {
        data: {
          decisions: decisions
        },
        id: userId
      }
    });
  };
  return { changeDecisions };
};
