import { useState, useEffect } from 'react';

const useDeviceDetect = () => {
  const checkForDeviceType = () => {
    const screenWidth = window.innerWidth;

    if (screenWidth < 768) {
      return 'mobile';
    } else if (screenWidth >= 768 && screenWidth < 1024) {
      return 'tablet';
    } else {
      return 'desktop';
    }
  };

  const [deviceType, setDeviceType] = useState(checkForDeviceType());

  useEffect(() => {
    const handlePageResized = () => {
      setDeviceType(checkForDeviceType());
    };

    window.addEventListener('resize', handlePageResized);
    window.addEventListener('load', handlePageResized);
    window.addEventListener('reload', handlePageResized);

    return () => {
      window.removeEventListener('resize', handlePageResized);
      window.removeEventListener('load', handlePageResized);
      window.removeEventListener('reload', handlePageResized);
    };
  }, []);

  return {
    isMobile: deviceType === 'mobile',
    isTablet: deviceType === 'tablet',
    isDesktop: deviceType === 'desktop'
  };
};

export default useDeviceDetect;
