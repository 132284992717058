// BreadcrumbContext.tsx
import React, { createContext, useContext, useState } from 'react';
import { BreadcrumbPropsType } from '../model/Breadcrumb';

type Props = {
  children: React.ReactElement;
};

interface BreadcrumbContextType {
  breadcrumbs: BreadcrumbPropsType[];
  addBreadcrumb: (breadcrumb: BreadcrumbPropsType) => void;
  setBreadcrumbs: (breadcrumbs: BreadcrumbPropsType[]) => void;
  removeBreadcrumbItems: (breadcrumbs: BreadcrumbPropsType) => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider: React.FC<Props> = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbPropsType[]>([]);

  const removeBreadcrumbItems = (clickedBreadCrumb: BreadcrumbPropsType) => {
    if (clickedBreadCrumb.index === breadcrumbs[breadcrumbs.length - 1].index) {
      setBreadcrumbs(breadcrumbs);
    } else if (clickedBreadCrumb.index === breadcrumbs[0].index) {
      setBreadcrumbs([breadcrumbs[0]]);
    } else {
      const updatedBreadcrumb = breadcrumbs.slice(0, clickedBreadCrumb.index + 1);
      setBreadcrumbs(updatedBreadcrumb);
    }
  };

  const addBreadcrumb = (newBreadcrumb: BreadcrumbPropsType) => {
    setBreadcrumbs((prevBreadcrumbs: BreadcrumbPropsType[]) => {
      if (prevBreadcrumbs[prevBreadcrumbs.length - 1]?.name === newBreadcrumb?.name) {
        return prevBreadcrumbs;
      } else {
        const test = [...prevBreadcrumbs, newBreadcrumb];
        return test;
      }
    });
  };

  return (
    <BreadcrumbContext.Provider
      value={{ breadcrumbs, addBreadcrumb, setBreadcrumbs, removeBreadcrumbItems }}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumb must be used within a BreadcrumbProvider');
  }
  return context;
};
