import { FC } from 'react';
import SearchIcon from '../icons/SearchIcon';
import { Typo } from '../Typo';
import Button from '../shared/Button';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

type Props = {
  name: string;
  onSearch: (value: string) => void;
};
const NoUsersFound: FC<Props> = ({ name, onSearch }) => {
  return (
    <div className="flex justify-center w-full">
      <div className=" px-6 pt-6 pb-10 flex flex-col items-center w-full gap-y-6 justify-center">
        <div className=" space-y-1">
          <Typo.textMd fontWeight="font-medium" className=" text-gray-900 w-full text-center">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.NO_USERS_FOUND.id}
              defaultMessage={TRANSLATED_CONSTANTS.NO_USERS_FOUND.defaultMessage}
            />
          </Typo.textMd>
          <Typo.textSm
            fontWeight="font-normal"
            className="text-gray-600 max-w-[352px] w-full text-center">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.DID_NOT_MATCH_ANY_USER.id}
              defaultMessage={TRANSLATED_CONSTANTS.DID_NOT_MATCH_ANY_USER.defaultMessage}
              values={{ name: name }}
            />
          </Typo.textSm>
        </div>
        <div className="flex justify-center gap-x-3">
          <Button onClick={() => onSearch('')} theme="base" className="">
            <Typo.textMd fontWeight="font-semibold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.CANCEL.id}
                defaultMessage={TRANSLATED_CONSTANTS.CANCEL.defaultMessage}
              />
            </Typo.textMd>
          </Button>
          <Button
            onClick={() => onSearch(name)}
            icon={<SearchIcon className="h-5 w-5 text-gray-900" />}
            theme="primary-yellow">
            <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.SEARCH_USER.id}
                defaultMessage={TRANSLATED_CONSTANTS.SEARCH_USER.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        </div>
      </div>
    </div>
  );
};
export default NoUsersFound;
