type Props = {
  className?: string;
};
const Star6Icon = ({ className }: Props) => {
  return (
    <>
      <svg
        className={`h-5 w-5 ${className} `}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_939_154670)">
          <path
            d="M3.75033 18.3332V14.1665M3.75033 5.83317V1.6665M1.66699 3.74984H5.83366M1.66699 16.2498H5.83366M10.8337 2.49984L9.38851 6.25722C9.1535 6.86825 9.036 7.17376 8.85327 7.43074C8.69132 7.6585 8.49232 7.8575 8.26456 8.01945C8.00758 8.20218 7.70207 8.31968 7.09104 8.55469L3.33366 9.99984L7.09104 11.445C7.70207 11.68 8.00758 11.7975 8.26456 11.9802C8.49232 12.1422 8.69132 12.3412 8.85327 12.5689C9.036 12.8259 9.1535 13.1314 9.38851 13.7425L10.8337 17.4998L12.2788 13.7425C12.5138 13.1314 12.6313 12.8259 12.8141 12.5689C12.976 12.3412 13.175 12.1422 13.4028 11.9802C13.6597 11.7975 13.9652 11.68 14.5763 11.445L18.3337 9.99984L14.5763 8.55469C13.9652 8.31968 13.6597 8.20217 13.4028 8.01945C13.175 7.8575 12.976 7.6585 12.8141 7.43074C12.6313 7.17376 12.5138 6.86825 12.2788 6.25722L10.8337 2.49984Z"
            stroke="currentColor"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_939_154670">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default Star6Icon;
