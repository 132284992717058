import { ChevronRightIcon } from '@heroicons/react/24/solid';
import HomeIcon from '../icons/HomeIcon';
import clsx from 'clsx';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { FC, useEffect } from 'react';
import { BreadcrumbPropsType } from '../../model/Breadcrumb';
import Button from './Button';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useBreadcrumb } from '../../context/Breadcrumb';
import { BREADCRUMB_ROUTES } from '../../constants/data';

const Breadcrumb: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { addBreadcrumb, breadcrumbs, removeBreadcrumbItems } = useBreadcrumb();
  // use the useEffect hook to update the breadcrumbs when the location changes
  useEffect(() => {
    BREADCRUMB_ROUTES.map((route) => {
      const match = matchPath(
        // pass the pattern as the first argument
        {
          path: route.pathName,
          caseSensitive: true,
          end: true
        },
        // pass the pathname as the second argument
        location.pathname
      );
      if (match && route?.name) {
        const breadcrumbItem = {
          link: location.pathname,
          name: route.name,
          index: breadcrumbs.length
        };
        // call the addBreadcrumb function with the new item
        addBreadcrumb(breadcrumbItem);
      }
    });
    // use location.pathname as a dependency
  }, [location.pathname]);

  const breadNavigate = (clickedBreadcrumb: BreadcrumbPropsType) => {
    navigate(clickedBreadcrumb.link);
    removeBreadcrumbItems(clickedBreadcrumb);
  };

  return (
    <div className="flex justify-start w-full">
      <nav className="hidden md:block h-7 py-4 " aria-label="Breadcrumb ">
        <ol role="list" className="flex items-center gap-2">
          <li className="h-full flex items-center">
            <Link to="/">
              <HomeIcon className="h-5 w-7 flex-shrink-0 text-gray-500" aria-hidden="true" />
            </Link>
          </li>
          {breadcrumbs?.length > 3 ? (
            <>
              <BreadcrumbItem page={breadcrumbs[0]} index={1} breadNavigate={breadNavigate} />
              <ChevronRightIcon className="h-4 w-4 text-gray-300" aria-hidden="true" />
              <li className="text-gray-600">...</li>
              <BreadcrumbItem
                page={breadcrumbs[breadcrumbs.length - 2]}
                index={breadcrumbs.length - 2}
                breadNavigate={breadNavigate}
              />
              <BreadcrumbItem
                page={breadcrumbs[breadcrumbs.length - 1]}
                index={breadcrumbs.length - 1}
                breadNavigate={breadNavigate}
              />
            </>
          ) : (
            breadcrumbs?.map(
              (page, index) =>
                page &&
                page?.link !== '/' && (
                  <BreadcrumbItem
                    key={index}
                    page={page}
                    breadNavigate={breadNavigate}
                    index={index}
                  />
                )
            )
          )}
        </ol>
      </nav>
      <div className="block md:hidden ">
        <Button onClick={() => navigate(-1)} className=" " theme="gray-link">
          <div className="flex justify-center  gap-x-2 ">
            <ArrowLeftIcon className=" w-5 h-5 " />
            <span className="text-sm font-medium font-inter text-gray-600">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.BACK.id}
                defaultMessage={TRANSLATED_CONSTANTS.BACK.defaultMessage}
              />
            </span>
          </div>
        </Button>
      </div>
    </div>
  );
};

const BreadcrumbItem = (props: {
  page: BreadcrumbPropsType;
  breadNavigate: (data: BreadcrumbPropsType) => void;
  index: number;
}) => {
  const { page, breadNavigate, index } = props;
  page.index = index;
  const pageName = getRouteInfo(page?.link);
  return (
    <li>
      <div className="flex items-center">
        <ChevronRightIcon className="h-4 w-4 text-gray-300 mr-2" aria-hidden="true" />
        <div
          className={clsx(
            'px-2 py-1 rounded-md justify-center items-center flex ',
            page.current ? ' bg-gray-50' : ''
          )}>
          <div
            onClick={() => breadNavigate(page)}
            className={clsx(
              ' text-[14px]  leading-tight capitalize cursor-pointer',
              page.current ? 'font-semibold text-gray-700' : 'font-medium text-gray-600'
            )}
            aria-current={page.current ? 'page' : undefined}>
            {page?.name ? page.name : pageName}
          </div>
        </div>
      </div>
    </li>
  );
};

// define the function
function getRouteInfo(pathname: string) {
  // loop through the BREADCRUMB_ROUTES array
  for (const routeItem of BREADCRUMB_ROUTES) {
    // compare each route pathName with the pathname using the matchPath function
    const match = matchPath(
      // pass the pattern as the first argument
      {
        path: routeItem.pathName,
        caseSensitive: true,
        end: true
      },
      // pass the pathname as the second argument
      pathname
    );

    // if match is not null, then the route matches the pathname
    if (match) {
      // return an object with the name and the slug from the route and the match object respectively
      return routeItem.name;
    }
  }
  // if no match is found, return null
  return null;
}

export default Breadcrumb;
