/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { NUMBER_OF_RECORDS_PER_TABLE } from '../../../constants/data';
import { useGetReportedMessages } from '../../../hooks/reports/useGetReportedMessages';
import { MessageReport } from '../../../model/Report';
import RedActionModal from '../../../components/modal/RedActionModal';
import Divider from '../../../components/shared/Divider';
import Pagination from '../../../components/shared/Pagination';
import EmptyReportListings from '../EmptyReportListings';
import Loader from '../../../components/shared/Loader';
import Table from '../../../components/shared/table/Table';
import TableHead from './TableHead';
import { handleAccountTypeBadge } from '../../../util/ListingBadgeHandler';
import CheckboxField from '../../../components/shared/CheckboxField';
import UserInfo from '../../../components/shared/table/UserInfo';
import { Typo } from '../../../components/Typo';
import Badge from '../../../components/shared/Badge';
import { formatDate } from '../../../util/AppUtil';
import Button from '../../../components/shared/Button';
import TrashIcon from '../../../components/icons/TrashIcon';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useHandleMessageReport } from '../../../hooks/useHandleMessageReport';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { addReportLayout } from '../../../lib/redux/reducers/report.slice';
import MessageIcon from '../../../components/icons/MessageIcon';

const MessageReportGridLayout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [checked, setChecked] = useState(false);
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const { loading, error, messageReports, messageReportsCount, refetch, fetchMore } =
    useGetReportedMessages(offset, NUMBER_OF_RECORDS_PER_TABLE, searchInput);

  const {
    showDeleteModal,
    selectedReports,
    onDeleteReportButtonClicked,
    onCloseDeleteModal,
    setSelectedReports
  } = useHandleMessageReport(refetch);

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedReports(messageReports);
    } else {
      setSelectedReports([]);
    }
  };

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };
  const showDetailedRecord = (index: number) => {
    dispatch(addReportLayout({ reportLayout: { type: 'detailed', index: index } }));
  };

  return (
    <div className="w-full">
      {messageReports?.length === 0 && !loading ? (
        // <EmptyReportListings />
        <EmptyReportListings
          title={TRANSLATED_CONSTANTS.NO_MESSAGES_YET}
          subtitle={TRANSLATED_CONSTANTS.NO_MESSAGES_DESCRIPTION}
          icon={<MessageIcon className="w-6 h-6 md:h-7 md:w-7 " />}
        />
      ) : (
        <div className="w-full">
          {loading ? (
            <Loader className="h-[400px] w-full flex justify-center items-center" />
          ) : (
            <div className="min-w-full w-full overflow-x-auto  ">
              <Table>
                <TableHead checked={checked} toggleAll={toggleAll} />
                <tbody className="divide-y divide-gray-200 bg-white">
                  {messageReports?.map((report: MessageReport, index: number) => {
                    const accountType = handleAccountTypeBadge(report?.type || '', intl);
                    const reportExistsInSelectedReports = selectedReports.some(
                      (eachObj: any) => eachObj.id === report.id
                    );
                    const reportContent =
                      report?.message_reported_user && report?.message_reported_user[0];
                    return (
                      <tr
                        key={report.id}
                        onClick={() => showDetailedRecord(index)}
                        className={clsx(
                          reportExistsInSelectedReports ? 'bg-gray-50' : undefined,
                          'cursor-pointer hover:bg-gray-50'
                        )}>
                        <td className="relative  sm:w-12 sm:px-6 py-3">
                          <div className="absolute left-6 top-1/2 -mt-3">
                            <CheckboxField
                              name="state"
                              value="check"
                              label=""
                              size="md"
                              type="checkbox"
                              roundedSize="rounded-md"
                              isSelected={reportExistsInSelectedReports}
                              onChange={(e) => {
                                e.stopPropagation();
                                setSelectedReports(
                                  e.target.checked
                                    ? [...selectedReports, report]
                                    : selectedReports.filter((p: any) => p.id !== report.id)
                                );
                              }}
                              register={() => {}}
                            />
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 pr-3 ">
                          <UserInfo profileImage={report?.profileImage?.url || ''}>
                            <div className="">
                              <Typo.textSm
                                fontWeight="font-medium"
                                className="text-gray-900 capitalize">
                                {report?.name || ''}
                              </Typo.textSm>

                              <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                @{report?.username || ''}
                              </Typo.textSm>
                            </div>
                          </UserInfo>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <Badge
                            hasDot={true}
                            theme={accountType.theme}
                            label={accountType.name}></Badge>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <Typo.textSm
                            fontWeight="font-medium"
                            className="text-gray-600 capitalize">
                            {formatDate(reportContent?.date.toString())}
                          </Typo.textSm>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <Typo.textSm
                            fontWeight="font-medium"
                            className="text-gray-600 capitalize">
                            {reportContent && reportContent?.claim}
                          </Typo.textSm>
                          <Typo.textXs
                            fontWeight="font-medium"
                            className="text-gray-600 capitalize">
                            {reportContent?.detail}
                          </Typo.textXs>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 ">
                          <Button
                            onClick={(event: { stopPropagation: () => void }) => {
                              event.stopPropagation();
                              onDeleteReportButtonClicked(report);
                            }}
                            size="md"
                            theme="gray-link"
                            icon={<TrashIcon className="w-5 h-5 text-gray-600" />}></Button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      )}
      <Divider />
      <div className="px-6">
        <Pagination
          currentPage={currentPage}
          totalCount={messageReportsCount}
          pageSize={NUMBER_OF_RECORDS_PER_TABLE}
          onPageChange={paginate}
          type="centered-page-number"
        />
      </div>

      <RedActionModal
        title={TRANSLATED_CONSTANTS.DELETE_MESSAGE_REPORT_MODAL_TITLE}
        isOpen={showDeleteModal}
        onCloseRedActionModal={(value: boolean) => onCloseDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DELETE_MESSAGE_REPORT_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default MessageReportGridLayout;
