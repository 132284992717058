import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useAppSelector } from '../../../lib/redux/hooks';
import { ActivityItemPropsType } from '../../../model/Activity';
import { useGetDetailedReportedMessages } from '../../../hooks/reports/useGetDetailedReportedMessages';
import { MessageReportDetails } from '../../../model/Report';
import EmptyReportListings from '../EmptyReportListings';
import Loader from '../../../components/shared/Loader';
import { REPORT_PAGES } from '../../../constants/data';
import ListingDetailedProfileHeader from '../listing/deletedAndQuarantine/ListingDetailedProfileHeader';
import { Typo } from '../../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import Button from '../../../components/shared/Button';
import Divider from '../../../components/shared/Divider';
import MessageCard from './MessageCard';
import ActivityList from '../../../components/activities/ActivityList';
import Pagination from '../../../components/shared/Pagination';
import {
  findNumberOfFlags,
  findNumberOfWarnings,
  handleReportActivityInfo
} from '../../../util/ReportUtil';
import { useHandleMessageReport } from '../../../hooks/useHandleMessageReport';
import RedActionModal from '../../../components/modal/RedActionModal';
import TrashIcon from '../../../components/icons/TrashIcon';
import { useHandleListingFlaggedReports } from '../../../hooks/useHandleListingFlaggedReports';
import UserXIcon from '../../../components/icons/UserXIcon';
import { useUpdateMessageReport } from '../../../hooks/reports/useUpdateMessageReport';
import Reason from '../detailedLayoutComponents/Reason';
import Reporter from '../detailedLayoutComponents/Reporter';
import Contact from '../detailedLayoutComponents/Contact';

const MessageReportDetailedLayout = () => {
  const intl = useIntl();
  const selectedGridRow = useAppSelector((state) => state.report.reportLayout);
  const updateMessageReport = useUpdateMessageReport();
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const clickedActivityItem = useAppSelector((state) => state.report.clickedActivityData);
  const [currentPage, setCurrentPage] = useState(1);
  const [isReportFetched, setIsReportFetched] = useState(false);
  const [activityData, setActivityData] = useState<ActivityItemPropsType[]>([]);
  const [activityItemData, setActivityItemData] = useState<ActivityItemPropsType | any>();
  const [offset, setOffset] = useState(0);
  const { loading, error, messageReports, messageReportsCount, refetch, fetchMore } =
    useGetDetailedReportedMessages(offset, 1, searchInput);

  const { showDeleteModal, onDeleteReportButtonClicked, onCloseDeleteModal } =
    useHandleMessageReport(refetch);
  const { showBanModal, onBanUserButtonClicked, onCloseBanModal } =
    useHandleListingFlaggedReports(refetch);

  const fetchMoreData = (pageNumber: number) => {
    const startIndex = pageNumber - 1;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: 1 }
    });
    setIsReportFetched(false);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    setOffset(selectedGridRow.index);
    setCurrentPage(selectedGridRow.index + 1);
  }, [selectedGridRow]);

  useEffect(() => {
    if (messageReports && !isReportFetched) {
      setIsReportFetched(true);
      const activityData = messageReports[0]?.message_reported_user?.map(
        (report: MessageReportDetails) => ({
          name: (report.reporter && report?.reporter.name) || '',
          username: (report.reporter && report?.reporter.username) || '',
          time: report?.date,
          detail: report?.detail || '',
          claim: report?.claim,
          opened: report?.opened,
          type: 'report_message',
          userId: report?.reporter && report?.reporter?.id,
          userImageUrl: report?.reporter?.profileImage?.url,
          link: '',
          accountType: report?.reporter && report?.reporter?.type,
          email: report?.reporter && report?.reporter?.email,
          message: report?.message?.content,
          messageTime: report?.message?.createdAt.toString(),
          id: report?.id
        })
      );
      setActivityData(activityData);
      activityData?.lenght > 0 ? setActivityItemData(activityData[0]) : '';
    }
  }, [messageReports]);

  useEffect(() => {
    // const update = async () => {
    //   if (activityItemData && activityItemData?.opened === false) {
    //     const response = await updateMessageReport({
    //       variables: {
    //         id: Number(activityItemData.id),
    //         data: { opened: true }
    //       }
    //     });
    //     if (response) {
    //       const activityItems = activityData;
    //       activityItems.map((activity) => {
    //         if (activityItemData.id === activity.id) {
    //           activity.opened = true;
    //         }
    //       });
    //       setActivityData(activityItems);
    //     }
    //   }
    // };
    // update();
    setActivityItemData(clickedActivityItem);
  }, [clickedActivityItem]);

  const report = messageReports && messageReports[0];

  const activityHeader = handleReportActivityInfo(REPORT_PAGES.MESSAGE_REPORT, intl);

  return (
    <div>
      {messageReports?.length === 0 && !loading ? (
        <EmptyReportListings />
      ) : (
        <div>
          {loading ? (
            <Loader className="h-screen" />
          ) : (
            <div>
              <div className="w-full flex flex-col lg:flex-row lg:gap-16">
                <div className="w-full flex gap-8 flex-col">
                  <ListingDetailedProfileHeader
                    pageType="message-report"
                    user={{
                      name: report?.name,
                      username: report?.username,
                      id: report?.id,
                      createdAt: report?.createdAt,
                      type: report?.type,
                      profileImage: report?.profileImage
                    }}
                    flags={findNumberOfFlags(report?.flags)}
                    warnings={findNumberOfWarnings(report?.warnings)}
                    openBanModal={() => onBanUserButtonClicked(report.user)}
                  />
                  <div className="md:px-4 lg:pl-4 lg:pr-0 pb-8">
                    <Contact email={report?.email || ''} />
                    <div className="my-8">
                      <Divider />
                    </div>
                    {report && (
                      <MessageCard
                        profileImage={report?.profileImage?.url}
                        name={report?.name}
                        username={report?.username}
                        userId={report?.id}
                        message={activityItemData?.message || ''}
                        messageTime={activityItemData?.messageTime || ''}
                      />
                    )}
                    <div className="my-8">
                      <Divider />
                    </div>
                    <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
                      <div className="w-[280px]">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                          <FormattedMessage
                            id="page.report.detailed_reported_message_issure_title"
                            defaultMessage="Report issued by"
                          />
                        </Typo.textSm>
                        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                          <FormattedMessage
                            id="page.report.detailed_reported_message_issuer_subtitle"
                            defaultMessage="You can view the profile that reported this message."
                          />
                        </Typo.textSm>
                      </div>
                      <Reporter activityItemData={activityItemData} />
                    </div>
                    <div className="my-8">
                      <Divider />
                    </div>
                    <Reason
                      claim={activityItemData?.claim || ''}
                      detail={activityItemData?.detail || ''}
                    />
                    <div className="mt-8 mb-5">
                      <Divider />
                    </div>

                    <div className="flex flex-col md:flex-row w-full md:justify-between gap-3 ">
                      <Button
                        onClick={() => {
                          onDeleteReportButtonClicked(report);
                        }}
                        size="md"
                        theme="gray-link">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.CLEAR_FLAGGED_MESSAGES.id}
                            defaultMessage={
                              TRANSLATED_CONSTANTS.CLEAR_FLAGGED_MESSAGES.defaultMessage
                            }
                          />
                        </Typo.textSm>
                      </Button>
                      <div className="flex flex-col md:flex-row gap-3 ">
                        <Button
                          onClick={() => {
                            onBanUserButtonClicked(report);
                          }}
                          size="md"
                          theme="error-outline">
                          <Typo.textSm fontWeight="font-medium" className="text-error-600">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON.id}
                              defaultMessage={
                                TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON.defaultMessage
                              }
                            />
                          </Typo.textSm>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-8 lg:mr-4 lg:mt-4 md:mx-4 ">
                  <ActivityList activityHeader={activityHeader} activityData={activityData} />
                </div>
              </div>
              <Divider />
              <div className="px-6">
                <Pagination
                  currentPage={currentPage}
                  totalCount={messageReportsCount}
                  pageSize={1}
                  onPageChange={fetchMoreData}
                  type="simple-card-footer"
                />
              </div>
            </div>
          )}
        </div>
      )}

      <RedActionModal
        title={TRANSLATED_CONSTANTS.DELETE_MESSAGE_REPORT_MODAL_TITLE}
        isOpen={showDeleteModal}
        onCloseRedActionModal={(value: boolean) => onCloseDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DELETE_MESSAGE_REPORT_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE}
        icon={<TrashIcon />}
      />
      <RedActionModal
        title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
        isOpen={showBanModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
        username={messageReports && messageReports[0]?.username}
      />
    </div>
  );
};

export default MessageReportDetailedLayout;
