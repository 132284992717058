import MapGraphicListing from '../../../assets/homepage/Map graphic listing section-min.png';
import ListingGraphic from '../../../assets/homepage/Listing graphic-23-min.png';
import { Typo } from '../../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import Featured1Icon from '../../../components/icons/Featured1Icon';
import Featured2Icon from '../../../components/icons/Featured2Icon';
import Featured3Icon from '../../../components/icons/Featured3Icon';

const AddListingToMap = () => {
  const features = [
    {
      id: 1,
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ADD_WANTED_LISTING.id}
          defaultMessage={TRANSLATED_CONSTANTS.ADD_WANTED_LISTING.defaultMessage}
        />
      ),
      description: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ADD_WANTED_LISTING_DETAILS.id}
          defaultMessage={TRANSLATED_CONSTANTS.ADD_WANTED_LISTING_DETAILS.defaultMessage}
        />
      ),
      icon: <Featured1Icon />
    },
    {
      id: 2,
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ADD_PERSONAL_LISTING.id}
          defaultMessage={TRANSLATED_CONSTANTS.ADD_PERSONAL_LISTING.defaultMessage}
        />
      ),
      description: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ADD_PERSONAL_LISTING_DETAILS.id}
          defaultMessage={TRANSLATED_CONSTANTS.ADD_PERSONAL_LISTING_DETAILS.defaultMessage}
        />
      ),
      icon: <Featured2Icon />
    },
    {
      id: 3,
      title: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ADD_A_STOREFRONT.id}
          defaultMessage={TRANSLATED_CONSTANTS.ADD_A_STOREFRONT.defaultMessage}
        />
      ),
      description: (
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.ADD_A_STOREFRONT_DETAILS.id}
          defaultMessage={TRANSLATED_CONSTANTS.ADD_A_STOREFRONT_DETAILS.defaultMessage}
        />
      ),
      icon: <Featured3Icon />
    }
  ];

  return (
    <div className="w-full 2xl:px-0 px-4 sm:px-16 lg:px-[120px] bg-white">
      <div className="max-w-7xl relative mx-auto py-[96px]">
        <div className="max-w-3xl mx-auto justify-center">
          <div className=" flex justify-center">
            <span className="text-center  bg-yellow-50 rounded-2xl py-1 px-3">
              <Typo.textSm
                fontWeight="font-medium"
                className="text-yellow-600 tracking-normal leading-5">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.THE_DOGELIST_MAP.id}
                  defaultMessage={TRANSLATED_CONSTANTS.THE_DOGELIST_MAP.defaultMessage}
                />
              </Typo.textSm>
            </span>
          </div>
          <Typo.headingMd fontWeight="font-medium" className="text-gray-900 mt-4 text-center">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ADD_LISTING_TO_MAP.id}
              defaultMessage={TRANSLATED_CONSTANTS.ADD_LISTING_TO_MAP.defaultMessage}
            />
          </Typo.headingMd>
          <Typo.textXl
            fontWeight="font-normal"
            className="text-gray-600 mt-6 text-center max-w-3xl">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ADD_LISTING_TO_MAP_DETAILS.id}
              defaultMessage={TRANSLATED_CONSTANTS.ADD_LISTING_TO_MAP_DETAILS.defaultMessage}
            />
          </Typo.textXl>
          <div className=" mt-16 mb-24 shadow-2xl relative">
            <img src={MapGraphicListing} alt="no image"></img>
            <img
              src={ListingGraphic}
              className="absolute h-[213px] w-[183px] left-0 sm:w-[371px] sm:h-[431px] top-[67px] sm:top-14 sm:-left-4 md:w-[424px] md:h-[492px] md:-left-[90px]"
              alt="No Image"></img>
          </div>
        </div>
        <div className=" flex flex-col md:flex-row gap-y-7 justify-center gap-x-8 ">
          {features?.map((feature) => (
            <div key={feature.id}>
              <div className="w-full flex justify-center">{feature?.icon}</div>
              <Typo.textXl fontWeight="font-medium" className="text-gray-700 mt-4 text-center">
                {feature.title}
              </Typo.textXl>
              <Typo.textMd fontWeight="font-normal" className=" text-gray-600 mt-2 text-center">
                {feature.description}
              </Typo.textMd>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddListingToMap;
