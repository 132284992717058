import { gql, useMutation } from '@apollo/client';

interface RestoreDeletedListingsInput {
  queryParams: string;
}
const RESTORE_DELTED_LISTINGS = gql`
  mutation RestoreDeletedListings($queryParams: String) {
    restoreDeletedListing(queryParams: $queryParams) {
      count
    }
  }
`;

export const useRestoreDeleteListings = (): ((
  restoreDeletedListings: RestoreDeletedListingsInput
) => any) => {
  const [restoreDeletedListings] = useMutation(RESTORE_DELTED_LISTINGS);
  return (input: RestoreDeletedListingsInput) => restoreDeletedListings({ variables: input });
};
