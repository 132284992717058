import React, { useEffect, useState } from 'react';
import { Typo } from '../../Typo';
import Toggle from './../Toggle';
import InputDropdown from '../location Dropdown/InputDropdown';
import { Hours } from '../../../constants/data';
import ChevronSelectorIcon from '../../icons/ChevronSelectorIcon';
import { DropdownOption } from '../../../model/DropdownOption';
import { OperationHourModel } from '../../../model/OperationHourModel';
import { useIntl } from 'react-intl';
type PriceProps = {
  defaultValue: OperationHourModel;
  onChange?: (value: OperationHourModel) => void;
  disabled?: boolean;
};
const OperationHourItem: React.FC<PriceProps> = (props) => {
  const intl = useIntl();
  const [toggle, setToggle] = useState(false);
  const [openAt, setOpenAt] = useState<DropdownOption>(Hours[0]);
  const [closeAt, setCloseAt] = useState<DropdownOption>(Hours[0]);
  const { defaultValue, onChange, disabled = false } = props;

  const onChangeState = (state: boolean) => {
    const tempItem = { ...defaultValue, isOpen: state };
    onChange && onChange(tempItem);
    setToggle(state);
  };

  const onChangeOpen = (option: DropdownOption) => {
    const tempItem = { ...defaultValue, opensAt: option };
    onChange && onChange(tempItem);
    setOpenAt(option);
  };

  const onChangeClose = (option: DropdownOption) => {
    const tempItem = { ...defaultValue, closesAt: option };
    onChange && onChange(tempItem);
    setCloseAt(option);
  };

  useEffect(() => {
    if (defaultValue) {
      setToggle(defaultValue.isOpen);
      setOpenAt(defaultValue.opensAt);
      setCloseAt(defaultValue.closesAt);
    }
  }, [defaultValue]);

  return (
    <div className="w-full grid grid-cols-1 h-auto sm:h-11 sm:grid-cols-5 space-y-6 sm:space-y-0 gap-x-12">
      <div className="w-full flex sm:col-span-2 justify-between items-start">
        <Typo.textSm fontWeight="font-medium" className="text-gray-700">
          {defaultValue?.day}
        </Typo.textSm>
        <Toggle
          size="sm"
          disabled={disabled}
          theme="dark"
          onChange={onChangeState}
          defaultValue={toggle}
          className=" disabled:bg-yellow-600"
          description={
            toggle
              ? `${intl.formatMessage({ id: 'shared.open', defaultMessage: 'Open' })}`
              : `${intl.formatMessage({ id: 'shared.closed', defaultMessage: 'Closed' })}`
          }></Toggle>
      </div>
      {toggle ? (
        <div className="flex gap-x-4 sm:col-span-3 items-start">
          <InputDropdown
            options={Hours}
            defaultValue={openAt}
            onChange={onChangeOpen}
            disabled={disabled}
            suffex={<ChevronSelectorIcon></ChevronSelectorIcon>}></InputDropdown>
          <div className=" flex h-full justify-between items-center">
            <Typo.textSm fontWeight="font-normal" className="text-gray-500">
              {intl.formatMessage({ id: 'shared.to', defaultMessage: 'To' })}
            </Typo.textSm>
          </div>
          <InputDropdown
            options={Hours}
            disabled={disabled}
            onChange={onChangeClose}
            defaultValue={closeAt}
            suffex={<ChevronSelectorIcon></ChevronSelectorIcon>}></InputDropdown>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default OperationHourItem;
