import { createSlice } from '@reduxjs/toolkit';
import { LISTING_TYPES } from '../../../constants/data';

const dashboardSlice = createSlice({
  name: 'dashboard slice',
  initialState: {
    tab: undefined,
    createListingType: LISTING_TYPES.SELLING
  },
  reducers: {
    addDashboardTab: (state, action) => {
      state.tab = action.payload;
    },
    addCreateListingType: (state, action) => {
      state.createListingType = action.payload;
    }
  }
});

export const { addDashboardTab, addCreateListingType } = dashboardSlice.actions;
export default dashboardSlice.reducer;
