import clsx from 'clsx';

type Props = {
  className?: string;
};
const TwitterIcon = ({ className }: Props) => {
  return (
    <svg
      className={clsx(' w-6 h-5', className ? className : 'text-gray-400 ')}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M7.55016 20C16.6045 20 21.5583 12.3046 21.5583 5.63322C21.5583 5.41688 21.5536 5.19574 21.5442 4.9794C22.5079 4.26466 23.3395 3.37935 24 2.36507C23.1025 2.7746 22.1496 3.04206 21.1739 3.15831C22.2013 2.52672 22.9705 1.53451 23.3391 0.365627C22.3726 0.953083 21.3156 1.36748 20.2134 1.59106C19.4708 0.78181 18.489 0.24599 17.4197 0.0664421C16.3504 -0.113105 15.2532 0.0736207 14.2977 0.597751C13.3423 1.12188 12.5818 1.95422 12.1338 2.96609C11.6859 3.97796 11.5754 5.11299 11.8195 6.1957C9.86249 6.09498 7.94794 5.57358 6.19998 4.6653C4.45203 3.75702 2.90969 2.48213 1.67297 0.923299C1.0444 2.03477 0.852057 3.35001 1.13503 4.6017C1.418 5.85339 2.15506 6.94762 3.19641 7.66199C2.41463 7.63654 1.64998 7.42066 0.965625 7.03221V7.09471C0.964925 8.26111 1.3581 9.39178 2.07831 10.2945C2.79852 11.1972 3.80132 11.8163 4.91625 12.0464C4.19206 12.2497 3.43198 12.2793 2.69484 12.133C3.00945 13.1361 3.62157 14.0135 4.44577 14.6426C5.26997 15.2718 6.26512 15.6213 7.29234 15.6425C5.54842 17.0474 3.39417 17.8095 1.17656 17.8058C0.783287 17.8052 0.390399 17.7805 0 17.7318C2.25286 19.2141 4.87353 20.0014 7.55016 20Z"
      />
    </svg>
  );
};

export default TwitterIcon;
