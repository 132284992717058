import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { customizeChats } from './transform';

export const GET_CHATS = gql`
  query GetChats($userId: ID!, $offset: Int!, $limit: Int!) {
    chatsCount: chats(
      filters: {
        messages: { id: { ne: null } }
        or: [{ firstUser: { id: { eq: $userId } } }, { secondUser: { id: { eq: $userId } } }]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    chats(
      sort: "publishedAt:ASC"
      pagination: { start: $offset, limit: $limit }
      filters: {
        messages: { id: { ne: null } }
        or: [{ firstUser: { id: { eq: $userId } } }, { secondUser: { id: { eq: $userId } } }]
      }
    ) {
      data {
        id
        attributes {
          publishedAt
          messages(sort: "publishedAt:DESC", pagination: { start: 0, limit: 1 }) {
            data {
              id
              attributes {
                content
              }
            }
          }
          firstUser {
            data {
              id
              attributes {
                name
                username
                type
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          secondUser {
            data {
              id
              attributes {
                name
                username
                type
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetChats = (userId: string, offset: number, limit?: number) => {
  const { loading, error, data, fetchMore } = useQuery(GET_CHATS, {
    variables: { userId, offset, limit },
    fetchPolicy: 'cache-and-network'
  });
  const flattedData = flattenObj(data?.chats);
  const customizedChats = customizeChats(flattedData, userId);
  return {
    chats: customizedChats,
    totalChats: data?.chatsCount,
    fetchMore,
    loading,
    error
  };
};
