import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { ButtonGroupModel } from '../../model/ButtonGroupModel';
type Props = {
  items?: Array<ButtonGroupModel>;
  onChange?: (option: ButtonGroupModel) => void;
};
const ButtonGroup: React.FC<Props> = (props) => {
  const { items = [], onChange } = props;
  const [selectedItem, setSelectedItem] = useState<ButtonGroupModel>(items[0]);

  const handleChange = (item: ButtonGroupModel) => {
    setSelectedItem(item);
    onChange && onChange(item);
  };
  return (
    <span className="isolate inline-flex rounded-md shadow-xs">
      {items?.map((item, index) => (
        <button
          onClick={() => handleChange(item)}
          key={index}
          type="button"
          disabled={item?.disabled ? true : false}
          className={clsx(
            'relative inline-flex items-center px-4 py-[10px] text-sm font-medium leading-5 font-inter  text-gray-700 ring-gray-300 hover:bg-gray-50 hover:text-gray-800  focus:bg-gray-50 focus:text-gray-700 disabled:bg-white disabled:text-gray-300 ring-1 ring-inset focus:z-10',
            index == 0 ? ' rounded-l-md ' : ' -ml-px ',
            items.length - 1 === index ? 'rounded-r-md  ' : '',
            selectedItem?.id === item.id ? 'bg-gray-50 text-gray-800 ' : 'bg-white'
          )}>
          {item.content}
        </button>
      ))}
    </span>
  );
};

export default ButtonGroup;
