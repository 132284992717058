import React, { FC } from 'react';
import Button from '../../components/shared/Button';
import MapPinIcon from '../../components/icons/MapPinIcon';
import { Typo } from '../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

type Props = {
  getDirection: () => void;
  className?: string;
};
const DirectionButton: FC<Props> = ({ getDirection, className }) => {
  return (
    <Button
      onClick={() => getDirection()}
      className={className}
      size="lg"
      theme="primary-yellow"
      icon={<MapPinIcon className="text-gray-700" />}>
      <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.DIRECTIONS.id}
          defaultMessage={TRANSLATED_CONSTANTS.DIRECTIONS.defaultMessage}
        />
      </Typo.textSm>
    </Button>
  );
};

export default DirectionButton;
