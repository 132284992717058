import React, { FC } from 'react';
import { Typo } from '../../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import InputField from '../../../components/shared/InputField';
import TextAreaField from '../../../components/shared/TextAreaField';
import { useForm } from 'react-hook-form';

type Props = {
  claim: string;
  detail: string;
};

const Reason: FC<Props> = ({ claim, detail }) => {
  const { register } = useForm();
  return (
    <div className="flex flex-col md:flex-row gap-8 justify-between items-start">
      <div className="w-[280px]">
        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.REASON.id}
            defaultMessage={TRANSLATED_CONSTANTS.REASON.defaultMessage}
          />
        </Typo.textSm>
        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
          <FormattedMessage
            id="page.report.detailed_report_reason_subtitle"
            defaultMessage="Why this was reported."
          />
        </Typo.textSm>
      </div>
      <div className="flex flex-1 w-full flex-col gap-4 ">
        <InputField
          name="claim"
          type="text"
          label="Claim"
          placeholder=""
          defaultValue={claim}
          value={claim}
          register={register}
          disabled={true}
        />
        <TextAreaField
          name="description"
          disabled={true}
          label="Details"
          rows={6}
          // defaultValue={detail}
          value={detail}
          placeholder=""
          register={register}
        />
      </div>
    </div>
  );
};

export default Reason;
