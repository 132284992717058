export const flattenObj = (data: any, showDefaultUrl?: boolean) => {
  const isObject = (data: any) => Object.prototype.toString.call(data) === '[object Object]';
  const isArray = (data: any) => Object.prototype.toString.call(data) === '[object Array]';
  const flatten = (data: any) => {
    if (!data.attributes) return data;

    if (
      data?.attributes?.formats &&
      data?.attributes?.formats?.small &&
      data?.attributes?.formats?.small?.url &&
      (showDefaultUrl == undefined || !showDefaultUrl)
    ) {
      data.attributes.url = data.attributes.formats.small.url;
    }

    return {
      id: data.id,
      ...data.attributes
    };
  };

  if (isArray(data)) {
    return data.map((item: any) => flattenObj(item, showDefaultUrl));
  }

  if (isObject(data)) {
    if (isArray(data.data)) {
      data = [...data.data];
    } else if (isObject(data.data)) {
      data = flatten({ ...data.data });
    } else if (data.data === null) {
      data = null;
    } else {
      data = flatten(data);
    }

    for (const key in data) {
      try {
        data[key] = flattenObj(data[key], showDefaultUrl);
      } catch (e) {}
    }

    return data;
  }

  return data;
};

// export const flattenObj = (data: any) => {
//   const isObject = (data: any) => Object.prototype.toString.call(data) === '[object Object]';
//   const isArray = (data: any) => Object.prototype.toString.call(data) === '[object Array]';

//   const flatten = (data: any) => {
//     if (!data.attributes) return data;

//     return {
//       id: data.id,
//       ...data.attributes
//     };
//   };

//   if (isArray(data)) {
//     return data.map((item: any) => flattenObj(item));
//   }

//   if (isObject(data)) {
//     if (isArray(data.data)) {
//       data = [...data.data];
//     } else if (isObject(data.data)) {
//       data = flatten({ ...data.data });
//     } else if (data.data === null) {
//       data = null;
//     } else {
//       data = flatten(data);
//     }

//     for (const key in data) {
//       try {
//         data[key] = flattenObj(data[key]);
//       } catch (e) {}
//     }

//     return data;
//   }

//   return data;
// };
