import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';

export const handleListingAndStorefrontBadge = (type: string, intl: any) => {
  const themeMap: { [key: string]: any } = {
    wanted: 'pink',
    personal: 'blue',
    storefront: 'purple',
    storefrontItem: 'indigo'
  };

  const typeMap: { [key: string]: string } = {
    wanted: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.WANTED.id,
      defaultMessage: TRANSLATED_CONSTANTS.WANTED.defaultMessage
    }),
    personal: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PERSONAL.id,
      defaultMessage: TRANSLATED_CONSTANTS.PERSONAL.defaultMessage
    }),
    storefront: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.STOREFRONT.id,
      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage
    }),
    storefrontItem: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.id,
      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.defaultMessage
    })
  };
  return { theme: themeMap[type], name: typeMap[type] };
};

export const handleAccountTypeBadge = (type: string, intl: any) => {
  const themeMap: { [key: string]: any } = {
    personal_seller: 'blue',
    business: 'purple'
  };
  const typeMap: { [key: string]: string } = {
    personal_seller: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PERSONAL.id,
      defaultMessage: TRANSLATED_CONSTANTS.PERSONAL.defaultMessage
    }),
    business: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.BUSINESS.id,
      defaultMessage: TRANSLATED_CONSTANTS.BUSINESS.defaultMessage
    })
  };
  return { theme: themeMap[type], name: typeMap[type] };
};

export const handleUserRoleBadge = (type: string, intl: any) => {
  const themeMap: { [key: string]: any } = {
    admin: 'blue-light',
    'super-admin': 'orange',
    moderator: 'gray'
  };
  const typeMap: { [key: string]: string } = {
    admin: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ADMIN.id,
      defaultMessage: TRANSLATED_CONSTANTS.ADMIN.defaultMessage
    }),
    'super-admin': intl.formatMessage({
      id: TRANSLATED_CONSTANTS.SUPER_ADMIN.id,
      defaultMessage: TRANSLATED_CONSTANTS.SUPER_ADMIN.defaultMessage
    }),
    moderator: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.MODERATOR.id,
      defaultMessage: TRANSLATED_CONSTANTS.MODERATOR.defaultMessage
    }),
    '': ''
  };

  const dashboardNameMap: { [key: string]: string } = {
    admin: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ADMIN_DASHBOARD.id,
      defaultMessage: TRANSLATED_CONSTANTS.ADMIN_DASHBOARD.defaultMessage
    }),
    'supper-admin': intl.formatMessage({
      id: TRANSLATED_CONSTANTS.SUPER_ADMIN_DASHBOARD.id,
      defaultMessage: TRANSLATED_CONSTANTS.SUPER_ADMIN_DASHBOARD.defaultMessage
    }),
    moderator: '',
    'regular-user': intl.formatMessage({
      id: TRANSLATED_CONSTANTS.USER_DASHBOARD.id,
      defaultMessage: TRANSLATED_CONSTANTS.SUPER_ADMIN.defaultMessage
    }),
    '': ''
  };
  return { theme: themeMap[type], name: typeMap[type], dashboardName: dashboardNameMap[type] };
};

export const handleListigTypeContent = (listType: string, intl: any) => {
  const titleMap: { [key: string]: any } = {
    wanted: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.WANTED_LISTING.id,
      defaultMessage: TRANSLATED_CONSTANTS.WANTED_LISTING.defaultMessage
    }),
    personal: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PERSONAL_LISTING.id,
      defaultMessage: TRANSLATED_CONSTANTS.PERSONAL_LISTING.defaultMessage
    }),
    storefrontItem: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.id,
      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.defaultMessage
    })
  };
  const typeMap: { [key: string]: any } = {
    wanted: 'buying',
    personal: 'selling',
    storefrontItem: 'selling'
  };
  const descriptionMap: { [key: string]: string } = {
    wanted: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ADD_WANTED_LISTING_DESCRIPTION.id,
      defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT_ITEM.defaultMessage
    }),
    personal: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ADD_PERSONAL_LISTING_DESCRIPTION.id,
      defaultMessage: TRANSLATED_CONSTANTS.ADD_PERSONAL_LISTING_DESCRIPTION.defaultMessage
    }),
    storefrontItem: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ADD_STOREFRONT_ITEM_DESCRIPTION.id,
      defaultMessage: TRANSLATED_CONSTANTS.ADD_STOREFRONT_ITEM_DESCRIPTION.defaultMessage
    }),
    '': ''
  };

  return {
    title: titleMap[listType],
    type: typeMap[listType],
    description: descriptionMap[listType]
  };
};

export const handlePriceTypeContent = (priceType: string, intl: any) => {
  const priceTypeTitleMap: { [key: string]: string } = {
    Price_is_firm: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PRIME_IS_FIRM.id,
      defaultMessage: TRANSLATED_CONSTANTS.PRIME_IS_FIRM.defaultMessage
    }),
    Open_for_offers: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.OPEN_FOR_OFFERS.id,
      defaultMessage: TRANSLATED_CONSTANTS.OPEN_FOR_OFFERS.defaultMessage
    }),
    '': ''
  };
  return { title: priceTypeTitleMap[priceType] };
};
export const handlePriceOptionContent = (priceOption: string, intl: any) => {
  const priceOptionTitleMap: { [key: string]: string } = {
    Negotiable_no_price: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.STANDARD_PRICE.id,
      defaultMessage: TRANSLATED_CONSTANTS.STANDARD_PRICE.defaultMessage
    }),
    Doge_Doge: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.DOGE_DOGE.id,
      defaultMessage: TRANSLATED_CONSTANTS.DOGE_DOGE.defaultMessage
    }),
    '': ''
  };
  return { title: priceOptionTitleMap[priceOption] };
};

export const handleValidationMessage = (intl: any) => {
  const validationMessageMap: { [key: string]: string } = {
    required_field: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.FIELD_REQUIRED.id,
      defaultMessage: TRANSLATED_CONSTANTS.FIELD_REQUIRED.defaultMessage
    }),
    an_option_must_select: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.AN_OPTION_MUST_BE_SELECTED.id,
      defaultMessage: TRANSLATED_CONSTANTS.AN_OPTION_MUST_BE_SELECTED.defaultMessage
    }),
    must_be_number: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.MUST_BE_NUMBER.id,
      defaultMessage: TRANSLATED_CONSTANTS.MUST_BE_NUMBER.defaultMessage
    }),
    username_taken: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.VALIDATION_USERNAME_TAKEN.id,
      defaultMessage: TRANSLATED_CONSTANTS.VALIDATION_USERNAME_TAKEN.defaultMessage
    }),
    enter_city: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PLEASE_ENTER_YOUR_CITY.id,
      defaultMessage: TRANSLATED_CONSTANTS.PLEASE_ENTER_YOUR_CITY.defaultMessage
    }),
    enter_province: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PLEASE_ENTER_YOUR_STATE_PROVINCE.id,
      defaultMessage: TRANSLATED_CONSTANTS.PLEASE_ENTER_YOUR_STATE_PROVINCE.defaultMessage
    }),
    enter_country: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.PLEASE_ENTER_YOUR_COUNTRY.id,
      defaultMessage: TRANSLATED_CONSTANTS.PLEASE_ENTER_YOUR_COUNTRY.defaultMessage
    }),
    '': ''
  };
  return validationMessageMap;
};

export const handleIssuedListingBadge = (intl: any) => {
  const issuedTheme: { [key: string]: any } = {
    'delete-warning': 'warning',
    'delete-ban': 'error',
    quarantine: 'blue-gray',
    delete: 'error'
  };

  const issueTitle: { [key: string]: string } = {
    'delete-warning': intl.formatMessage({
      id: TRANSLATED_CONSTANTS.DELETE_AND_WARNING.id,
      defaultMessage: TRANSLATED_CONSTANTS.DELETE_AND_WARNING.defaultMessage
    }),
    'delete-ban': intl.formatMessage({
      id: TRANSLATED_CONSTANTS.DELETE_AND_BAN.id,
      defaultMessage: TRANSLATED_CONSTANTS.DELETE_AND_BAN.defaultMessage
    }),
    quarantine: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.QUARANTINE.id,
      defaultMessage: TRANSLATED_CONSTANTS.QUARANTINE.defaultMessage
    }),
    delete: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.DELETE.id,
      defaultMessage: TRANSLATED_CONSTANTS.DELETE.defaultMessage
    })
  };
  return { issuedTheme: issuedTheme, issueTitle: issueTitle };
};
