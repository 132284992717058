import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import SettingWrapper from '../../components/wrapper/SettingWrapper';
import { Typo } from '../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Button from '../../components/shared/Button';
import { FormattedMessage, useIntl } from 'react-intl';
import SaveIcon from '../../components/icons/SaveIcon';
import Toggle from '../../components/shared/Toggle';
import { useState } from 'react';
import { useUpdateUser } from '../../hooks/user/useUpdateUser';
import { useAuth } from '../../context/Authn';
const Notifications = () => {
  const authn = useAuth();
  const user = authn.getUser();
  const intl = useIntl();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isAllowed, setIsAllowed] = useState(user?.allowNotification || false);
  const updateUser = useUpdateUser();

  const onSave = async () => {
    setIsSubmiting(true);
    await updateUser({
      variables: {
        data: {
          allowNotification: isAllowed
        },
        id: user?.id
      }
    });
    setIsSubmiting(false);
  };

  const onCancel = () => {
    setIsAllowed(user?.allowNotification);
  };

  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <div className="w-full">
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
            <DashboardHeader
              title={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.NOTIFICATIONS.id,
                defaultMessage: TRANSLATED_CONSTANTS.NOTIFICATIONS.defaultMessage
              })}
              description={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.NOTIFICATION_DETAILS.id,
                defaultMessage: TRANSLATED_CONSTANTS.NOTIFICATION_DETAILS.defaultMessage
              })}
              accountType={user?.type}
            />
          </div>

          <div className="mx-auto max-w-7xl w-full">
            <div className="divide-y divide-gray-200">
              <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1 px-4 md:px-0">
                <div className="py-5 w-full space-y-5 flex flex-col md:col-span-4 lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                  <div className="lg:col-span-2">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700  ">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.ALLOW_PUSH_NOTIFICATIONS.id}
                        defaultMessage={
                          TRANSLATED_CONSTANTS.ALLOW_PUSH_NOTIFICATIONS.defaultMessage
                        }
                      />
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500 ">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.ALLOW_PUSH_NOTIFICATIONS_DETAILS.id}
                        defaultMessage={
                          TRANSLATED_CONSTANTS.ALLOW_PUSH_NOTIFICATIONS_DETAILS.defaultMessage
                        }
                      />
                    </Typo.textSm>
                  </div>
                  <div className="lg:col-span-3">
                    <Toggle
                      size="sm"
                      theme="dark"
                      label={isAllowed ? 'Allowed' : 'Disallowed'}
                      defaultValue={isAllowed}
                      onChange={setIsAllowed}
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end gap-x-3  pt-5">
                <Button onClick={onCancel} size="lg" theme="base">
                  <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.CANCEL.id}
                      defaultMessage={TRANSLATED_CONSTANTS.CANCEL.defaultMessage}
                    />
                  </Typo.textMd>
                </Button>
                <Button
                  size="lg"
                  onClick={onSave}
                  loading={isSubmiting}
                  type="submit"
                  theme="primary-yellow"
                  icon={<SaveIcon className="h-5 w-4 text-gray-900" />}>
                  <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.SAVE.id}
                      defaultMessage={TRANSLATED_CONSTANTS.SAVE.defaultMessage}
                    />
                  </Typo.textMd>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SettingWrapper>
    </div>
  );
};

export default Notifications;
