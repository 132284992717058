type Props = {
  className?: string;
};
const RestoreIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className} `}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33398 5.83333H11.6673C14.4287 5.83333 16.6673 8.07191 16.6673 10.8333C16.6673 13.5948 14.4287 15.8333 11.6673 15.8333H3.33398M3.33398 5.83333L6.66732 2.5M3.33398 5.83333L6.66732 9.16667"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RestoreIcon;
