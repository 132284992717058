import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { transformDeletedListings } from './transform';

export const GET_DETAILED_DELETED_LISTINGS = gql`
  query getDetailedDeletedListings(
    $offset: Int
    $limit: Int
    $searchText: String
    $filter: UsersPermissionsUserFiltersInput
  ) {
    deletedListingsCount: usersPermissionsUsers(
      filters: {
        # banned_user: { banned: { id: { ne: null } } }
        listings: { issued_listing: { actionType: { ne: "quarantine" } } }
        or: [
          { username: { contains: $searchText } }
          { name: { contains: $searchText } }
          { listings: { name: { eq: $searchText } } }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersPermissionsUsers(
      pagination: { limit: $limit, start: $offset }
      sort: "id:desc"
      filters: {
        and: [
          $filter
          # { banned_user: { banned: { id: { ne: null } } } }
          { listings: { issued_listing: { actionType: { ne: "quarantine" } } } }
          {
            or: [
              { username: { contains: $searchText } }
              { name: { contains: $searchText } }
              { listings: { name: { eq: $searchText } } }
            ]
          }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          username
          email
          createdAt
          listings {
            data {
              id
              attributes {
                name
                type
                image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                type
                description
                price {
                  alternatePriceOptions
                  priceType
                  priceInUSD
                  priceInDoge
                }
                listing_reports {
                  data {
                    id
                  }
                }
                store_front {
                  data {
                    id
                    attributes {
                      name
                      location {
                        address
                        latitude
                        longitude
                      }
                    }
                  }
                }
                issued_listing {
                  data {
                    id
                    attributes {
                      actionType
                      detail
                      claim
                      date
                      issuer {
                        data {
                          id
                          attributes {
                            name
                            email
                            username
                            type
                            role {
                              data {
                                attributes {
                                  name
                                }
                              }
                            }
                            profileImage {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetDetailedDeletedListings = (
  offset: number,
  limit: number,
  searchText: string,
  userID?: number
) => {
  const filter = userID ? { id: { eq: userID } } : {};
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_DETAILED_DELETED_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText, filter: filter }
  });
  const flattenData = flattenObj(data?.usersPermissionsUsers);
  const formattedData = data?.usersPermissionsUsers
    ? transformDeletedListings(flattenData ? flattenData[0] : undefined)
    : flattenData;
  return {
    deletedListings: formattedData,
    deletedListingsCount: data?.deletedListingsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
