type Props = {
  className?: string;
};
const CurrencyDollarCircleIcon = ({ className }: Props) => {
  return (
    <svg
      className={'h-5 w-5 ' + className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_939_154679)">
        <path
          d="M7.08366 12.2221C7.08366 13.2959 7.95422 14.1665 9.0281 14.1665H10.8337C11.9843 14.1665 12.917 13.2338 12.917 12.0832C12.917 10.9326 11.9843 9.99984 10.8337 9.99984H9.16699C8.0164 9.99984 7.08366 9.0671 7.08366 7.9165C7.08366 6.76591 8.0164 5.83317 9.16699 5.83317H10.9725C12.0464 5.83317 12.917 6.70373 12.917 7.77762M10.0003 4.58317V5.83317M10.0003 14.1665V15.4165M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z"
          stroke="currentColor"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_939_154679">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CurrencyDollarCircleIcon;
