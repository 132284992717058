/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import { QuarantineListings } from '../../../../model/Listing';
import Table from '../../../../components/shared/table/Table';
import { useHandleIssuedListings } from '../../../../hooks/useHandleIssuedListings';
import CheckboxField from '../../../../components/shared/CheckboxField';
import { Typo } from '../../../../components/Typo';
import { formatDate } from '../../../../util/AppUtil';
import Badge from '../../../../components/shared/Badge';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import clsx from 'clsx';
import UserInfo from '../../../../components/shared/table/UserInfo';
import Button from '../../../../components/shared/Button';
import Divider from '../../../../components/shared/Divider';
import Pagination from '../../../../components/shared/Pagination';
import { NUMBER_OF_RECORDS_PER_TABLE } from '../../../../constants/data';
import PrimaryActionModal from '../../../../components/modal/PrimaryActionModal';
import TagIcon from '../../../../components/icons/TagIcon';
import TableHead from './TableHead';
import { useDispatch } from 'react-redux';
import { addReportLayout, setDeletedListing } from '../../../../lib/redux/reducers/report.slice';

type Props = {
  pageType: 'listing-quarantine' | 'listing-deleted';
  data: any;
  listingsCount: number;
  currentPage: number;
  refetch: () => void;
  paginate: (pageNumber: number) => void;
};

const ListingGridLayout: FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { data, listingsCount, currentPage, refetch, paginate, pageType } = props;
  const [checked, setChecked] = useState(false);
  const {
    setSelectedListings,
    selectedListings,
    onRestoreButtonClicked,
    showReslistItemModal,
    onCloseRelistModalModal
  } = useHandleIssuedListings(refetch);

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedListings(data);
    } else {
      setSelectedListings([]);
    }
  };

  const handleIndex = (index: number) => {
    const startIndex = (currentPage - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    return index + startIndex;
  };

  const showDetailedRecord = (index: number, listing?: QuarantineListings) => {
    dispatch(addReportLayout({ reportLayout: { type: 'detailed', index: handleIndex(index) } }));
    if (pageType == 'listing-deleted') {
      dispatch(
        setDeletedListing({
          userId: listing?.user?.id,
          listingId: listing?.id
        })
      );
    }
  };
  return (
    <div className="min-w-full w-full overflow-x-auto  ">
      <Table>
        <TableHead checked={checked} toggleAll={toggleAll} />
        <tbody className="divide-y divide-gray-200 bg-white">
          {data?.map((listing: QuarantineListings, index: number) => {
            const reportExistsInSelectedReports = selectedListings.some(
              (eachObj: any) => eachObj.id === listing.id
            );
            return (
              <tr
                key={listing.id}
                onClick={() => showDetailedRecord(index, listing)}
                className={clsx(
                  reportExistsInSelectedReports ? 'bg-gray-50' : undefined,
                  'cursor-pointer hover:bg-gray-50'
                )}>
                <td className="relative  sm:w-12 sm:px-6 py-3">
                  <div
                    className="absolute left-6 top-1/2 -mt-3"
                    onClick={(event) => event.stopPropagation()}>
                    <CheckboxField
                      name="state"
                      value="check"
                      label=""
                      size="md"
                      type="checkbox"
                      roundedSize="rounded-md"
                      isSelected={reportExistsInSelectedReports}
                      onChange={(e) =>
                        setSelectedListings(
                          e.target.checked
                            ? [...selectedListings, listing]
                            : selectedListings.filter((l: any) => l.id !== listing.id)
                        )
                      }
                      register={() => {}}
                    />
                  </div>
                </td>
                <td className="whitespace-nowrap px-3 py-4 min-w-[20rem] ">
                  <div className="">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-900 capitalize">
                      {listing?.name}
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                      @{listing?.user?.username}
                    </Typo.textSm>
                  </div>
                </td>

                <td className="whitespace-nowrap px-3 py-4 truncate">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-600 capitalize">
                    {listing?.issued_listing?.claim}
                  </Typo.textSm>
                  <Typo.textXs
                    fontWeight="font-medium"
                    className="text-gray-600 capitalize truncate w-52">
                    {listing?.issued_listing?.detail}
                  </Typo.textXs>
                </td>
                <td className="whitespace-nowrap px-3 py-4">
                  <Typo.textSm fontWeight="font-medium" className="text-gray-900 capitalize">
                    {formatDate(listing?.issued_listing?.date?.toString())}
                  </Typo.textSm>
                </td>

                <td className="whitespace-nowrap px-3 py-4 ">
                  <Typo.textXs fontWeight="font-medium">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-900 capitalize">
                      {listing?.issued_listing?.actionType === 'delete_ban' ? (
                        <Badge
                          theme="error"
                          label={
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.DELETE_AND_BAN.id}
                              defaultMessage={TRANSLATED_CONSTANTS.DELETE_AND_BAN.defaultMessage}
                            />
                          }></Badge>
                      ) : listing?.issued_listing?.actionType === 'delete_warning' ? (
                        <Badge
                          theme="primary"
                          label={
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.DELETE_AND_WARNING.id}
                              defaultMessage={
                                TRANSLATED_CONSTANTS.DELETE_AND_WARNING.defaultMessage
                              }
                            />
                          }></Badge>
                      ) : listing?.issued_listing?.actionType === 'quarantine' ? (
                        <Badge
                          theme="gray"
                          label={
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.QUARANTINE.id}
                              defaultMessage={TRANSLATED_CONSTANTS.QUARANTINE.defaultMessage}
                            />
                          }></Badge>
                      ) : (
                        <Badge
                          theme="primary"
                          label={
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.DELETE.id}
                              defaultMessage={TRANSLATED_CONSTANTS.DELETE.defaultMessage}
                            />
                          }></Badge>
                      )}
                    </Typo.textSm>
                  </Typo.textXs>
                </td>
                <td className={clsx('whitespace-nowrap py-4 pr-3 ')}>
                  <UserInfo profileImage={listing?.user?.profileImage?.url || ''}>
                    <div className="">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-900 capitalize">
                        {listing?.user?.name || ''}
                      </Typo.textSm>

                      <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                        @{listing?.user?.username || ''}
                      </Typo.textSm>
                    </div>
                  </UserInfo>
                </td>
                <td className="whitespace-nowrap px-3 py-4 ">
                  <Button
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      onRestoreButtonClicked(listing, true);
                    }}
                    size="md"
                    theme="gray-link">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-600">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.RESTORE.id}
                        defaultMessage={TRANSLATED_CONSTANTS.RESTORE.defaultMessage}
                      />
                    </Typo.textMd>
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Divider />
      <div className="px-6">
        <Pagination
          currentPage={currentPage}
          totalCount={listingsCount}
          pageSize={NUMBER_OF_RECORDS_PER_TABLE}
          onPageChange={paginate}
          type="centered-page-number"
        />
      </div>
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
        isOpen={showReslistItemModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseRelistModalModal(value, pageType)}
        subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_CONFIRM_BUTTON}
        icon={<TagIcon />}
      />
    </div>
  );
};

export default ListingGridLayout;
