import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { ChangeEvent, useEffect, useState } from 'react';
import { useUpdateUser } from '../../../hooks/user/useUpdateUser';
import { ACCOUNT_TYPE } from '../../../constants/data';
import InputField from '../../../components/shared/InputField';
import Button from '../../../components/shared/Button';
import { useCheckUsername } from '../../../hooks/user/useCheckUsername';
import React from 'react';
import { Typo } from '../../../components/Typo';
import { handleAccountTypeBadge, handleValidationMessage } from '../../../util/ListingBadgeHandler';
import Badge from '../../../components/shared/Badge';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';

type Props = {
  onNextStep: () => void;
  accountType: string;
  auth: any;
};
const CreateUserProfile: React.FC<Props> = (props) => {
  const { accountType, onNextStep, auth } = props;
  const updateUser = useUpdateUser();
  const user = auth?.getUser();
  const intl = useIntl();
  const { refetch } = useCheckUsername('', auth?.getMe()?.id);
  const validationMessages = handleValidationMessage(intl);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const schema = yup.object().shape({
    username: yup
      .string()
      .matches(/^[a-zA-Z0-9_.]+$/, 'Symbols and spaces are not allowed in the username')
      .test('no-profanity', 'Profanity, racial, or sexual references are not allowed', (value) => {
        // Replace this list with your own list of profane, racial, or sexual words
        const profaneWords = ['profane', 'female', 'male'];
        return !profaneWords.some((word) => value?.toLowerCase().includes(word));
      }),
    name: yup.string().required()
  });

  const accountNameAndThem = handleAccountTypeBadge(accountType, intl);

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    try {
      setIsSubmiting(true);
      const response = await updateUser({
        variables: {
          data: {
            username: data?.username,
            name: data?.name
          },
          id: auth?.getMe()?.id
        }
      });
      if (response) {
        auth?.userRefetch();
        onNextStep();
      }
      setIsSubmiting(false);
    } catch (e) {
      setIsSubmiting(false);
    }
  };

  const onChangeUsername = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      refetch({ username: e.target.value, id: user?.id }).then((response) => {
        if (response?.data?.usersPermissionsUsers?.data?.length > 0) {
          setError('username', {
            message: validationMessages['username_taken']
          });
        } else {
          clearErrors('username');
        }
      });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setValue('name', user?.name);
    setValue('username', user?.username);
  }, [user]);

  return (
    <div className="w-full px-4 pt-12 flex justify-center items-center sm:pt-24">
      <div className="max-w-[612px]">
        <div className="flex gap-x-4 items-center">
          <div className=" font-poppins font-medium text-xl leading-7">
            <Typo.textXl fontWeight="font-medium" className="text-gray-900">
              {accountType === ACCOUNT_TYPE.PERSONAL
                ? intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.CREATE_MY_PERSONAL_PROFILE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.CREATE_MY_PERSONAL_PROFILE.defaultMessage
                  })
                : intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.CREATE_STOREFRONT_PROFILE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.CREATE_STOREFRONT_PROFILE.defaultMessage
                  })}
            </Typo.textXl>
          </div>
          <Badge size="lg" label={accountNameAndThem.name} theme={accountNameAndThem.theme}></Badge>
        </div>
        <div className="mt-8 w-full">
          <form id="add-collection" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
            <div className="overflow-visible sm:rounded-md ">
              <div className="flex w-full flex-col gap-y-5">
                <InputField
                  name="name"
                  label={`${intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.NAME.id,
                    defaultMessage: TRANSLATED_CONSTANTS.NAME.defaultMessage
                  })}*`}
                  placeholder={
                    accountType === ACCOUNT_TYPE.PERSONAL
                      ? intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.YOUR_NAME.id,
                          defaultMessage: TRANSLATED_CONSTANTS.YOUR_NAME.defaultMessage
                        })
                      : intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.STORE_LLC.id,
                          defaultMessage: TRANSLATED_CONSTANTS.STORE_LLC.defaultMessage
                        })
                  }
                  register={register}
                  type="text"
                  errors={errors?.name}
                />
                <InputField
                  name="username"
                  onChange={onChangeUsername}
                  label={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.USERNAME.id,
                    defaultMessage: TRANSLATED_CONSTANTS.USERNAME.defaultMessage
                  })}
                  placeholder={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.USERNAME.id,
                    defaultMessage: TRANSLATED_CONSTANTS.USERNAME.defaultMessage
                  })}
                  register={register}
                  type="text"
                  hintText={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.USERNAME_VALIDATION_MESSAGE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.USERNAME_VALIDATION_MESSAGE.defaultMessage
                  })}
                  errors={errors?.username}
                />
              </div>
              <Typo.textSm fontWeight="font-normal" className=" mt-5 w-full text-gray-800">
                *
                {intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
                  defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
                })}
              </Typo.textSm>
              <div className="w-full mt-6">
                <Button
                  type="submit"
                  theme="primary-yellow"
                  className="w-full"
                  loading={isSubmiting}>
                  <Typo.textMd fontWeight="font-semibold">
                    {accountType === ACCOUNT_TYPE.PERSONAL
                      ? intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CREATE_MY_PERSONAL_PROFILE.id,
                          defaultMessage:
                            TRANSLATED_CONSTANTS.CREATE_MY_PERSONAL_PROFILE.defaultMessage
                        })
                      : intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CREATE_STOREFRONT_PROFILE.id,
                          defaultMessage:
                            TRANSLATED_CONSTANTS.CREATE_STOREFRONT_PROFILE.defaultMessage
                        })}
                  </Typo.textMd>
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateUserProfile;
