import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_DETAILED_QUARANTINE_LISTINGS = gql`
  query getQuarantineListings($offset: Int, $limit: Int, $searchText: String) {
    quarantineListingsCount: listings(
      filters: {
        issued_listing: { actionType: { eq: "quarantine" } }
        or: [
          { user: { username: { contains: $searchText } } }
          { user: { name: { contains: $searchText } } }
          { name: { contains: $searchText } }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    listings(
      pagination: { limit: $limit, start: $offset }
      filters: {
        issued_listing: { actionType: { eq: "quarantine" } }
        or: [
          { user: { username: { contains: $searchText } } }
          { user: { name: { contains: $searchText } } }
          { name: { contains: $searchText } }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
              }
            }
          }
          type
          description
          price {
            alternatePriceOptions
            priceType
            priceInUSD
            priceInDoge
          }
          user {
            data {
              id
              attributes {
                username
                name
                type
                email
                createdAt
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                listings {
                  data {
                    attributes {
                      listing_reports {
                        data {
                          id
                          attributes {
                            reporter {
                              data {
                                id
                              }
                            }
                          }
                        }
                      }
                      issued_listing {
                        data {
                          id
                          attributes {
                            actionType
                            issuer {
                              data {
                                id
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          store_front {
            data {
              id
              attributes {
                name
                location {
                  address
                  latitude
                  longitude
                }
              }
            }
          }
          issued_listing {
            data {
              id
              attributes {
                claim
                detail
                date
                issuer {
                  data {
                    id
                    attributes {
                      name
                      username
                      type
                      email
                      profileImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          listing_reports {
            data {
              id
              attributes {
                claim
                detail
                date
                reporter {
                  data {
                    id
                    attributes {
                      name
                      username
                      type
                      email
                      profileImage {
                        data {
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetDetailedQuarantineListings = (
  offset: number,
  limit: number,
  searchText: string
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_DETAILED_QUARANTINE_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  return {
    quarantineListings: flattenObj(data?.listings),
    quarantineListingsCount: data?.quarantineListingsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
