import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { User } from '../../../model/UserModel';
import { Typo } from '../../../components/Typo';
import Divider from '../../../components/shared/Divider';
import Pagination from '../../../components/shared/Pagination';
import Loader from '../../../components/shared/Loader';
import CheckboxField from '../../../components/shared/CheckboxField';
import UserInfo from '../../../components/shared/table/UserInfo';
import Badge from '../../../components/shared/Badge';
import Button from '../../../components/shared/Button';
import TrashIcon from '../../../components/icons/TrashIcon';
import clsx from 'clsx';
import Table from '../../../components/shared/table/Table';
import BannerUserTableHead from './BannerUserTableHead';
import EmailIcon from '../../../components/icons/EmailIcon';
import { formatDate } from '../../../util/AppUtil';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import EmptyRow from './EmptyRow';
import PrimaryActionModal from '../../../components/modal/PrimaryActionModal';
import UnbanAndWarnIcon from '../../../components/icons/UbanAndWarnIcon';
import { useUnBanUser } from '../../../hooks/settings/useUnBanUser';

type Props = {
  user: User;
  recordPerPage: number;
  loading: boolean;
  bannedUsers: any;
  bannedUsersCounts: number;
  fetchMore: (data: any) => void;
  setOffset: (value: number) => void;
  onSendEmail: (user: User) => void;
  refetch: () => void;
};

const BannedUsersTable: FC<Props> = (props) => {
  const {
    user,
    loading,
    bannedUsers,
    bannedUsersCounts,
    recordPerPage,
    fetchMore,
    setOffset,
    onSendEmail,
    refetch
  } = props;
  const unbanUser = useUnBanUser();
  const intl = useIntl();
  const [checked, setChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showUnbanModal, setShowUnbanModal] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedUsers(bannedUsers);
    } else {
      setSelectedUsers([]);
    }
  };

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * recordPerPage;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: recordPerPage }
    });
    setCurrentPage(pageNumber);
  };
  const size = 7 - bannedUsersCounts; // Ensure bannedUsers is not undefined
  const emptyUsers = size && size > 0 ? Array.from({ length: size }, (_, index) => index + 1) : [];

  const onCloseUnbanModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowUnbanModal(false);
      return;
    }
    const banUserIds = selectedUsers.map((user: any) => user?.banned_user?.banned?.id);
    await unbanUser({ bannerId: user.id, banUserIds: banUserIds }).then(() => {
      setShowUnbanModal(false);
      refetch();
    });
  };

  const onUnbanButtonClicked = (banUser: any) => {
    const exists = selectedUsers.some((user: any) => user.id === banUser.id);
    if (!exists) {
      setSelectedUsers([...selectedUsers, banUser]);
    }
    setShowUnbanModal(true);
  };

  return (
    <div className="w-full">
      <div className="w-full">
        {loading ? (
          <Loader className="h-[400px] w-full flex justify-center items-center" />
        ) : (
          <div className="min-w-full w-full overflow-x-auto  ">
            <Table>
              <BannerUserTableHead checked={checked} toggleAll={toggleAll} />
              <tbody className="divide-y divide-gray-200 bg-white">
                <>
                  {bannedUsers?.map((bannedUser: any) => {
                    const userExistsInSelectedUsers = selectedUsers.some(
                      (eachObj: any) => eachObj.id === bannedUser.id
                    );

                    return (
                      <tr
                        key={bannedUser.username}
                        className={userExistsInSelectedUsers ? 'bg-gray-50' : undefined}>
                        <td className="relative  sm:w-12 sm:px-6 py-3">
                          <div className="absolute left-6 top-1/2 -mt-3">
                            <CheckboxField
                              name="state"
                              value="check"
                              label=""
                              size="md"
                              type="checkbox"
                              roundedSize="rounded-md"
                              isSelected={userExistsInSelectedUsers}
                              onChange={(e) =>
                                setSelectedUsers(
                                  e.target.checked
                                    ? [...selectedUsers, bannedUser]
                                    : selectedUsers.filter((p: any) => p.id !== bannedUser?.id)
                                )
                              }
                              register={() => ({})}
                            />
                          </div>
                        </td>
                        <td className={clsx('whitespace-nowrap py-4 pr-3 ')}>
                          <UserInfo profileImage={bannedUser?.profileImage?.url || ''}>
                            <div className="">
                              <Typo.textSm
                                fontWeight="font-medium"
                                className="text-gray-900 capitalize">
                                {bannedUser?.name || ''}
                              </Typo.textSm>

                              <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                @{bannedUser?.username || ''}
                              </Typo.textSm>
                            </div>
                          </UserInfo>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <Typo.textSm
                            fontWeight="font-normal"
                            className="text-gray-600 capitalize">
                            {formatDate(bannedUser?.date)}
                          </Typo.textSm>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <div className="flex gap-x-1">
                            {bannedUser?.bans !== 0 && (
                              <Badge
                                theme="error"
                                label={
                                  bannedUser?.bans +
                                  ` ${intl.formatMessage({
                                    id: TRANSLATED_CONSTANTS.FLAGS.id,
                                    defaultMessage: TRANSLATED_CONSTANTS.FLAGS.defaultMessage
                                  })}`
                                }></Badge>
                            )}
                            {bannedUser?.warnings !== 0 && (
                              <Badge
                                theme="warning"
                                label={
                                  bannedUser?.warnings +
                                  ` ${intl.formatMessage({
                                    id: TRANSLATED_CONSTANTS.WARNINGS.id,
                                    defaultMessage: TRANSLATED_CONSTANTS.WARNINGS.defaultMessage
                                  })}`
                                }></Badge>
                            )}
                          </div>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">
                          <div className=" flex gap-x-3 items-center">
                            <Button
                              onClick={() => onSendEmail(bannedUser)}
                              size="md"
                              theme="gray-link"
                              icon={<EmailIcon className="h-5 w-5 text-gray-600" />}
                            />
                            <Button
                              onClick={(event: any) => {
                                event.stopPropagation();
                                onUnbanButtonClicked(bannedUser);
                              }}
                              size="md"
                              theme="gray-link"
                              icon={<TrashIcon className="h-5 w-5" />}
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                  {emptyUsers?.map((row, index) => {
                    return <EmptyRow key={index} />;
                  })}
                </>
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <Divider />
      <div className="px-6">
        <Pagination
          currentPage={currentPage}
          totalCount={bannedUsersCounts}
          pageSize={recordPerPage}
          onPageChange={paginate}
          type="centered-page-number"
        />
      </div>
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.UNBAN_MODAL_TITLE}
        isOpen={showUnbanModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseUnbanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.UNBAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.UNBAN_MODAL_CONFIRM_BUTTON}
        icon={<UnbanAndWarnIcon />}
        confirmButtonIcon={<UnbanAndWarnIcon className="text-gray-900 w-5 h-5" />}
      />
    </div>
  );
};

export default BannedUsersTable;
