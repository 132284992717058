import React, { useEffect, useState } from 'react';
import { OperationHourModel } from '../../../model/OperationHourModel';
import OperationHourItem from './OperationHourItem';
import { DropdownOption } from '../../../model/DropdownOption';
import { Hours } from '../../../constants/data';
import Tab from '../Tab';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { shiftData } from '../../../constants/data';
type PriceProps = {
  defaultValue: OperationHourModel[];
  onChange: (value: OperationHourModel[]) => void;
  className?: string;
};

const OperationHour: React.FC<PriceProps> = (props) => {
  const intl = useIntl();
  const tabs: DropdownOption[] = [
    {
      id: 1,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SELECT_HOURS.id,
        defaultMessage: TRANSLATED_CONSTANTS.SELECT_HOURS.defaultMessage
      })
    },
    {
      id: 2,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.HOURS_24.id,
        defaultMessage: TRANSLATED_CONSTANTS.HOURS_24.defaultMessage
      })
    },
    {
      id: 3,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.SKIP.id,
        defaultMessage: TRANSLATED_CONSTANTS.SKIP.defaultMessage
      })
    }
  ];

  const { defaultValue, onChange, className } = props;
  const [data, setData] = useState<OperationHourModel[]>([]);
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  const onChangeItem = (value: OperationHourModel) => {
    onChange && onChange(data?.map((item) => (item.id == value.id ? value : item)));
    setData(data?.map((item) => (item.id == value.id ? value : item)));
  };

  const onTabChange = (tab: DropdownOption) => {
    setCurrentTab(tab);
    if (tab.id == 1) {
      onChange(defaultValue?.length > 0 ? defaultValue : shiftData);
      setData(defaultValue?.length > 0 ? defaultValue : shiftData);
    } else if (tab.id == 2) {
      const tempShift =
        defaultValue?.length > 0
          ? defaultValue?.map((item) => ({
              id: item.id,
              day: item.day,
              isOpen: true,
              opensAt: Hours[0],
              closesAt: Hours[0]
            }))
          : shiftData?.map((item) => ({
              id: item.id,
              day: item.day,
              isOpen: true,
              opensAt: Hours[0],
              closesAt: Hours[0]
            }));
      onChange(tempShift);
      setData(tempShift);
    } else if (tab.id == 3) {
      onChange([]);
      setData([]);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      if (defaultValue.length == 0) {
        setCurrentTab(tabs[2]);
      } else {
        let days = 0;
        defaultValue?.map((item) =>
          item.isOpen &&
          item.closesAt.title == item.closesAt.title &&
          item.opensAt.title == '12:00 AM'
            ? days++
            : days
        );
        if (days === 7) {
          setCurrentTab(tabs[1]);
        } else {
          setCurrentTab(tabs[0]);
        }
      }
      setData(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <Tab tabs={tabs} currentTab={currentTab} type="button" onTabChange={onTabChange}></Tab>
      <div
        className={clsx(
          'w-full grid grid-cols-1 items-center justify-center gap-x-12 gap-y-6 mt-6 space-y-6 ',
          currentTab.id == 2 ? ' opacity-50' : '',
          className
        )}>
        {data?.map((item) => (
          <OperationHourItem
            key={item.id}
            onChange={onChangeItem}
            disabled={currentTab.id == 2}
            defaultValue={{
              id: item.id,
              day: item.day,
              closesAt: item.closesAt,
              opensAt: item.opensAt,
              isOpen: item.isOpen
            }}></OperationHourItem>
        ))}
      </div>
    </>
  );
};

export default OperationHour;
