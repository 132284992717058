import { gql, useMutation } from '@apollo/client';
interface ReportInput {
  variables: {
    data: {
      claim: string;
      detail: string;
      date: string | Date;
      store_front: number;
      reporter: number;
      publishedAt: string;
    };
  };
}
export const CREATE_REPORT_STOREFRONT = gql`
  mutation CreateReportListing($data: StorefrontReportInput!) {
    createStorefrontReport(data: $data) {
      data {
        id
        attributes {
          reporter {
            data {
              id
            }
          }
          store_front {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const useReportStorefront = (): ((reportListing: ReportInput) => any) => {
  const [createReportStorefrontResult] = useMutation(CREATE_REPORT_STOREFRONT);
  return createReportStorefrontResult;
};
