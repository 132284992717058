import React, { useState } from 'react';
import GenerateActivityTexts from '../../util/GenerateActivityTexts';
import { ActivityItemPropsType } from '../../model/Activity';
import Avatar from '../shared/Avatar';
import { timeAgo } from '../../util/AppUtil';
import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
import { Typo } from '../Typo';
import { useDispatch } from 'react-redux';
import { addClickedActivityData } from '../../lib/redux/reducers/report.slice';
import User1Icon from '../icons/User1Icon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import CloseIcon from '../icons/CloseIcon';

const ActivityItem: React.FC<ActivityItemPropsType> = (props) => {
  const dispatch = useDispatch();
  const [showDetails, setShowDetails] = useState(false);
  const { name, username, time, claim, detail, type, opened, userId, userImageUrl } = props;

  const onClick = () => {
    if (opened) {
      setShowDetails(!showDetails);
    } else {
      dispatch(addClickedActivityData({ clickedActivityData: props }));
    }
  };

  return (
    <div
      onClick={() => onClick()}
      className=" w-full justify-between items-start gap-3 inline-flex my-4 cursor-pointer">
      <div className="flex justify-end items-end">
        <Avatar
          image={userImageUrl}
          icon={!userImageUrl && <User1Icon className="text-gray-600" />}
          isOnline={useCheckOnlineUsers(Number(userId))}
          size="lg"
        />
      </div>
      <div className="grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex truncate ">
        <div className="self-stretch h-10 flex-col justify-start items-start flex">
          <div className="self-stretch justify-start items-center gap-2 inline-flex ">
            <Typo.textSm fontWeight="font-semibold" className="text-gray-700 ">
              {name}
            </Typo.textSm>
            <Typo.textXs fontWeight="font-normal" className="text-gray-600  truncate ">
              @{username}
            </Typo.textXs>
            <Typo.textXs fontWeight="font-normal" className="text-gray-600 ">
              {timeAgo(time)}
            </Typo.textXs>
          </div>
          <GenerateActivityTexts type={type} />
        </div>

        {showDetails ? (
          <div>
            <Typo.textSm fontWeight="font-medium" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.REASON.id}
                defaultMessage={TRANSLATED_CONSTANTS.REASON.defaultMessage}
              />
            </Typo.textSm>

            <Typo.textSm
              fontWeight="font-normal"
              className="self-stretch text-gray-600 w-full truncate mt-1">
              {claim}
            </Typo.textSm>
            <Typo.textSm fontWeight="font-medium" className="text-gray-700 mt-3">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.DESCRIPTION.id}
                defaultMessage={TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage}
              />
            </Typo.textSm>

            <Typo.textSm
              fontWeight="font-normal"
              className="self-stretch text-gray-600  w-full truncate mt-1">
              {detail}
            </Typo.textSm>
          </div>
        ) : (
          <Typo.textSm
            fontWeight="font-normal"
            className="self-stretch text-gray-600  w-full truncate">
            {detail}
          </Typo.textSm>
        )}
      </div>
      {showDetails && (
        <div className="w-2.5 h-2.5 relative">
          <CloseIcon className="h-[14px] w-[14px] text-gray-500" />
        </div>
      )}
      {/* {!opened && (
        <div className="w-2.5 h-2.5 relative">
          <div className="w-2 h-2 left-[1px] top-[1px] absolute bg-success-500 rounded-full" />
        </div>
      )} */}
    </div>
  );
};

export default ActivityItem;
