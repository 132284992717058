import React from 'react';
import Image from '../../shared/Image';
import ManJumping from '../../../assets/images/Man jumping-min.jpg';
import { Typo } from '../../Typo';
import Button from '../../shared/Button';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';

type Props = {
  onDismiss: () => void;
  onApply: () => void;
};

const BecomeModerator: React.FC<Props> = (props) => {
  const { onDismiss, onApply } = props;
  return (
    <div className="w-full px-4 sm:px-[60px] pt-8 ">
      <div className="rounded-xl border border-gray-200 shadow-sm flex flex-col sm:flex-row justify-left">
        <Image
          src={ManJumping}
          className=" max-h-[250px] rounded-t-lg sm:rounded-tr-none sm:rounded-l-lg object-cover w-full sm:w-[239px]"></Image>
        <div className="p-6">
          <Typo.textLg fontWeight="font-medium" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.BECOME_MODERATOR.id}
              defaultMessage={TRANSLATED_CONSTANTS.BECOME_MODERATOR.defaultMessage}
            />
          </Typo.textLg>
          <Typo.textSm fontWeight="font-normal" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.BECOME_MODERATOR_DESCRIPTION.id}
              defaultMessage={TRANSLATED_CONSTANTS.BECOME_MODERATOR_DESCRIPTION.defaultMessage}
            />
          </Typo.textSm>
          <div className="flex gap-x-3 mt-5">
            <Button onClick={onDismiss} size="md" theme="base">
              <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.DISMISS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.DISMISS.defaultMessage}
                />
              </Typo.textSm>
            </Button>
            <Button onClick={onApply} size="md" theme="primary-yellow">
              <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.START_APPLYING.id}
                  defaultMessage={TRANSLATED_CONSTANTS.START_APPLYING.defaultMessage}
                />
              </Typo.textSm>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BecomeModerator;
