import { gql, useQuery } from '@apollo/client';

export const FIND_OR_CREATE_CHAT = gql`
  query FindOrCreateChat($user1: ID!, $user2: ID!) {
    findOrCreateChat(input: { user1: $user1, user2: $user2 }) {
      id
      latestMessage
      publishedAt
    }
  }
`;

export const useFindOrCreateChat = (user1: number, user2: number) => {
  // Pass query and variables to useQuery
  const { loading, error, data } = useQuery(FIND_OR_CREATE_CHAT, {
    variables: { user1, user2 }
  });

  // Return loading, error and data states
  return { loading, error, chat: data?.findOrCreateChat };
};
