/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import InfoIcon from '../icons/InfoIcon';
import AlertIcon from '../icons/AlertIcon';
import clsx from 'clsx';
import { Transition } from '@headlessui/react';
import Button from './Button';
import ArrowRightIcon from '../icons/ArrowRigthIcon';
import CloseIcon from '../icons/CloseIcon';
import CheckCircleIcon from '../icons/CheckCircleIcon';
import { Typo } from '../Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useAppSelector } from '../../lib/redux/hooks';

type Props = {
  image?: string;
  title: string;
  description: string;
  firstButtonText: string;
  firstButtonLink?: string;
  secondButtonLink?: string;
  secondButtonText: string;
  type: 'welcome' | 'primary' | 'gray' | 'error' | 'warning' | 'success' | string;
  isOpen: boolean;
  onCloseAlert: () => void;
};

const colorMap: { [key: string]: any } = {
  welcome: {
    titleColor: 'text-gray-900',
    descriptionColor: 'text-gray-600',
    firstButtonTextColor: 'text-gray-600 hover:text-gray-700',
    secondButtonTextColor: 'text-yellow-700 hover:text-yellow-800',
    iconColor: 'text-gray-500',
    themeColor: 'border-gray-300 bg-white'
  },
  primary: {
    titleColor: 'text-yellow-700',
    descriptionColor: 'text-yellow-700',
    firstButtonTextColor: 'text-yellow-600 hover:text-yellow-700',
    secondButtonTextColor: 'text-yellow-700 hover:text-yellow-800',
    iconColor: 'text-yellow-500',
    themeColor: 'border-yellow-300 bg-yellow-50'
  },
  gray: {
    titleColor: 'text-gray-700',
    descriptionColor: 'text-gray-600',
    firstButtonTextColor: 'text-gray-600 hover:text-gray-700',
    secondButtonTextColor: 'text-yellow-700 hover:text-yellow-800',
    iconColor: 'text-gray-500',
    themeColor: 'border-gray-300 bg-gray-50'
  },
  error: {
    titleColor: 'text-error-700',
    descriptionColor: 'text-error-700',
    firstButtonTextColor: 'text-error-600 hover:text-error-700',
    secondButtonTextColor: 'text-error-700 hover:text-error-800',
    iconColor: 'text-error-500',
    themeColor: 'border-error-300 bg-error-50'
  },
  warning: {
    titleColor: 'text-warning-700',
    descriptionColor: 'text-warning-700',
    firstButtonTextColor: 'text-warning-600 hover:text-warning-700',
    secondButtonTextColor: 'text-warning-700 hover:text-warning-800',
    iconColor: 'text-warning-500',
    themeColor: 'border-warning-300 bg-warning-50'
  },
  success: {
    titleColor: 'text-success-700',
    descriptionColor: 'text-success-700',
    firstButtonTextColor: 'text-success-600 hover:text-success-700',
    secondButtonTextColor: 'text-success-700 hover:text-success-800',
    iconColor: 'text-success-500',
    themeColor: 'border-success-300 bg-success-50'
  }
};

const handleColors = (type: string) => {
  const defaultColor = {
    titleColor: 'text-gray-900',
    descriptionColor: 'text-gray-600',
    firstButtonTextColor: 'text-gray-600 hover:text-gray-700',
    secondButtonTextColor: 'text-yellow-700 hover:text-yellow-800',
    iconColor: 'text-gray-500'
  };

  return colorMap[type] || defaultColor;
};

const Alert: FC<Props> = ({
  title,
  description,
  firstButtonText,
  firstButtonLink,
  secondButtonText,
  secondButtonLink,
  type,
  image,
  isOpen,
  onCloseAlert
}) => {
  const isBannerOpen = useAppSelector((state) => state.alert.isBannerOpen);
  const colors = handleColors(type);

  const iconMap: { [key: string]: ReactElement } = {
    welcome: <img src={image} className="w-10 h-10" />,
    primary: <InfoIcon className="w-5 h-5 text-yellow-600" />,
    gray: <InfoIcon className="w-5 h-5 text-gray-600" />,
    error: <InfoIcon className="w-5 h-5 text-error-600" />,
    warning: <AlertIcon className="w-5 h-5 text-warning-600" />,
    success: <CheckCircleIcon className="w-5 h-5 text-success-600" />
  };

  const icon = iconMap[type];
  useEffect(() => {
    const timer = setTimeout(() => {
      onCloseAlert && onCloseAlert();
    }, 16000);

    return () => {
      clearTimeout(timer);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return (
    <div
      aria-live="assertive"
      className={`pointer-events-none fixed z-30 inset-0 flex px-4 py-6 items-start sm:p-6 ${
        isBannerOpen ? 'top-48 md:top-20 lg:top-12' : 'top-12'
      }`}>
      <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={isOpen}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="pointer-events-auto w-full max-w-[640px]  overflow-hidden  ">
            <div className={clsx(' p-4 rounded-xl  border', colors.themeColor)}>
              <div className="flex">
                <div className="flex flex-col md:flex-row">
                  <div className="flex-shrink-0 mb-3 md:mb-0">{icon}</div>
                  <div className="md:ml-3">
                    <Typo.textSm fontWeight="font-semibold" className={colors.titleColor}>
                      {title}
                    </Typo.textSm>
                    <Typo.textSm fontWeight="font-normal" className={colors.descriptionColor}>
                      {description}
                    </Typo.textSm>

                    <div className="flex gap-3 mt-3">
                      {firstButtonLink ? (
                        <Button
                          theme="gray-link"
                          to={firstButtonLink}
                          className={clsx(colors.firstButtonTextColor)}
                          size="sm">
                          <Typo.textSm fontWeight="font-semibold">{firstButtonText}</Typo.textSm>
                        </Button>
                      ) : (
                        <Button
                          theme="gray-link"
                          onClick={onCloseAlert}
                          className={clsx(colors.firstButtonTextColor)}
                          size="sm">
                          <Typo.textSm fontWeight="font-semibold">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.DISMISS.id}
                              defaultMessage={TRANSLATED_CONSTANTS.DISMISS.defaultMessage}
                            />
                          </Typo.textSm>
                        </Button>
                      )}
                      {secondButtonLink && (
                        <Button
                          size="sm"
                          className={colors.secondButtonTextColor}
                          iconPos="right"
                          icon={<ArrowRightIcon />}
                          to={secondButtonLink}>
                          <Typo.textSm fontWeight="font-semibold">{secondButtonText}</Typo.textSm>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="absolute right-9 md:relative md:right-0 md:ml-auto md:pl-3 ">
                  <Button
                    size="sm"
                    onClick={onCloseAlert}
                    className="-mt-2 -mr-2"
                    icon={<CloseIcon className={colors.iconColor}></CloseIcon>}></Button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default Alert;
