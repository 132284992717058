/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import EmptyReportListings from '../../EmptyReportListings';
import Loader from '../../../../components/shared/Loader';

import StorefrontsGridLayout from './StorefrontsGridLayout';
import { NUMBER_OF_RECORDS_PER_TABLE } from '../../../../constants/data';
import { useGetQuarantineStorefronts } from '../../../../hooks/reports/useGetQuarantineStorefronts';
import AlertHexagonIcon from '../../../../components/icons/AlertHexagonIcon';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';

const QuarantineStorefrontsGridLayout = () => {
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);

  const { quarantineStorefronts, quarantineStorefrontsCount, loading, error, refetch, fetchMore } =
    useGetQuarantineStorefronts(offset, NUMBER_OF_RECORDS_PER_TABLE, searchInput);

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {quarantineStorefronts?.length === 0 && !loading ? (
        <EmptyReportListings
          title={TRANSLATED_CONSTANTS.NO_QUARANTINE_YET}
          subtitle={TRANSLATED_CONSTANTS.NO_QUARANTINE_YET_DESCRIPTION}
          icon={<AlertHexagonIcon />}
        />
      ) : (
        <div className="w-full">
          <div className="w-full">
            {loading ? (
              <Loader className="h-[400px] w-full flex justify-center items-center" />
            ) : (
              <StorefrontsGridLayout
                pageType="listing-quarantine"
                data={quarantineStorefronts}
                listingsCount={quarantineStorefrontsCount}
                paginate={paginate}
                currentPage={currentPage}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default QuarantineStorefrontsGridLayout;
