import { AtomicBlockUtils, EditorState, RichUtils } from 'draft-js';
import { FC, useEffect, useState } from 'react';
import EditorBoldIcon from '../../../components/icons/EditorBoldIcon';
import EditorItalicIcon from '../../../components/icons/EditorItalicIcon';
import Button from '../../../components/shared/Button';
import EditorOrderedListIcon from '../../../components/icons/EditorOrderedListIcon';
import EditorUnorderedListinIcon from '../../../components/icons/EditorUnorderedListinIcon';
import InputDropdown from '../../../components/shared/location Dropdown/InputDropdown';
import ArrowDownIcon from '../../../components/icons/ArrowDownIcon';
import { DropdownOption } from '../../../model/DropdownOption';
import EditorFileIcon from '../../../components/icons/EditorFileIcon';

const headingTools: DropdownOption[] = [
  { title: 'Regular', style: 'un-styled', method: 'inline', id: 1 },
  { title: 'H1', style: 'header-one', method: 'block', id: 2 },
  { title: 'H2', style: 'header-two', method: 'block', id: 3 },
  { title: 'H3', style: 'header-three', method: 'block', id: 4 },
  { title: 'H4', style: 'header-four', method: 'block', id: 5 },
  { title: 'H5', style: 'header-five', method: 'block', id: 6 },
  { title: 'H6', style: 'header-six', method: 'block', id: 7 }
];

const toolsFirstPart = [
  {
    label: 'bold',
    style: 'BOLD',
    method: 'inline',
    icon: <EditorBoldIcon className="text-gray-400" />,
    activeIcon: <EditorBoldIcon className="text-gray-600" />
  },
  {
    label: 'italic',
    style: 'ITALIC',
    method: 'inline',
    icon: <EditorItalicIcon className="text-gray-400" />,
    activeIcon: <EditorItalicIcon className="text-gray-600" />
  }
];
const toolsSecondPart = [
  {
    label: 'Unordered-List',
    style: 'unordered-list-item',
    method: 'block',
    icon: <EditorUnorderedListinIcon className="text-gray-400" />,
    activeIcon: <EditorUnorderedListinIcon className="text-gray-600" />
  },
  {
    label: 'Ordered-List',
    style: 'ordered-list-item',
    method: 'block',
    icon: <EditorOrderedListIcon className="text-gray-400" />,
    activeIcon: <EditorOrderedListIcon className="text-gray-600" />
  }
];

const Toolbar: FC<{ editorState: any; setEditorState: any }> = ({
  editorState,
  setEditorState
}) => {
  const [selectedHeadingTool, setSelectedHeadingTool] = useState(headingTools[0]);

  const applyStyle = (e: any, style: string, method: string) => {
    e?.preventDefault();
    if (style !== 'file') {
      method === 'block'
        ? setEditorState(RichUtils.toggleBlockType(editorState, style))
        : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    } else {
    }
  };

  const isActive = (style: string, method: string) => {
    if (method === 'block') {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  useEffect(() => {
    applyStyle(null, selectedHeadingTool.style || '', selectedHeadingTool.method || '');
  }, [selectedHeadingTool]);

  const insertImage = (editorState: any, base64: any) => {
    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src: base64 });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' ');
  };

  const handleFileInput = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        // reader.result is the base64 string of the image file
        // you can pass it to your insertImage function
        const newEditorState = insertImage(editorState, reader.result);
        setEditorState(newEditorState);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="mt-3 flex flex-col md:flex-row items-center gap-2">
      <div className="w-full md:w-[240px]">
        <InputDropdown
          iconType="none"
          prefix={<ArrowDownIcon />}
          onChange={(value: any) => setSelectedHeadingTool(value)}
          defaultValue={selectedHeadingTool}
          placeholder=""
          options={headingTools}></InputDropdown>
      </div>
      <div className="flex items-center">
        {toolsFirstPart.map((item, idx) => (
          <Button
            key={idx}
            theme="gray-link"
            icon={isActive(item.style, item.method) ? item.activeIcon : item.icon}
            onClick={(e: any) => applyStyle(e, item.style, item.method)}
            onMouseDown={(e: any) => e.preventDefault()}
          />
        ))}
        <label htmlFor="file-input" className="cursor-pointer">
          <EditorFileIcon className="text-gray-400" />
        </label>
        <input
          id="file-input"
          type="file"
          accept="image/*"
          onChange={handleFileInput}
          className="hidden"
        />
        {toolsSecondPart.map((item, idx) => (
          <Button
            key={idx}
            theme="gray-link"
            icon={isActive(item.style, item.method) ? item.activeIcon : item.icon}
            onClick={(e: any) => applyStyle(e, item.style, item.method)}
            onMouseDown={(e: any) => e.preventDefault()}
          />
        ))}
      </div>
    </div>
  );
};

export default Toolbar;
