type Props = {
  className?: string;
};
const ArchiveIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.66732 9.32936C4.47598 9.32442 4.33703 9.31335 4.21211 9.2885C3.2865 9.10438 2.56293 8.38082 2.37882 7.45521C2.33398 7.22981 2.33398 6.95876 2.33398 6.41667C2.33398 5.87457 2.33398 5.60352 2.37882 5.37812C2.56293 4.45251 3.2865 3.72895 4.21211 3.54483C4.4375 3.5 4.70855 3.5 5.25065 3.5H22.7507C23.2927 3.5 23.5638 3.5 23.7892 3.54483C24.7148 3.72895 25.4384 4.45251 25.6225 5.37812C25.6673 5.60352 25.6673 5.87457 25.6673 6.41667C25.6673 6.95876 25.6673 7.22981 25.6225 7.45521C25.4384 8.38082 24.7148 9.10438 23.7892 9.2885C23.6643 9.31335 23.5253 9.32442 23.334 9.32936M11.6673 15.1667H16.334M4.66732 9.33333H23.334V18.9C23.334 20.8602 23.334 21.8403 22.9525 22.589C22.6169 23.2475 22.0815 23.783 21.4229 24.1185C20.6743 24.5 19.6942 24.5 17.734 24.5H10.2673C8.30713 24.5 7.32704 24.5 6.57835 24.1185C5.91978 23.783 5.38435 23.2475 5.04879 22.589C4.66732 21.8403 4.66732 20.8602 4.66732 18.9V9.33333Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArchiveIcon;
