import React, { useState, useRef, FC } from 'react';
import { Editor, RichUtils, convertToRaw, Entity, DefaultDraftBlockRenderMap } from 'draft-js';
import 'draft-js/dist/Draft.css';
import * as Immutable from 'immutable';
import './editor.css';
import Toolbar from './Toolbar';
import { Typo } from '../../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
const MAX_CHARS = 300;

type Props = {
  editorState: any;
  setEditorState: (editorState: any) => void;
};

const RichTextEditor: FC<Props> = ({ editorState, setEditorState }) => {
  const [remainingChars, setRemainingChars] = useState(MAX_CHARS);
  const editor = useRef<any>(null);

  const focusEditor = () => {
    if (editor.current && editor?.current.focus) {
      editor.current.focus();
    }
  };

  const handleKeyCommand = (command: any) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const imageBlockRenderer = (block: any) => {
    if (block.getType() === 'atomic') {
      const entityKey = block.getEntityAt(0);
      if (entityKey) {
        const entity = Entity.get(entityKey);
        if (entity.getType() === 'image') {
          return {
            component: Image,
            editable: false
          };
        }
      }
    }
    return null;
  };

  const Image = (props: any) => {
    const { src } = props.contentState.getEntity(props.block.getEntityAt(0)).getData();
    return <img src={src} alt="" />;
  };

  const blockRenderMap = DefaultDraftBlockRenderMap.merge(
    Immutable.Map({
      // you can add other custom block types here
      atomic: {
        element: 'div'
      }
    })
  );

  const getRemainingChars = (editorState: any) => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const plainText = contentState.getPlainText(rawContent);
    const currentChars = plainText.length;
    const remainingChars = MAX_CHARS - currentChars;
    return remainingChars;
  };

  const handleBeforeInput = () => {
    // get the current content state
    const contentState = editorState.getCurrentContent();
    // convert it to a raw object
    const rawContent = convertToRaw(contentState);
    // get the plain text from the raw object
    const plainText = rawContent.blocks.map((block) => block.text).join('\n');
    // get the current number of characters
    const currentChars = plainText.length;

    // check if the limit is reached
    if (currentChars >= MAX_CHARS) {
      // prevent adding more characters
      return 'handled';
    } else {
      // allow adding more characters
      return 'not-handled';
    }
  };
  return (
    <div className="md:w-[640px] max-w-[640px]" onClick={focusEditor}>
      <Typo.textSm fontWeight="font-normal" className="text-gray-700 mb-1.5">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.MESSAGE.id}
          defaultMessage={TRANSLATED_CONSTANTS.MESSAGE.defaultMessage}
        />
      </Typo.textSm>
      <div className="w-full rounded-lg border border-gray-300 py-[10px] px-[14px] shadow-sm min-h-[108px]">
        <Editor
          ref={editor}
          placeholder="Enter a message..."
          handleKeyCommand={handleKeyCommand}
          editorState={editorState}
          onChange={(editorState) => {
            editorState.getCurrentContent();
            setEditorState(editorState);
            const remainingChars = getRemainingChars(editorState);
            setRemainingChars(remainingChars);
          }}
          blockRendererFn={imageBlockRenderer}
          blockRenderMap={blockRenderMap}
          handleBeforeInput={handleBeforeInput}
        />
      </div>
      <Typo.textSm fontWeight="font-normal" className="mt-1.5 text-gray-500">
        {`${remainingChars} `} characters left
      </Typo.textSm>
      <Toolbar editorState={editorState} setEditorState={setEditorState} />
    </div>
  );
};

export default RichTextEditor;
