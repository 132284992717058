import LuxurySofa from '../../../assets/homepage/Luxury sofa-min.png';
import JordanShoes from '../../../assets/homepage/Jordan shoes_1-min.png';
import AvatarCursorFemaleYellow from '../../../assets/homepage/Avatar cursor female yellow.png';
import AvatarEmojeFemalePink from '../../../assets/homepage/Avatar emoji female pink.png';
import AvatarEmojeMaleBlue from '../../../assets/homepage/Avatar emoji male blue.png';
import CategoryGraphic from '../../../assets/homepage/Category graphic.png';
import { Typo } from '../../../components/Typo';
import Image from '../../../components/shared/Image';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
const BuyingAndSelling = () => {
  return (
    <div className="w-full">
      <div className="py-[96px] w-full px-4 sm:h-[800px] md:h-[850px] lg:max-w-[80rem] mx-auto lg:h-[540px] sm:pl-12 lg:pl-[0px] flex flex-col gap-y-12 lg:flex-row gap-x-24 relative ">
        <div className="relative text-left max-w-[552px]">
          <div className=" flex justify-start">
            <div className=" bg-yellow-50 flex py-1 px-3 rounded-2xl text-center">
              <Typo.textSm
                className="text-yellow-600 tracking-normal leading-5"
                fontWeight="font-medium">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.BUYING_SELLING_ON_DEGELIST.id}
                  defaultMessage={TRANSLATED_CONSTANTS.BUYING_SELLING_ON_DEGELIST.defaultMessage}
                />
              </Typo.textSm>
            </div>
          </div>
          <Typo.headingMd fontWeight="font-medium" className="mt-4 md:mt-5 text-left text-gray-900">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.BUY_SELL_ANYTHING.id}
              defaultMessage={TRANSLATED_CONSTANTS.BUY_SELL_ANYTHING.defaultMessage}
            />
          </Typo.headingMd>
          <Typo.textXl fontWeight="font-medium" className="text-gray-600 mt-6">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.BUY_SELL_ANYTHING_TITLE.id}
              defaultMessage={TRANSLATED_CONSTANTS.BUY_SELL_ANYTHING_TITLE.defaultMessage}
            />
          </Typo.textXl>
          <Typo.textXl fontWeight="font-normal" className="text-gray-600 mt-4">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.BUY_SELL_ANYTHING_DETAILS.id}
              defaultMessage={TRANSLATED_CONSTANTS.BUY_SELL_ANYTHING_DETAILS.defaultMessage}
            />
          </Typo.textXl>
        </div>
        <div>
          <div className=" relative lg:absolute sm:w-[816px] ">
            <div className="w-full flex flex-col gap-y-4 sm:flex-row gap-x-4 ">
              <div className=" relative ">
                <Image
                  className="rounded-2xl object-cover h-[228px] w-full sm:w-[400px] sm:h-[300px]"
                  src={LuxurySofa}></Image>
                <Image
                  className="h-16 w-16 object-cover z-10 absolute -bottom-[18px] -left-2 sm:-left-4 sm:-bottom-6"
                  src={AvatarEmojeFemalePink}></Image>
                <Image
                  className="h-16 w-16 object-cover absolute -top-[10px] right-[20px]"
                  src={AvatarEmojeMaleBlue}></Image>
              </div>

              <div className=" relative ">
                <Image
                  src={JordanShoes}
                  className="rounded-2xl object-cover h-[228px] w-full sm:w-[400px] sm:h-[300px] "></Image>
                <Image
                  className=" h-12 w-12 md:h-[61px] md:w-[61px] object-cover absolute bottom-[60px] z-10 -right-2 sm:left-[72px] sm:bottom-10 md:left-[51px]"
                  src={AvatarCursorFemaleYellow}></Image>
                <Image
                  className=" w-[144px] h-[182px] ms:w-[196px] md:w-[196px] md:h-[247px] object-cover absolute -bottom-[40px] right-[30px] sm:-left-[60px] sm:-bottom-[60px] md:-bottom-[95px]  md:-left-[130px]"
                  src={CategoryGraphic}></Image>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyingAndSelling;
