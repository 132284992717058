import { useState } from 'react';
import { MessageReport } from '../model/Report';
import { useDeleteMessageReport } from './reports/useDeleteMessageReport';

export const useHandleMessageReport = (refetch: any) => {
  const [selectedReports, setSelectedReports] = useState<MessageReport[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteMessageReport = useDeleteMessageReport();

  const onDeleteReportButtonClicked = (report: MessageReport) => {
    const exists = selectedReports.some((selectedReport) => selectedReport.id === report.id);
    if (!exists) {
      setSelectedReports([...selectedReports, report]);
    }
    setShowDeleteModal(true);
  };

  const onCloseDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowDeleteModal(false);
      return;
    }
    const reportIds: number[] = [];
    selectedReports.map((selectedReport) => {
      selectedReport.message_reported_user.map((messageReport) => {
        reportIds.push(Number(messageReport.id));
      });
    });
    const response = await deleteMessageReport({ reportIds: reportIds });
    if (response) {
      refetch();
    }
    setShowDeleteModal(false);
  };
  return {
    showDeleteModal,
    selectedReports,
    setSelectedReports,
    onDeleteReportButtonClicked,
    onCloseDeleteModal
  };
};
