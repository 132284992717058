import { Typo } from '../../components/Typo';
import Illustration404 from '../../components/icons/Illustration404';
import Button from '../../components/shared/Button';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ROUTES } from '../../constants/data';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showModal } from '../../lib/redux/reducers/modal.slice';

const NotFound = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  return (
    <div className=" h-screen w-full px-8 flex flex-col-reverse gap-y-8 md:flex-row md:gap-x-8 justify-center items-center">
      <div className="flex flex-col items-start max-w-xl">
        <Typo.textMd fontWeight="font-semibold" className=" text-yellow-700">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.ERROR_404.id,
            defaultMessage: TRANSLATED_CONSTANTS.ERROR_404.defaultMessage
          })}
        </Typo.textMd>
        <Typo.headingXl fontWeight="font-semibold" className=" text-gray-900 mt-3">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.PAGE_NOT_FOUND.id,
            defaultMessage: TRANSLATED_CONSTANTS.PAGE_NOT_FOUND.defaultMessage
          })}
        </Typo.headingXl>
        <Typo.textXl fontWeight="font-normal" className="text-gray-600 mt-6">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.PAGE_NOT_FOUND_DESCRIPTION.id,
            defaultMessage: TRANSLATED_CONSTANTS.PAGE_NOT_FOUND_DESCRIPTION.defaultMessage
          })}
        </Typo.textXl>
        <div className="flex flex-col w-full sm:flex-row gap-y-3 gap-x-4 mt-12">
          <Button onClick={() => dispatch(showModal('signup'))} theme="base">
            <Typo.textLg fontWeight="font-semibold">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SIGN_UP.id,
                defaultMessage: TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage
              })}
            </Typo.textLg>
          </Button>
          <Button onClick={() => navigate(ROUTES.MARKETPLACE_ROUTE)} theme="primary-yellow">
            <Typo.textLg fontWeight="font-semibold">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
                defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
              })}
            </Typo.textLg>{' '}
          </Button>
        </div>
      </div>
      <div className="max-w-xl flex items-start md:items-center w-full">
        <Illustration404 className=" w-[152px] h-[124px] md:w-[480px] md:h-[360px]"></Illustration404>
      </div>
    </div>
  );
};
export default NotFound;
