import { gql, useMutation } from '@apollo/client';
interface ReportInput {
  variables: {
    data: {
      claim: string;
      detail: string;
      date: string | Date;
      message: number;
      reporter: number;
      publishedAt: string;
      reported_user: number;
    };
  };
}

export const CREATE_MESSAGE_REPORT = gql`
  mutation CreateMessageReport($data: MessageReportInput!) {
    createMessageReport(data: $data) {
      data {
        id
      }
    }
  }
`;

export const useCreateReportMessage = (): ((reportMeessage: ReportInput) => any) => {
  const [createReportMessageResult] = useMutation(CREATE_MESSAGE_REPORT);
  return createReportMessageResult;
};
