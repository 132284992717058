import { FormattedMessage } from 'react-intl';
import { Typo } from '../components/Typo';
import PageWrapper from '../components/wrapper/PageWrapper';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';

function PrivaryPolicy() {
  return (
    <PageWrapper hasSpeicifWidth={true}>
      <div className="w-full items-center max-w-[720px] mx-auto">
        {/* main title */}
        <Typo.textMd fontWeight="font-medium" className="w-full text-center text-yellow-600">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_MAIN_TITLE.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_MAIN_TITLE.defaultMessage}
          />
        </Typo.textMd>
        {/* title 1 */}
        <Typo.headingLg fontWeight="font-semibold" className="text-gray-900 mt-3 text-center">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE1.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE1.defaultMessage}
          />
        </Typo.headingLg>
        {/* p1 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 mt-6 text-center">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P1.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P1.defaultMessage}
          />
        </Typo.textLg>
        {/* p2 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-24">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P2.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P2.defaultMessage}
          />
        </Typo.textLg>
        {/* p3 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P3.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P3.defaultMessage}
          />
        </Typo.textLg>
        {/* title 2 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE2.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE2.defaultMessage}
          />
        </Typo.headingSm>
        {/* p4 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P4.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P4.defaultMessage}
          />
        </Typo.textLg>
        {/* p5 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P5.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P5.defaultMessage}
          />
        </Typo.textLg>
        {/* title 3 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE3.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE3.defaultMessage}
          />
        </Typo.headingSm>
        {/* p6 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P6.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P6.defaultMessage}
          />
        </Typo.textLg>

        {/* title 4 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE4.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE4.defaultMessage}
          />
        </Typo.headingSm>
        {/* p7 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P7.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P7.defaultMessage}
          />
        </Typo.textLg>
        {/* title 5 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE5.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE5.defaultMessage}
          />
        </Typo.headingSm>
        {/* p8 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P8.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P8.defaultMessage}
          />
        </Typo.textLg>
        {/* title 6 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE6.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE6.defaultMessage}
          />
        </Typo.headingSm>
        {/* p9 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P9.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P9.defaultMessage}
          />
        </Typo.textLg>
        {/* title 7 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE7.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE7.defaultMessage}
          />
        </Typo.headingSm>
        {/* p10 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P10.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P10.defaultMessage}
          />
        </Typo.textLg>
        {/* title 8 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE8.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE8.defaultMessage}
          />
        </Typo.headingSm>
        {/* p11 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P11.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P11.defaultMessage}
          />
        </Typo.textLg>
        {/* p12 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P12.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P12.defaultMessage}
          />
        </Typo.textLg>
        {/* p13 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P13.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P13.defaultMessage}
          />
        </Typo.textLg>
        {/* title 9 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE9.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE9.defaultMessage}
          />
        </Typo.headingSm>
        {/* p14 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P14.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P14.defaultMessage}
          />
        </Typo.textLg>
        {/* title 10  */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE10.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE10.defaultMessage}
          />
        </Typo.headingSm>
        {/* p15 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P15.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P15.defaultMessage}
          />
        </Typo.textLg>
        {/* title 11 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE11.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE11.defaultMessage}
          />
        </Typo.headingSm>
        {/* p16 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P16.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P16.defaultMessage}
          />
        </Typo.textLg>
        {/* title 12 */}
        <Typo.headingSm fontWeight="font-medium" className="text-gray-900 mt-10">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE12.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_TITLE12.defaultMessage}
          />
        </Typo.headingSm>
        {/* p17 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P17.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P17.defaultMessage}
          />
        </Typo.textLg>
        {/* p18 */}
        <Typo.textLg fontWeight="font-normal" className="text-gray-600 text-left mt-5">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P18.id}
            defaultMessage={TRANSLATED_CONSTANTS.PRIVACY_POLICY_P18.defaultMessage}
          />
        </Typo.textLg>
      </div>
    </PageWrapper>
  );
}

export default PrivaryPolicy;
