import { useState } from 'react';
import { QuarantineListings } from '../model/Listing';
import { useRestoreQuarantine } from './reports/useRestoreQuarantine';
import { useRestoreDeleteListings } from './reports/useRestoreDeletedListings';

export const useHandleIssuedListings = (refetch: any) => {
  const [selectedListings, setSelectedListings] = useState<QuarantineListings[]>([]);
  const [showReslistItemModal, setShowRelistItemModal] = useState(false);
  const [showWarningInsteadModal, setShowWarningInsteadModal] = useState(false);

  const [isListing, setIsListing] = useState(true);
  const restoreQuarantine = useRestoreQuarantine();
  const restoreDeletedListings = useRestoreDeleteListings();
  const onRestoreButtonClicked = (listing: QuarantineListings, isListing: boolean) => {
    setIsListing(isListing);
    const exists = selectedListings.some((report) => report.id === listing.id);
    if (!exists) {
      setSelectedListings([...selectedListings, listing]);
    }
    setShowRelistItemModal(true);
  };

  const onCloseRelistModalModal = async (
    confirm: boolean,
    pageType: 'listing-deleted' | 'listing-quarantine'
  ) => {
    if (!confirm) {
      setShowRelistItemModal(confirm);
      return;
    }
    const issuerIds: number[] = [];
    const listingIds: number[] = [];
    const issuedListingIds: number[] = [];

    const queryParams: any = {};
    if (pageType === 'listing-quarantine') {
      const response = await restoreQuarantine({
        listingIds: listingIds,
        issuerIds: issuerIds,
        issuedListingIds: issuedListingIds,
        isListing: isListing
      });
      if (response) {
        refetch();
      }
    } else {
      selectedListings.map((listing: QuarantineListings) => {
        listingIds.push(Number(listing?.id));
        issuedListingIds.push(Number(listing?.issued_listing?.id));
        issuerIds.push(Number(listing?.issued_listing?.issuer?.id));
        queryParams[listing?.id] = {
          userId: listing?.user?.id,
          issuerId: listing?.issued_listing?.issuer?.id,
          issuedListingId: listing?.issued_listing?.id,
          actionType: listing?.issued_listing?.actionType,
          isListing: isListing
        };
      });
      const stringifiedQueryParam = JSON.stringify(queryParams);
      const response = await restoreDeletedListings({ queryParams: stringifiedQueryParam });
      if (response) {
        refetch();
      }
    }
    setShowRelistItemModal(false);
  };

  const onIssueWarningInsteadClicked = (listing: any) => {
    const exists = selectedListings.some((report) => report.id === listing.id);
    if (!exists) {
      setSelectedListings([...selectedListings, listing]);
    }
    setShowWarningInsteadModal(true);
  };

  const onCloseIssueWarningInsteadModal = (confirm: boolean) => {
    if (!confirm) {
      setShowWarningInsteadModal(false);
      return;
    }
  };
  return {
    selectedListings,
    setSelectedListings,
    onRestoreButtonClicked,
    showReslistItemModal,
    onCloseRelistModalModal,
    onIssueWarningInsteadClicked,
    onCloseIssueWarningInsteadModal,
    showWarningInsteadModal
  };
};
