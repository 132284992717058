import { gql, useMutation } from '@apollo/client';

interface UnBanUserInput {
  bannerId: number;
  banUserIds: [number];
}
const UN_BAN_USER = gql`
  mutation unbanUser($bannerId: ID!, $banUserIds: [ID]!) {
    unbanUser(bannerId: $bannerId, banUserIds: $banUserIds) {
      count
    }
  }
`;

export const useUnBanUser = (): ((unBanUser: UnBanUserInput) => any) => {
  const [unBanUser] = useMutation(UN_BAN_USER);
  return (input: UnBanUserInput) => unBanUser({ variables: input });
};
