import { useChangePassword } from './settings/useChangePassword';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';
import { useIntl } from 'react-intl';
import { ApolloError } from '@apollo/client/errors';
export const useHandleChangePassword = () => {
  const changePassword = useChangePassword();
  const intl = useIntl();
  const schema = yup.object().shape({
    currentPassword: yup.string(),
    password: yup
      .string()
      .required(
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.ENTER_A_PASSWORD.id,
          defaultMessage: TRANSLATED_CONSTANTS.ENTER_A_PASSWORD.defaultMessage
        })
      )
      .min(
        8,
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.id,
          defaultMessage: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.defaultMessage
        })
      )
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])/,
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.PASSWORD_VALIDATION_MESSAGE.id,
          defaultMessage: TRANSLATED_CONSTANTS.PASSWORD_VALIDATION_MESSAGE.defaultMessage
        })
      ),
    passwordConfirmation: yup.string().oneOf(
      [yup.ref('password'), ''],
      intl.formatMessage({
        id: TRANSLATED_CONSTANTS.PASSWORD_MUST_MATCH.id,
        defaultMessage: TRANSLATED_CONSTANTS.PASSWORD_MUST_MATCH.defaultMessage
      })
    ) // Check if it matches the 'password' field
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setError
  } = useForm<any>({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    try {
      const response = await changePassword(
        data.currentPassword,
        data.password,
        data.passwordConfirmation
      );
      return response;
    } catch (error) {
      if (error instanceof ApolloError) {
        const errorMessage = error.message;
        throw errorMessage;
      } else {
        throw error;
      }
    }
  };

  const onCancel = async () => {
    reset();
  };

  return { onSubmit, register, handleSubmit, onCancel, errors, setError, reset };
};
