import { useWarnDelete } from './../reports/useWarnDelete';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { ListingWithListingReport } from '../../model/Listing';
import { useBanUser } from '../reports/useBanUser';
import { AuthUserState } from '../../context/AuthUserProvider';
import { User } from '../../model/UserModel';

export const useHandleProfile = (refetch: any) => {
  const { authUser } = AuthUserState();
  const banUser = useBanUser();
  const warnUser = useWarnDelete();
  const [showBanModal, setShowBanModal] = useState(false);
  const [showWarnAndDeleteModal, setShowWarnAndDeleteModal] = useState(false);
  const [userToBeBanned, setUserToBeBanned] = useState<User>();

  const onBanUserButtonClicked = (user: User) => {
    setUserToBeBanned(user);
    setShowBanModal(true);
  };

  const onCloseBanModal = async (state: boolean) => {
    if (state && userToBeBanned) {
      const response = await banUser({
        banUserId: Number(userToBeBanned.id),
        bannerId: authUser?.id
      });
      if (response) {
        refetch();
      }
    }
    setShowBanModal(false);
  };

  const onCloseWarnAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowWarnAndDeleteModal(false);
      return;
    }
    // const listingId = selectedReports[0].id;
    // const userEmail = selectedReports[0]?.user?.email;
    // const userName = selectedReports[0]?.user?.name;
    // const warnedId = selectedReports[0]?.user?.id;

    // const response = await warnUser({
    //   listingId: Number(listingId),
    //   userName: userName || '',
    //   warnUserEmail: userEmail || '',
    //   warnerId: authUser.id,
    //   claim: reason.claim,
    //   detail: reason.detail,
    //   warnedId: warnedId
    // });
    // if (response) {
    //   refetch();
    // }
    setShowWarnAndDeleteModal(false);
  };

  const onWarnUserButtonClicked = (
    listing: ListingWithListingReport,
    claim: string,
    detail: string
  ) => {
    setShowWarnAndDeleteModal(true);
  };
  return {
    onCloseBanModal,
    showBanModal,
    onBanUserButtonClicked,
    showWarnAndDeleteModal,
    onCloseWarnAndDeleteModal,
    onWarnUserButtonClicked
  };
};
