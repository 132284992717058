import React, { FC, ReactElement } from 'react';
import FeaturedIcon from '../../components/shared/FeaturedIcon';
import FlagIcon from '../../components/icons/FlagIcon';
import { Typo } from '../../components/Typo';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import Button from '../../components/shared/Button';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import SearchIcon from '../../components/icons/SearchIcon';

type Props = {
  title?: MessageDescriptor;
  subtitle?: MessageDescriptor;
  icon?: ReactElement;
};
const EmptyReportListings: FC<Props> = ({ title, subtitle, icon }) => {
  return (
    <div className="flex items-center justify-center my-12">
      <div className="flex flex-col justify-center items-center">
        <FeaturedIcon theme="light-circle-outline" color="primary" size="xl" type="icon">
          {icon || <FlagIcon className="w-6 h-6 md:h-7 md:w-7 text-yellow-600" />}
        </FeaturedIcon>
        <Typo.textXl fontWeight="font-medium" className="text-gray-900 mt-5">
          <FormattedMessage
            id={title?.id || 'page.reports.nothing_reported_title'}
            defaultMessage={title?.defaultMessage || 'Nothing reported yet'}
          />
        </Typo.textXl>
        <Typo.textMd fontWeight="font-medium" className="text-gray-500 mt-2">
          <FormattedMessage
            id={subtitle?.id || 'page.reports.nothing_reported_subtitle'}
            defaultMessage={subtitle?.defaultMessage || 'You don’t have any flagged posts yet. '}
          />
        </Typo.textMd>
        <div className=" flex gap-x-3 items-center mt-8">
          <Button theme="base" size="lg">
            <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.MANAGE_USERS.id}
                defaultMessage={TRANSLATED_CONSTANTS.MANAGE_USERS.defaultMessage}
              />
            </Typo.textMd>
          </Button>
          <Button
            theme="primary-yellow"
            size="lg"
            to="/market-place"
            icon={<SearchIcon className="text-gray-900 w-5 h-5"></SearchIcon>}>
            <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id}
                defaultMessage={TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EmptyReportListings;
