import React from 'react';
import maleBrown from '../../../src/assets/avatar/Male avatar brown.png';
import photoFemale from '../../../src/assets/avatar/profile/Avatar profile photo female.png';
import femalePink from '../../../src/assets/avatar/Female avatar pink.png';
const AvatarGroup = () => {
  return (
    <div className="w-[120px] h-14 relative">
      <img
        className="w-12 h-12 left-0 top-[8px] absolute rounded-[200px] border border-white"
        src={maleBrown ? maleBrown : 'https://via.placeholder.com/48x48'}
      />
      <img
        className="w-12 h-12 left-[72px] top-[8px] absolute rounded-[200px] border border-white"
        src={femalePink ? femalePink : 'https://via.placeholder.com/48x48'}
      />
      <img
        className="w-14 h-14 left-[32px] top-0 absolute rounded-[200px]"
        src={photoFemale ? photoFemale : 'https://via.placeholder.com/56x56'}
      />
    </div>
  );
};

export default AvatarGroup;
