import { createSlice } from '@reduxjs/toolkit';

const directionSlice = createSlice({
  name: 'direction',
  initialState: {
    directionData: {
      current: {
        lat: 0,
        long: 0
      },
      destination: {
        lat: 0,
        long: 0,
        address: ''
      }
    }
  },
  reducers: {
    setDirectionData: (state, action) => {
      state.directionData = action.payload;
    }
  }
});

export const { setDirectionData } = directionSlice.actions;
export default directionSlice.reducer;
