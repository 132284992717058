import { gql, useMutation } from '@apollo/client';
interface LanguageProficiencyInput {
  variables: {
    data: {
      language?: number;
      level?: string;
      users_permissions_user?: number;
      publishedAt?: Date;
    };
    id: number;
  };
}

export const UPDATE_LANGUAGE = gql`
  mutation updateLanguage($data: LanguageProficiencyInput!, $id: ID!) {
    updateLanguageProficiency(data: $data, id: $id) {
      data {
        id
      }
    }
  }
`;

export const useUpdateLanguage = (): ((updateLanguage: LanguageProficiencyInput) => any) => {
  const [updateLanguageResult] = useMutation(UPDATE_LANGUAGE);

  return updateLanguageResult;
};
