import { gql, useMutation } from '@apollo/client';
interface UsersPermissionsUserEntityResponse {
  variables: {
    data: any;
    id: number;
  };
}

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;

export const useUpdateUserRole = (): ((
  updateUserRole: UsersPermissionsUserEntityResponse
) => any) => {
  const [updateUserResult] = useMutation(UPDATE_USER_ROLE);

  return updateUserResult;
};
