/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';

interface EmailListInput {
  variables: {
    data: {
      email: string;
      publishedAt: string;
    };
  };
}

export const CREATE_EMAIL_LIST = gql`
  mutation CreateEmailList($data: EmailListInput!) {
    createEmailList(data: $data) {
      data {
        id
      }
    }
  }
`;

export const useCreateEmailList = (): ((createEmailList: EmailListInput) => any) => {
  const [createMessageResult] = useMutation(CREATE_EMAIL_LIST);
  return createMessageResult;
};
