import React from 'react';
import Avatar from '../../shared/Avatar';
import { Typo } from '../../Typo';
import Flag2Icon from '../../icons/Flag2Icon';
import { getMessageDateTime } from '../../../util/AppUtil';
import { useCheckOnlineUsers } from '../../../hooks/useCheckOnlineUsers';
import User1Icon from '../../icons/User1Icon';
type Props = {
  name: string;
  profileImage: string;
  message: string;
  dateTime: string;
  userId: number;
  setShowMessageReportModal?: () => void;
};
const ReceiveMessage: React.FC<Props> = (props) => {
  const { name, profileImage, message, dateTime, userId, setShowMessageReportModal } = props;

  return (
    <div className="w-full flex items-start gap-3">
      <Avatar
        size="md"
        showState={true}
        isOnline={useCheckOnlineUsers(userId) || false}
        disabled={true}
        icon={!profileImage && <User1Icon className="text-gray-600" />}
        image={profileImage}></Avatar>
      <div className="flex flex-col gap-y-[6px] w-full">
        <div className="flex gap-2 justify-between items-center w-fit">
          <Typo.textSm fontWeight="font-medium" className="text-gray-700">
            {name}
          </Typo.textSm>
          <div className="flex gap-x-3">
            <Typo.textXs fontWeight="font-normal" className="text-gray-600">
              {getMessageDateTime(dateTime)}
            </Typo.textXs>
            <div onClick={setShowMessageReportModal}>
              <Flag2Icon className="h-3.5 w-3.5 text-gray-500 cursor-pointer"></Flag2Icon>
            </div>
          </div>
        </div>
        <div className="w-fit bg-gray-100 py-2.5 px-3.5 rounded-b-lg rounded-r-lg">
          <Typo.textMd fontWeight="font-normal" className="text-gray-800">
            {message}
          </Typo.textMd>
        </div>
      </div>
    </div>
  );
};

export default ReceiveMessage;
