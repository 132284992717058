import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_LANGUAGES = gql`
  query getLanguages {
    languages {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;

export const useGetLanguages = (checkBoxFormat = false) => {
  const { loading, error, data } = useQuery(GET_LANGUAGES, {
    fetchPolicy: 'cache-and-network'
  });
  const languages = checkBoxFormat
    ? flattenObj(data?.languages)?.map((language: any) => ({
        value: language?.id,
        label: language?.title
      }))
    : flattenObj(data?.languages);
  return {
    languages: languages,
    loading,
    error
  };
};
