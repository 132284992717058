import { gql, useMutation } from '@apollo/client';
import { GET_CHATS } from './useGetChats';

interface ChatInput {
  variables: {
    id: number;
  };
}

const DELETE_CHAT = gql`
  mutation DeleteChat($id: ID!) {
    deleteChat(id: $id) {
      data {
        id
      }
    }
  }
`;

export const useDeleteChat = (): ((deleteChat: ChatInput) => any) => {
  const [deleteChat, { loading: mutationLoading, error: mutationError }] = useMutation(
    DELETE_CHAT,
    {
      update(cache, { data: { deleteChat } }) {
        const existingChats: any = cache.readQuery({ query: GET_CHATS });
        const newChats = existingChats?.chats.filter((t: any) => t.id !== deleteChat.id);
        cache.writeQuery({
          query: GET_CHATS,
          data: { todos: newChats }
        });
        // cache.modify({
        //   fields: {
        //     chats(existingChat = [], { readField }) {
        //       return existingChat?.filter(
        //         (chat: any) => deleteChat.chat.id !== readField('id', chat)
        //       );
        //     }
        //   }
        // });
      }
    }
  );
  return deleteChat;
};
