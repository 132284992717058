import { ChevronLeftIcon } from '@heroicons/react/24/solid';
import { FC } from 'react';

type ArrowProps = {
  onClick?: () => void;
};
const PrevArrow: FC<ArrowProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="z-[2] bg-white rounded-full h-9 w-9 padding-2 flex items-center justify-center absolute top-[46%] left-4 ">
      <ChevronLeftIcon className="w-5 h-5" />
    </button>
  );
};

export default PrevArrow;
