import Avatar from './Avatar';
import { User } from '../../model/UserModel';
import React from 'react';
import User1Icon from '../icons/User1Icon';
import { Typo } from '../Typo';
import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
type Props = {
  user: User;
};
const DropdownMenuHeader: React.FC<Props> = (props) => {
  const { user } = props;
  return (
    <div className="px-4 flex w-full gap-x-3 items-center h-16 py-3 border-b border-gray-100">
      <div className="flex gap-x-3 w-full ">
        <div>
          <Avatar
            icon={!user?.profileImage?.url && <User1Icon className="text-gray-600" />}
            showState={true}
            isOnline={useCheckOnlineUsers(Number(user?.id)) || false}
            disabled={true}
            size="md"
            image={user?.profileImage?.url}></Avatar>
        </div>
        <div className=" flex flex-col font-poppins justify-center truncate">
          <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
            {user?.name ? user.name : 'Your Name'}
          </Typo.textMd>
          <Typo.textSm fontWeight="font-normal" className=" text-gray-500 truncate">
            @{user?.username}
          </Typo.textSm>
        </div>
      </div>
    </div>
  );
};

export default DropdownMenuHeader;
