/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { useGetListingReports } from '../../../../hooks/reports/useGetReportedListings';
import EmptyReportListings from '../../EmptyReportListings';
import Table from '../../../../components/shared/table/Table';
import CheckboxField from '../../../../components/shared/CheckboxField';
import clsx from 'clsx';
import UserXIcon from '../../../../components/icons/UserXIcon';
import Button from '../../../../components/shared/Button';
import TrashIcon from '../../../../components/icons/TrashIcon';
import Pagination from '../../../../components/shared/Pagination';
import Loader from '../../../../components/shared/Loader';
import UserInfo from '../../../../components/shared/table/UserInfo';
import Badge from '../../../../components/shared/Badge';
import { handleAccountTypeBadge } from '../../../../util/ListingBadgeHandler';
import { Typo } from '../../../../components/Typo';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import Divider from '../../../../components/shared/Divider';
import RedActionModal from '../../../../components/modal/RedActionModal';
import FlagIcon from '../../../../components/icons/FlagIcon';
import { ListingWithListingReport } from '../../../../model/Listing';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { handleFlagsBadge } from '../../../../util/ReportUtil';
import FlaggedListingTableHead from './FlaggedListingTableHead';
import { useDispatch } from 'react-redux';
import { addReportLayout } from '../../../../lib/redux/reducers/report.slice';
import { NUMBER_OF_RECORDS_PER_TABLE } from '../../../../constants/data';
import { useHandleListingFlaggedReports } from '../../../../hooks/useHandleListingFlaggedReports';

const FlaggedListingsGridLayout = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const [currentPage, setCurrentPage] = useState(1);
  const [offset, setOffset] = useState(0);
  const [checked, setChecked] = useState(false);

  const { loading, error, listingReports, listingReportsCount, refetch, fetchMore } =
    useGetListingReports(offset, offset + NUMBER_OF_RECORDS_PER_TABLE, searchInput);
  const {
    selectedReports,
    setSelectedReports,
    showClearFlagModal,
    showBanAndDeleteModal,
    onClearAllFlagButtonClicked,
    onBanDeleteUserButtonClicked,
    onCloseBanAndDeleteModal,
    onCloseClearAllFlagModal
  } = useHandleListingFlaggedReports(refetch);
  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: startIndex + NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedReports(listingReports);
    } else {
      setSelectedReports([]);
    }
  };

  const handleIndex = (index: number) => {
    const startIndex = (currentPage - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    return index + startIndex;
  };

  const showDetailedRecord = (index: number) => {
    dispatch(addReportLayout({ reportLayout: { type: 'detailed', index: handleIndex(index) } }));
  };
  return (
    <div className="w-full">
      {listingReports?.length === 0 && !loading ? (
        <EmptyReportListings
          title={{
            id: 'page.reports.nothing_reported_title',
            defaultMessage: 'Nothing reported yet'
          }}
          subtitle={{
            id: 'page.reports.nothing_reported_subtitle',
            defaultMessage: 'You don’t have any flagged posts yet.'
          }}
          icon={<FlagIcon className="w-6 h-6 md:h-7 md:w-7 text-yellow-600" />}
        />
      ) : (
        <div className="w-full">
          {loading ? (
            <Loader className="h-[400px] w-full flex justify-center items-center" />
          ) : (
            <div className="min-w-full w-full overflow-x-auto  ">
              <Table>
                <FlaggedListingTableHead checked={checked} toggleAll={toggleAll} />
                <tbody className="divide-y divide-gray-200 bg-white">
                  {listingReports?.map((report: ListingWithListingReport, index: number) => {
                    const accountType = handleAccountTypeBadge(report?.user?.type || '', intl);
                    const reportExistsInSelectedReports = selectedReports.some(
                      (eachObj: any) => eachObj.id === report.id
                    );

                    return (
                      <tr
                        key={report.id}
                        onClick={() => showDetailedRecord(index)}
                        className={clsx(
                          reportExistsInSelectedReports ? 'bg-gray-50' : undefined,
                          'cursor-pointer hover:bg-gray-50'
                        )}>
                        <td className="relative  sm:w-12 sm:px-6 py-3">
                          <div className="absolute left-6 top-1/2 -mt-3">
                            <div onClick={(event) => event.stopPropagation()}>
                              <CheckboxField
                                name="state"
                                value="check"
                                label=""
                                size="md"
                                type="checkbox"
                                roundedSize="rounded-md"
                                isSelected={reportExistsInSelectedReports}
                                onChange={(e) => {
                                  setSelectedReports(
                                    e.target.checked
                                      ? [...selectedReports, report]
                                      : selectedReports.filter((p: any) => p.id !== report.id)
                                  );
                                }}
                                register={() => {}}
                              />
                            </div>
                          </div>
                        </td>
                        <td className={clsx('whitespace-nowrap py-4 pr-3 ')}>
                          <UserInfo profileImage={report?.user?.profileImage?.url || ''}>
                            <div className="">
                              <Typo.textSm
                                fontWeight="font-medium"
                                className="text-gray-900 capitalize">
                                {report?.user?.name || ''}
                              </Typo.textSm>

                              <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                @{report?.user?.username || ''}
                              </Typo.textSm>
                            </div>
                          </UserInfo>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <Badge
                            hasDot={true}
                            theme={accountType.theme}
                            label={accountType.name}></Badge>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <div className="">
                            <Typo.textSm
                              fontWeight="font-medium"
                              className="text-gray-900 capitalize">
                              {report?.name}
                            </Typo.textSm>
                            <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                              @{report?.user?.username}
                            </Typo.textSm>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 ">
                          <Typo.textXs fontWeight="font-medium">
                            <Badge
                              theme="error"
                              label={handleFlagsBadge(report?.listing_reports?.length, intl)}
                            />
                          </Typo.textXs>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 ">
                          <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                            {report?.user?.email}
                          </Typo.textSm>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 ">
                          <Button
                            onClick={(event: { stopPropagation: () => void }) => {
                              event.stopPropagation();
                              onBanDeleteUserButtonClicked(report);
                            }}
                            size="md"
                            theme="gray-link"
                            icon={<UserXIcon className="h-5 w-5" />}
                          />
                        </td>
                        <td className="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">
                          <Button
                            onClick={(event: { stopPropagation: () => void }) => {
                              event.stopPropagation();
                              onClearAllFlagButtonClicked(report);
                            }}
                            size="md"
                            theme="gray-link"
                            icon={<TrashIcon className="h-5 w-5" />}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      )}
      <Divider />
      <div className="px-6">
        <Pagination
          currentPage={currentPage}
          totalCount={listingReportsCount}
          pageSize={NUMBER_OF_RECORDS_PER_TABLE}
          onPageChange={paginate}
          type="centered-page-number"
        />
      </div>
      <RedActionModal
        title={TRANSLATED_CONSTANTS.CLEAR_FLAG_MODAL_TITLE}
        isOpen={showClearFlagModal}
        onCloseRedActionModal={(value: boolean) => onCloseClearAllFlagModal(value)}
        subTitle={TRANSLATED_CONSTANTS.CLEAR_FLAG_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.CLEAR_ALL_FLAG_MODAL_CONFIRM_BUTTON}
        icon={<FlagIcon />}
      />
      <RedActionModal
        title={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_TITLE}
        isOpen={showBanAndDeleteModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE_BAN_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default FlaggedListingsGridLayout;
