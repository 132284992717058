import personalMarkerIcon from '../assets/personal-marker.png';
import wantedMarkerIcon from '../assets/wanted-marker.png';
import storefrontMarkerIcon from '../assets/storefront-marker.png';
import { Icon } from 'leaflet';

const markerIconMap: { [key: string]: string } = {
  wanted: wantedMarkerIcon,
  personal: personalMarkerIcon,
  storefrontItem: personalMarkerIcon,
  storefront: storefrontMarkerIcon
};

export const handleIcon = (type: string) => {
  const icon = new Icon({
    iconUrl: markerIconMap[type || 'storefront'],
    iconSize: [50, 50],
    iconAnchor: [25, 0]
  });
  return icon;
};
