import React, { FC } from 'react';
import { Typo } from '../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { priceOptionIconMap, priceTypeIconMap } from '.';
import MessageButton from './MessageButton';
import ReportButton from '../../components/shared/ReportButton';
import { handlePriceOptionContent, handlePriceTypeContent } from '../../util/ListingBadgeHandler';
import { ALTERNATE_PRICE_OPTION, ROLE_TYPES } from '../../constants/data';
import Price from '../../components/shared/Price';
import { useIntl } from 'react-intl';
import Button from '../../components/shared/Button';

type Props = {
  dogePrice: number;
  usdPrice: number;
  priceOption: string;
  priceType: string;
  disabled?: boolean;
  showReportModal?: (value: boolean) => void;
  openMessage?: (value: boolean) => void;
  showAction?: boolean;
  toFlags?: string;
  onQuarantine?: () => void;
  isQuarantined?: boolean;
  role?: string;
};

const PriceCard: FC<Props> = ({
  dogePrice,
  usdPrice,
  priceOption,
  priceType,
  disabled,
  showReportModal,
  openMessage,
  showAction = false,
  toFlags,
  onQuarantine,
  isQuarantined,
  role
}) => {
  const intl = useIntl();
  const priceTypeTitle = handlePriceTypeContent(priceType, intl);
  const priceOptionTitle = handlePriceOptionContent(priceOption, intl);
  return (
    <div className="p-6 bg-gray-50 w-full rounded-xl border border-gray-200 flex-col lg:h-full justify-start items-center">
      <Typo.textLg fontWeight="font-medium" className="text-gray-900 mb-2">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.PRICE.id}
          defaultMessage={TRANSLATED_CONSTANTS.PRICE.defaultMessage}
        />
      </Typo.textLg>
      <Price
        priceInUSD={usdPrice}
        priceInDoge={dogePrice}
        alternatePriceOptions={priceOption}
        className=" justify-between mb-2"
      />
      {priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE && (
        <Typo.textXl fontWeight="font-medium" className="text-gray-900 mb-2">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id}
            defaultMessage={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage}
          />
        </Typo.textXl>
      )}
      <Typo.textSm fontWeight="font-normal" className="text-gray-500 mb-5">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.PRICE_TRANSACTION_TEXT.id}
          defaultMessage={TRANSLATED_CONSTANTS.PRICE_TRANSACTION_TEXT.defaultMessage}
        />
      </Typo.textSm>
      <div className="flex gap-6">
        <div className="flex gap-2 items-center">
          {priceOptionIconMap[priceOption]}
          <Typo.textMd
            fontWeight="font-medium"
            className={` whitespace-nowrap ${
              ALTERNATE_PRICE_OPTION.DOGE_DOGE == priceOption ? ' text-yellow-600' : 'text-gray-600'
            }`}>
            {priceOption === ALTERNATE_PRICE_OPTION.NEGOTIABLE ? (
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id}
                defaultMessage={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage}
              />
            ) : (
              priceOptionTitle.title
            )}
          </Typo.textMd>
        </div>
        <div className="flex gap-2 items-center">
          {priceTypeIconMap[priceType]}
          <Typo.textMd fontWeight="font-medium" className="text-gray-600 whitespace-nowrap">
            {priceTypeTitle.title}
          </Typo.textMd>
        </div>
      </div>
      {showAction ? (
        <div className="flex gap-3 w-full mt-5">
          {(role == ROLE_TYPES.REGULAR_USER || role == ROLE_TYPES.SUPER_ADMIN) && (
            <ReportButton
              className="flex-grow"
              disabled={disabled || isQuarantined}
              openReportModal={(value: boolean) => showReportModal && showReportModal(value)}
            />
          )}

          {role === ROLE_TYPES.MODERATOR && (
            <Button
              disabled={isQuarantined}
              onClick={onQuarantine}
              className="border border-error-300 disabled:bg-gray-200 disabled:border-error-200 disabled:shadow-xs disabled:opacity-50"
              size="lg">
              <Typo.textMd fontWeight="font-semibold" className="text-error-700">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.QUARANTINE.id}
                  defaultMessage={TRANSLATED_CONSTANTS.QUARANTINE.defaultMessage}
                />
              </Typo.textMd>
            </Button>
          )}

          {role === ROLE_TYPES.ADMIN && (
            <Button
              disabled={isQuarantined}
              to={toFlags}
              className="border border-error-300 disabled:bg-gray-200 disabled:border-error-200 disabled:shadow-xs disabled:opacity-50"
              size="lg">
              <Typo.textMd fontWeight="font-semibold" className="text-error-700">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.VIEW_FLAGS.id}
                  defaultMessage={TRANSLATED_CONSTANTS.VIEW_FLAGS.defaultMessage}
                />
              </Typo.textMd>
            </Button>
          )}
          <MessageButton
            clasName="flex-grow"
            disabled={isQuarantined}
            openMessage={(value: boolean) => openMessage && openMessage(value)}
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PriceCard;
