import React, { useEffect, useState } from 'react';
import { CircleFlag } from 'react-circle-flags';
import { Typo } from '../../Typo';
import { getCountryCode } from '../../../util/AppUtil';
import CheckIcon from '../../icons/CheckIcon';
type Props = {
  placeId: string;
  description: string;
  className?: string;
  isSelected?: boolean;
};

const LocationDropdownItem: React.FC<Props> = (props) => {
  const { placeId, description, className, isSelected = false } = props;
  const [countryCode, setCountryCode] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Perform an asynchronous operation (e.g., fetching data from an API)
        const result = await getCountryCode(placeId);
        setCountryCode(result || '');
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [placeId]);
  return (
    <div
      className={`relative flex item-center w-full py-2.5 overflow-hidden pl-3.5 pr-6 cursor-pointer h-11 disabled:cursor-not-allowed bg-white  hover:bg-gray-100 ${className}`}>
      <div className="flex items-center gap-x-2 overflow-hidden ">
        <CircleFlag countryCode={countryCode} height="24" width="24" />
        <Typo.textMd
          fontWeight="font-medium"
          className="text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden ">
          {description}
        </Typo.textMd>
      </div>
      {isSelected && (
        <span className="absolute inset-y-0 right-0 flex bg-gray-100 items-center pl-1 pr-3 text-blue-gray-600">
          <CheckIcon></CheckIcon>
        </span>
      )}
    </div>
  );
};

export default LocationDropdownItem;
