import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  className?: string;
};

const Table: React.FC<Props> = (props) => {
  const { children, className } = props;
  return (
    <table className={`min-w-full table-fixed divide-y over divide-gray-300 ${className}`}>
      {children}
    </table>
  );
};

export default Table;
