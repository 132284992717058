import { RadioGroup } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Radio } from '../../model/Radio';
import { Typo } from '../Typo';
import FeaturedIcon from './FeaturedIcon';

type Props = {
  selectedOption?: Radio;
  onChange: (selected: Radio) => void;
  options?: Radio[];
  className?: string;
  [x: string]: any;
};

const RadioGroupComponent: React.FC<Props> = (props) => {
  const { selectedOption, options = [], onChange, className = '' } = props;
  const [selectedItem, setSelectedItem] = useState<Radio | undefined>(selectedOption);

  useEffect(() => {
    if (selectedOption) {
      options?.map((option: Radio) => {
        if (selectedOption.id == option.id) setSelectedItem(option);
      });
    } else if (options?.length > 1) {
      setSelectedItem(options[0]);
    }
  }, [selectedOption]);

  return (
    <RadioGroup value={selectedItem} onChange={onChange}>
      <div className={` inline-block w-full flex-col space-y-4 ${className}`}>
        {options?.map((option) => (
          <RadioGroup.Option
            key={option.id}
            value={option}
            className={({ checked }: any) =>
              clsx(
                checked || selectedItem?.id == option.id
                  ? ' border-2 border-yellow-600 bg-yellow-50 focus:ring-4 focus:ring-yellow-300 focus:shadow-xs'
                  : 'border border-gray-200 bg-white disabled:opacity-50 hover:border-yellow-500 ',
                ' relative group flex cursor-pointer rounded-xl p-4 focus:outline-none w-full '
              )
            }>
            {({ checked }: any) => (
              <>
                <span className="flex flex-1">
                  <span className="flex flex-col">
                    <div className=" flex flex-col sm:flex-row gap-y-4 gap-x-4">
                      <div className=" flex items-center">
                        {option?.image ? (
                          <img src={option?.image} className=" max-h-[46px] max-w-[120px]"></img>
                        ) : option?.icon ? (
                          <FeaturedIcon size="md" color="primary" theme="light-circle-outline">
                            {option.icon}
                          </FeaturedIcon>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        <RadioGroup.Label as="span">
                          <Typo.textMd
                            fontWeight="font-medium"
                            className={clsx(
                              checked || selectedItem?.id == option.id
                                ? ' text-yellow-800 '
                                : ' text-gray-700',
                              'block'
                            )}>
                            {option?.title}
                            <span
                              className={clsx(
                                checked || selectedItem?.id == option.id
                                  ? ' text-yellow-600 '
                                  : ' text-gray-600',
                                'font-normal'
                              )}>
                              {option?.type && `(${option?.type})`}
                            </span>
                          </Typo.textMd>
                        </RadioGroup.Label>
                        <RadioGroup.Description as="span">
                          <Typo.textMd
                            fontWeight="font-normal"
                            className={clsx(
                              checked ? ' text-yellow-700 ' : ' text-gray-600',
                              ' mt-[2px] '
                            )}>
                            {option?.description}
                          </Typo.textMd>
                        </RadioGroup.Description>
                        <RadioGroup.Description as="span">
                          <Typo.textSm
                            fontWeight="font-normal"
                            className={clsx(checked ? ' text-yellow-600 ' : ' text-gray-400')}>
                            {option?.message}
                          </Typo.textSm>
                        </RadioGroup.Description>
                      </div>
                    </div>
                  </span>
                </span>
                <span
                  className={clsx(
                    checked || selectedItem?.id == option.id
                      ? 'h-5 w-5 bg-yellow-600 inline-block p-0 rounded-full group-focus:ring-4 group-focus:ring-yellow-300 group-focus:shadow-xs group-hover:bg-yellow-700'
                      : ' w-[17px] visible bg-white rounded-full border border-gray-200 group-hover:border-yellow-600 group-hover:bg-yellow-100 absolute top-[17px] right-[17px]',
                    'flex items-center justify-center '
                  )}
                  aria-hidden="true">
                  <CheckIcon
                    className={clsx(
                      checked || selectedItem?.id == option.id
                        ? 'h-4 w-4 fill-white visible'
                        : ' invisible'
                    )}></CheckIcon>
                </span>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default RadioGroupComponent;
