import React, { FC } from 'react';
import User1Icon from '../../icons/User1Icon';
import Avatar from '../Avatar';
type Props = {
  profileImage: string;
  isOnline?: boolean;
  children: React.ReactElement;
  size?: 'lg' | 'md';
  showState?: boolean;
};
const UserInfo: FC<Props> = ({
  profileImage,
  isOnline,
  children,
  size = 'lg',
  showState = false
}) => {
  return (
    <div className="flex w-full items-center gap-3">
      <Avatar
        image={profileImage ? profileImage : undefined}
        icon={!profileImage ? <User1Icon className="text-gray-500" /> : undefined}
        showState={showState}
        size={size}
        isOnline={isOnline}
      />
      {children}
    </div>
  );
};

export default UserInfo;
