import { gql, useMutation } from '@apollo/client';

interface SavedListingInput {
  variables: {
    id: number;
  };
}

const DELETE_SAVED_LISTING = gql`
  mutation DeleteSavedListing($id: ID!) {
    deleteSavedListing(id: $id) {
      data {
        id
        attributes {
          __typename
        }
      }
    }
  }
`;

export const useUnsaveListing = (): ((unsaveListing: SavedListingInput) => any) => {
  const [deleteFavoriteListing, { loading, error }] = useMutation(DELETE_SAVED_LISTING);
  return deleteFavoriteListing;
};
