import React from 'react';
import Button from './Button';
import { Typo } from '../Typo';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import ArrowLeftIcon from '../icons/ArrowLeftIcon';
type Props = {
  className?: string;
  onBackNavClick?: () => void;
};
const BackNavigation: React.FC<Props> = (props) => {
  const { className, onBackNavClick } = props;
  const intl = useIntl();
  return (
    <Button
      type="button"
      onClick={onBackNavClick}
      theme="gray-link"
      className={clsx('text-gray-800', className)}
      icon={<ArrowLeftIcon></ArrowLeftIcon>}>
      <Typo.textSm fontWeight="font-semibold">
        {intl.formatMessage({ id: 'shared.back', defaultMessage: 'Back' })}
      </Typo.textSm>
    </Button>
  );
};

export default BackNavigation;
