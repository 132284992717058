import { DEFAULT_LAT_LNG, DEFAULT_LOCATION_BOUNDS } from './../../../constants/data';
import { createSlice } from '@reduxjs/toolkit';

const location = localStorage.getItem('locationData');
const usableLocation = location && JSON.parse(location);

const initialState = {
  toBeCleared: false,
  searchText: '',
  filterModal: {
    searchText: '',
    categories: [],
    locationData: usableLocation?.location || null,
    locationBounds: usableLocation?.locationBound || DEFAULT_LOCATION_BOUNDS,
    moreFilter: null,
    address: '',
    isFirstValue: false
  },
  latLng: DEFAULT_LAT_LNG
};
const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    addFilter: (state, action) => {
      state.toBeCleared = action.payload.state;
      state.searchText = action.payload.searchText;
    },
    addLatLng: (state, action) => {
      state.latLng = action?.payload?.latLng;
    },
    addFilterModal: (state, action) => {
      state.filterModal = action.payload.filterModal;
    }
  }
});

export const { addFilter, addFilterModal, addLatLng } = filterSlice.actions;
export default filterSlice.reducer;
