import { Navigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useGetMe } from '../hooks/user/useGetMe';
import { useGetUser } from '../hooks/user/useGetUser';
import Loader from '../components/shared/Loader';
import { ROLES, ROUTES } from '../constants/data';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../lib/redux/reducers/modal.slice';

interface AuthContextType {
  // user: any;
  getMe: () => any;
  getUser: () => any;
  signin: () => void;
  signout: () => void;
  meRefetch: () => void;
  userRefetch: () => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);

export function useAuth() {
  return React.useContext(AuthContext);
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { meLoading, meError, me, meRefetch } = useGetMe();
  const { userLoading, userError, user, userRefetch } = useGetUser('0');
  const signout = async () => {
    localStorage.removeItem('token');
    meRefetch();
    userRefetch();
  };

  const signin = () => {
    meRefetch();
  };

  useEffect(() => {
    if (me) {
      userRefetch({ id: me?.id });
    }
  }, [me]);

  const getMe = () => {
    return me;
  };

  const getUser = () => {
    return user;
  };

  const value = { getMe, meError, userError, getUser, signin, signout, meRefetch, userRefetch };

  if (meLoading || userLoading) {
    return <Loader className=" h-screen" />;
  }
  return (
    <AuthContext.Provider value={value}>
      <>{children}</>
    </AuthContext.Provider>
  );
}

// RequireAuthn is a component that requires the user to be logged in. If the user is not logged in,
// it will redirect to /login.
export function RequireAuthn({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();

  if (!auth.getMe()) {
    return <Navigate to={ROUTES.NOT_FOUND_ROUTE} state={{ from: location }} replace />;
  }

  return children;
}

// RequireAuthn is a component that requires the user to be logged in. If the user is not logged in,
// it will redirect to /login.
export const RequireAdminAndSuperAdmin = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();
  const me = auth.getMe();
  if (me?.role?.name !== ROLES.SUPER_ADMIN && me?.role?.name !== ROLES.ADMIN) {
    return <Navigate to={ROUTES.NOT_FOUND_ROUTE} />;
  }
  return children;
};

export function RequireAuthnMember({ children }: { children: JSX.Element }) {
  const auth = useAuth();
  const location = useLocation();
  const dispatch = useDispatch();
  if (!auth?.getMe()) {
    dispatch(showModal('signup'));
    return <Navigate to={ROUTES.HOME_ROUTE} state={{ from: location }} replace />;
  }
  if (auth?.getUser() && !auth?.getUser()?.profileCompleted) {
    dispatch(showModal('completeAccountToCreateListing'));
    return <Navigate to={ROUTES.HOME_ROUTE} state={{ from: location }} replace />;
  } else {
    dispatch(hideModal('completeAccountToCreateListing'));
  }

  return children;
}
