type Props = {
  className?: string;
};
const AwardIcon = ({ className }: Props) => {
  return (
    <svg
      width="18"
      height="18"
      className={`text-yellow-500 ${className}`}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.90149 11.595L5.25 16.5L8.6913 14.4352C8.80354 14.3679 8.85966 14.3342 8.91957 14.321C8.97256 14.3094 9.02744 14.3094 9.08043 14.321C9.14034 14.3342 9.19646 14.3679 9.3087 14.4352L12.75 16.5L12.0989 11.5928M12.3194 3.18666C12.4352 3.4668 12.6576 3.68947 12.9375 3.80573L13.9192 4.21236C14.1993 4.32841 14.4219 4.551 14.538 4.83116C14.654 5.11132 14.654 5.42611 14.538 5.70627L14.1316 6.68726C14.0155 6.96755 14.0154 7.28265 14.132 7.5628L14.5376 8.54351C14.5952 8.68227 14.6248 8.83101 14.6248 8.98122C14.6248 9.13143 14.5953 9.28018 14.5378 9.41896C14.4803 9.55774 14.396 9.68383 14.2898 9.79003C14.1836 9.89623 14.0575 9.98045 13.9187 10.0379L12.9377 10.4442C12.6576 10.5601 12.4349 10.7824 12.3187 11.0624L11.912 12.0441C11.796 12.3242 11.5734 12.5468 11.2933 12.6629C11.0131 12.7789 10.6983 12.7789 10.4182 12.6629L9.43722 12.2565C9.15706 12.1408 8.8424 12.141 8.56242 12.2572L7.58075 12.6632C7.30076 12.779 6.98626 12.7789 6.70633 12.663C6.42641 12.547 6.20395 12.3247 6.08783 12.0448L5.68109 11.0628C5.56526 10.7827 5.34293 10.56 5.06298 10.4438L4.08131 10.0371C3.80128 9.92113 3.57877 9.69869 3.46268 9.41869C3.3466 9.13869 3.34644 8.82405 3.46225 8.54393L3.86857 7.56293C3.98433 7.28276 3.98409 6.96809 3.86792 6.6881L3.46217 5.70569C3.40465 5.56693 3.37502 5.41819 3.375 5.26798C3.37498 5.11777 3.40455 4.96902 3.46203 4.83024C3.51951 4.69146 3.60378 4.56537 3.71001 4.45917C3.81624 4.35297 3.94235 4.26875 4.08114 4.21131L5.0621 3.80497C5.34199 3.68923 5.56452 3.46718 5.68086 3.18753L6.08748 2.20583C6.20352 1.92567 6.4261 1.70308 6.70625 1.58704C6.9864 1.47099 7.30118 1.47099 7.58133 1.58704L8.56229 1.99338C8.84245 2.10914 9.15711 2.1089 9.43709 1.99272L10.4192 1.58767C10.6993 1.47168 11.014 1.47171 11.2941 1.58773C11.5742 1.70375 11.7967 1.92628 11.9128 2.20637L12.3195 3.18836L12.3194 3.18666Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AwardIcon;
