import clsx from 'clsx';
import { FC } from 'react';

type IndicatorsProps = {
  count: number;
  activeSlide: number;
};

const Indicators: FC<IndicatorsProps> = ({ count, activeSlide }) => {
  return (
    <div className={clsx('absolute bottom-4 left-0 w-full flex justify-center ', 'z-[2] ')}>
      <div className="bg-white w-fit flex gap-3 justify-center items-center p-2 rounded-xl bg-opacity-90">
        {Array.from({ length: count }, (_, index) => (
          <div
            key={index}
            className={clsx(
              'h-2 w-2 rounded-full bg-gray-300 ',
              'transform transition-all duration-100',
              {
                'bg-gray-900': activeSlide === index
              }
            )}></div>
        ))}
      </div>
    </div>
  );
};

export default Indicators;
