import { gql, useMutation } from '@apollo/client';

interface StorefrontReportsInput {
  storefrontIds: number[];
  reporterIds: number[];
}
const CLEAR_STOREFRONT_FLAGS = gql`
  mutation DeleteListingReports($storefrontIds: [ID!], $reporterIds: [ID!]) {
    deleteReportStorefrontsByStorefront(storefrontIds: $storefrontIds, reporterIds: $reporterIds) {
      count
    }
  }
`;

export const useClearStorefrontFlags = (): ((
  deleteStorefrontReports: StorefrontReportsInput
) => any) => {
  const [deleteStorefrontReports] = useMutation(CLEAR_STOREFRONT_FLAGS);
  return (input: StorefrontReportsInput) => deleteStorefrontReports({ variables: input });
};
