import React from 'react';
import ClockIcon from '../../components/icons/ClockIcon';
import { Typo } from '../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

const StoreHoursCard = () => {
  return (
    <div className="p-6 bg-gray-50 w-full rounded-xl border border-gray-200 flex-col justify-start items-center lg:h-full">
      <div className="w-12 h-12 p-3 bg-white rounded-[10px] border-gray-200 shadow border  justify-center items-center inline-flex mb-5">
        <ClockIcon className="w-6 h-6 text-gray-700" />
      </div>
      <Typo.textLg fontWeight="font-medium" className="mb-1 text-gray-900">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.STORE_HOURS.id}
          defaultMessage={TRANSLATED_CONSTANTS.STORE_HOURS.defaultMessage}
        />
      </Typo.textLg>
      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.STORE_HOURS_DESCRIPTION.id}
          defaultMessage={TRANSLATED_CONSTANTS.STORE_HOURS_DESCRIPTION.defaultMessage}
        />
      </Typo.textSm>
    </div>
  );
};

export default StoreHoursCard;
