import React, { useState } from 'react';
import { User } from '../../model/UserModel';
import { ApiSearchOrderEnum } from '../../util/enum/enum';
import {
  LISTING_BREAKPOINT,
  LIST_PAGES,
  NUMBER_OF_ITEMS_PER_PAGE,
  ROUTES
} from '../../constants/data';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useGetSavedListings } from '../../hooks/listing/useGetSavedListings';
import DashboardSubHeader from '../../components/shared/Dashboard Header/DashboardSubHeader';
import SearchIcon from '../../components/icons/SearchIcon';
import EmptyListing from '../../components/dashboard/card/EmptyListing';
import HeartIcon from '../../components/icons/HeartIcon';
import Pagination from '../../components/shared/Pagination';
import StorefrontItemCard from '../../components/shared/ListingCard/StorefrontItemCard';
import StorefrontCard from '../../components/shared/ListingCard/StorefrontCard';
import ListingCard from '../../components/shared/ListingCard/ListingCard';
import { useHandleSave } from '../../hooks/useHandleSave';
type Props = {
  user?: User;
};
const SavedListings: React.FC<Props> = (props) => {
  const { user } = props;
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const [offset, setOffset] = useState({ listing: 0, storefront: 0 });
  const {
    listings = [],
    listingCount,
    storefronts = [],
    storeFrontsCount,
    loading,
    fetchMore,
    refetch
  } = useGetSavedListings(
    user?.id + '',
    offset.listing,
    offset.storefront,
    NUMBER_OF_ITEMS_PER_PAGE,
    ApiSearchOrderEnum.PUBLISHED_DESC
  );
  const { save } = useHandleSave(refetch);

  const onPageChange = (pageNumber: number) => {
    const listingStartIndex = (pageNumber - 1) * listings.length;
    const storefrontStartIndex = (pageNumber - 1) * storefronts.length;
    setOffset({
      listing: listingStartIndex,
      storefront: storefrontStartIndex
    });
    fetchMore({
      variables: {
        listingOffset: listingStartIndex,
        storefrontOffset: storefrontStartIndex,
        limit: NUMBER_OF_ITEMS_PER_PAGE
      }
    });
    setCurrentPage(pageNumber);
  };
  const mapData = [...(listings || []), ...(storefronts || [])];
  return (
    <div className="w-full">
      <DashboardSubHeader
        title={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.SAVED_LISTINGS.id,
          defaultMessage: TRANSLATED_CONSTANTS.SAVED_LISTINGS.defaultMessage
        })}
        description={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.SAVED_LISTINGS_DESCRIPTION.id,
          defaultMessage: TRANSLATED_CONSTANTS.SAVED_LISTINGS_DESCRIPTION.defaultMessage
        })}
        amount={listingCount + storeFrontsCount || 0}
        confirmButtonIcon={<SearchIcon />}
        confirmButtonTitle={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
          defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
        })}
        onConfrimClick={() => navigate(ROUTES.MARKETPLACE_ROUTE)}></DashboardSubHeader>
      <div className="w-full py-6  space-y-4">
        {mapData?.length == 0 || loading ? (
          <EmptyListing
            title={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NOTHING_SAVED_LISTING.id,
              defaultMessage: TRANSLATED_CONSTANTS.NOTHING_SAVED_LISTING.defaultMessage
            })}
            description={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NOTHING_SAVED_LISTING_DESCRIPTION.id,
              defaultMessage: TRANSLATED_CONSTANTS.NOTHING_SAVED_LISTING_DESCRIPTION.defaultMessage
            })}
            to={ROUTES.MARKETPLACE_ROUTE}
            icon={<HeartIcon />}
            btnIcon={<SearchIcon />}
            loadding={loading}
            btnTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
              defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
            })}></EmptyListing>
        ) : (
          mapData?.map((data: any, index: any) => {
            if (data?.type === 'personal' || data?.type === 'wanted') {
              return (
                <ListingCard
                  cardClickable={true}
                  key={index}
                  listing={data}
                  page={LIST_PAGES.MAP}
                  size={LISTING_BREAKPOINT.DEFAULT}
                  onLike={save}></ListingCard>
              );
            } else if (data?.type === 'storefrontItem') {
              return (
                <StorefrontItemCard
                  cardClickable={true}
                  key={index}
                  listing={data}
                  storeFront={data.storefront}
                  onLike={save}
                  page={LIST_PAGES.SAVED}
                  size={LISTING_BREAKPOINT.DEFAULT}></StorefrontItemCard>
              );
            } else {
              return (
                <StorefrontCard
                  key={index}
                  cardClickable={true}
                  storeFront={data}
                  onLike={save}
                  page={LIST_PAGES.SAVED}
                  size={LISTING_BREAKPOINT.DEFAULT}
                />
              );
            }
          })
        )}

        <Pagination
          currentPage={currentPage}
          totalCount={listingCount + storeFrontsCount}
          pageSize={NUMBER_OF_ITEMS_PER_PAGE}
          onPageChange={onPageChange}
          type="centered-page-number"
        />
      </div>
    </div>
  );
};

export default SavedListings;
