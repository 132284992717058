import clsx from 'clsx';
import { FC } from 'react';

type Props = {
  type: string;
};

const GenerateActivityTexts: FC<Props> = ({ type }) => {
  const activityList = ['banned', 'admin', 'moderator', 'quarantine', 'delete_warning'];
  return (
    <div className="self-stretch text-[14px] leading-tight">
      <span
        key="text"
        className={clsx({
          'text-gray-600  font-normal text-[14px] font-inter':
            type === 'delete_ban' || activityList.includes(type),
          'text-yellow-700  font-medium text-[14px] font-inter': [
            'report_message',
            'report_listing'
          ].includes(type)
        })}>
        {
          {
            message: 'Sent a message',
            banned: 'Is now ',
            admin: 'Is now an ',
            moderator: 'Is now a ',
            report_message: 'Reported ',
            report_listing: 'Flagged ',
            quarantine: 'Issued a ',
            delete_warning: 'Issued a ',
            delete_ban: 'Issued a '
          }[type]
        }
      </span>

      <span
        key="highlight"
        className={clsx({
          'text-yellow-700  font-medium font-inter text-[14px]': activityList.includes(type),
          'text-gray-600  font-normal': ['report_message', 'report_listing'].includes(type),
          'font-medium text-error-700': type === 'delete_ban'
        })}>
        {
          {
            banned: 'Banned',
            admin: 'Admin',
            moderator: 'Moderator',
            report_message: 'a message',
            report_listing: 'a listing',
            quarantine: 'Quarantine',
            delete_warning: 'Warning & deletion',
            delete_ban: 'Ban & deletion'
          }[type]
        }
      </span>
    </div>
  );
};

export default GenerateActivityTexts;
