import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_DELETED_LISTINGS = gql`
  query getDeletedListings($offset: Int, $limit: Int, $searchText: String) {
    deletedListingsCount: listings(
      filters: {
        issued_listing: { actionType: { ne: "quarantine" } }
        or: [
          { user: { username: { contains: $searchText } } }
          { user: { name: { contains: $searchText } } }
          { name: { contains: $searchText } }
        ]
      }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    listings(
      pagination: { limit: $limit, start: $offset }
      sort: "user.id:desc"
      filters: {
        issued_listing: { actionType: { ne: "quarantine" } }
        or: [
          { user: { username: { contains: $searchText } } }
          { user: { name: { contains: $searchText } } }
          { name: { contains: $searchText } }
        ]
      }
    ) {
      data {
        id
        attributes {
          name
          issued_listing {
            data {
              id
              attributes {
                claim
                detail
                date
                actionType
                issuer {
                  data {
                    id
                  }
                }
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
                name
                type
                email
                createdAt
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetDeletedListings = (offset: number, limit: number, searchText: string) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_DELETED_LISTINGS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  return {
    deletedListings: flattenObj(data?.listings),
    deletedListingsCount: data?.deletedListingsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
