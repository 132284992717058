export const getCurrentDate = () => {
  const now = new Date();
  const utc = new Date(now.getTime() + now.getTimezoneOffset());
  return utc?.toISOString();
};

export const getCurrentDateFormatted = (date?: Date) => {
  const currentDate = date ? date : new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are zero-based
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
