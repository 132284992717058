import React, { ChangeEvent, useEffect, useState } from 'react';
import { User } from '../../model/UserModel';
import PlusIcon from '../../components/icons/PlusIcon';
import Pagination from '../../components/shared/Pagination';
import { ApiSearchOrderEnum } from '../../util/enum/enum';
import { debounce } from 'lodash';
import {
  LISTING_BREAKPOINT,
  LIST_PAGES,
  NUMBER_OF_ITEMS_PER_PAGE,
  ROUTES
} from '../../constants/data';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useGetStorefrontListings } from '../../hooks/listing/useGetStorefrontListing';
import StorefrontItemCard from '../../components/shared/ListingCard/StorefrontItemCard';
import StorefrontCard from '../../components/shared/ListingCard/StorefrontCard';
import EmptyListing from '../../components/dashboard/card/EmptyListing';
import TagIcon from '../../components/icons/TagIcon';
import { useGetStorefronts } from '../../hooks/storfront/useGetStorefronts';
import { DropdownOption } from '../../model/DropdownOption';
import InputField from '../../components/shared/InputField';
import SearchIcon from '../../components/icons/SearchIcon';
import InputDropdown from '../../components/shared/location Dropdown/InputDropdown';
import Button from '../../components/shared/Button';
import { Typo } from '../../components/Typo';
import StorefrontMoreFilter from '../../components/shared/StorefrontMoreFilter';
import { useDispatch } from 'react-redux';
import { setShareData } from '../../lib/redux/reducers/share.slice';
import RedActionModal from '../../components/modal/RedActionModal';
import TrashIcon from '../../components/icons/TrashIcon';
import { useDeleteListing } from '../../hooks/listing/useDeleteListing';
import { useUpdateListing } from '../../hooks/listing/useUpdateListing';
import DashboardSubHeader from '../../components/shared/Dashboard Header/DashboardSubHeader';
import { useArchiveListing } from '../../hooks/listing/useArchiveListing';
type Props = {
  user?: User;
};

type StorefrontType = {
  id: number;
  name: string;
};

const Storefronts: React.FC<Props> = (props) => {
  const { user } = props;
  const intl = useIntl();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedStorefront, setSelectedStorefront] = useState<DropdownOption>();
  const [storefrontOptions, setStorefrontOptions] = useState<DropdownOption[]>([]);
  const [searchText, setSearchText] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [eventLoading, setEventLoading] = useState(false);
  const [listingId, setListingId] = useState(0);
  // const [storefrontId, setStorefronId] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { storeFronts } = useGetStorefronts(user?.id + '', -1, 0);
  const deleteListing = useArchiveListing();
  const updateListing = useUpdateListing();
  const [offset, setOffset] = useState({ listing: 0, storefront: 0 });
  const {
    listings,
    storefrontItemsCount,
    storefronts,
    storeFrontsCount,
    loading,
    fetchMore,
    refetch
  } = useGetStorefrontListings(
    offset.listing,
    offset.storefront,
    NUMBER_OF_ITEMS_PER_PAGE,
    ApiSearchOrderEnum.PUBLISHED_DESC,
    '',
    user?.id
  );
  const onPageChange = async (pageNumber: number) => {
    setEventLoading(true);
    const listingStartIndex = (pageNumber - 1) * listings.length;
    const storefrontStartIndex = (pageNumber - 1) * storefronts.length;
    setOffset({
      listing: listingStartIndex,
      storefront: storefrontStartIndex
    });
    fetchMore({
      variables: {
        listingOffset: listingStartIndex,
        storefrontOffset: storefrontStartIndex,
        limit: NUMBER_OF_ITEMS_PER_PAGE
      }
    });
    setCurrentPage(pageNumber);
    setEventLoading(false);
  };

  const debouncedSearch = debounce((value: string, storefrontId: number | undefined) => {
    setOffset({
      listing: 0,
      storefront: 0
    });
    refetch({
      listingOffset: 0,
      storefrontOffset: 0,
      limit: NUMBER_OF_ITEMS_PER_PAGE,
      order: ApiSearchOrderEnum.PUBLISHED_DESC,
      title: value,
      userId: user?.id,
      storefrontId: storefrontId
    });
    setCurrentPage(1);
  }, 500); // Delay in milliseconds, e.g., 500ms

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { value } = e.target;
    const storeId = selectedStorefront?.id !== 0 ? Number(selectedStorefront?.id) : undefined;
    setSearchText(value);
    debouncedSearch(value, storeId || undefined); // Call the debounced function with the input value
  };

  const onStorefrontChange = (value: DropdownOption) => {
    setSelectedStorefront(value);
    const storeId = value?.id !== 0 ? Number(value?.id) : undefined;
    debouncedSearch(searchText, storeId || undefined);
  };

  const onClear = () => {
    setSelectedStorefront({
      id: 0,
      title: intl.formatMessage({
        id: TRANSLATED_CONSTANTS.ALL.id,
        defaultMessage: TRANSLATED_CONSTANTS.ALL.defaultMessage
      })
    });
    setSearchText('');
    debouncedSearch('', undefined);
  };

  const onDelete = async (state: boolean) => {
    setEventLoading(true);
    setShowDeleteModal(false);
    if (listingId !== 0) {
      if (state) {
        await deleteListing({ variables: { id: listingId, data: { deleted: true } } }).then(
          async (response: any) => {
            if (response) {
              await onPageChange(listings.length > 1 ? currentPage : currentPage - 1);
            }
          }
        );
      }
    }
    setListingId(0);
    setEventLoading(false);
  };

  useEffect(() => {
    if (storeFronts && storefrontOptions?.length === 0) {
      const options = storeFronts.map((storefront: StorefrontType) => ({
        id: storefront.id,
        title: storefront.name
      }));
      setStorefrontOptions([
        {
          id: 0,
          title: intl.formatMessage({
            id: TRANSLATED_CONSTANTS.ALL.id,
            defaultMessage: TRANSLATED_CONSTANTS.ALL.defaultMessage
          })
        },
        ...options
      ]);
      setSelectedStorefront({
        id: 0,
        title: intl.formatMessage({
          id: TRANSLATED_CONSTANTS.ALL.id,
          defaultMessage: TRANSLATED_CONSTANTS.ALL.defaultMessage
        })
      });
    }
  }, [storeFronts]);

  const onMarkAsSold = async (id: number) => {
    setEventLoading(true);
    await updateListing({ variables: { data: { isSold: true }, id: id } }).then((res: any) => {
      if (res) {
        refetch();
      }
    });
    setEventLoading(false);
  };

  const mapData = [...(listings || []), ...(storefronts || [])];

  return (
    <div className="w-full">
      <DashboardSubHeader
        title={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.YOUR_STOREFRONT.id,
          defaultMessage: TRANSLATED_CONSTANTS.YOUR_STOREFRONT.defaultMessage
        })}
        description={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.YOUR_STOREFRONT_DESCRIPTION.id,
          defaultMessage: TRANSLATED_CONSTANTS.YOUR_STOREFRONT_DESCRIPTION.defaultMessage
        })}
        amount={storefrontItemsCount + storeFrontsCount || 0}
        confirmButtonIcon={<PlusIcon />}
        confirmButtonTitle={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.CREATE_A_STOREFRONT.id,
          defaultMessage: TRANSLATED_CONSTANTS.CREATE_A_STOREFRONT.defaultMessage
        })}
        cancelButtonTitle={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.CREATE_LISTING.id,
          defaultMessage: TRANSLATED_CONSTANTS.CREATE_LISTING.defaultMessage
        })}
        cancelDisabled={storeFronts?.length == 0}
        onCancelClick={() => navigate(ROUTES.CREATE_LISTING_ROUTE)}
        onConfrimClick={() => navigate(ROUTES.CREATE_STOREFRONT_ROUTE)}></DashboardSubHeader>
      <div className="w-full space-y-6 py-6">
        <div className="bg-gray-50 flex flex-col gap-y-4 md:flex-row justify-between items-end p-5 rounded-xl">
          <div className="flex flex-col w-full md:w-auto sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3">
            <InputField
              name="search"
              label={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SEARCH_STOREFRONT_OR_LISTING.id,
                defaultMessage: TRANSLATED_CONSTANTS.SEARCH_STOREFRONT_OR_LISTING.defaultMessage
              })}
              type="text"
              value={searchText}
              placeholder={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SEARCH.id,
                defaultMessage: TRANSLATED_CONSTANTS.SEARCH.defaultMessage
              })}
              onChange={onSearch}
              className="w-full md:w-60"
              prefix={<SearchIcon className="text-gray-400" />}
              register={() => ({})}
            />
            <div className="w-full min-w-[192px] hidden sm:block">
              <InputDropdown
                onChange={onStorefrontChange}
                defaultValue={selectedStorefront}
                label={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.STOREFRONT.id,
                  defaultMessage: TRANSLATED_CONSTANTS.STOREFRONT.defaultMessage
                })}
                options={storefrontOptions}></InputDropdown>
            </div>
            <div className="w-full min-w-[192px] block sm:hidden">
              <StorefrontMoreFilter
                className="w-full"
                btnClassName="w-full"
                onFilter={onStorefrontChange}
                selectedStorefront={selectedStorefront}
                storefronts={storefrontOptions}
              />
            </div>
          </div>
          <Button
            onClick={onClear}
            size="lg"
            theme="base"
            className=" hidden sm:block w-full md:w-fit mb-1">
            <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
              {intl.formatMessage({
                id: TRANSLATED_CONSTANTS.CLEAR_ALL.id,
                defaultMessage: TRANSLATED_CONSTANTS.CLEAR_ALL.defaultMessage
              })}
            </Typo.textMd>
          </Button>
        </div>

        {mapData?.length == 0 || loading || eventLoading ? (
          <EmptyListing
            title={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NO_STOREFRONTS_YET.id,
              defaultMessage: TRANSLATED_CONSTANTS.NO_STOREFRONTS_YET.defaultMessage
            })}
            description={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NO_STOREFRONTS_YET_CONTENT.id,
              defaultMessage: TRANSLATED_CONSTANTS.NO_STOREFRONTS_YET_CONTENT.defaultMessage
            })}
            to={ROUTES.CREATE_STOREFRONT_ROUTE}
            icon={<TagIcon />}
            btnIcon={<PlusIcon />}
            loadding={loading || eventLoading}
            btnTitle={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.id,
              defaultMessage: TRANSLATED_CONSTANTS.CREATE_STOREFRONT.defaultMessage
            })}></EmptyListing>
        ) : (
          mapData
            ?.sort(
              (a: any, b: any) =>
                new Date(a.publishedAt).getTime() - new Date(b.publishedAt).getTime()
            )
            .map((data: any, index: any) => {
              if (data?.type === 'storefrontItem') {
                return (
                  <StorefrontItemCard
                    cardClickable={true}
                    key={index}
                    listing={data}
                    onEdit={() => navigate(`${ROUTES.EDIT_LISTING_ROUTE}/${data.id}`)}
                    onDelete={(id: number) => {
                      setListingId(id);
                      setShowDeleteModal(true);
                    }}
                    onMarkAsSold={(id: number) => onMarkAsSold(id)}
                    onShare={() => {
                      dispatch(
                        setShareData({
                          isOpen: true,
                          url: `${ROUTES.LISTING_DETAILS_ROUTE}/${data.id}`
                        })
                      );
                    }}
                    storeFront={data.storefront}
                    page={LIST_PAGES.DASHBOARD}
                    size={LISTING_BREAKPOINT.DEFAULT}></StorefrontItemCard>
                );
              } else {
                return (
                  <StorefrontCard
                    key={index}
                    cardClickable={true}
                    onEdit={() => navigate(`${ROUTES.EDIT_STOREFRONT_ROUTE}/${data.id}`)}
                    onDelete={(id: number) => {
                      // setStorefronId(id);
                      setShowDeleteModal(true);
                    }}
                    onShare={() => {
                      dispatch(
                        setShareData({
                          isOpen: true,
                          url: `${ROUTES.EDIT_STOREFRONT_ROUTE}/${data.id}`
                        })
                      );
                    }}
                    storeFront={data}
                    page={LIST_PAGES.DASHBOARD}
                    size={LISTING_BREAKPOINT.DEFAULT}
                  />
                );
              }
            })
        )}

        <Pagination
          currentPage={currentPage}
          totalCount={storefrontItemsCount + storeFrontsCount || 0}
          pageSize={NUMBER_OF_ITEMS_PER_PAGE}
          onPageChange={onPageChange}
          type="centered-page-number"
        />
        <RedActionModal
          title={TRANSLATED_CONSTANTS.LISTING_DELETE_MODAL_TITLE}
          isOpen={showDeleteModal}
          onCloseRedActionModal={(value: boolean) => onDelete(value)}
          subTitle={TRANSLATED_CONSTANTS.LISTING_DELETE_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.DELETE_LISTING}
          icon={<TrashIcon></TrashIcon>}
          hasButtonIcon={true}
        />
      </div>
    </div>
  );
};

export default Storefronts;
