import { DropdownOption } from '../model/DropdownOption';
import { LocationBound } from '../model/Location';
import { OperationHourModel } from '../model/OperationHourModel';

export const CreateAccountSteps: DropdownOption[] = [
  {
    id: 1,
    title: 'Choose account type',
    supportingTitle: 'Are you a private seller or a business?'
  },
  { id: 2, title: 'Create user profile', supportingTitle: 'Enter your information' },
  { id: 3, title: 'Add your location', supportingTitle: 'Where are you located?' },
  { id: 4, title: 'Congratulations', supportingTitle: 'You’re all done!' }
];

export enum LISTING_TYPES {
  SELLING = 'personal',
  BUYING = 'wanted',
  STOREFRONT_ITEM = 'storefrontItem'
}

export enum ROLE_TYPES {
  ADMIN = 'admin',
  SUPER_ADMIN = 'super-admin',
  MODERATOR = 'moderator',
  REGULAR_USER = 'regular-user',
  USER = 'USER'
}

export enum ROUTES {
  HOME_ROUTE = '/',
  MASTER_PAGE_ROUTE = 'master-page',
  MASTER_COMPONENT_ROUTE = 'component-master-page',
  CREATE_ACOUNT_ROUTE = '/complete-profile',
  LOGIN_ROUTE = '/login',
  SIGN_UP_ROUTE = '/sign-up',
  FORGOT_PASSWORD_ROUTE = '/forgot-password',
  RESET_PASSWORD_ROUTE = '/reset-password',
  EMAIL_VERIFICATION_ROUTE = '/email-varification',
  EMAIL_CONFIRMATION_ROUTE = '/email-confirmation',
  DASHBOARD_ROUTE = '/dashboard',
  CREATE_STOREFRONT_ROUTE = '/dashboard/create-storefront',
  EDIT_STOREFRONT_ROUTE = '/dashboard/edit-storefront',
  CREATE_LISTING_ROUTE = '/dashboard/create-listing',
  EDIT_LISTING_ROUTE = '/dashboard/edit-listing',
  SETTING_ROUTE = '/dashboard/settings',
  MARKETPLACE_ROUTE = '/market-place',
  DOGELIST_PRIVACY_POLICY_ROUTE = '/privacy-policy',
  DOGELIST_TERM_OF_SERVICE = '/terms-service',
  ABOUT_ROUTE = '/about',
  NOT_FOUND_ROUTE = '*',
  LISTING_DETAILS_ROUTE = '/listing',
  STOREFRONT_DETAILS_ROUTE = '/storefront',
  STOREFRONT_ITEM_DETAILS_ROUTE = '/storefront-item',
  AUTH_PROVIDER_ROUTE = '/connect/:providerName/redirect',
  LOGOUT_ROUTE = '/logout',
  LISTING = 'LISTING',
  LEADER_BOARD = '/dashboard/leaderboard',
  PROFILE = '/dashboard/profile',
  LOCATION_MEETUP_ROUTE = '/dashboard/location-meet-up',
  CHANGE_PASSWORD_ROUTE = '/dashboard/change-passwrod',
  NOTIFICATIONS_ROUTE = '/dashboard/notifications',
  BLOCKED_USERS_ROUTE = '/dashboard/blocked-users',
  BANNED_USERS_ROUTE = '/dashboard/banned-users',
  ADMIN_SETTINGS_ROUTE = '/dashboard/admin-settings',
  MODERATOR_SETTINGS_ROUTE = '/dashboard/moderator-settings',
  REFERRAL_HISTORY_ROUTE = '/dashboard/referral-history',
  REPORTS = '/reports'
}

export const BREADCRUMB_ROUTES = [
  {
    name: '',
    pathName: '/'
  },
  {
    name: 'Map',
    pathName: '/market-place'
  },
  {
    name: 'Listing',
    pathName: '/listing/:id'
  },
  {
    name: 'Storefront',
    pathName: '/storefront/:id'
  },
  {
    name: 'Storefront item',
    pathName: '/storefront-item/:id'
  },
  { name: 'Dashboard', pathName: '/dashboard' },
  { name: 'Create storefront', pathName: '/dashboard/create-storefront' },
  {
    name: 'Edit storefront',
    pathName: '/dashboard/edit-storefront'
  },
  { name: 'Create listing', pathName: '/dashboard/create-listing' },
  { name: 'Edit listing', pathName: '/dashboard/edit-listing' },
  { name: 'Settings', pathName: '/dashboard/settings' },
  { name: 'Privacy policy', pathName: '/privacy-policy' },
  { name: 'Terms of service', pathName: '/terms-service' },
  { name: 'About', pathName: '/about' },
  { name: 'Settings', pathName: '/dashboard/profile' },
  { name: 'Settings', pathName: '/dashboard/notifications' },
  { name: 'Settings', pathName: '/dashboard/referral-history' },
  { name: 'Settings', pathName: '/dashboard/change-passwrod' },
  { name: 'Settings', pathName: '/dashboard/blocked-users' },
  { name: 'Settings', pathName: '/dashboard/location-meet-up' },
  { name: 'Settings', pathName: '/dashboard/admin-settings' },
  { name: 'Settings', pathName: '/dashboard/moderator-settings' },
  { name: 'Reports', pathName: '/reports' }
];
// remove it
export enum LIST_TYPE {
  STOREFRONT = 'storefront',
  LISTING = 'listing',
  WANTED = 'wanted',
  SAVED = 'saved'
}

export enum ACCOUNT_TYPE {
  PERSONAL = 'personal_seller',
  BUSINESS = 'business'
}
export enum LIST_PAGES {
  MAP = 'map',
  SAVED = 'saved',
  ARCHIVE = 'archive',
  DASHBOARD = 'dashboard',
  SLIDE = 'slide'
}

export enum ISSUE_LISTING_ACTION_TYPE {
  DELETE_WARNING = 'delete_warning',
  DELETE_BAN = 'delete_ban',
  QUARANTINE = 'quarantine',
  DELETE = 'delete'
}

export enum LISTING_CARD_TYPES {
  STOREFRONT = 'storefront',
  STOREFRONT_ITEM = 'storefrontItem',
  WANTED = 'wanted',
  PERSONAL = 'personal'
}

export enum LISTING_BREAKPOINT {
  DEFAULT = 'default',
  MOBILE = 'mobile',
  SLIDE = 'slide'
}

export enum ALTERNATE_PRICE_OPTION {
  DOGE_DOGE = 'Doge_Doge',
  NEGOTIABLE = 'Negotiable_no_price'
}

export enum PRICE_OPTIONS {
  PRICE_FIRM = 'Price_is_firm',
  OPEN_FOR_OFFERS = 'Open_for_offers'
}

export enum AUTHENTICATION_TYPE {
  LOGING = 'login',
  SIGUNUP = 'signup'
}

export const Hours: DropdownOption[] = [
  { id: 1, title: '12:00 AM' },
  { id: 2, title: '1:00 AM' },
  { id: 3, title: '2:00 AM' },
  { id: 4, title: '3:00 AM' },
  { id: 5, title: '4:00 AM' },
  { id: 6, title: '5:00 AM' },
  { id: 7, title: '6:00 AM' },
  { id: 8, title: '7:00 AM' },
  { id: 9, title: '8:00 AM' },
  { id: 10, title: '9:00 AM' },
  { id: 11, title: '10:00 AM' },
  { id: 12, title: '11:00 AM' },
  { id: 13, title: '12:00 PM' },
  { id: 14, title: '1:00 PM' },
  { id: 15, title: '2:00 PM' },
  { id: 16, title: '3:00 PM' },
  { id: 17, title: '4:00 PM' },
  { id: 18, title: '5:00 PM' },
  { id: 19, title: '6:00 PM' },
  { id: 20, title: '7:00 PM' },
  { id: 21, title: '8:00 PM' },
  { id: 22, title: '9:00 PM' },
  { id: 23, title: '10:00 PM' },
  { id: 24, title: '11:00 PM' }
];

export const Days: DropdownOption[] = [
  { id: 1, title: '1' },
  { id: 2, title: '2' },
  { id: 3, title: '3' },
  { id: 4, title: '4' },
  { id: 5, title: '5' },
  { id: 6, title: '6' },
  { id: 7, title: '7' },
  { id: 8, title: '8' },
  { id: 9, title: '9' },
  { id: 10, title: '10' },
  { id: 11, title: '11' },
  { id: 12, title: '12' },
  { id: 13, title: '13' },
  { id: 14, title: '14' },
  { id: 15, title: '15' },
  { id: 16, title: '16' },
  { id: 17, title: '17' },
  { id: 18, title: '18' },
  { id: 19, title: '19' },
  { id: 20, title: '20' },
  { id: 21, title: '21' },
  { id: 22, title: '22' },
  { id: 23, title: '23' },
  { id: 24, title: '24' },
  { id: 25, title: '25' },
  { id: 26, title: '26' },
  { id: 27, title: '27' },
  { id: 28, title: '28' },
  { id: 29, title: '29' },
  { id: 30, title: '30' },
  { id: 31, title: '31' }
];

export const Months: DropdownOption[] = [
  { id: 0, title: 'January' },
  { id: 1, title: 'February' },
  { id: 2, title: 'March' },
  { id: 3, title: 'April' },
  { id: 4, title: 'May' },
  { id: 5, title: 'June' },
  { id: 6, title: 'July' },
  { id: 7, title: 'August' },
  { id: 8, title: 'September' },
  { id: 9, title: 'October' },
  { id: 10, title: 'November' },
  { id: 11, title: 'December' }
];

export const Years: DropdownOption[] = [
  { id: 2024, title: '2024' },
  { id: 2023, title: '2023' },
  { id: 2022, title: '2022' },
  { id: 2021, title: '2021' },
  { id: 2020, title: '2020' },
  { id: 2019, title: '2019' },
  { id: 2018, title: '2018' },
  { id: 2017, title: '2017' },
  { id: 2016, title: '2016' },
  { id: 2015, title: '2015' },
  { id: 2014, title: '2014' },
  { id: 2013, title: '2013' },
  { id: 2012, title: '2012' },
  { id: 2011, title: '2011' },
  { id: 2010, title: '2010' },
  { id: 2009, title: '2009' },
  { id: 2008, title: '2008' },
  { id: 2007, title: '2007' },
  { id: 2006, title: '2006' },
  { id: 2005, title: '2005' },
  { id: 2004, title: '2004' },
  { id: 2003, title: '2003' },
  { id: 2002, title: '2002' },
  { id: 2001, title: '2001' },
  { id: 2000, title: '2000' },
  { id: 1999, title: '1999' },
  { id: 1998, title: '1998' },
  { id: 1997, title: '1997' },
  { id: 1996, title: '1996' },
  { id: 1995, title: '1995' },
  { id: 1994, title: '1994' },
  { id: 1993, title: '1993' },
  { id: 1992, title: '1992' },
  { id: 1991, title: '1991' },
  { id: 1990, title: '1990' },
  { id: 1989, title: '1989' },
  { id: 1988, title: '1988' },
  { id: 1987, title: '1987' },
  { id: 1986, title: '1986' },
  { id: 1985, title: '1985' },
  { id: 1984, title: '1984' },
  { id: 1983, title: '1983' },
  { id: 1982, title: '1982' },
  { id: 1981, title: '1981' },
  { id: 1980, title: '1980' },
  { id: 1979, title: '1979' },
  { id: 1978, title: '1978' },
  { id: 1977, title: '1977' },
  { id: 1976, title: '1976' },
  { id: 1975, title: '1975' },
  { id: 1974, title: '1974' },
  { id: 1973, title: '1973' },
  { id: 1972, title: '1972' },
  { id: 1971, title: '1971' },
  { id: 1970, title: '1970' },
  { id: 1969, title: '1969' },
  { id: 1968, title: '1968' },
  { id: 1967, title: '1967' },
  { id: 1966, title: '1966' },
  { id: 1965, title: '1965' },
  { id: 1964, title: '1964' },
  { id: 1963, title: '1963' },
  { id: 1962, title: '1962' },
  { id: 1961, title: '1961' },
  { id: 1960, title: '1960' },
  { id: 1959, title: '1959' },
  { id: 1958, title: '1958' },
  { id: 1957, title: '1957' },
  { id: 1956, title: '1956' },
  { id: 1955, title: '1955' },
  { id: 1954, title: '1954' },
  { id: 1953, title: '1953' },
  { id: 1952, title: '1952' },
  { id: 1951, title: '1951' },
  { id: 1950, title: '1950' },
  { id: 1949, title: '1949' },
  { id: 1948, title: '1948' },
  { id: 1947, title: '1947' },
  { id: 1946, title: '1946' },
  { id: 1945, title: '1945' },
  { id: 1944, title: '1944' },
  { id: 1943, title: '1943' },
  { id: 1942, title: '1942' },
  { id: 1941, title: '1941' },
  { id: 1940, title: '1940' },
  { id: 1939, title: '1939' },
  { id: 1938, title: '1938' },
  { id: 1937, title: '1937' },
  { id: 1936, title: '1936' },
  { id: 1935, title: '1935' },
  { id: 1934, title: '1934' },
  { id: 1933, title: '1933' },
  { id: 1932, title: '1932' },
  { id: 1931, title: '1931' },
  { id: 1930, title: '1930' }
];

export const languages: DropdownOption[] = [
  { id: 'English', title: 'English' },
  { id: 'Spanish', title: 'Spanish' },
  { id: 'Chinese', title: 'Chinese' },
  { id: 'Urdu', title: 'Urdu' },
  { id: 'Arabic', title: 'Arabic' },
  { id: 'Italian', title: 'Italian' },
  { id: 'German', title: 'German' },
  { id: 'Turkish', title: 'Turkish' },
  { id: 'Russian', title: 'Russian' },
  { id: 'Japanese', title: 'Japanese' },
  { id: 'Korean', title: 'Korean' }
];

export const languageLevels: DropdownOption[] = [
  { id: 'Fluent', title: 'Fluent' },
  { id: 'Conversational', title: 'Conversational' },
  { id: 'Elementary', title: 'Elementary' }
];

export const getHourObject = (title: string) => {
  const selectedHours = Hours.filter((hour) => hour.title == title);
  return selectedHours[0];
};

// operation hour shift data
export const shiftData: OperationHourModel[] = [
  { id: 1, day: 'Sunday', isOpen: false, opensAt: Hours[9], closesAt: Hours[17] },
  { id: 2, day: 'Monday', isOpen: true, opensAt: Hours[9], closesAt: Hours[17] },
  { id: 3, day: 'Tuesday', isOpen: true, opensAt: Hours[9], closesAt: Hours[17] },
  { id: 4, day: 'Wednesday', isOpen: true, opensAt: Hours[9], closesAt: Hours[17] },
  { id: 5, day: 'Thursday', isOpen: true, opensAt: Hours[9], closesAt: Hours[17] },
  { id: 6, day: 'Friday', isOpen: true, opensAt: Hours[9], closesAt: Hours[17] },
  { id: 7, day: 'Saturday', isOpen: false, opensAt: Hours[9], closesAt: Hours[17] }
];

export const NUMBER_OF_ITEMS_PER_PAGE = 9;
export const NUMBER_OF_RECORDS_PER_TABLE = 7;
export const NUMBER_OF_ITEMS_COMMAND_MEMU = 6;

export const DEFAULT_LOCATION_BOUNDS: LocationBound = {
  minLat: -90,
  maxLat: 90,
  minLng: -180,
  maxLng: 180
};

export const DEFAULT_LAT_LNG = {
  lat: 0,
  lng: 0,
  zoom: 2
};
export const CLICKED_ZOOM = 12;
export const LOCATIONDROPDOWN_ZOOM = 6;
export const ROLES = {
  REGULAR_USER: 'regular-user',
  ADMIN: 'admin',
  SUPER_ADMIN: 'super-admin',
  MODERATOR: 'moderator'
};

export enum REPORT_PAGES {
  LISTING_FLAG = 'listing-flag',
  LISTING_QUARANTINE = 'listing-quarantine',
  LISTING_DELETED_WARNED = 'listing-deleted-warned',
  LISTING_DELETED_BANED = 'listing-deleted-baned',
  LISTING_DELETED = 'listing-deleted',
  MESSAGE_REPORT = 'message-report'
}
export enum ALERT_TYPE {
  DEFAULT = '',
  WELCOME = 'welcome',
  PRIMARY = 'primary',
  GRAY = 'gray',
  ERROR = 'error',
  SUCCESS = 'success',
  WARNING = 'warning'
}
