import { FC, useState } from 'react';
import { Storefront } from '../../../model/Storefront';
// import { Popup } from 'react-map-gl';
import CustomPopup from './CustomPopup';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';
import './map.css';

type Props = {
  listings: any;
};

const PopupSlider: FC<Props> = ({ listings }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const nextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex === (listings?.length ?? 0) - 1 ? 0 : prevIndex + 1));
  };

  const prevSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex === 0 ? (listings?.length ?? 0) - 1 : prevIndex - 1));
  };

  return (
    <div className="relative popup-shadow">
      <div className="overflow-hidden relative">
        <div
          className="flex transition-transform ease-in-out duration-500 swiper-cards"
          style={{ transform: `translateX(-${slideIndex * 100}%)` }}>
          {listings?.map((listing: any) => (
            <div className="w-[200px] sm:w-[240px] swiper-slide" key={listing.id}>
              <CustomPopup listing={listing} />
            </div>
          ))}
        </div>
      </div>
      {listings?.length > 1 && (
        <>
          <NextArrow nextSlide={nextSlide} />
          <PrevArrow prevSlide={prevSlide} />
        </>
      )}
    </div>
  );
};

export default PopupSlider;

type NextArrowProps = {
  nextSlide?: any;
};

const NextArrow: FC<NextArrowProps> = ({ nextSlide }) => {
  return (
    <button
      onClick={nextSlide}
      className="arrows-contaier absolute top-1/2 -right-10 transform -translate-y-1/2 bg-white rounded-full h-7 w-7 padding-2 flex items-center justify-center  absolute top-[46%] -right-12 ">
      <ChevronRightIcon className="w-4 h-4" />
    </button>
  );
};

type PrevArrowProps = {
  prevSlide?: any;
};
const PrevArrow: FC<PrevArrowProps> = ({ prevSlide }) => {
  return (
    <button
      onClick={prevSlide}
      className="arrows-contaier absolute top-1/2 -left-10 transform -translate-y-1/2 z-[2] bg-white rounded-full h-7 w-7 padding-2 flex items-center justify-center absolute top-[46%] -left-12 ">
      <ChevronLeftIcon className="w-4 h-4" />
    </button>
  );
};
