import { FC } from 'react';
import CheckboxField from '../../../components/shared/CheckboxField';
const EmptyRow: FC = () => {
  return (
    <tr className="h-[72px]">
      <td className="relative  sm:w-12 sm:px-6 py-3">
        <div className="absolute left-6 top-1/2 -mt-3">
          <CheckboxField
            name="state"
            value="check"
            label=""
            size="md"
            type="checkbox"
            roundedSize="rounded-md"
            disabled={true}
            register={() => ({})}
          />
        </div>
      </td>
    </tr>
  );
};

export default EmptyRow;
