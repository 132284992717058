import React, { FC } from 'react';
import { ActivityItemPropsType } from '../../../model/Activity';
import UserInfo from '../../../components/shared/table/UserInfo';
import { Typo } from '../../../components/Typo';
import { timeAgo } from '../../../util/AppUtil';
import { FormattedMessage } from 'react-intl';

type Props = {
  profileImage: string;
  name: string;
  username: string;
  messageTime: string;
  message: string;
  userId: string;
};

const MessageCard: FC<Props> = ({ profileImage, name, username, messageTime, message, userId }) => {
  return (
    <div className="flex flex-col md:flex-row gap-8 justify-between items-start">
      <div className="w-[280px] min-w-[280px]">
        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
          <FormattedMessage id="page.report.message.title" defaultMessage="Reported messages" />
        </Typo.textSm>
        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
          <FormattedMessage
            id="page.report.message.subtitle"
            defaultMessage="All reported & team messages are displayed here."
          />
        </Typo.textSm>
      </div>
      <div className="w-full  flex justify-start">
        <div className="p-4 bg-gray-50 rounded-lg w-full md:w-[360px]">
          <div className="flex justify-between items-start">
            <div className="">
              <UserInfo profileImage={profileImage || ''} size="md" showState={true}>
                <div className="w-full">
                  <div className="flex  gap-2">
                    <Typo.textSm fontWeight="font-semibold" className="text-gray-700 capitalize">
                      {name}
                    </Typo.textSm>
                  </div>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                    @{username}
                  </Typo.textSm>
                </div>
              </UserInfo>
            </div>
            <div className="w-fit">
              <Typo.textSm fontWeight="font-normal" className="text-gray-600 w-fit">
                {timeAgo(messageTime)}
              </Typo.textSm>
            </div>
          </div>
          <Typo.textSm fontWeight="font-normal" className="text-gray-600 mt-4">
            {message}
          </Typo.textSm>
        </div>
      </div>
    </div>
  );
};

export default MessageCard;
