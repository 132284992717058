export const customizeUsersBanned = (users: any) => {
  const customizedUsersBanned = users?.map((user: any) => {
    let bans = 0;
    let warnings = 0;
    user?.listings?.map((listing: any) => {
      bans += listing?.listing_reports.length;
      warnings += listing?.issued_listing ? 1 : 0;
    });
    user.date = user?.banned_user?.date;
    user.bans = bans;
    user.warnings = warnings;
    return user;
  });
  return customizedUsersBanned;
};
