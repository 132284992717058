import { FC } from 'react';
import { NavigationModel } from '../../model/Navigation';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { Typo } from '../Typo';
type Props = {
  navigation?: NavigationModel;
  current?: boolean;
};
const NavLink: FC<Props> = (props) => {
  const { navigation, current = false } = props;
  return (
    <Link to={navigation?.link || '/'}>
      <button
        type="button"
        className={clsx(
          'px-3 py-2 text-gray-700 hover:text-gray-500 rounded-md focus:ring-[3px] focus:ring-gray-300',
          { ' text-yellow-700': current }
        )}>
        <Typo.textMd fontWeight="font-medium">{navigation?.title}</Typo.textMd>
      </button>
    </Link>
  );
};

export default NavLink;
