/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { FC, createContext, useContext, useState } from 'react';
import { MessageNotification } from '../model/Message';
type Props = {
  children: React.ReactElement;
};

type ChatNotificationContextType = {
  selectedChat: any;
  setSelectedChat: (params: any) => void;
  onlineUsers: any;
  setOnlineUsers: (params: any) => void;
  notification: any;
  setNotification: (params: any) => void;
  chats: any;
  setChats: (params: any) => void;
  receiverId: string;
  setReceiverId: (params: string) => void;
  notifications: MessageNotification[];
  setNotifications: (params: any) => void;
  socket: any;
  setSocket: (params: any) => void;
  chatId: any;
  setChatId: (params: any) => void;
};

const ChatNotificationContext = createContext<ChatNotificationContextType>(null!);

const ChatNotificationProvider: FC<Props> = ({ children }) => {
  const [selectedChat, setSelectedChat] = useState();
  const [onlineUsers, setOnlineUsers] = useState();
  const [notification, setNotification] = useState([]);
  const [chats, setChats] = useState();
  const [receiverId, setReceiverId] = useState('');
  const [socket, setSocket] = useState();
  const [notifications, setNotifications] = useState<MessageNotification[]>([]);
  const [chatId, setChatId] = useState();

  return (
    <ChatNotificationContext.Provider
      value={{
        selectedChat,
        setSelectedChat,
        onlineUsers,
        setOnlineUsers,
        notification,
        setNotification,
        chats,
        setChats,
        receiverId,
        setReceiverId,
        notifications,
        setNotifications,
        socket,
        setSocket,
        chatId,
        setChatId
      }}>
      {children}
    </ChatNotificationContext.Provider>
  );
};
export const ChatState = () => {
  const chat = useContext(ChatNotificationContext);
  return chat;
};
export default ChatNotificationProvider;
