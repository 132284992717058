import clsx from 'clsx';
import React from 'react';
import VerifiedIcon from '../icons/VerifiedIcon';

type Props = {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  icon?: any;
  image?: any;
  showState?: boolean;
  verified?: boolean;
  isOnline?: boolean;
  className?: string;
  disabled?: boolean;
  [x: string]: any;
};

const Avatar: React.FC<Props> = (props) => {
  const {
    size = 'xs',
    icon,
    image,
    verified = false,
    showState = false,
    isOnline = false,
    className,
    disabled = false
  } = props;
  return (
    <div className="flex">
      <div className="relative flex justify-center ">
        <button
          type="button"
          disabled={disabled}
          className={clsx(
            icon
              ? ' border border-gray-300 bg-gray-100 hover:border-gray-400 hover:bg-gray-200 focus:ring-4 focus:ring-gray-200 focus:bg-gray-25 focus:border-gray-25'
              : '',
            image ? 'p-0 hover:ring-4 hover:ring-gray-200 focus:ring-yellow-500' : '',
            {
              ' h-6 w-6 ': size === 'xs',
              'h-8 w-8': size === 'sm',
              'h-10 w-10': size === 'md',
              'h-12 w-12': size === 'lg',
              'h-14 w-14': size === 'xl',
              'h-16 w-16': size === '2xl'
            },
            'rounded-full disabled:ring-0 disabled:hover:ring-0',
            className
          )}>
          {image && !icon && (
            <img className="rounded-full h-full w-full object-cover" src={image} alt="" />
          )}
          {icon && !image && (
            <span
              className={clsx(
                {
                  ' p-1': size === 'xs',
                  'p-1.5': size === 'sm',
                  'p-2': size === 'md',
                  'p-2.5': size === 'lg',
                  'p-3': size === 'xl',
                  'p-4': size === '2xl'
                },
                ' flex p-1  justify-center items-center w-full h-full'
              )}>
              {icon}
            </span>
          )}
        </button>
        {showState && (
          <span
            className={clsx(
              isOnline ? 'bg-success-500' : ' bg-gray-300 ',
              {
                ' h-1.5 w-1.5': size == 'xs',
                'h-2 w-2': size == 'sm',
                'h-2.5 w-2.5': size == 'md',
                'h-3 w-3': size == 'lg',
                'h-[14px] w-[14px]': size == 'xl',
                'h-4 w-4': size == '2xl'
              },
              'absolute border-white bottom-0 right-0 rounded-full  border-[1.5px]'
            )}
          />
        )}

        {/* {verified && (
          <VerifiedIcon
            className={clsx(
              {
                'h-2.5 w-2.5': size === 'xs',
                'h-3 w-3': size === 'sm',
                'h-3.5 w-3.5': size === 'md',
                'h-4 w-4': size === 'lg',
                'h-4.5 w-4.5': size === 'xl',
                'h-5 w-5': size === '2xl'
              },
              ' absolute  bottom-0 right-0 block '
            )}></VerifiedIcon>
        )} */}
      </div>
    </div>
  );
};

export default Avatar;
