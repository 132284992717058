/* eslint-disable @typescript-eslint/no-explicit-any */
import { LocationBound } from '../../model/Location';
import { CheckboxOption } from '../../model/CheckboxOption';
import { useGetMarketPlaceData } from './useGetMarketPlaceData';
import { NUMBER_OF_ITEMS_PER_PAGE } from '../../constants/data';

export const useGetMapListings = (
  moreFilter: any,
  categories: CheckboxOption[] | undefined,
  searchText: string,
  locationBounds: LocationBound,
  listingOffset: number,
  storefrontOffset: number,
  order?: string,
  userId?: string
) => {
  const limit = NUMBER_OF_ITEMS_PER_PAGE;
  const type = 'list';
  return useGetMarketPlaceData(
    limit,
    type,
    moreFilter,
    categories,
    searchText,
    locationBounds,
    listingOffset,
    storefrontOffset,
    order,
    userId
  );
};
