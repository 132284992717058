type Props = {
  className?: string;
};
const MapPinIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-5 h-5 text-blue-gray-600 ' + className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99999 10.4167C11.3807 10.4167 12.5 9.29737 12.5 7.91666C12.5 6.53594 11.3807 5.41666 9.99999 5.41666C8.61928 5.41666 7.49999 6.53594 7.49999 7.91666C7.49999 9.29737 8.61928 10.4167 9.99999 10.4167Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99999 18.3333C11.6667 15 16.6667 12.8486 16.6667 8.33332C16.6667 4.65142 13.6819 1.66666 9.99999 1.66666C6.3181 1.66666 3.33333 4.65142 3.33333 8.33332C3.33333 12.8486 8.33333 15 9.99999 18.3333Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MapPinIcon;
