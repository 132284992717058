import React from 'react';
import Image from './Image';
import VerifiedIcon from '../icons/VerifiedIcon';
import User1Icon from '../icons/User1Icon';
type Props = {
  className?: string;
  image?: string;
  verified?: boolean;
  verfiedClassName?: string;
};

const ProfileAvatar: React.FC<Props> = (props) => {
  const { className = '', image, verified, verfiedClassName } = props;
  return (
    <span
      className={` shadow-md sm:shadow-lg  flex rounded-full w-24 h-24 ${
        image ? 'border-white border-[3px] sm:border-4' : 'border bg-gray-100 border-gray-300'
      } ${className}`}>
      <div className="relative h-full flex items-center justify-center w-full">
        {image ? (
          <Image src={image} className="w-full h-full rounded-full"></Image>
        ) : (
          <User1Icon className="h-full w-full mx-auto text-gray-600 p-3" />
        )}
        {/* {verified && <VerifiedIcon className={`absolute ${verfiedClassName}`}></VerifiedIcon>} */}
      </div>
    </span>
  );
};

export default ProfileAvatar;
