import React, { FC } from 'react';
import DollarIcon from '../../components/icons/DollarIcon';
import { Typo } from '../../components/Typo';
import clsx from 'clsx';
import AwardIcon from '../../components/icons/AwardIcon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ALTERNATE_PRICE_OPTION } from '../../constants/data';

type Props = {
  currency: string;
};

const CurrencyCard: FC<Props> = ({ currency }) => {
  return (
    <div
      className={clsx(
        'p-6 w-full rounded-xl border flex-col justify-start items-center lg:h-full',
        currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE
          ? 'border-yellow-200 bg-yellow-50 '
          : 'border-gray-200 bg-gray-50 '
      )}>
      <div
        className={clsx(
          'w-12 h-12 p-3 bg-white rounded-[10px] shadow border  justify-center items-center inline-flex mb-5',
          currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE ? 'border-yellow-200' : 'border-gray-200'
        )}>
        {currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE ? (
          <AwardIcon className="w-6 h-6 " />
        ) : (
          <DollarIcon className="w-6 h-6 " />
        )}
      </div>
      <Typo.textLg
        fontWeight="font-medium"
        className={clsx(
          'mb-1',
          currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE ? 'text-yellow-800 ' : 'text-gray-900'
        )}>
        {currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE ? 'Doge = Doge' : ' Negotiable Price'}
      </Typo.textLg>
      <Typo.textSm
        fontWeight="font-normal"
        className={clsx(
          currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE ? 'text-yellow-700' : 'text-gray-500'
        )}>
        {currency === ALTERNATE_PRICE_OPTION.DOGE_DOGE ? (
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.DOGE_CURRENCY_DESCRIPTION.id}
            defaultMessage={TRANSLATED_CONSTANTS.DOGE_CURRENCY_DESCRIPTION.defaultMessage}
          />
        ) : (
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.STANDARD_CURRENCY_DESCRIPTION.id}
            defaultMessage={TRANSLATED_CONSTANTS.STANDARD_CURRENCY_DESCRIPTION.defaultMessage}
          />
        )}
      </Typo.textSm>
    </div>
  );
};

export default CurrencyCard;
