import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_COUNTRIES = gql`
  query getCountries {
    countries {
      value
      label
    }
  }
`;

export const useGetCountries = () => {
  const { loading, error, data } = useQuery(GET_COUNTRIES, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    countries: data?.countries,
    loading,
    error
  };
};
