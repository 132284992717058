import React, { useEffect, useState } from 'react';
import { Typo } from '../../Typo';
import { compareDates, getDayName } from '../../../util/AppUtil';
type Props = {
  index: number;
  currentDate: string;
  beforeDate: string;
};

const MessageDate: React.FC<Props> = (props) => {
  const { currentDate, beforeDate, index } = props;
  const [dateSate, setDateState] = useState(false);

  useEffect(() => {
    setDateState(compareDates(currentDate, beforeDate));
  }, [currentDate, beforeDate]);
  return (
    <div
      className={`${
        dateSate && index !== 0 ? ' hidden' : ' relative flex py-8 w-full justify-center'
      }`}>
      <div className="w-full h-[1px] bg-gray-300 absolute z-0 top-10"></div>
      <Typo.textSm fontWeight="font-normal" className="bg-white z-0 text-gray-600 px-2 text-center">
        {getDayName(currentDate)}
      </Typo.textSm>
    </div>
  );
};

export default MessageDate;
