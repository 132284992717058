import { AuthUserState } from '../context/AuthUserProvider';

export const useRole = (role: string): boolean => {
  const { me } = AuthUserState();
  return me && me?.role?.name === role;
};

export const useGetUserRole = (): string => {
  const { me } = AuthUserState();
  return me && me?.role?.name;
};
