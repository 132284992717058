import { ChangeEvent, FC, Fragment, useEffect, useState } from 'react';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import AvatarGroup from './AvatarGroup';
import ModalBodyText from './ModalBodyText';
import Divider from '../shared/Divider';
import InputField from '../shared/InputField';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import Button from '../shared/Button';
import CopyIcon from '../icons/CopyIcon';
import baseUrl from '../../config/config';
import { Typo } from '../Typo';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import FacebookIcon from '../icons/FacebookIcon';
import TwitterIcon from '../icons/TwitterIcon';
import EmailIcon from '../icons/EmailIcon';
import { Dialog, Transition } from '@headlessui/react';
import BaselineEmailIcon from '../icons/BaselineEmailIcon';
import LinkIcon from '../icons/LinkIcon';
type Props = {
  isOpen: boolean;
  url?: string;
  onCloseShareModal: (arg0: boolean) => void;
};

const ShareModal: FC<Props> = ({ isOpen, url, onCloseShareModal }) => {
  const currentPath = url ? url : baseUrl.BASE_URL + window?.location?.pathname;
  const intl = useIntl();
  const [urlLink, setUrlLink] = useState(currentPath);
  const [copied, setCopied] = useState(false);
  const [copuedButton, setCopiedButton] = useState(false);

  const shareLabel = intl.formatMessage({
    id: TRANSLATED_CONSTANTS.SHARE_LINK.id,
    defaultMessage: TRANSLATED_CONSTANTS.SHARE_LINK.defaultMessage
  });

  const handleCopy = async (isFirst: boolean) => {
    await navigator.clipboard.writeText(urlLink);
    isFirst ? setCopied(true) : setCopiedButton(true);
  };

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
        setCopiedButton(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied, copuedButton]);

  useEffect(() => {
    if (url) {
      setUrlLink(baseUrl.BASE_URL + url);
    } else {
      setUrlLink(baseUrl.BASE_URL + window.location.pathname);
    }
  }, [url]);

  const { register } = useForm();
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onCloseShareModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:my-8 w-full  md:max-w-[400px] ">
                <div className="p-5 md:p-6">
                  <div className="">
                    <div className="flex justify-center mb-6">
                      <AvatarGroup />
                    </div>
                    <ModalBodyText
                      title={TRANSLATED_CONSTANTS.SHARE_MODAL_TITLE}
                      subTitle={TRANSLATED_CONSTANTS.SHARE_MODAL_SUBTITLE}
                    />
                    <div className="flex gap-1 relative items-end mt-5">
                      <InputField
                        name="text"
                        label={shareLabel}
                        value={urlLink}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setUrlLink(e.target.value)}
                        type="text"
                        placeholder="text"
                        defaultValue={currentPath}
                        register={register}
                        disabled={true}
                        className="truncate w-64"
                      />
                      <Button
                        onClick={() => handleCopy(true)}
                        className="mb-1 p-[10px]"
                        size="md"
                        theme="gray-link"
                        icon={<CopyIcon className="h-5 w-5 text-gray-500"></CopyIcon>}></Button>
                      <div
                        className={`absolute top-2 -right-2 rounded-lg py-2 px-3 bg-gray-900 text-white ${
                          copied ? ' block' : 'hidden'
                        }`}>
                        <Typo.textXs fontWeight="font-semibold">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.COPIED.id,
                            defaultMessage: TRANSLATED_CONSTANTS.COPIED.defaultMessage
                          })}
                        </Typo.textXs>
                      </div>
                    </div>
                    <div className="-mr-6 -ml-6 md:mt-8 mt-6">
                      <Divider />
                    </div>
                    <div className=" flex flex-col space-y-3 py-6">
                      <Button size="lg" theme="base" className="w-full">
                        <TwitterShareButton className="w-full h-full" url={urlLink}>
                          <div className="flex gap-x-2 w-full justify-center">
                            <TwitterIcon className="text-[#1DA1F2]" />
                            <Typo.textMd fontWeight="font-semibold">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.SHARE_VIA_TWITTER.id}
                                defaultMessage={
                                  TRANSLATED_CONSTANTS.SHARE_VIA_TWITTER.defaultMessage
                                }
                              />
                            </Typo.textMd>
                          </div>
                        </TwitterShareButton>
                      </Button>
                      <Button size="lg" theme="base" className="w-full">
                        <FacebookShareButton
                          url={urlLink}
                          className="w-full h-full"
                          quote="test sharing"
                          hashtag="">
                          <div className="flex gap-x-2 w-full justify-center">
                            <FacebookIcon className="text-[#1DA1F2]" />
                            <Typo.textMd fontWeight="font-semibold">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.SHARE_VIA_FACEBOOK.id}
                                defaultMessage={
                                  TRANSLATED_CONSTANTS.SHARE_VIA_FACEBOOK.defaultMessage
                                }
                              />
                            </Typo.textMd>
                          </div>
                        </FacebookShareButton>
                      </Button>
                      <Button size="lg" theme="base" className="w-full">
                        <EmailShareButton url={urlLink} className="w-full">
                          <div className="flex gap-x-2 items-center justify-center w-full">
                            <BaselineEmailIcon className="text-warning-500 h-6 w-6" />
                            <Typo.textMd fontWeight="font-semibold">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.SHARE_VIA_EMAIL.id}
                                defaultMessage={TRANSLATED_CONSTANTS.SHARE_VIA_EMAIL.defaultMessage}
                              />
                            </Typo.textMd>
                          </div>
                        </EmailShareButton>
                      </Button>
                      <Button
                        onClick={() => handleCopy(false)}
                        size="lg"
                        theme="primary-yellow"
                        className="w-full">
                        <div className="flex gap-x-2 relative">
                          <LinkIcon className=" h-5 w-5 text-gray-900" />
                          <Typo.textMd fontWeight="font-semibold">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.COPY_LINK.id}
                              defaultMessage={TRANSLATED_CONSTANTS.COPY_LINK.defaultMessage}
                            />
                          </Typo.textMd>
                          <div
                            className={`absolute top-2 -right-2 rounded-lg py-2 px-3 bg-gray-900 text-white ${
                              copuedButton ? ' block' : 'hidden'
                            }`}>
                            <Typo.textXs fontWeight="font-semibold">
                              {intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.COPIED.id,
                                defaultMessage: TRANSLATED_CONSTANTS.COPIED.defaultMessage
                              })}
                            </Typo.textXs>
                          </div>
                        </div>
                      </Button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ShareModal;
