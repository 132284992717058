import React from 'react';
import { Typo } from '../../Typo';
import { shortenText } from '../../../util/AppUtil';
import { Storefront } from '../../../model/Storefront';
import {
  LISTING_BREAKPOINT,
  LISTING_CARD_TYPES,
  LIST_PAGES,
  ROUTES
} from '../../../constants/data';
import clsx from 'clsx';
import CardWrapper from './CardWrapper';
import ListingCardAction from './ListingCardAction';
import FavoriteAction from './FavoriteAction';
import GetDirectionAction from './GetDirectionAction';
import { useIntl } from 'react-intl';
import { AuthUserState } from '../../../context/AuthUserProvider';
import Button from '../Button';
import EyeIcon from '../../icons/EyeIcon';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
type PriceProps = {
  page?: LIST_PAGES.MAP | LIST_PAGES.SAVED | LIST_PAGES.ARCHIVE | LIST_PAGES.DASHBOARD;
  size?: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  onEdit?: (id: number | string) => void;
  onDelete?: (id: number) => void;
  onShare?: (id: number | string) => void;
  onLike?: (id: number | string, type: string, like: boolean, savedStorefrontId?: number) => void;
  storeFront: Storefront;
  cardClickable?: boolean;
};
const StorefrontCard: React.FC<PriceProps> = (props) => {
  const { me, authUser } = AuthUserState();
  const {
    page = LIST_PAGES.MAP,
    size = LISTING_BREAKPOINT.DEFAULT,
    storeFront,
    onDelete,
    onEdit,
    onShare,
    onLike,
    cardClickable = false
  } = props;
  const intl = useIntl();

  const onSave = (id: number | string, type: string) => {
    onLike &&
      onLike(
        id,
        type,
        storeFront?.saved_storefronts && storeFront?.saved_storefronts.length > 0 ? true : false,
        storeFront.saved_storefronts && storeFront?.saved_storefronts.length > 0
          ? Number(storeFront.saved_storefronts[0].id)
          : undefined
      );
  };
  return (
    <CardWrapper
      type="storefront"
      cardClickable={cardClickable}
      slug={storeFront.slug}
      size={size}
      id={Number(storeFront.id)}
      imageUrl={storeFront?.image?.length > 0 ? storeFront?.image[0]?.url : ''}
      intl={intl}
      listingType={LISTING_CARD_TYPES.STOREFRONT}>
      {/* list description  */}
      <div className="w-full">
        {/* listing and user description   */}
        <div className="flex flex-col">
          <div className="flex justify-between items-start">
            <div className="">
              <div>
                <Typo.textSm fontWeight="font-medium" className="text-blue-gray-500">
                  {`@${storeFront?.user?.username}`}
                </Typo.textSm>
                <Typo.textLg fontWeight="font-medium" className=" text-gray-900 mt-1">
                  {storeFront?.name}
                </Typo.textLg>
              </div>
            </div>
            {/* card ation button */}
            <div onClick={(event) => event.stopPropagation()}>
              {/*  like and dislike button */}
              {(page === LIST_PAGES.MAP || page === LIST_PAGES.SAVED) && (
                <FavoriteAction
                  id={storeFront?.id || 0}
                  size={size}
                  liked={
                    storeFront?.saved_storefronts && storeFront?.saved_storefronts.length > 0
                      ? true
                      : false
                  }
                  disabled={me?.id == storeFront?.user?.id}
                  onLike={() => onSave(storeFront.id, 'storefront')}></FavoriteAction>
              )}

              {/* share , edit and delete on dashboard  */}
              {page === LIST_PAGES.DASHBOARD && (
                <ListingCardAction
                  id={storeFront?.id || 0}
                  onDelete={() => onDelete && onDelete(Number(storeFront?.id))}
                  onEdit={onEdit}
                  onShare={onShare}></ListingCardAction>
              )}
            </div>
          </div>
          <Typo.textMd className=" text-gray-500 w-full mt-4 mb-4" fontWeight="font-normal">
            {shortenText(storeFront?.description || '', 60)}
          </Typo.textMd>
        </div>

        <div
          className={clsx(
            'flex justify-between items-start',
            size == LISTING_BREAKPOINT.SLIDE ? ' block sm:hidden ' : ' block '
          )}>
          <div className="flex justify-between items-center">
            {/* storefront time operation and get direction button */}
            {page == LIST_PAGES.MAP && (
              <GetDirectionAction
                storefront={storeFront}
                size={size}
                showTimeLabel={true}
                currentLat={authUser?.location?.latitude || 0}
                currentLong={authUser?.location?.longitude || 0}
                destinationLat={storeFront?.location?.latitude || 0}
                destinationLong={storeFront?.location?.longitude || 0}
                destinationAddress={storeFront?.location?.address || ''}></GetDirectionAction>
            )}

            {/* Preview button */}
            {page === LIST_PAGES.DASHBOARD && (
              <div className="flex gap-x-6">
                <Button
                  type="button"
                  theme="blue-link"
                  className="underline "
                  to={`${ROUTES.STOREFRONT_DETAILS_ROUTE}/${storeFront.id}`}
                  icon={<EyeIcon className=" stroke-blue-gray-600"></EyeIcon>}>
                  <Typo.textMd fontWeight="font-semibold">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.PREVIEW.id,
                      defaultMessage: TRANSLATED_CONSTANTS.PREVIEW.defaultMessage
                    })}
                  </Typo.textMd>
                </Button>
                {/* <PriceOption title={listing?.price?.priceType}></PriceOption> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default StorefrontCard;
