import { useQuery, gql } from '@apollo/client';
import { DropdownOption } from '../../model/DropdownOption';
import { CheckboxOption } from '../../model/CheckboxOption';

export const GET_FILTER_DATA = gql`
  query getFilterData {
    itemConditions {
      data {
        id
        attributes {
          name
        }
      }
    }
    categories {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

const changeData = (data: any) => {
  const options: CheckboxOption[] = [];
  data?.data?.map((item: any) => {
    options.push({ value: item?.id, label: item.attributes.name } as CheckboxOption);
  });
  return options;
};

export const useGetFilterData = () => {
  const { loading, error, data } = useQuery(GET_FILTER_DATA, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    categories: changeData(data?.categories),
    itemConditions: changeData(data?.itemConditions),
    loading,
    error
  };
};
