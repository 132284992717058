import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_LISTINGS = gql`
  query GetListings(
    $profileId: ID!
    $userId: ID
    $offset: Int!
    $limit: Int!
    $order: [String]
    $type: String
    $skip: Boolean!
    $exceptId: ID
  ) {
    listings(
      pagination: { start: $offset, limit: $limit }
      sort: $order
      filters: {
        and: [
          { user: { id: { eq: $profileId } }, type: { contains: $type } }
          { id: { ne: $exceptId } }
          { deleted: { eq: false } }
        ]
      }
    ) {
      __typename
      data {
        id
        attributes {
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
                formats
              }
            }
          }
          name
          isSold
          description
          location {
            address
            latitude
            longitude
            state
            country
            city
            street
          }
          slug
          price {
            priceType
            priceInUSD
            priceInDoge
            alternatePriceOptions
          }
          type
          item_condition {
            data {
              id
              attributes {
                name
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
                type
              }
            }
          }
          saved_listings(filters: { user: { id: { eq: $userId } } }) @skip(if: $skip) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          publishedAt
        }
      }
      meta {
        pagination {
          total
          pageSize
          page
          pageCount
        }
      }
    }
  }
`;

export const useGetListings = (
  userId: string | number | null,
  profileId: number,
  offset: number,
  limit?: number,
  order?: string,
  type?: string,
  exceptId?: number
) => {
  let skip = true;
  if (userId) {
    skip = false;
  }
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_LISTINGS, {
    variables: { userId, profileId, limit, order, type, offset, skip, exceptId },
    fetchPolicy: 'cache-and-network'
  });

  return {
    listings: flattenObj(data?.listings),
    pagination: data?.listings?.meta?.pagination,
    fetchMore,
    refetch,
    loading,
    error
  };
};
