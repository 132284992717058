import { gql, useMutation } from '@apollo/client';

interface UpdateRolesInput {
  usersIds: number[];
  roleId: number;
}
const UPDATE_ROLES = gql`
  mutation UpdateRoles($usersIds: [ID!], $roleId: ID!) {
    updateRoles(usersIds: $usersIds, roleId: $roleId) {
      count
    }
  }
`;

export const useUpdateRoles = (): ((updateRole: UpdateRolesInput) => any) => {
  const [updateRole] = useMutation(UPDATE_ROLES);
  return (input: UpdateRolesInput) => updateRole({ variables: input });
};
