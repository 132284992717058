import React, { FC } from 'react';
import ListingDetailedProfileHeader from './ListingDetailedProfileHeader';
import ActivityList from '../../../../components/activities/ActivityList';
import { ActivityItemPropsType } from '../../../../model/Activity';
import { Typo } from '../../../../components/Typo';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import { useForm } from 'react-hook-form';
import Button from '../../../../components/shared/Button';
import Divider from '../../../../components/shared/Divider';
import { ISSUE_LISTING_ACTION_TYPE, REPORT_PAGES, ROLES } from '../../../../constants/data';
import { useAppSelector } from '../../../../lib/redux/hooks';
import UserInfo from '../../../../components/shared/table/UserInfo';
import { handleAccountTypeBadge } from '../../../../util/ListingBadgeHandler';
import Pagination from '../../../../components/shared/Pagination';
import { handleListingDetailConstants } from '../../../../util/ReportUtil';
import { useDispatch } from 'react-redux';
import { setChatData, setProfileData, showSlide } from '../../../../lib/redux/reducers/slide.slice';
import { AuthUserState } from '../../../../context/AuthUserProvider';
import {
  ListingModel,
  ListingWithListingReport,
  QuarantineListings
} from '../../../../model/Listing';
import { User } from '../../../../model/UserModel';
import { useGetUserRole } from '../../../../hooks/useRole';
import DetailedLayoutListingCards from '../../detailedLayoutComponents/DetailedLayoutListingCards';
import RestoreIcon from '../../../../components/icons/RestoreIcon';
import Reason from '../../detailedLayoutComponents/Reason';
import Contact from '../../detailedLayoutComponents/Contact';

type Props = {
  pageType: 'listing-flag' | 'listing-quarantine' | 'listing-deleted';
  data: any;
  activityData: ActivityItemPropsType[];
  activityHeader: { title: string; description: string };
  listingReportsCount: number;
  fetchMoreData: (param: number) => void;
  currentPage: number;
  numberOfFlags?: number;
  numberOfWarnings?: number;
  openBanModal?: (user: User, listing: ListingModel) => void;
  openBanAndDeleteModal?: (param: ListingWithListingReport, claim: string, detail: string) => void;
  openClearFlagsModal?: (param: ListingWithListingReport) => void;
  openDeleteAndWarnModal?: (
    report: ListingWithListingReport,
    claim: string,
    detail: string
  ) => void;
  openRestoreModal?: (param: QuarantineListings, isListing: boolean) => void;
  activityItemData?: ActivityItemPropsType;
};

const ListingDetailedLayout: FC<Props> = (props) => {
  const {
    pageType,
    data,
    activityData,
    activityHeader,
    listingReportsCount,
    fetchMoreData,
    currentPage,
    openBanAndDeleteModal,
    openBanModal,
    openDeleteAndWarnModal,
    openRestoreModal,
    numberOfFlags,
    numberOfWarnings,
    activityItemData
  } = props;
  const intl = useIntl();
  const role = useGetUserRole();
  const { authUser } = AuthUserState();
  const dispatch = useDispatch();
  const { register } = useForm();
  const profileState = useAppSelector((state) => state.slide.profile);
  const report = data && data[0];
  const issuedListing = report?.issued_listing;
  const issuer =
    pageType === REPORT_PAGES.LISTING_QUARANTINE ? report?.issued_listing?.issuer : undefined;
  const claim = activityItemData ? activityItemData?.claim : issuedListing?.claim;
  const detail = activityItemData ? activityItemData?.detail : issuedListing?.detail;
  const accountType = handleAccountTypeBadge(
    activityData?.length > 0 ? activityItemData?.accountType : issuedListing?.issuer?.type,
    intl
  );

  const issuedListingConstants = handleListingDetailConstants(accountType, pageType);

  const openMessage = () => {
    dispatch(
      setChatData({
        sender: {
          profileImage: { url: authUser.profileImage?.url },
          name: authUser.name,
          username: authUser.username,
          id: authUser.id
        },
        receiver: {
          profileImage: {
            url:
              pageType === REPORT_PAGES.LISTING_QUARANTINE
                ? issuer?.profileImage?.url
                : activityItemData?.userImageUrl
          },
          name:
            pageType === REPORT_PAGES.LISTING_QUARANTINE ? issuer?.name : activityItemData?.name,
          username:
            pageType === REPORT_PAGES.LISTING_QUARANTINE
              ? issuer?.username
              : activityItemData?.username,
          id: pageType === REPORT_PAGES.LISTING_QUARANTINE ? issuer?.id : activityItemData?.userId
        }
      })
    );
    dispatch(showSlide('message'));
  };

  return (
    <div>
      <div className="w-full flex flex-col lg:flex-row lg:gap-16">
        <div className="w-full flex gap-8 flex-col">
          <ListingDetailedProfileHeader
            pageType={REPORT_PAGES.LISTING_DELETED_WARNED}
            user={report?.user}
            openBanModal={(user: User) => {
              openBanModal && openBanModal(user, report);
            }}
            warnings={numberOfWarnings || 0}
            flags={numberOfFlags || 0}
          />
          <div className="md:px-4 lg:pl-4 lg:pr-0 pb-8">
            <Contact email={report?.user?.email} />

            <div className="my-8">
              <Divider />
            </div>
            {report && (
              <DetailedLayoutListingCards
                report={report}
                pageType={REPORT_PAGES.LISTING_FLAG}
                activityItemData={activityItemData}
                title={issuedListingConstants.title}
                subtitle={issuedListingConstants.subtitle}
              />
            )}

            <div className="my-8">
              <Divider />
            </div>
            <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
              <div className="w-[280px]">
                <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                  {issuedListingConstants.reporterTitle}
                </Typo.textSm>
                <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                  {issuedListingConstants.reporterSubTitle}
                </Typo.textSm>
              </div>
              <div className="flex flex-col md:flex-row gap-6 flex-1 w-full  justify-between items-start ">
                <UserInfo
                  profileImage={
                    pageType === REPORT_PAGES.LISTING_QUARANTINE
                      ? issuer?.profileImage?.url
                      : activityItemData?.userImageUrl
                  }>
                  <div className="w-full">
                    <div className="flex  gap-2">
                      <Typo.textMd fontWeight="font-medium" className="text-gray-900 capitalize">
                        {pageType === REPORT_PAGES.LISTING_QUARANTINE
                          ? issuer?.name
                          : activityItemData?.name}
                      </Typo.textMd>

                      {issuedListingConstants.reporterBadge}
                    </div>
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {pageType === REPORT_PAGES.LISTING_QUARANTINE
                        ? issuer?.email
                        : activityItemData?.email}
                    </Typo.textMd>
                  </div>
                </UserInfo>
                <div className="w-full flex gap-4 justify-end">
                  <Button onClick={openMessage} size="md" theme="gray-link">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.MESSAGE.id}
                        defaultMessage={TRANSLATED_CONSTANTS.MESSAGE.defaultMessage}
                      />
                    </Typo.textSm>
                  </Button>
                  <Button
                    onClick={() => {
                      if (!profileState) {
                        dispatch(
                          setProfileData({
                            userId:
                              pageType === REPORT_PAGES.LISTING_QUARANTINE
                                ? issuer?.id
                                : activityItemData?.userId
                          })
                        );
                        dispatch(showSlide('profile'));
                      }
                    }}
                    size="sm"
                    theme="yellow-link">
                    <Typo.textSm fontWeight="font-semibold" className="">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                        defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
                      />
                    </Typo.textSm>
                  </Button>
                </div>
              </div>
            </div>
            <div className="my-8">
              <Divider />
            </div>
            <Reason claim={claim || ''} detail={detail || ''} />
            <div className="mt-8 mb-5">
              <Divider />
            </div>

            <div className="w-full flex justify-end">
              <div className="flex gap-3">
                {activityItemData?.type === ISSUE_LISTING_ACTION_TYPE.DELETE_BAN &&
                  role === ROLES.SUPER_ADMIN && (
                    <Button
                      onClick={() => {
                        openDeleteAndWarnModal && openDeleteAndWarnModal(report, claim, detail);
                      }}
                      size="md"
                      theme="base">
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.ISSUE_WARNING_INSTEAD.id}
                          defaultMessage={TRANSLATED_CONSTANTS.ISSUE_WARNING_INSTEAD.defaultMessage}
                        />
                      </Typo.textSm>
                    </Button>
                  )}

                {pageType === REPORT_PAGES.LISTING_QUARANTINE && (
                  <div className="flex gap-3">
                    <Button
                      onClick={() => {
                        openDeleteAndWarnModal && openDeleteAndWarnModal(report, claim, detail);
                      }}
                      size="md"
                      theme="base">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.DELETE_AND_WARNING.id}
                          defaultMessage={TRANSLATED_CONSTANTS.DELETE_AND_WARNING.defaultMessage}
                        />
                      </Typo.textSm>
                    </Button>
                    <Button
                      onClick={() => {
                        openBanAndDeleteModal && openBanAndDeleteModal(report, claim, detail);
                      }}
                      size="md"
                      theme="error-outline">
                      <Typo.textSm fontWeight="font-medium" className="text-error-600">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.DELETE_AND_BAN.id}
                          defaultMessage={TRANSLATED_CONSTANTS.DELETE_AND_BAN.defaultMessage}
                        />
                      </Typo.textSm>
                    </Button>
                  </div>
                )}

                <Button
                  onClick={() => {
                    openRestoreModal && openRestoreModal(report, true);
                  }}
                  icon={<RestoreIcon className="text-gray-900 w-5 h-5" />}
                  size="md"
                  theme="primary-yellow">
                  <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.RESTORE_LISTING.id}
                      defaultMessage={TRANSLATED_CONSTANTS.RESTORE_LISTING.defaultMessage}
                    />
                  </Typo.textSm>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-8 lg:mr-4 lg:mt-4 md:mx-4 ">
          <ActivityList activityHeader={activityHeader} activityData={activityData} />
        </div>
      </div>
      <Divider />
      <div className="px-6">
        <Pagination
          currentPage={currentPage}
          totalCount={listingReportsCount}
          pageSize={1}
          onPageChange={fetchMoreData}
          type="simple-card-footer"
        />
      </div>
    </div>
  );
};

export default ListingDetailedLayout;
