import { gql, useMutation } from '@apollo/client';

interface ResetPasswordInput {
  variables: {
    password: string;
    passwordConfirmation: string;
    code: string;
  };
}

export const RESET_PASSWORD = gql`
  mutation resetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
    resetMyPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
      id
      email
      username
    }
  }
`;

export const useResetPassword = (): ((updateUser: ResetPasswordInput) => any) => {
  const [resetPasswordResult] = useMutation(RESET_PASSWORD);

  return resetPasswordResult;
};
