type Props = {
  className?: string;
};
const CopyIcon = ({ className }: Props) => {
  return (
    <svg
      className={`${className} `}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.16669 13.0001C3.39012 13.0001 3.00183 13.0001 2.69555 12.8732C2.28717 12.7041 1.96271 12.3796 1.79355 11.9712C1.66669 11.6649 1.66669 11.2767 1.66669 10.5001V4.83341C1.66669 3.89999 1.66669 3.43328 1.84834 3.07676C2.00813 2.76316 2.2631 2.50819 2.5767 2.3484C2.93322 2.16675 3.39993 2.16675 4.33335 2.16675H10C10.7766 2.16675 11.1649 2.16675 11.4712 2.29362C11.8795 2.46277 12.204 2.78723 12.3732 3.19561C12.5 3.50189 12.5 3.89018 12.5 4.66675M10.1667 18.8334H15.6667C16.6001 18.8334 17.0668 18.8334 17.4233 18.6518C17.7369 18.492 17.9919 18.237 18.1517 17.9234C18.3334 17.5669 18.3334 17.1002 18.3334 16.1667V10.6667C18.3334 9.73333 18.3334 9.26662 18.1517 8.9101C17.9919 8.59649 17.7369 8.34153 17.4233 8.18174C17.0668 8.00008 16.6001 8.00008 15.6667 8.00008H10.1667C9.23327 8.00008 8.76656 8.00008 8.41004 8.18174C8.09643 8.34153 7.84147 8.59649 7.68168 8.9101C7.50002 9.26662 7.50002 9.73333 7.50002 10.6667V16.1667C7.50002 17.1002 7.50002 17.5669 7.68168 17.9234C7.84147 18.237 8.09643 18.492 8.41004 18.6518C8.76656 18.8334 9.23327 18.8334 10.1667 18.8334Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyIcon;
