import React, { useState } from 'react';
import ModalBodyText from './ModalBodyText';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import clsx from 'clsx';

type SlidesType = {
  id: number;
  slide: React.ReactElement;
};

const slides: SlidesType[] = [
  {
    id: 1,
    slide: (
      <ModalBodyText
        title={TRANSLATED_CONSTANTS.VOLENTEER_MODAL_MOD_ONE_TITLE}
        subTitle={TRANSLATED_CONSTANTS.VOLENTEER_MODAL_MOD_ONE_SUBTITLE}
      />
    )
  },
  {
    id: 2,
    slide: (
      <ModalBodyText
        title={TRANSLATED_CONSTANTS.VOLENTEER_MODAL_MOD_TWO_TITLE}
        subTitle={TRANSLATED_CONSTANTS.VOLENTEER_MODAL_MOD_TWO_SUBTITLE}
      />
    )
  },
  {
    id: 3,
    slide: (
      <ModalBodyText
        title={TRANSLATED_CONSTANTS.VOLENTEER_MODAL_MOD_THREE_TITLE}
        subTitle={TRANSLATED_CONSTANTS.VOLENTEER_MODAL_MOD_THREE_SUBTITLE}
      />
    )
  }
];

const ApplyModalBodySlide = () => {
  const [activeSlide, setActiveSlide] = useState<SlidesType>(slides[0]);

  const handleDotClick = (activeSlide: SlidesType) => {
    setActiveSlide(activeSlide);
  };

  return (
    <div className="pt-5 md:pt-6">
      {activeSlide.slide}
      <div className="w-full h-2.5 justify-center items-center gap-4 inline-flex mt-5">
        {slides.map((slide) => {
          return (
            <button
              onClick={() => handleDotClick(slide)}
              key={slide.id}
              className={clsx(
                'w-2.5 h-2.5 relative  rounded-md',
                slide.id === activeSlide.id ? 'bg-yellow-800' : 'bg-gray-300'
              )}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ApplyModalBodySlide;
