type Props = {
  className?: string;
};
const CheckIcon = ({ className }: Props) => {
  return (
    // <svg
    //   className={`w-5 h-5 ${className}`}
    //   viewBox="0 0 21 20"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg">
    //   <path
    //     d="M17.1666 5L7.99992 14.1667L3.83325 10"
    //     stroke="currentColor"
    //     strokeWidth="1.66667"
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
    <svg
      className={`w-5 h-5 ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6666 5L7.49992 14.1667L3.33325 10"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
