import { gql, useMutation } from '@apollo/client';

interface ForgoetPasswordInput {
  variables: {
    email: string;
  };
}

export const FOROGT_PASSWORD_MUTATION = gql`
  mutation ForgotPassword($email: String!) {
    forgottenPassword(email: $email) {
      ok
    }
  }
`;

export const useForgotPassword = (): ((email: ForgoetPasswordInput) => any) => {
  const [forgotPasswordResult] = useMutation(FOROGT_PASSWORD_MUTATION);
  return forgotPasswordResult;
};
