/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';
import { GET_STOREFRONT } from '../storfront/useGetStorefront';
import { ListingModel } from '../../model/Listing';

interface ListingInput {
  variables: {
    data: ListingModel;
  };
}

export const CREATE_STOREFRONT = gql`
  mutation createListing($data: ListingInput!) {
    createListing(data: $data) {
      data {
        id
        attributes {
          name
          description
        }
      }
    }
  }
`;

export const useCreateListing = (): ((createListing: ListingInput) => any) => {
  const [createListingResult] = useMutation(CREATE_STOREFRONT, {
    refetchQueries: [GET_STOREFRONT, 'GetStorefront']
  });
  return createListingResult;
};
