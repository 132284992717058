/* eslint-disable @typescript-eslint/no-explicit-any */
import clsx from 'clsx';
import { FC, useState } from 'react';
import { DropdownOption } from '../../../model/DropdownOption';
import StepIcon from '../../icons/StepIcon';
import { Typo } from '../../Typo';
import CloseIcon from '../../icons/CloseIcon';
import Image from '../Image';
import manMeetUpLocation from '../../../assets/images/Man meet up location-min.jpg';
import Button from '../Button';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import DogeDomain from '../DogeDomain';

type OperationPropsType = {
  steps: DropdownOption[];
  currentStep: number;
  onNextStep?: () => void;
};

const ProgressSteps: FC<OperationPropsType> = (props) => {
  const { steps = [], currentStep = 1, onNextStep } = props;
  const [showMeetup, setShowMeetup] = useState<boolean>(false);
  return (
    <div className="flex flex-col justify-between">
      <nav aria-label="Progress">
        <ol role="list" className="overflow-hidden">
          {steps.map((step, stepIdx) => (
            <li
              key={step.title}
              className={clsx(stepIdx !== steps.length - 1 ? 'pb-7' : '', 'relative pl-1 pt-1', {
                ' opacity-100': currentStep >= stepIdx + 1,
                ' opacity-50': currentStep < stepIdx + 1
              })}>
              <>
                {stepIdx !== steps.length - 1 ? (
                  <div
                    className={clsx(
                      'absolute left-5 top-[42px] -ml-px h-11 w-0.5 rounded-sm bg-yellow-600 '
                    )}
                    aria-hidden="true"
                  />
                ) : null}
                <div aria-hidden="true" className={clsx('group relative flex ')}>
                  <span className="flex h-9 items-center">
                    <span
                      className={clsx(
                        'relative z-10 flex h-8 w-8 items-center justify-center rounded-full',
                        {
                          'bg-yellow-700 ': currentStep > stepIdx + 1,
                          ' ring-4 ring-yellow-300 bg-yellow-50 border-2 border-yellow-700 text-yellow-100':
                            currentStep === stepIdx + 1 && currentStep !== steps.length,
                          ' bg-yellow-700': currentStep === steps.length,
                          ' border-2 border-yellow-700 bg-yellow-50 ': currentStep < stepIdx + 1
                        }
                      )}>
                      {currentStep > stepIdx + 1 || currentStep == steps.length ? (
                        <StepIcon className="text-yellow-100"></StepIcon>
                      ) : (
                        <span className={clsx('h-2.5 w-2.5 rounded-full bg-yellow-700')} />
                      )}
                    </span>
                  </span>
                  <span className="ml-4 flex min-w-0 flex-col pt-1">
                    <Typo.textMd fontWeight="font-medium" className="text-gray-900">
                      {step.title}
                    </Typo.textMd>
                    <Typo.textMd fontWeight="font-normal" className="text-gray-500">
                      {step.supportingTitle}
                    </Typo.textMd>
                  </span>
                </div>
              </>
            </li>
          ))}
        </ol>
        {currentStep == 3 && !showMeetup && (
          <div className="h-[200px] max-w-[400px] bg-white rounded-lg my-16 py-5 px-4">
            <div className="flex justify-between items-start">
              <Typo.textSm fontWeight="font-medium" className="text-gray-900">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.PROVIDE_MEETUP_LOCATION.id}
                  defaultMessage={TRANSLATED_CONSTANTS.PROVIDE_MEETUP_LOCATION.defaultMessage}
                />
              </Typo.textSm>

              <CloseIcon className="h-5 w-5 text-gray-500" />
            </div>
            <Typo.textSm fontWeight="font-normal" className="text-gray-600 mt-1">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.PROVIDE_MEETUP_LOCATION_DETAILS.id}
                defaultMessage={TRANSLATED_CONSTANTS.PROVIDE_MEETUP_LOCATION_DETAILS.defaultMessage}
              />
            </Typo.textSm>
            <Image
              src={manMeetUpLocation}
              className="h-[136px] w-full object-cover my-4 rounded-lg"></Image>

            <div className="flex gap-x-3">
              <Button
                onClick={() => {
                  setShowMeetup(true);
                  localStorage.setItem('showedMeetupLocation', 'true');
                  onNextStep && onNextStep();
                }}
                theme="gray-link">
                <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.DISMISS.id}
                    defaultMessage={TRANSLATED_CONSTANTS.DISMISS.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
              <Button
                onClick={() => {
                  setShowMeetup(true);
                  localStorage.setItem('showedMeetupLocation', 'true');
                }}
                theme="yellow-link">
                <Typo.textSm fontWeight="font-medium" className="text-yellow-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.CONTINUE.id}
                    defaultMessage={TRANSLATED_CONSTANTS.CONTINUE.defaultMessage}
                  />
                </Typo.textSm>
              </Button>
            </div>
          </div>
        )}
      </nav>
      <div
        className={`py-10 px-12 ${
          currentStep !== 3 || showMeetup ? ' absolute bottom-0' : ' relative'
        }`}>
        <DogeDomain hasCopyWrite={true}></DogeDomain>
      </div>
    </div>
  );
};

export default ProgressSteps;
