import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_USERS_BY_ROLE_AND_ACCURACY = gql`
  query getUsersByRoleAndAccuracy(
    $offset: Int
    $limit: Int
    $role: String
    $highestAccuracyNumber: Int
    $lowestAccuracyNumber: Int
    $searchText: String
  ) {
    usersCount: usersByRoleAndAccuracy(
      role: $role
      highestAccuracyNumber: $highestAccuracyNumber
      lowestAccuracyNumber: $lowestAccuracyNumber
      searchText: $searchText
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    usersByRoleAndAccuracy(
      pagination: { limit: $limit, start: $offset }
      role: $role
      highestAccuracyNumber: $highestAccuracyNumber
      lowestAccuracyNumber: $lowestAccuracyNumber
      searchText: $searchText
    ) {
      data {
        id
        attributes {
          name
          type
          correctDecisions
          wrongDecisions
          decisions
          username
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
          profileImage {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUsersByRoleAndAccuracy = (
  offset: number,
  limit: number,
  searchText: string,
  role: string,
  highestAccuracyNumber: number,
  lowestAccuracyNumber: number
) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_USERS_BY_ROLE_AND_ACCURACY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset,
      limit,
      role,
      highestAccuracyNumber,
      lowestAccuracyNumber,
      searchText
    }
  });

  return {
    users: flattenObj(data?.usersByRoleAndAccuracy),
    usersCount: data?.usersCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
