import { useLocation, useNavigate } from 'react-router-dom';
import { AuthUserState } from '../context/AuthUserProvider';
import { ROUTES } from '../constants/data';
export const useAuthRedirect = (routes: string[]) => {
  const { banned } = AuthUserState();

  const navigate = useNavigate();
  const location = useLocation();

  // Check if the current location is in the list of routes
  const isProtected = routes.includes(location.pathname);
  // Check if the user is banned and trying to access a protected route
  if (banned && isProtected) {
    // Redirect the user to the notfound page

    navigate(ROUTES.NOT_FOUND_ROUTE);
  }
};
