import { FC, useState } from 'react';
import { Disclosure } from '@headlessui/react';
import { Typo } from '../Typo';
import { NavigationModel } from '../../model/Navigation';
import ChevronIcon from '../icons/ChevronIcon';
import { User } from '../../model/UserModel';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/data';
import { useDispatch } from 'react-redux';
import { addDashboardTab } from '../../lib/redux/reducers/dashboard.slice';
import { showModal } from '../../lib/redux/reducers/modal.slice';
type Props = {
  navigation: NavigationModel;
  hasRightArrow?: boolean;
  current?: boolean;
  user?: User;
  onClick?: (navigation: NavigationModel) => void;
};
const NavItem: FC<Props> = (props) => {
  const { navigation, hasRightArrow = false, current = false, user, onClick } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = () => {
    //TODO: Remove the modal when leaderboard is complete
    if (navigation.link === ROUTES.LEADER_BOARD) {
      dispatch(showModal('commingSoon'));
    } else {
      navigate(navigation.link || ROUTES.HOME_ROUTE);
      if (navigation.dashboardTab) {
        dispatch(addDashboardTab(navigation.dashboardTab));
      }
    }
  };

  return (
    <>
      {navigation?.link && navigation?.link !== '' ? (
        <button
          onClick={handleNavigation}
          className={clsx('', {
            ' hidden ': !navigation?.roles.includes(user?.role?.name || '')
          })}>
          <div
            className={`flex px-4 sm:px-[60px] justify-between py-3 text-gray-700 hover:bg-gray-50 items-center my-2 ${
              current ? 'bg-gray-50' : 'bg-white'
            } `}>
            <div className="flex gap-x-3 items-center">
              <span className="text-gray-600">{navigation?.icon}</span>
              <Typo.textLg fontWeight="font-medium">{navigation?.title}</Typo.textLg>
            </div>
            <ChevronIcon
              className={`rotate-90 text-gray-600 h-4 w-4 ${
                hasRightArrow ? 'hidden sm:block' : ' hidden'
              }`}></ChevronIcon>
          </div>
        </button>
      ) : (
        <Disclosure.Button
          as="div"
          onClick={() => onClick && onClick(navigation)}
          className={clsx('', {
            ' hidden ': !navigation?.roles.includes(user?.role?.name || '')
          })}>
          <div
            className={`flex px-4 sm:px-[60px] hover:cursor-pointer justify-between py-3 text-gray-700 hover:bg-gray-50 items-center my-2 ${
              current ? 'bg-gray-50' : 'bg-white'
            } `}>
            <div className="flex gap-x-3 items-center">
              <span className="text-gray-600">{navigation?.icon}</span>
              <Typo.textLg fontWeight="font-medium">{navigation?.title}</Typo.textLg>
            </div>
            <ChevronIcon
              className={`rotate-90 text-gray-600 h-4 w-4 ${
                hasRightArrow ? 'hidden sm:block' : ' hidden'
              }`}></ChevronIcon>
          </div>
        </Disclosure.Button>
      )}
    </>
  );
};

export default NavItem;
