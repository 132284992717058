import React, { ReactElement, useState } from 'react';
import BackNavigation from '../../components/shared/BackNavigation';
import DogeDomain from '../../components/shared/DogeDomain';
import { ACCOUNT_TYPE } from '../../constants/data';
import ProgressSteps from '../../components/shared/ProgressSteps/ProgressSteps';
import CreateAccountHead from '../../components/shared/Create Account/CreateAccountHead';
import ProgressDotSteps from '../../components/shared/ProgressSteps/ProgressDotSteps';
import { useAuth } from '../../context/Authn';
import { useNavigate } from 'react-router-dom';
import ChooseAccountStep from './components/ChooseAccountStep';
import CreateUserProfile from './components/CreateUserProfile';
import AddLocationStep from './components/AddLocationStep';
import CongratulationStep from './components/CongratulationStep';
import { progressContentHandler } from '../../util/CreateAccount';
import CreateStoreFrontStep from './components/CreateStoreFrontStep';
const CreateAccount = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [accountType, setAccountType] = useState<string>(ACCOUNT_TYPE.PERSONAL);
  const navigate = useNavigate();
  const auth = useAuth();
  const onSelectedType = (type: string) => {
    console.log(type);
    setCurrentStep(currentStep + 1);
    if (type) {
      setAccountType(type);
    }
  };

  const sizeStep: { [key: string]: ReactElement } = {
    1: <ChooseAccountStep auth={auth} onSelectedType={onSelectedType}></ChooseAccountStep>,
    2: (
      <CreateUserProfile
        auth={auth}
        accountType={accountType}
        onNextStep={() => setCurrentStep(currentStep + 1)}></CreateUserProfile>
    ),
    3:
      accountType === ACCOUNT_TYPE.PERSONAL ? (
        <AddLocationStep
          accountType={accountType}
          onNextStep={() => setCurrentStep(currentStep + 1)}></AddLocationStep>
      ) : (
        <CreateStoreFrontStep
          auth={auth}
          onNextStep={() => setCurrentStep(currentStep + 1)}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          navigate={navigate}
        />
      ),
    4: <CongratulationStep accountType={accountType}></CongratulationStep>
  };

  return (
    <div className="w-full h-full flex py-0 ">
      {currentStep === 3 && accountType === ACCOUNT_TYPE.BUSINESS ? (
        <div className=""></div>
      ) : (
        <div className=" h-screen overflow-y-scroll hidden lg:flex flex-col justify-between lg:min-w-[464px] px-14 py-4  bg-gradient-to-r from-transparent to-[#F9FAFB]">
          <div className="h-full">
            <DogeDomain className="mt-4"></DogeDomain>

            <BackNavigation
              className="mt-10 mb-14"
              onBackNavClick={() => {
                currentStep >= 2 ? setCurrentStep(currentStep - 1) : navigate(-1);
              }}></BackNavigation>

            <ProgressSteps
              onNextStep={() => setCurrentStep(currentStep + 1)}
              steps={progressContentHandler(accountType)}
              currentStep={currentStep}></ProgressSteps>
          </div>
        </div>
      )}
      <div className="h-screen flex flex-col overflow-auto w-full">
        <div className="flex-grow w-full">
          <CreateAccountHead
            onBackNavClick={() => {
              currentStep >= 2 ? setCurrentStep(currentStep - 1) : navigate(-1);
            }}
            className=" block lg:hidden"></CreateAccountHead>
          <div className="flex flex-col justify-start h-full">{sizeStep[currentStep]}</div>
        </div>
        <ProgressDotSteps
          className="flex-shrink-0"
          steps={progressContentHandler(accountType)?.length}
          currentStep={currentStep}></ProgressDotSteps>
      </div>
    </div>
  );
};

export default CreateAccount;
