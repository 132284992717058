import { FC, useState } from 'react';
import Modal from './Modal';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import InputField from '../shared/InputField';
import { useHandleNewPassword } from '../../hooks/useHandleNewPassword';
import InlineAlert from '../shared/InlineAlert';
import { ApolloError } from '@apollo/client/errors';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../lib/redux/reducers/modal.slice';
import { useNavigate } from 'react-router-dom';
type Props = {
  isOpen: boolean;
  onCloseNewPasswordModal: (arg0: boolean) => void;
};

const NewPasswordModal: FC<Props> = ({ isOpen, onCloseNewPasswordModal }) => {
  const [submiting, setSubmiting] = useState(false);
  const { onSubmit, register, handleSubmit, errors } = useHandleNewPassword();
  const intl = useIntl();
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, SetAlertType] = useState<'default' | 'success' | 'error'>('success');

  const onChangePassword = async (data: any) => {
    try {
      setSubmiting(true);
      const response = await onSubmit(data);
      if (response) {
        navigate('/');
        dispatch(hideModal('newPassword'));
        dispatch(showModal('login'));
      }
    } catch (error) {
      setShowAlert(true);
      SetAlertType('error');

      if (error instanceof ApolloError) {
        setAlertMessage(error?.message);
      } else {
        setAlertMessage(
          intl.formatMessage({
            id: TRANSLATED_CONSTANTS.NETWORK_ERROR.id,
            defaultMessage: TRANSLATED_CONSTANTS.NETWORK_ERROR.defaultMessage
          })
        );
      }
    } finally {
      setSubmiting(false);
    }
  };

  return (
    <Modal
      onCancel={() => onCloseNewPasswordModal(false)}
      isOpen={isOpen}
      confirmText={TRANSLATED_CONSTANTS.New_PASSWORD_MODAL_CONFIRM_BUTTON}
      title={TRANSLATED_CONSTANTS.New_PASSWORD_MODAL_TITLE}
      actionButtonsFlow="vertical"
      theme="primary"
      headerType="center"
      containsLogo={true}
      formId="new-password"
      isSubmiting={submiting}
      hasCancelButton={false}>
      <form id="new-password" action="#" method="POST" onSubmit={handleSubmit(onChangePassword)}>
        <InlineAlert
          state={showAlert}
          onChangeState={setShowAlert}
          type={alertType}
          className="mt-4"
          text={alertMessage}></InlineAlert>
        <div className="w-full mt-5">
          <InputField
            name="currentPassword"
            label={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.New_PASSWORD.id,
              defaultMessage: TRANSLATED_CONSTANTS.New_PASSWORD.defaultMessage
            })}
            type="password"
            placeholder=""
            register={register}
            errors={errors?.currentPassword}
            hintText={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.id,
              defaultMessage: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.defaultMessage
            })}
            required={true}
          />
        </div>
        <div className="w-full mt-4">
          <InputField
            name="confirmPassword"
            label={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.CONFIRM_PASSWORD.id,
              defaultMessage: TRANSLATED_CONSTANTS.CONFIRM_NEW_PASSWORD.defaultMessage
            })}
            type="password"
            placeholder=""
            register={register}
            errors={errors?.confirmPassword}
            required={true}
            hintText={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
              defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
            })}
          />
        </div>
      </form>
    </Modal>
  );
};

export default NewPasswordModal;
