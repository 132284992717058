import { useDispatch } from 'react-redux';
import { AuthUserState } from '../context/AuthUserProvider';
import { showModal } from '../lib/redux/reducers/modal.slice';
import { useSaveListing } from './listing/useSaveListing';
import { useUnsaveListing } from './listing/useUnsaveListing';
import { useSaveStorefront } from './storfront/useSaveStorefront';
import { useUnSaveStorefront } from './storfront/useUnSaveStorefront';
import { getCurrentDate } from '../util/CurrentDate';

export const useHandleSave = (refetch: any) => {
  const { authUser } = AuthUserState();
  const saveListing = useSaveListing();
  const saveStorefront = useSaveStorefront();
  const unSaveListing = useUnsaveListing();
  const unSaveStorefront = useUnSaveStorefront();
  const dispatch = useDispatch();
  const save = async (
    id: number | string,
    type: string,
    liked: boolean,
    savedId: number | undefined
  ) => {
    if (!authUser) {
      dispatch(showModal('login'));
    } else {
      if (type === 'storefront') {
        if (!liked) {
          await saveStorefront({
            variables: {
              data: {
                store_front: Number(id),
                user: authUser?.id,
                publishedAt: getCurrentDate()
              }
            }
          }).then((response: any) => {
            if (response) {
              refetch();
            }
          });
        } else {
          if (savedId) {
            await unSaveStorefront({ variables: { id: savedId } }).then((response: any) => {
              if (response) {
                refetch();
              }
            });
          }
        }
      } else {
        if (!liked) {
          await saveListing({
            variables: {
              data: { user: authUser?.id, listing: Number(id), publishedAt: getCurrentDate() }
            }
          }).then((response: any) => {
            if (response) {
              refetch();
            }
          });
        } else {
          if (savedId) {
            await unSaveListing({ variables: { id: savedId } }).then((response: any) => {
              if (response) {
                refetch();
              }
            });
          }
        }
      }
    }
  };
  return { save };
};
