import clsx from 'clsx';
import React, { FC, useEffect } from 'react';
import Footer from '../footer/footer';

type Props = {
  children: React.ReactElement;
  className?: string;
  hasFooter?: boolean;
  hasSpeicifWidth?: boolean;
};

const PageWrapper: FC<Props> = ({
  children,
  className,
  hasFooter = true,
  hasSpeicifWidth = false
}) => {
  // Functional component
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div
        className={clsx(
          'pt-12 pb-16 md:pt-16 md:pb-[96px] w-full ',
          hasSpeicifWidth ? 'lg:max-w-[1216px] lg:mx-auto' : '',
          className ? className : 'px-4 md:px-[60px] xl:px-0'
        )}>
        {children}
      </div>
      {hasFooter && <Footer />}
    </div>
  );
};

export default PageWrapper;
