import React, { FC, useState } from 'react';
import { useIntl } from 'react-intl';
import { User } from '../../../model/UserModel';
import { Typo } from '../../../components/Typo';
import Divider from '../../../components/shared/Divider';
import Pagination from '../../../components/shared/Pagination';
import Loader from '../../../components/shared/Loader';
import CheckboxField from '../../../components/shared/CheckboxField';
import UserInfo from '../../../components/shared/table/UserInfo';
import Badge from '../../../components/shared/Badge';
import Button from '../../../components/shared/Button';
import TrashIcon from '../../../components/icons/TrashIcon';
import clsx from 'clsx';
import Table from '../../../components/shared/table/Table';
import { handleAccountTypeBadge } from '../../../util/ListingBadgeHandler';
import BlockedUserTableHead from './BlockedUserTableHead';
import EmptyRow from '../Banner User/EmptyRow';

type Props = {
  user: User;
  recordPerPage: number;
  loading: boolean;
  blockedUsers: any;
  blockedUsersCounts: number;
  fetchMore: (data: any) => void;
  setOffset: (value: number) => void;
  onDelete: (user: any) => void;
};

const BlockedUsersTable: FC<Props> = (props) => {
  const {
    loading,
    blockedUsers,
    blockedUsersCounts,
    recordPerPage,
    fetchMore,
    setOffset,
    onDelete
  } = props;
  const intl = useIntl();
  const [checked, setChecked] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSelectedUsers(blockedUsers);
    } else {
      setSelectedUsers([]);
    }
  };

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * recordPerPage;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: recordPerPage }
    });
    setCurrentPage(pageNumber);
  };

  const size = 7 - blockedUsersCounts; // Ensure bannedUsers is not undefined
  const emptyUsers = size && size > 0 ? Array.from({ length: size }, (_, index) => index + 1) : [];

  return (
    <div className="w-full">
      <div className="w-full">
        {loading ? (
          <Loader className="h-[400px] w-full flex justify-center items-center" />
        ) : (
          <div className="min-w-full w-full overflow-x-auto  ">
            <Table>
              <BlockedUserTableHead checked={checked} toggleAll={toggleAll} />
              <tbody className="divide-y divide-gray-200 bg-white">
                <>
                  {blockedUsers?.map((user: any) => {
                    const accountType = handleAccountTypeBadge(user?.blocked?.type || '', intl);
                    const userExistsInSelectedUsers = selectedUsers.some(
                      (eachObj: any) => eachObj.id === user.id
                    );

                    return (
                      <tr
                        key={user?.username}
                        className={userExistsInSelectedUsers ? 'bg-gray-50' : undefined}>
                        <td className="relative  sm:w-12 sm:px-6 py-3">
                          <div className="absolute left-6 top-1/2 -mt-3">
                            <CheckboxField
                              name="state"
                              value="check"
                              label=""
                              size="md"
                              type="checkbox"
                              roundedSize="rounded-md"
                              isSelected={userExistsInSelectedUsers}
                              onChange={(e) =>
                                setSelectedUsers(
                                  e.target.checked
                                    ? [...selectedUsers, user]
                                    : selectedUsers.filter((p: any) => p.id !== user.id)
                                )
                              }
                              register={() => ({})}
                            />
                          </div>
                        </td>
                        <td className={clsx('whitespace-nowrap py-4 pr-3 ')}>
                          <UserInfo profileImage={user?.blocked?.profileImage?.url || ''}>
                            <div className="">
                              <Typo.textSm
                                fontWeight="font-medium"
                                className="text-gray-900 capitalize">
                                {user?.blocked?.name || ''}
                              </Typo.textSm>

                              <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                @{user?.blocked?.username || ''}
                              </Typo.textSm>
                            </div>
                          </UserInfo>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4">
                          <Badge
                            hasDot={true}
                            theme={accountType.theme}
                            label={accountType.name}></Badge>
                        </td>
                        <td className="whitespace-nowrap pr-3 py-4 text-sm text-gray-500">
                          <Button
                            onClick={() => onDelete(user)}
                            size="md"
                            theme="gray-link"
                            icon={<TrashIcon className="h-5 w-5" />}
                          />
                        </td>
                      </tr>
                    );
                  })}
                  {emptyUsers?.map((row, index) => {
                    return <EmptyRow key={index} />;
                  })}
                </>
              </tbody>
            </Table>
          </div>
        )}
      </div>
      <Divider />
      <div className="px-6">
        <Pagination
          currentPage={currentPage}
          totalCount={blockedUsersCounts}
          pageSize={recordPerPage}
          onPageChange={paginate}
          type="centered-page-number"
        />
      </div>
    </div>
  );
};

export default BlockedUsersTable;
