import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_USER = gql`
  query getUser($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          name
          username
          email
          blocked
          phone
          type
          allowNotification
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          sharingPoints
          referralCode
          decisions
          correctDecisions
          profileCompleted
          confirmed
          createdAt
          countryName
          countryCode
          headerImage {
            data {
              id
              attributes {
                url
              }
            }
          }
          language_proficiencies {
            data {
              id
              attributes {
                level
                language {
                  data {
                    id
                    attributes {
                      title
                    }
                  }
                }
              }
            }
          }
          location {
            address
            latitude
            longitude
            country
            city
            state
            street
          }
          banned_user {
            data {
              attributes {
                banned {
                  data {
                    id
                  }
                }
              }
            }
          }
          role {
            data {
              id
              attributes {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetUser = (id: string) => {
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });
  return {
    user: flattenObj(data?.usersPermissionsUser),
    userLoading: loading,
    userError: error,
    userRefetch: refetch
  };
};
