import DashboardHeader from '../../../components/shared/Dashboard Header/DashboardHeader';
import SettingWrapper from '../../../components/wrapper/SettingWrapper';
import { AuthUserState } from '../../../context/AuthUserProvider';
import TableContainer from '../../../components/shared/table/TableContainer';
import { Typo } from '../../../components/Typo';
import Button from '../../../components/shared/Button';
import PlusIcon from '../../../components/icons/PlusIcon';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useIntl } from 'react-intl';
import BannedUsersTable from './BannedUserTable';
import { useGetUsersBanned } from '../../../hooks/settings/useGetUsersBanned';
import { ROLE_TYPES, ROUTES } from '../../../constants/data';
import { useGetUserRole } from '../../../hooks/useRole';
import BanUserCommandMenu from '../../../components/Command Menus/BanUserCommandMenu';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../../lib/redux/reducers/modal.slice';
import { User } from '../../../model/UserModel';
import { setProfileData, showSlide } from '../../../lib/redux/reducers/slide.slice';
import { useUnBanUser } from '../../../hooks/settings/useUnBanUser';
import { useNavigate } from 'react-router-dom';
import { setEmailData } from '../../../lib/redux/reducers/email.slice';
const BannedUsers = () => {
  const userState = AuthUserState();
  const user = userState.authUser;
  const userRole = useGetUserRole();
  const [offset, setOffset] = useState(0);
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const banModalSate = useAppSelector((state) => state?.modal?.banUser);
  const NUMBER_OF_RECORDS_PER_PAGE = 7;
  const { loading, bannedUsers, bannedUsersCounts, refetch, fetchMore } = useGetUsersBanned(
    offset,
    NUMBER_OF_RECORDS_PER_PAGE,
    '',
    user?.id
  );

  const onSearch = async (value: string) => {
    await refetch({ offset: 0, limit: NUMBER_OF_RECORDS_PER_PAGE, searchText: value });
  };

  const onSendEmail = (user: User) => {
    navigate(ROUTES.REPORTS);
    dispatch(setEmailData(user?.email));
  };

  const onCloseModal = async (user?: User) => {
    dispatch(hideModal('banUser'));
    await refetch();
    if (user) {
      if (user) {
        dispatch(setProfileData({ userId: user?.id }));
        dispatch(showSlide('profile'));
      }
    }
  };
  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <div className="w-full">
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
            <DashboardHeader
              title={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.BANNED_USERS.id,
                defaultMessage: TRANSLATED_CONSTANTS.BANNED_USERS.defaultMessage
              })}
              accountType={user?.type}
              onSearch={onSearch}
            />
          </div>

          <div className="mx-auto max-w-7xl w-full">
            <div className="divide-y divide-gray-200">
              <div className="w-full md:grid lg:grid-cols-1">
                <div className="py-5 md:px-0 px-4 w-full space-y-6 flex flex-col lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                  <div className="md:flex md:justify-between lg:flex-col lg:justify-start lg:col-span-2 space-y-5">
                    <div>
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700  ">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.ACCOUNTS.id}
                          defaultMessage={TRANSLATED_CONSTANTS.ACCOUNTS.defaultMessage}
                        />
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500 ">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.BANNED_USERS_LISTED_HERE.id}
                          defaultMessage={
                            TRANSLATED_CONSTANTS.BANNED_USERS_LISTED_HERE.defaultMessage
                          }
                        />
                      </Typo.textSm>
                    </div>
                    {}
                    {userRole == ROLE_TYPES.SUPER_ADMIN && (
                      <Button
                        size="md"
                        theme="primary-yellow"
                        onClick={() => dispatch(showModal('banUser'))}
                        className="w-full md:w-auto lg:w-fit"
                        icon={<PlusIcon className="h-5 w-5 text-gray-900" />}>
                        <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON.id}
                            defaultMessage={
                              TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON.defaultMessage
                            }
                          />
                        </Typo.textSm>
                      </Button>
                    )}
                  </div>
                  <div className="lg:col-span-4">
                    <TableContainer>
                      <div>
                        <BannedUsersTable
                          loading={loading}
                          bannedUsers={bannedUsers}
                          bannedUsersCounts={bannedUsersCounts}
                          user={user}
                          recordPerPage={NUMBER_OF_RECORDS_PER_PAGE}
                          setOffset={setOffset}
                          fetchMore={fetchMore}
                          refetch={refetch}
                          onSendEmail={onSendEmail}
                        />
                      </div>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {banModalSate && (
            <BanUserCommandMenu isOpen={banModalSate} user={user} onClose={onCloseModal} />
          )}
        </div>
      </SettingWrapper>
    </div>
  );
};

export default BannedUsers;
