import clsx from 'clsx';
import React from 'react';
import { Typo } from '../Typo';

type Props = {
  theme?: 'dark' | 'light';
  arrow?:
    | 'none'
    | 'bottom-center'
    | 'bottom-left'
    | 'bottom-right'
    | 'left'
    | 'right'
    | 'top-center';
  title?: string;
  children?: any;
  supportingTitle?: string;
  [x: string]: any;
};

const Tooltip: React.FC<Props> = (props) => {
  const { theme = 'light', arrow = 'none', title = '', supportingTitle = '', children } = props;

  return (
    <div className="flex justify-center group items-center">
      <div className="relative inline-block">
        <span className="block cursor-pointer ">{children}</span>
        {arrow !== 'none' && (
          <svg
            width="16"
            height="9"
            className={clsx(
              'absolute w-4 invisible z-20 ',
              {
                'bottom-full left-1/2 -top-2.5 -translate-x-1/2 group-hover:visible':
                  arrow === 'bottom-center',
                'bottom-full left-2.5 -top-2 group-hover:visible': arrow === 'bottom-left',
                'bottom-full right-2.5 -top-2 group-hover:visible ': arrow === 'bottom-right',
                'top-1/2 -right-[6px] -translate-y-1/2 rotate-90 group-hover:visible':
                  arrow === 'left',
                'top-1/2 -left-[6px] -translate-y-1/2 -rotate-90 group-hover:visible':
                  arrow === 'right',
                '-bottom-1 left-1/2 -translate-x-1/2 rotate-180 group-hover:visible':
                  arrow === 'top-center'
              },
              theme == 'light' ? 'fill-white  shadow-lg' : 'fill-gray-900'
            )}
            viewBox="0 0 16 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0711 0.485289C14.962 0.485289 15.4081 1.56243 14.7782 2.1924L8.70711 8.26347C8.31658 8.654 7.68342 8.654 7.29289 8.26347L1.22183 2.1924C0.591867 1.56243 1.03803 0.485289 1.92894 0.485289L14.0711 0.485289Z" />
          </svg>
        )}
        <div
          className={clsx(
            'absolute z-10 invisible group-hover:visible py-2 px-3 font-inter text-sx rounded-lg shadow-lg',
            {
              'bottom-full left-1/2 -translate-x-1/2 -translate-y-2': arrow === 'bottom-center',
              'bottom-full left-0 -translate-x-5': arrow === 'bottom-left',
              'bottom-full right-0 translate-x-5': arrow === 'bottom-right',
              'top-1/2 left-full -translate-y-1/2': arrow === 'left',
              'top-1/2 right-full -translate-y-1/2': arrow === 'right',
              'top-full left-1/2 -translate-x-1/2': arrow === 'top-center',
              'bottom-full left-1/2 -translate-x-1/2 border border-gray-100': arrow === 'none'
            },
            theme === 'light' ? 'bg-white' : 'bg-gray-900 text-white'
          )}>
          <h3 className={clsx('whitespace-nowrap font-semibold')}>
            <Typo.textXs
              fontWeight="font-semibold"
              className={`whitespace-nowrap font-semibold ${
                theme == 'light' ? 'text-gray-700' : 'text-white'
              }`}>
              {title}
            </Typo.textXs>
          </h3>
          {supportingTitle && (
            <p>
              <Typo.textXs
                className={clsx(
                  'w-[200px] sm:w-[320px] font-normal mt-1',
                  theme == 'light' ? ' text-gray-500' : ' text-white'
                )}
                fontWeight="font-normal">
                {supportingTitle}
              </Typo.textXs>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
