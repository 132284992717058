import React from 'react';
import Spinner from './Spinner';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
type Props = {
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  icon?: any;
  iconPos?: 'top' | 'bottom' | 'left' | 'right' | undefined;
  type?: 'button' | 'submit' | 'reset' | undefined;
  to?: string;
  circle?: boolean;
  theme?:
    | 'primary-yellow'
    | 'warning'
    | 'primary-blue'
    | 'info'
    | 'base'
    | 'error'
    | 'gray'
    | 'yellow-link'
    | 'blue-link'
    | 'gray-link'
    | 'base-transparent'
    | 'error-outline';
  children?: any;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  hasOpacity?: boolean;
  [x: string]: any;
};
const Button: React.FC<Props> = (props) => {
  const {
    size = 'md',
    icon = null,
    iconPos = 'left',
    type = 'button',
    children = '',
    theme = '',
    to,
    disabled = false,
    loading = false,
    circle = false,
    className,
    hasOpacity = true,
    ...rest
  } = props;

  const paddingMap = {
    sm: ` ${children ? 'py-2 px-3.5' : 'p-2'}`,
    md: ` ${children ? 'py-2.5 px-4' : 'p-2.5'} `,
    lg: ` ${children ? 'py-2.5 px-[18px]' : 'p-3'} `,
    xl: ` ${children ? 'py-3 px-5' : 'p-3.5'} `,
    '2xl': `${children ? 'py-4 px-7 ' : 'p-4'}`
  };

  const designMap: { [key: string]: string } = {
    'base-transparent': `bg-transparent border border-gray-300  text-gray-700  focus:border-gray-300 focus:shadow-base disabled:bg-white disabled:border-gray-200 disabled:text-gray-300 ${paddingMap[size]}`,
    base: `bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 hover:text-gray-800 focus:bg-white focus:border-gray-300 focus:shadow-base disabled:bg-white disabled:border-gray-200 disabled:text-gray-300 ${paddingMap[size]}`,
    'error-outline': `bg-white border border-error-300 hover:bg-gray-50 text-error-700 hover:text-error-800 focus:bg-white focus:border-error-300 focus:shadow-base disabled:bg-white disabled:border-error-200 disabled:text-error-300 ${paddingMap[size]}`,
    gray: `bg-white border border-white text-gray-600 hover:bg-gray-50 hover:border-gray-50 hover:text-gray-800 focus:bg-white focus:shadow-base focus:border-white disabled:text-gray-300 disabled:bg-white  ${
      paddingMap[size]
    } ${hasOpacity ? 'opacity-50' : ''}`,
    'primary-yellow': `bg-yellow-300 border border-yellow-300 text-gray-900 shadow-xs hover:bg-yellow-200 hover:border-yellow-200 focus:bg-yellow-100 focus:border-yellow-100 focus:shadow-primary-yellow disabled:bg-yellow-300 disabled:border-yellow-300 disabled:opacity-50 ${paddingMap[size]}`,
    warning: `bg-yellow-50 shadow-xs border border-yellow-50 text-yellow-700 hover:bg-yellow-100 hover:border-yellow-100 hover:text-yellow-800 focus:border-yellow-300 focus:bg-yellow-50 focus:text-yellow-700 focus:shadow-primary-yellow disabled:opacity-50 ${paddingMap[size]}`,
    'primary-blue': `bg-blue-700 text-white border border-blue-700 shadow-xs hover:bg-blue-800 hover:border-blue-800 focus:bg-blue-50 focus:border-blue-50 focus:text-blue-700 disabled:bg-blue-700 focus:shadow-primary-blue disabled:border-blue-700 disabled:opacity-50 ${paddingMap[size]}`,
    info: `border border-blue-50 bg-blue-50 text-blue-700 shadow-xs hover:border-blue-100 hover:bg-blue-100  focus:bg-blue-50 focus:border-blue-50 focus:text-blue-700 focus:shadow-info disabled:bg-blue-50 disabled:opacity-50 ${paddingMap[size]}`,
    error: `border border-error-600 bg-error-600 text-white hover:border-error-700 hover:bg-error-700 shadow-xs focus:bg-error-600 focus:border-error-600 focus:shadow-error disabled:bg-error-200 disabled:border-error-200 disabled:shadow-xs disabled:opacity-50 ${paddingMap[size]}`,
    'yellow-link':
      'text-yellow-700 hover:text-yellow-800 focus:text-yellow-500 disabled:text-gray-300',
    'blue-link':
      'text-blue-gray-600 hover:text-blue-gray-500 focus:text-yellow-500 disabled:text-gray-300',
    'gray-link':
      'text-gray-600 hover:text-gray-500 focus:text-yellow-500 disabled:text-gray-300 flex items-center',
    '': `${paddingMap[size]}`
  };
  return (
    <>
      {to ? (
        <Link to={to || '#'} className="p-0">
          <button
            type={type}
            disabled={disabled}
            className={clsx(
              ` disabled:cursor-not-allowed focus:outline-none ${
                circle ? 'rounded-full' : 'rounded-lg'
              }`,
              designMap[theme],
              className
            )}
            {...rest}>
            <div
              className={clsx('flex items-center h-full w-full justify-center gap-x-2 ', {
                'flex-row-reverse': iconPos == 'right'
              })}>
              {loading && <Spinner className=" w-5 h-5 leading " />}
              {icon && <div>{icon}</div>}
              {!loading && children}
            </div>
          </button>
        </Link>
      ) : (
        <button
          type={type}
          disabled={disabled}
          className={clsx(
            ` disabled:cursor-not-allowed focus:outline-none ${
              circle ? 'rounded-full' : 'rounded-lg'
            }`,
            designMap[theme],
            className
          )}
          {...rest}>
          <div
            className={clsx('flex items-center h-full w-full justify-center gap-x-2 ', {
              'flex-row-reverse': iconPos === 'right'
            })}>
            {loading && <Spinner className="w-5 h-5 leading " />}
            {icon && <div>{icon}</div>}
            {!loading && children}
          </div>
        </button>
      )}
    </>
  );
};

export default Button;
