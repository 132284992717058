/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { User } from '../../../model/UserModel';
import TableContainer from '../../../components/shared/table/TableContainer';
import TableTitle from '../../../components/shared/table/TableTitle';
import TableBanner from '../../../components/shared/table/TableBanner';
import { ButtonGroupModel } from '../../../model/ButtonGroupModel';
import DeletedListings from './deletedAndQuarantine/DeletedListings';
import { useIntl } from 'react-intl';
import { handleReportPageInfo } from '../../../util/ReportUtil';
import FlagedListings from './flaged/FlagedListings';
import QuarantineListings from './deletedAndQuarantine/QuarantineListings';

type Props = {
  user: User;
};

const handleButtonGroup = (id: number | string) => {
  const groupButtonMap: { [key: string]: ReactElement | string } = {
    1: <FlagedListings />,
    2: <QuarantineListings />,
    3: <DeletedListings />
  };
  return groupButtonMap[id];
};

const Listings: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const [listingTable, setListingTable] = useState<ButtonGroupModel>();

  useEffect(() => {
    setListingTable({ id: 1, content: 'Flags', disabled: false });
  }, []);

  const onHandleButtonGroup = (option: ButtonGroupModel) => {
    setListingTable(option);
  };
  const pageInfo = listingTable && handleReportPageInfo(listingTable.id, intl);
  return (
    <div className="">
      <TableContainer>
        <div className="py-5">
          <TableTitle title={pageInfo?.title} description={pageInfo?.description} />
        </div>
        <TableBanner
          type="with-group-button"
          groupButtonHandler={(option: ButtonGroupModel) => onHandleButtonGroup(option)}
        />
        <div>{listingTable && handleButtonGroup(listingTable.id)}</div>
      </TableContainer>
    </div>
  );
};

export default Listings;
