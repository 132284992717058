import { useDispatch } from 'react-redux';
import { AuthUserState } from '../context/AuthUserProvider';
import { setChatData, showSlide } from '../lib/redux/reducers/slide.slice';
import { showModal } from '../lib/redux/reducers/modal.slice';
import { ListingNew } from '../model/Listing';

export const useHandleMessageSenderAuth = (listing?: ListingNew) => {
  const dispatch = useDispatch();
  const { authUser } = AuthUserState();
  const onSendMessage = () => {
    if (authUser && authUser.id !== listing?.user?.id && authUser.profileCompleted) {
      dispatch(
        setChatData({
          sender: {
            profileImage: { url: authUser.profileImage?.url },
            name: authUser.name,
            username: authUser.username,
            id: authUser.id
          },
          receiver: listing?.user
        })
      );
      dispatch(showSlide('message'));
    } else {
      if (!authUser) {
        dispatch(showModal('login'));
      } else if (authUser && !authUser.profileCompleted) {
        dispatch(showModal('completeAccountToSendMessage'));
      }
    }
  };
  return { onSendMessage };
};
