type Props = {
  className?: string;
};
const EditorBoldIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}>
      <path
        d="M19.6 14.79C20.57 14.12 21.25 13.02 21.25 12C21.25 9.74 19.5 8 17.25 8H11V22H18.04C20.13 22 21.75 20.3 21.75 18.21C21.75 16.69 20.89 15.39 19.6 14.79ZM14 10.5H17C17.83 10.5 18.5 11.17 18.5 12C18.5 12.83 17.83 13.5 17 13.5H14V10.5ZM17.5 19.5H14V16.5H17.5C18.33 16.5 19 17.17 19 18C19 18.83 18.33 19.5 17.5 19.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditorBoldIcon;
