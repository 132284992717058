import { gql, useQuery } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_USER = gql`
  query getUser($id: ID!) {
    usersPermissionsUser(id: $id) {
      data {
        id
        attributes {
          name
          username
          email
          blocked
          phone
          type
          allowNotification
          profileImage {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetMessageReceiver = (id: number) => {
  const { loading, error, data, refetch } = useQuery(GET_USER, {
    variables: { id },
    fetchPolicy: 'cache-and-network'
  });
  return {
    receiver: flattenObj(data?.usersPermissionsUser),
    userLoading: loading,
    userError: error,
    userRefetch: refetch
  };
};
