import { gql, useMutation } from '@apollo/client';

interface DeleteMessageReportInput {
  reportIds: number[];
}
const DELETE_MESSAGE_REPORT = gql`
  mutation DeleteMeessageReports($reportIds: [ID!]) {
    deleteMeessageReports(reportIds: $reportIds) {
      count
    }
  }
`;

export const useDeleteMessageReport = (): ((
  deleteMessageReports: DeleteMessageReportInput
) => any) => {
  const [deleteMessageReports] = useMutation(DELETE_MESSAGE_REPORT);
  return (input: DeleteMessageReportInput) => deleteMessageReports({ variables: input });
};
