import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/app/App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import client from './services/apollo-client';
import { IntlProvider } from 'react-intl';
import English from '../src/i18n/translations/en.json';
import French from '../src/i18n/translations/fr.json';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { store } from './lib/redux/store';
import { AuthProvider } from './context/Authn';
import ChatNotificationProvider from './context/ChatNotificationProvider';
import AuthUserProvider from './context/AuthUserProvider';
import PriceProvider from './context/PriceProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const locale = navigator.language;
let lang;
if (locale === 'en') {
  lang = English;
} else {
  if (locale === 'fr') {
    lang = French;
  } else {
    lang = English;
  }
}
root.render(
  <React.StrictMode>
    <IntlProvider locale={locale} messages={lang}>
      <HelmetProvider>
        <Helmet>
          <title>Dogelist.com</title>
        </Helmet>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <AuthProvider>
              <AuthUserProvider>
                <ChatNotificationProvider>
                  <PriceProvider>
                    <App />
                  </PriceProvider>
                </ChatNotificationProvider>
              </AuthUserProvider>
            </AuthProvider>
          </Provider>
        </ApolloProvider>
      </HelmetProvider>
    </IntlProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
