import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUpdateUser } from '../../hooks/user/useUpdateUser';
import { useAuth } from '../../context/Authn';
import { ACCOUNT_TYPE } from '../../constants/data';
import { LocationType } from '../../model/Location';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import InputField from '../../components/shared/InputField';
import Button from '../../components/shared/Button';
import { Typo } from '../../components/Typo';
import SettingWrapper from '../../components/wrapper/SettingWrapper';
import LocationDropdown from '../../components/shared/location Dropdown/LocationDropdown';
import Location from '../../components/shared/map/Location';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import SaveIcon from '../../components/icons/SaveIcon';

const LocationMeetUp: React.FC = () => {
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [locationSelected, setLocationSelected] = useState(false);
  const updateUser = useUpdateUser();
  const auth = useAuth();
  const intl = useIntl();
  const [coords, setCoords] = useState<number[]>([51.505, -0.09]);
  const user = auth?.getUser();
  const schema = yup.object().shape({
    latitude: yup
      .number()
      .nullable()
      .default(null)
      .transform((value, originalValue) =>
        originalValue === null || originalValue === '' ? null : value
      ),
    longitude: yup
      .number()
      .nullable()
      .default(null)
      .transform((value, originalValue) =>
        originalValue === null || originalValue === '' ? null : value
      ),
    street: yup.string().nullable().default(null),
    city: yup.string().required('Please enter your city'),
    state: yup
      .string()
      .required(
        user?.type == ACCOUNT_TYPE.PERSONAL
          ? 'Please enter your state/province'
          : 'Please enter your state/province'
      ),
    country: yup.string().required('Please enter your country')
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    try {
      setIsSubmiting(true);
      const response = await updateUser({
        variables: {
          data: {
            location: {
              latitude: data.latitude,
              longitude: data.longitude,
              street: data?.street,
              city: data.city,
              state: data.state,
              country: data.country
            },
            profileCompleted: true
          },
          id: user?.id
        }
      });
      if (response) {
        auth.userRefetch();
        setIsSubmiting(false);
      }
    } catch (e) {
      setIsSubmiting(false);
    }
  };

  const onPlaceSelected = (location: LocationType) => {
    setLocationSelected(true);
    setCoords([location?.latitude, location?.longitude]);
    setValue('street', location?.street || '', { shouldValidate: true });
    setValue('city', location?.city || '', { shouldValidate: true });
    setValue('country', location?.country || '', {
      shouldValidate: true
    });
    setValue('state', location?.state || '', {
      shouldValidate: true
    });
    setValue('latitude', location?.latitude || 0, { shouldValidate: true });
    setValue('longitude', location?.longitude || 0, { shouldValidate: true });
  };

  const onCancel = async () => {
    onPlaceSelected(user?.location);
  };

  useEffect(() => {
    onPlaceSelected(user?.location);
  }, [user]);

  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <div className="w-full">
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
            <DashboardHeader
              title={intl.formatMessage({
                id:
                  user?.type == ACCOUNT_TYPE.PERSONAL
                    ? TRANSLATED_CONSTANTS.MEET_UP_LOCATION.id
                    : TRANSLATED_CONSTANTS.LOCATION_SERVICES.id,
                defaultMessage:
                  user?.type == ACCOUNT_TYPE.PERSONAL
                    ? TRANSLATED_CONSTANTS.MEET_UP_LOCATION.defaultMessage
                    : TRANSLATED_CONSTANTS.LOCATION_SERVICES.defaultMessage
              })}
              description={intl.formatMessage({
                id:
                  user?.type == ACCOUNT_TYPE.PERSONAL
                    ? TRANSLATED_CONSTANTS.YOU_CAN_CHANGE_MEETUP_LOCATION.id
                    : TRANSLATED_CONSTANTS.YOU_CAN_CHANGE_LOCATION_PERMISSION.id,
                defaultMessage:
                  user?.type == ACCOUNT_TYPE.PERSONAL
                    ? TRANSLATED_CONSTANTS.YOU_CAN_CHANGE_MEETUP_LOCATION.defaultMessage
                    : TRANSLATED_CONSTANTS.YOU_CAN_CHANGE_LOCATION_PERMISSION.defaultMessage
              })}
              accountType={user?.type}
            />
          </div>
          <div className=" max-w-7xl mx-auto">
            <form id="add-location" onSubmit={handleSubmit(onSubmit)} action="#" method="POST">
              <div className="w-full divide-y divide-gray-200">
                <div className="w-full md:grid md:grid-cols-5 lg:grid-cols-1 px-4 md:px-0 ">
                  <div className="py-5 w-full space-y-1.5 flex flex-col md:col-span-5 lg:col-span-1 md:grid md:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                    <div className=" md:col-span-2">
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700 lg:col-span-1">
                        {user?.type == ACCOUNT_TYPE.PERSONAL
                          ? `${intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.MEETUP_LOCATION.id,
                              defaultMessage: TRANSLATED_CONSTANTS.MEETUP_LOCATION.defaultMessage
                            })} *`
                          : `${intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.STOREFRONT_LOCATION.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.STOREFRONT_LOCATION.defaultMessage
                            })} *`}
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        {user?.type == ACCOUNT_TYPE.PERSONAL
                          ? `${intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.ADD_MEETUP_LOCATION.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.ADD_MEETUP_LOCATION.defaultMessage
                            })}`
                          : intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.ADD_YOUR_STOREFRONT_LOCATION.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.ADD_YOUR_STOREFRONT_LOCATION.defaultMessage
                            })}
                      </Typo.textSm>
                    </div>
                    <div className=" md:col-span-4 xl:col-span-3 pt-5">
                      <div className=" px-4 ">
                        <div className="grid grid-cols-1 gap-y-3 lg:grid-cols-5 gap-x-4">
                          <div className="w-full lg:col-span-3 space-y-5">
                            <LocationDropdown
                              placeholder="City, Neighborhood, ZIP, Ad..."
                              onLocationSelect={onPlaceSelected}></LocationDropdown>
                          </div>
                          <div className="w-full lg:col-span-2 h-[164px] overflow-hidden bg-[#E2E2E2] rounded-lg">
                            <Location className="w-full min-w-[200px]" coords={coords} />
                          </div>
                        </div>

                        <div className={`${locationSelected ? 'block' : ' hidden lg:block'}`}>
                          <Typo.textXl fontWeight="font-medium" className="my-8 text-gray-900">
                            {intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.id,
                              defaultMessage:
                                TRANSLATED_CONSTANTS.TWEAK_YOUR_LOCATION.defaultMessage
                            })}
                          </Typo.textXl>

                          <div className="overflow-visible sm:rounded-md space-y-5 ">
                            <div className="grid grid-cols-2 gap-x-3">
                              <InputField
                                name="latitude"
                                label={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.LATITUDE.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.LATITUDE.defaultMessage
                                })}
                                placeholder="35.8973748"
                                register={register}
                                type="text"
                                errors={errors?.latitude}
                              />
                              <InputField
                                name="longitude"
                                label={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.LONGITUDE.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.LONGITUDE.defaultMessage
                                })}
                                placeholder="-119.887349"
                                register={register}
                                type="text"
                                errors={errors?.longitude}
                              />
                            </div>
                            <div className="w-full">
                              <InputField
                                name="street"
                                label={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.STREET.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.STREET.defaultMessage
                                })}
                                placeholder={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.ENTER_A_STREET.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.ENTER_A_STREET.defaultMessage
                                })}
                                helpIcon={true}
                                register={register}
                                type="text"
                                errors={errors?.street}
                              />
                            </div>
                            <div className="grid grid-cols-2 gap-x-3">
                              <InputField
                                name="city"
                                label={`${intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.CITY.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.CITY.defaultMessage
                                })}*`}
                                placeholder="San Diego"
                                register={register}
                                type="text"
                                errors={errors?.city}
                              />
                              <InputField
                                name="state"
                                label={`${intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.STATE_OR_PROVINE.id,
                                  defaultMessage:
                                    TRANSLATED_CONSTANTS.STATE_OR_PROVINE.defaultMessage
                                })}*`}
                                placeholder="California"
                                register={register}
                                type="text"
                                errors={errors?.state}
                              />
                            </div>

                            <div className="w-full">
                              <InputField
                                name="country"
                                label={`${intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.COUNTRY.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.COUNTRY.defaultMessage
                                })}*`}
                                placeholder="United States"
                                register={register}
                                type="text"
                                errors={errors?.country}
                              />
                            </div>
                            <Typo.textSm fontWeight="font-normal" className="w-full text-gray-500">
                              *
                              {intl.formatMessage({
                                id: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.id,
                                defaultMessage: TRANSLATED_CONSTANTS.REQUIRED_FIELDS.defaultMessage
                              })}
                            </Typo.textSm>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end gap-x-3 pt-5">
                  <Button onClick={onCancel} size="lg" theme="base">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CANCEL.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CANCEL.defaultMessage
                      })}
                    </Typo.textMd>
                  </Button>
                  <Button
                    size="lg"
                    loading={isSubmiting}
                    type="submit"
                    theme="primary-yellow"
                    icon={<SaveIcon className="h-5 w-4 text-gray-900" />}>
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.SAVE.id,
                        defaultMessage: TRANSLATED_CONSTANTS.SAVE.defaultMessage
                      })}
                    </Typo.textMd>
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </SettingWrapper>
    </div>
  );
};

export default LocationMeetUp;
