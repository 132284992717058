type Props = {
  className?: string;
};
const ScalesIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 text-gray-400 ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66675 14.1667V14.0415C1.66675 13.7778 1.66675 13.6459 1.68692 13.5174C1.70483 13.4033 1.73455 13.2914 1.77559 13.1835C1.82182 13.062 1.88725 12.9475 2.0181 12.7185L5.00008 7.5M1.66675 14.1667C1.66675 16.0076 3.15913 17.5 5.00008 17.5C6.84103 17.5 8.33342 16.0076 8.33342 14.1667M1.66675 14.1667V14C1.66675 13.7666 1.66675 13.65 1.71216 13.5608C1.75211 13.4824 1.81585 13.4187 1.89425 13.3787C1.98338 13.3333 2.10006 13.3333 2.33341 13.3333H7.66675C7.9001 13.3333 8.01678 13.3333 8.10591 13.3787C8.18431 13.4187 8.24805 13.4824 8.288 13.5608C8.33342 13.65 8.33342 13.7666 8.33342 14V14.1667M5.00008 7.5L7.98206 12.7185C8.11291 12.9475 8.17834 13.062 8.22457 13.1835C8.26561 13.2914 8.29533 13.4033 8.31324 13.5174C8.33342 13.6459 8.33342 13.7778 8.33342 14.0415V14.1667M5.00008 7.5L15.0001 5.83333M11.6667 12.5V12.3748C11.6667 12.1111 11.6667 11.9792 11.6869 11.8507C11.7048 11.7367 11.7345 11.6248 11.7756 11.5168C11.8218 11.3953 11.8872 11.2808 12.0181 11.0518L15.0001 5.83333M11.6667 12.5C11.6667 14.3409 13.1591 15.8333 15.0001 15.8333C16.841 15.8333 18.3334 14.3409 18.3334 12.5M11.6667 12.5V12.3333C11.6667 12.1 11.6667 11.9833 11.7122 11.8942C11.7521 11.8158 11.8159 11.752 11.8943 11.7121C11.9834 11.6667 12.1001 11.6667 12.3334 11.6667H17.6667C17.9001 11.6667 18.0168 11.6667 18.1059 11.7121C18.1843 11.752 18.2481 11.8158 18.288 11.8942C18.3334 11.9833 18.3334 12.1 18.3334 12.3333V12.5M15.0001 5.83333L17.9821 11.0518C18.1129 11.2808 18.1783 11.3953 18.2246 11.5168C18.2656 11.6248 18.2953 11.7367 18.3132 11.8507C18.3334 11.9792 18.3334 12.1111 18.3334 12.3748V12.5M10.0001 2.5V6.66667"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ScalesIcon;
