import clsx from 'clsx';
import React, { FC, ReactElement } from 'react';

type Props = {
  children: ReactElement;
  theme: 'light-circle' | 'light-circle-outline' | 'modern' | 'primary-dark-circle-outline';
  color: 'primary' | 'gray' | 'error' | 'warning' | 'success' | 'primary-dark';
  size: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
  type?: 'image' | 'icon';
  className?: string;
};

const sizeMap: { [key: string]: { icon: string; padding: string; ring: string; image: string } } = {
  xl: {
    icon: 'w-7 h-7 ',
    padding: 'p-[14px]',
    ring: 'ring-[10px]',
    image: 'w-[56px] h-[56px]'
  },
  lg: {
    icon: 'w-6 h-6',
    padding: 'p-3',
    ring: 'ring-8',
    image: 'w-12 h-12'
  },
  md: {
    icon: 'w-5 h-5',
    padding: 'p-[10px]',
    ring: 'ring-[6px]',
    image: 'w-10 h-10'
  },
  sm: {
    icon: 'w-4 h-4',
    padding: 'p-2',
    ring: 'ring-4',
    image: 'w-8 h-8'
  },
  xs: {
    icon: 'w-3 h-3',
    padding: 'p-[6px]',
    ring: 'ring-2',
    image: 'w-6 h-6'
  }
};

const handleSize = (size: string) => {
  return sizeMap[size];
};

const FeaturedIcon: FC<Props> = ({ theme, color, size, type = 'icon', children, className }) => {
  const customColor = color === 'primary' ? 'yellow' : color;
  const ringColors = {
    primary: 'ring-yellow-50',
    gray: 'ring-gray-50',
    warning: 'ring-warning-50',
    error: 'ring-error-50',
    success: 'ring-success-50',
    'primary-dark': 'ring-yellow-600'
  };
  const ringColor = ringColors[color];
  const sizes = handleSize(size);
  return (
    <div className={className}>
      {type === 'icon' ? (
        <div
          className={clsx(
            'flex items-center justify-center  w-fit rounded-full ',
            ``,
            sizes.padding,
            theme === 'light-circle-outline' || theme === 'primary-dark-circle-outline'
              ? `${sizes.ring} ${ringColor}`
              : '',
            theme === 'modern'
              ? `bg-white border-[1px] border-gray-200`
              : theme === 'primary-dark-circle-outline'
              ? 'bg-yellow-500'
              : `bg-${customColor}-100`
          )}>
          {React.cloneElement(children, {
            className: `${sizes.icon} ${
              theme === 'primary-dark-circle-outline' ? 'text-white' : `text-${customColor}-600`
            }`
          })}
        </div>
      ) : (
        <div>{React.cloneElement(children, { className: `${sizes.image} ` })}</div>
      )}
    </div>
  );
};

export default FeaturedIcon;
