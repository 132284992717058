import { gql, useMutation } from '@apollo/client';
interface SavedStorefrontInput {
  variables: {
    data: { user: number; store_front: number; publishedAt?: string };
  };
}

export const CREATE_SAVED_STOREFRONT = gql`
  mutation storeStorefront($data: SavedStorefrontInput!) {
    createSavedStorefront(data: $data) {
      data {
        id
      }
    }
  }
`;

export const useSaveStorefront = (): ((storeStorefront: SavedStorefrontInput) => any) => {
  const [createSavedStorefrontResult] = useMutation(CREATE_SAVED_STOREFRONT);
  return createSavedStorefrontResult;
};
