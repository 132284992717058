import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_ROLES = gql`
  query getAllRoles {
    usersPermissionsRoles {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const useGetRoles = () => {
  const { loading, error, data, refetch } = useQuery(GET_ROLES, {
    fetchPolicy: 'cache-and-network'
  });
  return {
    roles: flattenObj(data?.usersPermissionsRoles),
    loading,
    error,
    refetch
  };
};
