type Props = {
  className?: string;
};
const EditorFileIcon = ({ className }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      className={className}>
      <path
        d="M7.9 16C7.9 14.29 9.29 12.9 11 12.9H15V11H11C8.24 11 6 13.24 6 16C6 18.76 8.24 21 11 21H15V19.1H11C9.29 19.1 7.9 17.71 7.9 16ZM12 17H20V15H12V17ZM21 11H17V12.9H21C22.71 12.9 24.1 14.29 24.1 16C24.1 17.71 22.71 19.1 21 19.1H17V21H21C23.76 21 26 18.76 26 16C26 13.24 23.76 11 21 11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default EditorFileIcon;
