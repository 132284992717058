import { gql, useMutation } from '@apollo/client';

interface ListingReportsInput {
  listingIds: number[];
  reporterIds: number[];
}
const CLEAR_LISTING_FLAGS = gql`
  mutation DeleteListingReports($listingIds: [ID!], $reporterIds: [ID!]) {
    deleteReportListingsByListing(listingIds: $listingIds, reporterIds: $reporterIds) {
      count
    }
  }
`;

export const useClearListingFlags = (): ((deleteListingReports: ListingReportsInput) => any) => {
  const [deleteListingReports] = useMutation(CLEAR_LISTING_FLAGS);
  return (input: ListingReportsInput) => deleteListingReports({ variables: input });
};
