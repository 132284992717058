import React from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import DeletedListingsGridLayout from './DeletedListingsGridLayout';
import DeletedListingsDetailedLayout from './DeletedListingsDetailedLayout';

const DeletedListings = () => {
  const gridLayout = useAppSelector((state) => state.report.reportLayout);

  return (
    <div>
      {gridLayout.type === 'grid' ? (
        <DeletedListingsGridLayout />
      ) : (
        <DeletedListingsDetailedLayout />
      )}
    </div>
  );
};

export default DeletedListings;
