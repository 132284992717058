import React, { FC } from 'react';
import { ROLES } from '../../../constants/data';
import { useGetUserRole } from '../../../hooks/useRole';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useForm } from 'react-hook-form';
import Button from '../../../components/shared/Button';
import { Typo } from '../../../components/Typo';
import clsx from 'clsx';
import CopyIcon from '../../../components/icons/CopyIcon';

type Props = {
  email: string;
};

const Contact: FC<Props> = ({ email }) => {
  const role = useGetUserRole();
  const { register } = useForm();
  const handleCopy = async () => {
    await navigator.clipboard.writeText(email);
  };
  return (
    <div>
      {role === ROLES.SUPER_ADMIN && (
        <div className="flex flex-col md:flex-row gap-8 justify-between items-start">
          <div className="md:max-w-[280px]">
            <Typo.textSm fontWeight="font-medium" className="text-gray-600">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.CONTACT.id}
                defaultMessage={TRANSLATED_CONSTANTS.CONTACT.defaultMessage}
              />
            </Typo.textSm>
            <Typo.textSm fontWeight="font-normal" className="text-gray-500">
              <FormattedMessage
                id="page.report.detailed_contact_description"
                defaultMessage="You can contact the user via chat or email."
              />
            </Typo.textSm>
          </div>
          <div className="flex flex-1 items-end w-full">
            <div className="w-full relative space-y-1.5">
              <label htmlFor="email" className="left-0 text-left">
                <Typo.textSm fontWeight="font-normal" className={` text-gray-700 `}>
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.EMAIL.id}
                    defaultMessage={TRANSLATED_CONSTANTS.EMAIL.defaultMessage}
                  />
                </Typo.textSm>
              </label>
              <input
                id="email"
                {...register}
                type="email"
                defaultValue={email}
                disabled={true}
                className={clsx(
                  ` pr-[14px]  rounded-l-lg border shadow-xs w-full md:py-[10px]`,
                  ' border-gray-300  text-gray-500 hover:text-gray-900 focus:ring-0 focus:border-gray-300 focus:shadow-base  disabled:bg-gray-50 disabled:text-gray-400 '
                )}
              />
            </div>
            <div className="rounded-r-lg border border-t-gray-300 border-b-gray-300 border-r-gray-300">
              <Button
                onClick={handleCopy}
                className="p-[10px] hover:bg-gray-50"
                size="md"
                theme="gray-link"
                icon={<CopyIcon className="h-5 w-5 text-gray-700"></CopyIcon>}>
                <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.COPY.id}
                    defaultMessage={TRANSLATED_CONSTANTS.COPY.defaultMessage}
                  />
                </Typo.textMd>
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contact;
