type Props = {
  className?: string;
};
const ChevronIcon = ({ className }: Props) => (
  <svg
    className={' w-3 h-2  ' + className}
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 6.5L6 1.5L1 6.5"
      stroke="currentColor"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronIcon;
