import React, { ChangeEvent, ReactElement } from 'react';
import { Typo } from '../../Typo';
import Badge from '../Badge';
import Button from '../Button';
import TrashIcon from '../../icons/TrashIcon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useIntl } from 'react-intl';
import { handleAccountTypeBadge, handleUserRoleBadge } from '../../../util/ListingBadgeHandler';
import InputField from '../InputField';
import SearchIcon from '../../icons/SearchIcon';
type Props = {
  title?: string;
  description?: string;
  cancelButtonIcon?: ReactElement;
  confirmButtonIcon?: ReactElement;
  hasDeleteOption?: boolean;
  amount?: number;
  confirmType?: 'button' | 'submit';
  confirmButtonTitle?: string;
  cancelButtonTitle?: string;
  storefrontUrl?: string;
  cancelDisabled?: boolean;
  confirmDisabled?: boolean;
  onCancelClick?: () => void;
  onConfrimClick?: () => void;
  onDeleteClick?: () => void;
  onSearch?: (value: string) => void;
  accountType?: string;
  role?: string;
  editBtn?: ReactElement;
  deleteText?: string;
};
const DashboardSubHeader: React.FC<Props> = (props) => {
  const {
    title,
    description,
    amount,
    cancelButtonIcon,
    cancelButtonTitle,
    confirmButtonIcon,
    confirmButtonTitle,
    confirmType = 'button',
    hasDeleteOption = false,
    storefrontUrl,
    confirmDisabled = false,
    cancelDisabled = false,
    accountType,
    role,
    onCancelClick,
    onConfrimClick,
    onDeleteClick,
    onSearch,
    editBtn,
    deleteText
  } = props;
  const intl = useIntl();
  const account = handleAccountTypeBadge(accountType || '', intl);
  const roleBande = role ? handleUserRoleBadge(role, intl) : '';
  return (
    <div className=" flex flex-col items-start py-5 px-4 gap-y-4 md:px-0 w-full border-b border-b-gray-200 md:flex-row md:justify-between">
      <div className="gap-y-2 md:gap-y-1">
        <div className="flex flex-col gap-y-1 sm:flex-row justify-start sm:items-center gap-x-2">
          <div className="flex gap-x-2 items-center">
            <Typo.headingXs fontWeight="font-medium" className="text-gray-900">
              {title}
            </Typo.headingXs>
            {amount && <Badge theme="gray" size="sm" label={amount}></Badge>}
          </div>
          <div className="flex gap-x-2 items-center">
            {account && <Badge theme={account.theme} label={account.name}></Badge>}
            {roleBande && <Badge theme={roleBande.theme} label={roleBande.name}></Badge>}
          </div>
        </div>
        <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1 w-full">
          {description}
        </Typo.textSm>
      </div>
      <div className=" flex gap-x-3">
        {storefrontUrl && (
          <Button to={storefrontUrl} size="md" disabled={cancelDisabled} theme="base">
            <Typo.textSm fontWeight="font-semibold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.VIEW_STOREFRONT.id}
                defaultMessage={TRANSLATED_CONSTANTS.VIEW_STOREFRONT.defaultMessage}
              />
            </Typo.textSm>
          </Button>
        )}
        {hasDeleteOption && (
          <Button
            onClick={onDeleteClick && onDeleteClick}
            theme="error"
            icon={<TrashIcon className="text-white h-5 w-5" />}
            size="md">
            <Typo.textSm fontWeight="font-semibold" className="text-white">
              {deleteText}
            </Typo.textSm>
          </Button>
        )}
        {editBtn && editBtn}
        {(cancelButtonIcon || cancelButtonTitle) && (
          <Button
            onClick={onCancelClick && onCancelClick}
            disabled={cancelDisabled}
            icon={cancelButtonIcon ? cancelButtonIcon : ''}
            size="md"
            theme="base">
            <Typo.textSm fontWeight="font-semibold">{cancelButtonTitle}</Typo.textSm>
          </Button>
        )}
        {(confirmButtonTitle || confirmButtonIcon) && (
          <Button
            type={confirmType}
            onClick={onConfrimClick && onConfrimClick}
            disabled={confirmDisabled}
            icon={confirmButtonIcon ? confirmButtonIcon : ''}
            size="md"
            theme="primary-yellow">
            <Typo.textSm fontWeight="font-semibold"> {confirmButtonTitle}</Typo.textSm>
          </Button>
        )}
        {onSearch && (
          <InputField
            name="search"
            prefix={<SearchIcon className="h-5 w-5 text-gray-500" />}
            type="text"
            placeholder="Search"
            onChange={(e: ChangeEvent<HTMLInputElement>) => onSearch(e?.target?.value)}
            register={() => ({})}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardSubHeader;
