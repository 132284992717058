import DashboardHeader from '../../../components/shared/Dashboard Header/DashboardHeader';
import SettingWrapper from '../../../components/wrapper/SettingWrapper';
import { AuthUserState } from '../../../context/AuthUserProvider';
import TableContainer from '../../../components/shared/table/TableContainer';
import BlockedUsersTable from './BlockedUsersTable';
import { Typo } from '../../../components/Typo';
import Button from '../../../components/shared/Button';
import PlusIcon from '../../../components/icons/PlusIcon';
import { useGetBlockedUsers } from '../../../hooks/settings/useGetBlockedUsers';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../lib/redux/hooks';
import BlockedUserCommandMenu from '../../../components/Command Menus/BlockedUserCommandMenu';
import { hideModal, showModal } from '../../../lib/redux/reducers/modal.slice';
import { useUnBlockUser } from '../../../hooks/profile/useUnBlockUser';
import { User } from '../../../model/UserModel';
import { setProfileData, showSlide } from '../../../lib/redux/reducers/slide.slice';
const BlockedUsers = () => {
  const userState = AuthUserState();
  const user = userState.authUser;
  const dispatch = useDispatch();
  const [offset, setOffset] = useState(0);
  const unBlockUser = useUnBlockUser();
  const blockModalState = useAppSelector((state) => state.modal.blockUser);
  const intl = useIntl();
  const NUMBER_OF_RECORDS_PER_PAGE = 7;
  const { loading, blockedUsers, blockedUsersCounts, refetch, fetchMore } = useGetBlockedUsers(
    offset,
    NUMBER_OF_RECORDS_PER_PAGE,
    '',
    user?.id
  );

  const onSearch = (value: string) => {
    refetch({ offset: 0, limit: NUMBER_OF_RECORDS_PER_PAGE, searchText: value });
  };

  const onUnBlockUser = async (user: any) => {
    await unBlockUser({ variables: { id: user?.id } });
    refetch();
  };

  const onCloseModal = async (user?: User) => {
    dispatch(hideModal('blockUser'));
    await refetch();
    if (user) {
      dispatch(setProfileData({ userId: user?.id }));
      dispatch(showSlide('profile'));
    }
  };

  return (
    <div className={`pt-12 pb-16 md:pt-16 md:pb-[96px] w-full px-4 md:px-[60px] `}>
      <SettingWrapper>
        <div className="w-full">
          <div className="my-6 max-w-7xl gap-x-5 flex relative justify-center space-y-1 mx-auto mb-2">
            <DashboardHeader
              title={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.BLOCKED_USERS.id,
                defaultMessage: TRANSLATED_CONSTANTS.BLOCKED_USERS.defaultMessage
              })}
              description={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.YOU_CAN_ADD_REMOVE_BLOCKED_USERS.id,
                defaultMessage: TRANSLATED_CONSTANTS.YOU_CAN_ADD_REMOVE_BLOCKED_USERS.defaultMessage
              })}
              accountType={user?.type}
              onSearch={onSearch}
            />
          </div>

          <div className="mx-auto  max-w-7xl w-full">
            <div className="divide-y divide-gray-200">
              <div className="w-full md:grid lg:grid-cols-1">
                <div className="py-5 px-4 md:px-0 w-full space-y-6 flex flex-col lg:col-span-1 lg:grid lg:grid-cols-6 lg:gap-x-8 lg:space-y-0">
                  <div className="md:flex md:justify-between lg:flex-col lg:justify-start lg:col-span-2 space-y-5">
                    <div>
                      <Typo.textSm fontWeight="font-medium" className="text-gray-700  ">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.BLOCKED_USERS.id}
                          defaultMessage={TRANSLATED_CONSTANTS.BLOCKED_USERS.defaultMessage}
                        />
                      </Typo.textSm>
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500 ">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.CLICK_BLOCKED_USER_TO_ADD_USER.id}
                          defaultMessage={
                            TRANSLATED_CONSTANTS.CLICK_BLOCKED_USER_TO_ADD_USER.defaultMessage
                          }
                        />
                      </Typo.textSm>
                    </div>
                    <Button
                      size="md"
                      theme="primary-yellow"
                      onClick={() => dispatch(showModal('blockUser'))}
                      className="w-full md:w-auto lg:w-fit"
                      icon={<PlusIcon className="h-5 w-5 text-gray-900" />}>
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.BLOCK_USER.id}
                          defaultMessage={TRANSLATED_CONSTANTS.BLOCK_USER.defaultMessage}
                        />
                      </Typo.textSm>
                    </Button>
                  </div>
                  <div className="lg:col-span-4">
                    <TableContainer>
                      <div>
                        <BlockedUsersTable
                          loading={loading}
                          blockedUsers={blockedUsers}
                          blockedUsersCounts={blockedUsersCounts}
                          user={user}
                          recordPerPage={NUMBER_OF_RECORDS_PER_PAGE}
                          setOffset={setOffset}
                          fetchMore={fetchMore}
                          onDelete={onUnBlockUser}
                        />
                      </div>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {blockModalState && (
            <BlockedUserCommandMenu isOpen={blockModalState} user={user} onClose={onCloseModal} />
          )}
        </div>
      </SettingWrapper>
    </div>
  );
};

export default BlockedUsers;
