import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const CEHCK_USER_PENDING_ROLE = gql`
  query checkUserRole($userId: ID, $role: [String]) {
    pendingRoles(
      filters: { and: [{ assignee: { id: { eq: $userId } } }, { role: { in: $role } }] }
    ) {
      data {
        id
        attributes {
          approved
          role
        }
      }
    }
  }
`;

export const useCheckUserPendingRole = (role: string[], userId: number) => {
  const { loading, error, data } = useQuery(CEHCK_USER_PENDING_ROLE, {
    fetchPolicy: 'cache-and-network',
    variables: { role, userId }
  });

  return {
    pendingRoles: flattenObj(data?.pendingRoles),
    loading,
    error
  };
};
