type Props = {
  className?: string;
};
const MessageCircleIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M24.5 13.4166C24.504 14.9565 24.1442 16.4755 23.45 17.85C22.6268 19.497 21.3614 20.8823 19.7954 21.8508C18.2293 22.8193 16.4246 23.3326 14.5833 23.3333C13.0435 23.3373 11.5245 22.9776 10.15 22.2833L3.5 24.5L5.71667 17.85C5.02242 16.4755 4.66265 14.9565 4.66667 13.4166C4.66738 11.5754 5.18071 9.77063 6.14917 8.20461C7.11763 6.6386 8.50296 5.37313 10.15 4.54997C11.5245 3.85573 13.0435 3.49596 14.5833 3.49997H15.1667C17.5984 3.63413 19.8952 4.66053 21.6173 6.38264C23.3394 8.10475 24.3658 10.4016 24.5 12.8333V13.4166Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MessageCircleIcon;
