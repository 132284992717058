import { ReactElement, useEffect, useState } from 'react';
import ProfileHeader from '../../components/shared/Dashboard Header/ProfileHeader';
import { TabModel } from '../../model/TabModel';
import Listings from './Listings';
import { User } from '../../model/UserModel';
import Messages from './Messages';
import WantedListings from './WantedListing';
import SavedListings from './SavedListings';
import ArchiveListings from './ArchiveListings';
import Storefronts from './Storefronts';
import { ACCOUNT_TYPE, ROLE_TYPES } from '../../constants/data';
import { AuthUserState } from '../../context/AuthUserProvider';
import { useAppSelector } from '../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { addDashboardTab } from '../../lib/redux/reducers/dashboard.slice';
import BecomeModerator from '../../components/dashboard/card/BecomeModerator';
import ApplyModal from '../../components/modal/ApplyModal';
import { useCreatePendingRole } from '../../hooks/user/useCreatePendingRole';
import ManJumping from '../../assets/images/Man jumping-min.jpg';
import { getCurrentDate, getCurrentDateFormatted } from '../../util/CurrentDate';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { usePendingRoles } from '../../hooks/user/useGetPendingRole';
import { addNotification } from '../../lib/redux/reducers/notification.slice';
const tabs: TabModel[] = [
  { id: 1, title: 'Listings' },
  { id: 2, title: 'Storefront' },
  { id: 3, title: 'Messages' },
  { id: 4, title: 'Wanted' },
  { id: 5, title: 'Saved' },
  { id: 6, title: 'Archive' }
];

export const handleUserRoleBadge = (id: number, user: User) => {
  const tabComponentMap: { [key: string]: ReactElement | string } = {
    1: <Listings user={user} />,
    2: <Storefronts user={user} />,
    3: <Messages user={user} />,
    4: <WantedListings user={user} />,
    5: <SavedListings user={user} />,
    6: <ArchiveListings user={user} />
  };
  return tabComponentMap[id];
};

const Dashboard = () => {
  const dashaboardTab = useAppSelector((state) => state.dashboard.tab) as any;
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const [becomeModeratorModal, setBecomeModeratorModal] = useState(false);
  const { me, authUser } = AuthUserState();
  const intl = useIntl();
  const [selectedTab, setSelectedTab] = useState<TabModel>();
  const [roleDismissed, setRoleDismissed] = useState(localStorage.getItem('roleDissmiss'));
  const createPendingRole = useCreatePendingRole();
  const dispatch = useDispatch();
  const { pendingRoles } = usePendingRoles(me?.id);

  useEffect(() => {
    if (authUser) {
      if (dashaboardTab && dashaboardTab?.tab === 3) {
        // setSelectedTab({ id: 3, title: 'Messages' });
      } else {
        setSelectedTab(authUser?.type == ACCOUNT_TYPE.PERSONAL ? tabs[0] : tabs[1]);
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (dashaboardTab !== undefined) {
      setSelectedTab(dashaboardTab);
    }
  }, [dashaboardTab]);

  const onTabChanged = (tab: TabModel) => {
    dispatch(addDashboardTab(tab));
  };

  const onDismiss = () => {
    localStorage.setItem('roleDissmiss', 'moderator');
    setRoleDismissed('moderator');
  };

  const onApply = async (state: boolean) => {
    if (state) {
      await createPendingRole({
        variables: {
          data: {
            date: getCurrentDateFormatted(),
            role: 'moderator',
            assignee: me?.id,
            assigner: undefined,
            approved: false,
            publishedAt: getCurrentDate()
          }
        }
      }).then((response: any) => {
        if (response) {
          dispatch(
            addNotification({
              title: intl.formatMessage({
                id: TRANSLATED_CONSTANTS.MODERATOR_APPLICATION_SUBMITTED.id,
                defaultMessage: TRANSLATED_CONSTANTS.MODERATOR_APPLICATION_SUBMITTED.defaultMessage
              }),
              description: intl.formatMessage({
                id: TRANSLATED_CONSTANTS.MODERATOR_APPLICATION_SUBMITTED_DETAILS.id,
                defaultMessage:
                  TRANSLATED_CONSTANTS.MODERATOR_APPLICATION_SUBMITTED_DETAILS.defaultMessage
              }),
              image: ManJumping,
              id:
                notifications && notifications?.length > 0
                  ? notifications[notifications?.length - 1]?.id + 1
                  : 1,
              type: 'default'
            })
          );
          setRoleDismissed('moderator');
        }
      });
    }
    setBecomeModeratorModal(false);
  };
  return (
    <div>
      <ProfileHeader
        tabs={tabs}
        user={authUser}
        currentTab={selectedTab}
        onTabChange={onTabChanged}></ProfileHeader>

      <div className="max-w-[95rem] mx-auto">
        {roleDismissed !== 'moderator' &&
          me?.role?.name == ROLE_TYPES.REGULAR_USER &&
          pendingRoles?.length == 0 &&
          authUser?.profileCompleted && (
            <BecomeModerator onDismiss={onDismiss} onApply={() => setBecomeModeratorModal(true)} />
          )}
        <div className="w-full px-4 sm:px-[60px] pt-8">
          {selectedTab ? handleUserRoleBadge(Number(selectedTab?.id) || 1, me) : ''}
        </div>
        <ApplyModal
          isOpen={becomeModeratorModal}
          onCloseApplyModal={() => setBecomeModeratorModal(false)}
          onConfirmApply={(value: any) => onApply(value)}
        />
      </div>
    </div>
  );
};

export default Dashboard;
