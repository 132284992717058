import axios from 'axios';

export const getDoge = async () => {
  try {
    const binanceApiUrl = process.env.REACT_APP_BINANCE_API_URL;
    const response = await axios.get(`${binanceApiUrl}`, {
      params: {
        symbol: 'DOGEUSDT'
      }
    });
    return response?.data?.price;
  } catch (error) {
    console.error('Error fetching Dogecoin price:', error);
  }
};

export const ConvertToDoge = (value: number, doge: number) => {
  // const dogePrice = await getDoge();
  return Math.round(value / doge);
};

export const convertToUsd = (value: number, doge: number) => {
  const dogePrice = (value * doge)?.toFixed(2);
  return dogePrice;
};
