import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
const backendUrl = process.env.REACT_APP_STRAPI_API_URL;
import { useDispatch } from 'react-redux';
import { setAlertData, setAlertType } from '../../lib/redux/reducers/alert.slice';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ROUTES } from '../../constants/data';
import Loader from '../../components/shared/Loader';

const LoginRedirect = () => {
  const location = useLocation();
  const params = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    fetch(`${backendUrl}/api/auth/${params.providerName}/callback${location.search}`)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then((res) => res.json())
      .then((res) => {
        // Successfully logged with Strapi
        if (res?.jwt) {
          localStorage.setItem('token', res.jwt);
          window.location.href = '/';
          dispatch(setAlertType('success'));
          dispatch(
            setAlertData({
              title: intl.formatMessage(
                {
                  id: TRANSLATED_CONSTANTS.SUCCESS_ALERT_TITLE.id,
                  defaultMessage: TRANSLATED_CONSTANTS.SUCCESS_ALERT_TITLE.defaultMessage
                },
                { name: res?.username }
              ),
              description: intl.formatMessage(
                {
                  id: TRANSLATED_CONSTANTS.SUCCESS_ALERT_DESCRIPTION.id,
                  defaultMessage: TRANSLATED_CONSTANTS.SUCCESS_ALERT_DESCRIPTION.defaultMessage
                },
                { name: res?.username }
              ),
              link: ROUTES.DASHBOARD_ROUTE,
              confirmTitle: intl.formatMessage({
                id: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.id,
                defaultMessage: TRANSLATED_CONSTANTS.VIEW_DASHBOARD.defaultMessage
              }),
              cancelTitle: intl.formatMessage({
                id: TRANSLATED_CONSTANTS.DISMISS.id,
                defaultMessage: TRANSLATED_CONSTANTS.DISMISS.defaultMessage
              }),
              image: ''
            })
          );
        }
      })
      .catch((err) => {
        window.location.href = '/';
        // setText('An error occurred, please see the developer console.');
      });
  }, [history, location.search, params.providerName]);

  return <Loader className=" h-screen" />;
};

export default LoginRedirect;
