type Props = {
  className?: string;
};
const ChevronSelectorIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 ${className} `}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.83337 12.5L10 16.6666L14.1667 12.5M5.83337 7.49998L10 3.33331L14.1667 7.49998"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChevronSelectorIcon;
