/* eslint-disable @typescript-eslint/no-empty-function */
import React, { FC, useState } from 'react';
import { User } from '../../../model/UserModel';
import { FormattedMessage, useIntl } from 'react-intl';
import TableTitle from '../../../components/shared/table/TableTitle';
import InputField from '../../../components/shared/InputField';
import SearchIcon from '../../../components/icons/SearchIcon';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useForm } from 'react-hook-form';
import Divider from '../../../components/shared/Divider';
import Button from '../../../components/shared/Button';
import PlusIcon from '../../../components/icons/PlusIcon';
import { Typo } from '../../../components/Typo';
import MoreFilter from '../../../components/shared/MoreFilter';
import { CheckboxOption } from '../../../model/CheckboxOption';
import Badge from '../../../components/shared/Badge';
import Table from '../../../components/shared/table/Table';
import TableHead from './TableHead';
import { useGetCountries } from '../../../hooks/reports/useGetCountries';
import { NUMBER_OF_RECORDS_PER_TABLE, ROLES } from '../../../constants/data';
import { useGetLanguages } from '../../../hooks/settings/useGetLanguages';
import { useGetModerators } from '../../../hooks/reports/useGetModeratos';
import Pagination from '../../../components/shared/Pagination';
import EmptyReportListings from '../EmptyReportListings';
import Loader from '../../../components/shared/Loader';
import { handleAccountTypeBadge } from '../../../util/ListingBadgeHandler';
import CheckboxField from '../../../components/shared/CheckboxField';
import UserInfo from '../../../components/shared/table/UserInfo';
import ProgressBar from '../../../components/shared/ProgressBar';
import TrashIcon from '../../../components/icons/TrashIcon';
import { CircleFlag } from 'react-circle-flags';
import { getDateDifferenceInDays } from '../../../util/AppUtil';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useDispatch } from 'react-redux';
import { useUpdateRoles } from '../../../hooks/reports/useUpdateRoles';
import PrimaryActionModal from '../../../components/modal/PrimaryActionModal';
import { hideModal, showModal } from '../../../lib/redux/reducers/modal.slice';
import { AuthUserState } from '../../../context/AuthUserProvider';
import AddAdminAndModCommandMenu from '../../../components/Command Menus/AddAdminAndModCommandMenu';
import InlineAlert from '../../../components/shared/InlineAlert';

type Props = {
  user: User;
};
const usertypes: CheckboxOption[] = [
  {
    value: 'personal seller',
    label: <Badge size="sm" theme="blue" label="Personal"></Badge>
  },
  {
    value: 'business',
    label: <Badge size="sm" theme="purple" label="Business"></Badge>
  }
];

const accurecyScores: CheckboxOption[] = [
  { value: '70', label: '70% or above' },
  { value: '60', label: '60% or below' },
  { value: '0', label: 'Any score' }
];

const Mods: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { roles } = AuthUserState();
  const { register } = useForm();
  const [searchText, setSearchText] = useState('');
  const [checked, setChecked] = useState(false);
  const [filters, setFilters] = useState<any>();
  const [offset, setOffset] = useState(0);
  const [selectedUsers, setSeletedUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const updateRole = useUpdateRoles();
  const { countries } = useGetCountries();
  const { languages } = useGetLanguages(true);
  const openAddModeratorModal = useAppSelector((state) => state.modal.addModerator);
  const { moderators, moderatorsCount, loading, error, refetch, fetchMore } = useGetModerators(
    offset,
    NUMBER_OF_RECORDS_PER_TABLE,
    searchText,
    filters?.accurecyScores,
    filters?.countries,
    filters?.languages,
    filters?.userTypes
  );
  const regularUser = roles?.filter((role) => role?.name === ROLES.REGULAR_USER);

  const toggleAll = (e: any) => {
    setChecked(e.target.checked);
    if (e.target.checked) {
      setSeletedUsers([]);
    } else {
      setSeletedUsers([]);
    }
  };

  const onCloseModal = () => {
    dispatch(hideModal('addModerator'));
  };

  const paginate = (pageNumber: number) => {
    const startIndex = (pageNumber - 1) * NUMBER_OF_RECORDS_PER_TABLE;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: NUMBER_OF_RECORDS_PER_TABLE }
    });
    setCurrentPage(pageNumber);
  };

  const onDeleteUserButtonClicked = (user: User) => {
    const exists = selectedUsers.some((selectedUser) => selectedUser.id === user.id);
    if (!exists) {
      setSeletedUsers([...selectedUsers, user]);
    }
    setShowDeleteModal(true);
  };

  const onCloseDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowDeleteModal(false);
      return;
    }

    const usersIds = selectedUsers.map((user) => user.id);
    const response = await updateRole({ usersIds: usersIds, roleId: regularUser[0].id });
    if (response) {
      refetch();
    }
    setShowDeleteModal(false);
  };

  return (
    <div>
      <div className=" pb-8">
        <TableTitle
          title={intl.formatMessage({
            id: 'page.report.moderator.table.title',
            defaultMessage: 'Moderators'
          })}
          description={intl.formatMessage({
            id: 'page.report.moderator.table.subtitle',
            defaultMessage: 'Moderator metrics are displayed here.'
          })}
        />
      </div>
      <Divider />
      <div className="flex w-full justify-between py-3">
        <div className="flex justify-start">
          <div className="min-w-[140px] ">
            <MoreFilter
              type="moderator"
              userTypes={usertypes}
              accurecyScores={accurecyScores}
              languages={languages}
              onFilter={(filter) => setFilters(filter)}
              countries={countries}></MoreFilter>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-3 ">
          <div className="md:w-[400px] w-full flex ">
            <InputField
              prefix={<SearchIcon className="h-5 w-5 text-gray-500" />}
              name="searchInput"
              placeholder={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SEARCH.id,
                defaultMessage: TRANSLATED_CONSTANTS.SEARCH.defaultMessage
              })}
              type="text"
              className="h-[44px]"
              onChange={(e: any) => setSearchText(e.target.value)}
              register={register}
            />
          </div>
          <Button
            onClick={() => dispatch(showModal('addModerator'))}
            size="md"
            className="h-[44px] "
            theme="primary-yellow"
            icon={<PlusIcon className=" text-gray-900" />}>
            <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
              <FormattedMessage
                id="page.report.moderator.button.add_moderator"
                defaultMessage="Add mod"
              />
            </Typo.textSm>
          </Button>
        </div>
      </div>
      <div className="w-full">
        {moderators?.length === 0 && !loading ? (
          <InlineAlert
            className="flex justify-center"
            text={intl.formatMessage({
              id: TRANSLATED_CONSTANTS.NOTHING_HERE_TO_DISPLAY.id,
              defaultMessage: TRANSLATED_CONSTANTS.NOTHING_HERE_TO_DISPLAY.defaultMessage
            })}
            type="default"
            state={true}
          />
        ) : (
          <div className="w-full">
            {loading ? (
              <Loader className="h-[400px] min-w-full w-full flex justify-center items-center" />
            ) : (
              <div className="min-w-full w-full overflow-x-auto  ">
                <Table>
                  <TableHead checked={checked} toggleAll={toggleAll} />

                  <tbody className="divide-y divide-gray-200 bg-white">
                    {moderators?.map((user: User) => {
                      const userExistsInSelectedUsers = selectedUsers.some(
                        (eachObj: any) => eachObj.id === user.id
                      );
                      const accountType = handleAccountTypeBadge(user?.type || '', intl);
                      return (
                        <tr
                          key={user.id}
                          className={userExistsInSelectedUsers ? 'bg-gray-50' : undefined}>
                          <td className="relative  sm:w-12 sm:px-6 py-3">
                            <div className="absolute left-6 top-1/2 -mt-3">
                              <CheckboxField
                                name="state"
                                value="check"
                                label=""
                                size="md"
                                type="checkbox"
                                roundedSize="rounded-md"
                                isSelected={userExistsInSelectedUsers}
                                onChange={(e) =>
                                  setSeletedUsers(
                                    e.target.checked
                                      ? [...selectedUsers, user]
                                      : selectedUsers.filter((u: any) => u.id !== user.id)
                                  )
                                }
                                register={() => {}}
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4">
                            <UserInfo profileImage={user?.profileImage?.url || ''}>
                              <div className="">
                                <Typo.textSm
                                  fontWeight="font-medium"
                                  className="text-gray-900 capitalize">
                                  {user?.name || ''}
                                </Typo.textSm>

                                <Typo.textSm fontWeight="font-normal" className="text-gray-600">
                                  @{user?.username || ''}
                                </Typo.textSm>
                              </div>
                            </UserInfo>
                          </td>

                          <td className="whitespace-nowrap  px-3 py-4">
                            <div className="flex gap-1">
                              <Badge theme={accountType.theme} label={accountType.name}></Badge>
                              <Badge
                                theme="gray"
                                label={intl.formatMessage({
                                  id: TRANSLATED_CONSTANTS.MODERATOR.id,
                                  defaultMessage: TRANSLATED_CONSTANTS.MODERATOR.defaultMessage
                                })}
                              />
                            </div>
                          </td>
                          <td className="whitespace-nowrap  px-3 py-4">
                            <div className="flex gap-3">
                              <CircleFlag
                                countryCode={user?.countryCode || ''}
                                height="20"
                                width="20"
                                className=" h-5 w-5 "
                              />
                              <Typo.textSm
                                fontWeight="font-medium"
                                className="text-gray-600 uppercase">
                                {user?.countryCode}
                              </Typo.textSm>
                            </div>
                          </td>

                          <td className="whitespace-nowrap  py-4 ">
                            <Typo.textSm fontWeight="font-medium" className="text-gray-500">
                              {getDateDifferenceInDays(
                                user?.moderatorStartDate || new Date().toString()
                              )}
                            </Typo.textSm>
                          </td>
                          <td className="whitespace-nowrap py-4 px-3 ">
                            <ProgressBar
                              value={
                                user?.correctDecisions === 0 && user?.wrongDecisions === 0
                                  ? 0
                                  : Math.round(
                                      ((user?.correctDecisions || 0) /
                                        ((user?.correctDecisions || 0) +
                                          (user?.wrongDecisions || 0))) *
                                        100
                                    )
                              }
                              showLabel={true}></ProgressBar>
                          </td>
                          <td className="whitespace-nowrap py-4 pl-3 pr-5 ">
                            <Typo.textSm
                              fontWeight="font-medium"
                              className="text-gray-500 capitalize">
                              {user?.correctDecisions} /
                              {(user?.correctDecisions || 0) + (user?.wrongDecisions || 0)}
                            </Typo.textSm>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 ">
                            <Button
                              onClick={() => onDeleteUserButtonClicked(user)}
                              size="md"
                              theme="gray-link"
                              icon={<TrashIcon className="w-5 h-5 text-gray-600" />}></Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Divider />
                <div className="px-6">
                  <Pagination
                    currentPage={currentPage}
                    totalCount={moderatorsCount}
                    pageSize={NUMBER_OF_RECORDS_PER_TABLE}
                    onPageChange={paginate}
                    type="centered-page-number"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {openAddModeratorModal && (
        <AddAdminAndModCommandMenu
          isOpen={openAddModeratorModal}
          onCloseAddAdminAndModModal={onCloseModal}
          role={ROLES.REGULAR_USER}
        />
      )}
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE}
        isOpen={showDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.DELETE}
        icon={<TrashIcon />}
      />
    </div>
  );
};

export default Mods;
