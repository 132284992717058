import React from 'react';
import coin from '../../assets/point-icon.svg';

const SharingPiointBadge = () => {
  return (
    <div className="flex justify-center items-center gap-0.5 md:gap-1 px-1 py-0.5 md:px-1.5 md:py-1 bg-gray-900 bg-opacity-70 rounded-2xl">
      <img src={coin} className="h-4 w-[19px]" alt="" />
      <div className="text-white text-[12px] font-bold leading-none">x150</div>
    </div>
  );
};

export default SharingPiointBadge;
