import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_ARCHIVED_LISTINGS = gql`
  query GetListings($userId: ID, $offset: Int, $limit: Int, $order: [String]) {
    listings(
      pagination: { start: $offset, limit: $limit }
      sort: $order
      filters: { and: [{ user: { id: { eq: $userId } } }, { deleted: { eq: true } }] }
    ) {
      __typename
      data {
        id
        attributes {
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
              }
            }
          }
          name
          description
          deleted
          location {
            address
            latitude
            longitude
            state
            country
            city
            street
          }
          slug
          price {
            priceType
            priceInUSD
            priceInDoge
            alternatePriceOptions
          }
          type
          item_condition {
            data {
              id
              attributes {
                name
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
                type
              }
            }
          }
          issued_listing {
            data {
              id
              attributes {
                publishedAt
                claim
                detail
                actionType
                date
              }
            }
          }
          publishedAt
        }
      }
      meta {
        pagination {
          total
          pageSize
          page
          pageCount
        }
      }
    }
  }
`;

export const useGetArchivedListings = (
  userId: string,
  offset: number,
  limit?: number,
  order?: string
) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_ARCHIVED_LISTINGS, {
    variables: { userId, offset, limit, order },
    fetchPolicy: 'cache-and-network'
  });

  return {
    listings: flattenObj(data?.listings),
    pagination: data?.listings?.meta?.pagination,
    fetchMore,
    refetch,
    loading,
    error
  };
};
