import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import Button from './Button';
import FilterIcon from '../icons/FilterIcon';
import MoreFiltetItem from './MoreFilterItem';
import { CheckboxOption } from '../../model/CheckboxOption';
import MoreFilterCategoryItem from './MoreFilterCategoryItem';
import CategoryIcon from '../icons/CategoryIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { Typo } from '../Typo';
import { useAppSelector } from '../../lib/redux/hooks';
import { defaultConditions } from '../../util/MoreFilterUtil';

export type MoreFilterProps = {
  type?: 'map' | 'moderator';
  label?: string;
  all?: Array<CheckboxOption>;
  listings?: Array<CheckboxOption>;
  conditions?: Array<CheckboxOption>;
  alertnatePriceOptions?: Array<CheckboxOption>;
  priceType?: Array<CheckboxOption>;
  categories?: Array<CheckboxOption>;
  userTypes?: Array<CheckboxOption>;
  accurecyScores?: Array<CheckboxOption>;
  languages?: Array<CheckboxOption>;
  countries?: Array<CheckboxOption>;
  onFilter?: (filter: any) => void;
  resetState?: boolean;
};
const MoreFilter: React.FC<MoreFilterProps> = (props) => {
  const {
    onFilter,
    type = 'map',
    label = '',
    listings = [],
    conditions = [],
    alertnatePriceOptions = [],
    priceType = [],
    categories = [],
    userTypes = [],
    accurecyScores = [],
    languages = [],
    countries = [],
    resetState
  } = props;
  const filterFromModal = useAppSelector((state) => state.filter.filterModal);
  const intl = useIntl();
  const allCategories = {
    value: '0',
    label: intl.formatMessage({
      id: TRANSLATED_CONSTANTS.ALL_CATEGORIES.id,
      defaultMessage: TRANSLATED_CONSTANTS.ALL_CATEGORIES.defaultMessage
    })
  };
  const [selectedListing, setSelectedListing] = useState<Array<CheckboxOption>>([]);
  const [selectedConditions, setSelectedConditions] = useState<Array<CheckboxOption>>([]);
  const [selectedAlternatePriceOptions, setSelectedAlternatePriceOptions] = useState<
    Array<CheckboxOption>
  >([]);
  const [selectedPriceTypeOptions, setSelectedPriceTypeOptions] = useState<Array<CheckboxOption>>(
    []
  );
  const [selectedCategory, setSelectedCategory] = useState<CheckboxOption>(allCategories);
  const [selectedUserTypes, setSelectedUserTypes] = useState<Array<CheckboxOption>>([]);
  const [selectedAccurecyScores, setSelectedAccurecyScore] = useState<Array<CheckboxOption>>([]);
  const [selectedLanguages, setSelectedLanguages] = useState<Array<CheckboxOption>>([]);
  const [selectedCountries, setSelectedCountries] = useState<Array<CheckboxOption>>([]);
  const all = [
    {
      value: 'all',
      label: (
        <span className="ml-1">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.ALL.id}
            defaultMessage={TRANSLATED_CONSTANTS.ALL.defaultMessage}
          />
        </span>
      )
    }
  ];

  const [allSelected, setAllSelected] = useState<Array<CheckboxOption>>();

  useEffect(() => {
    handelAllSelect(all);
  }, [resetState]);

  //No reason why it is here!
  useEffect(() => {
    if (conditions?.length > 0) {
      resetFilter();
    }
  }, [conditions]);

  useEffect(() => {
    if (languages?.length > 0) {
      resetFilter();
    }
  }, [languages]);

  const handelAllSelect = (options: CheckboxOption[]) => {
    setAllSelected(options);
    if (options?.length > 0) {
      if (type === 'map') {
        setSelectedListing(listings);
        setSelectedConditions(conditions);
        setSelectedAlternatePriceOptions(alertnatePriceOptions);
        setSelectedPriceTypeOptions(priceType);
        setSelectedCategory(allCategories);
      } else if (type === 'moderator') {
        setSelectedUserTypes(userTypes);
        setSelectedAccurecyScore(accurecyScores);
        setSelectedLanguages(languages);
        setSelectedCountries(countries);
      }
    } else {
      clearFilter();
    }
  };

  const clearFilter = () => {
    setSelectedListing([]);
    setSelectedConditions([]);
    setSelectedAlternatePriceOptions([]);
    setSelectedPriceTypeOptions([]);
    setSelectedCategory(allCategories);
    setSelectedUserTypes([]);
    setSelectedAccurecyScore([]);
    setSelectedLanguages([]);
    setSelectedCountries([]);
  };

  const resetFilter = () => {
    const moreFilter: any = filterFromModal?.moreFilter;
    if (moreFilter) {
      if (type === 'map') {
        setSelectedListing(moreFilter?.listings || []);
        setSelectedConditions(moreFilter?.conditions || []);
        setSelectedAlternatePriceOptions(moreFilter?.alternatePriceOptions || []);
        setSelectedPriceTypeOptions(moreFilter?.priceType || []);
        setSelectedCategory(moreFilter?.categories || allCategories);
      } else {
        setSelectedUserTypes(moreFilter?.userTypes || []);
        setSelectedAccurecyScore(moreFilter?.accurecyScores || []);
        setSelectedLanguages(moreFilter?.languages || []);
        setSelectedCountries(moreFilter?.countries || []);
      }
      onCheckSelectedAll('', 0);
    } else {
      handelAllSelect(all);
      setAllSelected(all);
    }
  };

  const onApplyFilter = () => {
    let filter = {};
    if (type === 'map') {
      filter = {
        listings: selectedListing.map((item) => ({ label: item.value, value: item.value })) || [],
        conditions: selectedConditions || [],
        alternatePriceOptions: selectedAlternatePriceOptions || [],
        priceType: selectedPriceTypeOptions || [],
        categories: selectedCategory.value == '0' ? categories : [selectedCategory]
      };
    } else {
      filter = {
        userTypes: selectedUserTypes,
        accurecyScores: selectedAccurecyScores,
        languages: selectedLanguages,
        countries: selectedCountries
      };
    }
    onFilter && onFilter(filter);
  };

  const onCheckSelectedAll = (
    itemType:
      | 'listings'
      | 'conditions'
      | 'alertnatePriceOptions'
      | 'priceType'
      | 'userTypes'
      | 'accurecyScores'
      | 'languages'
      | 'countries'
      | '',
    itemSize: number
  ) => {
    const timer = setTimeout(() => {
      if (type === 'map') {
        const listingSize = itemType == 'listings' ? itemSize : selectedListing?.length;
        const conditionSize = itemType == 'conditions' ? itemSize : selectedConditions?.length;
        const alertnatePriceOptionSize =
          itemType == 'alertnatePriceOptions' ? itemSize : selectedAlternatePriceOptions?.length;
        const priceTypeSize = itemType == 'priceType' ? itemSize : selectedPriceTypeOptions?.length;
        const size =
          (listings?.length || 0) +
          (conditions?.length || 0) +
          (alertnatePriceOptions?.length || 0) +
          (priceType?.length || 0);
        const selectedSize = listingSize + conditionSize + alertnatePriceOptionSize + priceTypeSize;

        if (size == selectedSize) {
          setAllSelected(all);
        } else {
          setAllSelected([]);
        }
      }
      if (type === 'moderator') {
        const userTypeSize = itemType == 'userTypes' ? itemSize : selectedUserTypes?.length;
        const accurecyScoreSize =
          itemType == 'accurecyScores' ? itemSize : selectedAccurecyScores?.length;
        const languageSize = itemType == 'languages' ? itemSize : selectedLanguages?.length;
        const countrieSize = itemType == 'countries' ? itemSize : selectedCountries?.length;
        const size =
          (userTypes?.length || 0) +
          (accurecyScores?.length || 0) +
          (languages?.length || 0) +
          (countries?.length || 0);
        const selectedSize = userTypeSize + accurecyScoreSize + languageSize + countrieSize;

        if (size == selectedSize) {
          setAllSelected(all);
        } else {
          setAllSelected([]);
        }
      }
    }, 1000);
    return () => clearTimeout(timer);
  };

  return (
    <Menu as="div" className="relative inline-block text-left">
      {({ open, close }: any) => (
        <>
          <div>
            <Menu.Button className="flex items-center group">
              <button
                className={`bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 hover:text-gray-800 focus:bg-white focus:border-gray-300 focus:shadow-base disabled:bg-white disabled:border-gray-200 rounded-lg disabled:cursor-not-allowed focus:outline-none disabled:text-gray-300 py-2 px-[0.625rem] md:px-3.5 ${
                  open ? ' ring-4 ring-gray-100 ' : ''
                }`}>
                <div className="flex items-center justify-center gap-x-2 py-0.5">
                  <FilterIcon></FilterIcon>{' '}
                  <span>
                    {label
                      ? label
                      : `${intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.MORE_FILTERS.id,
                          defaultMessage: TRANSLATED_CONSTANTS.MORE_FILTERS.defaultMessage
                        })}`}
                  </span>
                </div>
              </button>
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items
              className={`absolute ${
                type == 'moderator'
                  ? 'left-0 pb-20 lg:pb-24 xl:pb-24 max-h-[500px]'
                  : ' right-0 max-h-[500px] lg:max-h-[450px] '
              } z-10 mt-2 w-[350px]  overflow-y-auto md:w-[400px] origin-top-right rounded-lg border border-gray-200 bg-white shadow-xl focus:outline-none`}>
              <div className=" w-full divide-y ">
                <div className="w-full p-6">
                  <Typo.textXl fontWeight="font-medium" className=" text-gray-900">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.FILTERS.id}
                      defaultMessage={TRANSLATED_CONSTANTS.FILTERS.defaultMessage}
                    />
                  </Typo.textXl>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.APPLY_FILTERS_TO_SEARCH.id}
                      defaultMessage={TRANSLATED_CONSTANTS.APPLY_FILTERS_TO_SEARCH.defaultMessage}
                    />
                  </Typo.textSm>
                </div>
                <div className="w-full">
                  <MoreFiltetItem
                    name="all"
                    title={intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.ALL_FILTERS.id,
                      defaultMessage: TRANSLATED_CONSTANTS.ALL_FILTERS.defaultMessage
                    })}
                    onChange={handelAllSelect}
                    selectedOptions={allSelected}
                    options={[
                      {
                        value: 'all',
                        label: (
                          <span className="ml-1">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.ALL.id}
                              defaultMessage={TRANSLATED_CONSTANTS.ALL.defaultMessage}
                            />
                          </span>
                        )
                      }
                    ]}></MoreFiltetItem>
                </div>
                {type === 'map' && (
                  <>
                    <div className="w-full space-y-4">
                      <MoreFiltetItem
                        name="listings"
                        title={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.LISTINGS.id,
                          defaultMessage: TRANSLATED_CONSTANTS.LISTINGS.defaultMessage
                        })}
                        selectedOptions={selectedListing}
                        onChange={(data) => {
                          setSelectedListing(data);
                          onCheckSelectedAll('listings', data?.length || 0);
                        }}
                        options={listings}></MoreFiltetItem>
                    </div>
                    <div className="w-full  space-y-4">
                      <MoreFiltetItem
                        name="conditions"
                        title={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CONDITION.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CONDITION.defaultMessage
                        })}
                        selectedOptions={selectedConditions}
                        onChange={(data) => {
                          setSelectedConditions(data);
                          onCheckSelectedAll('conditions', data?.length || 0);
                        }}
                        options={conditions}></MoreFiltetItem>
                    </div>
                    <div className="w-full  space-y-4">
                      <MoreFiltetItem
                        name="alertnatePriceOptions"
                        selectedOptions={selectedAlternatePriceOptions}
                        onChange={(data) => {
                          setSelectedAlternatePriceOptions(data);
                          onCheckSelectedAll('alertnatePriceOptions', data?.length || 0);
                        }}
                        title={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.PRICE_TYPE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.PRICE_TYPE.defaultMessage
                        })}
                        options={alertnatePriceOptions}></MoreFiltetItem>
                    </div>
                    {/* <div className="w-full  space-y-4">
                      <MoreFiltetItem
                        name="priceType"
                        selectedOptions={selectedPriceTypeOptions}
                        onChange={(data) => {
                          setSelectedPriceTypeOptions(data);
                          onCheckSelectedAll('priceType', data?.length || 0);
                        }}
                        title={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.OFFER_TYPE.id,
                          defaultMessage: TRANSLATED_CONSTANTS.OFFER_TYPE.defaultMessage
                        })}
                        options={priceType}></MoreFiltetItem>
                    </div> */}
                    <div className="w-full py-6 space-y-4 block md:hidden">
                      <MoreFilterCategoryItem
                        name="categories"
                        prefix={<CategoryIcon></CategoryIcon>}
                        placeholder={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.SELECT_CATEGORY.id,
                          defaultMessage: TRANSLATED_CONSTANTS.SELECT_CATEGORY.defaultMessage
                        })}
                        selectedOption={selectedCategory}
                        onChange={setSelectedCategory}
                        title={intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.CATEGORY.id,
                          defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                        })}
                        options={categories}
                        usedOnFilter={true}></MoreFilterCategoryItem>
                    </div>
                  </>
                )}

                {type === 'moderator' && (
                  <>
                    <div className="w-full space-y-4">
                      <MoreFiltetItem
                        name="userTypes"
                        title="User Type"
                        selectedOptions={selectedUserTypes}
                        onChange={(data) => {
                          setSelectedUserTypes(data);
                          onCheckSelectedAll('userTypes', data?.length || 0);
                        }}
                        options={userTypes}></MoreFiltetItem>
                    </div>
                    <div className="w-full space-y-4">
                      <MoreFiltetItem
                        name="accurecyScores"
                        title="Accuracy Score"
                        selectedOptions={selectedAccurecyScores}
                        onChange={(data) => {
                          setSelectedAccurecyScore(data);
                          onCheckSelectedAll('accurecyScores', data?.length || 0);
                        }}
                        options={accurecyScores}></MoreFiltetItem>
                    </div>
                    <div className="w-full space-y-4">
                      <MoreFiltetItem
                        name="languages"
                        hasSearch={true}
                        placeholder="Search language"
                        selectedOptions={selectedLanguages}
                        onChange={(data) => {
                          setSelectedLanguages(data);
                          onCheckSelectedAll('languages', data?.length || 0);
                        }}
                        title="Language proficiency"
                        options={languages}></MoreFiltetItem>
                    </div>
                    <div className="w-full space-y-4">
                      <MoreFiltetItem
                        name="countries"
                        title="Country & region"
                        placeholder="Search country"
                        selectedOptions={selectedCountries}
                        onChange={(data) => {
                          setSelectedCountries(data);
                          onCheckSelectedAll('countries', data?.length || 0);
                        }}
                        hasSearch={true}
                        options={countries}></MoreFiltetItem>
                    </div>
                  </>
                )}
                <div className="w-full px-6 py-4 ">
                  <div className=" flex justify-end gap-x-3">
                    <Button
                      onClick={() => {
                        resetFilter();
                        close();
                      }}
                      size="md"
                      theme="base">
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.CANCEL.id}
                          defaultMessage={TRANSLATED_CONSTANTS.CANCEL.defaultMessage}
                        />
                      </Typo.textSm>
                    </Button>
                    <Button
                      onClick={() => {
                        onApplyFilter();
                        close();
                      }}
                      size="md"
                      theme="primary-yellow">
                      <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.APPLY.id}
                          defaultMessage={TRANSLATED_CONSTANTS.APPLY.defaultMessage}
                        />
                      </Typo.textSm>
                    </Button>
                  </div>
                </div>
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};

export default MoreFilter;
