type Props = {
  className?: string;
};
const EyeIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 ${className} `}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3009_13490)">
        <path
          d="M0.833252 10C0.833252 10 4.16658 3.33333 9.99992 3.33333C15.8333 3.33333 19.1666 10 19.1666 10C19.1666 10 15.8333 16.6667 9.99992 16.6667C4.16658 16.6667 0.833252 10 0.833252 10Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99992 12.5C11.3806 12.5 12.4999 11.3807 12.4999 10C12.4999 8.61929 11.3806 7.5 9.99992 7.5C8.61921 7.5 7.49992 8.61929 7.49992 10C7.49992 11.3807 8.61921 12.5 9.99992 12.5Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3009_13490">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EyeIcon;
