import Image from '../Image';
import Badge from '../Badge';
import DotIcon from '../../icons/DotIcon';
import React, { ReactElement } from 'react';
import TagItemIcon from '../../icons/TagItemIcon';
import AwardIcon from '../../icons/AwardIcon';
import {
  LISTING_BREAKPOINT,
  LISTING_CARD_TYPES,
  LISTING_TYPES,
  ROUTES
} from '../../../constants/data';
import clsx from 'clsx';
import { handleListingAndStorefrontBadge } from '../../../util/ListingBadgeHandler';
import { useNavigate } from 'react-router-dom';
import wantedDefaultImage from '../../../assets/images/Wanted default image.png';
type PriceProps = {
  size?: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  imageUrl?: string;
  hasAward?: boolean;
  listingType: string;
  children?: ReactElement;
  className?: string;
  cardClickable?: boolean;
  type: 'listing' | 'storefront' | 'storefrontItem';
  slug: string;
  id: number;
  intl: any;
};

const CardWrapper: React.FC<PriceProps> = (props) => {
  const navigate = useNavigate();
  const {
    size = LISTING_BREAKPOINT.DEFAULT,
    imageUrl,
    hasAward,
    listingType,
    children,
    className,
    cardClickable,
    type,
    slug,
    id,
    intl
  } = props;
  let cardImage = imageUrl;
  if (listingType === LISTING_TYPES.BUYING && !imageUrl) {
    cardImage = wantedDefaultImage;
  }
  const listingNameAndThem = handleListingAndStorefrontBadge(listingType, intl);
  const routeMap: { [key: string]: string } = {
    listing: ROUTES.LISTING_DETAILS_ROUTE,
    storefront: ROUTES.STOREFRONT_DETAILS_ROUTE,
    storefrontItem: ROUTES.STOREFRONT_ITEM_DETAILS_ROUTE
  };
  const handleNavigation = () => {
    cardClickable && navigate(`${routeMap[type]}/${id}`);
  };
  return (
    <div
      onClick={handleNavigation}
      className={clsx(
        'border border-gray-200 rounded-xl w-full shadow-xs hover:shadow-md focus:shadow-none focus:ring-2.5 focus:ring-gray-300 bg-white ',
        {
          'p-0  sm:p-4': size == LISTING_BREAKPOINT.DEFAULT || size == LISTING_BREAKPOINT.SLIDE,
          'p-0': size == LISTING_BREAKPOINT.MOBILE
        },
        { 'cursor-pointer': cardClickable === true },
        className
      )}>
      <div
        className={clsx('flex items-start gap-x-5 w-full ', {
          'flex-col sm:flex-row':
            size == LISTING_BREAKPOINT.DEFAULT || size == LISTING_BREAKPOINT.SLIDE,
          'flex-col': size == LISTING_BREAKPOINT.MOBILE
        })}>
        {/* card image  */}
        <div
          className={clsx('relative ', {
            'w-full sm:w-[200px] h-[176px] sm:h-[144px]':
              size == LISTING_BREAKPOINT.DEFAULT || size == LISTING_BREAKPOINT.SLIDE,
            ' h-[176px] w-full': size == LISTING_BREAKPOINT.MOBILE
          })}>
          <Image
            alt="not found"
            src={cardImage}
            className={clsx('w-full h-full ', {
              ' rounded-b-none rounded-t-lg ': size == LISTING_BREAKPOINT.MOBILE,
              'rounded-b-none rounded-t-lg sm:rounded-lg':
                size == LISTING_BREAKPOINT.DEFAULT || size == LISTING_BREAKPOINT.SLIDE
            })}></Image>
          <Badge
            size="sm"
            label={listingNameAndThem.name}
            icon={
              listingType !== LISTING_CARD_TYPES.STOREFRONT_ITEM ? (
                <DotIcon></DotIcon>
              ) : (
                <TagItemIcon></TagItemIcon>
              )
            }
            className="absolute bottom-2 left-2"
            theme={listingNameAndThem.theme}></Badge>
          {/* listing award on map page  */}
          {hasAward && (
            <div className={clsx('rounded-full absolute top-2 left-2 p-1 bg-yellow-50 z-4')}>
              <AwardIcon></AwardIcon>
            </div>
          )}
        </div>
        {/* card description  */}
        <div
          className={clsx('flex justify-between w-full h-full', {
            'flex-col pt-5 pl-4 pr-4 pb-4 sm:flex-row-reverse sm:p-0 gap-y-4 sm:gap-y-0':
              size == LISTING_BREAKPOINT.DEFAULT || size == LISTING_BREAKPOINT.SLIDE,
            'flex-col pt-5 pl-4 pr-4 pb-4 gap-y-4': size == LISTING_BREAKPOINT.MOBILE
          })}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CardWrapper;
