import React from 'react';
import Spinner from './Spinner';
import { Typo } from '../Typo';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
type Props = {
  className?: string;
};

const Loader: React.FC<Props> = (props) => {
  const { className } = props;
  const intl = useIntl();

  return (
    <div className={`bg-white flex flex-col gap-y-5 items-center justify-center ${className}`}>
      <Spinner className="h-10 w-10"></Spinner>
      <div className=" text-center">
        <Typo.textLg fontWeight="font-medium" className="text-gray-700">
          {intl.formatMessage({
            id: TRANSLATED_CONSTANTS.LOADING.id,
            defaultMessage: TRANSLATED_CONSTANTS.LOADING.defaultMessage
          })}
        </Typo.textLg>
      </div>
    </div>
  );
};

export default Loader;
