import React, { FC } from 'react';
import { User } from '../../../model/UserModel';
import { useAppSelector } from '../../../lib/redux/hooks';
import MessageReportGridLayout from './MessageReportGridLayout';
import MessageReportDetailedLayout from './MessageReportDetailedLayout';
import TableTitle from '../../../components/shared/table/TableTitle';
import { useIntl } from 'react-intl';
import TableBanner from '../../../components/shared/table/TableBanner';

type Props = {
  user: User;
};

const Messages: FC<Props> = ({ user }) => {
  const intl = useIntl();
  const gridLayout = useAppSelector((state) => state.report.reportLayout);
  return (
    <>
      <div className="py-5">
        <TableTitle
          title={intl.formatMessage({
            id: 'page.report.message.title',
            defaultMessage: 'Reported messages'
          })}
          description={intl.formatMessage({
            id: 'page.report.message.subtitle',
            defaultMessage: 'All reported & team messages are displayed here.'
          })}
        />
      </div>
      <TableBanner type="none" />
      {gridLayout.type === 'grid' ? (
        <MessageReportGridLayout />
      ) : (
        <MessageReportDetailedLayout />
      )}{' '}
    </>
  );
};

export default Messages;
