/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';
import { GET_STOREFRONT } from './useGetStorefront';
import { StorefrontModel } from '../../model/Storefront';

interface StoreFrontInput {
  variables: {
    data: StorefrontModel;
  };
}

export const CREATE_STOREFRONT = gql`
  mutation createStorefront($data: StoreFrontInput!) {
    createStoreFront(data: $data) {
      data {
        id
        attributes {
          name
          description
        }
      }
    }
  }
`;

export const useCreateStorefront = (): ((createStorefront: StoreFrontInput) => any) => {
  const [createStorefrontResult] = useMutation(CREATE_STOREFRONT, {
    refetchQueries: [GET_STOREFRONT, 'GetStorefront']
  });
  return createStorefrontResult;
};
