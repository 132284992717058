import { ActivityItemPropsType } from './../../model/Activity';
import { ListingReport } from '../../model/Listing';
import { StorefrontReport } from '../../model/Storefront';

export const transformMessageReport = (messageReports: any) => {
  const hashmap: any = {};
  for (let i = 0; i < messageReports.length; i++) {
    const claim = messageReports[i].claim;
    hashmap[claim] = (hashmap[claim] || 0) + 1;
  }
  const maxFreq = Math.max(Number(...Object.values(hashmap)));
  const result = Object.keys(hashmap).filter((name) => hashmap[name] == maxFreq);
  return result;
};

export const transformListingsReports = (listing: any) => {
  let sumFlags = 0;
  const activity = listing?.listing_reports?.map((report: ListingReport) => ({
    name: (report.reporter && report?.reporter.name) || '',
    username: (report.reporter && report?.reporter.username) || '',
    time: report.date,
    detail: report.detail || '',
    claim: report.claim || '',
    opened: false,
    type: 'report_listing',
    userId: report.reporter && report.reporter.id,
    userImageUrl: report.reporter?.profileImage?.url,
    link: '',
    accountType: report.reporter && report.reporter.type,
    email: report.reporter && report.reporter.email,
    reportId: report.id
  }));
  listing?.user?.listings?.map((listing: any) => {
    sumFlags += listing?.listing_reports?.length;
  });
  if (listing) {
    listing.activity = activity || [];
    listing.flags = sumFlags;
  }

  return listing;
};
export const transformStorefrontReports = (storefront: any) => {
  let sumFlags = 0;
  const activity = storefront?.storefront_reports?.map((report: StorefrontReport) => ({
    name: (report.reporter && report?.reporter.name) || '',
    username: (report.reporter && report?.reporter.username) || '',
    time: report.date,
    detail: report.detail || '',
    claim: report.claim || '',
    opened: false,
    type: 'report_listing',
    userId: report.reporter && report.reporter.id,
    userImageUrl: report.reporter?.profileImage?.url,
    link: '',
    accountType: report.reporter && report.reporter.type,
    email: report.reporter && report.reporter.email,
    reportId: report.id
  }));
  storefront?.user?.store_fronts?.map((storefront: any) => {
    sumFlags += storefront?.storefront_reports?.length;
  });
  if (storefront) {
    storefront.activity = activity || [];
    storefront.flags = sumFlags;
    storefront.type = 'storefront';
  }

  return storefront;
};

export const transformDeletedListings = (user: any) => {
  let sumFlags = 0;
  let sumWarnings = 0;
  const activities: ActivityItemPropsType[] = [];
  const listings = user?.listings;
  const banIndex = listings?.findIndex(
    (obj: any) => obj?.issued_listing?.actionType === 'delete_ban'
  );
  if (banIndex !== -1) {
    const bannedListing = listings?.splice(banIndex, 1)[0];
    listings?.unshift(bannedListing);
  }
  listings?.map((listing: any) => {
    sumFlags += listing?.listing_reports.length;
    if (listing?.issued_listing?.actionType === 'delete_warning') {
      sumWarnings += 1;
    }

    const activity = {
      name: listing?.issued_listing?.issuer?.name || '',
      username: listing?.issued_listing?.issuer?.username || '',
      time: listing?.issued_listing?.date,
      detail: listing?.issued_listing?.detail || '',
      claim: listing?.issued_listing?.claim || '',
      opened: false,
      type: listing?.issued_listing?.actionType,
      userId: listing?.issued_listing?.issuer?.id,
      userImageUrl: listing?.issued_listing?.issuer?.profileImage?.url,
      link: '',
      accountType: listing?.issued_listing?.issuer?.type,
      email: listing?.issued_listing?.issuer?.email,
      listingId: listing?.id,
      id: listing?.issued_listing?.id
    };
    activities.push(activity);
  });

  const userListings: any = { ...user };
  if (user) {
    userListings.flags = sumFlags;
    userListings.warnings = sumWarnings;
    userListings.listings = listings;
    userListings.activityData = activities;
    userListings.user = {
      name: user?.name,
      email: user?.email,
      username: user?.username,
      id: user?.id,
      createdAt: user?.createdAt
    };
  }
  return userListings;
};

export const transformDeletedStorefronts = (user: any) => {
  let sumFlags = 0;
  let sumWarnings = 0;
  const activities: ActivityItemPropsType[] = [];
  const storefronts = user?.store_fronts;
  const banIndex = storefronts?.findIndex(
    (obj: any) => obj?.issued_listing?.actionType === 'delete_ban'
  );
  if (banIndex !== -1) {
    const bannedStorefront = storefronts?.splice(banIndex, 1)[0];
    storefronts?.unshift(bannedStorefront);
  }
  storefronts?.map((storefront: any) => {
    sumFlags += storefront?.storefront_reports.length;
    if (storefront?.issued_listing?.actionType === 'delete_warning') {
      sumWarnings += 1;
    }

    const activity = {
      name: storefront?.issued_listing?.issuer?.name || '',
      username: storefront?.issued_listing?.issuer?.username || '',
      time: storefront?.issued_listing.date,
      detail: storefront?.issued_listing.detail || '',
      claim: storefront?.issued_listing.claim || '',
      opened: false,
      type: storefront?.issued_listing?.actionType,
      userId: storefront?.issued_listing?.issuer?.id,
      userImageUrl: storefront?.issued_listing?.issuer?.profileImage?.url,
      link: '',
      accountType: storefront?.issued_listing?.issuer?.type,
      email: storefront?.issued_listing?.issuer?.email,
      listingId: storefront?.id,
      id: storefront?.issued_listing?.id
    };
    activities.push(activity);
  });

  const userStorefronts: any = { ...user };
  if (user) {
    userStorefronts.flags = sumFlags;
    userStorefronts.warnings = sumWarnings;
    userStorefronts.store_fronts = storefronts;
    userStorefronts.activityData = activities;
    userStorefronts.user = {
      name: user?.name,
      email: user?.email,
      username: user?.username,
      id: user?.id,
      createdAt: user?.createdAt
    };
  }
  return userStorefronts;
};
