import randomstring from 'randomstring';

export const generateReferralCode = () => {
  const code = randomstring.generate({
    length: 12, // Total length of the code
    charset: 'alphanumeric',
    capitalization: 'uppercase'
  });
  return code;
};
