import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React, { FC } from 'react';
type ArrowProps = {
  onClick?: () => void;
};

const NextArrow: FC<ArrowProps> = ({ onClick }) => {
  return (
    <button
      onClick={onClick}
      className="bg-white rounded-full h-9 w-9 padding-2 flex items-center justify-center  absolute top-[46%] right-4">
      <ChevronRightIcon className="w-5 h-5" />
    </button>
  );
};
export default NextArrow;
