import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_BLOCKED_USERS = gql`
  query GetBlockedUsers($user1: ID!, $user2: ID!) {
    blockedUsers(
      filters: {
        or: [
          { and: [{ blocked: { id: { eq: $user1 } } }, { blocked_by: { id: { eq: $user2 } } }] }
          { and: [{ blocked: { id: { eq: $user2 } } }, { blocked_by: { id: { eq: $user1 } } }] }
        ]
      }
    ) {
      data {
        id
        attributes {
          blocked {
            data {
              id
              attributes {
                username
                name
              }
            }
          }
          blocked_by {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const useGetBlockedUsers = (user1: number, user2: number) => {
  if (!user2) {
    return {
      blockedUsers: null
    };
  }
  const { data } = useQuery(GET_BLOCKED_USERS, {
    variables: { user1, user2 },
    fetchPolicy: 'cache-and-network'
  });

  return {
    blockedUsers: flattenObj(data?.blockedUsers)
  };
};
