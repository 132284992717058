import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/data';
import React from 'react';
import { Typo } from '../Typo';
import CopyRightIcon from '../icons/CopyRightIcon';
import Image from './Image';
import logo from '../../assets/logo.svg';

type Props = {
  className?: string;
  hasCopyWrite?: boolean;
};
const DogeDomain: React.FC<Props> = (props) => {
  const { className, hasCopyWrite } = props;
  return (
    <>
      {hasCopyWrite ? (
        <div key="domain" className="flex gap-x-2 text-gray-500 items-center">
          <CopyRightIcon className="h-[14px] w-[14px]" />
          <Typo.textSm fontWeight="font-normal">Dogelist.com 2022</Typo.textSm>
        </div>
      ) : (
        <Link key="link" to={ROUTES.HOME_ROUTE}>
          <Image src={logo} className="w-[186px] h-9" />
        </Link>
      )}
    </>
  );
};
export default DogeDomain;
