/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';
import { GET_BLOCKED_USERS } from '../chat/useGetBlockedUser';

interface DeleteUserInput {
  variables: {
    id: number;
  };
}

export const DELETE_BLOCKED_USER = gql`
  mutation unBlockUser($id: ID!) {
    deleteBlockedUser(id: $id) {
      data {
        id
      }
    }
  }
`;

export const useUnBlockUser = (): ((deleteUserInput: DeleteUserInput) => any) => {
  const [deleteBlockedUserResult] = useMutation(DELETE_BLOCKED_USER, {
    refetchQueries: [GET_BLOCKED_USERS, 'GetBlockedUsers']
  });
  return deleteBlockedUserResult;
};
