import { useWarnDelete } from './reports/useWarnDelete';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useClearListingFlags } from './reports/useClearListingFlags';
import { useBanDeleteUser } from './reports/useBanDeleteUser';
import { AuthUserState } from '../context/AuthUserProvider';
import { User } from '../model/UserModel';
import { useBanDeleteUsers } from './reports/useBanDeleteUsers';
import { Storefront, StorefrontWithStorefrontReports } from '../model/Storefront';
import { useClearStorefrontFlags } from './reports/useClearStorefrontFlags';

type QueryParams = {
  banUserId: number;
  itemIds: number[];
  banUserName: string;
  banUserEmail: string;
};

export const useHandleStorefrontFlaggedReports = (refetch: any) => {
  const { authUser } = AuthUserState();
  // const clearListingFlags = useClearListingFlags();
  const clearStorefrontFlags = useClearStorefrontFlags();
  const banDeleteUser = useBanDeleteUser();
  const banDeleteUsers = useBanDeleteUsers();
  const warnUser = useWarnDelete();
  const [selectedReports, setSelectedReports] = useState<StorefrontWithStorefrontReports[]>([]);
  const [showClearFlagModal, setShowClearFlagModal] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);
  const [showBanAndDeleteModal, setShowBanAndDeleteModal] = useState(false);
  const [showWarnAndDeleteModal, setShowWarnAndDeleteModal] = useState(false);
  const [userToBeBanned, setUserToBeBanned] = useState<any>({ user: {}, storefront: {} });
  const [report, setReport] = useState<StorefrontWithStorefrontReports>();
  const [reason, setReason] = useState({ claim: '', detail: '' });

  const findReporterIds = (listings: any) => {
    const reporterIds: number[] = [];
    listings?.map((listing: any) => {
      listing?.listing_reports?.map((listingReport: any) => {
        listingReport?.reporter && reporterIds.push(Number(listingReport?.reporter.id));
      });
    });
    return reporterIds;
  };

  const onClearAllFlagButtonClicked = (listing: StorefrontWithStorefrontReports) => {
    const exists = selectedReports.some((report) => report.id === listing.id);
    if (!exists) {
      setSelectedReports([...selectedReports, listing]);
    }
    setShowClearFlagModal(true);
  };

  const onCloseClearAllFlagModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowClearFlagModal(false);
      return;
    }
    const storefrontIds: number[] = selectedReports.map(
      (listing: StorefrontWithStorefrontReports) => Number(listing.id)
    );
    const reporterIds = findReporterIds(selectedReports);
    const response = await clearStorefrontFlags({
      storefrontIds: storefrontIds,
      reporterIds: reporterIds
    });
    if (response) {
      refetch();
    }

    setShowClearFlagModal(false);
  };

  const onBanDeleteUserButtonClicked = (listing: StorefrontWithStorefrontReports) => {
    const exists = selectedReports.some((report) => report.id === listing.id);
    if (!exists) {
      setSelectedReports([...selectedReports, listing]);
    }
    setShowBanAndDeleteModal(true);
  };

  const onBanUserButtonClicked = (user: User, storefront: Storefront) => {
    setUserToBeBanned({ user: user, storefront: storefront });
    setShowBanModal(true);
  };

  const onCloseBanModal = async (state: boolean) => {
    if (state && userToBeBanned) {
      const response = await banDeleteUser({
        banUserId: Number(userToBeBanned.user.id),
        name: userToBeBanned.user.name || '',
        email: userToBeBanned.user.email || '',
        claim: '',
        detail: '',
        bannerId: authUser?.id,
        storefrontId: userToBeBanned.storefront.id || null
      });
      if (response) {
        refetch();
      }
    }
    setShowBanModal(false);
  };

  const onCloseBanAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowBanAndDeleteModal(false);
      return;
    }
    const queryParams: QueryParams[] = [];
    selectedReports.map((report: StorefrontWithStorefrontReports) => {
      const availableUser = queryParams.find(
        (userData: any) => userData.banUserId === Number(report.user.id)
      );
      if (!availableUser) {
        queryParams.push({
          banUserId: Number(report.user.id),
          itemIds: [Number(report.id)],
          banUserName: report?.user?.name || '',
          banUserEmail: report?.user?.email || ''
        });
      } else {
        queryParams.map((userData: QueryParams) => {
          if (userData.banUserId === Number(report.user.id)) {
            userData.itemIds.push(Number(report.id));
          }
        });
      }
    });
    const response = await banDeleteUsers({
      queryParams: JSON.stringify(queryParams),
      bannerId: authUser?.id,
      isListing: false
    });
    if (response) {
      refetch();
    }

    setShowBanAndDeleteModal(false);
  };

  const onDetailedBanDeleteUserButtonClicked = (
    storefront: StorefrontWithStorefrontReports,
    claim: string,
    detail: string
  ) => {
    setReason({ claim: claim, detail: detail });
    setReport(storefront);
    setShowBanAndDeleteModal(true);
  };

  const onCloseDetailedBanAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowBanAndDeleteModal(false);
      return;
    }
    const response = await banDeleteUser({
      banUserId: Number(report?.user.id || 0),
      name: report?.user.name || '',
      email: report?.user.email || '',
      claim: reason.claim,
      detail: reason.detail,
      bannerId: authUser?.id,
      storefrontId: Number(report?.id)
    });
    if (response) {
      refetch();
    }
    setShowBanAndDeleteModal(false);
  };

  const onCloseWarnAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowWarnAndDeleteModal(false);
      return;
    }
    const listingId = selectedReports[0].id;
    const userEmail = selectedReports[0]?.user?.email;
    const userName = selectedReports[0]?.user?.name;
    const warnedId = selectedReports[0]?.user?.id;

    const response = await warnUser({
      storefrontId: Number(listingId),
      userName: userName || '',
      warnUserEmail: userEmail || '',
      warnerId: authUser.id,
      claim: reason.claim,
      detail: reason.detail,
      warnedId: warnedId
    });
    if (response) {
      refetch();
    }
    setShowWarnAndDeleteModal(false);
  };

  const onWarnUserButtonClicked = (
    listing: StorefrontWithStorefrontReports,
    claim: string,
    detail: string
  ) => {
    setSelectedReports([listing]);
    setReason({ claim: claim, detail: detail });
    setShowWarnAndDeleteModal(true);
  };
  return {
    selectedReports,
    setSelectedReports,
    showClearFlagModal,
    showBanModal,
    showBanAndDeleteModal,
    onCloseBanModal,
    onBanDeleteUserButtonClicked,
    onCloseClearAllFlagModal,
    onClearAllFlagButtonClicked,
    onCloseBanAndDeleteModal,
    onBanUserButtonClicked,
    showWarnAndDeleteModal,
    onCloseWarnAndDeleteModal,
    onWarnUserButtonClicked,
    onDetailedBanDeleteUserButtonClicked,
    onCloseDetailedBanAndDeleteModal
  };
};
