type Props = {
  className?: string;
};
const RefreshIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5 text-gray-600 ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.66663 8.33333C1.66663 8.33333 3.33744 6.05685 4.69482 4.69854C6.0522 3.34022 7.92796 2.5 9.99996 2.5C14.1421 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1421 17.5 9.99996 17.5C6.58071 17.5 3.69589 15.2119 2.7931 12.0833M1.66663 8.33333V3.33333M1.66663 8.33333H6.66663"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RefreshIcon;
