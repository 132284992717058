import React, { FC } from 'react';
import { Storefront } from '../../model/Storefront';
import { Typo } from '../../components/Typo';
import OperationHourLabel from '../../components/shared/Hours Operation/OperationHourLabel';
import DirectionButton from '../storefront/DirectionButton';
import Button from '../../components/shared/Button';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ROUTES } from '../../constants/data';

type Props = {
  storefront?: Storefront;
  getDirection: () => void;
};

const StorefrontInfoCard: FC<Props> = ({ storefront, getDirection }) => {
  return (
    <div className="p-6 bg-gray-50 w-full rounded-xl border border-gray-200 flex-col lg:h-full justify-start items-center">
      <Typo.textLg fontWeight="font-medium" className="text-gray-900">
        {storefront?.name}
      </Typo.textLg>
      <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-2">
        {storefront?.description}
      </Typo.textSm>
      <div className="w-auto flex my-5">
        <OperationHourLabel defaultValue={storefront?.operationHours}></OperationHourLabel>
      </div>
      <div className="flex gap-3 w-full">
        <Button
          theme="base"
          to={`${ROUTES.STOREFRONT_DETAILS_ROUTE}/${storefront?.id}`}
          className="w-full">
          <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.VIEW.id}
              defaultMessage={TRANSLATED_CONSTANTS.VIEW.defaultMessage}
            />
          </Typo.textMd>
        </Button>
        <DirectionButton className="w-full" getDirection={() => getDirection()} />
      </div>
    </div>
  );
};

export default StorefrontInfoCard;
