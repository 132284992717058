import Hero from './components/hero';
import AddBusinessToMap from './components/AddBusinessToMap';
import BuyingAndSelling from './components/BuyingAndSelling';
import SellingPersonalBusiness from './components/SellingPersonalBusiness';
import AddListingToMap from './components/AddListingToMap';
import Subscriber from './components/Subscriber';
import Footer from '../../components/footer/footer';
import { AuthUserState } from '../../context/AuthUserProvider';
import { ACCOUNT_TYPE } from '../../constants/data';

const HomePage = () => {
  const user = AuthUserState();
  const authUser = user.authUser;
  return (
    <>
      <div>
        <Hero />
        {(authUser?.type === ACCOUNT_TYPE.BUSINESS || !authUser) && <AddBusinessToMap />}
        <BuyingAndSelling />
        <AddListingToMap />
        <SellingPersonalBusiness />
        <Subscriber />
        <Footer></Footer>
      </div>
    </>
  );
};

export default HomePage;
