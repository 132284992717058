import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../../lib/redux/hooks';
import { useGetDetailedDeletedListings } from '../../../../hooks/reports/useGetDetailedDeletedListings';
import { useHandleIssuedListings } from '../../../../hooks/useHandleIssuedListings';
import Loader from '../../../../components/shared/Loader';
import { ISSUE_LISTING_ACTION_TYPE, REPORT_PAGES } from '../../../../constants/data';
import RedActionModal from '../../../../components/modal/RedActionModal';
import { TRANSLATED_CONSTANTS } from '../../../../static/translatedConstants';
import UserXIcon from '../../../../components/icons/UserXIcon';
import PrimaryActionModal from '../../../../components/modal/PrimaryActionModal';
import TagIcon from '../../../../components/icons/TagIcon';
import { ActivityItemPropsType } from '../../../../model/Activity';
import { useDispatch } from 'react-redux';
import {
  handleListingDetailConstants,
  handleReportActivityInfo
} from '../../../../util/ReportUtil';
import { FormattedMessage, useIntl } from 'react-intl';
import EmptyReportListings from '../../EmptyReportListings';
import { useHandleListingFlaggedReports } from '../../../../hooks/useHandleListingFlaggedReports';
import TrashIcon from '../../../../components/icons/TrashIcon';
import ListingDetailedProfileHeader from './ListingDetailedProfileHeader';
import Contact from '../../detailedLayoutComponents/Contact';
import Divider from '../../../../components/shared/Divider';
import DetailedLayoutListingCards from '../../detailedLayoutComponents/DetailedLayoutListingCards';
import { handleAccountTypeBadge, handleUserRoleBadge } from '../../../../util/ListingBadgeHandler';
import ActivityList from '../../../../components/activities/ActivityList';
import { Typo } from '../../../../components/Typo';
import UserInfo from '../../../../components/shared/table/UserInfo';
import { setChatData, setProfileData, showSlide } from '../../../../lib/redux/reducers/slide.slice';
import { AuthUserState } from '../../../../context/AuthUserProvider';
import Button from '../../../../components/shared/Button';
import Reason from '../../detailedLayoutComponents/Reason';
import RestoreIcon from '../../../../components/icons/RestoreIcon';
import Pagination from '../../../../components/shared/Pagination';
import UnbanAndWarnIcon from '../../../../components/icons/UbanAndWarnIcon';
import { ListingModel, ListingNew } from '../../../../model/Listing';

const DeletedListingsDetailedLayout = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const profileState = useAppSelector((state) => state.slide.profile);
  const selectedDeletedListing = useAppSelector((state) => state.report.deleltedListing);
  const { authUser } = AuthUserState();
  const selectedGridRow = useAppSelector((state) => state.report.reportLayout);
  const searchInput = useAppSelector((state) => state.report.listingSearchInput);
  const clickedActivityItem: ActivityItemPropsType | any = useAppSelector(
    (state) => state.report.clickedActivityData
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [actionType, setActionType] = useState('');
  const [offset, setOffset] = useState(0);
  const [isDeletedListingsFetched, setIsDeletedListingsFetched] = useState(false);
  const [activityData, setActivityData] = useState<ActivityItemPropsType[]>([]);
  const [activeListing, setActiveListing] = useState<any>({
    listing: null,
    issuedListingConstants: null
  });

  const { deletedListings, deletedListingsCount, loading, error, refetch, fetchMore } =
    useGetDetailedDeletedListings(offset, 1, searchInput, selectedDeletedListing?.userId);

  const {
    onRestoreButtonClicked,
    showReslistItemModal,
    onCloseRelistModalModal,
    showWarningInsteadModal,
    onCloseIssueWarningInsteadModal,
    onIssueWarningInsteadClicked
  } = useHandleIssuedListings(refetch);

  const { showBanModal, onBanUserButtonClicked, onCloseBanModal } =
    useHandleListingFlaggedReports(refetch);

  const fetchMoreData = (pageNumber: number) => {
    const startIndex = pageNumber - 1;
    setOffset(startIndex);
    fetchMore({
      variables: { offset: startIndex, limit: 1 }
    });
    setIsDeletedListingsFetched(false);
    setCurrentPage(pageNumber);
  };
  const activityHeader = handleReportActivityInfo(REPORT_PAGES.LISTING_DELETED, intl);

  const handleActiveListing = (listing: ListingNew) => {
    const accountType = handleAccountTypeBadge(listing?.issued_listing?.issuer?.type || '', intl);
    const userRole = handleUserRoleBadge(listing?.issued_listing?.issuer?.role?.name || '', intl);

    const issuedListingConstants = handleListingDetailConstants(
      accountType,
      listing?.issued_listing?.actionType === 'delete_warning' ||
        listing?.issued_listing?.actionType === 'delete'
        ? REPORT_PAGES.LISTING_DELETED_WARNED
        : REPORT_PAGES.LISTING_DELETED_BANED,
      userRole
    );
    setActiveListing({
      listing: listing,
      issuedListingConstants: issuedListingConstants
    });
  };

  useEffect(() => {
    if (deletedListings && deletedListings?.listings?.length > 0 && !isDeletedListingsFetched) {
      const listingFinded = deletedListings?.listings?.filter(
        (li: any) => li?.id == selectedDeletedListing?.listingId
      );
      const listing =
        selectedDeletedListing?.listingId && listingFinded?.length > 0
          ? listingFinded[0]
          : deletedListings?.listings[0];
      setIsDeletedListingsFetched(true);
      handleActiveListing(listing);
    }
  }, [deletedListings]);

  useEffect(() => {
    const filteredListing = deletedListings?.listings?.filter(
      (item: any) => item.id === clickedActivityItem?.listingId
    );
    if (filteredListing?.length > 0) {
      handleActiveListing(filteredListing[0]);
    }
  }, [clickedActivityItem]);

  const issuer = activeListing?.listing?.issued_listing?.issuer;

  const openMessage = () => {
    dispatch(
      setChatData({
        sender: {
          profileImage: { url: authUser.profileImage?.url },
          name: authUser.name,
          username: authUser.username,
          id: authUser.id
        },
        receiver: {
          profileImage: {
            url: activeListing?.listing?.issued_listing?.issuer?.profileImage?.url
          },
          name: activeListing?.listing?.issued_listing?.issuer?.name,
          username: activeListing?.listing?.issued_listing?.issuer?.username,
          id: activeListing?.listing?.issued_listing?.issuer?.id
        }
      })
    );
    dispatch(showSlide('message'));
  };
  return (
    <div>
      {deletedListings?.length === 0 && !loading ? (
        <EmptyReportListings
          title={{
            id: 'page.reports.nothing_deleted_title',
            defaultMessage: 'No deleted yet'
          }}
          subtitle={{
            id: 'page.reports.nothing_deleted_subtitle',
            defaultMessage: 'You don’t have any deleted posts yet.'
          }}
          icon={<TrashIcon className="w-6 h-6 md:h-7 md:w-7 " />}
        />
      ) : (
        <div>
          {loading ? (
            <Loader className="h-screen" />
          ) : (
            <div>
              <div className="w-full flex flex-col lg:flex-row lg:gap-16">
                <div className="w-full flex gap-8 flex-col">
                  <ListingDetailedProfileHeader
                    pageType={REPORT_PAGES.LISTING_DELETED_WARNED}
                    user={deletedListings?.user}
                    openBanModal={() =>
                      onBanUserButtonClicked(deletedListings?.user, activeListing.listing)
                    }
                    warnings={deletedListings?.warnings || 0}
                    flags={deletedListings?.flags || 0}
                  />
                  <div className="md:px-4 lg:pl-4 lg:pr-0 pb-8">
                    <Contact email={deletedListings?.user?.email} />

                    <div className="my-8">
                      <Divider />
                    </div>
                    {activeListing.listing && (
                      <DetailedLayoutListingCards
                        report={activeListing.listing}
                        pageType={REPORT_PAGES.LISTING_FLAG}
                        activityItemData={activeListing.listing}
                        title={activeListing.issuedListingConstants?.title}
                        subtitle={activeListing.issuedListingConstants?.subtitle}
                      />
                    )}

                    <div className="my-8">
                      <Divider />
                    </div>
                    <div className="flex flex-col lg:flex-row gap-8 justify-between items-start">
                      <div className="w-[280px]">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                          {activeListing.issuedListingConstants?.reporterTitle}
                        </Typo.textSm>
                        <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                          {activeListing.issuedListingConstants?.reporterSubTitle}
                        </Typo.textSm>
                      </div>
                      <div className="flex flex-col md:flex-row gap-6 flex-1 w-full  justify-between items-start ">
                        <UserInfo
                          profileImage={
                            activeListing?.listing?.issued_listing?.issuer?.profileImage?.url
                          }>
                          <div className="w-full">
                            <div className="flex  gap-2">
                              <Typo.textMd
                                fontWeight="font-medium"
                                className="text-gray-900 capitalize">
                                {issuer?.name}
                              </Typo.textMd>

                              {activeListing?.issuedListingConstants?.reporterBadge}
                            </div>
                            <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                              {issuer?.email}
                            </Typo.textMd>
                          </div>
                        </UserInfo>
                        <div className="w-full flex gap-4 justify-end">
                          <Button onClick={openMessage} size="md" theme="gray-link">
                            <Typo.textSm fontWeight="font-medium" className="text-gray-600">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.MESSAGE.id}
                                defaultMessage={TRANSLATED_CONSTANTS.MESSAGE.defaultMessage}
                              />
                            </Typo.textSm>
                          </Button>
                          <Button
                            onClick={() => {
                              if (!profileState) {
                                dispatch(
                                  setProfileData({
                                    userId: activeListing?.listing?.issued_listing?.issuer?.id
                                  })
                                );
                                dispatch(showSlide('profile'));
                              }
                            }}
                            size="sm"
                            theme="yellow-link">
                            <Typo.textSm fontWeight="font-semibold" className="">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
                                defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
                              />
                            </Typo.textSm>
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="my-8">
                      <Divider />
                    </div>
                    <Reason
                      claim={activeListing?.listing?.issued_listing?.claim || ''}
                      detail={activeListing?.listing?.issued_listing?.detail || ''}
                    />
                    <div className="mt-8 mb-5">
                      <Divider />
                    </div>

                    <div className="w-full flex justify-end">
                      <div className="flex gap-3">
                        {activeListing?.listing?.issued_listing?.actionType ===
                          ISSUE_LISTING_ACTION_TYPE.DELETE_BAN && (
                          <Button
                            onClick={() => {
                              onIssueWarningInsteadClicked(activeListing.listing);
                            }}
                            size="md"
                            theme="base">
                            <Typo.textSm fontWeight="font-semibold" className="text-gray-700">
                              <FormattedMessage
                                id={TRANSLATED_CONSTANTS.ISSUE_WARNING_INSTEAD.id}
                                defaultMessage={
                                  TRANSLATED_CONSTANTS.ISSUE_WARNING_INSTEAD.defaultMessage
                                }
                              />
                            </Typo.textSm>
                          </Button>
                        )}

                        <Button
                          onClick={() => {
                            onRestoreButtonClicked(activeListing.listing, true);
                            setActionType(activeListing?.listing?.issued_listing?.actionType);
                          }}
                          icon={<RestoreIcon className="text-gray-900 w-5 h-5" />}
                          size="md"
                          theme="primary-yellow">
                          <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
                            <FormattedMessage
                              id={TRANSLATED_CONSTANTS.RESTORE_LISTING.id}
                              defaultMessage={TRANSLATED_CONSTANTS.RESTORE_LISTING.defaultMessage}
                            />
                          </Typo.textSm>
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-8 lg:mr-4 lg:mt-4 md:mx-4 ">
                  <ActivityList
                    activityHeader={activityHeader}
                    activityData={deletedListings?.activityData}
                  />
                </div>
              </div>
              <Divider />
              <div className="px-6">
                <Pagination
                  currentPage={currentPage}
                  totalCount={deletedListingsCount}
                  pageSize={1}
                  onPageChange={fetchMoreData}
                  type="simple-card-footer"
                />
              </div>
            </div>
          )}
        </div>
      )}

      <RedActionModal
        title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
        isOpen={showBanModal}
        onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
        subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
        icon={<UserXIcon />}
        username={deletedListings && deletedListings[0]?.user?.username}
      />
      <PrimaryActionModal
        title={
          actionType === ISSUE_LISTING_ACTION_TYPE.DELETE_BAN
            ? TRANSLATED_CONSTANTS.UNBAN_AND_RESTORE_LISTING_MODAL_TITLE
            : TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_TITLE
        }
        isOpen={showReslistItemModal}
        onClosePrimaryActionModal={(value: boolean) =>
          onCloseRelistModalModal(value, 'listing-quarantine')
        }
        subTitle={
          actionType === ISSUE_LISTING_ACTION_TYPE.DELETE_BAN
            ? TRANSLATED_CONSTANTS.UNBAN_AND_RESTORE_LISTING_MODAL_SUBTITLE
            : TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_SUBTITLE
        }
        confirmText={
          actionType === ISSUE_LISTING_ACTION_TYPE.DELETE_BAN
            ? TRANSLATED_CONSTANTS.UNBAN_AND_RESTORE_LISTING_MODAL_CONFIRM_BUTTON
            : TRANSLATED_CONSTANTS.RELIST_ITEM_MODAL_CONFIRM_BUTTON
        }
        icon={<RestoreIcon />}
        confirmButtonIcon={<RestoreIcon className="text-gray-900 w-5 h-5" />}
      />

      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.UNBAN_AND_ISSUE_WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarningInsteadModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseIssueWarningInsteadModal(value)}
        subTitle={TRANSLATED_CONSTANTS.UNBAN_AND_ISSUE_WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.UNBAN_AND_ISSUE_WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<UnbanAndWarnIcon />}
        confirmButtonIcon={<UnbanAndWarnIcon className="text-gray-900 w-5 h-5" />}
      />
    </div>
  );
};

export default DeletedListingsDetailedLayout;
