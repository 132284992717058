import Button from '../Button';
import React from 'react';
import { LISTING_BREAKPOINT } from '../../../constants/data';
import clsx from 'clsx';
import MapPinIcon from '../../icons/MapPinIcon';
import { Typo } from '../../Typo';
import OperationHourLabel from '../Hours Operation/OperationHourLabel';
import { Storefront } from '../../../model/Storefront';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../lib/redux/reducers/modal.slice';
import { setDirectionData } from '../../../lib/redux/reducers/direction.slice';
type PriceProps = {
  storefront?: Storefront;
  size: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  showTimeLabel?: boolean;
  currentLat: number;
  currentLong: number;
  destinationLat: number;
  destinationLong: number;
  destinationAddress?: string;
};

const GetDirectionAction: React.FC<PriceProps> = (props) => {
  const {
    size = LISTING_BREAKPOINT.DEFAULT,
    storefront,
    showTimeLabel,
    currentLat,
    currentLong,
    destinationLat,
    destinationLong,
    destinationAddress = ''
  } = props;
  const currentLatLng = localStorage.getItem('locationData');
  const stringifiedLatLng = currentLatLng ? JSON.parse(currentLatLng) : '';
  const currentLocationLat =
    currentLat === 0 || currentLat === undefined
      ? stringifiedLatLng?.location?.latitude
      : currentLat;
  const currentLocationLng =
    currentLong === 0 || currentLong === undefined
      ? stringifiedLatLng?.location?.longitude
      : currentLong;
  const intl = useIntl();
  const dispath = useDispatch();
  const onGetDirection = () => {
    dispath(showModal('direction'));
    dispath(
      setDirectionData({
        current: {
          lat: currentLocationLat,
          long: currentLocationLng
        },
        destination: {
          lat: destinationLat,
          long: destinationLong,
          address: destinationAddress
        }
      })
    );
  };
  return (
    <div className="flex items-center gap-x-6">
      <Button
        type="button"
        theme="blue-link"
        className="underline "
        onClick={(event: { stopPropagation: () => void }) => {
          event.stopPropagation();
          onGetDirection();
        }}
        icon={<MapPinIcon className=" stroke-blue-gray-600"></MapPinIcon>}>
        <Typo.textMd fontWeight="font-semibold">
          {intl.formatMessage({
            id: 'shared.get-direction',
            defaultMessage: 'Get Directions'
          })}
        </Typo.textMd>
      </Button>
      {showTimeLabel && (
        <OperationHourLabel
          className={clsx({
            'hidden sm:block':
              size == LISTING_BREAKPOINT.DEFAULT || size == LISTING_BREAKPOINT.SLIDE,
            hidden: size == LISTING_BREAKPOINT.MOBILE
          })}
          defaultValue={storefront?.operationHours}></OperationHourLabel>
      )}
    </div>
  );
};

export default GetDirectionAction;
