import React, { FC } from 'react';
import MapPinIcon from '../icons/MapPinIcon';
import { Typo } from '../Typo';

const Address: FC<{ address: string }> = ({ address }) => {
  return (
    <div className="flex gap gap-2  items-center">
      <MapPinIcon className="w-5 h-5 text-gray-400" />
      <Typo.textMd fontWeight="font-medium" className="text-gray-600">
        {address}
      </Typo.textMd>
    </div>
  );
};

export default Address;
