import Button from '../../../components/shared/Button';
import mapMockup from '../../../assets/homepage/Hero map mockup-23-min.png';
import { useDispatch } from 'react-redux';
import { Typo } from '../../../components/Typo';
import { ROUTES } from '../../../constants/data';
import { showModal } from '../../../lib/redux/reducers/modal.slice';
import { useAuth } from '../../../context/Authn';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
const Hero = () => {
  const dispatch = useDispatch();
  const auth = useAuth();
  const onLogout = () => {
    window.location.href = '/';
    auth.signout();
  };

  return (
    <div className=" relative pb-40 md:pb-72">
      <div className="row relative bg-gradient-to-b h-[750px] pb-[120px] from-[#F9FAFB] to-[#FBFAF9] from-0%  to-100%">
        <div className="max-w-7xl mx-auto">
          <div className="px-5 max-w-6xl mx-auto text-center justify-center text-[#000000]">
            <div className=" w-full flex justify-center">
              <h2 className="py-1 px-3 bg-white mt-12 md:mt-[72px] lg:mt-24 rounded-2xl shadow-xs ">
                <Typo.textSm
                  fontWeight="font-medium"
                  className="text-yellow-600 tracking-normal leading-5">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.WELCOME_TO_DODELIST.id}
                    defaultMessage={TRANSLATED_CONSTANTS.WELCOME_TO_DODELIST.defaultMessage}
                  />
                </Typo.textSm>
              </h2>
            </div>
            <Typo.headingXl
              fontWeight="font-semibold"
              className="text-gray-900 mt-4 text-center flex -tracking-[1px] justify-center w-full">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.EXPLORE_BUY_SELL.id}
                defaultMessage={TRANSLATED_CONSTANTS.EXPLORE_BUY_SELL.defaultMessage}
              />
            </Typo.headingXl>
            <Typo.textXl
              fontWeight="font-normal"
              className=" mt-6 text-center text-gray-600 max-w-3xl mx-auto">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.EXPLORE_BUY_SELL_DETAILS.id}
                defaultMessage={TRANSLATED_CONSTANTS.EXPLORE_BUY_SELL_DETAILS.defaultMessage}
              />
            </Typo.textXl>
            {/* <div> */}
            <div className="mx-auto max-w-lg mt-12 flex flex-col gap-y-4 sm:flex-row justify-center sm:gap-x-4">
              {auth?.getMe() ? (
                <Button size="2xl" onClick={onLogout} className="border border-error-300">
                  <Typo.textLg fontWeight="font-semibold" className="text-error-700">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.LOGOUT.id}
                      defaultMessage={TRANSLATED_CONSTANTS.LOGOUT.defaultMessage}
                    />
                  </Typo.textLg>
                </Button>
              ) : (
                <Button
                  size="2xl"
                  theme="base"
                  onClick={() => dispatch(showModal('signup'))}
                  className="">
                  <Typo.textLg fontWeight="font-semibold">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.SIGN_UP.id}
                      defaultMessage={TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage}
                    />
                  </Typo.textLg>
                </Button>
              )}
              <Button
                to={ROUTES.MARKETPLACE_ROUTE}
                size="2xl"
                theme="primary-yellow"
                className="w-full md:w-auto">
                <Typo.textLg fontWeight="font-semibold" className="text-gray-900 ">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id}
                    defaultMessage={TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage}
                  />
                </Typo.textLg>
              </Button>
            </div>
            {/* </div> */}
            <div className="max-w-7xl relative mx-auto h-[280px] flex justify-center">
              <div className=" mt-12">
                <div className="box-border border border-gray-100 rounded-[10px] shadow-2xl">
                  <img
                    src={mapMockup}
                    className=" rounded-[10px]"
                    alt="Image"
                    width="786"
                    height="512"
                  />
                </div>
              </div>
            </div>
            {/*TODO:remove this part  */}
            {/* <div className=" mt-[72px] mb-28">
            <CarouselElement></CarouselElement>
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
