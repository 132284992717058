type Props = {
  className?: string;
};
const BaselineEmailIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5 4.5H4.5C3.4 4.5 2.51 5.4 2.51 6.5L2.5 18.5C2.5 19.6 3.4 20.5 4.5 20.5H20.5C21.6 20.5 22.5 19.6 22.5 18.5V6.5C22.5 5.4 21.6 4.5 20.5 4.5ZM20.5 8.5L12.5 13.5L4.5 8.5V6.5L12.5 11.5L20.5 6.5V8.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BaselineEmailIcon;
