import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';

export const GET_STOREFRONT_REPORTS = gql`
  query getStorefrontReports($offset: Int, $limit: Int, $searchText: String) {
    storefrontReportsCount: storefrontWithReports(searchText: $searchText) {
      meta {
        pagination {
          total
        }
      }
    }
    storefrontWithReports(pagination: { limit: $limit, start: $offset }, searchText: $searchText) {
      data {
        id
        attributes {
          name
          user {
            data {
              id
              attributes {
                username
                name
                email
                createdAt
                profileImage {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          storefront_reports {
            data {
              id
              attributes {
                reporter {
                  data {
                    id
                  }
                }
                claim
                detail
              }
            }
          }
        }
      }
    }
  }
`;

export const useGetStorefrontReports = (offset: number, limit: number, searchText: string) => {
  const { loading, error, data, refetch, fetchMore } = useQuery(GET_STOREFRONT_REPORTS, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: offset, limit: limit, searchText }
  });
  return {
    storefrontReports: flattenObj(data?.storefrontWithReports),
    storefrontReportsCount: data?.storefrontReportsCount?.meta?.pagination?.total,
    loading,
    error,
    refetch,
    fetchMore
  };
};
