/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FC, createContext, useContext, useState } from 'react';
import { Role } from '../model/UserModel';

type Props = {
  children: React.ReactElement;
};

type AuthUserContextType = {
  authUser: any | undefined;
  setAuthUser: (params: any) => void;
  me: any | undefined;
  setMe: (params: any) => void;
  banned: boolean | undefined;
  setBanned: (params: boolean) => void;
  roles: Role[];
  setRoles: (params: any) => void;
};

const AuthUserContext = createContext<AuthUserContextType>(null!);

const AuthUserProvider: FC<Props> = ({ children }) => {
  const [authUser, setAuthUser] = useState<any>();
  const [me, setMe] = useState<any>();
  const [banned, setBanned] = useState<boolean>(false);
  const [roles, setRoles] = useState<any>();

  return (
    <AuthUserContext.Provider
      value={{ authUser, setAuthUser, me, setMe, banned, setBanned, roles, setRoles }}>
      {children}
    </AuthUserContext.Provider>
  );
};

export const AuthUserState = () => {
  const authUser = useContext(AuthUserContext);
  return authUser;
};

export default AuthUserProvider;
