import { FC } from 'react';
import clsx from 'clsx';

type OperationPropsType = {
  steps: number;
  currentStep: number;
  className?: string;
};

const ProgressDotSteps: FC<OperationPropsType> = (props) => {
  const { steps = 0, currentStep = 1, className } = props;
  const stepsArray = Array.from({ length: steps }, (_, index) => index + 1);

  return (
    <div className={clsx('px-4 py-12 sm:px-16 sm:py-16 w-full', className)}>
      <div className="flex w-full justify-center">
        <div className="flex flex-row max-w-lg gap-x-3 justify-center w-full">
          {stepsArray.map((item) => (
            <div
              key={item}
              className={clsx(
                'h-2 min-w-16 w-full rounded',
                currentStep == item ? 'bg-yellow-700' : 'bg-gray-100'
              )}></div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgressDotSteps;
