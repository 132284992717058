import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ModalState {
  login: boolean;
  signup: boolean;
  forgotPassword: boolean;
  newPassword: boolean;
  age: boolean;
  date: boolean;
  filter: boolean;
  inviteFriend: boolean;
  completeAccountToCreateListing: boolean;
  completeAccountToSendMessage: boolean;
  addAdmin: boolean;
  addEmailRecipient: boolean;
  banUser: boolean;
  blockUser: boolean;
  addModerator: boolean;
  direction: boolean;
  commingSoon: boolean;
}

const modalSlice = createSlice({
  name: 'modals',
  initialState: {
    login: false, // Set initial state for each modal here
    signup: false,
    forgotPassword: false,
    newPassword: false,
    age: false,
    date: false,
    filter: false,
    inviteFriend: false,
    completeAccountToCreateListing: false,
    completeAccountToSendMessage: false,
    addAdmin: false,
    addEmailRecipient: false,
    banUser: false,
    blockUser: false,
    addModerator: false,
    direction: false,
    commingSoon: false
    // Add more modal states as needed
  },
  reducers: {
    showModal: (state, action: PayloadAction<keyof ModalState>) => {
      state[action.payload] = true;
    },
    hideModal: (state, action: PayloadAction<keyof ModalState>) => {
      state[action.payload] = false;
    }
  }
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
