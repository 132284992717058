import React, { FC } from 'react';
import FeaturedIcon from '../../../components/shared/FeaturedIcon';
import SearchIcon from '../../../components/icons/SearchIcon';
import { Typo } from '../../../components/Typo';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import Button from '../../../components/shared/Button';
import { useDispatch } from 'react-redux';
import { addFilter } from '../../../lib/redux/reducers/filter.slice';
import { useAppSelector } from '../../../lib/redux/hooks';

type Props = {
  newSearch?: () => void;
};

const NoListingsFound: FC<Props> = ({ newSearch }) => {
  const dispatch = useDispatch();
  const filterSearchText = useAppSelector((state) => state?.filter.searchText);
  const clearSearch = () => {
    dispatch(addFilter({ state: true }));
  };

  return (
    <div className="w-full flex flex-col justify-center items-center py-5 md:py-12 px-3 border border-gray-200 rounded-xl shadow">
      <div className="w-full md:w-[352px] flex flex-col items-center">
        <FeaturedIcon theme="light-circle-outline" color="primary" size="xl" type="icon">
          <SearchIcon className="w-6 h-6 md:h-7 md:w-7 text-yellow-600" />
        </FeaturedIcon>
        <div className="flex flex-col items-center mt-4 md:mt-5">
          <Typo.textXl fontWeight="font-medium" className="text-gray-900 mb-2">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.NO_LISTING_FOUND.id}
              defaultMessage={TRANSLATED_CONSTANTS.NO_LISTING_FOUND.defaultMessage}
            />
          </Typo.textXl>
          <div className="font-inter text-sm leading-5 md:text-base md:leading-6 text-gray-500 text-center">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.NO_LISTING_FOUND_DETAILS_FIRST_PART.id}
              defaultMessage={
                TRANSLATED_CONSTANTS.NO_LISTING_FOUND_DETAILS_FIRST_PART.defaultMessage
              }
            />
            {' "'}
            <span>{filterSearchText}</span>
            {'" '}
            <span>
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.NO_LISTING_FOUND_DETAILS_SECOND_PART.id}
                defaultMessage={
                  TRANSLATED_CONSTANTS.NO_LISTING_FOUND_DETAILS_SECOND_PART.defaultMessage
                }
              />
            </span>
          </div>
        </div>
        <div className="flex gap-3 mt-6 md:mt-8">
          <Button onClick={clearSearch} theme="base" size="lg">
            <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.CLeAR_SEARCH.id}
                defaultMessage={TRANSLATED_CONSTANTS.CLeAR_SEARCH.defaultMessage}
              />
            </Typo.textMd>
          </Button>
          <Button onClick={newSearch} theme="primary-yellow" size="lg">
            <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.NEW_SEARCH.id}
                defaultMessage={TRANSLATED_CONSTANTS.NEW_SEARCH.defaultMessage}
              />
            </Typo.textMd>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoListingsFound;
