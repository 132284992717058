type Props = {
  className?: string;
};
const Wanted2Icon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.33329 11.6668L4.6455 16.9156C4.6824 17.0633 4.70085 17.1371 4.72268 17.2015C4.93593 17.8311 5.50289 18.2738 6.16539 18.328C6.23321 18.3335 6.30929 18.3335 6.46145 18.3335C6.65199 18.3335 6.74726 18.3335 6.82752 18.3257C7.62079 18.2488 8.24857 17.621 8.32551 16.8277C8.33329 16.7475 8.33329 16.6522 8.33329 16.4617V4.5835M15.4166 11.2502C17.0275 11.2502 18.3333 9.94433 18.3333 8.3335C18.3333 6.72267 17.0275 5.41684 15.4166 5.41684M8.54162 4.5835H5.41663C3.34556 4.5835 1.66662 6.26244 1.66663 8.33351C1.66663 10.4046 3.34556 12.0835 5.41663 12.0835H8.54163C10.0137 12.0835 11.8143 12.8726 13.2035 13.6299C14.014 14.0717 14.4192 14.2926 14.6846 14.2601C14.9307 14.2299 15.1168 14.1194 15.2611 13.9178C15.4166 13.7003 15.4166 13.2652 15.4166 12.3949V4.27207C15.4166 3.40183 15.4166 2.96671 15.2611 2.74924C15.1168 2.54759 14.9307 2.43709 14.6846 2.40694C14.4192 2.37444 14.014 2.59534 13.2035 3.03713C11.8143 3.79443 10.0136 4.5835 8.54162 4.5835Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Wanted2Icon;
