type Props = {
  className?: string;
};
const ArrowLeftIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-4.5 h-5 text-gray-600' + className}
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8334 10H4.16669M4.16669 10L10 15.8333M4.16669 10L10 4.16666"
        strokeWidth="1.67"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowLeftIcon;
