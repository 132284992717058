type Props = {
  className?: string;
};
const CreditCardLockIcon = ({ className }: Props) => {
  return (
    <svg
      className={'h-5 w-5 ' + className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.8753 14.5832V13.1248C16.8753 12.3194 16.2224 11.6665 15.417 11.6665C14.6116 11.6665 13.9587 12.3194 13.9587 13.1248V14.5832M18.3337 8.33317H1.66699M18.3337 9.1665V6.83317C18.3337 5.89975 18.3337 5.43304 18.152 5.07652C17.9922 4.76292 17.7372 4.50795 17.4236 4.34816C17.0671 4.1665 16.6004 4.1665 15.667 4.1665H4.33366C3.40024 4.1665 2.93353 4.1665 2.57701 4.34816C2.2634 4.50795 2.00844 4.76292 1.84865 5.07652C1.66699 5.43304 1.66699 5.89975 1.66699 6.83317V13.1665C1.66699 14.0999 1.66699 14.5666 1.84865 14.9232C2.00844 15.2368 2.2634 15.4917 2.57701 15.6515C2.93353 15.8332 3.40024 15.8332 4.33366 15.8332H9.16699M13.8337 17.9165H17.0003C17.467 17.9165 17.7004 17.9165 17.8787 17.8257C18.0355 17.7458 18.1629 17.6183 18.2428 17.4615C18.3337 17.2832 18.3337 17.0499 18.3337 16.5832V15.9165C18.3337 15.4498 18.3337 15.2164 18.2428 15.0382C18.1629 14.8814 18.0355 14.7539 17.8787 14.674C17.7004 14.5832 17.467 14.5832 17.0003 14.5832H13.8337C13.3669 14.5832 13.1336 14.5832 12.9553 14.674C12.7985 14.7539 12.671 14.8814 12.5912 15.0382C12.5003 15.2164 12.5003 15.4498 12.5003 15.9165V16.5832C12.5003 17.0499 12.5003 17.2832 12.5912 17.4615C12.671 17.6183 12.7985 17.7458 12.9553 17.8257C13.1336 17.9165 13.3669 17.9165 13.8337 17.9165Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreditCardLockIcon;
