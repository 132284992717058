import { ReactElement, useState } from 'react';
import PageWrapper from '../../components/wrapper/PageWrapper';
import { Typo } from '../../components/Typo';
import CurrencyDollarCircleIcon from '../../components/icons/CurrencyDollarCircleIcon';
import Button from '../../components/shared/Button';
import ShareIcon from '../../components/icons/ShareIcon';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Divider from '../../components/shared/Divider';
import ImageSlider from '../../components/shared/ImageSlider/ImageSlider';
import ScalesIcon from '../../components/icons/ScalesIcon';
import Star6Icon from '../../components/icons/Star6Icon';
import CurrencyCard from './CurrencyCard';
import PriceCard from './PriceCard';
import CreditCardLockIcon from '../../components/icons/CreditCardLock';
import DiamondIcon from '../../components/icons/DiamondIcon';
import AwardIcon from '../../components/icons/AwardIcon';
import MessageButton from './MessageButton';
import ReportButton from '../../components/shared/ReportButton';
import Address from '../../components/shared/Address';
import AccountInfo from '../../components/shared/AccountInfo';
import { useGetListing } from '../../hooks/listing/useGetListing';
import { useParams } from 'react-router-dom';
import {
  ALTERNATE_PRICE_OPTION,
  ISSUE_LISTING_ACTION_TYPE,
  LISTING_TYPES,
  ROLE_TYPES,
  ROUTES
} from '../../constants/data';
import { AuthUserState } from '../../context/AuthUserProvider';
import Loader from '../../components/shared/Loader';
import Location from '../../components/shared/map/Location';
import DisLikeIcon from '../../components/icons/DisLikeIcon';
import LikeIcon from '../../components/icons/LikeButton';
import { getCurrentDate } from '../../util/CurrentDate';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import { useIntl } from 'react-intl';
import {
  handleIssuedListingBadge,
  handlePriceOptionContent,
  handlePriceTypeContent
} from '../../util/ListingBadgeHandler';
import { useReportListing } from '../../hooks/listing/useReportListing';
import { useNavigate } from 'react-router-dom';
import { useHandleSave } from '../../hooks/useHandleSave';
import { useDispatch } from 'react-redux';
import Badge from '../../components/shared/Badge';
import { useGetUserRole } from '../../hooks/useRole';
import { useCreateIssuedListing } from '../../hooks/listing/useCreateIssuedListing';
import { useHandleListingAvailabel } from '../../hooks/listing/useHandleListingAvailabel';
import { useHandleUserDecisions } from '../../hooks/listing/useHandelUserDecisions';
import { useHandleMessageSenderAuth } from '../../hooks/useHandleMessageSenderAuth';
import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
import { useAppSelector } from '../../lib/redux/hooks';
import { setProfileData, showSlide } from '../../lib/redux/reducers/slide.slice';
import ListingItems from '../storefront/ListingItems';
import ListingSlideScreen from '../../components/dashboard/slide/ListingSlide';
import { addNotification } from '../../lib/redux/reducers/notification.slice';
import InlineAlert from '../../components/shared/InlineAlert';
import ReportModal from '../../components/modal/ReportModal';
import { setShareData } from '../../lib/redux/reducers/share.slice';
import EditIcon from '../../components/icons/EditIcon';
import { useArchiveListing } from '../../hooks/listing/useArchiveListing';
import { useWarnDelete } from '../../hooks/reports/useWarnDelete';
import PrimaryActionModal from '../../components/modal/PrimaryActionModal';
import TrashIcon from '../../components/icons/TrashIcon';
export const priceTypeIconMap: { [key: string]: ReactElement } = {
  Price_is_firm: <CreditCardLockIcon className="w-5 h-5 text-gray-400" />,
  Open_for_offers: <ScalesIcon className="w-5 h-5 text-gray-400" />
};

export const conditionIconMap = {
  'Brand new': <DiamondIcon className="w-5 h-5 text-gray-400" />,
  'Excellent condition': <Star6Icon className="w-5 h-5 text-gray-400" />
};

export const priceOptionIconMap: { [key: string]: ReactElement } = {
  Negotiable_no_price: <CurrencyDollarCircleIcon className="w-5 h-5 text-gray-400" />,
  Doge_Doge: <AwardIcon className="w-5 h-5" />
};

const Listing = () => {
  const auth = AuthUserState();
  const user = auth?.authUser;
  const navigate = useNavigate();
  const intl = useIntl();
  const role = useGetUserRole();
  const dispatch = useDispatch();
  const [showReportListingModal, setShowReportListingModal] = useState(false);
  const [quarantineModal, setQuarantineModal] = useState(false);
  const [showWarnAndDeleteModal, setShowWarnAndDeleteModal] = useState(false);
  const profileState = useAppSelector((state) => state.slide.profile);
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const [showListingSlide, setShowListingSlide] = useState(false);
  const reportListing = useReportListing();
  const { id } = useParams();
  const { listing, loading, reportCounts, refetch } = useGetListing(Number(id), user?.id);
  const listingUser = listing?.user;
  const isUserOnline = useCheckOnlineUsers(Number(listingUser?.id));
  const { onSendMessage } = useHandleMessageSenderAuth(listing);
  const { save } = useHandleSave(refetch);
  const createIssuedListing = useCreateIssuedListing();
  const handleListingAvailabel = useHandleListingAvailabel();
  const handleUserDecisions = useHandleUserDecisions();
  const { issueTitle, issuedTheme } = handleIssuedListingBadge(intl);
  const warnDelete = useWarnDelete();
  const onShare = () => {
    dispatch(setShareData({ isOpen: true, url: `${ROUTES.LISTING_DETAILS_ROUTE}/${listing?.id}` }));
  };

  const onCloseReportModal = (value: boolean) => {
    setShowReportListingModal(value);
  };
  const onCloseQuarantineModal = (value: boolean) => {
    setQuarantineModal(value);
  };
  const priceOptionTitle = handlePriceOptionContent(
    listing?.price?.alternatePriceOptions || '',
    intl
  );

  const priceTypeTitle = handlePriceTypeContent(listing?.price?.priceType || '', intl);

  const onReport = async (detail: string, claim: string) => {
    await reportListing({
      variables: {
        data: {
          detail: detail,
          claim: claim,
          listing: listing?.id || 0,
          reporter: user?.id,
          date: new Date(),
          publishedAt: getCurrentDate()
        }
      }
    }).then(async (response: any) => {
      if (response) {
        await handleUserDecisions.changeDecisions(user?.id, user?.decisions + 1);
        setShowReportListingModal(false);
        dispatch(
          addNotification({
            id:
              notifications && notifications?.length > 0
                ? notifications[notifications?.length - 1]?.id + 1
                : 1,
            title: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_TITLE.id,
              defaultMessage: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_TITLE.defaultMessage
            }),
            description: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_DESCRIPTION.id,
              defaultMessage: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_DESCRIPTION.defaultMessage
            }),
            type: 'flag',
            linkText: '',
            link: '',
            linkIcon: false
          })
        );
        navigate(-1);
      }
    });
  };

  const onQuarantine = async (detail: string, claim: string) => {
    await createIssuedListing({
      variables: {
        data: {
          detail: detail,
          claim: claim,
          listing: listing?.id || 0,
          issuer: user?.id,
          date: new Date(),
          actionType: ISSUE_LISTING_ACTION_TYPE.QUARANTINE,
          publishedAt: getCurrentDate()
        }
      }
    }).then(async (response: any) => {
      if (response) {
        await handleUserDecisions.changeDecisions(user?.id, user?.decisions + 1);
        await handleListingAvailabel.changeAvailable(listing.id, false);
        setQuarantineModal(false);
        navigate(-1);
      }
    });
    setQuarantineModal(false);
  };

  const onSave = async (liked: boolean, savedId: number | undefined) => {
    save(listing?.id, 'listing', liked, savedId);
  };

  const openProfile = () => {
    // if (role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.SUPER_ADMIN) {
    if (!profileState) {
      dispatch(setProfileData({ userId: listingUser?.id }));
      dispatch(showSlide('profile'));
    }
    // }
  };

  const onCloseWarnAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowWarnAndDeleteModal(false);
      return;
    }
    await warnDelete({
      warnedId: listingUser.id,
      warnerId: user.id,
      listingId: listing.id,
      warnUserEmail: listingUser.email,
      userName: listingUser.username,
      claim: '',
      detail: ''
    }).then(async (response: any) => {
      navigate(-1);
    });
    setShowWarnAndDeleteModal(false);
  };
  const isQuarantined = listing?.issued_listing?.actionType == ISSUE_LISTING_ACTION_TYPE.QUARANTINE;
  return (
    <div>
      <PageWrapper hasSpeicifWidth={true}>
        {!loading && listing ? (
          <div className="flex flex-col gap-8">
            <DashboardHeader
              title={listing?.name}
              cancelButtonIcon={<ShareIcon className="text-gray-700 w-5 h-5" />}
              cancelDisabled={!listing?.available}
              confirmDisabled={!listing?.available || (user && user?.id == listing?.user?.id)}
              onCancelClick={() => onShare()}
              cancelButtonTitle={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SHARE.id,
                defaultMessage: TRANSLATED_CONSTANTS.SHARE.defaultMessage
              })}
              onConfrimClick={
                listing?.saved_listings && listing?.saved_listings?.length > 0
                  ? () => onSave(true, listing?.saved_listings[0].id)
                  : () => onSave(false, undefined)
              }
              confirmButtonTitle={intl.formatMessage({
                id:
                  listing?.saved_listings?.length > 0
                    ? TRANSLATED_CONSTANTS.UNSAVE.id
                    : TRANSLATED_CONSTANTS.SAVE.id,
                defaultMessage:
                  listing?.saved_listings?.length > 0
                    ? TRANSLATED_CONSTANTS.UNSAVE.defaultMessage
                    : TRANSLATED_CONSTANTS.SAVE.defaultMessage
              })}
              editBtn={
                user?.id == listing?.user?.id ? (
                  <Button
                    to={`${ROUTES.EDIT_LISTING_ROUTE}/${listing.id}`}
                    icon={<EditIcon className="h-5 w-5 text-gray-700"></EditIcon>}></Button>
                ) : (
                  <div></div>
                )
              }
              hasDeleteOption={role === ROLE_TYPES.SUPER_ADMIN}
              deleteText={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.DELETE_LISTING.id,
                defaultMessage: TRANSLATED_CONSTANTS.DELETE_LISTING.defaultMessage
              })}
              onDeleteClick={() => setShowWarnAndDeleteModal(true)}
              description={
                <div className="flex items-center mt-1 gap-6">
                  {listing?.location?.address || listing?.location?.street ? (
                    <Address
                      address={
                        listing?.location?.address && listing?.location?.address !== ''
                          ? listing?.location?.address
                          : listing?.location?.street
                      }
                    />
                  ) : (
                    ''
                  )}
                  <div className="hidden md:block">
                    <div className="flex gap-2 items-center">
                      {priceOptionIconMap[listing?.price?.alternatePriceOptions]}
                      <Typo.textMd
                        fontWeight="font-medium"
                        className={
                          listing?.price?.alternatePriceOptions == ALTERNATE_PRICE_OPTION.DOGE_DOGE
                            ? 'text-yellow-700'
                            : 'text-gray-600'
                        }>
                        {listing?.price?.alternatePriceOptions ==
                        ALTERNATE_PRICE_OPTION.NEGOTIABLE ? (
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id}
                            defaultMessage={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage}
                          />
                        ) : (
                          priceOptionTitle.title
                        )}
                      </Typo.textMd>
                    </div>
                  </div>
                  {(role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.MODERATOR) && (
                    <Badge
                      label={`${reportCounts} ${intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.FLAGS.id,
                        defaultMessage: TRANSLATED_CONSTANTS.FLAGS.defaultMessage
                      })}`}
                      theme="error"></Badge>
                  )}
                  {listing?.issued_listing && (
                    <Badge
                      label={issueTitle[listing?.issued_listing?.actionType]}
                      theme={issuedTheme[listing?.issued_listing?.actionType]}></Badge>
                  )}
                </div>
              }></DashboardHeader>
            {/* Hero section  */}
            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-8">
              <ImageSlider
                type={listing?.type}
                className="w-full lg:col-span-2"
                imageUrls={listing?.image?.map((img: any) => img.url)}
              />
              <div className="w-full flex flex-col md:flex-row lg:col-span-1 lg:flex-col gap-5">
                {/* price  */}
                <PriceCard
                  usdPrice={listing?.price?.priceInUSD}
                  dogePrice={listing?.price?.priceInDoge}
                  disabled={
                    (listing?.listing_reports && listing?.listing_reports?.length > 0) ||
                    user?.id == listing?.user?.id
                  }
                  priceOption={listing?.price?.alternatePriceOptions}
                  priceType={listing?.price?.priceType}
                  showReportModal={(value: boolean) => setShowReportListingModal(value)}
                  openMessage={onSendMessage}
                  onQuarantine={() => setQuarantineModal(true)}
                  showAction={true}
                  role={role}
                  isQuarantined={!listing?.available || user?.id == listing?.user?.id}
                />

                {/* currency  */}
                <CurrencyCard currency={listing?.price?.alternatePriceOptions} />
              </div>
            </div>
            {/* Listing Details section  */}
            <div className="w-full flex-col ">
              <div className="flex flex-col-reverse md:flex-row gap-5 mb-6">
                <div className="flex-1 ">
                  <Typo.headingXs fontWeight="font-medium" className="text-gray-900 mb-2">
                    {listing?.name}
                  </Typo.headingXs>
                  <div className="flex gap-2 items-center">
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CATEGORY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                      })}
                    </Typo.textMd>
                    <div className="flex justify-center items-center">
                      <div className="w-[2px] h-[2px] bg-gray-600 rounded-full" />
                    </div>
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {listing?.category?.name}
                    </Typo.textMd>
                  </div>
                </div>
                <AccountInfo
                  className="flex-1 flex justify-start gap-4 items-start cursor-pointer"
                  name={listingUser?.name}
                  isOnline={isUserOnline}
                  username={listingUser?.username}
                  profileImage={listingUser?.profileImage?.url}
                  openProfile={openProfile}
                />
              </div>
              <Divider />
              <div className="flex flex-col gap-6 mt-6 max-w-[824px] mb-6">
                <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.DESCRIPTION.id}
                    defaultMessage={TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage}
                  />
                </Typo.textMd>
                <Typo.textMd fontWeight="font-normal" className="text-gray-500">
                  {listing?.description}
                </Typo.textMd>
                <div className="flex gap-4 flex-wrap md:gap-6">
                  <div className="flex gap-2 items-center">
                    {priceOptionIconMap[listing?.price?.alternatePriceOptions]}
                    <Typo.textMd
                      fontWeight="font-medium"
                      className={
                        listing?.price?.alternatePriceOptions == ALTERNATE_PRICE_OPTION.DOGE_DOGE
                          ? 'text-yellow-700 '
                          : 'text-gray-600'
                      }>
                      {listing?.price?.alternatePriceOptions ==
                      ALTERNATE_PRICE_OPTION.NEGOTIABLE ? (
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id}
                          defaultMessage={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage}
                        />
                      ) : (
                        priceOptionTitle.title
                      )}
                    </Typo.textMd>
                  </div>
                  <div className="flex gap-2 items-center">
                    {priceTypeIconMap[listing?.price?.priceType]}
                    <Typo.textMd fontWeight="font-medium" className="text-gray-600">
                      {priceTypeTitle.title}
                    </Typo.textMd>
                  </div>
                  <div className="flex gap-2 items-center">
                    {conditionIconMap['Excellent condition']}
                    <Typo.textMd fontWeight="font-medium" className="text-gray-600">
                      {listing?.item_condition?.name}
                    </Typo.textMd>
                  </div>
                  {(role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.MODERATOR) &&
                    reportCounts > 0 && (
                      <Badge
                        label={`${reportCounts} ${intl.formatMessage({
                          id: TRANSLATED_CONSTANTS.FLAGS.id,
                          defaultMessage: TRANSLATED_CONSTANTS.FLAGS.defaultMessage
                        })}`}
                        theme="error"></Badge>
                    )}
                  {listing?.issued_listing && (
                    <Badge
                      label={issueTitle[listing?.issued_listing?.actionType]}
                      theme={issuedTheme[listing?.issued_listing?.actionType]}></Badge>
                  )}
                </div>
                <div className="flex gap-3 w-full mt-5">
                  {user?.id == listing?.user?.id && (
                    <Button
                      to={`${ROUTES.EDIT_LISTING_ROUTE}/${listing?.id}`}
                      theme="base"
                      icon={<EditIcon className="h-4" />}
                      size="lg">
                      <Typo.textMd fontWeight="font-semibold">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.EDIT_LISTING.id}
                          defaultMessage={
                            TRANSLATED_CONSTANTS.EDIT_LISTING.defaultMessage
                          }></FormattedMessage>
                      </Typo.textMd>
                    </Button>
                  )}

                  <Button
                    className=""
                    disabled={!listing?.available || user?.id == listing?.user?.id}
                    onClick={
                      listing?.saved_listings && listing?.saved_listings?.length > 0
                        ? () => onSave(true, listing?.saved_listings[0].id)
                        : () => onSave(false, undefined)
                    }
                    size="md"
                    theme="base"
                    icon={
                      listing?.saved_listings?.length > 0 ? <DisLikeIcon /> : <LikeIcon />
                    }></Button>
                  {(role === ROLE_TYPES.REGULAR_USER || role === ROLE_TYPES.SUPER_ADMIN) && (
                    <ReportButton
                      disabled={
                        (listing?.listing_reports && listing?.listing_reports?.length > 0) ||
                        !listing?.available ||
                        user?.id == listing?.user?.id
                      }
                      openReportModal={(value: boolean) => setShowReportListingModal(value)}
                    />
                  )}
                  {role === ROLE_TYPES.ADMIN && (
                    <Button
                      disabled={!listing?.available}
                      to=""
                      className="border border-error-300 disabled:bg-gray-200 disabled:border-error-200 disabled:shadow-xs disabled:opacity-50"
                      size="lg">
                      <Typo.textMd fontWeight="font-semibold" className="text-error-700">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.VIEW_FLAGS.id}
                          defaultMessage={TRANSLATED_CONSTANTS.VIEW_FLAGS.defaultMessage}
                        />
                      </Typo.textMd>
                    </Button>
                  )}
                  {role === ROLE_TYPES.MODERATOR && (
                    <Button
                      disabled={!listing?.available || user?.id == listing?.user?.id}
                      onClick={() => setQuarantineModal(true)}
                      className="border border-error-300 disabled:bg-gray-200 disabled:border-error-200 disabled:shadow-xs disabled:opacity-50"
                      size="lg">
                      <Typo.textMd fontWeight="font-semibold" className="text-error-700">
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.QUARANTINE.id}
                          defaultMessage={TRANSLATED_CONSTANTS.QUARANTINE.defaultMessage}
                        />
                      </Typo.textMd>
                    </Button>
                  )}
                  <MessageButton
                    disabled={!listing?.available || user?.id == listing?.user?.id}
                    openMessage={onSendMessage}
                  />
                </div>
              </div>
              <InlineAlert
                text={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_MESSAGE.id,
                  defaultMessage: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_MESSAGE.defaultMessage
                })}
                className=" mb-6 max-w-[792px]"
                type="default"
                linkText={intl.formatMessage({
                  id: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_LINK_TEXT.id,
                  defaultMessage: TRANSLATED_CONSTANTS.QUARANTINE_ALERT_LINK_TEXT.defaultMessage
                })}
                state={isQuarantined}
                hasIcon={true}
                link={ROUTES.HOME_ROUTE}
              />
              <Divider />
            </div>
            {/* Storefront Items  */}
            {listingUser && (
              <ListingItems
                listingUser={listing?.user || []}
                exceptId={listing?.id}
                userId={user?.id}
                onViewMore={() => setShowListingSlide(true)}
              />
            )}
            {/* Location  */}
            <div className="flex flex-col gap-6">
              <Typo.headingXs fontWeight="font-medium" className="text-gray-900">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.MEETUP_LOCATION.id}
                  defaultMessage={TRANSLATED_CONSTANTS.MEETUP_LOCATION.defaultMessage}
                />
              </Typo.headingXs>
              <div className="h-[400px] w-full bg-[#E2E2E2] rounded-lg">
                {listing && (
                  <Location
                    className="w-full h-[400px]"
                    type="listing"
                    listings={[listing]}
                    refetch={refetch}
                  />
                )}
              </div>
              <div className="flex flex-col-reverse md:flex-row justify-start items-start gap-6 md:gap-16 mb-6">
                <div className="flex flex-col gap-6">
                  <AccountInfo
                    openProfile={openProfile}
                    className=" flex justify-start gap-4 items-start cursor-pointer"
                    name={listingUser?.name}
                    isOnline={isUserOnline}
                    username={listingUser?.username}
                    profileImage={listingUser?.profileImage?.url}
                  />
                  <Button
                    onClick={onSendMessage}
                    disabled={!listing?.available}
                    theme="base"
                    size="lg">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                      <FormattedMessage
                        id={
                          listing?.type === LISTING_TYPES.BUYING
                            ? TRANSLATED_CONSTANTS.CONTACT_BUYER.id
                            : TRANSLATED_CONSTANTS.CONTACT_SELLER.id
                        }
                        defaultMessage={
                          listing?.type === LISTING_TYPES.BUYING
                            ? TRANSLATED_CONSTANTS.CONTACT_BUYER.defaultMessage
                            : TRANSLATED_CONSTANTS.CONTACT_SELLER.defaultMessage
                        }
                      />
                    </Typo.textMd>
                  </Button>
                </div>
                <div className="flex flex-col gap-2">
                  <Address
                    address={
                      listing?.location?.address && listing?.location?.address !== ''
                        ? listing?.location?.address
                        : listing?.location?.street
                    }
                  />
                  <Typo.textMd fontWeight="font-normal" className="text-gray-500 max-w-[760px]">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.LISTING_ADDRESS_DESCRIPTION.id}
                      defaultMessage={
                        TRANSLATED_CONSTANTS.LISTING_ADDRESS_DESCRIPTION.defaultMessage
                      }
                    />
                  </Typo.textMd>
                </div>
              </div>
              <Divider />
            </div>
          </div>
        ) : (
          <Loader className=" h-screen" />
        )}
      </PageWrapper>
      <ReportModal
        onReport={onReport}
        isOpen={showReportListingModal}
        onCloseReportModal={(value: boolean) => onCloseReportModal(value)}
        type="listing"
        confirmText={TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_CONFIRM_BUTTON}
        modalTitle={TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_TITLE}
        dropDownLabel={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_LABEL.id,
          defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_LABEL.defaultMessage
        })}
      />
      <ReportModal
        onReport={onQuarantine}
        isOpen={quarantineModal}
        onCloseReportModal={(value: boolean) => onCloseQuarantineModal(value)}
        type="listing"
        confirmText={TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_CONFIRM_BUTTON}
        modalTitle={TRANSLATED_CONSTANTS.QUARANTINE_MODAL_TITLE}
        dropDownLabel={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_LABEL.id,
          defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_LABEL.defaultMessage
        })}
      />

      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarnAndDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseWarnAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />

      {listingUser ? (
        <ListingSlideScreen
          profileId={listingUser?.id}
          onClose={() => setShowListingSlide(false)}
          open={showListingSlide}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default Listing;
