import { gql, useMutation } from '@apollo/client';
import { ListingModel } from '../../model/Listing';
interface ListingInput {
  variables: {
    data: ListingModel;
    id: number;
  };
}

export const UPDATE_LISTING = gql`
  mutation UpdateListing($id: ID!, $data: ListingInput!) {
    updateListing(data: $data, id: $id) {
      data {
        id
        attributes {
          name
        }
      }
    }
  }
`;

export const useUpdateListing = (): ((updateListing: ListingInput) => any) => {
  const [updateListingResult] = useMutation(UPDATE_LISTING, {
    update(cache, { data: { updateListing } }) {
      cache.modify({
        fields: {
          listing(existingListing = []) {
            return updateListing?.listing;
          }
        }
      });
    }
  });

  return updateListingResult;
};
