type Props = {
  className?: string;
};
const Featured3Icon = ({ className }: Props) => {
  return (
    <>
      <svg
        width="57"
        height="56"
        viewBox="0 0 57 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#FEF2CD" />
        <path
          d="M31.5 37V31.6C31.5 31.0399 31.5 30.7599 31.391 30.546C31.2951 30.3578 31.1422 30.2049 30.954 30.109C30.7401 30 30.4601 30 29.9 30H27.1C26.5399 30 26.2599 30 26.046 30.109C25.8578 30.2049 25.7049 30.3578 25.609 30.546C25.5 30.7599 25.5 31.0399 25.5 31.6V37M19.5 23C19.5 24.6569 20.8431 26 22.5 26C24.1569 26 25.5 24.6569 25.5 23C25.5 24.6569 26.8431 26 28.5 26C30.1569 26 31.5 24.6569 31.5 23C31.5 24.6569 32.8431 26 34.5 26C36.1569 26 37.5 24.6569 37.5 23M22.7 37H34.3C35.4201 37 35.9802 37 36.408 36.782C36.7843 36.5903 37.0903 36.2843 37.282 35.908C37.5 35.4802 37.5 34.9201 37.5 33.8V22.2C37.5 21.0799 37.5 20.5198 37.282 20.092C37.0903 19.7157 36.7843 19.4097 36.408 19.218C35.9802 19 35.4201 19 34.3 19H22.7C21.5799 19 21.0198 19 20.592 19.218C20.2157 19.4097 19.9097 19.7157 19.718 20.092C19.5 20.5198 19.5 21.0799 19.5 22.2V33.8C19.5 34.9201 19.5 35.4802 19.718 35.908C19.9097 36.2843 20.2157 36.5903 20.592 36.782C21.0198 37 21.5799 37 22.7 37Z"
          stroke="#B08503"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#FFF9EB" strokeWidth="8" />
      </svg>
    </>
  );
};

export default Featured3Icon;
