import { useQuery, gql } from '@apollo/client';
import { flattenObj } from '../../util/flattenObj';
import { customizeListings, customizeStorefronts } from '../marketPlace/transform';
import { handleMapPageData } from '../../util/AppUtil';

export const GET_SAVED_LISTINGS = gql`
  query GetSavedStorefrontListings(
    $listingOffset: Int
    $storefrontOffset: Int
    $limit: Int
    $userId: ID!
    $order: [String]
  ) {
    listingCount: listings(
      sort: $order
      filters: { and: [{ saved_listings: { user: { id: { eq: $userId } } } }] }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    listings(
      sort: $order
      pagination: { start: $listingOffset, limit: $limit }
      filters: { and: [{ saved_listings: { user: { id: { eq: $userId } } } }] }
    ) {
      data {
        id
        attributes {
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
              }
            }
          }
          type
          name
          isSold
          description
          location {
            address
            latitude
            longitude
          }
          price {
            alternatePriceOptions
            priceType
            priceInUSD
            priceInDoge
          }
          user {
            data {
              id
              attributes {
                username
                name
              }
            }
          }
          saved_listings(filters: { user: { id: { eq: $userId } } }) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          store_front {
            data {
              id
              attributes {
                name
                location {
                  address
                  latitude
                  longitude
                }
              }
            }
          }
          publishedAt
        }
      }
    }
    storeFrontsCount: storeFronts(
      sort: $order
      filters: { and: [{ saved_storefronts: { user: { id: { eq: $userId } } } }] }
    ) {
      meta {
        pagination {
          total
        }
      }
    }
    storeFronts(
      sort: $order
      pagination: { start: $storefrontOffset, limit: $limit }
      filters: { and: [{ saved_storefronts: { user: { id: { eq: $userId } } } }] }
    ) {
      data {
        id
        attributes {
          name
          description
          image(pagination: { limit: 1 }, sort: ["createdAt:DESC"]) {
            data {
              attributes {
                url
                formats
              }
            }
          }
          user {
            data {
              id
              attributes {
                username
              }
            }
          }
          saved_storefronts(filters: { user: { id: { eq: $userId } } }) {
            data {
              id
              attributes {
                user {
                  data {
                    id
                  }
                }
              }
            }
          }
          location {
            address
            latitude
            longitude
          }
          publishedAt
        }
      }
    }
  }
`;

export const useGetSavedListings = (
  userId: string,
  listingOffset: number,
  storefrontOffset: number,
  limit?: number,
  order?: string
) => {
  const { loading, error, data, fetchMore, refetch } = useQuery(GET_SAVED_LISTINGS, {
    variables: { userId, listingOffset, storefrontOffset, limit, order },
    fetchPolicy: 'cache-and-network'
  });

  const listings = flattenObj(data?.listings);
  const storefronts = flattenObj(data?.storeFronts);
  const customizedListings = customizeListings(listings);
  const customizedStorefronts = customizeStorefronts(storefronts);
  const mapData = handleMapPageData(customizedListings, customizedStorefronts);
  return {
    listings: mapData?.listingsData,
    listingCount: flattenObj(data?.listingCount)?.meta?.pagination?.total,
    storefronts: mapData.storefrontsData,
    storeFrontsCount: flattenObj(data?.storeFrontsCount)?.meta?.pagination?.total,
    fetchMore,
    refetch,
    loading,
    error
  };
};
