import { gql, useMutation } from '@apollo/client';

interface RestoreQuarantineInput {
  listingIds: number[];
  isListing: boolean;
  issuerIds: number[];
  issuedListingIds: number[];
}
const RESTORE_QUARANTINE = gql`
  mutation RestoreQuarantine(
    $listingIds: [ID!]
    $issuerIds: [ID!]
    $issuedListingIds: [ID!]
    $isListing: Boolean
  ) {
    restoreQuarantine(
      listingIds: $listingIds
      issuerIds: $issuerIds
      issuedListingIds: $issuedListingIds
      isListing: $isListing
    ) {
      count
    }
  }
`;

export const useRestoreQuarantine = (): ((restoreQuarantine: RestoreQuarantineInput) => any) => {
  const [restoreQuarantine] = useMutation(RESTORE_QUARANTINE);
  return (input: RestoreQuarantineInput) => restoreQuarantine({ variables: input });
};
