import React, { useState } from 'react';
import Image from '../../shared/Image';
import banner1 from '../../../../src/assets/images/Banner 1.png';
import banner2 from '../../../../src/assets/images/Banner 2.png';
import ProfileAvatar from '../../shared/ProfileAvatar';
import { Typo } from '../../Typo';
import DotIcon from '../../icons/DotIcon';
import Badge from '../../shared/Badge';
import Button from '../../shared/Button';
import BlockIcon from '../../icons/BlockIcon';
import EmailIcon from '../../icons/EmailIcon';
import ProgressBar from '../../shared/ProgressBar';
import HelpIcon from '../../icons/HelpIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import SendIcon from '../../icons/SendIcon';
import { useDispatch } from 'react-redux';
import { setChatData, showSlide } from '../../../lib/redux/reducers/slide.slice';
import { handleAccountTypeBadge, handleUserRoleBadge } from '../../../util/ListingBadgeHandler';
import XIcon from '../../icons/XIcon';
import clsx from 'clsx';
import Divider from '../../shared/Divider';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useGetProfile } from '../../../hooks/user/useGetProfile';
import { getCreatedAt } from '../../../util/AppUtil';
import { ACCOUNT_TYPE, ROLES } from '../../../constants/data';
import { ChatState } from '../../../context/ChatNotificationProvider';
import { useGetBlockedUsers } from '../../../hooks/chat/useGetBlockedUser';
import RedActionModal from '../../modal/RedActionModal';
import { useBlockUser } from '../../../hooks/profile/useBlockUser';
import { useUnBlockUser } from '../../../hooks/profile/useUnBlockUser';
import ProfileListings from './profile/ProfileListings';
import ProfileStorefronts from './profile/ProfileStorefronts';
import LogOutIcon from '../../icons/LogOutIcon';
import { DropdownOption } from '../../../model/DropdownOption';
import MessageAlertIcon from '../../icons/MessageAlertIcon';
import UserXIcon from '../../icons/UserXIcon';
import { addNotification } from '../../../lib/redux/reducers/notification.slice';
import { flattenObj } from '../../../util/flattenObj';
import { useAuth } from '../../../context/Authn';
import { CircleFlag } from 'react-circle-flags';
import DropdownMenu from '../../shared/DropdownMenu';
import { useHandleProfile } from '../../../hooks/profile/useHandleProfile';
type Props = {
  open: boolean;
  onClose: () => void;
  className?: string;
};

const ProfileSlideScreen: React.FC<Props> = (props) => {
  const { open, onClose, className } = props;
  const dispatch = useDispatch();
  const profileData = useAppSelector((state) => state.slide.profileData);
  const auth = useAuth();
  const authUser = auth.getUser();
  const messageState = useAppSelector((state) => state.slide.message);
  const notifications = useAppSelector((state) => state.notifications.notifications);
  const { user, reportCounts, warnedCounts, refetch } = useGetProfile(
    profileData?.userId,
    authUser
  );
  const authUserRole = authUser?.role?.name;
  const [showBlockModal, setShowBlockModal] = useState(false);
  const intl = useIntl();
  const accountTypeMap = handleAccountTypeBadge(user?.type, intl);
  const roleMap = handleUserRoleBadge(user?.role?.name, intl);
  const { onlineUsers } = ChatState();
  const blockUser = useBlockUser();
  const unBlockUser = useUnBlockUser();
  const { blockedUsers } = useGetBlockedUsers(profileData?.userId, authUser?.id);
  const { onCloseBanModal, showBanModal, onBanUserButtonClicked } = useHandleProfile(refetch);
  const onBlockUser = async (state: boolean) => {
    if (state) {
      const response = await blockUser({
        variables: {
          data: {
            blocked: profileData?.userId,
            blocked_by: authUser?.id,
            date: new Date(),
            publishedAt: new Date()
          }
        }
      });
      if (response) {
        const res = flattenObj(response?.data?.createBlockedUser);
        dispatch(
          addNotification({
            id:
              notifications && notifications?.length > 0
                ? notifications[notifications?.length - 1]?.id + 1
                : 1,
            title: intl.formatMessage(
              {
                id: TRANSLATED_CONSTANTS.YOU_BLOCKED_TITLE.id,
                defaultMessage: TRANSLATED_CONSTANTS.YOU_BLOCKED_TITLE.defaultMessage
              },
              { name: res.blocked.username }
            ),
            description: intl.formatMessage(
              {
                id: TRANSLATED_CONSTANTS.YOU_BLOCKED_MESSAGE.id,
                defaultMessage: TRANSLATED_CONSTANTS.YOU_BLOCKED_MESSAGE.defaultMessage
              },
              { name: res.blocked.username }
            ),
            type: 'block-ban',
            linkText: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.VIEW_MY_BLOCKED_USERS.id,
              defaultMessage: TRANSLATED_CONSTANTS.VIEW_MY_BLOCKED_USERS.defaultMessage
            }),
            link: '',
            linkIcon: true
          })
        );
      }
    }
    setShowBlockModal(false);
  };

  const onUnBlockUser = async (id: number) => {
    await unBlockUser({ variables: { id: id } });
  };

  const options: DropdownOption[] = [
    {
      id: 1,
      title: 'Message user',
      icon: <MessageAlertIcon className="h-3.5 text-gray-500" />
    },
    {
      id: 2,
      title: 'Ban user',
      icon: <UserXIcon className="h-3.5 text-gray-500" />
    }
  ];

  const onChangeOption = (option: DropdownOption) => {
    if (option.id == 1) {
      dispatch(
        setChatData({
          sender: {
            profileImage: { url: authUser?.profileImage?.url },
            name: authUser?.name,
            username: authUser?.username,
            id: authUser?.id
          },
          receiver: user
        })
      );
      dispatch(showSlide('message'));
    } else if (option.id == 2) {
      onBanUserButtonClicked(user);
    }
  };

  return (
    <div
      className={clsx(
        'flex h-full flex-col w-full md:w-[552px] divide-y divide-gray-200 bg-white shadow-xl',
        open ? 'flex' : ' hidden',
        className
      )}>
      <div className="flex min-h-0 flex-1 flex-col">
        <div className="flex items-start justify-between py-5 px-4">
          <Typo.textXl fontWeight="font-medium" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.PROFILE.id}
              defaultMessage={TRANSLATED_CONSTANTS.PROFILE.defaultMessage}
            />
          </Typo.textXl>
          <div className=" flex gap-3 h-7 items-center">
            {/* <DropdownMenu options={options} onChange={onChangeOption} type="h-dots"></DropdownMenu> */}
            <Button
              onClick={onClose}
              theme="gray"
              size="md"
              icon={<XIcon className="h-5 w-5" aria-hidden="true" />}>
              <span className="sr-only">Close panel</span>
            </Button>
          </div>
        </div>
        <div className="relative h-screen overflow-y-auto">
          <div className=" h-screen overflow-y-auto">
            <div className="w-full relative flex ">
              <Image
                className="w-full h-[120px]"
                src={user?.type === ACCOUNT_TYPE.PERSONAL ? banner1 : banner2}></Image>
              <ProfileAvatar
                verified={true}
                image={user?.profileImage?.url}
                className=" absolute bottom-0 translate-y-[50%] left-4 w-24 h-24"
                verfiedClassName="h-6 w-6 right-[3px] bottom-[3px]"></ProfileAvatar>
            </div>
            <div className="px-4">
              <div className=" mt-16 flex items-center gap-x-2">
                <Typo.headingXs fontWeight="font-medium" className=" text-gray-900">
                  {user?.name}
                </Typo.headingXs>
                <DotIcon
                  className={` h-2.5 w-2.5 ${
                    onlineUsers && onlineUsers[profileData?.userId]?.online
                      ? ' text-success-500'
                      : 'text-gray-300'
                  } `}></DotIcon>
              </div>
              <div className=" flex gap-x-2 items-center">
                <Typo.textMd fontWeight="font-normal" className=" text-gray-500 mt-1">
                  @{user?.username}
                </Typo.textMd>
                {blockedUsers?.length > 0 && (
                  <>
                    <DotIcon className="h-1 w-1 text-gray-400"></DotIcon>
                    <BlockIcon className="text-gray-400 h-5 w-5" />
                    <Typo.textMd fontWeight="font-normal" className=" text-gray-500 mt-1">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.BLOCKED.id}
                        defaultMessage={TRANSLATED_CONSTANTS.BLOCKED.defaultMessage}
                      />
                    </Typo.textMd>
                  </>
                )}
              </div>
              <div className="flex gap-x-1 mt-4">
                <Badge theme={accountTypeMap.theme} label={accountTypeMap.name}></Badge>
                <Badge theme={roleMap.theme} label={roleMap.name}></Badge>
                {authUser && reportCounts > 0 ? (
                  <Badge
                    theme="error"
                    label={
                      `${reportCounts} ` +
                      intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.FLAGS.id,
                        defaultMessage: TRANSLATED_CONSTANTS.FLAGS.defaultMessage
                      })
                    }></Badge>
                ) : (
                  ''
                )}
                {authUser && warnedCounts > 0 && (
                  <Badge
                    theme="warning"
                    label={
                      `${warnedCounts} ` +
                      intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.WARNINGS.id,
                        defaultMessage: TRANSLATED_CONSTANTS.WARNINGS.defaultMessage
                      })
                    }></Badge>
                )}
              </div>
              <div className=" flex mt-4 gap-x-3 items-center">
                {authUser && authUser?.id !== profileData.userId && (
                  <DropdownMenu
                    contentClassName=" left-0"
                    options={
                      authUserRole === ROLES.ADMIN || authUserRole === ROLES.SUPER_ADMIN
                        ? options
                        : [options[0]]
                    }
                    onChange={onChangeOption}
                    type="h-dots"></DropdownMenu>
                )}

                {authUser && authUser?.id !== profileData.userId && (
                  <Button
                    icon={<BlockIcon className="block h-5 w-5 sm:hidden" />}
                    size="md"
                    theme="base"
                    disabled={user?.role?.name == ROLES.SUPER_ADMIN}
                    onClick={() => {
                      blockedUsers?.length > 0
                        ? onUnBlockUser(Number(blockedUsers[0]?.id))
                        : setShowBlockModal(true);
                    }}
                    className="">
                    <Typo.textSm fontWeight="font-medium" className=" hidden sm:block">
                      <FormattedMessage
                        id={
                          blockedUsers?.length > 0
                            ? TRANSLATED_CONSTANTS.UNBLOCK.id
                            : TRANSLATED_CONSTANTS.BLOCK_USER.id
                        }
                        defaultMessage={
                          blockedUsers?.length > 0
                            ? TRANSLATED_CONSTANTS.UNBLOCK.defaultMessage
                            : TRANSLATED_CONSTANTS.BLOCK_USER.defaultMessage
                        }
                      />
                    </Typo.textSm>
                  </Button>
                )}

                {user?.type === ACCOUNT_TYPE.PERSONAL && authUser?.id !== profileData.userId && (
                  <Button
                    size="md"
                    theme="primary-yellow"
                    disabled={messageState}
                    onClick={() => {
                      dispatch(
                        setChatData({
                          sender: {
                            profileImage: { url: authUser?.profileImage?.url },
                            name: authUser?.name,
                            username: authUser?.username,
                            id: authUser?.id
                          },
                          receiver: user
                        })
                      );
                      dispatch(showSlide('message'));
                    }}
                    icon={<SendIcon className="h-5 w-5" />}
                    className="">
                    <Typo.textSm fontWeight="font-semibold">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.DIRECT_MESSAGE.id,
                        defaultMessage: TRANSLATED_CONSTANTS.DIRECT_MESSAGE.defaultMessage
                      })}
                    </Typo.textSm>
                  </Button>
                )}

                {user?.type === ACCOUNT_TYPE.BUSINESS && authUser?.id !== profileData.userId && (
                  <Button size="md" theme="primary-yellow" className="">
                    <Typo.textSm fontWeight="font-semibold">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.VIEW_STOREFRONT.id,
                        defaultMessage: TRANSLATED_CONSTANTS.VIEW_STOREFRONT.defaultMessage
                      })}
                    </Typo.textSm>
                  </Button>
                )}

                {authUser?.id == profileData?.userId ? (
                  <Button
                    onClick={() => {
                      close();
                      auth.signout();
                      window.location.href = '/';
                    }}
                    size="md"
                    theme="base"
                    icon={<LogOutIcon className="h-5 w-5" />}
                    className="">
                    <Typo.textSm fontWeight="font-semibold">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.LOGOUT.id,
                        defaultMessage: TRANSLATED_CONSTANTS.LOGOUT.defaultMessage
                      })}
                    </Typo.textSm>
                  </Button>
                ) : (
                  ''
                )}
              </div>
              <div className=" grid grid-cols-1 space-y-6 sm:grid-cols-2 mt-6">
                <div>
                  <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.INFO.id,
                      defaultMessage: TRANSLATED_CONSTANTS.INFO.defaultMessage
                    })}
                  </Typo.textSm>
                  <Typo.textSm fontWeight="font-normal" className="text-gray-500 mt-1">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.MEMBER_SINCE.id,
                      defaultMessage: TRANSLATED_CONSTANTS.MEMBER_SINCE.defaultMessage
                    })}
                    {' ' + getCreatedAt(user?.createdAt)}
                  </Typo.textSm>
                  <div className="flex gap-x-2 items-center mt-2">
                    {user?.email && <EmailIcon className="h-4 w-4 text-gray-400"></EmailIcon>}
                    <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                      {user?.email}
                    </Typo.textSm>
                  </div>
                </div>
                {user?.language_proficiencies && user?.language_proficiencies?.length > 0 && (
                  <div>
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.LANGUAGE_PROFICIENCY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.LANGUAGE_PROFICIENCY.defaultMessage
                      })}
                    </Typo.textSm>
                    {user?.language_proficiencies?.map((language: any) => {
                      return (
                        <Typo.textSm
                          key={language?.id}
                          fontWeight="font-normal"
                          className="text-gray-500 mt-1">
                          {language?.level} - {language?.language?.title}
                        </Typo.textSm>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className=" grid grid-cols-1 space-y-6 sm:grid-cols-2 mt-6">
                {user?.countryCode && user?.countryCode && (
                  <div className="flex flex-col gap-y-1 col-span-1 items-start">
                    <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.COUNTRY_REGION.id,
                        defaultMessage: TRANSLATED_CONSTANTS.COUNTRY_REGION.defaultMessage
                      })}
                    </Typo.textSm>
                    <div className="flex gap-x-1 items-center">
                      <CircleFlag
                        countryCode={user.countryCode}
                        height="20"
                        width="20"
                        className=" h-5 w-5 "
                      />
                      <Typo.textSm fontWeight="font-normal" className="text-gray-500">
                        {user.countryName}
                      </Typo.textSm>
                    </div>
                  </div>
                )}
                {user?.role?.name == ROLES.MODERATOR ||
                  (user?.role?.name == ROLES.ADMIN && (
                    <div className="flex flex-col col-span-1 items-start">
                      <div className="flex items-center">
                        <Typo.textSm fontWeight="font-medium" className="text-gray-700">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.MOD_SCORE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.MOD_SCORE.defaultMessage
                          })}
                          :
                        </Typo.textSm>
                        <Typo.textSm fontWeight="font-medium" className="text-gray-700 ml-3">
                          {user?.correctDecisions}/{user?.decisions}
                        </Typo.textSm>
                        <HelpIcon className=" text-gray-400 h-4 w-4 ml-2"></HelpIcon>
                      </div>
                      <ProgressBar
                        value={(user?.correctDecisions * 100) / user?.decisions}
                        showLabel={true}></ProgressBar>
                    </div>
                  ))}
              </div>
              <Divider className="bg-gray-200 my-6" />
              <div className="w-full h-60">
                {user?.type == ACCOUNT_TYPE?.PERSONAL ? (
                  <ProfileListings profileId={profileData?.userId} userId={authUser?.id} />
                ) : (
                  <ProfileStorefronts profileId={profileData?.userId} userId={authUser?.id} />
                )}
              </div>
            </div>
          </div>
        </div>
        <RedActionModal
          title={TRANSLATED_CONSTANTS.BLOCK_MODAL_TITLE}
          isOpen={showBlockModal}
          onCloseRedActionModal={onBlockUser}
          subTitle={TRANSLATED_CONSTANTS.BLOCK_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.BLOCK_USER}
          icon={<BlockIcon />}
          username={user?.username}
        />
        <RedActionModal
          title={TRANSLATED_CONSTANTS.BAN_MODAL_TITLE}
          isOpen={showBanModal}
          onCloseRedActionModal={(value: boolean) => onCloseBanModal(value)}
          subTitle={TRANSLATED_CONSTANTS.BAN_MODAL_SUBTITLE}
          confirmText={TRANSLATED_CONSTANTS.BAN_MODAL_CONFIRM_BUTTON}
          icon={<UserXIcon />}
          username={user?.username}
        />
      </div>
    </div>
  );
};

export default ProfileSlideScreen;
