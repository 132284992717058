type Props = {
  className?: string;
};
const DisLikeIcon = ({ className }: Props) => {
  return (
    <svg
      className={`h-5 w-5  ${className}`}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3009_15878)">
        <path
          d="M2.5 2.5L9.58333 9.58333L17.5 17.5"
          stroke="currentColor"
          strokeWidth="1.67"
          strokeLinecap="round"
        />
        <path
          d="M9.99426 5.11315C11.6604 3.1653 14.4029 2.6601 16.5263 4.42498C18.6497 6.18986 18.9077 9.19078 17.2684 11.3003"
          stroke="currentColor"
          strokeWidth="1.67"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <mask
          id="mask0_3009_15878"
          //   style="mask-type:alpha"
          maskUnits="userSpaceOnUse"
          x="-6"
          y="2"
          width="22"
          height="21">
          <rect
            x="9.04053"
            y="22.741"
            width="19.8669"
            height="9.13855"
            transform="rotate(-135.03 9.04053 22.741)"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_3009_15878)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.99425 5.11316C8.32813 3.16531 5.54975 2.64135 3.46221 4.42499C1.37466 6.20863 1.08077 9.19079 2.72012 11.3003C4.08314 13.0542 8.2081 16.7534 9.56004 17.9507C9.7113 18.0846 9.78692 18.1516 9.87514 18.1779C9.95213 18.2009 10.0364 18.2009 10.1134 18.1779C10.2016 18.1516 10.2772 18.0846 10.4285 17.9507C11.7804 16.7534 15.9054 13.0542 17.2684 11.3003C18.9077 9.19079 18.6497 6.18987 16.5263 4.42499C14.4029 2.66011 11.6604 3.16531 9.99425 5.11316Z"
            stroke="currentColor"
            strokeWidth="1.67"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_3009_15878">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DisLikeIcon;
