import { FC, useEffect, useRef, useState, useCallback, memo } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import './map.css';
import clsx from 'clsx';
import { useAppSelector } from '../../../lib/redux/hooks';
import { useGetMarketPlaceMapData } from '../../../hooks/marketPlace/useGetMarketPlaceMapData';
import { ApiSearchOrderEnum } from '../../../util/enum/enum';
import { AuthUserState } from '../../../context/AuthUserProvider';
import wantedIcon from '../../../assets/wanted-marker.svg';
import personalIcon from '../../../assets/personal-marker.svg';
import storeFrontIcon from '../../../assets/storefront-marker.svg';
import clusteringIcon from '../../../assets/tagIcon.svg';
import PopupSlider from './PopupSlider';
import { useDispatch } from 'react-redux';
import { addFilterModal, addLatLng } from '../../../lib/redux/reducers/filter.slice';
import { MapContainer, Marker, Popup, TileLayer, ZoomControl, useMap } from 'react-leaflet';
import * as L from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';

type Props = {
  className?: string;
  width?: string;
  height?: string;
  isMobile?: boolean;
};

const listingIcon = L.icon({
  iconUrl: personalIcon,
  iconSize: [38, 38]
});
const storeIcon = L.icon({
  iconUrl: storeFrontIcon,
  iconSize: [38, 38]
});
const needIcon = L.icon({
  iconUrl: wantedIcon,
  iconSize: [38, 38]
});

const createClusterCustomIcon = function (cluster: L.MarkerCluster) {
  const iconUrls = [];
  let hasPersonalItem = false;
  let hasStorefront = false;
  let hasWantedItem = false;

  const markers = cluster.getAllChildMarkers();
  for (let index = 0; index < markers.length; index++) {
    const iconUrl = markers[index]?.options.icon?.options.iconUrl;
    if (iconUrl)
      if (iconUrl?.includes('personal')) {
        hasPersonalItem = true;
      } else if (iconUrl?.includes('storefront')) {
        hasStorefront = true;
      } else if (iconUrl?.includes('wanted')) {
        hasWantedItem = true;
      }
  }
  if (hasPersonalItem) {
    iconUrls.push(personalIcon);
  }
  if (hasStorefront) {
    iconUrls.push(storeFrontIcon);
  }
  if (hasWantedItem) {
    iconUrls.push(wantedIcon);
  }

  const childCount = cluster.getChildCount();

  return L.divIcon({
    html: `<div class='cluster-cont' > <div><img src='${clusteringIcon}' class='cluster-icon'/></div>
    <div class='cluster-child-count'>${childCount}</div><div class="image-container">
    ${iconUrls.map((url, index) => `<img src="${url}" alt="Image ${index + 1}">`).join('')}
</div>
  </div>`,
    className: 'custom-marker-cluster',
    iconSize: L.point(75 + iconUrls.length * 28 + childCount.toString().length * 5, 56, true)
  });
};
const MapboxLe: FC<Props> = ({ className, width, height, isMobile = false }) => {
  const { authUser } = AuthUserState();
  const mapRef = useRef<any>(null);
  const dispatch = useDispatch();
  const [isFlyto, setIsFlyto] = useState<boolean>(false);
  const filterFromModal = useAppSelector((state) => state.filter.filterModal);
  const [points, setPoints] = useState<any>([]);
  const [lastBounds, setLastBounds] = useState<any>(null);
  const latLng = useAppSelector((state) => state.filter.latLng);

  const [viewport, setViewport] = useState<any>({
    center: [latLng?.lat, latLng.lng],
    zoom: latLng?.zoom // CLICKED_ZOOM
  });

  useEffect(() => {
    if (mapRef.current) {
      const locBounds = filterFromModal.locationBounds;
      setIsFlyto(true);
      if (locBounds) {
        const maxLat = locBounds.maxLat;
        const maxLng = locBounds.maxLng;
        const minLat = locBounds.minLat;
        const minLng = locBounds.minLng;
        const leafletBounds = L.latLngBounds(L.latLng(minLat, minLng), L.latLng(maxLat, maxLng));
        mapRef.current.fitBounds(leafletBounds, {
          duration: 1500 / 1000 // Duration in seconds
        });
      }
    }
  }, [filterFromModal.address]);

  const { geoData, loading } = useGetMarketPlaceMapData(
    filterFromModal?.moreFilter,
    filterFromModal.categories,
    filterFromModal.searchText,
    filterFromModal.locationBounds,
    0,
    0,
    ApiSearchOrderEnum.PUBLISHED_DESC,
    authUser?.id || null
  );

  useEffect(() => {
    const useableData = geoData ? JSON.parse(geoData) : null;
    setPoints(
      useableData?.features?.map((listing: any) => ({
        type: 'Feature',
        properties: {
          cluster: false,
          listingId: listing.id,
          category: listing.category,
          data: listing.properties
        },
        geometry: {
          type: 'Point',
          coordinates: listing.geometry.coordinates
        }
      }))
    );
  }, [geoData]);

  const handleMapMove = useCallback(async () => {
    const isPopupOpen = document.getElementsByClassName('leaflet-popup')?.length > 0;
    if (mapRef.current && !isFlyto && !isPopupOpen) {
      const leafletMap = mapRef.current;
      const bounds = leafletMap.getBounds();
      if (bounds.isValid()) {
        const center = leafletMap.getCenter();
        setViewport({
          center: [center.lat, center.lng],
          zoom: latLng.zoom
        });
        const boundsChanged =
          lastBounds === null ||
          bounds.getNorthEast().distanceTo(lastBounds?.getNorthEast()) > 2200 ||
          bounds.getSouthWest().distanceTo(lastBounds?.getSouthWest()) > 2200;

        if (boundsChanged) {
          dispatch(addLatLng({ latLng: { lat: center.lat, lng: center.lng, zoom: latLng.zoom } }));
          dispatch(
            addFilterModal({
              filterModal: {
                locationBounds: {
                  maxLat: bounds.getNorthEast().lat,
                  maxLng: bounds.getNorthEast().lng,
                  minLat: bounds.getSouthWest().lat,
                  minLng: bounds.getSouthWest().lng
                },
                moreFilter: filterFromModal?.moreFilter,
                searchText: filterFromModal.searchText,
                categories: filterFromModal.categories,
                locationData: {
                  address: 'Current View',
                  latitude: center.lat,
                  longitude: center.lng
                },
                address: 'Current View'
              }
            })
          );
          setLastBounds(bounds);
        }
      }
    }
    setIsFlyto(false);
  }, [filterFromModal, isFlyto]);

  const MapEventComponent = () => {
    const map = useMap();

    useEffect(() => {
      map.on(
        'moveend',
        handleMapMove // Call fetchData with debouncing
      );

      return () => {
        map.off('moveend', handleMapMove);
      };
    }, []);
    return null;
  };

  return (
    <div className={clsx('w-[100vh] lg:w-full lg:h-[calc(100vh-96px)] pr-10', className)}>
      <MapContainer
        center={viewport.center}
        style={{ width: width, height: `${height ?? '100%'}` }}
        zoom={viewport.zoom}
        minZoom={2}
        ref={mapRef}
        className="z-0"
        scrollWheelZoom={true}
        fadeAnimation={true}
        zoomAnimation={true}
        zoomControl={false}>
        <ZoomControl position="topright" />
        <TileLayer attribution="" url={process.env.REACT_APP_MAPBOX_TILE ?? ''} />
        {!loading ? (
          <>
            <MarkerClusterGroup
              showCoverageOnHover={false}
              iconCreateFunction={createClusterCustomIcon}
              chunkedLoading>
              {points?.map((cluster: any, index: number) => {
                const [latitude, longitude] = cluster.geometry.coordinates;
                const type = cluster.properties?.data[0]?.type;
                return (
                  <Marker
                    icon={
                      type === 'wanted' ? needIcon : type === 'personal' ? listingIcon : storeIcon
                    }
                    key={`${index}type`}
                    position={[latitude, longitude]}>
                    <Popup maxWidth={isMobile ? 199 : 239}>
                      {cluster?.properties?.data?.length > 0 && (
                        <PopupSlider listings={cluster.properties.data} />
                      )}
                    </Popup>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
            <MapEventComponent />
          </>
        ) : (
          ''
        )}
      </MapContainer>
    </div>
  );
};

export default MapboxLe;
