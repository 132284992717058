import React, { FC } from 'react';
import UserInfo from '../../../components/shared/table/UserInfo';
import Badge from '../../../components/shared/Badge';
import { handleAccountTypeBadge } from '../../../util/ListingBadgeHandler';
import { Typo } from '../../../components/Typo';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setChatData, setProfileData, showSlide } from '../../../lib/redux/reducers/slide.slice';
import Button from '../../../components/shared/Button';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { useAppSelector } from '../../../lib/redux/hooks';
import { AuthUserState } from '../../../context/AuthUserProvider';
import { ActivityItemPropsType } from '../../../model/Activity';

type Props = {
  activityItemData: ActivityItemPropsType | undefined;
};

const Reporter: FC<Props> = ({ activityItemData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { authUser } = AuthUserState();
  const accountType = handleAccountTypeBadge(activityItemData?.accountType || '', intl);
  const profileState = useAppSelector((state) => state.slide.profile);

  const openMessage = () => {
    dispatch(
      setChatData({
        sender: {
          profileImage: { url: authUser.profileImage?.url },
          name: authUser.name,
          username: authUser.username,
          id: authUser.id
        },
        receiver: {
          profileImage: {
            url: activityItemData?.userImageUrl
          },
          name: activityItemData?.name,
          username: activityItemData?.username,
          id: activityItemData?.userId
        }
      })
    );
    dispatch(showSlide('message'));
  };
  return (
    <div className="flex flex-col md:flex-row gap-6 flex-1 w-full  justify-between items-start ">
      <UserInfo profileImage={activityItemData?.userImageUrl || ''}>
        <div className="w-full">
          <div className="flex  gap-2 truncate">
            <Typo.textMd fontWeight="font-medium" className="text-gray-900 capitalize ">
              {activityItemData?.name}
            </Typo.textMd>

            <Badge hasDot={false} theme={accountType.theme} label={accountType.name}></Badge>
          </div>
          <Typo.textMd fontWeight="font-normal" className="text-gray-600 ">
            {activityItemData?.email}
          </Typo.textMd>
        </div>
      </UserInfo>
      <div className="w-full flex gap-4 justify-end">
        <Button onClick={openMessage} size="md" theme="gray-link">
          <Typo.textSm fontWeight="font-medium" className="text-gray-600">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.MESSAGE.id}
              defaultMessage={TRANSLATED_CONSTANTS.MESSAGE.defaultMessage}
            />
          </Typo.textSm>
        </Button>
        <Button
          onClick={() => {
            if (!profileState) {
              dispatch(
                setProfileData({
                  userId: activityItemData?.userId
                })
              );
              dispatch(showSlide('profile'));
            }
          }}
          size="sm"
          theme="yellow-link">
          <Typo.textSm fontWeight="font-semibold" className="">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.VIEW_PROFILE.id}
              defaultMessage={TRANSLATED_CONSTANTS.VIEW_PROFILE.defaultMessage}
            />
          </Typo.textSm>
        </Button>
      </div>
    </div>
  );
};

export default Reporter;
