import React, { useEffect, useState, KeyboardEvent, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typo } from '../../Typo';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Button from '../../shared/Button';
import { MessageModel, MessageSlide } from '../../../model/Message';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { ChatState } from '../../../context/ChatNotificationProvider';
import { useGetMessages } from '../../../hooks/chat/useGetMessages';
import { useGetBlockedUsers } from '../../../hooks/chat/useGetBlockedUser';
import { useFindOrCreateChat } from '../../../hooks/chat/useFindOrCreateChat';
import Loader from '../../shared/Loader';
import XIcon from '../../icons/XIcon';
import Avatar from '../../shared/Avatar';
import Badge from '../../shared/Badge';
import DotIcon from '../../icons/DotIcon';
import MessageDate from './MessageDate';
import ReceiveMessage from './ReceiveMessage';
import SendMessage from './SendMessage';
import InfoIcon from '../../icons/InfoIcon';
import InputField from '../../shared/InputField';
import SendIcon from '../../icons/SendIcon';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { setProfileData, showSlide } from '../../../lib/redux/reducers/slide.slice';
import { useAppSelector } from '../../../lib/redux/hooks';
import User1Icon from '../../icons/User1Icon';
import ReportModal from '../../modal/ReportModal';
import { useCreateReportMessage } from '../../../hooks/reports/useCreateReportMessage';
import { getCurrentDate } from '../../../util/CurrentDate';
import { useGetMessageReceiver } from '../../../hooks/user/useGeMessageReceiver';

type Props = {
  open: boolean;
  onClose: (state: boolean) => void;
  className?: string;
  chatData: MessageSlide;
};

const MessageSlideScreen: React.FC<Props> = (props) => {
  const { socket, setChatId } = ChatState();
  const { open, onClose, chatData } = props;
  const dispatch = useDispatch();
  const profileState = useAppSelector((state) => state.slide.profile);

  const receiverId = chatData?.receiver?.id;

  const senderId = chatData?.sender?.id;
  const intl = useIntl();
  const [offset, setOffset] = useState(0);
  const [isChatFetched, setIsChatFetched] = useState(false);
  const { chat } = useFindOrCreateChat(chatData?.receiver?.id, chatData?.sender?.id);
  const { receiver } = useGetMessageReceiver(receiverId);
  const { loading, messages, error, fetchMore, refetch } = useGetMessages(chat?.id, offset, -1);

  const { blockedUsers } = useGetBlockedUsers(receiverId, senderId);
  const [socketConnected, setSocketConnected] = useState(false);
  const [typing, setTyping] = useState(false);
  const [istyping, setIsTyping] = useState(false);
  const [lastItem, setLastItem] = useState<any>(null);
  const [content, setContent] = useState('');
  const [showMessageReportModal, setShowMessageReportModal] = useState(false);
  const [reportedMessageId, setReportedMessageId] = useState(0);
  const reportMessage = useCreateReportMessage();
  useEffect(() => {
    if (socket && chat && !isChatFetched) {
      setIsChatFetched(true);
      socket.emit('join chat', chat.id);
      setChatId(chat.id);
    }
  }, [socket, chat]);

  useEffect(() => {
    if (socket) {
      socket.on('joined chat', () => {
        setSocketConnected(true);
      });
      socket.on('typing', () => {
        setIsTyping(true);
      });
      socket.on('stop typing', () => setIsTyping(false));

      // remove the previous event listener
      socket.off('receive message');
      socket.on('receive message', () => {
        refetch();
      });
    }
  }, [socket]);
  const schema = yup.object().shape({
    content: yup.string()
  });

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    const messageContent = {
      sender: chatData.sender,
      receiver: chatData.receiver,
      message: {
        content: data.content,
        chatId: chat.id,
        dateTime: ''
      }
    };
    if (socket) {
      socket.emit('send message', messageContent);
    }
    reset();
    setContent('');
    scrollToBottom();
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    lastItem?.scrollIntoView({ behavior: 'smooth' });
  };

  const typingHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
    if (!socketConnected) return;

    if (!typing) {
      setTyping(true);
      socket.emit('typing', chat.id);
    }
    const lastTypingTime = new Date().getTime();
    const timerLength = 3000;
    setTimeout(() => {
      const timeNow = new Date().getTime();
      const timeDiff = timeNow - lastTypingTime;
      if (timeDiff >= timerLength && typing) {
        socket.emit('stop typing', chat.id);
        setTyping(false);
      }
    }, timerLength);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(() => onSubmit({ content: content }))();
    }
  };

  const onReport = async (detail: string, claim: string) => {
    await reportMessage({
      variables: {
        data: {
          detail: detail,
          claim: claim,
          message: Number(reportedMessageId),
          reporter: Number(senderId),
          reported_user: Number(receiverId),
          date: new Date(),
          publishedAt: getCurrentDate()
        }
      }
    }).then(() => {
      setShowMessageReportModal(false);
    });
  };

  const onCloseReportModal = (value: boolean) => {
    setShowMessageReportModal(value);
  };
  return (
    <div
      className={clsx(
        'flex h-[calc(100vh_-_10px)] pb-12 sm:pb-0 flex-col divide-y divide-gray-200 bg-white shadow-xl w-[335px] md:w-[400px]',
        open ? 'flex' : ' hidden'
      )}>
      <div className="flex min-h-0 flex-1 flex-col">
        <div className="flex items-start justify-between py-5 px-4">
          <Typo.textXl fontWeight="font-medium" className="text-gray-900">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.MESSAGES.id}
              defaultMessage={TRANSLATED_CONSTANTS.MESSAGES.defaultMessage}
            />
          </Typo.textXl>
          <div className=" flex gap-3 h-7 items-center">
            <Button
              onClick={onClose}
              theme="gray"
              size="md"
              icon={<XIcon className="h-5 w-5" aria-hidden="true" />}>
              <span className="sr-only">Close panel</span>
            </Button>
          </div>
        </div>
        <div className="relative h-screen">
          <div className="flex h-full flex-col bg-white shadow-xl pb-20">
            {/* First Row */}
            <div className="flex-none justify-end">
              <div
                onClick={() => {
                  if (!profileState) {
                    dispatch(setProfileData({ userId: chatData?.receiver?.id }));
                    dispatch(showSlide('profile'));
                  }
                }}
                className="flex gap-x-4 border-y px-4 border-gray-300 py-5 cursor-pointer">
                <Avatar
                  size="lg"
                  disabled={true}
                  icon={!receiver?.profileImage?.url && <User1Icon className="text-gray-600" />}
                  image={receiver?.profileImage?.url}></Avatar>
                <div className="truncate">
                  <div className="flex gap-x-2 items-center">
                    <Typo.textLg fontWeight="font-medium" className="text-gray-900">
                      {receiver?.name}
                    </Typo.textLg>
                    <Badge theme="blue" label="Personal"></Badge>
                  </div>
                  <div className="flex gap-x-2 items-center ">
                    <Typo.textSm
                      fontWeight="font-normal"
                      className=" text-blue-gray-600 flex-grow truncate">
                      @{receiver?.username}
                    </Typo.textSm>
                    {blockedUsers?.length > 0 &&
                      blockedUsers[0]?.blocked_by?.id === senderId.toString() && (
                        <div className="flex items-center gap-x-2">
                          <DotIcon className="h-[6px] w-[6px] text-gray-400 "></DotIcon>
                          <Typo.textSm fontWeight="font-medium">
                            {intl.formatMessage({
                              id: TRANSLATED_CONSTANTS.BLOCKED.id,
                              defaultMessage: TRANSLATED_CONSTANTS.BLOCKED.defaultMessage
                            })}
                          </Typo.textSm>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {/* Middle Row */}
            <div className="flex-grow min-h-0 overflow-y-auto h-full bg-white ">
              {loading ? (
                <Loader className="h-[400px]" />
              ) : (
                <div className="relative p-4 space-y-4 pb-40">
                  {messages?.map((message: MessageModel, index: number) => {
                    return (
                      <div key={index}>
                        <MessageDate
                          index={index}
                          currentDate={message.dateTime}
                          beforeDate={index == 0 ? message.dateTime : messages[index - 1].dateTime}
                        />
                        {message.senderId !== Number(chatData.sender.id) ? (
                          <ReceiveMessage
                            setShowMessageReportModal={() => {
                              setShowMessageReportModal(true);
                              setReportedMessageId(message.id);
                            }}
                            message={message.content}
                            dateTime={message.dateTime}
                            name={receiver?.name || ''}
                            profileImage={receiver?.profileImage?.url || ''}
                            userId={receiver?.id}
                          />
                        ) : (
                          <SendMessage message={message.content} dateTime={message.dateTime} />
                        )}
                      </div>
                    );
                  })}
                  {blockedUsers?.length > 0 && (
                    <div className="w-full bg-gray-100 mt-8 mb-6 p-2 rounded-lg flex gap-x-2 items-start">
                      <div className="w-4 h-4">
                        <InfoIcon className="w-4 h-4 text-gray-500" />
                      </div>
                      {blockedUsers[0]?.blocked_by?.id == senderId.toString() && (
                        <Typo.textXs fontWeight="font-normal" className="text-gray-600">
                          @{receiver?.username}{' '}
                          <FormattedMessage
                            id="message.blocker_text"
                            defaultMessage="is now blocked and can’t contact you anymore. Unblock to start a new conversation."
                          />
                        </Typo.textXs>
                      )}
                      {blockedUsers[0]?.blocked_by?.id === receiverId.toString() && (
                        <Typo.textXs fontWeight="font-normal" className="text-gray-600">
                          @{receiver.username}{' '}
                          <FormattedMessage
                            id="message.blocked_text"
                            defaultMessage="has blocked you and you can't contact this user."
                          />
                        </Typo.textXs>
                      )}
                    </div>
                  )}
                  <div ref={(el) => setLastItem(el)}></div>
                </div>
              )}
            </div>
          </div>

          {/* Last Row */}
          <div className=" absolute bottom-36 flex bg-white gap-x-3 w-full justify-end px-6 border-t border-t-gray-300 py-4">
            <form
              id="add-collection"
              className="w-full"
              onSubmit={handleSubmit(onSubmit)}
              action="#"
              method="POST">
              {istyping ? (
                <div className="flex mb-4 ml-0 bg-gray-50 w-[70px] h-[30px] rounded-2xl items-center justify-center">
                  <div className="h-3 w-3 bg-gray-200 rounded-full mr-1 animate-bounce"></div>
                  <div className="h-3 w-3 bg-gray-200 rounded-full mr-1 animate-bounce200"></div>
                  <div className="h-3 w-3 bg-gray-200 rounded-full animate-bounce400"></div>
                </div>
              ) : (
                ''
              )}
              <div className="flex gap-x-3 w-full items-start ">
                <InputField
                  disabled={blockedUsers?.length > 0 ? true : false}
                  onChange={typingHandler}
                  name="content"
                  placeholder={intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.MESSAGE.id,
                    defaultMessage: TRANSLATED_CONSTANTS.MESSAGE.defaultMessage
                  })}
                  onkeydown={handleKeyDown}
                  register={register}
                  type="text"
                  className="w-full"
                  errors={errors?.content}
                />
                <Button
                  disabled={blockedUsers?.length > 0 ? true : false}
                  type="submit"
                  theme="primary-yellow"
                  icon={<SendIcon className="h-5 w-5" />}></Button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ReportModal
        onReport={onReport}
        isOpen={showMessageReportModal}
        onCloseReportModal={(value: boolean) => onCloseReportModal(value)}
        type="message"
        confirmText={TRANSLATED_CONSTANTS.REPORT_MESSAGE_MODAL_CONFIRM_BUTTON}
        modalTitle={TRANSLATED_CONSTANTS.REPORT_MESSAGE_MODAL_TITLE}
        dropDownLabel={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.REPORT_MESSAGE_MODAL_LABEL.id,
          defaultMessage: TRANSLATED_CONSTANTS.REPORT_MESSAGE_MODAL_LABEL.defaultMessage
        })}
      />
    </div>
  );
};

export default MessageSlideScreen;
