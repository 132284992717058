import Footer from '../../components/footer/footer';
import dogeIcon from '../../assets/dogelist-Icon.svg';
import dogeDog from '../../assets/DonationPic.png';
import DogeWallet from '../../assets/dogewallet.png';
import paypalIcon from '../../assets/paypal_icon.png';
import Button from '../../components/shared/Button';
import DogeIcon from '../../components/icons/DogeIcon';

import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';

import Modal from '../../components/modal/Modal';
import { useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../context/Authn';
import { Typo } from '../../components/Typo';
import { showModal } from '../../lib/redux/reducers/modal.slice';
import { ROUTES } from '../../constants/data';
import { useDispatch } from 'react-redux';
import Subscriber from '../homePage/components/Subscriber';
import CopyIcon from '../../components/icons/CopyIcon';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { CheckCircleIcon } from '@heroicons/react/24/outline';
// This value is from the props in the UI
const walletAddress = process.env.REACT_APP_WALLET_ADDRESS ?? 'D7b61sEjrx3tBenLrGFUnfGrCSam1SGx8y';
const donationPaypalLink = process.env.REACT_APP_PAYPAL_DONATION_LINK ?? '';

const paymentSuccessAlert = () => {
  return (
    <div className="pointer-events-auto w-full max-w-[540px]  overflow-hidden  ">
      <div className={clsx(' p-4 rounded-xl  border border-success-300 bg-success-50')}>
        <div className="flex">
          <div className="flex flex-row">
            <div className="flex-shrink-0 mb-3 md:mb-0">
              <CheckCircleIcon className="w-5 h-5 text-success-600" />
            </div>
            <div className="md:ml-3">
              <Typo.textSm fontWeight="font-semibold" className={'text-success-700 text-base'}>
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.DONATION_SUCCESS_TITLE.id}
                  defaultMessage={TRANSLATED_CONSTANTS.DONATION_SUCCESS_TITLE.defaultMessage}
                />
              </Typo.textSm>
              <Typo.textSm fontWeight="font-normal" className={'text-success-700 text-base'}>
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.DONATION_SUCCESS_DES.id}
                  defaultMessage={TRANSLATED_CONSTANTS.DONATION_SUCCESS_DES.defaultMessage}
                />
              </Typo.textSm>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function About() {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const auth = useAuth();

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const myParam = params.get('payment');
  const dispatch = useDispatch();

  useEffect(() => {
    if (myParam === 'success') setPaymentSuccess(true);
    const alertTimeout = setTimeout(() => {
      setPaymentSuccess(false);
      console.log('timeout');
    }, 4000);

    return () => clearTimeout(alertTimeout);
  }, []);
  const onLogout = () => {
    window.location.href = '/';
    auth.signout();
  };
  const copyToClipboard = () => {
    setIsCopied(true);
    navigator.clipboard.writeText(walletAddress);
  };

  const onCancel = () => {
    setIsModalOpen(false);
  };

  const openPaypalDonationLink = () => {
    window.open(donationPaypalLink, '_blank');
  };

  const renderHomePageButtons = useMemo(() => {
    return (
      <div className="max-w-lg mt-10 flex flex-col gap-y-4 sm:flex-row justify-start sm:gap-x-4">
        {auth?.getMe() ? (
          <Button size="2xl" onClick={onLogout} className="border border-error-300">
            <Typo.textLg fontWeight="font-semibold" className="text-error-700">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.LOGOUT.id}
                defaultMessage={TRANSLATED_CONSTANTS.LOGOUT.defaultMessage}
              />
            </Typo.textLg>
          </Button>
        ) : (
          <Button
            size="2xl"
            theme="base"
            onClick={() => dispatch(showModal('signup'))}
            className="">
            <Typo.textLg fontWeight="font-semibold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.SIGN_UP.id}
                defaultMessage={TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage}
              />
            </Typo.textLg>
          </Button>
        )}
        <Button
          to={ROUTES.MARKETPLACE_ROUTE}
          size="2xl"
          theme="primary-yellow"
          className="w-full md:w-auto">
          <Typo.textLg fontWeight="font-semibold" className="text-gray-900 ">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id}
              defaultMessage={TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage}
            />
          </Typo.textLg>
        </Button>
      </div>
    );
  }, []);

  return (
    <div>
      <div className="min-h-[518px] bg-[#FEF2CD] ">
        <div className="px-[16px] sm:px-0 sm:w-3/4 py-24 mx-auto">
          <img src={dogeIcon} className="h-20" />
          <div className="text-6xl text-[#111828] font-semibold">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.aboutTitle.id}
              defaultMessage={TRANSLATED_CONSTANTS.aboutTitle.defaultMessage}
            />
          </div>
          <div className="text-2xl text-[#111828] font-semibold pt-10 py-2">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.aboutDescription.id}
              defaultMessage={TRANSLATED_CONSTANTS.aboutDescription.defaultMessage}
            />
          </div>
          <div className="md:w-2/4 text-lg text-[#475466]">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.dogelistDescription.id}
              defaultMessage={TRANSLATED_CONSTANTS.dogelistDescription.defaultMessage}
            />
          </div>
          {renderHomePageButtons}
        </div>
      </div>
      <div className="px-[16px] sm:px-0 sm:w-3/4 pt-24 mx-auto">
        {/* donation buttons */}
        <div className="flex  md:flex-row  flex-col-reverse justify-between">
          <div className="basis-3/5">
            <div className="text-2xl text-[#111828] font-semibold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.donationTitle.id}
                defaultMessage={TRANSLATED_CONSTANTS.donationTitle.defaultMessage}
              />
            </div>
            <div className="text-lg sm:pr-[6rem]  text-[#475466] mt-2">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.donationDescription.id}
                defaultMessage={TRANSLATED_CONSTANTS.donationDescription.defaultMessage}
              />
            </div>
            <div className="my-4">
              <Button
                onClick={() => setIsModalOpen(true)}
                className="w-[250px]  text-[#475466] font-bold"
                icon={<DogeIcon className="h-5 w-5" />}
                theme="primary-yellow">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.donateViaDogecoin.id}
                  defaultMessage={TRANSLATED_CONSTANTS.donateViaDogecoin.defaultMessage}
                />
              </Button>
              <div className="mt-2">
                <Button
                  onClick={openPaypalDonationLink}
                  className="w-[250px] bg-white text-[#111828] font-bold"
                  icon={<img src={paypalIcon} className="h-5 w-5" />}
                  theme="base-transparent">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.donateViaPayPal.id}
                    defaultMessage={TRANSLATED_CONSTANTS.donateViaPayPal.defaultMessage}
                  />
                </Button>
              </div>
              <div className="mt-4  md:pr-[8rem]">{paymentSuccess && paymentSuccessAlert()}</div>
            </div>
          </div>
          <div className="basis-2/5 pb-8 text-right">
            <img src={dogeDog} alt="Your Image" className=" h-auto" />
          </div>
        </div>
        <section className="my-12 text-[#111828]">
          <div className="py-4">
            <div className="text-3xl  py-4 font-bold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.missionTitle.id}
                defaultMessage={TRANSLATED_CONSTANTS.missionTitle.defaultMessage}
              />
            </div>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.missionDescription.id}
                defaultMessage={TRANSLATED_CONSTANTS.missionDescription.defaultMessage}
              />
            </p>
          </div>

          <div className="py-4">
            <div className="text-3xl py-4 font-bold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.envisioningTitle.id}
                defaultMessage={TRANSLATED_CONSTANTS.envisioningTitle.defaultMessage}
              />
            </div>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.envisioningDescription.id}
                defaultMessage={TRANSLATED_CONSTANTS.envisioningDescription.defaultMessage}
              />
            </p>
          </div>

          <div className="py-4">
            <div className="text-3xl py-4 font-bold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.whyDogelistTitle.id}
                defaultMessage={TRANSLATED_CONSTANTS.whyDogelistTitle.defaultMessage}
              />
            </div>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.whyDogelistDescription1.id}
                defaultMessage={TRANSLATED_CONSTANTS.whyDogelistDescription1.defaultMessage}
              />
            </p>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.whyDogelistDescription2.id}
                defaultMessage={TRANSLATED_CONSTANTS.whyDogelistDescription2.defaultMessage}
              />
            </p>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.whyDogelistDescription3.id}
                defaultMessage={TRANSLATED_CONSTANTS.whyDogelistDescription3.defaultMessage}
              />
            </p>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.whyDogelistDescription4.id}
                defaultMessage={TRANSLATED_CONSTANTS.whyDogelistDescription4.defaultMessage}
              />
            </p>
          </div>

          <div className="py-4">
            <div className="text-3xl py-4 font-bold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.magicTitle.id}
                defaultMessage={TRANSLATED_CONSTANTS.magicTitle.defaultMessage}
              />
            </div>
            <p className="text-[#475466] text-lg">
              <ul className="list-decimal px-[16px] ">
                <li>
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.magicDescription1.id}
                    defaultMessage={TRANSLATED_CONSTANTS.magicDescription1.defaultMessage}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.magicDescription2.id}
                    defaultMessage={TRANSLATED_CONSTANTS.magicDescription2.defaultMessage}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.magicDescription3.id}
                    defaultMessage={TRANSLATED_CONSTANTS.magicDescription3.defaultMessage}
                  />
                </li>
                <li>
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.magicDescription4.id}
                    defaultMessage={TRANSLATED_CONSTANTS.magicDescription4.defaultMessage}
                  />
                </li>
              </ul>
            </p>
          </div>

          <div className="py-4">
            <div className="text-3xl py-4 font-bold">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.joinUsTitle.id}
                defaultMessage={TRANSLATED_CONSTANTS.joinUsTitle.defaultMessage}
              />
            </div>
            <p className="text-[#475466] text-lg">
              <FormattedMessage
                id={TRANSLATED_CONSTANTS.joinUsDescription.id}
                defaultMessage={TRANSLATED_CONSTANTS.joinUsDescription.defaultMessage}
              />
            </p>
          </div>
          <div className="pb-6">{renderHomePageButtons}</div>
        </section>
      </div>

      <Subscriber className="lg:px-0 w-full mb-[88px] " maxWidth="lg:max-w-[80vw]" />
      <div className="block"></div>
      <Modal
        confirmText={isCopied ? TRANSLATED_CONSTANTS.COPIED : TRANSLATED_CONSTANTS.copyToClipboard}
        actionButtonsFlow="vertical"
        theme="primary"
        onConfirm={copyToClipboard}
        onCancel={onCancel}
        confirmButtonIcon={<CopyIcon className="h-5 w-5" />}
        isReversed={true}
        hasHeader={false}
        isOpen={isModalOpen}>
        <>
          <div className="font-semibold pb-[1.5rem] text-xl text-center">
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.dogeWalletModalTitle.id}
              defaultMessage={TRANSLATED_CONSTANTS.dogeWalletModalTitle.defaultMessage}
            />
          </div>
          <div className="bg-gray-200 p-12 h-56 rounded-lg ">
            <img src={DogeWallet} className="m-auto" />
          </div>
          <div className="flex items-center mt-4 mb-[-17px]">
            <hr className="flex-grow border-t-1 border-gray-200" />
            <span className="mx-4 text-gray-500">or</span>
            <hr className="flex-grow border-t-1 border-gray-200" />
          </div>
        </>
      </Modal>
      <Footer></Footer>
    </div>
  );
}

export default About;
