import React, { FC } from 'react';
import CreateStorefront from '../../createStorefront/createStorefront';
import DogeDomain from '../../../components/shared/DogeDomain';
import BackNavigation from '../../../components/shared/BackNavigation';

type Props = {
  onNextStep: () => void;
  auth: any;
  currentStep: number;
  setCurrentStep: (currentStp: number) => void;
  navigate: (value: number) => void;
};

const CreateStoreFrontStep: FC<Props> = (props) => {
  const { onNextStep, auth, currentStep, setCurrentStep, navigate } = props;
  return (
    <div className="mt-12 px-[60px] w-full ">
      <DogeDomain className="mt-4"></DogeDomain>

      <BackNavigation
        className="mt-10 mb-14"
        onBackNavClick={() => {
          currentStep >= 2 ? setCurrentStep(currentStep - 1) : navigate(-1);
        }}></BackNavigation>
      <CreateStorefront isFirstStorefront={true} cancelCreation={onNextStep} create={onNextStep} />
    </div>
  );
};

export default CreateStoreFrontStep;
