import { useEffect, useState } from 'react';
import { handlegetCurrentLocationSuccess } from '../util/AppUtil';

const useCurrentLocation = () => {
  const [selectedLocation, setSelectedLocation] = useState<any>({});
  const [isCurrentLocatinLoading, setIsCurrentLocaitonLoading] = useState(0);

  useEffect(() => {
    const locationAccess = localStorage.getItem('locationAccess');
    const isLocationAllow = locationAccess ? JSON.parse(locationAccess) : false;
    if (isLocationAllow) {
      setIsCurrentLocaitonLoading(1);
      getLocation();
    } else {
      setIsCurrentLocaitonLoading(-1);
    }
  }, []);
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error);
    } else {
      console.log('Geolocation not supported');
    }
    function success(position: any) {
      const latLng = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      };
      handlegetCurrentLocationSuccess(position)
        .then((locationInfo) => {
          // You can access the locationInfo here
          if (locationInfo) {
            setSelectedLocation({
              address: {
                address: locationInfo?.location?.address,
                latitude: locationInfo?.location?.latitude,
                longitude: locationInfo?.location?.longitude,
                countryCode: locationInfo?.location?.countryCode,
                country: locationInfo?.location?.country,
                state: locationInfo?.location?.state,
                city: locationInfo?.location?.city,
                street: locationInfo?.location?.state,
                id: ''
              },
              locationBound: locationInfo.locationBound,
              latLng
            });
            setIsCurrentLocaitonLoading(-1);
          }
        })
        .catch((error) => {
          // Handle errors here
          setIsCurrentLocaitonLoading(-1);
          console.error('Failed to retrieve location: ' + error);
        });
    }
    function error() {
      setIsCurrentLocaitonLoading(-1);
      console.log('Unable to retrieve your location');
    }
  };

  return {
    selectedLocation,
    isCurrentLocatinLoading
  };
};

export default useCurrentLocation;
