import Button from '../Button';
import { ListingNew } from '../../../model/Listing';
import React from 'react';
import { Typo } from '../../Typo';
import Price from '../Price';
import RefreshIcon from '../../icons/RefreshIcon';
import { useIntl } from 'react-intl';
import { shortenText } from '../../../util/AppUtil';
import { Storefront } from '../../../model/Storefront';
import {
  ALTERNATE_PRICE_OPTION,
  LISTING_BREAKPOINT,
  LIST_PAGES,
  ROUTES
} from '../../../constants/data';
import clsx from 'clsx';
import ShoppingBagIcon from '../../icons/ShoppingBagIcon';
import EyeIcon from '../../icons/EyeIcon';
import PriceOption from '../PriceOption';
import CardWrapper from './CardWrapper';
import ListingCardAction from './ListingCardAction';
import FavoriteAction from './FavoriteAction';
import GetDirectionAction from './GetDirectionAction';
import { TRANSLATED_CONSTANTS } from '../../../static/translatedConstants';
import { AuthUserState } from '../../../context/AuthUserProvider';
type PriceProps = {
  page?:
    | LIST_PAGES.MAP
    | LIST_PAGES.SAVED
    | LIST_PAGES.ARCHIVE
    | LIST_PAGES.DASHBOARD
    | LIST_PAGES.SLIDE;
  size?: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  onEdit?: (id: number | string) => void;
  onDelete?: (id: number) => void;
  onShare?: (id: number | string) => void;
  onRelist?: (id: number) => void;
  onMarkAsSold?: (id: number) => void;
  onSendMessage?: (id: number | string) => void;
  onLike?: (id: number | string, type: string, like: boolean, savedListingId?: number) => void;
  listing: ListingNew;
  storeFront: Storefront;
  className?: string;
  cardClickable?: boolean;
};
const StorefrontItemCard: React.FC<PriceProps> = (props) => {
  const { me, authUser } = AuthUserState();
  const {
    page = LIST_PAGES.MAP,
    size = LISTING_BREAKPOINT.DEFAULT,
    listing,
    storeFront,
    className,
    onDelete,
    onEdit,
    onShare,
    onMarkAsSold,
    onRelist,
    onLike,
    cardClickable = false
  } = props;
  const intl = useIntl();
  const onSave = (id: number, type: string) => {
    onLike &&
      onLike(
        id,
        type,
        listing?.saved_listings && listing?.saved_listings.length > 0 ? true : false,
        listing?.saved_listings && listing?.saved_listings.length > 0
          ? Number(listing?.saved_listings[0]?.id)
          : undefined
      );
  };
  return (
    <CardWrapper
      type="storefrontItem"
      id={listing?.id}
      cardClickable={cardClickable}
      slug={listing?.slug}
      size={size}
      imageUrl={listing?.image[0].url}
      hasAward={listing.price.alternatePriceOptions == ALTERNATE_PRICE_OPTION.DOGE_DOGE}
      className={className}
      intl={intl}
      listingType={listing?.type}>
      <div className="w-full">
        {/* listing and user description   */}
        <div className="flex flex-col">
          <div className="flex justify-between items-start">
            <div className="">
              {/* price mobile size */}
              <div
                className={clsx('mb-4', {
                  ' block': size == LISTING_BREAKPOINT.SLIDE || LISTING_BREAKPOINT.MOBILE,
                  ' block sm:hidden': size === LISTING_BREAKPOINT.DEFAULT
                })}>
                <Price
                  alternatePriceOptions={listing.price.alternatePriceOptions}
                  priceInDoge={listing?.price?.priceInUSD || 0}
                  priceInUSD={listing?.price?.priceInDoge || 0}></Price>
              </div>

              <div>
                <Typo.textSm fontWeight="font-medium" className="text-blue-gray-500">
                  {`@${listing?.user && listing?.user.username}`}
                </Typo.textSm>
                <Typo.textLg fontWeight="font-medium" className=" text-gray-900 mt-1">
                  {listing?.name}
                </Typo.textLg>
              </div>
            </div>
            {/* card ation button */}
            <div>
              {/*  like and dislike button */}
              {(page === LIST_PAGES.MAP || page === LIST_PAGES.SAVED) && (
                <FavoriteAction
                  id={listing?.id || 0}
                  size={size}
                  liked={
                    listing?.saved_listings && listing?.saved_listings?.length > 0 ? true : false
                  }
                  disabled={me?.id == listing?.user?.id}
                  onLike={() => onSave(listing?.id, 'storefrontItem')}></FavoriteAction>
              )}

              {/* reslit button on archive */}
              {page === LIST_PAGES.ARCHIVE && (
                <>
                  <Button
                    size="lg"
                    iconPos="right"
                    theme="gray"
                    className={clsx({
                      'block sm:hidden': size == LISTING_BREAKPOINT.DEFAULT,
                      ' hidden':
                        size == LISTING_BREAKPOINT.MOBILE || size === LISTING_BREAKPOINT.SLIDE
                    })}
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      onRelist && listing?.issued_listing
                        ? onRelist(listing?.issued_listing?.id || 0)
                        : {};
                    }}
                    icon={<RefreshIcon></RefreshIcon>}>
                    <Typo.textMd fontWeight="font-semibold">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.RELIST.id,
                        defaultMessage: TRANSLATED_CONSTANTS.RELIST.defaultMessage
                      })}
                    </Typo.textMd>
                  </Button>
                  <Button
                    size="sm"
                    iconPos="right"
                    className={clsx({
                      ' hidden sm:block': size == LISTING_BREAKPOINT.DEFAULT,
                      ' block':
                        size == LISTING_BREAKPOINT.MOBILE || size == LISTING_BREAKPOINT.SLIDE
                    })}
                    theme="gray"
                    onClick={(event: { stopPropagation: () => void }) => {
                      event.stopPropagation();
                      onRelist && listing?.issued_listing
                        ? onRelist(listing?.issued_listing?.id || 0)
                        : {};
                    }}
                    icon={<RefreshIcon></RefreshIcon>}></Button>
                </>
              )}

              {/* share , edit and delete on dashboard  */}
              {page === 'dashboard' && (
                <ListingCardAction
                  id={listing?.id || 0}
                  onDelete={() => onDelete && onDelete(listing.id)}
                  onEdit={onEdit}
                  onShare={onShare}
                />
              )}
            </div>
          </div>
          <Typo.textMd className=" text-gray-500 w-full mt-4 mb-4" fontWeight="font-normal">
            {shortenText(listing?.description || '', 60)}
          </Typo.textMd>
        </div>

        <div
          className={clsx(
            'flex justify-between items-start',
            size == LISTING_BREAKPOINT.SLIDE ? ' block sm:hidden ' : ' block '
          )}>
          <div className="flex justify-between items-center gap-x-3">
            {/* Mark as sold button on dashboard */}
            {(page == LIST_PAGES.ARCHIVE || page == LIST_PAGES.DASHBOARD) && (
              <Button
                type="button"
                theme="blue-link"
                className="underline "
                onClick={(event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                  onMarkAsSold ? onMarkAsSold(listing?.id || 0) : {};
                }}
                disabled={listing?.isSold}
                icon={<ShoppingBagIcon></ShoppingBagIcon>}>
                <Typo.textLg fontWeight="font-semibold">
                  {intl.formatMessage({
                    id: TRANSLATED_CONSTANTS.MARK_AS_SOLD.id,
                    defaultMessage: TRANSLATED_CONSTANTS.MARK_AS_SOLD.defaultMessage
                  })}
                </Typo.textLg>
              </Button>
            )}

            {/* storefront item get direction button */}
            {page === LIST_PAGES.MAP && (
              <GetDirectionAction
                storefront={storeFront}
                size={size}
                showTimeLabel={false}
                currentLat={authUser?.location?.latitude}
                currentLong={authUser?.location?.longitude}
                destinationLat={storeFront?.location?.latitude}
                destinationLong={storeFront?.location?.longitude}
                destinationAddress={storeFront?.location?.address}></GetDirectionAction>
            )}

            {/* Preview button */}
            {page === LIST_PAGES.DASHBOARD && (
              <div className="flex gap-x-6">
                <Button
                  type="button"
                  theme="blue-link"
                  className="underline "
                  to={`${ROUTES.STOREFRONT_ITEM_DETAILS_ROUTE}/${listing.id}`}
                  icon={<EyeIcon className=" stroke-blue-gray-600"></EyeIcon>}>
                  <Typo.textMd fontWeight="font-semibold">
                    {intl.formatMessage({
                      id: TRANSLATED_CONSTANTS.PREVIEW.id,
                      defaultMessage: TRANSLATED_CONSTANTS.PREVIEW.defaultMessage
                    })}
                  </Typo.textMd>
                </Button>
                <PriceOption title={listing?.price?.priceType}></PriceOption>
              </div>
            )}
          </div>
          {/* card price */}
          <div
            className={clsx('mb-4', {
              ' hidden': size === LISTING_BREAKPOINT.MOBILE || size == LISTING_BREAKPOINT.SLIDE,
              ' hidden sm:block': size === LISTING_BREAKPOINT.DEFAULT
            })}>
            <Price
              alternatePriceOptions={listing.price.alternatePriceOptions}
              priceInUSD={listing.price.priceInUSD}
              priceInDoge={listing.price.priceInDoge}></Price>
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};

export default StorefrontItemCard;
