import { gql, useMutation } from '@apollo/client';

interface WarnUserInput {
  listingId?: number;
  storefrontId?: number;
  warnUserEmail: string;
  warnerId: number;
  claim: string;
  detail: string;
  userName: string;
  warnedId: number;
}
const WARN_USER = gql`
  mutation WarnDelete(
    $warnerId: ID!
    $listingId: ID
    $storefrontId: ID
    $warnUserEmail: String!
    $claim: String
    $detail: String
    $userName: String
    $warnedId: ID!
  ) {
    warnDelete(
      warnerId: $warnerId
      userName: $userName
      listingId: $listingId
      storefrontId: $storefrontId
      warnUserEmail: $warnUserEmail
      claim: $claim
      detail: $detail
      warnedId: $warnedId
    ) {
      count
    }
  }
`;

export const useWarnDelete = (): ((warnUser: WarnUserInput) => any) => {
  const [warnUser] = useMutation(WARN_USER);
  return (input: WarnUserInput) => warnUser({ variables: input });
};
