import clsx from 'clsx';
import { ReactElement } from 'react';
type TypoProps = {
  element?: keyof JSX.IntrinsicElements;
  /** Children to render inside this component. */
  children: string | JSX.Element | Array<JSX.Element> | React.ReactNode;

  /** CSS classnames. */
  className?: string;
  fontWeight: 'font-bold' | 'font-semibold' | 'font-normal' | 'font-medium';
};

const heading2xl = (props: TypoProps): ReactElement => {
  const { element = 'h1', children, fontWeight, className } = props;
  const Tag = element as keyof React.JSX.IntrinsicElements;
  const classes = clsx(
    'font-poppins text-6xl leading-[72px] md:text-7xl md:leading-[90px] -tracking-[1px]',
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingXl = (props: TypoProps): ReactElement => {
  const { element = 'h2', children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    'font-poppins text-5xl leading-[60px] md:text-6xl md:leading-[72px] -tracking-[1px]',
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingLg = (props: TypoProps): ReactElement => {
  const { element = 'h3', children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    'font-poppins text-4xl leading-[44px] md:text-5xl md:leading-[60px] -tracking-[1px]',
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingMd = (props: TypoProps): ReactElement => {
  const { element = 'h4', children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    'font-poppins text-3xl md:text-4xl leading-[44px] -tracking-[1px]',
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingSm = (props: TypoProps): ReactElement => {
  const { element = 'h5', children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    'font-poppins text-2xl leading-8 md:text-3xl md:leading-[44px] -tracking-[1px]',
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const headingXs = (props: TypoProps): ReactElement => {
  const { element = 'h6', children, fontWeight, className } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    'font-poppins text-xl leading-7 md:text-2xl md:leading-8 -tracking-[1px]',
    fontWeight,
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

const textXl = (props: TypoProps): ReactElement => {
  const { element = 'div', children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    className,
    'font-inter text-lg leading-7 md:text-xl md:leading-[30px]',
    fontWeight
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const textLg = (props: TypoProps): ReactElement => {
  const { element = 'div', children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    className,
    'font-inter text-base leading-6 md:text-lg md:leading-7',
    fontWeight
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const textMd = (props: TypoProps): ReactElement => {
  const { element = 'div', children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    className,
    'font-inter text-sm leading-5 md:text-base md:leading-6',
    fontWeight
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const textSm = (props: TypoProps): ReactElement => {
  const { element = 'div', children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(
    fontWeight,
    'font-inter text-xs leading-[18px]  md:text-sm md:leading-5',
    className
  );

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};
const textXs = (props: TypoProps): ReactElement => {
  const { element = 'div', children, className, fontWeight } = props;
  const Tag = element as keyof JSX.IntrinsicElements;
  const classes = clsx(className, 'font-inter text-xs leading-[18px]', fontWeight);

  return (
    <>
      <Tag className={classes}>{children}</Tag>
    </>
  );
};

export const Typo = {
  heading2xl,
  headingXl,
  headingLg,
  headingMd,
  headingSm,
  headingXs,
  textXl,
  textLg,
  textMd,
  textSm,
  textXs
};
