import Button from '../Button';
import React from 'react';
import { LISTING_BREAKPOINT } from '../../../constants/data';
import clsx from 'clsx';
import DisLikeIcon from '../../icons/DisLikeIcon';
import LikeIcon from '../../icons/LikeButton';
type PriceProps = {
  id: number | string;
  liked: boolean;
  size?: LISTING_BREAKPOINT.DEFAULT | LISTING_BREAKPOINT.MOBILE | LISTING_BREAKPOINT.SLIDE;
  onLike?: (id: number | string, state: boolean) => void;
  disabled: boolean;
};
const FavoriteAction: React.FC<PriceProps> = (props) => {
  const { size = LISTING_BREAKPOINT.DEFAULT, onLike, id, liked = false, disabled = false } = props;
  return (
    <>
      <Button
        size="sm"
        theme="gray"
        disabled={disabled}
        className={clsx({
          'block sm:hidden': size == LISTING_BREAKPOINT.DEFAULT,
          block: size == LISTING_BREAKPOINT.MOBILE || size === LISTING_BREAKPOINT.SLIDE
        })}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          onLike && onLike(id, !liked);
        }}
        icon={
          liked ? (
            <DisLikeIcon className="text-gray-700"></DisLikeIcon>
          ) : (
            <LikeIcon className="text-gray-500"></LikeIcon>
          )
        }></Button>
      <Button
        size="lg"
        theme="info"
        disabled={disabled}
        className={clsx({
          ' hidden sm:block': size == LISTING_BREAKPOINT.DEFAULT,
          ' hidden': size == LISTING_BREAKPOINT.MOBILE || size == LISTING_BREAKPOINT.SLIDE
        })}
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          onLike && onLike(id, !liked);
        }}
        icon={liked ? <DisLikeIcon></DisLikeIcon> : <LikeIcon></LikeIcon>}></Button>
    </>
  );
};

export default FavoriteAction;
