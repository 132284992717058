import { gql, useMutation } from '@apollo/client';

interface BanUserInput {
  queryParams: string;
  bannerId: number;
  isListing: boolean;
}
const BAN_USER = gql`
  mutation BanDeleteUsers($queryParams: String, $bannerId: ID!, $isListing: Boolean) {
    banDeleteUsers(queryParams: $queryParams, bannerId: $bannerId, isListing: $isListing) {
      count
    }
  }
`;

export const useBanDeleteUsers = (): ((banUser: BanUserInput) => any) => {
  const [banUser] = useMutation(BAN_USER);
  return (input: BanUserInput) => banUser({ variables: input });
};
