type Props = {
  className?: string;
};
const PlusIcon = ({ className }: Props) => {
  return (
    <svg
      className={' w-5 h-5 ' + className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 4.16669V15.8334M4.16669 10H15.8334"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
