import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useResetPassword } from './user/useResetPassword';
import { useSearchParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../static/translatedConstants';
export const useHandleNewPassword = () => {
  const [searchParams] = useSearchParams();
  const resetPassword = useResetPassword();
  const code = searchParams.get('code');
  const intl = useIntl();
  const schema = yup.object().shape({
    currentPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.ENTER_NEW_PASSWORD.id,
          defaultMessage: TRANSLATED_CONSTANTS.ENTER_NEW_PASSWORD.defaultMessage
        })
      )
      .min(
        8,
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.id,
          defaultMessage: TRANSLATED_CONSTANTS.MUST_BE_AT_LEAST_EIGHT_CHARACTERS.defaultMessage
        })
      )
      .matches(
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])/,
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.PASSWORD_VALIDATION_MESSAGE.id,
          defaultMessage: TRANSLATED_CONSTANTS.PAGE_NOT_FOUND_DESCRIPTION.defaultMessage
        })
      ),
    confirmPassword: yup
      .string()
      .required(
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.CONFIRM_NEW_PASSWORD.id,
          defaultMessage: TRANSLATED_CONSTANTS.CONFIRM_NEW_PASSWORD.defaultMessage
        })
      )
      .oneOf(
        [yup.ref('currentPassword'), ''],
        intl.formatMessage({
          id: TRANSLATED_CONSTANTS.DONOT_MATCH_PASSWORD.id,
          defaultMessage: TRANSLATED_CONSTANTS.DONOT_MATCH_PASSWORD.defaultMessage
        })
      )
  });

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (data: any) => {
    const response = await resetPassword({
      variables: {
        password: data.currentPassword,
        passwordConfirmation: data.confirmPassword,
        code: code || ''
      }
    });
    return response;
  };
  return { onSubmit, register, handleSubmit, errors };
};
