export const CONSTANT = {
  DOGELIST: 'Dogelist',
  DOGELIST_DOMAIN: 'Dogelist.com',
  LOGIN_LABEL: 'Log in to continue',
  EMAIL: 'Email',
  PASSWORD: 'Password',
  PASSWORD_CONFIRM: 'Password confirm',
  FORGOT_PASSWORD: 'Forgot password ?',
  FORGOT_PASSWORD_MESSAGE: 'Please enter the email address associated with your account.',
  RESET_MESSAGE: 'We will email you a link to reset your password.',
  LOGIN: 'login',
  CREATE_NEW_ACCOUNT: 'Create new account',
  DONOT_HAVE_ACCOUNT: 'Don’t have account yet?',
  COPY_WRITE: 'Dogelist.com 2022',
  BACK: 'Back',
  SEND: 'send',
  EMAIL_VERIFICATION: 'Email verification',
  EMAIL_VERIFICATION_MESSAGE: 'Please enter the verification code we sent to your email address.',
  DONOT_GET_CODE: 'Didn’t get a code? ',
  RE_SEND: 'Click to resend',
  CANCEL: 'Cancel',
  VERIFY: 'Verify',
  CHOOSE_ACCOUNT_TYPE: 'Choose your account type',
  PERSONAL_SELLER: 'Personal Seller',
  PERSONAL_SELLER_NOTE: '*As a personal seller your address is always kept private',
  BUSINESS_SELLER: 'Business',
  BUSINESS_SELLER_NOTE: '*Invite buyers to your brick and mortar location ',
  DIRECT_MESSAGING_IS_DISABLED: '*Direct messaging is currently disabled for businesses',
  CONTINUE: 'Continue',
  SIGN_UP_TO_CONTINUE: 'Sign up to continue',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  PHONE_NUMBER: 'Phone number',
  PHONE_VERIFICATION_NOTE: 'You’ll receive a 4 digit code to verify your number.',
  USER_NAME: 'Username',
  USER_NAME_VERIFICATION_NOTE: 'Symbols, profanity, racial or sexual references are not allowed.',
  EMAIL_VERIFICATION_NOTE:
    'An activation code will be sent to your e-mail address to make sure it’s you. Click on the link in this e-mail to activate your account.',
  PASSWROD: 'Password',
  PASSWORD_VERIFICATION_NOTE:
    'At least 8 characters. Must include at least one: uppercase letter, lowercase letter, number and symbol.',
  REQUIRED_FIELDS: 'Required Fields',
  STRIK: '*',
  AGREEMENT_MESSAGE: 'I agree to the Terms of Service and Privacy Policy',
  VERIFY_PHONE_NUMBER: 'Verify phone number',
  WE_HAVE_SEND_CODE: 'We’ve sent a code to ',
  ADD_YOUR_LOCATION: 'Add your location',
  WHERE_YOU_LOCATED: 'Where are you located?',
  LOCATION_PLACEHOLDER: 'E.g. New York, NY, USA',
  SHOWING_LOCATION_NOTE:
    '*As a private seller your address and phone number are always kept private.',
  TWEAK_YOUR_LOCATION: 'Tweak your location',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  CITY: 'City',
  STATE: 'State',
  COUNTRY: 'Country',
  ADDRESS: 'Address',
  CONGRATULATION: 'Congratulations!',
  PERSONAL_ACCOUNT_CREATED_SUCCESSFULY: 'You’re personal account was successfully created.',
  BUSINESS_ACCOUNT_CREATED_SUCCESSFULY: 'You’re business account was successfully created.',
  PERSONAL_ACCOUNT_CREATED_MESSAGE:
    'Click the view dashboard button to add your first listing. Now get out there and start exploring, buying and selling!',
  BUSINESS_ACCOUNT_CREATED_MESSAGE:
    'Click the view dashboard button to add your first listing. Now get out there and start exploring, buying and selling!',
  VIEW_DASHBOARD: 'View Dashboard',
  DASHBOARD: 'Dashboard',
  WELLCOME_TO_DOGELIST: 'Welcome to Dogelist.com',
  EXPLORE_BUY_SELL: 'Explore, buy, and sell with Dogecoin',
  FIND_BUYER_AND_SELLER_MESSAGE:
    'Find local buyers and sellers who want to transact in Dogecoin – the people’s crypto. Sign up in just a few easy clicks!',
  EXPLORE_MARKETPLACE: 'Explore Marketplace',
  CREATE_LISTING: 'Create a listing',
  DOGELIST_MAP: 'Dogelist Map',
  ADD_YOUR_BUSINESS_TO_MAP: 'Add your business to the Dogelist map to start selling today!',
  FIND_LCATION: 'Find A Location',
  ADD_LOCATION: 'Add Location',
  ENTER_FOR_SEARCH_LOCATION: 'Enter a search word to find matching locations.',
  FIND: 'Find',
  OPEN_DOGELIST_MAP: 'Open the Dogelist map',
  BUYING_TITLE: 'Buy anything locally using Dogecoin',
  BUYING_DESCRIPTION:
    'Discover what local sellers and businesses are selling in your local area, wherever you may be. On Dogelist.com, you can buy anything locally using Dogecoin. Our marketplace is built to support local communities. You can easily find sellers near you and get a quote for your purchase.',
  BUYING_START: 'Start Buying',
  SELLING_TITLE: 'Sell anything using Dogecoin',
  SELLING_DESCRIPTION:
    'Sell with confidence. You can buy or sell anything locally using Dogecoin as a private seller or as a business.',
  SELLING_START: 'Start Selling',
  SELLING_AS_PRIVATE_SELLER: 'Selling as a private seller',
  SELLING_AS_PUBLIC_SELLER: 'Selling as a business',
  FINT_LOCAL_BUYER_SELLER:
    'Find local buyers and sellers who want to transact in Dogecoin – the people’s crypto.',
  HOME: 'Home',
  ABOUT: 'About',
  FORUME: 'Forum',
  WHITEPAPER: 'Whitepaper',
  TERMS: 'Terms',
  PRIVACY: 'Privacy',
  COPYWRITE: 'Dogelist.com 2022',
  JOIN_EMAIL_LIST: 'Join our email list',
  JOIN_EMAIL_LIST_DESCRIPTION:
    'Join our emailing list to stay up to date with new updates and features.',
  SIGN_UP: 'Sign up',
  FOLLOW_US_ON_SOCIAL: 'Follow us on Social',
  TERM_OF_SERVICE: 'Terms of Service',
  TERM_OF_SERVICE_AGREEMENT: 'Terms of Service Agreement',
  LAST_UPDATED: 'Last Updated',
  READ_AND_UNERSTAND: 'I have read and understood the ',
  AGREE_WITH_TERM_OF_SERVICE: 'I agree with the terms of service ',
  PRIVARY_POLICY_AGREEMENT: 'Privacy Policy Agreement',
  PRIVARY_POLICY: ' Privacy Policy',
  AGREE_WITH_PRIVACY_POLICY: 'I agree with the privacy policy',
  YOUR_SVAED_LISING: 'Your Saved Listings',
  YOUR_SAVED_LISTING_EMPTY: 'Your saved list is empty.',
  NO_LISING_SAVED: 'No listings saved yet',
  YOU_HAVE: 'You have ',
  SAVED_LISING: 'saved listing.',
  YOUR_WANTED_LISING: 'Your Wanted Listings',
  YOUR_WANTED_LISTING_EMPTY: 'Your wanted list is empty',
  NO_LISING_WANTED: 'There’s nothing in your wanted list yet.',
  WANTED_LISING: ' wanted listing',
  YOUR_ACTIVE_LISING: 'Your Active Listings',
  YOUR_ACTIVE_LISTING_EMPTY: 'No active listings.',
  NO_LISING_ACTIVE: 'No listings yet',
  ACTIVE_LISING: ' active listing',
  CREATE_NEW_LISTING: 'Create New Listing',
  YOUR_MESSAGES: 'Your Messages',
  YOUR_MESSAGES_EMPTY: 'You don’t have any messages yet.',
  NO_MESSAGES: 'No messages yet',
  UNREAD_MESSAGES: ' unread messages.',
  YOUR_STOREFRONT: 'Your Storefront',
  YOUR_STOREFRONT_EMPTY: 'No active storefronts.',
  CREATE_A_STOREFRONT: 'Create a Storefront',
  NO_STOREFRONT: 'No stores yet',
  ADD_ANOTHER_STOREFRONT: 'Add Another Storefront',
  STOREFRONT: 'Storefront',
  USER_DASHBOARD: 'User Dashboard',
  PERSONAL: 'Personal seller',
  JOINED: 'Joined',
  BUSINESS: 'Business',
  CREATE_NEW_SELLER: 'Create New Listing',
  ADD_PHOTO_OF_YOUR_ITEM: 'Add a photo of your item',
  SUPPORTED_FILES: 'File types supported: JPG, PNG, SVG',
  WHAT_CATEGORY_DOES_FALL: 'What category does this fall under?',
  DRAG_YOUR_IMAGE: 'Drop your image here, or',
  BROWSE: 'browse',
  SELECT_CATEGORY: 'Select a category',
  CURRENCY: 'Currency',
  USD: 'USD',
  AMOUNT: 'Amount',
  PRICE_IN_DOGE: 'Price in Doge',
  DOGE: 'DOGE',
  PRICE_OPTION: 'Price options',
  PRICE_IS_FIRM: 'Price is firm',
  OPEN_FOR_OFFER: 'Open for offers',
  ENTER_NAME_OF_YOUR_ITEM: 'Enter the name of your item ',
  ENTER_YOUR_ITEM_NAME: 'Enter your item’s name',
  ITEM_CONDATION: 'Item Condition',
  DESCRIPTION: 'Description',
  ENTER_ITEM_DESCRIPTION: 'Enter your item’s description',
  CHO0SE_WITCH_STROEFRONT: 'Choose which storefront to add this item to',
  ADD_TO_STOREFRONT: 'Add to Storefront',
  ADD_AS_INDIVIDUAL: 'Add as an individual listing',
  ITEM_LOCATION: 'Item Location',
  ADD_INDIVIDUAL_LISTING: 'Add Individual Listing',
  ADD_PHOTO_FOR_SOTREFRONT: 'Add some photos for your storefront',
  ENTER_NAME_OF_YOUR_STORE: 'Enter the name of your store ',
  ENTER_YOUR_STORE_NAME: 'Enter your store’s name',
  GIVE_ABRIEF_DESCRIPTION: 'Give a brief description of what you sell in your store.',
  STOREFRONT_LOCATION: 'Storefront Location',
  WHERE_STORE_LOCATED: 'Where is the store located?',
  SEND_MESSAGE: 'Send Message',
  HOW_MUCH_YOU_WANT_PAY: 'How much do you want to pay?',
  WANTED_PRICE_IN_DOGE: 'Wanted price in Doge',
  ARE_YOU_BUYING_OR_SELLING: 'Are you buying or selling?',
  SELLING: 'Selling',
  ANYONE_SEE: 'Anyone will be able to see your listing on the map',
  WANTED: 'Wanted (buying)',
  WANTED_TAG: 'Wanted',
  LIST_ALL_ITEM_YOU_WISH: 'List an item you wish to purchase',
  SELLING_PRICE: 'Selling Price',
  WANTED_PRICE: 'Wanted Price',
  EDIT_STORFRONT: 'Edit storefront',
  DELETE_LISTING: 'Delete Listing',
  DELETE_CHAT: 'Delete Chat',
  EDIT_LISTING: 'Edit Listing',
  IMAGES_ARE_REQUIRED: 'images are required fields',
  LISTING_CREATED_SUCCESSFULY: 'listing created successfully',
  STOREFRONT_CREATED_SUCCESSFULY: 'storefront created successfully',
  DELETE_MESSAGE: 'Are you sure you want delete this ',
  SAVE: 'Save',
  STOREFRONT_UPDATED_SUCCESSFULY: 'Storefront updated successfully',
  LISTING_UPDATED_SUCCESSFULY: 'Listing updated successfully',
  CHOOSE_STOREFRONT: 'Choose which storefront to add this item to',
  YOU_WANT_DELETE_STROFRONT: 'Are you sure you want to delete this storefront?',
  YOU_WANT_DELETE_LISTING: 'Are you sure you want to delete this listing?',
  NO_ACTIVE_STOREFRONT: 'No active storefronts.',
  GET_DIRECTIONS: 'Get Directions',
  MESSAGE_SELLER: 'Message Seller',
  YOU_AGREE_TO_THE: 'You agree to the ',
  AND_SIGN: ' & ',
  YOU_ALSO_AGREE_TO: 'You also agree to the',
  AND_THE: 'and the ',
  YOUR_WANTED_LIST_DISPLAYED_HERE: 'Your wanted list is displayed here.',
  YOUR_ACTIVE_LISTING_DISPLAYED_HERE: 'Your active listings are displayed here.',
  YOUR_SAVED_LIST_DISPLAYED_HERE: 'Your saved listings are displayed here.',
  CREATE_ACCOUNT: 'Create account',
  CREATE_AN_ACCOUNT: 'Create an account',
  DOGELIST_TERM_OF_SERVICE: 'Dogelist Terms of Service',
  DOGELIST_PRIVACY_POLICY: 'Dogelist Privacy Policy',
  GOOGLE_PRIVACY_POLICY: 'Google Privacy Policy',
  GOOGLE_TERM_OF_SERVICE: 'Google Terms of Service',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  CREATE_AN_ACCOUNT_ACCESS_EVERTHING:
    'Create an account for access to everything Dogelist has to offer.',
  SIGN_UP_WITH_GOOGLE: 'Sign up with Google',
  OR: 'Or',
  LOGIN_WITH_GOOGLE: 'Log in with Google',
  SELECT_STORFRONT: 'Select a storefront',
  BUSINESS_NAME: 'Name of business',
  CLEAR: 'Clear',
  SEARCH: 'Search',
  PROFILE: 'Profile',
  MESSAGES: 'Messages',
  SETTINGS: 'Settings',
  LOGOUT: 'Log out',
  ADD_WANTED_LISTING: 'Add a wanted listing',
  ADD_WANTED_LISTING_DESCRIPTION:
    'Create a wanted listing for item’s you’re looking to purchase and someone will reach out to you if they have it for sale.',
  ADD_PERSONAL_LISTING: 'Add a personal listing',
  ADD_PERSONAL_LISTING_DESCRIPTION:
    'List anything from your old pairs of shoes or unused electronics. Dogelist is the right place to sell your unwanted items.',
  ADD_STOREFRONT: 'Add a storefront',
  ADD_STOREFRONT_DESCRIPTION:
    'If you own a business you can list your local business as a storefront that other Dogelist users can discover on the map.',
  THE_DOGELIST_MAP: 'The Dogelist map',
  ADD_LISTING_TO_MAP: 'Add listings to the map',
  ADD_LISTING_TO_MAP_DESCRIPTION:
    'All listings on the Dogelist map are available for anyone in the area to see. Buyers can search by category, name, and location to find items they want to purchase or discover.',
  DOES_ACCEPT_DOGELIST: 'Does your business accept Dogecoin?',
  ADD_YOUR_LOCAL_BUSINESS_TO_MAP: 'Add your local business to the map.',
  BUYING_AND_SELLING_ON_DOGELIST: 'Buying & selling on Dogelist.com',
  BUY_AND_SELL_NAYTHING_LOCALY: 'Buy & sell anything locally using Dogecoin',
  OUR_MARKETPLACE_BUILT_TO_LOCAL_COMMUNITIES:
    'Our marketplace is built to support local communities.',
  DISCOVR_LOCAL_BUYER_AND_BUSINESS:
    'Discover what local sellers and businesses are selling in your area — wherever you may be.'
};
