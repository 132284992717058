import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import InfoIcon from '../icons/InfoIcon';
import { Typo } from '../Typo';

type Props = {
  text: string;
  hasIcon?: boolean;
  type: 'default' | 'error' | 'success';
  linkText?: string;
  link?: string;
  className?: string;
  state: boolean;
  onChangeState?: (state: boolean) => void;
};

const InlineAlert: FC<Props> = ({
  text,
  hasIcon = false,
  type,
  linkText,
  link,
  className,
  state,
  onChangeState
}) => {
  const colorMap = {
    default: 'gray',
    success: 'success',
    error: 'error'
  };

  const color = colorMap[type] || 'default';
  const parts = linkText ? text?.split(linkText) : [text];

  useEffect(() => {
    let timer: any = '';
    if (state) {
      timer = setTimeout(() => {
        onChangeState && onChangeState(!state);
      }, 2000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  return (
    <>
      {state ? (
        <div className={`rounded-lg p-2 md:p-4 w-full bg-${color}-100 ${className}`}>
          <div className="flex gap-3">
            {hasIcon && (
              <div>
                <InfoIcon className={`w-4 h-4 text-${color}-500`} />
              </div>
            )}
            <Typo.textSm fontWeight="font-normal" className={`text-${color}-600n`}>
              {parts[0]}
              {link && (
                <Link to={link} className="underline">
                  {linkText}
                </Link>
              )}
              {parts[1]}
            </Typo.textSm>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default InlineAlert;
