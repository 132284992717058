/* eslint-disable react/prop-types */
import React, { FC, useEffect, useState } from 'react';
import { TileLayer, Marker, MapContainer, useMap, ZoomControl } from 'react-leaflet';
import { LatLngTuple } from 'leaflet';
import { handleIcon } from '../../../util/MapUtil';
import 'leaflet/dist/leaflet.css';
import clsx from 'clsx';
import { ListingNew } from '../../../model/Listing';
import { Storefront } from '../../../model/Storefront';

type Props = {
  coords?: number[];
  className?: string;
  hasPopup?: boolean;
  listings?: ListingNew[];
  storeFronts?: Storefront[];
  refetch?: () => void;
  type?: 'storefront' | 'listing';
};
const zoom = 1.5;

const Location: FC<Props> = ({ coords, className, listings, storeFronts, type }) => {
  const [locationCoords, setLocationCoords] = useState(coords || [0, 0]);
  useEffect(() => {
    setLocationCoords(coords || [0, 0]);
  }, [coords]);

  const Content = () => {
    const map = useMap();

    useEffect(() => {
      const markers: LatLngTuple[] = [];
      if (coords) {
        markers.push([coords[0], coords[1]]);
      } else {
        [...(storeFronts || []), ...(listings || [])].map((item) => {
          item?.location ? markers.push([item?.location?.latitude, item?.location?.longitude]) : '';
        });
      }
      if (markers?.length > 0) {
        map.fitBounds(markers);
      }
    }, [storeFronts, listings]);
    return (
      <>
        {storeFronts?.map((item: Storefront, index: number) => {
          return (
            <Marker
              key={index}
              position={[item?.location?.latitude, item?.location?.longitude]}
              icon={handleIcon('storefront')}>
              {/* <MapTooltip storeFront={item} /> */}
            </Marker>
          );
        })}
        {listings?.map((item: ListingNew, index: number) => {
          return (
            <Marker
              key={index}
              position={[item?.location?.latitude, item?.location?.longitude]}
              icon={handleIcon(item?.type)}>
              {/* <MapTooltip listing={item} /> */}
            </Marker>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <MapContainer
        center={[0, 0]}
        className={clsx('rounded-lg z-[2] ', className ? className : 'w-[200px] h-[200px]')}
        zoom={zoom}
        scrollWheelZoom={true}
        fadeAnimation={true}
        zoomAnimation={true}
        zoomControl={false}>
        <ZoomControl position="topright" />
        <TileLayer attribution="" url={process.env.REACT_APP_MAPBOX_TILE ?? ''} />
        {coords && type ? (
          <Marker
            position={[locationCoords[0], locationCoords[1]]}
            icon={handleIcon(type)}></Marker>
        ) : (
          <Content />
        )}
      </MapContainer>
    </div>
  );
};

export default Location;
