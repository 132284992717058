/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql, useMutation } from '@apollo/client';
import { GET_BLOCKED_USERS } from '../chat/useGetBlockedUser';

interface BlockedUserInput {
  variables: {
    data: {
      date: Date;
      blocked: number;
      blocked_by: number;
      publishedAt: Date;
    };
  };
}

export const CREATE_BLOCKED_USER = gql`
  mutation blockUser($data: BlockedUserInput!) {
    createBlockedUser(data: $data) {
      data {
        id
        attributes {
          blocked {
            data {
              id
              attributes {
                username
                name
              }
            }
          }
          blocked_by {
            data {
              id
            }
          }
        }
      }
    }
  }
`;

export const useBlockUser = (): ((createBlockedUser: BlockedUserInput) => any) => {
  const [createBlockedUserResult] = useMutation(CREATE_BLOCKED_USER, {
    refetchQueries: [GET_BLOCKED_USERS, 'GetBlockedUsers']
  });
  return createBlockedUserResult;
};
