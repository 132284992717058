import React, { useEffect } from 'react';
import Button from './Button';
import CloseIcon from '../icons/CloseIcon';
import clsx from 'clsx';
import CopyRightIcon from '../icons/CopyRightIcon';
import FeaturedIcon from './FeaturedIcon';
import { Typo } from '../Typo';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { useDispatch } from 'react-redux';
import { setIsBannerOpen } from '../../lib/redux/reducers/alert.slice';

type Props = {
  type?: 'slim' | 'privacy' | 'dual-action' | undefined;
  theme?: 'primary-light' | 'primary-dark' | 'gray-light';
  children?: any;
  className?: string;
  icon?: React.ReactElement;
  state?: boolean;
  onDeclineClicked?: () => void;
  onAllowClicked?: () => void;
  onCloseClicked?: () => void;
  dualActionText?: string;
  [x: string]: any;
};
const Banner: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const {
    children = '',
    theme = 'gray-light',
    type = 'privacy',
    onDeclineClicked = () => void {},
    onAllowClicked = () => void {},
    onCloseClicked,
    className,
    icon,
    dualActionText,
    state
  } = props;

  useEffect(() => {
    dispatch(setIsBannerOpen(true));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      onCloseClicked && onCloseClicked();
    }, 8000);

    return () => {
      clearTimeout(timer);
    };
  }, [state]);

  if (!state) {
    return null;
  }
  let classes = 'relative p-4 space-y-3 sm:space-y-0  flex justify-center ';

  switch (type) {
    case 'slim':
      classes += `${theme === 'gray-light' ? 'border-b border-gray-300 bg-gray-50' : ''}${
        theme === 'primary-light' ? 'border-b border-yellow-300 bg-yellow-50' : ''
      } `;
      break;
    case 'dual-action':
      classes += `flex-col sm:flex-row relative gap-y-3 p-4 sm:py-3 sm:px-8 ${
        theme === 'primary-light'
          ? 'border-t border-yellow-300 sm:border-b sm:border-t-0 sm:border-yellow-300'
          : ''
      }`;
      break;
    case 'privacy':
      classes += 'border-t border-gray-300';
      break;
  }

  switch (theme) {
    case 'primary-light':
      classes += ' bg-yellow-25 ';
      break;
    case 'primary-dark':
      classes += ' bg-gradient-to-r from-yellow-700 to-yellow-600';
      break;
    case 'gray-light':
      classes += '  bg-gray-50 ';
      break;
  }

  classes += ` ${className}`;
  return (
    <div className={clsx(classes)}>
      <div className="flex items-center gap-x-4 w-full">
        {icon && (
          <div className={`hidden ${type === 'dual-action' ? ' lg:flex ' : ''}`}>
            <FeaturedIcon
              color={theme === 'primary-dark' ? 'primary-dark' : 'primary'}
              theme={
                theme === 'primary-dark' ? 'primary-dark-circle-outline' : 'light-circle-outline'
              }
              size="md">
              {icon}
            </FeaturedIcon>
          </div>
        )}
        <div className="pr-10 w-full flex items-center ">
          {type === 'privacy' ? (
            <div className=" flex flex-col sm:flex-row w-full justify-between font-inter text-base leading-6">
              <div className=" flex gap-x-8">
                <div className=" hidden text-gray-500 sm:flex gap-x-2 items-center">
                  <CopyRightIcon></CopyRightIcon> Dogelist.com 2022
                </div>
                <div className=" text-gray-700">
                  <FormattedMessage id="shared.privacy_choice" defaultMessage="Privacy choice" />
                </div>
              </div>
              <div className=" flex gap-x-4">
                <Link to="">
                  <FormattedMessage id="shared.terms" defaultMessage="Terms" />
                </Link>
                <Link to="">
                  <FormattedMessage id="shared.privacy" defaultMessage="Privacy" />
                </Link>
              </div>
            </div>
          ) : (
            children
          )}
          {type === 'dual-action' && (
            <div className="flex gap-[6px]">
              <Typo.textMd
                fontWeight="font-semibold"
                className={clsx({
                  'text-white': theme === 'primary-dark',
                  'text-yellow-700': theme === 'primary-light'
                })}>
                {dualActionText}
              </Typo.textMd>
              <Typo.textMd fontWeight="font-normal" className="text-yellow-600">
                <FormattedMessage id="banner.dual_action_read_our" defaultMessage="Read our" />{' '}
                <Link to="/privacy-policy" className="underline">
                  <FormattedMessage id="shared.privacy_policy" defaultMessage="Privacy policy" />
                </Link>
              </Typo.textMd>
            </div>
          )}
        </div>
        <Button
          onClick={onCloseClicked}
          size="md"
          className={`absolute top-1 right-3 sm:relative sm:flex sm:items-center sm:top-0 sm:right-0 border-none bg-opacity-0 hover:bg-opacity-0 focus:bg-opacity-0 ${
            type === 'dual-action' ? ' sm:hidden' : ''
          }`}>
          <CloseIcon
            className={clsx(
              ' h-5 w-5 flex justify-center items-center',
              theme == 'primary-light' ? 'stroke-yellow-500' : '',
              theme == 'primary-dark' ? 'stroke-white' : '',
              theme == 'gray-light' ? 'stroke-gray-500' : ''
            )}></CloseIcon>
        </Button>
      </div>
      <div
        className={`${
          type === 'dual-action' ? 'flex ' : ' hidden '
        } w-full flex-col sm:w-auto sm:flex-row space-y-3 sm:space-y-0 justify-center items-center  gap-x-2`}>
        <Button onClick={onDeclineClicked} theme="base" size="lg" className=" w-full sm:w-auto ">
          <FormattedMessage
            id={TRANSLATED_CONSTANTS.DECLINE.id}
            defaultMessage={TRANSLATED_CONSTANTS.DECLINE.defaultMessage}
          />
        </Button>
        <Button
          onClick={onAllowClicked}
          theme="primary-yellow"
          size="lg"
          className={`w-full sm:w-auto ${
            theme == 'primary-dark' ? ' bg-yellow-50' : 'bg-yellow-600'
          }`}>
          <span className={`${theme == 'primary-dark' ? 'text-yellow-700' : 'text-white'}`}>
            <FormattedMessage
              id={TRANSLATED_CONSTANTS.ALLOW.id}
              defaultMessage={TRANSLATED_CONSTANTS.ALLOW.defaultMessage}
            />
          </span>
        </Button>
        <Button
          onClick={onCloseClicked}
          size="lg"
          className=" hidden sm:block relative border-none bg-opacity-0 hover:bg-opacity-0">
          <CloseIcon
            className={clsx(
              ' h-5 w-5 flex justify-center items-center bg-opacity-0 hover:bg-opacity-0',
              theme == 'primary-light' ? 'text-yellow-500' : '',
              theme == 'primary-dark' ? 'text-white hover:text-yellow-500' : '',
              theme == 'gray-light' ? 'text-gray-500' : ''
            )}></CloseIcon>
        </Button>
      </div>
    </div>
  );
};

export default Banner;
