import { useState } from 'react';
import PageWrapper from '../../components/wrapper/PageWrapper';
import { Typo } from '../../components/Typo';
import Address from '../../components/shared/Address';
import Button from '../../components/shared/Button';
import ShareIcon from '../../components/icons/ShareIcon';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import Divider from '../../components/shared/Divider';
import AccountInfo from '../../components/shared/AccountInfo';
import DisLikeIcon from '../../components/icons/DisLikeIcon';
import LikeIcon from '../../components/icons/LikeButton';
import ReportButton from '../../components/shared/ReportButton';
import { AuthUserState } from '../../context/AuthUserProvider';
import { useParams } from 'react-router-dom';
import DashboardHeader from '../../components/shared/Dashboard Header/DashboardHeader';
import Loader from '../../components/shared/Loader';
import OperationHourLabel from '../../components/shared/Hours Operation/OperationHourLabel';
import ImageSlider from '../../components/shared/ImageSlider/ImageSlider';
import { Image } from '../../model/Image';
import Location from '../../components/shared/map/Location';
import { getCurrentDate } from '../../util/CurrentDate';
import StorefrontSlideScreen from '../../components/dashboard/slide/StorefrontSlide';
import { priceOptionIconMap } from '../listing';
import { ALERT_TYPE, ALTERNATE_PRICE_OPTION, ROLES, ROUTES } from '../../constants/data';
import { handlePriceOptionContent } from '../../util/ListingBadgeHandler';
import DirectionButton from '../storefront/DirectionButton';
import PriceCard from '../listing/PriceCard';
import StorefrontInfoCard from '../listing/StorefrontInfoCard';
import { useReportListing } from '../../hooks/listing/useReportListing';
import { useNavigate } from 'react-router-dom';
import StorefrontItems from '../storefront/StorefrontItems';
import { useGetStorefrontItem } from '../../hooks/listing/useGetStorefrontItem';
import { useHandleSave } from '../../hooks/useHandleSave';
import { useUpdateUser } from '../../hooks/user/useUpdateUser';
import { useDispatch } from 'react-redux';
import { setAlertData, setAlertType } from '../../lib/redux/reducers/alert.slice';
import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
import ReportModal from '../../components/modal/ReportModal';
import { showModal } from '../../lib/redux/reducers/modal.slice';
import { setDirectionData } from '../../lib/redux/reducers/direction.slice';
import { setShareData } from '../../lib/redux/reducers/share.slice';
import PrimaryActionModal from '../../components/modal/PrimaryActionModal';
import TrashIcon from '../../components/icons/TrashIcon';
import { useWarnDelete } from '../../hooks/reports/useWarnDelete';
import { useGetUserRole } from '../../hooks/useRole';
import { useAppSelector } from '../../lib/redux/hooks';
import { setProfileData, showSlide } from '../../lib/redux/reducers/slide.slice';
const StorefrontItemDetails = () => {
  const [showReportListingModal, setShowReportListingModal] = useState(false);
  const [showWarnAndDeleteModal, setShowWarnAndDeleteModal] = useState(false);
  const reportListing = useReportListing();
  const navigate = useNavigate();
  const auth = AuthUserState();
  const user = auth.authUser;
  const intl = useIntl();
  const profileState = useAppSelector((state) => state.slide.profile);
  const { id } = useParams();
  const { listing, loading, refetch } = useGetStorefrontItem(Number(id), user?.id || null);
  console.log(listing, 'lllllllll');
  const listingUser = listing?.user;
  const isUserOnline = useCheckOnlineUsers(Number(listingUser?.id));
  const [showStorefrontSlide, setShowStorefrontSlide] = useState(false);
  const { save } = useHandleSave(refetch);
  const updateUser = useUpdateUser();
  const dispatch = useDispatch();
  const warnDelete = useWarnDelete();
  const role = useGetUserRole();

  const onShare = () => {
    dispatch(
      setShareData({ isOpen: true, url: `${ROUTES.STOREFRONT_ITEM_DETAILS_ROUTE}/${listing?.id}` })
    );
  };

  const onCloseReportModal = (value: boolean) => {
    setShowReportListingModal(value);
  };

  const onReport = async (detail: string, claim: string) => {
    await reportListing({
      variables: {
        data: {
          detail: detail,
          claim: claim,
          listing: Number(listing?.id),
          reporter: user?.id,
          date: new Date(),
          publishedAt: getCurrentDate()
        }
      }
    }).then(async (response: any) => {
      if (response) {
        await updateUser({
          variables: {
            data: {
              decisions: user?.decisions + 1
            },
            id: user?.id
          }
        });
        dispatch(setAlertType(ALERT_TYPE.ERROR));
        dispatch(
          setAlertData({
            title: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_TITLE.id,
              defaultMessage: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_TITLE.defaultMessage
            }),
            description: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_DESCRIPTION.id,
              defaultMessage: TRANSLATED_CONSTANTS.LISTING_REPORT_ALERT_DESCRIPTION.defaultMessage
            }),
            link: '',
            cancelTitle: intl.formatMessage({
              id: TRANSLATED_CONSTANTS.DISMISS.id,
              defaultMessage: TRANSLATED_CONSTANTS.DISMISS.defaultMessage
            }),
            image: ''
          })
        );
        setShowReportListingModal(false);
        navigate(-1);
      }
    });
  };

  const onSave = async (liked: boolean, savedId: number | undefined) => {
    save(listing?.id, 'storefrontItem', liked, savedId);
  };

  const priceOptionTitle = handlePriceOptionContent(
    listing?.price?.alternatePriceOptions || '',
    intl
  );

  const onGetDirection = () => {
    dispatch(showModal('direction'));
    dispatch(
      setDirectionData({
        current: {
          lat: user?.location?.latitude,
          long: user?.location?.longitude
        },
        destination: {
          lat: listing?.store_front?.location?.latitude,
          long: listing?.store_front?.location?.longitude
        }
      })
    );
  };

  const onCloseWarnAndDeleteModal = async (confirm: boolean) => {
    if (!confirm) {
      setShowWarnAndDeleteModal(false);
      return;
    }
    await warnDelete({
      warnedId: listingUser.id,
      warnerId: user.id,
      listingId: listing.id,
      warnUserEmail: listingUser.email,
      userName: listingUser.username,
      claim: '',
      detail: ''
    }).then(() => {
      navigate(-1);
    });
    setShowWarnAndDeleteModal(false);
  };

  const openProfile = () => {
    // if (role === ROLE_TYPES.ADMIN || role === ROLE_TYPES.SUPER_ADMIN) {
    if (!profileState) {
      dispatch(setProfileData({ userId: listing?.user?.id }));
      dispatch(showSlide('profile'));
    }
    // }
  };

  return (
    <div>
      <PageWrapper hasSpeicifWidth={true}>
        {!loading && listing ? (
          <div className="flex flex-col gap-8">
            <DashboardHeader
              title={listing?.name}
              cancelButtonIcon={<ShareIcon className="text-gray-700 h-5 w-5" />}
              onCancelClick={() => onShare()}
              cancelButtonTitle={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.SHARE.id,
                defaultMessage: TRANSLATED_CONSTANTS.SHARE.defaultMessage
              })}
              confirmDisabled={user?.id == listing?.user?.id}
              onConfrimClick={
                listing?.saved_listings && listing?.saved_listings?.length > 0
                  ? () => onSave(true, listing?.saved_listings[0]?.id)
                  : () => onSave(false, undefined)
              }
              hasDeleteOption={role === ROLES.SUPER_ADMIN}
              onDeleteClick={() => setShowWarnAndDeleteModal(true)}
              deleteText={intl.formatMessage({
                id: TRANSLATED_CONSTANTS.DELETE_STOREFRONT_ITEM.defaultMessage,
                defaultMessage: TRANSLATED_CONSTANTS.DELETE_STOREFRONT_ITEM.defaultMessage
              })}
              confirmButtonTitle={intl.formatMessage({
                id:
                  listing?.saved_listings && listing?.saved_listings?.length > 0
                    ? TRANSLATED_CONSTANTS.UNSAVE.id
                    : TRANSLATED_CONSTANTS.SAVE.id,
                defaultMessage:
                  listing?.saved_listings && listing?.saved_listings?.length > 0
                    ? TRANSLATED_CONSTANTS.UNSAVE.defaultMessage
                    : TRANSLATED_CONSTANTS.SAVE.defaultMessage
              })}
              storefrontUrl={`${ROUTES.STOREFRONT_DETAILS_ROUTE}/${listing?.store_front?.id}`}
              description={
                <div className="flex items-center mt-1 gap-6">
                  <Address address={listing?.store_front?.location?.address || ''} />
                  <div className="hidden md:block">
                    <div className="flex gap-2 items-center">
                      {priceOptionIconMap[listing?.price?.alternatePriceOptions]}
                      <Typo.textMd
                        fontWeight="font-medium"
                        className={
                          listing?.price?.alternatePriceOptions == ALTERNATE_PRICE_OPTION.DOGE_DOGE
                            ? 'text-yellow-700'
                            : 'text-gray-600'
                        }>
                        {priceOptionTitle.title}
                      </Typo.textMd>
                    </div>
                  </div>
                </div>
              }></DashboardHeader>
            {/* Hero section  */}
            <div className="flex flex-col lg:grid lg:grid-cols-3 gap-8">
              <ImageSlider
                type="storefrontItem"
                className="w-full lg:col-span-2"
                imageUrls={listing?.image?.map((img: Image) => img.url)}
              />
              <div className="w-full flex flex-col md:flex-row lg:col-span-1 lg:flex-col gap-5">
                {/* price  */}
                <PriceCard
                  usdPrice={listing?.price?.priceInUSD}
                  dogePrice={listing?.price?.priceInDoge}
                  disabled={listing.listing_reports && listing.listing_reports?.length > 0}
                  priceOption={listing?.price?.alternatePriceOptions}
                  priceType={listing?.price?.priceType}
                />

                {/* currency  */}
                <StorefrontInfoCard
                  getDirection={onGetDirection}
                  storefront={listing?.store_front}
                />
              </div>
            </div>
            {/* Listing Details section  */}
            <div className="w-full flex-col ">
              <div className="flex flex-col-reverse md:flex-row gap-5 mb-6">
                <div className="flex-1 ">
                  <Typo.headingXs fontWeight="font-medium" className="text-gray-900 mb-2">
                    {listing?.name}
                  </Typo.headingXs>
                  <div className="flex gap-2 items-center">
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {intl.formatMessage({
                        id: TRANSLATED_CONSTANTS.CATEGORY.id,
                        defaultMessage: TRANSLATED_CONSTANTS.CATEGORY.defaultMessage
                      })}
                    </Typo.textMd>
                    <div className="w-1.5 h-1.5 p-0.5 justify-center items-center inline-flex">
                      <div className="w-0.5 h-0.5 bg-gray-600 rounded-full" />
                    </div>
                    <Typo.textMd fontWeight="font-normal" className="text-gray-600">
                      {listing?.category?.name}
                    </Typo.textMd>
                  </div>
                </div>
                <AccountInfo
                  openProfile={openProfile}
                  className="flex-1 flex justify-start gap-4 items-start"
                  name={listingUser?.name}
                  isOnline={isUserOnline}
                  username={listingUser?.username}
                  profileImage={listingUser?.profileImage?.url}
                />
              </div>
              <Divider />
              <div className="flex flex-col gap-6 mt-6 max-w-[824px] mb-6">
                <Typo.textMd fontWeight="font-medium" className="text-gray-700">
                  <FormattedMessage
                    id={TRANSLATED_CONSTANTS.DESCRIPTION.id}
                    defaultMessage={TRANSLATED_CONSTANTS.DESCRIPTION.defaultMessage}
                  />
                </Typo.textMd>
                <Typo.textMd fontWeight="font-normal" className="text-gray-500">
                  {listing?.description}
                </Typo.textMd>
                <div className="flex gap-4 flex-wrap md:gap-6">
                  <OperationHourLabel
                    defaultValue={listing?.store_front?.operationHours || []}></OperationHourLabel>
                  <div className="flex gap-2 items-center">
                    {priceOptionIconMap[listing?.price?.alternatePriceOptions]}
                    <Typo.textMd
                      fontWeight="font-medium"
                      className={
                        listing?.price?.alternatePriceOptions == ALTERNATE_PRICE_OPTION.DOGE_DOGE
                          ? 'text-yellow-700'
                          : 'text-gray-600'
                      }>
                      {listing?.price?.alternatePriceOptions ==
                      ALTERNATE_PRICE_OPTION.NEGOTIABLE ? (
                        <FormattedMessage
                          id={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.id}
                          defaultMessage={TRANSLATED_CONSTANTS.NEGOTIABLE_NO_PRICE.defaultMessage}
                        />
                      ) : (
                        priceOptionTitle.title
                      )}
                    </Typo.textMd>
                  </div>
                  <Address address={listing?.store_front?.location?.address || ''} />
                </div>
                <div className="flex gap-3 w-full mt-5">
                  <Button
                    className=""
                    disabled={user?.id == listing?.user?.id}
                    onClick={
                      listing?.saved_listings && listing?.saved_listings?.length > 0
                        ? () => onSave(true, listing?.saved_listings[0]?.id)
                        : () => onSave(false, undefined)
                    }
                    size="md"
                    theme="base"
                    icon={
                      listing?.saved_listings?.length > 0 ? <DisLikeIcon /> : <LikeIcon />
                    }></Button>
                  <ReportButton
                    disabled={
                      (listing.listing_reports && listing.listing_reports?.length > 0) ||
                      user?.id == listing?.user?.id
                    }
                    openReportModal={(value: boolean) => setShowReportListingModal(value)}
                  />

                  <DirectionButton getDirection={onGetDirection} />
                </div>
              </div>
              <Divider />
            </div>
            {/* Storefront Items  */}
            {listing && listing.store_front && (
              <StorefrontItems
                listings={listing?.store_front?.listings || []}
                storefront={listing.store_front}
                onViewMore={() => setShowStorefrontSlide(true)}
                onSave={save}
              />
            )}
            {/* Location  */}
            <div className="flex flex-col gap-6">
              <Typo.headingXs fontWeight="font-medium" className="text-gray-900">
                <FormattedMessage
                  id={TRANSLATED_CONSTANTS.MEETUP_LOCATION.id}
                  defaultMessage={TRANSLATED_CONSTANTS.MEETUP_LOCATION.defaultMessage}
                />
              </Typo.headingXs>
              <div className="h-[400px] w-full bg-[#E2E2E2] rounded-lg">
                {listing?.store_front && (
                  <Location className="w-full h-[400px]" storeFronts={[listing?.store_front]} />
                )}
              </div>
              <div className="flex flex-col-reverse md:flex-row justify-start items-start gap-6 md:gap-16 mb-6">
                <div className="flex flex-col gap-6">
                  <AccountInfo
                    className=" flex justify-start gap-4 items-start"
                    name={listingUser?.name}
                    isOnline={isUserOnline}
                    username={listingUser?.username}
                    profileImage={listingUser?.profileImage?.url}
                  />
                  <Button onClick={onGetDirection} theme="base" size="lg">
                    <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                      <FormattedMessage
                        id={TRANSLATED_CONSTANTS.GET_DIRECTIONS.id}
                        defaultMessage={TRANSLATED_CONSTANTS.GET_DIRECTIONS.defaultMessage}
                      />
                    </Typo.textMd>
                  </Button>
                </div>
                <div className="flex flex-col gap-2">
                  {listing?.store_front?.location?.address ? (
                    <Address address={listing?.store_front?.location.address} />
                  ) : (
                    ''
                  )}
                  <Typo.textMd fontWeight="font-normal" className="text-gray-500 max-w-[760px]">
                    <FormattedMessage
                      id={TRANSLATED_CONSTANTS.LISTING_ADDRESS_DESCRIPTION.id}
                      defaultMessage={
                        TRANSLATED_CONSTANTS.LISTING_ADDRESS_DESCRIPTION.defaultMessage
                      }
                    />
                  </Typo.textMd>
                </div>
              </div>
              <Divider />
            </div>
          </div>
        ) : (
          <Loader className=" h-screen" />
        )}
      </PageWrapper>
      <ReportModal
        onReport={onReport}
        isOpen={showReportListingModal}
        onCloseReportModal={(value: boolean) => onCloseReportModal(value)}
        type="listing"
        confirmText={TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_CONFIRM_BUTTON}
        modalTitle={TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_TITLE}
        dropDownLabel={intl.formatMessage({
          id: TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_LABEL.id,
          defaultMessage: TRANSLATED_CONSTANTS.REPORT_LISTING_MODAL_LABEL.defaultMessage
        })}
      />
      <PrimaryActionModal
        title={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_TITLE}
        isOpen={showWarnAndDeleteModal}
        onClosePrimaryActionModal={(value: boolean) => onCloseWarnAndDeleteModal(value)}
        subTitle={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_SUBTITLE}
        confirmText={TRANSLATED_CONSTANTS.WARN_DELETE_LISTING_MODAL_CONFIRM_BUTTON}
        icon={<TrashIcon />}
      />
      {listing?.store_front && (
        <StorefrontSlideScreen
          storefront={listing?.store_front}
          onClose={() => setShowStorefrontSlide(false)}
          open={showStorefrontSlide}
        />
      )}
    </div>
  );
};

export default StorefrontItemDetails;
