import React from 'react';
import { Typo } from '../Typo';

type Props = {
  icon?: string | JSX.Element;
  children?: string | JSX.Element;
  className?: string;
  [x: string]: any;
};

const DescriptionLabel: React.FC<Props> = (props) => {
  const { icon, children, className } = props;
  return (
    <div className=" flex items-center gap-x-2">
      {icon}
      <Typo.textMd fontWeight="font-medium" className={`text-gray-600 ${className}`}>
        {children}
      </Typo.textMd>
    </div>
  );
};

export default DescriptionLabel;
