type Props = {
  className?: string;
};
const CalendarIcon = ({ className }: Props) => {
  return (
    <svg className={className} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5 8.83464H2.5M13.3333 2.16797V5.5013M6.66667 2.16797V5.5013M6.5 18.8346H13.5C14.9001 18.8346 15.6002 18.8346 16.135 18.5622C16.6054 18.3225 16.9878 17.94 17.2275 17.4696C17.5 16.9348 17.5 16.2348 17.5 14.8346V7.83464C17.5 6.4345 17.5 5.73444 17.2275 5.19966C16.9878 4.72925 16.6054 4.3468 16.135 4.10712C15.6002 3.83464 14.9001 3.83464 13.5 3.83464H6.5C5.09987 3.83464 4.3998 3.83464 3.86502 4.10712C3.39462 4.3468 3.01217 4.72925 2.77248 5.19966C2.5 5.73444 2.5 6.4345 2.5 7.83464V14.8346C2.5 16.2348 2.5 16.9348 2.77248 17.4696C3.01217 17.94 3.39462 18.3225 3.86502 18.5622C4.3998 18.8346 5.09987 18.8346 6.5 18.8346Z"
        stroke="currentColor"
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
