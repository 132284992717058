import { Disclosure } from '@headlessui/react';
import { Typo } from '../Typo';
import Button from '../shared/Button';
import SearchIcon from '../icons/SearchIcon';
import DropdownMenu from '../shared/DropdownMenu';
import { DropdownOption } from '../../model/DropdownOption';
import DropdownMenuHeader from '../shared/DropdownMenuHeader';
import Menu2Icon from '../icons/Menu2Icon';
import XIcon from '../icons/XIcon';
import Avatar from '../shared/Avatar';
import { NavigationModel } from '../../model/Navigation';
import LogOutIcon from '../icons/LogOutIcon';
import NavItem from './NavItem';
import NavLink from './NavLink';
import { FormattedMessage, useIntl } from 'react-intl';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { ROLES } from '../../constants/data';
import { useLocation, useSearchParams } from 'react-router-dom';
import LoginModal from '../modal/LoginModal';
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../lib/redux/reducers/modal.slice';
import { useAppSelector } from '../../lib/redux/hooks';
import SignupModal from '../modal/SignupModal';
import AgeVerificationModal from '../modal/AgeVerificationModal';
import DatePickerModal from '../modal/DatePickerModal';
import ForgotPasswordModal from '../modal/ForgotPasswordModal';
import NewPasswordModal from '../modal/NewPasswordModal';
import { useEffect } from 'react';
import DogeDomain from '../shared/DogeDomain';
import InviteModal from '../modal/InviteModal';
import { useGetUserRole } from '../../hooks/useRole';
import User1Icon from '../icons/User1Icon';
import { useAuth } from '../../context/Authn';
import NotificationsList from '../Notifications/NotificationsList';
import { useCheckOnlineUsers } from '../../hooks/useCheckOnlineUsers';
import { handleNavigationConstData } from '../../util/Navigation';
import { useHandlePagesWithNoHeader } from '../../hooks/useHandlePagesWithNoHeader';
const Navigation = () => {
  const hasHeader = useHandlePagesWithNoHeader();
  if (!hasHeader) {
    return null;
  }
  const intl = useIntl();
  const location = useLocation();
  const dispatch = useDispatch();
  const loginState: boolean = useAppSelector((state) => state.modal.login);
  const signUpState = useAppSelector((state) => state.modal.signup);
  const ageState = useAppSelector((state) => state.modal.age);
  const dateState = useAppSelector((state) => state.modal.date);
  const forgotPasswordSate = useAppSelector((state) => state.modal.forgotPassword);
  const newPasswordState = useAppSelector((state) => state.modal.newPassword);
  const inviteSate = useAppSelector((state) => state.modal.inviteFriend);
  const role = useGetUserRole();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const auth = useAuth();
  const authUser = auth?.getUser();
  const {
    authenticatedOptions,
    unAuthenticatedOptions,
    pagesNavigation,
    eventNavigation,
    settingNavigation,
    headerNavigations
  } = handleNavigationConstData(intl, authUser);

  const onChangeOption = (option: DropdownOption) => {
    if (!authUser) {
      if (option.id == 4) {
        dispatch(showModal('login'));
      } else if (option.id == 3) {
        dispatch(showModal('signup'));
      }
    } else {
      if (option.id == 7) {
        auth.signout();
        window.location.href = '/';
      } else if (option.id === 5) {
        dispatch(showModal('inviteFriend'));
      }
    }
  };

  const onChangeEvent = (option: NavigationModel) => {
    if (option.id === 3) {
      dispatch(showModal('inviteFriend'));
    }
  };

  const onCloseLoginModal = () => {
    dispatch(hideModal('login'));
  };

  const onCloseSignupModal = () => {
    dispatch(hideModal('signup'));
  };

  const onCloseAgeVerificationModal = () => {
    dispatch(hideModal('age'));
    dispatch(showModal('signup'));
  };

  const onCloseForgotPasswordModal = () => {
    dispatch(hideModal('forgotPassword'));
    dispatch(showModal('login'));
  };

  const onCloseNewPasswordModal = () => {
    dispatch(hideModal('newPassword'));
  };

  const onCloseDatePickerModal = () => {
    dispatch(hideModal('date'));
    dispatch(showModal('age'));
  };

  const openFilterModal = () => {
    dispatch(showModal('filter'));
  };
  const onCloseInviteModal = () => {
    dispatch(hideModal('inviteFriend'));
  };

  useEffect(() => {
    if (code) {
      dispatch(showModal('newPassword'));
    }
  }, [code]);

  return (
    <div className="sticky top-0 z-20">
      <div className="">
        <Disclosure as="nav">
          {({ open, close }) => (
            <>
              <div className="mx-auto px-4 sm:px-[60px] w-full bg-gradient-to-b from-[#F9FAFB] to-[#FBFAF9] from-0%  to-100% ">
                <div className="flex h-[72px] items-center justify-between">
                  <div className="flex gap-x-5">
                    <div className="flex flex-shrink-0 items-center gap-2">
                      <DogeDomain />
                      <Typo.textSm
                        fontWeight="font-medium"
                        className="rounded-xl bg-yellow-100 text-yellow-800 px-2 py-0.5">
                        Beta
                      </Typo.textSm>
                    </div>
                    <div className="hidden lg:flex lg:space-x-1">
                      {headerNavigations?.map((nav) => (
                        <>
                          {nav.id === 4 ? (
                            (role === ROLES.SUPER_ADMIN || role === ROLES.ADMIN) && (
                              <NavLink
                                key={nav.title}
                                navigation={nav}
                                current={nav.link === location.pathname}></NavLink>
                            )
                          ) : (
                            <NavLink
                              key={nav.title}
                              navigation={nav}
                              current={nav.link === location.pathname}></NavLink>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                  <div className="flex gap-x-4">
                    <div className="flex items-center gap-x-4">
                      <Button
                        onClick={openFilterModal}
                        theme="base"
                        size="2xl"
                        className="hidden lg:block"
                        icon={<SearchIcon className="text-gray-700"></SearchIcon>}>
                        <Typo.textSm fontWeight="font-semibold">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
                          })}
                        </Typo.textSm>
                      </Button>

                      <div className="flex ">
                        <Button
                          size="md"
                          theme="gray-link"
                          className="p-[10px] block sm:hidden"
                          icon={<SearchIcon className="text-gray-500"></SearchIcon>}></Button>
                        {/* TODO:uncomment when localization is active */}
                        {/* <Button
                        size="md"
                        theme="gray-link"
                        className="p-[10px] hidden sm:block"
                        icon={<GlobeIcon className="text-gray-500"></GlobeIcon>}></Button> */}
                        <NotificationsList />
                      </div>
                      <DropdownMenu
                        type="image"
                        hasChevron={true}
                        className=" hidden sm:block"
                        image={authUser?.profileImage?.url}
                        header={
                          authUser ? <DropdownMenuHeader user={authUser}></DropdownMenuHeader> : ''
                        }
                        onChange={onChangeOption}
                        options={
                          !authUser
                            ? unAuthenticatedOptions
                            : authUser?.profileCompleted
                            ? authenticatedOptions
                            : authenticatedOptions.filter(
                                (option) => option.id !== 2 && option.id !== 3
                              )
                        }></DropdownMenu>
                    </div>
                    <div className="flex items-center lg:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center">
                        <span className="sr-only">Open main menu</span>
                        <Button
                          theme="gray-link"
                          icon={
                            open ? (
                              <XIcon className="block h-6 w-6 text-gray-500" aria-hidden="true" />
                            ) : (
                              <Menu2Icon
                                className="block h-6 w-6 text-gray-700"
                                aria-hidden="true"
                              />
                            )
                          }></Button>
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden absolute w-full overflow-auto pb-20 h-screen z-10 bg-white shadow-lg">
                <div className="border-t border-gray-200 min-h-full">
                  {/* navigation header */}
                  <div className="px-4 sm:px-[60px] flex flex-col gap-y-4 sm:flex-row w-full items-start sm:items-center justify-between py-3 border-b border-gray-200 ">
                    <div className="flex gap-x-3 py-3">
                      <div>
                        <Avatar
                          showState={authUser ? true : false}
                          isOnline={useCheckOnlineUsers(Number(authUser?.id)) || false}
                          disabled={true}
                          size="md"
                          icon={
                            !authUser?.profileImage?.url && <User1Icon className="text-gray-600" />
                          }
                          image={authUser?.profileImage?.url}></Avatar>
                      </div>
                      <div className=" flex flex-col font-poppins justify-center">
                        <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                          {authUser?.name}
                        </Typo.textMd>
                        <Typo.textMd fontWeight="font-normal" className="text-gray-500">
                          {authUser?.username && `@${authUser?.username}`}
                        </Typo.textMd>
                      </div>
                    </div>
                    <div className="flex">
                      <Button
                        onClick={openFilterModal}
                        theme="base"
                        icon={<SearchIcon className="text-gray-700"></SearchIcon>}>
                        <Typo.textSm fontWeight="font-semibold">
                          {intl.formatMessage({
                            id: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.id,
                            defaultMessage: TRANSLATED_CONSTANTS.EXPLORE_MARKETPLACE.defaultMessage
                          })}
                        </Typo.textSm>
                      </Button>
                    </div>
                  </div>
                  {/* page navigations */}
                  <div className="py-[30px] border-b border-gray-200">
                    {pagesNavigation?.map((page) => {
                      return (
                        <div key={page.title} onClick={() => close()}>
                          <NavItem user={authUser} navigation={page} hasRightArrow={true}></NavItem>
                        </div>
                      );
                    })}
                  </div>
                  {/* event navigations */}
                  {authUser && (
                    <div className="py-[30px] border-b border-gray-200">
                      {eventNavigation?.map((event) => {
                        return (
                          <div key={event.title} onClick={() => close()}>
                            <NavItem
                              onClick={onChangeEvent}
                              user={authUser}
                              navigation={event}
                              hasRightArrow={true}></NavItem>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* setting navigations */}
                  {authUser && (
                    <div className="py-[30px] border-b border-gray-200">
                      {settingNavigation?.map((setting) => {
                        return (
                          <div key={setting.title} onClick={() => close()}>
                            <NavItem
                              user={authUser}
                              key={setting.title}
                              navigation={setting}
                              hasRightArrow={true}></NavItem>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {/* logout navigation */}
                  {authUser ? (
                    <div className="py-[30px]  border-gray-200">
                      <NavItem
                        onClick={() => {
                          auth.signout();
                          window.location.href = '/';
                        }}
                        navigation={{
                          link: '',
                          title: 'Log out',
                          roles: [''],
                          icon: <LogOutIcon className="h-6 w-6" />
                        }}></NavItem>
                    </div>
                  ) : (
                    <div className="w-full px-4 sm:px-[60px] pt-6 flex flex-col gap-y-3">
                      <Button
                        onClick={() => {
                          dispatch(showModal('login'));
                          close();
                        }}
                        size="lg"
                        theme="base">
                        <Typo.textMd fontWeight="font-semibold" className="text-gray-700">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.LOGIN.id}
                            defaultMessage={TRANSLATED_CONSTANTS.LOGIN.defaultMessage}
                          />
                        </Typo.textMd>
                      </Button>
                      <Button
                        onClick={() => {
                          dispatch(showModal('signup'));
                          close();
                        }}
                        size="lg"
                        theme="primary-yellow">
                        <Typo.textMd fontWeight="font-semibold" className="text-gray-900">
                          <FormattedMessage
                            id={TRANSLATED_CONSTANTS.SIGN_UP.id}
                            defaultMessage={TRANSLATED_CONSTANTS.SIGN_UP.defaultMessage}
                          />
                        </Typo.textMd>
                      </Button>
                    </div>
                  )}
                </div>
              </Disclosure.Panel>
              <SignupModal
                isOpen={signUpState}
                onCloseSignupModal={onCloseSignupModal}></SignupModal>
              <LoginModal onCloseLoginModal={onCloseLoginModal} isOpen={loginState} />
              <DatePickerModal isOpen={dateState} onCancelDatePicker={onCloseDatePickerModal} />
              <AgeVerificationModal
                isOpen={ageState}
                onCloseAgeVerificationModal={onCloseAgeVerificationModal}
              />
              <ForgotPasswordModal
                isOpen={forgotPasswordSate}
                onCloseForgotPasswordModal={onCloseForgotPasswordModal}
              />
              <NewPasswordModal
                isOpen={newPasswordState}
                onCloseNewPasswordModal={onCloseNewPasswordModal}
              />
              <InviteModal
                isOpen={inviteSate}
                onCloseInviteModal={onCloseInviteModal}
                lastName="Erfan"
              />
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

export default Navigation;
