import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  username: '',
  password: '',
  referalCode: '',
  age: ''
};
const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setAge: (state, action) => {
      state.age = action.payload;
    },
    setSignupData: (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.referalCode = action.payload.referalCode;
    }
  }
});

export const { setAge, setSignupData } = signupSlice.actions;
export default signupSlice.reducer;
