import React, { FC } from 'react';
import Button from '../../components/shared/Button';
import MessageIcon from '../../components/icons/MessageIcon';
import { Typo } from '../../components/Typo';
import { TRANSLATED_CONSTANTS } from '../../static/translatedConstants';
import { FormattedMessage } from 'react-intl';

type Props = {
  openMessage: (value: boolean) => void;
  clasName?: string;
  disabled?: boolean;
};

const MessageButton: FC<Props> = ({ openMessage, clasName, disabled = false }) => {
  return (
    <Button
      onClick={() => openMessage(true)}
      className={clasName}
      disabled={disabled}
      size="md"
      theme="primary-yellow"
      icon={<MessageIcon className="text-gray-700" />}>
      <Typo.textSm fontWeight="font-semibold" className="text-gray-900">
        <FormattedMessage
          id={TRANSLATED_CONSTANTS.MESSAGE.id}
          defaultMessage={TRANSLATED_CONSTANTS.MESSAGE.defaultMessage}
        />
      </Typo.textSm>
    </Button>
  );
};

export default MessageButton;
